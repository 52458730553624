
<div *ngIf="(isLoading$ | async); else templateBody" class="spinner-container">
    <!-- <p-progressSpinner styleClass="w-6rem h-6rem"></p-progressSpinner> -->
    <p-card>
        <div class="flex flex-wrap">

            <div class="relative w-full h-4rem  md:my-0 border-round">
                <div
                    class="absolute top-0 right-0 font-bold flex align-items-center justify-content-center  border-round">
                    <!-- <p-skeleton height="2rem" styleClass="mb-2"></p-skeleton> -->
                    <p-button icon="pi pi-refresh" styleClass="p-button-rounded p-button-info p-button-outlined"
                        (onClick)="refresh()"></p-button>

                </div>
            </div>
        </div>
        <p-table [value]="dummyArrayHorizontalGrid">
            <ng-template pTemplate="header">
                <!--  <tr>
                    <th>Code</th>
                    <th>Name</th>
                    <th>Category</th>
                    <th>Quantity</th>
                </tr> -->
            </ng-template>
            <ng-template pTemplate="body" let-product>
                <tr>
                    <td class="font-bold"> Container Number</td>
                    <td><p-skeleton></p-skeleton></td>
                </tr>
                <tr>
                    <td class="font-bold"> Product Name</td>
                    <td><p-skeleton></p-skeleton></td>
                </tr>
                <tr>
                    <td class="font-bold"> Formula</td>
                    <td><p-skeleton></p-skeleton></td>
                </tr>
                <tr>
                    <td class="font-bold"> Net Volume CBM </td>
                    <td><p-skeleton></p-skeleton></td>
                </tr>
                <tr>
                    <td class="font-bold"> Net Volume CFT </td>
                    <td><p-skeleton></p-skeleton></td>
                </tr>
                <tr>
                    <td class="font-bold"> Pieces</td>
                    <td><p-skeleton></p-skeleton></td>
                </tr>
                <tr>
                    <td class="font-bold"> CFT Average</td>
                    <td> <p-skeleton></p-skeleton></td>
                </tr>
                <tr>
                    <td class="font-bold"> Allowance</td>
                    <td>
                <tr>
                    <td style="min-width: 10rem;" class="font-bold">Girth</td>
                    <td><p-skeleton width="10rem" height="1rem"></p-skeleton></td>
                </tr>
                <tr>
                    <td class="font-bold">Length</td>
                    <td><p-skeleton width="10rem" height="1rem"></p-skeleton></td>
                </tr>
                </td>
                </tr>

                <tr>
                    <td class="font-bold"> Unit of Measure </td>
                    <td>
                <tr>
                    <td style="min-width: 10rem;" class="font-bold">Girth</td>
                    <td><p-skeleton width="10rem" height="1rem"></p-skeleton></td>
                </tr>
                <tr>
                    <td class="font-bold">Length</td>
                    <td><p-skeleton width="10rem" height="1rem"></p-skeleton></td>
                </tr>
                </td>
                </tr>
            </ng-template>
        </p-table>

        <div style="padding: 1em 0;">
            <hr style="border-top: 1px solid #000;">
        </div>

        <!-- Girth -->
        <p-skeleton width="100%" height="4rem"></p-skeleton>
        <div class="mt-1">
            <p-skeleton width="100%" height="6rem"></p-skeleton>
        </div>



        <div style="padding: 1em 0;">
            <hr style="border-top: 1px solid #000;">
        </div>
        <!-- Height -->
        <p-skeleton width="100%" height="4rem"></p-skeleton>
        <div class="mt-1">
            <p-skeleton width="100%" height="6rem"></p-skeleton>
        </div>
    </p-card>
</div>

<ng-template #templateBody>
    <p-card>
        <div class="flex flex-wrap">

            <div class="relative w-full h-4rem  md:my-0 border-round">
                <div
                    class="absolute top-0 right-0 font-bold flex align-items-center justify-content-center  border-round">
                    <p-button icon="pi pi-refresh" styleClass="p-button-rounded p-button-info p-button-outlined"
                        (onClick)="refresh()"></p-button>

                </div>
            </div>
        </div>



        <p-table [value]="dummyArrayHorizontalGrid">
            <ng-template pTemplate="header">
                <!--  <tr>
                    <th>Code</th>
                    <th>Name</th>
                    <th>Category</th>
                    <th>Quantity</th>
                </tr> -->
            </ng-template>
            <ng-template pTemplate="body" let-product>
                <tr>
                    <td class="font-bold"> Container Number</td>
                    <td>{{ hoppusTimberSummary?.containerNumber?? '' }}</td>
                </tr>
                <tr>
                    <td class="font-bold"> Product Name</td>
                    <td>{{ hoppusTimberSummary?.productName?? '' }}</td>
                </tr>
                <tr>
                    <td class="font-bold"> Formula</td>
                    <td>{{ hoppusTimberSummary?.formula?? '' }}</td>
                </tr>
                <tr>
                    <td class="font-bold"> Net Volume CBM </td>
                    <td>{{ hoppusTimberSummary?.netVolume?? '' }}</td>
                </tr>
                <tr>
                    <td class="font-bold"> Net Volume CFT </td>
                    <td>{{ (hoppusTimberSummary?.netVolumeCft) || 0 | number:'1.3-3' }}</td>
                </tr>
                <tr>
                    <td class="font-bold"> Pieces</td>
                    <td>{{ hoppusTimberSummary?.pieces?? 0 }}</td>
                </tr>
                <tr>
                    <td class="font-bold"> CFT Average</td>
                    <td>{{ (( (hoppusTimberSummary?.netVolume?? 0) * 35.315) / hoppusTimberSummary?.pieces) || 0 | number:'1.3-3'
                        }}</td>
                </tr>
                <tr>
                    <td class="font-bold"> Allowance</td>
                    <td>
                <tr>
                    <td style="min-width: 10rem;" class="font-bold">Girth</td>
                    <td>{{hoppusTimberSummary?.girthAllowance?? 0 }}</td>
                </tr>
                <tr>
                    <td class="font-bold">Length</td>
                    <td>{{hoppusTimberSummary?.lengthAllowance?? 0 }}</td>
                </tr>
                </td>
                </tr>

                <tr>
                    <td class="font-bold"> Unit of Measure</td>
                    <td>
                <tr>
                    <td style="min-width: 10rem;" class="font-bold">Girth</td>
                    <td>{{hoppusTimberSummary?.lengthUnit?? '' }}</td>
                </tr>
                <tr>
                    <td class="font-bold">Length</td>
                    <td>{{hoppusTimberSummary?.girthUnit?? '' }}</td>
                </tr>
                </td>
                </tr>
            </ng-template>
        </p-table>



        <div style="padding: 1em 0;">
            <hr style="border-top: 1px solid #000;">
        </div>

        <!-- Girth -->
        <p-table [value]="[dummyArrayHorizontalGrid]" styleClass="mt-5" styleClass="p-datatable-gridlines">
            <ng-template pTemplate="caption" class="text-center">
                Girth Overview
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Girth</th>
                    <th class="text-center" *ngFor="let item of hoppusTimberSummary?.girthHistogramArray?? []">{{item.range}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td>Total Pieces</td>
                    <td class="text-center" *ngFor="let item of hoppusTimberSummary?.girthHistogramArray?? []">{{item.count}}
                    </td>
                </tr>
            </ng-template>
        </p-table>


        <div style="padding: 1em 0;">
            <hr style="border-top: 1px solid #000;">
        </div>
        <!-- Height -->
        <p-table [value]="[dummyArrayHorizontalGrid]" styleClass="mt-5" styleClass="mt-5"
            styleClass="p-datatable-gridlines">
            <ng-template pTemplate="caption" class="text-center">
                Length Overview
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Length</th>
                    <th class="text-center" *ngFor="let item of hoppusTimberSummary?.lengthHistogramArray?? []">{{item.range}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td>Total Pieces</td>
                    <td class="text-center" *ngFor="let item of hoppusTimberSummary?.lengthHistogramArray?? []">{{item.count}}
                    </td>
                </tr>
            </ng-template>
        </p-table>


    </p-card>
</ng-template>