import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { ChartDataType } from 'src/app/interfaces/tally-sheet/chart-data-type';
import { ChartDataUnit } from 'src/app/interfaces/tally-sheet/chart-data-unit';
import { ChartTitle } from 'src/app/interfaces/tally-sheet/chart-title';
import { ColorType } from 'src/app/interfaces/tally-sheet/color-type';
import { environment } from 'src/environments/environment';
 


@Injectable({
  providedIn: 'root'
})
export class ChartService {

  /* private httpProtocol = 'http://'; //'https://';
   private port = ':8080'; //':8080' ':80' ''
 
   private ipaddress = '192.168.1.71' //'192.168.1.71' //'192.168.1.3' // 'localhost' // '192.168.249.152' // 'riksb.tunn.dev'
   private baseUrl = `${this.httpProtocol}${this.ipaddress}${this.port}/test/`;
   private webSocketUrl = `${this.httpProtocol}${this.ipaddress}${this.port}/ws`*/

  // private baseUrl = `${environment.httpProtocol}${environment.ipaddress}${environment.port}/test/`;
  // private webSocketUrl = `${environment.httpProtocol}${environment.ipaddress}${environment.port}/ws`;

  private baseUrl = `${AppConstants.API_URL}test/`;
  // private webSocketUrl = `${environment.httpProtocol}${environment.ipaddress}${environment.port}/ws`;
  constructor(private readonly http: HttpClient) { }

  getChartData(summaryId: number, colorType: ColorType, dataUnit: ChartDataUnit,
    dataType: ChartDataType, title: ChartTitle): Observable<any> {
    const url = `${this.baseUrl}charts`;
    const params = new HttpParams()
      .set('summaryId', summaryId.toString())
      .set('colorType', colorType)
      .set('dataUnit', dataUnit)
      .set('dataType', dataType)
      .set('title', title)

    return this.http.get(url, { params });

  }
  getChartPercentage(summaryId: number, colorType: ColorType, dataUnit: ChartDataUnit, dataType: ChartDataType): Observable<any> {
    const url = `${this.baseUrl}charts-percentage`;
    const params = new HttpParams()
      .set('summaryId', summaryId.toString())
      .set('colorType', colorType)
      .set('dataUnit', dataUnit)
      .set('dataType', dataType)

    return this.http.get(url, { params });
  }
  getChartCount(summaryId: number, colorType: ColorType, dataUnit: ChartDataUnit, dataType: ChartDataType): Observable<any> {
    const url = `${this.baseUrl}charts-count`;
    const params = new HttpParams()
      .set('summaryId', summaryId.toString())
      .set('colorType', colorType)
      .set('dataUnit', dataUnit)
      .set('dataType', dataType)

    return this.http.get(url, { params });

  }


  /*  getChartData(numbers: number[]): Observable<any> {
     return this.http.post<any>(this.apiUrl, numbers);
   } */
}
