import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  HostListener,
} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Page } from 'src/app/commons/models/page';
import { Product } from '../model/product';
import { ProductSharedDataService } from '../services/product-shared-data.service';
import { ProductService } from '../services/product.service';
import { UtilService } from 'src/app/services/util.service';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { Toast } from 'primeng/toast';
import { Formula } from '../product/product.component';
import { AppConstants } from 'src/app/commons/app-constants';
import { SHARED_MODULES } from '../../shared-imports';
import { ProductComponent } from '../product/product.component';
import { EncryptionService } from 'src/app/services/encryption.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css'],
  providers: [ToastMessagesService],
  standalone: true,
  imports: [SHARED_MODULES, ProductComponent]
})
export class ProductListComponent implements OnInit {
  @ViewChild('globalFiterInput', { static: false })
  globalFiterInput!: ElementRef;
  productSidebarVisible: boolean = false;
  isAddLoadingSiteFromContainer: boolean = false;
  formHeader!: string;
  formSubHeader!: string;
  showNameColumn = true;
  showContactPersonColumn = true;
  showGstColumn = true;
  showCityColumn = true;
  showPhoneColumn = false;
  showEmailColumn = false;
  protected items!: MenuItem[];
  protected productSpeedDialItems!: MenuItem[];
  home!: MenuItem;
  rows = 5;
  products!: Product[];
  multiSortMeta!: any[];
  totalRecords: number = 0;
  first: number = 0;
  last: number = 0;
  rowsPerPageOptions = [5, 10, 20]; // set the options for the number of rows per page
  @ViewChild('dt') table!: Table;
  cols!: any[];
  isMobile = false;

  _selectedColumns!: any[];
  selectedProducts: Product[] = [];
  tableStyle: any;
  formulas: any[];
  loading: boolean = false;

  tableStyleOptions: any;

  constructor(
    private productService: ProductService,
    private toastMessagesService: ToastMessagesService,
    private confirmationService: ConfirmationService,
    private productSharedDataService: ProductSharedDataService,
    private router: Router,
    private titleService: Title,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private encryptionService: EncryptionService
  ) {
    this.formulas = [
      { value: AppConstants.HOPPUS_NAME, key: 1 },
      { value: AppConstants.RECTANGLE_PRISM_NAME, key: 2 },
    ];
    this.setPageTitle();
    this.isMobile = this.utilService.isMobile();
    this.tableStyle = this.utilService.getTableSize();
  }

  ngOnInit() {
    this.initTableColumns();
    this.populateBreadCrumb();
    this.initSpeedDial();
    this.initTableStyle();
  }

  sortProducts(event: any) {
    this.loading = true;
    const pageNumber = Math.floor(event.first / event.rows) + 1;
    console.log(`Page Number ${pageNumber}`);
    console.log(`Event List ${JSON.stringify(event)}`);
    let params = new HttpParams();
    let sortOrder = '';
    console.log(`customSort field ${[event!.field!]}`);
    // console.log(`customSort data ${[event!.data!]}`)
    console.log(`customSort mode ${[event!.mode!]}`);
    console.log(`customSort order ${[event!.order!]}`);
    console.log(
      `customSort multiSortMeta ${JSON.stringify([event!.multiSortMeta!])}`
    );
    console.log(`multiSortMeta ${event!.multiSortMeta?.length}`);
    const multiSortLength = event!.multiSortMeta?.length;
    params = params.set('size', event.rows);
    params = params.set('page', pageNumber - 1);
    for (var i = 0; i < multiSortLength!; i++) {
      if (event!.multiSortMeta![i].order === -1) {
        sortOrder = 'DESC';
      } else {
        sortOrder = 'ASC';
      }
      console.log(`field ${event!.multiSortMeta![i].field}`);
      console.log(`order ${event!.multiSortMeta![i].order}`);

      if (multiSortLength! > 0) {
        console.log(`in if`);
        params = params.set(
          'sort',
          event!.multiSortMeta![i].field + ',' + sortOrder
        );
      }
    }
    console.log(`Params ${params.toString()}`);
    this.products = [];
    // filter implementation
    const filters = event.filters;
    if (event.filters.hasOwnProperty('name')) {
      console.log(`filters ${JSON.stringify(filters.name.length)}`);
      for (var i = 0; i < filters.name.length; i++) {
        if (filters.name[i].value) {
          params = params.set('name', filters.name[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('hsCode')) {
      console.log(`filters ${JSON.stringify(filters.hsCode.length)}`);
      for (var i = 0; i < filters.hsCode.length; i++) {
        if (filters.hsCode[i].value) {
          params = params.set('hsCode', filters.hsCode[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('description')) {
      console.log(`filters ${JSON.stringify(filters.description.length)}`);
      for (var i = 0; i < filters.description.length; i++) {
        if (filters.description[i].value) {
          params = params.set('description', filters.description[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('formula')) {
      console.log(`filters for forumula ${JSON.stringify(filters.formula)}`);

      let formulaValues: string[] = [];

      for (var i = 0; i < filters.formula.length; i++) {
        if (filters.formula[i].value) {
          for (var j = 0; j < filters.formula[i].value.length; j++) {
            const param = filters.formula[i].value[j].key;
            console.log(`filters for forumula ${JSON.stringify(filters.formula[i].value[j].key)}`);
            formulaValues.push(param);
          }
        }
      }

      if (formulaValues.length > 0) {
        params = params.set('formula', formulaValues.join(','));
      }
    }


    this.productService.getProductsList(params).subscribe(
      (response: Page<Product>) => {
        this.products = response.content;
        this.first =
          response.pageable.pageNumber * response.pageable.pageSize + 1;
        const max =
          response.pageable.pageNumber * response.pageable.pageSize +
          response.pageable.pageSize;
        this.last = max < response.totalElements ? max : response.totalElements;
        this.totalRecords = response.totalElements;
      },
      (error) => { },
      () => {
        this.loading = false;
      }
    );
  }

  globalFilter(value: any, table: Table) {
    console.log(`globalFilter  ${JSON.stringify(value)}`);
    const globalFilter = this.globalFiterInput.nativeElement.value;
    console.log(`value ${globalFilter}`);
    if (globalFilter) {
      let params = new HttpParams();
      params = params.append('q', globalFilter);
      this.productService.getProductsListGlobalFilter(params).subscribe(
        (response: Page<Product>) => {
          this.products = response.content;
          this.first =
            response.pageable.pageNumber * response.pageable.pageSize + 1;
          const max =
            response.pageable.pageNumber * response.pageable.pageSize +
            response.pageable.pageSize;
          this.last =
            max < response.totalElements ? max : response.totalElements;
          this.totalRecords = response.totalElements;
        },
        (error) => { },
        () => { }
      );
    } else {
      table.clear();
    }
  }

  clear(table: Table) {
    table.clear();
  }

  clearAllFilter(table: Table, field: HTMLInputElement) {
    field.value = ''; // cl
    table.clear();
  }

  initTableColumns() {
    this._selectedColumns = ['HS Code', 'Formula'];
    this.cols = ['HS Code', 'Description', 'Formula'];
  }

  deleteRows() {
    const ids: number[] = this.selectedProducts.map(
      (product) => product.productId
    );

    if (ids.length === 0) {
      this.toastMessagesService.showWarningMessage(
        'No Selection',
        'No rows selected for deletion.'
      );
      return;
    }

    this.confirmationService.confirm({
      header: 'Delete Product',
      message: 'Are you sure you want to delete this product?',
      key: 'deleteConfirmation',
      accept: () => {
        /* this.productService.deleteProducts(ids).subscribe(
          () => {
            this.toastMessagesService.showInfoMessage(
              'Success',
              'Product Deleted Successfully'
            );
            this.refreshTable();
          },
          () => {
            this.toastMessagesService.showErrorMessage(
              'Operation Unsuccessful',
              'An error occurred. Please try again.'
            );
          }
        ); */
        this.productService.deleteProducts(ids).subscribe(
          () => {
            this.toastMessagesService.showInfoMessage(
              'Success',
              'Product Deleted Successfully'
            );
            this.refreshTable();
          },
          (error) => {
            if (error.status === 409) {
              console.error(`Error while deleting is ${JSON.stringify(error)}`);
              // Assuming 409 is the HTTP status code for 'Conflict'
              // Show PrimeNG Confirm Dialog
              this.confirmationService.confirm({
                message: 'Warning: Sorry, this product is associated with other items. Please remove the associations before attempting to delete.',
                accept: () => {
                  // User confirmed, proceed with force delete
                },
              });
            } else {
              this.toastMessagesService.showErrorMessage(
                'Operation Unsuccessful',
                'An error occurred. Please try again.'
              );
            }
          }
        );
      },
      reject: () => { }
    });
  }

  refreshTable() {
    this.productService
      .getProductsList()
      .subscribe((response: Page<Product>) => {
        this.products = response.content;
        this.first =
          response.pageable.pageNumber * response.pageable.pageSize + 1;
        const max =
          response.pageable.pageNumber * response.pageable.pageSize +
          response.pageable.pageSize;
        this.last = max < response.totalElements ? max : response.totalElements;
        this.totalRecords = response.totalElements;
        this.selectedProducts = [];
      });
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) => val.includes(col));
  }
  getSelectedProducts() {
    console.log(`Selected Products length is ${this.selectedProducts.length} 
     and \nData is ${JSON.stringify(this.selectedProducts)}`);
  }

  onRowSelect(event: any) {
    //this.messageService.add({ severity: 'info', summary: 'Product Selected', detail: event.data.name });
  }

  onRowClick(data: Product) {
    console.log(`onRowClick Data is ${JSON.stringify(data)}`);
    const id = "" + data.productId;
    // const encryptedId = btoa(id); // Encrypt the ID
    // this.router.navigate(['/edit/product', encryptedId]);

    this.encryptionService.encrypt(id).then((encryptedId) => {
      this.router.navigate(['/edit/product', encryptedId]);
    });
  }

  populateBreadCrumb() {
    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.items = [{ label: 'Products' }];
  }
  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = this.utilService.isMobile();
    console.log(`is mobile ${this.utilService.isMobile()}`);
    // this.tableStyle = this.utilService.getTableSize();
    this.initTableStyle();
  }

  initSpeedDial() {
    this.productSpeedDialItems = [
      {
        icon: 'pi pi-filter-slash',
        command: () => {
          this.globalFiterInput.nativeElement.value = '';
          this.table.clear();
        },
      },
      {
        icon: 'pi pi-search',
        command: () => {
          this.focusOnGlobalFilter();
        },
      },
      {
        icon: 'pi pi-trash',
        command: () => {
          this.deleteRows();
          //this.messageService.add({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
        },
      },
      {
        icon: 'pi pi-plus',
        command: () => {
          this.productSidebarVisible = true;
          // this.deleteRows()
          //this.messageService.add({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
        },
      },
    ];
  }

  focusOnGlobalFilter() {
    this.globalFiterInput.nativeElement.focus();
  }

  clearFilter(dt: any) {
    this.table.clear();
  }

  onProductSubmit(event: any) {
    this.table.reset();
  }

  getFormulaName(formulaNumber: number): string {
    if (formulaNumber === 1) return AppConstants.HOPPUS_NAME;
    else if (formulaNumber === 2) return AppConstants.RECTANGLE_PRISM_NAME;
    else return '';
    // return Formula[formulaNumber];
  }

  getFormulaNumber(formulaName: string): number {
    if (formulaName === AppConstants.HOPPUS_NAME) {
      console.log(
        `Formula Nameis ${formulaName}  = ${AppConstants.HOPPUS_NAME}`
      );
      return 1;
    } else if (formulaName === AppConstants.RECTANGLE_PRISM_NAME) {
      console.log(
        `Formula Nameis ${formulaName}  = ${AppConstants.RECTANGLE_PRISM_NAME}`
      );
      return 2;
    } else {
      console.log(`Else`);
      return 0;
    }
  }

  initTableStyle() {
    this.isMobile = this.utilService.isMobile();
    this.tableStyle = this.utilService.getTableSize();
    this.tableStyleOptions = this.utilService.getTableMinWidth();
  }
}
