import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Route, Router } from '@angular/router';
import { FileUpload } from 'primeng/fileupload';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { Container } from '../../container/model/container';
import { ContainerSummary } from '../../container/model/container-summary';
import { ContainerService } from '../../container/services/container.service';
import { BlPackingList } from '../model/bl-packing-list';
import { BlSummary } from '../model/bl-summary';
import { Measurement } from '../model/measurement';
import { Photos } from '../model/photos';
import { MeasurementSharedDataService } from '../services/measurement-shared-data.service';
import { MeasurementService } from '../services/measurement.service';
import { Meta } from '@angular/platform-browser';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';
import { AppConstants } from 'src/app/commons/app-constants';
import { MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { SHARED_MODULES } from '../../shared-imports';
import { ContainerPhotosComponent } from '../../container/container-photos/container-photos.component';
import { ViewHoppusMeasurementComponent } from '../../hoppus/view-hoppus-measurement/view-hoppus-measurement.component';
import {ViewHoppusSummaryComponent} from '../../hoppus/view-hoppus-summary/view-hoppus-summary.component';
// const CONTAINER_ID = 6;

@Component({
  selector: 'app-measurement-view',
  templateUrl: './measurement-view.component.html',
  styleUrls: ['./measurement-view.component.css'],
  providers: [ToastMessagesService, MessageService],
  standalone:true,
  imports:[SHARED_MODULES, ContainerPhotosComponent, ViewHoppusMeasurementComponent, ViewHoppusSummaryComponent]
})
export class MeasurementViewComponent implements OnInit, AfterViewInit {
  isLoading$ = new BehaviorSubject<boolean>(true);
  containerUUID: any;
  isPublic: boolean = false;
  containerNumber!: string;
  errorDialoagVisible = false;
  errorDialogHeader!: string;
  errorDialogContent!: string;

  constructor(
    private encryptedStorageService: EncryptedStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private metaTagService: Meta,
    private activatedRoute: ActivatedRoute,
    private toastMessageService: ToastMessagesService,
    private containerService: ContainerService
  ) {
    this.route.params.subscribe((params) => {
      this.containerUUID = params['containerUUID'];
    });
  }

  ngAfterViewInit(): void {
    // this.blSummary.dummyArrayHorizontalGrid = this.dummyArrayHorizontalGrid;
    // this.blSummaryInput.dummyArrayHorizontalGrid = this.dummyArrayHorizontalGrid;
  }

  /* ngOnInit(): void {
    this.isPublic = this.router.url.includes('public');
    console.log(`UUID from @INput is ${this.INPUT_CONTAINER_ID}`)
    if (this.isPublic) {
      this.route.params.subscribe(params => {
        // this.containerUUID = params['containerUUID'];
       // this.INPUT_CONTAINER_ID = params['containerUUID'];
        console.log(`UUID is ${this.INPUT_CONTAINER_ID}`)

        this.containerService.getContainerIDFromUUID(this.INPUT_CONTAINER_ID).subscribe(
          (data) => {
            console.log(`Container ID is ${JSON.stringify(data)}`);
            this.addOGTags(data);
            this.containerNumberFromUUID = data;
          },
          (error) => {
            this.toastMsgService.showErrorMessage('Error', 'Error while fetching data');
          },
          () => { }
        )
      });
    } else { // internal app use no public
      this.INPUT_CONTAINER_ID = this.measurementSharedDataService.getContainerIdStorage();
      // this.dummyImages();
      this.containerService.getContainerNumberFromID(this.INPUT_CONTAINER_ID).subscribe
        (
          (response: any) => {
            this.containerNumberFromUUID = response;
            console.log(`Container Numebr is ${JSON.stringify(response)}`)
            console.log(`Container Numebr is ${this.containerNumberFromUUID}`);
          },
          (error: any) => { },
          () => { }

        )
      if (this.INPUT_CONTAINER_ID) {
        this.getMeasurement(this.INPUT_CONTAINER_ID);
      } else {
        this.getQueryParameter();
        this.getMeasurement(this.INPUT_CONTAINER_ID);
      }
    }

  } */

  ngOnInit(): void {
    this.initOGTags();
    this.isPublic = this.router.url.includes('public');
    this.isLoading$.next(true);
    if (this.isPublic) {
      this.handlePublicRoute();
    } else {
      this.handlePrivateRoute();
    }
  }

  handlePublicRoute() {
    this.activatedRoute.params.subscribe((params) => {
      // const containerUUID = params['containerUUID'];
      this.containerUUID = params['containerUUID'];
      if (!this.containerUUID) {
        this.toastMessageService.showErrorMessage(
          'Error',
          'Container UUID not found'
        );
        // Handle the error, for example, navigate to a default route
        return;
      }

      this.containerService
        .getContainerIDFromUUID(this.containerUUID)
        .subscribe(
          (data: any) => {
            console.log(`data is ${JSON.stringify(data)}`);
            this.containerNumber = data.containerNumber;
          },
          (error: HttpErrorResponse) => {
            console.log(`error *&&^^&* ${JSON.stringify(error)}`)
            this.errorDialoagVisible = true;
            this.errorDialogHeader = 'Error Encountered';
            this.errorDialogContent =
              'Invalid container number provided.';
            this.isLoading$.next(false);
          },
          () => {
            this.isLoading$.next(false);
          }
        );
    });
  }

  handlePrivateRoute() {
    this.encryptedStorageService
      .getEncryptedDataFromStorage(AppConstants.MEASUREMENT)
      .subscribe(
        (data: any) => {
          console.log(`data is ${JSON.stringify(data)}`);
          this.containerNumber = data.containerNumber;
        },
        (error: any) => {},
        () => {
          this.isLoading$.next(false);
        }
      );
  }

  initOGTags() {
    this.metaTagService.updateTag({
      name: 'og:description',
      content:
        "Here's your timber packing list link from Lumberlinq. Click to view detailed packing lists, photos, and summary",
    });
  }

  /* private logInputContainerId(): void {
    console.log(`UUID from @Input is ${this.INPUT_CONTAINER_ID}`);
  }
  
  private handlePublicRoute(): void {
    this.route.params.subscribe(params => {
     // this.fetchContainerIDFromUUID();
    });
  }
  
  private handlePrivateRoute(): void {
    this.INPUT_CONTAINER_ID = this.measurementSharedDataService.getContainerIdStorage();
    this.fetchContainerNumberFromID();
  
    if (this.INPUT_CONTAINER_ID) {
      this.getMeasurement(this.INPUT_CONTAINER_ID);
    } else {
      this.getQueryParameter();
      this.getMeasurement(this.INPUT_CONTAINER_ID);
    }
  }
  
   
  private fetchContainerNumberFromID(): void {
    this.containerService.getContainerNumberFromID(this.INPUT_CONTAINER_ID).subscribe(
      (response: any) => {
        this.containerNumberFromUUID = response;
        console.log(`Container Number is ${JSON.stringify(response)}`);
        console.log(`Container Number is ${this.containerNumberFromUUID}`);
      }
    );
  }
  

  getMeasurement(containerID: number) {
    this.containerService.getByContainerId(containerID).subscribe(
      ((containerResponse: Container) => {
        this.container = containerResponse;

        this.fieldArray = this.container.measurements;
        console.log(`GetMeasurement is ${JSON.stringify(this.fieldArray)}`)
        this.getGirthSummary();
        this.getLengthSummary();
        this.blSummary.container = containerResponse;
        this.blPackingList.container = containerResponse;
      }),
      ((error: any) => {
        this.toastMsgService.showErrorMessage('Error', 'An occured while fetching data');
      }),
      (() => {

      }),
    )
  }

  getLengthSummary() {
    var count = 0;
    this.minLength = Math.min.apply(Math, this.fieldArray.map(o => o.grossLength!));
    this.maxLength = Math.max.apply(Math, this.fieldArray.map(o => o.grossLength!));
    console.log(`Minimum length is ${this.minLength}`);
    console.log(`Maximum length is ${this.maxLength}`);
    console.log(`&&`)
    for (var i = this.minLength; i <= this.maxLength; i += 5) {
      count = 0;
      for (var j = 0; j < this.fieldArray.length; j++) {

        if (i == this.fieldArray[j].grossLength) {
          count = count + 1;
          // console.log(`Length steps are ${i} ${this.fieldArray[j].girth} * ${this.fieldArray[j].length} ${count}`);
        }
        // console.log(`Length steps are ${i} ${this.fieldArray[j].girth} * ${this.fieldArray[j].length} ${count}`);
      }

      this.lengthSummary.push({ "length": i, "count": count })
      // console.log(`Length steps are ${i} ${count}`);
      // console.log(`Length steps are ${i}`);
    }
    this.blSummary.lengthSummary = this.lengthSummary;
    console.log(JSON.stringify(this.lengthSummary));
  }


  getGirthSummary() {
    console.log(`Girth Summary ${this.fieldArray.length}`)
    var count = 0;
    this.minGirth = Math.min.apply(Math, this.fieldArray.map(o => o.grossGirth!));
    this.maxGirth = this.roundUpGirth(Math.max.apply(Math, this.fieldArray.map(o => o.grossGirth!)));

    for (var i = this.minGirth; i < this.maxGirth; i += 10) { //parseInt(i / 10, 10) + 1 * 10;  
      count = 0;
      for (var j = 0; j < this.fieldArray.length; j++) {
        if (this.fieldArray[j].grossGirth! >= i && this.fieldArray[j].grossGirth! < i + 10) {
          count = count + 1;
        }
      }
      this.girthSummary.push({ "girth": `${i}-${i + 9}`, "count": count });

    }
    this.blSummary.girthSummary = this.girthSummary;
    console.log(JSON.stringify(this.girthSummary));
  }

  private roundUpGirth(girth: number): number {
    const num = 102;
    const mod = num % 100;
    const remainder = 10 - mod;
    let finalNumber = num + remainder;
    // console.log(`%%%%%%%% ${finalNumber}`);
    return finalNumber;
  }

  getQueryParameter() {
    this.route.queryParams.subscribe((params: Params) => {
      console.log(params); // Log the entire Params object

      // To access a specific query parameter, use the parameter name as a key
      const containerNumberParam = params['containerNumber'];
      if (containerNumberParam) {
        console.log('containerNumber:', containerNumberParam);
        this.INPUT_CONTAINER_ID = containerNumberParam;
      } else {
        // console.log('someParam not found');
      }
    });
  }

  @Input() inputPhotos: Photos = new Photos();
  fileName = '';
  progress = 0;

  photos = new Photos();

  dummyImages() {
    this.measurementService.getImages().then(images1 => {
      console.log(`Photos ${images1}`);
      this.inputPhotos.images = images1
    });
  }

  onFileUpload(event: any) {
    const fileUpload: FileUpload = event.files[0];
    const file: File = fileUpload.files[0];
    this.fileName = fileUpload.name || 'defaultFilename.txt'; // Provide a default value if necessary

    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener('progress', (event: ProgressEvent) => {
      if (event.lengthComputable) {
        this.progress = Math.round((event.loaded / event.total) * 100);
      }
    });

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        console.log('File uploaded successfully');
      }
    };

    xhr.open('POST', fileUpload.url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.send(file);
  }

  private addOGTags(containerUUID: any) {
    console.log(`Add OT Tags ${JSON.stringify(containerUUID.containerUUID) }`);
    this.measurementService.getContainerOTTags(containerUUID.containerUUID).subscribe
      (
        (data) => {
          console.log(`Container OT Tags Response is ${JSON.stringify(data)}`);
          this.meta.addTag({ property: 'og:title', content: data.title });
          this.meta.addTag({ property: 'og:description', content: data.description });
          this.meta.addTag({ property: 'og:image', content: data.image });
        },
        (error) => { },
        () => { }
      )

  } */
}
