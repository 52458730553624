import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Formula } from '../../product/product/product.component';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { SHARED_MODULES } from '../../shared-imports';
import { BlPhotosComponent } from '../../measurement/measurement-view/bl-photos/bl-photos.component';
import { BlVolumetricPackingListComponent } from '../bl-volumetric-packing-list/bl-volumetric-packing-list.component';
import { ViewHoppusMeasurementComponent } from '../../hoppus/view-hoppus-measurement/view-hoppus-measurement.component';
import { ViewHoppusSummaryComponent } from '../../hoppus/view-hoppus-summary/view-hoppus-summary.component';
import { BlVolumetricSummaryComponent } from '../bl-volumetric-summary/bl-volumetric-summary.component';

export enum VIEW_MODE {
  public,
  private,
}

@Component({
  selector: 'app-bill-of-lading-main',
  templateUrl: './bill-of-lading-main.component.html',
  styleUrls: ['./bill-of-lading-main.component.css'],
  standalone:true,
  imports:[SHARED_MODULES, BlPhotosComponent, BlVolumetricPackingListComponent,ViewHoppusMeasurementComponent, ViewHoppusSummaryComponent, BlVolumetricSummaryComponent]
})
export class BillOfLadingMainComponent
  implements OnInit, OnDestroy, AfterViewInit
{  Formula = Formula;

  @Input() containerId!: number;
  @Input() containerUUID!: any;
  public loadingSubject = new BehaviorSubject<boolean>(false);



  @Input() formula!: Formula;

  @Input() viewMode!: VIEW_MODE;

  mode = VIEW_MODE.private;

  isPublicMode = false;

  constructor(private router: Router) {
    const currentUrl = this.router.url;
    console.log(`Current Url is ${currentUrl}`);
    if (currentUrl.includes('/public/view')) {
      this.isPublicMode = true;
      this.mode = VIEW_MODE.public;
    }
  }

  ngOnInit(): void {
    //throw new Error('Method not implemented.');
  }
  ngOnDestroy(): void {
    //throw new Error('Method not implemented.');
  }
  ngAfterViewInit(): void {
    //throw new Error('Method not implemented.');
    console.log(`Container Id ${this.containerId}`);
    console.log(`formula Id** ${this.formula}`);
  }

  @Input()
  set loading(value: boolean) {
    this.loadingSubject.next(value);
  }

  get loading(): boolean {
    return this.loadingSubject.getValue();
  }
}
