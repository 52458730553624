import { HttpClient } from '@angular/common/http';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, fromEvent, debounceTime, filter } from 'rxjs';
import { UtilService } from 'src/app/services/util.service';
import { Measurement } from '../../measurement/model/measurement';
import { Container } from '../model/container';
import { ContainerService } from '../services/container.service';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';
import { AppConstants } from 'src/app/commons/app-constants';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-container-packing-list',
  templateUrl: './container-packing-list.component.html', 
  styleUrls: ['./container-packing-list.component.css'],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class ContainerPackingListComponent  implements OnInit, OnDestroy {

  dummyArrayHorizontalGrid = ['Helo'];
  container!: Container;
  measurements: Measurement[] = [];
  //data: any[] = [];
  loading!: boolean;

  totalRecords: number = 0; // total number of records in the dataset
  limit: number = 50; // number of records to fetch per request
  offset: number = 0; // current offset in the dataset
  scrollSubscription: Subscription | undefined; // subscription to scroll event
  @Input() containerId!: any;
  isPublic!: boolean;
  containerUUID: any;
  tableStyle!: any; 
  isMobile!: boolean;

  constructor(
    private httpClient: HttpClient,
    private containerService: ContainerService,
    private router: Router,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private encryptedStorageService: EncryptedStorageService
  ) {
  }

  ngOnInit(): void {
    this.isPublic = this.router.url.includes('public');
    console.log(`this.isPublic ${this.isPublic}`)
    if (this.isPublic) {
      this.route.params.subscribe(params => {
        this.containerUUID = params['containerUUID'];
        console.log(`UUID is ${this.containerUUID}`)
        if(this.containerUUID){
          this.containerId = this.containerUUID;
        }
        this.loadPublicData();
        this.scrollSubscription = fromEvent(window, 'scroll').pipe(
          debounceTime(0),
          filter(() => !this.loading && this.offset < this.totalRecords && this.isScrolledToBottom())
        ).subscribe(() => {
          this.offset += this.limit;
          this.loadPublicData();
        });
      });
    }
    else { // for internal users not for public
      console.log(`IN  Private Data`)
      this.encryptedStorageService.getEncryptedDataFromStorage(AppConstants.MEASUREMENT).subscribe
      ((data:any)=>{
        console.log(`£££££ ${JSON.stringify(data.containerId)}`)
        this.containerId = data.containerId;
        this.loadPrivateData();
      },
      (error:any)=>{},
      ()=>{}),
      
      this.scrollSubscription = fromEvent(window, 'scroll').pipe(
        debounceTime(0),
        filter(() => !this.loading && this.offset < this.totalRecords && this.isScrolledToBottom())
      ).subscribe(() => {
        this.offset += this.limit;
        this.loadPrivateData();
      });
    }
    this.tableStyle = this.utilService.getTableSize();
  }


 /*  isScrolledToBottom(): boolean {
    const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + window.pageYOffset;
    return windowBottom >= documentHeight;
  } */
  isScrolledToBottom(threshold: number = 200): boolean {
    const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight) - threshold;
    const windowBottom = windowHeight + window.pageYOffset;
    return windowBottom >= documentHeight;
}


  loadPrivateData() {
    this.loading = true;
    // fetch data from API
    this.containerService.fetchData(this.containerId, this.offset, this.limit).subscribe(
      (response) => {
        this.measurements = this.measurements.concat(response.data);
        this.totalRecords = response.totalRecords;
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }


  loadPublicData() {
    this.loading = true;
    // fetch data from API
    this.containerService.fetchDataPublicVirtualScroll(this.containerId, this.offset, this.limit).subscribe(
      (response) => {
        this.measurements = this.measurements.concat(response.data);
        console.log(`*** *** *** *** ${JSON.stringify(response.data)}`);
        this.totalRecords = response.totalRecords;
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }
  onScroll(event: any) {
    if (this.loading) {
      return;
    }
    if (this.offset >= this.totalRecords) {
      return;
    }
    this.offset += this.limit;
    this.loadPrivateData();
  }

  ngOnDestroy(): void {
    // unsubscribe from scroll event
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = this.utilService.isMobile();
    console.log(`is mobile ${this.utilService.isMobile()}`);
    this.tableStyle = this.utilService.getTableSize();
  }
}

