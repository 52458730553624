import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app/commons/app-constants';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { ContainerService } from 'src/app/modules/container/services/container.service';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';

@Component({
  selector: 'app-sawn-timber-view-photos',
  templateUrl: './sawn-timber-view-photos.component.html',
  styleUrls: ['./sawn-timber-view-photos.component.css'],
  providers: [ToastMessagesService],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class SawnTimberViewPhotosComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() data: any;
  showProgressbar = false;

  images!: any[];

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5,
    },
    {
      breakpoint: '960px',
      numVisible: 4,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
    },
  ];
  containerId!: number;
  isPublic = false;
  containerUUID!: string | null;

  constructor(
    private containerService: ContainerService,
    private toastMessageService: ToastMessagesService,
    private encryptedStorageService: EncryptedStorageService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    console.log(`Url is ${this.router.url}`);
    if (this.router.url.includes('/public/view')) {
      console.log('URL contains /public/view');
      this.isPublic = true;
      this.route.paramMap.subscribe((params) => {
        let containerUUID = params.get('containerUUID');
        console.log(containerUUID); // Use the containerUUID for your logic here.
        this.containerUUID = containerUUID;
      });
    } else {
      console.log('URL does not contain /public/view');
      this.isPublic = false;
    }
  }

  ngAfterViewInit(): void {
    this.showProgressbar = true;
    this.loadImages().finally(() => (this.showProgressbar = false));
  }

  async loadImages() {
    try {
      if (!this.isPublic) {
        const data: any = await this.encryptedStorageService
          .getEncryptedDataFromStorage(AppConstants.VIEW)
          .toPromise();
        console.log(`Data from Encrypted Storage is ${JSON.stringify(data)}`);
        this.data = data;
        const response: any = await this.containerService
          .getContainerImages(this.data.containerId)
          .toPromise();
        console.log(`Response of Images are ${JSON.stringify(response)}`);
        this.images = response;
      }else{

        const response: any = await this.containerService
        .getContainerImagesByContainerUUID(this.containerUUID)
        .toPromise();
      console.log(`Response of Images are ${JSON.stringify(response)}`);
      this.images = response;
      }
    } catch (error) {
      console.log('Error occured:', error);
      // Handle error
    }
  }

  ngOnDestroy(): void {
    // throw new Error('Method not implemented.');

   
  }
}
