import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

export interface AppConfig {
    inputStyle: string;
    colorScheme: string;
    theme: string;
    ripple: boolean;
    menuMode: string;
    scale: number;
}

interface LayoutState {
    staticMenuDesktopInactive: boolean;
    overlayMenuActive: boolean;
    profileSidebarVisible: boolean;
    configSidebarVisible: boolean;
    staticMenuMobileActive: boolean;
    menuHoverActive: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class AppConfigService {
    private _config = new BehaviorSubject<AppConfig>({
        ripple: false,
        inputStyle: 'outlined',
        menuMode: 'static',
        colorScheme: 'light',
        theme: 'lara-light-indigo',
        scale: 14,
    });

    readonly config$ = this._config.asObservable();

    state: LayoutState = {
        staticMenuDesktopInactive: false,
        overlayMenuActive: false,
        profileSidebarVisible: false,
        configSidebarVisible: false,
        staticMenuMobileActive: false,
        menuHoverActive: false,
    };

    private configUpdate = new Subject<AppConfig>();
    private overlayOpen = new Subject<any>();

    configUpdate$ = this.configUpdate.asObservable();
    overlayOpen$ = this.overlayOpen.asObservable();

    constructor() {
        this.config$.subscribe(config => {
            if (this.updateStyle(config)) {
                // this.changeTheme();
            }
            // this.changeScale(config.scale);
            this.onConfigUpdate();
        });
    }

    updateStyle(config: AppConfig) {
        return (
            config.theme !== this._config.value.theme ||
            config.colorScheme !== this._config.value.colorScheme
        );
    }

    showConfigSidebar() {
      this.state.configSidebarVisible = true;
  }

    // ... (rest of the methods remain the same)

    onConfigUpdate() {
        this.configUpdate.next(this._config.value);
    }

    // Method to update the configuration
    updateConfig(newConfig: AppConfig) {
        this._config.next(newConfig);
    }
}
