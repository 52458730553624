import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SHARED_MODULES } from '../../shared-imports';
import { SubscriptionManagementComponent } from '../subscription-management/subscription-management.component';
import { TransactionHistoryComponent } from '../transaction-history/transaction-history.component';
import { SubscriptionOptedComponent } from '../subscription-opted/subscription-opted.component';
  
@Component({
  selector: 'app-subscription-view-main',
  templateUrl: './subscription-view-main.component.html',
  styleUrls: ['./subscription-view-main.component.css'],
  standalone: true,
  imports: [SHARED_MODULES, SubscriptionManagementComponent, TransactionHistoryComponent, SubscriptionOptedComponent]
})
export class SubscriptionViewMainComponent implements OnInit {
  selectedTabIndex: number = 0;

  constructor(private titleService: Title, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.setPageTitle();
    this.moveToPackageSelectionTab();
  }

  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      //  this.formHeader = data['header'];
      // this.formSubHeader = data['subHeader'];
    });
  }

  moveToPackageSelectionTab(){
    console.log(`moveToPackageSelectionTab `);
    
    this.route.fragment.subscribe(fragment => {
        console.log(`Received fragment: ${fragment}`);
      if(fragment){
        const match = fragment.match(/tab(\d)/);
        if (match && match[1]) {
            const tabIndex = +match[1] - 1;
            console.log(`Parsed tabIndex: ${tabIndex}`);
            
            if (!isNaN(tabIndex)) {
                this.selectedTabIndex = tabIndex;
            }
        } else {
            console.log(`No valid tab index found in the fragment.`);
        }
      }
    });
}

}
