import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { HttpParams } from '@angular/common/http';
import { Page } from 'src/app/commons/models/page';
import { LoadingSite } from '../model/loading-site';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class LoadingSiteService {

  constructor(private http: HttpClient) { }

  getData(): Observable<LoadingSite> {
    return this.http.get<LoadingSite>(AppConstants.API_URL + 'api/v1/loading-sites');
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  postData(data: LoadingSite): Observable<LoadingSite> {
    return this.http.post<LoadingSite>(AppConstants.API_URL + 'api/v1/loading-site', data, { responseType: 'json' });
  }

  getByLoadingSiteName(name: string): Observable<any | undefined> {
    const response = this.http.get(AppConstants.API_URL + `api/v1/check-loading-site-name?name=${name}`, httpOptions);
    console.log(`getByLoadingSiteName Response is ${JSON.stringify(response)}`)
    return response;
  }


  getListLoadingSites(httpParams?: HttpParams): Observable<Page<LoadingSite>> {
    let url = `${AppConstants.API_URL}api/v1/list-loading-sites?`;
    if (httpParams) {
      url = url + httpParams;
    }
    console.log(`url is ${url}`)
    return this.http.get<Page<LoadingSite>>(url);
  }

  getListLoadingSitesGlobalFilter(httpParams?: HttpParams): Observable<Page<LoadingSite>> {
    let url = `${AppConstants.API_URL}api/v1/loading-sites/search?`;
    if (httpParams) {
      url = url + httpParams;
    }
    console.log(`url is ${url}`)
    return this.http.get<Page<LoadingSite>>(url);
  }  ///buyers/{id}

  deleteListLoadingSites(id: any, forceDelete: boolean = false): Observable<string> {
    let url = `${AppConstants.API_URL}api/v1/loading-sites/${id}?forceDelete=${forceDelete}`;
    console.log(`url is ${url}`)
    return this.http.delete<string>(url);
  }

  getLoadingSiteById(id: any): Observable<LoadingSite> {
    const url = AppConstants.API_URL + 'api/v1/loading-sites/' + id;
    console.log(`getLoadingSiteById Url is ${url}`);
    return this.http.get<LoadingSite>(url);
  }
}
