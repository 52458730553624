import { HttpParams } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  HostListener,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Page } from 'src/app/commons/models/page';
import { Buyer } from '../model/buyer';
import { BuyerSharedDataService } from '../services/buyer-shared-data.service';
import { BuyerService } from '../services/buyer.service';
import { UtilService } from 'src/app/services/util.service';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { SHARED_MODULES } from '../../shared-imports';
import { BuyerComponent } from '../buyer/buyer.component';
import { EncryptionService } from 'src/app/services/encryption.service';

@Component({
  selector: 'app-buyers-list',
  templateUrl: './buyers-list.component.html',
  styleUrls: ['./buyers-list.component.css'],
  providers: [ToastMessagesService],
  standalone: true,
  imports: [SHARED_MODULES, BuyerComponent]
})
export class BuyersListComponent implements OnInit {

  @ViewChild('globalFiterInput', { static: false }) globalFiterInput!: ElementRef;
  buyerSidebarVisible: boolean = false;
  isAddLoadingSiteFromContainer: boolean = false;
  loading: boolean = false;

  formHeader!: string;
  formSubHeader!: string;
  showNameColumn = true;
  showContactPersonColumn = true;
  showGstColumn = true;
  showCityColumn = true;
  showPhoneColumn = false;
  showEmailColumn = false;
  protected items!: MenuItem[];
  protected buyerSpeedDialItems!: MenuItem[];
  home!: MenuItem;
  rows = 5;
  buyers!: Buyer[];
  multiSortMeta!: any[];
  totalRecords: number = 0;
  first: number = 0;
  last: number = 0;
  rowsPerPageOptions = [5, 10, 20]; // set the options for the number of rows per page
  // @ViewChild('globalFiterInput') globalFiterInput!: ElementRef;
  @ViewChild('dt') table!: Table;
  cols!: any[];

  _selectedColumns!: any[];
  selectedBuyers: Buyer[] = [];
  isMobile = false;
  tableStyle: any;
  tableStyleOptions: any;

  constructor(
    private buyerService: BuyerService,
    private toastMessageService: ToastMessagesService,
    private confirmationService: ConfirmationService,
    private buyerSharedData: BuyerSharedDataService,
    private router: Router,
    private titleService: Title,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private encryptionService: EncryptionService
  ) {
    this.setPageTitle();
    this.initTableStyle();
  }

  initTableStyle() {
    this.isMobile = this.utilService.isMobile();
    this.tableStyle = this.utilService.getTableSize();
    this.tableStyleOptions = this.utilService.getTableMinWidth();
  }

  ngOnInit() {
    this.initTableColumns();
    this.populateBreadCrumb();
    /*  this.buyerService.getListBuyers().subscribe(
       (data: any) => {
         this.buyers = data.content;
       },
       (error) => { },
       () => { }) */
    this.initSpeedDial();
  }

  sortBuyers(event: any) {
    //const globalFilter = this.globalFiterInput.nativeElement.value;
    /* console.log(`value ${globalFilter}`);
    console.log(`%%% ${globalFilter}`) */
    this.loading = true;
    const pageNumber = Math.floor(event.first / event.rows) + 1;
    console.log(`Page Number ${pageNumber}`);
    console.log(`Event List ${JSON.stringify(event)}`);
    let params = new HttpParams();
    let sortOrder = '';
    console.log(`customSort field ${[event!.field!]}`);
    // console.log(`customSort data ${[event!.data!]}`)
    console.log(`customSort mode ${[event!.mode!]}`);
    console.log(`customSort order ${[event!.order!]}`);
    console.log(
      `customSort multiSortMeta ${JSON.stringify([event!.multiSortMeta!])}`
    );
    console.log(`multiSortMeta ${event!.multiSortMeta?.length}`);
    const multiSortLength = event!.multiSortMeta?.length;
    params = params.set('size', event.rows);
    params = params.set('page', pageNumber - 1);
    for (var i = 0; i < multiSortLength!; i++) {
      if (event!.multiSortMeta![i].order === -1) {
        sortOrder = 'DESC';
      } else {
        sortOrder = 'ASC';
      }
      console.log(`field ${event!.multiSortMeta![i].field}`);
      console.log(`order ${event!.multiSortMeta![i].order}`);

      if (multiSortLength! > 0) {
        console.log(`in if`);
        params = params.set(
          'sort',
          event!.multiSortMeta![i].field + ',' + sortOrder
        );
      }
    }
    console.log(`Params ${params.toString()}`);
    this.buyers = [];
    // filter implementation
    const filters = event.filters;
    if (event.filters.hasOwnProperty('name')) {
      console.log(`filters ${JSON.stringify(filters.name.length)}`);
      for (var i = 0; i < filters.name.length; i++) {
        if (filters.name[i].value) {
          params = params.set('name', filters.name[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('contactPerson')) {
      console.log(`filters ${JSON.stringify(filters.contactPerson.length)}`);
      for (var i = 0; i < filters.contactPerson.length; i++) {
        if (filters.contactPerson[i].value) {
          params = params.set('contactPerson', filters.contactPerson[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('city')) {
      console.log(`filters ${JSON.stringify(filters.city.length)}`);
      for (var i = 0; i < filters.city.length; i++) {
        if (filters.city[i].value) {
          params = params.set('city', filters.city[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('country')) {
      console.log(`filters ${JSON.stringify(filters.country.length)}`);
      for (var i = 0; i < filters.country.length; i++) {
        if (filters.country[i].value) {
          params = params.set('country', filters.country[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('state')) {
      console.log(`filters ${JSON.stringify(filters.state.length)}`);
      for (var i = 0; i < filters.state.length; i++) {
        if (filters.state[i].value) {
          params = params.set('state', filters.state[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('gst')) {
      console.log(`filters ${JSON.stringify(filters.gst.length)}`);
      for (var i = 0; i < filters.gst.length; i++) {
        if (filters.gst[i].value) {
          params = params.set('gst', filters.gst[i].value);
        }
      }
    }

    this.buyerService.getListBuyers(params).subscribe(
      (response: Page<Buyer>) => {
        this.buyers = response.content;
        // console.log(`Buyer object is ${JSON.stringify(buyers1)}`)
        this.first =
          response.pageable.pageNumber * response.pageable.pageSize + 1;
        const max =
          response.pageable.pageNumber * response.pageable.pageSize +
          response.pageable.pageSize;
        this.last = max < response.totalElements ? max : response.totalElements;
        this.totalRecords = response.totalElements;
      },
      (error) => { },
      () => {
        this.loading = false;
      }
    );
  }

  globalFilter(value: any, table: Table) {
    console.log(`globalFilter  ${JSON.stringify(value)}`);
    const globalFilter = this.globalFiterInput.nativeElement.value;
    console.log(`value ${globalFilter}`);
    if (globalFilter) {
      let params = new HttpParams();
      params = params.append('q', globalFilter);
      this.buyerService.getBuyersGlobalFilter(params).subscribe(
        (response: Page<Buyer>) => {
          this.buyers = response.content;
          // console.log(`Buyer object is ${JSON.stringify(buyers1)}`)
          this.first =
            response.pageable.pageNumber * response.pageable.pageSize + 1;
          const max =
            response.pageable.pageNumber * response.pageable.pageSize +
            response.pageable.pageSize;
          this.last =
            max < response.totalElements ? max : response.totalElements;
          this.totalRecords = response.totalElements;
        },
        (error) => { },
        () => { }
      );
    } else {
      table.clear();
    }
  }

  clearAllFilter(table: Table, field: HTMLInputElement) {
    field.value = ''; // cl
    table.clear();
  }

  clear(table: Table) {
    table.clear();
  }

  initTableColumns() {
    this._selectedColumns = ['Contact Person', 'GST'];
    this.cols = ['Contact Person', 'GST', 'City', 'State', 'Country', 'Phone', 'Email'];
  }

  deleteRows() {
    if (!this.selectedBuyers.length) {
      this.toastMessageService.showWarningMessage('No Selection', 'No rows selected for deletion.');
      return;
    }

    const ids: number[] = this.selectedBuyers.map(buyer => buyer.buyerId);
    console.log(`IDS ARE ${ids}`);

    this.confirmationService.confirm({
      header: 'Delete Buyer',
      message: 'Are you sure you want to delete this buyer?',
      accept: () => {
        /* this.buyerService.deleteBuyers(ids).subscribe(
          (response) => {
            this.toastMessageService.showInfoMessage(
              'Success',
              'Buyer Deleted Successfully'
            );
            this.table.reset();
            this.loadBuyers();
          },
          (error) => {
            this.toastMessageService.showErrorMessage(
              'Operation Unsuccessful',
              'An error occurred. Please try again.'
            );
          }
        ); */

        this.buyerService.deleteBuyers(ids).subscribe(
          () => {
            this.toastMessageService.showInfoMessage(
              'Success',
              'Buyer Deleted Successfully'
            );
            this.loadBuyers();
          },
          (error) => {
            if (error.status === 409) {
              console.error(`Error while deleting is ${JSON.stringify(error)}`);
              // Assuming 409 is the HTTP status code for 'Conflict'
              // Show PrimeNG Confirm Dialog
              this.confirmationService.confirm({
                message: 'Warning: Deleting this buyer will also result in the permanent removal of all linked records, such as containers, measurements, and BL. This action is irreversible. Would you like to proceed?',
                accept: () => {
                  // User confirmed, proceed with force delete
                  this.buyerService.deleteBuyers(ids, true).subscribe(
                    () => {
                      this.toastMessageService.showInfoMessage(
                        'Success',
                        'Buyer Force-Deleted Successfully'
                      );
                      this.loadBuyers();
                    },
                    () => {
                      this.toastMessageService.showErrorMessage(
                        'Operation Unsuccessful',
                        'An error occurred. Please try again.'
                      );
                    }
                  );
                },
              });
            } else {
              this.toastMessageService.showErrorMessage(
                'Operation Unsuccessful',
                'An error occurred. Please try again.'
              );
            }
          }
        );
      },
    });
  }

  // Method to fetch buyers after delete operation
  loadBuyers() {
    this.loading = true;
    this.buyerService.getListBuyers().subscribe(
      (response: Page<Buyer>) => {
        this.buyers = response.content;
        this.first = response.pageable.pageNumber * response.pageable.pageSize + 1;
        const max = response.pageable.pageNumber * response.pageable.pageSize + response.pageable.pageSize;
        this.last = max < response.totalElements ? max : response.totalElements;
        this.totalRecords = response.totalElements;
      },
      (error: any) => { },
      () => {

        this.loading = false;
      }
    );
  }


  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) => val.includes(col));
  }
  getSelectedBuyers() {
    console.log(`Selected Buyers length is ${this.selectedBuyers.length} 
     and \nData is ${JSON.stringify(this.selectedBuyers)}`);
  }

  onRowSelect(event: any) {
    //this.messageService.add({ severity: 'info', summary: 'Product Selected', detail: event.data.name });
  }

  onRowClick(data: Buyer) {
    console.log(`onRowClick Data is ${JSON.stringify(data)}`);
    const id = "" + data.buyerId;
    // const encryptedId = btoa(id); // Encrypt the ID
    this.encryptionService.encrypt(id).then((encryptedId) => {
      this.router.navigate(['/edit/buyer', encryptedId]);
    }
      // this.router.navigate(['/edit/buyer', encryptedId]);
    );
  }

  populateBreadCrumb() {
    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.items = [{ label: 'Buyers' }];
    //this.items2 = [{ label: 'Buyers' },{ label: 'Buyers' },{ label: 'Buyers' },{ label: 'Buyers' },{ label: 'Buyers' }]
  }
  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = this.utilService.isMobile();
    this.tableStyle = this.utilService.getTableSize();
    console.log(`is mobile ${this.utilService.isMobile()}`);
    this.initTableStyle();
  }

  initSpeedDial() {
    this.buyerSpeedDialItems = [
      {
        icon: 'pi pi-filter-slash',
        command: () => {
          this.globalFiterInput.nativeElement.value = '';
          this.table.clear();
        },
      },
      {
        icon: 'pi pi-search',
        command: () => {
          this.focusOnGlobalFilter();
        },
      },
      {
        icon: 'pi pi-trash',
        command: () => {
          this.deleteRows()
          //this.messageService.add({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
        },
      },
      {
        icon: 'pi pi-plus',
        command: () => {
          this.buyerSidebarVisible = true;
          // this.deleteRows()
          //this.messageService.add({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
        },
      },

    ];
  }

  focusOnGlobalFilter() {
    this.globalFiterInput.nativeElement.focus();
  }

  clearFilter(dt: any) {
    this.table.clear()
  }

  onBuyerSubmit(event: Event) {
    this.table.reset();
  }
}
