<div>
  <h2>User Registration</h2>
  <form [formGroup]="registrationForm" (ngSubmit)="registerUser()">
    <input formControlName="firstName" placeholder="First Name">
    <input formControlName="lastName" placeholder="Last Name">
    <input formControlName="email" placeholder="Email">
    <input formControlName="password" type="password" placeholder="Password">
    <input formControlName="mobile" placeholder="Mobile">
    <!-- Uncomment if the company name is needed -->
    <!-- <input formControlName="companyName" placeholder="Company Name"> -->
    <button type="submit">Register</button>
  </form>
  <a href="http://localhost:8080/oauth2/authorization/facebook">Login with Facebook</a>
</div>


<div>
  <h2>Login </h2>
  <form [formGroup]="loginForm" (ngSubmit)="loginUser()">
    <input formControlName="email" placeholder="Email">
    <input formControlName="password" placeholder="Password" type="password">

    <button type="submit">Register</button>
  </form>
  <a href="http://localhost:8080/oauth2/authorization/facebook?state=12312312">Login with Facebook</a>
  <a [href]="facebookUrl">Login with Facebook22</a>
  <input type="button" (click)="onClickUrl()" value="Facebook">Facebook

</div>