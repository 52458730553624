import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app/commons/app-constants';
import { LogoutService } from 'src/app/services/logout.service';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-user-already-login',
  templateUrl: './user-already-login.component.html',
  styleUrls: ['./user-already-login.component.css'],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class UserAlreadyLoginComponent implements OnInit {
  response!: any;
  googleURL = AppConstants.GOOGLE_AUTH_URL_FORCE;
  microsoftURL = AppConstants.MICROSOFT_AUTH_URL_FORCE;
  linkedInURL = AppConstants.LINKEDIN_AUTH_URL_FORCE;
  displayDialog: boolean = true;
  url!: string;

  constructor(
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private logOutService: LogoutService
  ) {}
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const response = params['response'];
      this.response = JSON.parse(response);
      // Use the 'response' value here
    });
    if (this.response.method === 'google') {
      this.url = this.googleURL;
    } else if (this.response.method === 'linkedin') {
      this.url = this.linkedInURL;
    } else if (this.response.method === 'microsoft') {
      this.url = this.microsoftURL;
    } else {
    }
  }

  onLogin() {
    // Logic to log in and log out other sessions
    this.displayDialog = false;
    console.log(`URL @@@ ${this.url}`);
    window.location.href = this.url;
  }

  onCancel() {
    // Logic to cancel the login
    this.displayDialog = false;
  }

  httpRequest(url: string) {
    this.httpClient.get(url).subscribe(
      (response) => {
        console.log('Success:', response);
      },
      (error) => {
        console.log('Error:', error);
      }
    );
  }

  onForceYes() {
    console.log(`on Force Yes Clicked ${this.response.lh}` );
    const url = `${AppConstants.API_URL}api/v1/login/force-login/yes/${this.response.lh}`;
    this.httpClient.get(url).subscribe(
      (response: any) => {
        console.log(`Response on Force YES Login ${response}`);
        this.redirect();
      },
      (error: any) => {
        console.error(`Error on Force YES Login ${error}`);
      },
      () => {}
    );
  }

  onForceNo() {
    console.log(`on Force No Clicked ${this.response.lh}`);
    const url = `${AppConstants.API_URL}api/v1/login/force-login/no/${this.response.lh}`;
    this.httpClient.get(url).subscribe(
      (response: any) => {
        console.log(`Response on Force NO Login ${response}`); 
        this.logOutService.logout().subscribe(isSuccess => {
          if (isSuccess) {
            // Do something if logout is successful
            this.router.navigate(['login']);;
          } else {
            // Do something if logout failed
          }
        });
      },
      (error: any) => {
        console.error(`Error on Force NO Login ${error}`);
      },
      () => {}
    );
  }

  private redirect() {
    const redirectUrl = localStorage.getItem(
      AppConstants.REDIRECT_SNAPSHOT_URL
    );
    // console.log(`redirect url is ${redirectUrl}`);
    if (redirectUrl) {
      console.log(`redirect url is ${redirectUrl}`);
      this.router.navigateByUrl(redirectUrl);
      localStorage.removeItem(AppConstants.REDIRECT_SNAPSHOT_URL);
    } else {
      this.router.navigateByUrl(AppConstants.DASHBOARD);
    }
  }
}
