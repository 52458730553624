import { Component, HostListener } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { BlSummary } from '../model/bl-summary';
import { BillOfLadingSharedDataService } from '../service/bill-of-lading-shared-data.service';
import { BillOfLadingService } from '../service/bill-of-lading.service';
import { UtilService } from 'src/app/services/util.service';
import { Formula } from '../../product/product/product.component';
import { MenuItem } from 'primeng/api';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';
import { AppConstants } from 'src/app/commons/app-constants';
import { ContainerService } from '../../container/services/container.service';
import { BehaviorSubject } from 'rxjs';
import { SHARED_MODULES } from '../../shared-imports';
import { BillOfLadingMainComponent } from '../bill-of-lading-main/bill-of-lading-main.component';

@Component({
  selector: 'app-bill-of-lading-view',
  templateUrl: './bill-of-lading-view.component.html',
  styleUrls: ['./bill-of-lading-view.component.css'],
  standalone:true,
  imports:[SHARED_MODULES, BillOfLadingMainComponent]
})
export class BillOfLadingViewComponent {
  tabIndex = 0;
  formHeader!: string;
  formSubHeader!: string;
  protected items!: MenuItem[];
  loadingSubject = new BehaviorSubject<boolean>(false);

  totalPiecesHoppus: number = 0;
  totalGrossAvgHoppus: number = 0.0;
  totalNetAvgHoppus: number = 0.0;
  totalNetVolumeHoppus: number = 0.0;
  totalGrossVolumeHoppus: number = 0.0;

  totalPiecesRectanglePrism: number = 0;
  totalGrossAvgRectanglePrism: number = 0.0;
  totalNetAvgRectanglePrism: number = 0.0;
  totalNetVolumeRectanglePrism: number = 0.0;
  totalGrossVolumeRectanglePrism: number = 0.0;

  blSummaryHoppus: BlSummary[] = [];
  blSummaryRectanglePrism: BlSummary[] = [];

  blSummaryCombined: BlSummary[] = [];

  /* blSummary: BlSummary[] = [];
 
  totalPieces: number = 0;
  totalGrossAvg: number = 0.000;
  totalNetAvg: number = 0.000;
  totalNetVolume: number = 0.000;
  totalGrossVolume: number = 0.000; */

  isPublic = false;
  blUUID: any;
  tableStyle: any;
  isMobile!: boolean;
  blNumber: any;
  home!: MenuItem;

  constructor(
    private router: Router,
    private billOfLadingService: BillOfLadingService,
    private titleService: Title,
    private route: ActivatedRoute,
    private toastService: ToastMessagesService,
    private utilService: UtilService,
    private encryptedStorageService: EncryptedStorageService,
    private containerService: ContainerService,
    private metaTagService: Meta
  ) {
    this.setPageTitle();
    this.populateBreadCrumb();
    this.handleURLAndFetchData();
    this.setTableStyle();
  }

  ngOnInit() {
    this.initOGTags();
  }

  private handleURLAndFetchData(): void {
    this.loadingSubject.next(true);
    this.isPublic = this.router.url.includes('public');
    // if (this.isPublic) {
    this.route.params.subscribe((params) => {
      this.blUUID = params['blUUID'];
      this.getBillOfLadingById(this.blUUID, true);
      this.billOfLadingService
        .getBillOfLadingNumberByBlUUID(this.blUUID)
        .subscribe(
          (data: any) => {
            console.log(`Data for BL NUMBER is ${JSON.stringify(data)}`);
            this.blNumber = data.message;
            this.loadingSubject.next(false);
          },
          (error: any) => {
            this.loadingSubject.next(false);
          },
          () => {
            this.loadingSubject.next(false);
          }
        );
    });
    /* } else {
      // const blID = this.billOfLadingSharedDataService.getBLIdLocalStorage();
      this.encryptedStorageService.getEncryptedDataFromStorage(AppConstants.BL_VIEW).subscribe(
        (data:any)=>{
          console.log(`Data from view ${JSON.stringify(data)}`);
          this.blNumber = data.blNumber;
          this.getBillOfLadingById(data.billOfLadingId, this.isPublic);
        },
        (error:any)=>{
          console.error(`Data from view ${JSON.stringify(error)}`);
        },
        ()=>{},
      )
     
    } */
  }

  private setTableStyle(): void {
    this.tableStyle = this.utilService.getTableSize();
  }

  private setPageTitle(): void {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }

  private populateBreadCrumb(): void {
    this.items = [
      { label: 'Bill Of Lading', routerLink: '/bill-of-ladings' },
      { label: 'View Bill Of Lading' },
    ];
  }

  private getBillOfLadingById(id: any, isPublic: boolean): void {
    let serviceCall;
    if (isPublic) {
      serviceCall =
        this.billOfLadingService.getBillOfLadingProjectionByBlUUID(id);
    } else {
      serviceCall =
        this.billOfLadingService.getBillOfLadingProjectionByBLId(id);
    }

    serviceCall.subscribe(
      (blData: any) => {
        this.processBillOfLadingData(blData);
      },
      (error: any) => {
        this.toastService.showErrorMessage(
          'Error',
          'An error occurred while fetching data'
        );
      }
    );
  }

  /*  private processBillOfLadingData(blData: any): void {
    const blContainers = blData.containers;
    blContainers.forEach((container: any) => {
      let eachContainerSummary = this.prepareContainerSummary(container);
      this.blSummary.push(eachContainerSummary);
      this.calculateTotals(container);
    });
  } */
  private processBillOfLadingData(blData: any): void {
    const blContainers = blData.containers;
    blContainers.forEach((container: any) => {
      let eachContainerSummary = this.prepareContainerSummary(container);
      if (container.formula === Formula.Hoppus) {
        this.blSummaryHoppus.push(eachContainerSummary);
        this.blSummaryCombined.push(eachContainerSummary);
        this.calculateTotals(container, Formula.Hoppus);
      } else {
        this.blSummaryRectanglePrism.push(eachContainerSummary);
        this.blSummaryCombined.push(eachContainerSummary);
        this.calculateTotals(container, Formula.RectanglePrism);
      }
    });
  }

  private prepareContainerSummary(container: any): BlSummary {
    let eachContainerSummary: BlSummary = new BlSummary();
    const containerSummary = container.containerSummary;
    eachContainerSummary.containerNumber = container.containerNumber;
    eachContainerSummary.containerSummaryId =
      containerSummary.containerSummaryId;
    eachContainerSummary.grossAverage = containerSummary.grossAverage;
    eachContainerSummary.netAverage = containerSummary.netAverage;
    eachContainerSummary.netVolume = containerSummary.netVolume;
    eachContainerSummary.grossVolume = containerSummary.grossVolume;
    eachContainerSummary.pieces = containerSummary.pieces;
    eachContainerSummary.containerId = container.containerId;
    eachContainerSummary.containerUUID = container.containerUUID;
    eachContainerSummary.formula = container.formula;
    return eachContainerSummary;
  }

  /* private calculateTotals(container: any): void {
    this.totalPieces += container.containerSummary.pieces || 0;
    this.totalGrossAvg += container.containerSummary.grossAverage || 0;
    this.totalNetAvg += container.containerSummary.netAverage || 0;
    this.totalNetVolume += container.containerSummary.netVolume || 0;
    this.totalGrossVolume += container.containerSummary.grossVolume || 0;
  } */

  private calculateTotals(container: any, formula: Formula): void {
    if (formula === Formula.Hoppus) {
      this.totalPiecesHoppus += container.containerSummary.pieces || 0;
      this.totalGrossAvgHoppus += container.containerSummary.grossAverage || 0;
      this.totalNetAvgHoppus += container.containerSummary.netAverage || 0;
      this.totalNetVolumeHoppus += container.containerSummary.netVolume || 0;
      this.totalGrossVolumeHoppus +=
        container.containerSummary.grossVolume || 0;
    } else {
      this.totalPiecesRectanglePrism += container.containerSummary.pieces || 0;
      this.totalGrossAvgRectanglePrism +=
        container.containerSummary.grossAverage || 0;
      this.totalNetAvgRectanglePrism +=
        container.containerSummary.netAverage || 0;
      this.totalNetVolumeRectanglePrism +=
        container.containerSummary.netVolume || 0;
      this.totalGrossVolumeRectanglePrism +=
        container.containerSummary.grossVolume || 0;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = this.utilService.isMobile();
    this.tableStyle = this.utilService.getTableSize();
  }

  initOGTags() {
    this.metaTagService.updateTag({
      name: 'og:description',
      content:
        "A Bill of Lading has been shared with you, containing detailed packing lists, photos, and summaries of the containers. Access it here",
    });
  }
  /* tabIndex = 0;
  formHeader!: string;
  formSubHeader!: string;
  protected items!: MenuItem[];
  blSummary: BlSummary[] = [];
  totalPieces: number = 0;
  totalGrossAvg: number = 0.000;
  totalNetAvg: number = 0.000;
  totalNetVolume: number = 0.000;
  totalGrossVolume: number = 0.000;
  isPublic = false;
  blUUID: any;
  tableStyle: any;
  isMobile!: boolean;
  blNumber: any;
  home!: MenuItem;

  constructor(
    private fb: FormBuilder,
    private nameValidator: NameCheckValidator,
    private populateDropdownService: PopulateDropdownService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private billOfLadingService: BillOfLadingService,
    private titleService: Title,
    private route: ActivatedRoute,
    private toastService: ToastMessagesService,
    private billOfLadingSharedDataService: BillOfLadingSharedDataService,
    private utilService: UtilService
  ) {
    this.setPageTitle();
    this.populateBreadCrumb();
    this.handleURLAndFetchData();
    this.setTableStyle();
  }

  ngOnInit() { }

  private handleURLAndFetchData(): void {
    this.isPublic = this.router.url.includes('public');
    if (this.isPublic) {
      this.route.params.subscribe(params => {
        this.blUUID = params['blUUID'];
        this.getBillOfLadingById(this.blUUID, this.isPublic);
      });
    } else {
      const blID = this.billOfLadingSharedDataService.getBLIdLocalStorage();
      this.getBillOfLadingById(blID, this.isPublic);
    }
  }

  private setTableStyle(): void {
    this.tableStyle = this.utilService.getTableSize();
  }

  private setPageTitle(): void {
    this.route.data.subscribe(data => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }

  private populateBreadCrumb(): void {
    this.items = [
      { label: 'Bill Of Lading', routerLink: '/bill-of-ladings' },
      { label: 'View Bill Of Lading' },
    ];
  }

  private getBillOfLadingById(id: any, isPublic: boolean): void {
    let serviceCall;
    if (isPublic) {
      serviceCall = this.billOfLadingService.getBillOfLadingProjectionByBlUUID(id);
    } else {
      serviceCall = this.billOfLadingService.getBillOfLadingProjectionByBLId(id);
    }

    serviceCall.subscribe(
      ((blData: any) => {
        this.processBillOfLadingData(blData);
      }),
      ((error: any) => {
        this.toastService.showErrorMessage('Error', 'An error occurred while fetching data');
      }),
    )
  }

  private processBillOfLadingData(blData: any): void {
    const blContainers = blData.containers;
    blContainers.forEach((container: any) => {
      let eachContainerSummary = this.prepareContainerSummary(container);
      this.blSummary.push(eachContainerSummary);
      this.calculateTotals(container);
    });
  }

  private prepareContainerSummary(container: any): BlSummary {
    let eachContainerSummary: BlSummary = new BlSummary();
    const containerSummary = container.containerSummary;
    eachContainerSummary.containerNumber = container.containerNumber;
    eachContainerSummary.containerSummaryId = containerSummary.containerSummaryId;
    eachContainerSummary.grossAverage = containerSummary.grossAverage;
    eachContainerSummary.netAverage = containerSummary.netAverage;
    eachContainerSummary.netVolume = containerSummary.netVolume;
    eachContainerSummary.grossVolume = containerSummary.grossVolume;
    eachContainerSummary.pieces = containerSummary.pieces;
    eachContainerSummary.containerId = container.containerId;
    eachContainerSummary.containerUUID = container.containerUUID;
    return eachContainerSummary;
  }

  private calculateTotals(container: any): void {
    this.totalPieces += container.containerSummary.pieces || 0;
    this.totalGrossAvg += container.containerSummary.grossAverage || 0;
    this.totalNetAvg += container.containerSummary.netAverage || 0;
    this.totalNetVolume += container.containerSummary.netVolume || 0;
    this.totalGrossVolume += container.containerSummary.grossVolume || 0;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = this.utilService.isMobile();
    this.tableStyle = this.utilService.getTableSize();
  } */
  /*  OLD WORKING CODE, above code is also doing exactly the same thing,
     but the difference is above is REFACTORED CODE, and this commented code is NOT REFACTORED
  tabIndex = 0;
  formHeader!: string;
  formSubHeader!: string;
  protected items!: MenuItem[];
  home!: MenuItem;
  protected blSummary: BlSummary[] = [];
  totalPieces: number = 0;
  totalGrossAvg: number = 0.000;
  totalNetAvg: number = 0.000;
  totalNetVolume: number = 0.000;
  totalGrossVolume: number = 0.000;
  isPublic: boolean;
  blUUID: any;
  tableStyle: any;
  isMobile!: boolean;
  blNumber: any;

  constructor(
    private fb: FormBuilder,
    private nameValidator: NameCheckValidator,
    private populateDropdownService: PopulateDropdownService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private billOfLadingService: BillOfLadingService,
    private titleService: Title,
    private route: ActivatedRoute,
    private toastService: ToastMessagesService,
    private billOfLadingSharedDataService: BillOfLadingSharedDataService,
    private utilService: UtilService
  ) {
    this.setPageTitle();
    this.populateBreadCrumb();
    this.tableStyle = this.utilService.getTableSize();
    this.isPublic = this.router.url.includes('public');
    console.log(`url is ${this.router.url}`)
    console.log(`this.isPublic ${this.isPublic}`)
    if (this.isPublic) {
      this.route.params.subscribe(params => {
        this.blUUID = params['blUUID']; 
        console.log(`UUID is ${this.blUUID}`)
        this.getPublicBillOfLadingById();
      })
    } else { 

      const blID = this.billOfLadingSharedDataService.getBLIdLocalStorage();
      this.getPrivateBillOfLadingById(blID);
    }

  }

  private setPageTitle() {
    this.route.data.subscribe(data => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header']
      this.formSubHeader = data['subHeader']
    });
  }

  private populateBreadCrumb() {
    this.items = [
      { label: 'Bill Of Lading', routerLink: '/bill-of-ladings' },
      { label: 'View Bill Of Lading' },
    ];
  }

  private getPublicBillOfLadingById() {
    console.log(`getBillOfLadingById `);
    this.billOfLadingService.getBillOfLadingProjectionByBlUUID(this.blUUID).subscribe(
      ((blData: any) => {
        console.log(`New Format of Data is ${JSON.stringify(blData)}`)
        this.blNumber= blData.blNumber;
        const blSize = blData.containers.length;
        const blContainers = blData.containers;
        console.log(`New blContainers are ${JSON.stringify(blContainers)}`)
        
        for (var i = 0; i < blContainers.length; i++) {
          let eachContainerSummary: BlSummary = new BlSummary();
          const containerNumber1 = blContainers[i];
          eachContainerSummary.containerNumber = containerNumber1.containerNumber;
          eachContainerSummary.containerSummaryId = containerNumber1.containerSummary.containerSummaryId;
          eachContainerSummary.grossAverage = containerNumber1.containerSummary.grossAverage;
          eachContainerSummary.netAverage = containerNumber1.containerSummary.netAverage;
          eachContainerSummary.netVolume = containerNumber1.containerSummary.netVolume;
          eachContainerSummary.grossVolume = containerNumber1.containerSummary.grossVolume;
          eachContainerSummary.pieces = containerNumber1.containerSummary.pieces;
          eachContainerSummary.containerId = containerNumber1.containerId;
          eachContainerSummary.containerUUID = containerNumber1.containerUUID;
          this.blSummary.push(eachContainerSummary);
          console.log(`New eachContainerSummary ${JSON.stringify(eachContainerSummary)}`)
          this.totalPieces = this.totalPieces + containerNumber1!.containerSummary!.pieces! || 0;
          this.totalGrossAvg = this.totalGrossAvg + containerNumber1!.containerSummary!.grossAverage! || 0;
          this.totalNetAvg = this.totalNetAvg + containerNumber1!.containerSummary!.netAverage! || 0;
          this.totalNetVolume = this.totalNetVolume + containerNumber1!.containerSummary!.netVolume! || 0;
          this.totalGrossVolume = this.totalGrossVolume + containerNumber1!.containerSummary!.grossVolume! || 0;
          console.log(`New Pieces are ${containerNumber1!.containerSummary!.pieces}`)
        }

      }),
      ((error: any) => {
        this.toastService.showErrorMessage('Error', 'An error occured while fetchind data')
      }),
      (() => {

      }),
    )
  }

  private getPrivateBillOfLadingById(blId: number) {
    console.log(`getBillOfLadingById `);
    this.billOfLadingService.getBillOfLadingProjectionByBLId(blId).subscribe(
      ((blData: any) => {
        console.log(`New Format of Data is ${JSON.stringify(blData)}`)
        const blSize = blData.containers.length;
        const blContainers = blData.containers; 
        console.log(`New blContainers are ${JSON.stringify(blContainers)}`)
        for (var i = 0; i < blContainers.length; i++) {
          let eachContainerSummary: BlSummary = new BlSummary();
          const containerNumber1 = blContainers[i];
          eachContainerSummary.containerNumber = containerNumber1.containerNumber;
          eachContainerSummary.containerSummaryId = containerNumber1.containerSummary.containerSummaryId;
          eachContainerSummary.grossAverage = containerNumber1.containerSummary.grossAverage;
          eachContainerSummary.netAverage = containerNumber1.containerSummary.netAverage;
          eachContainerSummary.netVolume = containerNumber1.containerSummary.netVolume;
          eachContainerSummary.grossVolume = containerNumber1.containerSummary.grossVolume;
          eachContainerSummary.pieces = containerNumber1.containerSummary.pieces;
          eachContainerSummary.containerId = containerNumber1.containerId;
          eachContainerSummary.formula =  this.toFormula(containerNumber1.formula);;
          console.log(`eachContainerSummary.formula ${eachContainerSummary.formula}`);
          this.blSummary.push(eachContainerSummary);

          console.log(`New eachContainerSummary ${JSON.stringify(eachContainerSummary)}`)

          this.totalPieces = this.totalPieces + containerNumber1!.containerSummary!.pieces! || 0;
          this.totalGrossAvg = this.totalGrossAvg + containerNumber1!.containerSummary!.grossAverage! || 0;
          this.totalNetAvg = this.totalNetAvg + containerNumber1!.containerSummary!.netAverage! || 0;
          this.totalNetVolume = this.totalNetVolume + containerNumber1!.containerSummary!.netVolume! || 0;
          this.totalGrossVolume = this.totalGrossVolume + containerNumber1!.containerSummary!.grossVolume! || 0;
          console.log(`New Pieces are ${containerNumber1!.containerSummary!.pieces}`)
        }
      }),
      ((error: any) => {

        this.toastService.showErrorMessage('Error', 'An error occured while fetchind data')
      }),
      (() => {
      }),
    )
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = this.utilService.isMobile();
    this.tableStyle = this.utilService.getTableSize();
    console.log(`is mobile ${this.utilService.isMobile()}`);
  }

   toFormula(value: number): Formula {
    if (!Object.values(Formula).includes(value)) {
        throw new Error('Invalid value for Formula: ' + value);
    }
    return value as Formula;
}
 */
}
