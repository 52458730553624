import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { LocationSelectionService } from './service/location-selection.service';
import { City } from './model/city';
import { Country } from './model/country';
import { State } from './model/state';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ResetCommonService } from 'src/app/commons/services/reset-common.service';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-location-selection',
  templateUrl: './location-selection.component.html',
  styleUrls: ['./location-selection.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationSelectionComponent),
      multi: true,

    }, ToastMessagesService
  ],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class LocationSelectionComponent
  implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {//, OnChanges {
  @Output() initializationFinished = new EventEmitter<void>();
  @Output() countriesLoaded = new EventEmitter<void>();
  @Output() statesLoaded = new EventEmitter<void>();
  @Output() citiesLoaded = new EventEmitter<void>();

  applyStyle = 'field col-12 xl:col-3 lg:col-3 md:col-6';
  @Output() locationSelected: EventEmitter<any> = new EventEmitter();
  @Output() locationFormStatus = new EventEmitter<boolean>();
  @Input() locationFormValidators!: { [key: string]: any[] };
  @Input() existingLocationData: any;

  @Input() isCountryVisible: boolean = true;
  @Input() isStateVisible: boolean = true;
  @Input() isCityVisible: boolean = true;
  @Input() isZipVisible: boolean = true;
  @Input() isAddress1Visible: boolean = true;
  @Input() isAddress2Visible: boolean = true;

  @Input() isAddress1Optional: boolean = false;
  @Input() isAddress2Optional: boolean = false;
  @Input() isCountryOptional: boolean = false;
  @Input() isStateOptional: boolean = false;
  @Input() isCityOptional: boolean = false;
  @Input() isZipOptional: boolean = false;
  @Input() isFromSidebar: boolean = false;

  // isSubmitting = false;
  locationForm!: FormGroup;

  countryTooltip = 'Loading countries';
  isCountryDisabled = true;
  isCountryLoading: boolean = false;
  selectedCountry!: Country | undefined;
  countrySuggestions: Country[] = [];

  stateTooltip = 'Select country first';
  isStateDisabled = true;
  isStateLoading: boolean = false;
  selectedState!: State | undefined;
  stateSuggestions: State[] = [];

  cityTooltip = 'Select state first';
  isCityDisabled = true;
  isCityLoading: boolean = false;
  selectedCity!: City | undefined;
  citySuggestions: City[] = [];

  isZipcodeDisabled = true;

  constructor(
    private locationService: LocationSelectionService,
    private fb: FormBuilder,
    private resetService: ResetCommonService,
    private toastMessageService: ToastMessagesService
  ) {
  }

  ngOnInit(): void {
    this.updateVisibility();
    this.updateOptional();
    this.updateStyle();
    this.createForm();

    this.locationForm.valueChanges.subscribe((val) => {
      this.onChange(val);
      this.onTouched();
    });

    this.locationForm.statusChanges.subscribe((status) => {
      this.locationFormStatus.emit(status === 'VALID');
    });

    if (this.existingLocationData) {
      this.initializeExistingLocationData();
      this.locationForm.markAsPristine();
    } else {
      this.fetchCountries();
    }
  }

  private initializeExistingLocationData() {
    this.countrySuggestions = this.existingLocationData.countries;
    this.selectedCountry = this.findMatchingItem(this.countrySuggestions, this.existingLocationData.country);

    if (this.selectedCountry) {
      this.locationForm.patchValue({ country: this.selectedCountry });
      this.countriesLoaded.emit();

      this.stateSuggestions = this.existingLocationData.states;
      this.selectedState = this.findMatchingItem(this.stateSuggestions, this.existingLocationData.state);

      if (this.selectedState) {
        this.locationForm.patchValue({ state: this.selectedState });
        this.statesLoaded.emit();

        this.citySuggestions = this.existingLocationData.cities;
        this.selectedCity = this.findMatchingItem(this.citySuggestions, this.existingLocationData.city);

        if (this.selectedCity) {
          this.locationForm.patchValue({ city: this.selectedCity });
          this.citiesLoaded.emit();
        }
      }
    }

  }

  private findMatchingItem<T extends { name: string }>(items: T[], target: T): T | undefined {
    return items.find(item => item.name === target.name);
  }


  /*  ngOnChanges(changes: SimpleChanges) {
     if (changes['existingLocationData'] && this.existingLocationData?.country) {
       // Find the matching country object from countrySuggestions
       const matchingCountry = this.countrySuggestions.find(c => c.id === this.existingLocationData.country.id);
       // Set the selectedCountry and patch the form control
       if (matchingCountry) {
         this.selectedCountry = matchingCountry;
         this.locationForm.patchValue({ country: this.selectedCountry });
         console.log(`I found the matching country`);
       }
       this.fetchStatesNew(this.selectedCountry.id);
       console.log('Selected Country:', JSON.stringify(this.selectedCountry));
     }
   } */


  updateVisibility() {
    if (!this.isCountryVisible) {
      this.isStateVisible = false;
      this.isCityVisible = false;
    }
    if (!this.isStateVisible) {
      this.isCityVisible = false;
    }
    if (!this.isAddress1Visible) {
      this.isAddress2Visible = false;
    }
  }

  updateOptional() {
    if (this.isCountryOptional) {
      this.isStateOptional = true;
      this.isCityOptional = true;
    }
    if (this.isStateOptional) {
      this.isCityOptional = true;
    }
    if (this.isAddress1Optional) {
      this.isAddress2Optional = true;
    }
  }

  updateStyle() {
    if (this.isFromSidebar) {
      this.applyStyle = 'field col-12 xl:col-6 lg:col-6 md:col-6';
    }
  }

  ngOnDestroy(): void { }

  ngAfterViewInit(): void { }

  private createForm() {
    this.locationForm = this.fb.group({
      id: [],
      address1: ['', this.isAddress1Optional ? null : Validators.required],
      address2: ['', this.isAddress2Optional ? null : Validators.required],
      country: [null, this.isCountryOptional ? null : Validators.required],
      state: [null, this.isStateOptional ? null : Validators.required],
      city: [null, this.isCityOptional ? null : Validators.required],
      zipcode: [null, this.isZipOptional ? null : Validators.required],
    });
  }

  onSubmit() {
    // this.isSubmitting = true;
    this.locationForm.markAllAsTouched();
    const controlKeys = Object.keys(this.locationForm.controls);

    const invalidControlKeys = controlKeys.filter((key) => {
      const control = this.locationForm.controls[key];
      return control.invalid;
    });
    // console.log('Invalid control keys:', invalidControlKeys);
    Object.keys(this.locationForm.controls).forEach((key) => {
      // console.log(`Key is ${key}`);
    });
    if (this.locationForm.valid) {
      // console.log(this.locationForm.value);
    }
  }

  onCountrySelection(event: any) {
    if (this.selectedCountry) {
      this.fetchStatesNew(event.value.id);
    }
  }

  onStateSelection(event: any) {

    if (this.selectedState) {
      // console.log('Selected State:', JSON.stringify(this.selectedState));
      delete this.selectedState.cities;
      console.log('Selected State:', JSON.stringify(this.selectedState));

      this.fetchCitiesNew(event.value.id);
    }
  }

  onStateClick() {
    if (!this.selectedCountry) {
      this.toastMessageService.clearMessage();
      this.toastMessageService.showWarningMessage('Select Country', 'Select Country First');
    }
  }


  onCityClick() {
    if (!this.selectedState) {
      this.toastMessageService.clearMessage();
      this.toastMessageService.showWarningMessage('Select State', 'Select State First');
    }
  }

  onCitySelection(event: any) {

    if (this.selectedCity) {
      console.log('Selected City:', JSON.stringify(this.selectedCity));
      //  this.isZipcodeDisabled = false;
    }
  }

  fetchCountries(): void {
    this.isCountryLoading = true;

    this.locationService.getCountriesNEW().subscribe(
      (countries) => {
        this.countrySuggestions = countries;
        // Emit the event here when countries are loaded
        console.log(`existingLocationData Country are ${JSON.stringify(this.existingLocationData.country)}`);
        if (this.existingLocationData) {
          this.selectedCountry = this.countrySuggestions.find(
            (country) => {
              return country.name === this.existingLocationData.country.name;
            }
          );
          if (this.selectedCountry) {
            // this.selectedCountry = country;
            this.locationForm.patchValue({ country: this.selectedCountry });
            this.countriesLoaded.emit();
            this.fetchStatesNew(this.selectedCountry.id);
          } else {
          }
        }
      },
      (error) => console.log(JSON.stringify(error)),
      () => {

        this.isCountryDisabled = false;
        this.countryTooltip = 'Select a country';
        setTimeout(() => {
          this.isCountryLoading = false;
          this.initializationFinished.emit();

        }, 50);
      }
    );
  }

  fetchStates(countryId: number): void {
    this.isStateLoading = true;
    this.locationService.getStates(countryId).subscribe(
      (states) => {
        this.stateSuggestions = states;
        if (this.existingLocationData) {
          this.selectedState = this.stateSuggestions.find(
            (state) => state.name === this.existingLocationData.state.name
          );
          if (this.selectedState) {
            delete this.selectedState.cities;
            this.locationForm.patchValue({ state: this.selectedState });

            this.fetchCities(this.selectedState.id);
          }
        }
      },
      (error) => console.log(error),
      () => {
        this.isStateDisabled = false;
        this.stateTooltip = 'Select a state';
        setTimeout(() => {
          this.isStateLoading = false;
        }, 50);
      }
    );
  }

  fetchStatesNew(countryId: number): void {
    this.isStateLoading = true;
    this.locationService.getStatesNEW(countryId).subscribe(
      (states) => {
        this.stateSuggestions = states;
        if (this.existingLocationData) {
          this.selectedState = this.stateSuggestions.find(
            (state) => state.name === this.existingLocationData.state.name);
          if (this.selectedState) {
            // Emit the event here when states are loaded
            this.statesLoaded.emit();
            this.locationForm.patchValue({ state: this.selectedState });
            this.fetchCitiesNew(this.selectedState.id);
          }
        }
      },
      (error) => console.log(error),
      () => {
        this.isStateDisabled = false;
        this.stateTooltip = 'Select a state';
        setTimeout(() => {
          this.isStateLoading = false;
        }, 50);
      }
    );
  }


  fetchCities(stateId: number): void {
    this.isCityLoading = true;
    this.locationService.getCities(stateId).subscribe(
      (cities) => {
        this.citySuggestions = cities;

        const city = this.citySuggestions.find((city) => {

          return city.name === this.existingLocationData.city.name;
        });
        this.locationForm.patchValue({ city: city });
        if (city) {
          this.selectedCity = city;
        } else {
        }
      },
      (error) => console.log(error),
      () => {
        this.isCityDisabled = false;
        this.cityTooltip = 'Select a city';
        setTimeout(() => {
          this.isCityLoading = false;
        }, 500);
      }
    );
  }

  fetchCitiesNew(stateId: number): void {
    this.isCityLoading = true;
    this.locationService.getCitiesNEW(stateId).subscribe(
      (cities) => {
        this.citySuggestions = cities;

        const city = this.citySuggestions.find((city) => {

          return city.name === this.existingLocationData.city.name;
        });
        this.locationForm.patchValue({ city: city });
        // Emit the event here when cities are loaded
        this.citiesLoaded.emit();
        if (city) {
          this.selectedCity = city;
        } else {
        }
      },
      (error) => console.log(error),
      () => {
        this.isCityDisabled = false;
        this.cityTooltip = 'Select a city';
        setTimeout(() => {
          this.isCityLoading = false;
        }, 500);
      }
    );
  }

  onChange = (value: any) => { };
  onTouched = () => { };

  writeValue(value: any) {
    if (value) {
      // console.log(`WriteValue is ${JSON.stringify(value)}`)
      this.locationForm.patchValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  resetLocationForm() {
    this.resetService.resetForm(this.locationForm);
  }

  // Method to mark all controls as pristine
  markAllControlsAsPristine() {
    this.locationForm.markAsPristine();
    // Object.values(this.locationForm.controls).forEach(control => control.markAsPristine());
  }
}
