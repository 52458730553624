import { HttpParams } from '@angular/common/http';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Page } from 'src/app/commons/models/page';
import { MeasurementSummary } from '../model/measurement-summary';
import { Measurement } from '../model/measurement';
import { SplitButtonModule } from 'primeng/splitbutton';
import { Location } from '@angular/common';

import { MeasurementSharedDataService } from '../services/measurement-shared-data.service';
import { MeasurementService } from '../services/measurement.service';
import { UtilService } from 'src/app/services/util.service';
import { ContextMenu } from 'primeng/contextmenu';
import { ContainerService } from '../../container/services/container.service';
import { ClipboardService } from 'ngx-clipboard';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { AppConstants } from 'src/app/commons/app-constants';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-measurement-list',
  templateUrl: './measurement-list.component.html',
  styleUrls: ['./measurement-list.component.css'],
  providers: [ToastMessagesService],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class MeasurementListComponent implements OnInit {
  openContextMenu: ContextMenu | null = null;
  copyUrl!: string;

  containerNumberOnContextMenu!: string;
  currentRowIndex = 0;
  formHeader!: string;
  formSubHeader!: string;
  showNameColumn = true;
  showContactPersonColumn = true;
  showGstColumn = true;
  showCityColumn = true;
  showPhoneColumn = false;
  showEmailColumn = false;
  protected items!: MenuItem[];
  protected shareItems!: MenuItem[];
  measurementSpeedDialItems!: MenuItem[];
  home!: MenuItem;
  rows = 5;
  measurementSummarys!: MeasurementSummary[];
  multiSortMeta!: any[];
  totalRecords: number = 0;
  first: number = 0;
  last: number = 0;
  rowsPerPageOptions = [5, 10, 20]; // set the options for the number of rows per page
  @ViewChild('globalFiterInput') globalFiterInput!: ElementRef;
  @ViewChild('dt') table!: Table;
  cols!: any[];
  isMobile = false;
  loading: boolean = false;
  _selectedColumns!: any[];
  selectedSummary: MeasurementSummary[] = [];
  tableStyle: any;
  shareContainerUUID: any;
  shareContainerShortenUrl: any; //
  shareContainerFormula: any; // Volumetric or Hoppus
  tableStyleOptions: any;

  constructor(
    private measurementService: MeasurementService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private measurementSharedDataService: MeasurementSharedDataService,
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private containerSerivce: ContainerService,
    private location: Location,
    private _clipboardService: ClipboardService,
    private toastMessagesService: ToastMessagesService,
    private encryptedStorageService: EncryptedStorageService
  ) {
    this.setPageTitle();
    this.isMobile = this.utilService.isMobile();
    this.tableStyle = this.utilService.getTableSize();
    this.initTableStyle();
  }

  ngOnInit() {
    this.shareItemsInit();
    this.initTableColumns();
    this.populateBreadCrumb();
    this.initSpeedDial();
    /*  this.buyerService.getListBuyers().subscribe(
       (data: any) => {
         this.buyers = data.content;
       },
       (error) => { },
       () => { }) */
  }

  sortSummary(event: any) {
    //const globalFilter = this.globalFiterInput.nativeElement.value;
    /* console.log(`value ${globalFilter}`);
    console.log(`%%% ${globalFilter}`) */
    this.loading = true;
    const pageNumber = Math.floor(event.first / event.rows) + 1;
    console.log(`Page Number ${pageNumber}`);
    console.log(`Event List ${JSON.stringify(event)}`);
    let params = new HttpParams();
    let sortOrder = '';
    //    console.log(`customSort field ${[event!.field!]}`);
    // console.log(`customSort data ${[event!.data!]}`)
    //  console.log(`customSort mode ${[event!.mode!]}`);
    //console.log(`customSort order ${[event!.order!]}`);
    /* console.log(
      `customSort multiSortMeta ${JSON.stringify([event!.multiSortMeta!])}`
    );
    console.log(`multiSortMeta ${event!.multiSortMeta?.length}`); */
    const multiSortLength = event!.multiSortMeta?.length;
    params = params.set('size', event.rows);
    params = params.set('page', pageNumber - 1);
    for (var i = 0; i < multiSortLength!; i++) {
      if (event!.multiSortMeta![i].order === -1) {
        sortOrder = 'DESC';
      } else {
        sortOrder = 'ASC';
      }
      console.log(`field ${event!.multiSortMeta![i].field}`);
      console.log(`order ${event!.multiSortMeta![i].order}`);

      if (multiSortLength! > 0) {
        console.log(`in if`);
        params = params.set(
          'sort',
          event!.multiSortMeta![i].field + ',' + sortOrder
        );
      }
    }
    console.log(`Params ${params.toString()}`);
    this.measurementSummarys = [];
    // filter implementation
    const filters = event.filters;
    /*  if (event.filters.hasOwnProperty('pieces')) {
      for (var i = 0; i < filters.pieces.length; i++) {
        if (filters.pieces[i].value) {
          params = params.set('pieces', filters.pieces[i].value);
          params = params.set('piecesMatchMode', filters.pieces[i].matchMode);
        }
      }
    }
    if (event.filters.hasOwnProperty('netVolume')) {
      for (var i = 0; i < filters.netVolume.length; i++) {
        if (filters.netVolume[i].value) {
          params = params.set('netVolume', filters.netVolume[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('grossVolume')) {
      for (var i = 0; i < filters.grossVolume.length; i++) {
        if (filters.grossVolume[i].value) {
          params = params.set('grossVolume', filters.grossVolume[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('netAverage')) {
      for (var i = 0; i < filters.netAverage.length; i++) {
        if (filters.netAverage[i].value) {
          params = params.set('netAverage', filters.netAverage[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('grossAverage')) {
      for (var i = 0; i < filters.grossAverage.length; i++) {
        if (filters.grossAverage[i].value) {
          params = params.set('grossAverage', filters.grossAverage[i].value);
        }
      }
    }

    if (event.filters.hasOwnProperty('containerNumber')) {
      for (var i = 0; i < filters.containerNumber.length; i++) {
        if (filters.containerNumber[i].value) {
          params = params.set(
            'containerNumber',
            filters.containerNumber[i].value
          );
        }
      }
    }
    if (event.filters.hasOwnProperty('supplier')) {
      for (var i = 0; i < filters.supplier.length; i++) {
        if (filters.supplier[i].value) {
          params = params.set('supplier', filters.supplier[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('product')) {
      for (var i = 0; i < filters.product.length; i++) {
        if (filters.product[i].value) {
          params = params.set('product', filters.product[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('loadingSite')) {
      for (var i = 0; i < filters.loadingSite.length; i++) {
        if (filters.loadingSite[i].value) {
          params = params.set('loadingSite', filters.loadingSite[i].value);
        }
      }
    } */
    const size = event.rows;
    const page = pageNumber - 1;
    this.measurementService
      .getListContainerSummary1(event, page, size)
      .subscribe(
        (response: Page<MeasurementSummary>) => {
          console.log(`@@@@@&&&`);
          this.measurementSummarys = response.content;
          // console.log(`MeasurementSummary object is ${JSON.stringify(response)}`)
          this.first =
            response.pageable.pageNumber * response.pageable.pageSize + 1;
          const max =
            response.pageable.pageNumber * response.pageable.pageSize +
            response.pageable.pageSize;
          this.last =
            max < response.totalElements ? max : response.totalElements;
          this.totalRecords = response.totalElements;
        },
        (error) => {},
        () => {
          this.loading = false;
        }
      );
  }

  globalFilter(value: any, tabel: Table) {
    console.log(`globalFilter  ${JSON.stringify(value)}`);
    const globalFilter = this.globalFiterInput.nativeElement.value;
    console.log(`value ${globalFilter}`);
    //if (globalFilter && globalFilter.length > 0)
    {
      let params = new HttpParams();
      params = params.append('q', globalFilter);
      this.measurementService
        .getMeasurementSummaryGlobalFilter(params)
        .subscribe(
          (response: Page<MeasurementSummary>) => {
            this.measurementSummarys = response.content;
            console.warn(
              `measurementSummarys is ${JSON.stringify(
                this.measurementSummarys
              )}`
            );
            this.first =
              response.pageable.pageNumber * response.pageable.pageSize + 1;
            const max =
              response.pageable.pageNumber * response.pageable.pageSize +
              response.pageable.pageSize;
            this.last =
              max < response.totalElements ? max : response.totalElements;
            this.totalRecords = response.totalElements;
          },
          (error) => {},
          () => {}
        );
    }
  }

  clear(table: Table) {
    table.clear();
  }

  initTableColumns() {
    this._selectedColumns = [
      'Container Number',
      'Pieces',
      'G Vol',
      'N Vol',
      'G Avg',
      'N Avg',
    ];
    this.cols = [
      'Container Number',
      'Pieces',
      'G Vol',
      'N Vol',
      'G Avg',
      'N Avg',
      'Product',
      'Supplier',
      'Loading Site',
      'Formula',
    ];
  }

  deleteRows() {
    const ids: number[] = this.selectedSummary.map(
      (summary) => summary.containerSummaryId
    );

    if (ids.length === 0) {
      this.toastMessagesService.showWarningMessage(
        'No Selection',
        'No rows selected for deletion.'
      );
      return;
    }

    this.confirmationService.confirm({
      header: 'Delete Measurement Summary',
      message: 'Are you sure you want to delete this measurement summary?',
      accept: () => {
        this.measurementService.deleteContainerSummary(ids).subscribe(
          () => {
            this.toastMessagesService.showInfoMessage(
              'Success',
              'Measurement Summary Deleted Successfully'
            );
            this.refreshTable();
          },
          () => {
            this.toastMessagesService.showErrorMessage(
              'Operation Unsuccessful',
              'An error occurred. Please try again.'
            );
          }
        );
      },
    });
  }

  refreshTable() {
    this.table.reset();
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) => val.includes(col));
  }
  getSelectedContainerSummary() {
    console.log(`Selected Summary length is ${this.selectedSummary.length} 
     and \nData is ${JSON.stringify(this.selectedSummary)}`);
  }

  onRowSelect(event: any) {
    //this.messageService.add({ severity: 'info', summary: 'Product Selected', detail: event.data.name });
  }

  onRowClick(data: MeasurementSummary) {
    console.log(`onRowClick Data is ${JSON.stringify(data)}`);
    /* this.buyerSharedData.sharedData = data;
    console.log(`shared is ${JSON.stringify(this.buyerSharedData.sharedData)}`) */
    //this.measurementSharedDataService.saveDataToLocalStorage(data);

    this.storeInEncryptedStorage(data.container.containerId);
    //  this.router.navigate(['/sawn/row']);
    // this.router.navigate(['/edit/measurement']);
  }

  populateBreadCrumb() {
    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.items = [{ label: 'Measurements' }];
  }
  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }

  onViewMeasurement(container: any) {
    this.encryptedStorageService
      .setEncryptedDataToStorage(AppConstants.CONTAINER, container)
      .subscribe(
        (data: any) => {},
        (error: any) => {}
      );
    this.encryptedStorageService
      .setEncryptedDataToStorage(AppConstants.MEASUREMENT, container)
      .subscribe(
        (data: any) => {
          console.log(`Stored in Encryted Storage`);
        },
        (error: any) => {}
      );
    console.log(`onViewMeasurement ${JSON.stringify(container)}`);
    if (container.productId.formula == 1) {
      this.measurementSharedDataService.saveContainerIdStorage(
        container.containerId
      );
      this.router.navigate(['/view/measurement', container.containerUUID]);
    } else if (container.productId.formula == 2) {
      this.encryptedStorageService
        .setEncryptedDataToStorage(AppConstants.VIEW, container)
        .subscribe(
          (data: any) => {
            console.log(
              `data stored in encrypted-storage data is ${JSON.stringify(data)}`
            );
            this.router.navigate(['/view-sawn-timber']);
          },
          (error: any) => {
            console.log(`error in data stored in encrypted-storage`);
          },
          () => {}
        );
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = this.utilService.isMobile();
    console.log(`is mobile ${this.utilService.isMobile()}`);
    // this.tableStyle = this.utilService.getTableSize();
    this.initTableStyle();
  }

  /* private shareItemsInit() {
    if (this.currentRowIndex) {
      console.log(`this.currentRowIndex ${this.currentRowIndex}`);
      console.log(
        `data of rowIndex is ${JSON.stringify(
          this.measurementSummarys[this.currentRowIndex]
        )}`
      );
    }
    this.shareItems = [
      {
        icon: 'pi pi-copy',
        label: 'Copy Link',
        command: (event) => {
          // console.log(`Event is ${JSON.stringify(event)}`)
          console.log(`this.currentRowIndex ${this.currentRowIndex}`);
          console.log(this.measurementSummarys[this.currentRowIndex]); // public/view/measurement/
          const formula =
            this.measurementSummarys[this.currentRowIndex].container.productId
              .formula;
          console.log(
            'product id ******> ' +
              JSON.stringify(
                this.measurementSummarys[this.currentRowIndex].container
              )
          ); // public/view/measurement/
          console.log(`location ${window.location.hostname}`);
          this.copyPublicContainerViewLink(
            this.measurementSummarys[this.currentRowIndex].container
              .containerId,
            formula
          );
        },
      },
      {
        icon: 'pi pi-external-link',
        label: 'Open Link',
        command: () => {
          const formula =
            this.measurementSummarys[this.currentRowIndex].container.productId
              .formula;
          this.openInNewTabPublicContainerViewLink(
            this.measurementSummarys[this.currentRowIndex].container
              .containerId,
            formula
          );

          // this.messageService.add({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
        },
      },
    ];
  } */
  @ViewChildren('cm') contextMenus!: QueryList<ContextMenu>;

  // showShareContextMenu(event: MouseEvent, index: number){
  //   event.preventDefault();
  //   this.contextMenus.toArray()[index].show(event);
  // }

  private hidingInProgress = false;

  /* showShareContextMenu(
    event: MouseEvent,
    rowIndex: number,
    measurements: MeasurementSummary
  ): void {
    // Prevent opening a new menu if there's a menu being hidden
    if (this.hidingInProgress) return;

    this.hidingInProgress = true;
    this.contextMenus.forEach((cm, index) => {
      if (index !== rowIndex) {
        cm.hide();
      }
    });

    // Allow enough time for the hide operations to complete
    setTimeout(() => {
      this.hidingInProgress = false;
    }, 100); // Adjust this delay if needed

    // Stop propagation to prevent the event reaching higher level components
    event.stopPropagation();

    const contextMenu = this.contextMenus.toArray()[rowIndex - 1];
    contextMenu.show(event);
    this.currentRowIndex = rowIndex - 1; // For copying public url

    // Since the show method is asynchronous, we need to delay our positioning code slightly
    setTimeout(() => {
      const cmElement = contextMenu.containerViewChild
        .nativeElement as HTMLElement;

      // Use the event's clientX and clientY properties to get the mouse click position
      const clickPosition = { x: event.clientX, y: event.clientY };

      // Calculate the offsets based on the dimensions of the context menu or the clicked row
      const offsetY = cmElement.offsetHeight / 2;
      const offsetX = cmElement.offsetWidth / 2;

      // Adjust the context menu position
      cmElement.style.top = `${clickPosition.y - offsetY}px`; // Subtract additional 40
      cmElement.style.left = `${clickPosition.x - offsetX}px`; // Subtract additional 30
    });
  }

  copyPublicContainerViewLink(containerId: number, formula: number) {
    this.containerSerivce.getContainerNumberFromID(containerId).subscribe(
      (data: any) => {
        console.log(`Container UUID is ${JSON.stringify(data)}`);
        const origin = window.location.origin;
        let path = '';
        if (formula === 1) {
          path = origin + AppConstants.PUBLIC_URL_HOPPUS + data.containerUUID;
        } else if (formula === 2) {
          path =
            origin + AppConstants.PUBLIC_URL_VOLUMETRIC + data.containerUUID;
        }
        console.log('URL without path:', origin);
        console.log('URL with path:', path);
        this._clipboardService.copy(path);
        this.messageService.add({
          severity: 'success',
          summary: 'Copied',
          detail: 'Link Copied',
        });
      },
      (error: any) => {
        console.error('Error while loading pulbic url');
      },
      () => {}
    );
  }

  openInNewTabPublicContainerViewLink(containerId: number, formula: number) {
    this.containerSerivce.getContainerNumberFromID(containerId).subscribe(
      (data: any) => {
        console.log(`Container UUID is ${JSON.stringify(data)}`);
        const origin = window.location.origin;
        let path;
        if (formula === 1) {
          path = origin + AppConstants.PUBLIC_URL_HOPPUS + data.containerUUID;
        } else if (formula === 2) {
          path =
            origin + AppConstants.PUBLIC_URL_VOLUMETRIC + data.containerUUID;
          AppConstants;
        }
        console.log('URL without path:', origin);
        console.log('URL with path:', path);
        if (path) {
          window.open(path, '_blank');
        }
        // this.messageService.add({ severity: 'success', summary: 'Copied', detail: 'Link Copied' });
      },
      (error: any) => {
        console.error('Error while loading pulbic url');
      },
      () => {}
    );
  } */
  initSpeedDial() {
    this.measurementSpeedDialItems = [
      {
        icon: 'pi pi-filter-slash',
        command: () => {
          this.globalFiterInput.nativeElement.value = '';
          this.table.clear();
        },
      },
      {
        icon: 'pi pi-search',
        command: () => {
          this.focusOnGlobalFilter();
        },
      },
      /* {
        icon: 'pi pi-trash',
        command: () => {
          this.deleteRows();
        },
      }, */
      {
        icon: 'pi pi-plus',
        command: () => {
          this.router.navigate(['/timber/container']);
          //this.deleteRows()
          //this.messageService.add({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
        },
      },
    ];
  }

  focusOnGlobalFilter() {
    this.globalFiterInput.nativeElement.focus();
  }

  clearFilter(dt: any) {
    this.table.clear();
  }

  storeInEncryptedStorage(containerId: number) {
    // /containers/fetch/{containerId}
    this.containerSerivce.getContainerDTOFromId(containerId).subscribe(
      (data: any) => {
        console.log(`getContainerDTOFromId ${JSON.stringify(data)}`);
        this.encryptedStorageService
          .setEncryptedDataToStorage(AppConstants.CONTAINER, data)
          .subscribe(
            (data: any) => {
              console.log(`Data Stored Successfully`);
              this.router.navigate(['/timber/pl']);
            },
            (error: any) => {},
            () => {}
          );
      },
      (error: any) => {
        console.error(`getContainerDTOFromId error  ${JSON.stringify(error)}`);
      },
      () => {}
    );
  }

  clearAllFilter(table: Table, field: HTMLInputElement) {
    field.value = ''; // cl
    table.clear();
  }

  showShareContextMenu(
    event: MouseEvent,
    rowIndex: number,
    summary: any,
    contextMenu: ContextMenu
  ): void {
    console.log(
      `Container Summary is ${JSON.stringify(
        summary.container.productId.formula
      )}`
    );
    // Close previously open context menu
    if (this.openContextMenu && this.openContextMenu !== contextMenu) {
      this.openContextMenu.hide();
    }

    // Set the current open context menu
    this.openContextMenu = contextMenu;
    this.shareContainerUUID = summary.container.containerUUID;
    this.shareContainerShortenUrl = summary.container.shortenUrl;
    this.shareContainerFormula = summary.container.productId.formula;
    this.containerNumberOnContextMenu = summary.containerId;
    //this.shareContainerFormula =
    //this.shareBLUUID = bl.blUUID;
    // Prevent opening a new menu if there's a menu being hidden
    if (this.hidingInProgress) return;

    this.hidingInProgress = true;

    // Stop propagation to prevent the event reaching higher level components
    event.stopPropagation();

    // Show the context menu and set currentRowIndex
    contextMenu.show(event);
    this.currentRowIndex = rowIndex - 1;

    // Since the show method is asynchronous, we need to delay our positioning code slightly
    // Adjust the context menu position and show it
    setTimeout(() => {
      const cmElement = contextMenu.containerViewChild
        .nativeElement as HTMLElement;
      const clickPosition = { x: event.clientX, y: event.clientY };
      const offsetY = cmElement.offsetHeight / 2;
      const offsetX = cmElement.offsetWidth / 2;

      cmElement.style.top = `${clickPosition.y - offsetY}px`;
      cmElement.style.left = `${clickPosition.x - offsetX}px`;

      this.hidingInProgress = false;
    }, 100); // Adjust this delay if needed
  }
  private shareItemsInit() {
    this.shareItems = [
      /* { label: this.containerNumberOnContextMenu, disabled: true },
      {
        separator: true,
      }, */
      {
        icon: 'pi pi-copy',
        label: 'Copy Link',
        command: () => {
          if (this.shareContainerFormula === 1) {
            this.copyPublicContainerViewLink(
              this.shareContainerShortenUrl, // this.shareContainerUUID,
              this.shareContainerFormula
            );
          } else if (this.shareContainerFormula === 2) {
            this.copyPublicContainerViewLink(
              this.shareContainerShortenUrl, // this.shareContainerUUID,
              this.shareContainerFormula
            );
          }
        },
      },
      {
        icon: 'pi pi-external-link',
        label: 'Open Link',
        command: () => {
          if (this.shareContainerFormula === 1) {
            this.openInNewTabPublicContainerViewLink(
              this.shareContainerShortenUrl, // this.shareContainerUUID,
              this.shareContainerFormula
            );
          } else if (this.shareContainerFormula === 2) {
            this.openInNewTabPublicContainerViewLink(
              this.shareContainerShortenUrl, // this.shareContainerUUID,
              this.shareContainerFormula
            );
          }
          this.openInNewTabPublicContainerViewLink(
            this.shareContainerShortenUrl, // this.shareContainerUUID,
            this.shareContainerFormula
          );
        },
      },
      {
        icon: 'pi pi-whatsapp',
        label: 'Share WhatsApp',
        command: () => {
          let message =
         //   'Hi, your packing list is now available on Lumberlinq. Please check it here: ';
         'Please review the packing list on Lumberlinq via this secure link: \n';


          if (this.shareContainerFormula === 1) {
            this.copyPublicContainerViewLink(
              this.shareContainerShortenUrl, // this.shareContainerUUID,
              this.shareContainerFormula,
              false
            );
          } else if (this.shareContainerFormula === 2) {
            this.copyPublicContainerViewLink(
              this.shareContainerShortenUrl, // this.shareContainerUUID,
              this.shareContainerFormula,
              false
            );
          }
          setTimeout(() => {
            message += this.copyUrl;
            const encodedMessage = encodeURIComponent(message);

            // Define the WhatsApp number here (e.g., '1234567890')
            const whatsappNumber = 'your_whatsapp_number_here';

            // Construct the WhatsApp URL
           // const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodedMessage}`;
           const whatsappUrlnew = `https://api.whatsapp.com/send?text=${encodedMessage}. ${encodeURIComponent("\n\nThis link will open the digital packing list, complete with container photos and a comprehensive container summary.")}`;

           // const whatsappUrlnew = `https://api.whatsapp.com/send?text=${encodedMessage}`;

            // Open the URL to send the message via WhatsApp
            window.open(whatsappUrlnew, '_blank');
          }, 200);

          // Encode the message for URL usage
        },
      },
    ];
  }

  copyPublicContainerViewLink(
    shortUrl: string,
    formula: any,
    isForWhatsapp: boolean = true
  ) {
    const origin = window.location.origin;
    let path = origin; // + AppConstants.PUBLIC_URL_CONTAINER + bluuid;
    if (formula === 1) {
      path = AppConstants.PUBLIC_SHARE_URL + shortUrl;
    } else if (formula === 2) {
      path = AppConstants.PUBLIC_SHARE_URL + shortUrl;
    }

    this._clipboardService.copy(path);
    this.copyUrl = path;

    if (isForWhatsapp) {
      this.messageService.add({
        severity: 'success',
        summary: 'Copied',
        detail: 'Link Copied',
      });
    }
  }

  openInNewTabPublicContainerViewLink(shortUrl: string, formula: any) {
   // const origin = window.location.origin;
    let path;// = origin; // + AppConstants.PUBLIC_URL_CONTAINER + bluuid;
    if (formula === 1) {
      path = AppConstants.PUBLIC_SHARE_URL + shortUrl;
    } else if (formula === 2) {
      path = AppConstants.PUBLIC_SHARE_URL + shortUrl;
    }
    console.log('URL without path:', origin);
    window.open(path, '_blank');
  }

  initTableStyle() {
    this.isMobile = this.utilService.isMobile();
    this.tableStyle = this.utilService.getTableSize();
    this.tableStyleOptions = this.utilService.getTableMinWidth();
  }
}
