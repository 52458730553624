import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { SubscriptionViewService } from '../services/subscription-view.service';
import { MenuItem } from 'primeng/api';
import * as FileSaver from 'file-saver';
import { SHARED_MODULES } from '../../shared-imports';

interface Column {
  field: string;
  header: string;
  format?: string;
}
@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.css'],
  imports: [SHARED_MODULES],
  standalone: true
})
export class TransactionHistoryComponent implements OnInit {
  history!: any;
  protected breadCumbItems!: MenuItem[];
  home!: MenuItem;

  constructor(private subscriptionViewService: SubscriptionViewService) {}

  cols!: Column[];

  _selectedColumns!: Column[];

  initColumns() {
    this.cols = [
      { field: 'sr', header: 'SR' },
      { field: 'date', header: 'Date', },
      { field: 'start_date', header: 'Start Date' },
      { field: 'end_date', header: 'End Date' },
      { field: 'package', header: 'Package' },
      { field: 'duration', header: 'Duration' },
      { field: 'amount', header: 'Amount' },
      { field: 'payment_id', header: 'Payment Id' },
      { field: 'payment_status', header: 'Payment Status' },
      { field: 'auto_debit', header: 'Auto Debit' },
      { field: 'download', header: 'Download' },
    ];

    this._selectedColumns = [
      { field: 'sr', header: 'SR' },
      { field: 'date', header: 'Date' },
      { field: 'start_date', header: 'Start Date' },
      { field: 'end_date', header: 'End Date' },
      { field: 'package', header: 'Package' },
      { field: 'amount', header: 'Amount' },
      { field: 'payment_status', header: 'Payment Status' },
      { field: 'download', header: 'Download' },
    ];
  }

  ngOnInit(): void {
    this.initColumns();
    console.log(`TransactionHistoryComponent Loading now... `);
    this.subscriptionViewService.getHistory().subscribe(
      (response: any) => {
        this.history = response;
        console.log(
          `Response for SubscriptionOptedFeaturesComponent is ${JSON.stringify(
            response
          )}`
        );
      },
      (error: HttpErrorResponse) => {
        console.error(
          `Error for SubscriptionOptedFeaturesComponent is ${JSON.stringify(
            error
          )}`
        );
      },
      () => {}
    );
    this.populateBreadCrumb();
  }

  populateBreadCrumb() {
    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.breadCumbItems = [
      { label: 'Subscription' },
      { label: 'Transaction History' },
    ];
  }

  downloadInvoice(subscriptionOrderId: number) {
    console.log(
      `downloadInvoice -> subscriptionOrderId ${subscriptionOrderId}`
    );
    this.subscriptionViewService.downloadInvoice(subscriptionOrderId).subscribe(
      (response: Blob) => {
        FileSaver.saveAs(response, 'invoice.pdf'); // Replace 'invoice.pdf' with your desired file name
        console.log(`downloadInvoice response is ${JSON.stringify(response)}`);
      },
      (error: any) => {
        console.log(`downloadInvoice error is ${JSON.stringify(error)}`);
      },
      () => {}
    );
  }

  sendInvoiceByEMail(subscriptionOrderId: number) {
    console.log(
      `sendInvoiceByEMail -> subscriptionOrderId ${subscriptionOrderId}`
    );
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.cols.filter((col) => val.includes(col));
  }

  isColumnSelected(fieldName: string): boolean {
    return this._selectedColumns?.some(col => col.field === fieldName) ?? false;
  }
  
}
