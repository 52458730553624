<div class="card">
    <!-- {{ selectedPackage | json }}
    <br /><br />
    {{ selectedDuration | json }} -->


    <p-table [tableStyle]="{'min-width': '30rem'}" [value]="dummy">
        <ng-template pTemplate="header">
            <tr>
                <th class="text-center">Description </th>
                <th class="text-right">Unit Price</th>
                <th class="text-right">Qty</th>
                <th class="text-right">Total</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-sale>
            <tr>
                <td class="text-left">
                    <p class="font-bold">Package: {{selectedPackage?.name?? ''}}</p>
                    <p>Duration: {{selectedDuration?.duration }}</p>
                </td>

                <td class="text-right">
                    {{selectedDuration?.offerPrice?? 0 |
                    currency:selectedDuration?.isoCurrency}}
                </td>

                <td class="text-right">
                    1
                </td>

                <td class="text-right">
                    <!-- {{selectedPackage?.subscriptionPrice.offerPrice?? 0 |
                    currency:selectedCurrency}} -->
                    {{selectedDuration?.offerPrice?? 0 |
                    currency:selectedDuration?.isoCurrency}}
                </td>
            </tr>



            <tr *ngIf="selectedCurrency === 'INR'">
                <td colspan="2"></td>
                <td class="text-right">SGST &#64;; 9.00%</td>
                <td class="text-right"> {{ selectedDuration?.sgst| currency: selectedDuration?.isoCurrency }} </td>
            </tr>
            <tr *ngIf="selectedCurrency === 'INR'">
                <td colspan="2"></td>
                <td class="text-right">&#64;%</td>
                <td colspan="2" class="text-right"> {{ selectedDuration?.cgst| currency: selectedDuration?.isoCurrency
                    }} </td>
            </tr>

            <tr *ngIf="promoApplied"
                class="font-500 fadein animation-duration-1000 animation-iteration-5 scalein animation-linear animation-duration-500 animation-iteration-5">
                <td colspan="2"></td>
                <td class="text-right font-500">Promotion</td>
                <td colspan="2" class="text-right font-500">
                    <span> - </span>

                    <span>{{ promoApplied.discountAmount| currency:
                        selectedDuration?.isoCurrency?? '' }} </span>
                </td>
            </tr>

        </ng-template>

        <ng-template pTemplate="footer">
            <tr>
                <td colspan="2"></td>
                <td class="text-right">Total</td>
                <td colspan="2" class="text-right">
                    <!-- {{ selectedDuration?.grandTotal| currency: selectedDuration?.isoCurrency }}  -->
                    {{ grandTotal| currency: selectedDuration?.isoCurrency }}
                </td>
            </tr>

        </ng-template>
    </p-table>


</div>