import { Component } from '@angular/core';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-user-not-approved',
  templateUrl: './user-not-approved.component.html',
  styleUrls: ['./user-not-approved.component.css'],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class UserNotApprovedComponent {

}
