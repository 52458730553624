<div *ngIf="isLoading; else templateBody">
    <!-- <p-progressSpinner styleClass="w-6rem h-6rem"></p-progressSpinner> -->

    <div>

        <div class="flex flex-wrap">

            <div class="relative w-full h-4rem  md:my-0 border-round">
                <div
                    class="absolute top-0 right-0 font-bold flex align-items-center justify-content-center  border-round">
                    <p-button icon="pi pi-refresh" styleClass="p-button-rounded p-button-info p-button-outlined"
                        (onClick)="refresh()"></p-button>

                </div>
            </div>
        </div>
        <p-table [value]="containerDetailsArray" styleClass="mt-5">
            <ng-template pTemplate="caption" class="text-center">
                Container Summary
            </ng-template>
            <ng-template pTemplate="header">
                <!-- <tr>
            <th>Property</th>
            <th>Value</th>
        </tr> -->
            </ng-template>
            <ng-template pTemplate="body" let-containerData>
                <tr>
                    <td> <p-skeleton></p-skeleton> </td>
                    <td><p-skeleton></p-skeleton></td>
                </tr>
            </ng-template>
        </p-table>



        <div style="padding: 1em 0;">
            <hr style="border-top: 1px solid #000;">
        </div>

        <!-- Width -->
        <p-table [value]="[widthSummary]" styleClass="mt-5" styleClass="p-datatable-gridlines">
            <ng-template pTemplate="caption" class="text-center">
                Width Overview
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Width</th>
                    <th class="text-center" *ngFor="let item of widthSummary"><p-skeleton></p-skeleton></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td>Total Pieces</td>
                    <td class="text-center" *ngFor="let item of rowData"><p-skeleton></p-skeleton></td>
                </tr>
            </ng-template>
        </p-table>


        <div style="padding: 1em 0;">
            <hr style="border-top: 1px solid #000;">
        </div>
        <!-- Height -->
        <p-table [value]="[heightSummary]" styleClass="mt-5" styleClass="mt-5" styleClass="p-datatable-gridlines">
            <ng-template pTemplate="caption" class="text-center">
                Thickness Overview
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Height</th>
                    <th class="text-center" *ngFor="let item of heightSummary"><p-skeleton></p-skeleton></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td>Total Pieces</td>
                    <td class="text-center" *ngFor="let item of rowData"><p-skeleton></p-skeleton></td>
                </tr>
            </ng-template>
        </p-table>

        <div style="padding: 1em 0;">
            <hr style="border-top: 1px solid #000;">
        </div>
        <!-- Length -->
        <p-table [value]="[lengthSummary]" styleClass="mt-5" styleClass="mt-5" styleClass="p-datatable-gridlines">
            <ng-template pTemplate="caption" class="text-center">
                Length Overview
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Length</th>
                    <th class="text-center" *ngFor="let item of lengthSummary"><p-skeleton></p-skeleton></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td>Total Pieces</td>
                    <td class="text-center" *ngFor="let item of rowData"><p-skeleton></p-skeleton></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<ng-template #templateBody>
    <div>

        <div class="flex flex-wrap">

            <div class="relative w-full h-4rem  md:my-0 border-round">
                <div
                    class="absolute top-0 right-0 font-bold flex align-items-center justify-content-center  border-round">
                    <p-button icon="pi pi-refresh" styleClass="p-button-rounded p-button-info p-button-outlined"
                        (onClick)="refresh()"></p-button>

                </div>
            </div>
        </div>
        <p-table [value]="containerDetailsArray" styleClass="mt-5">
            <ng-template pTemplate="caption" class="text-center">
                Container Summary
            </ng-template>
            <ng-template pTemplate="header">
                <!-- <tr>
            <th>Property</th>
            <th>Value</th>
        </tr> -->
            </ng-template>
            <ng-template pTemplate="body" let-containerData>
                <tr>
                    <td>{{containerData.property}}</td>
                    <td>{{containerData.value}}</td>
                </tr>
            </ng-template>
        </p-table>



        <div style="padding: 1em 0;">
            <hr style="border-top: 1px solid #000;">
        </div>

        <!-- Width -->
        <p-table [value]="[widthSummary]" styleClass="mt-5" styleClass="p-datatable-gridlines">
            <ng-template pTemplate="caption" class="text-center">
                Width Overview 
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Width ({{widthUnit  | lowercase }})</th>
                    <th class="text-center" *ngFor="let item of widthSummary">{{item.dimension}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td>Total Pieces</td>
                    <td class="text-center" *ngFor="let item of rowData">{{item.totalPieces}}</td>
                </tr>
            </ng-template>
        </p-table>


        <div style="padding: 1em 0;">
            <hr style="border-top: 1px solid #000;">
        </div>
        <!-- Height -->
        <p-table [value]="[heightSummary]" styleClass="mt-5" styleClass="mt-5" styleClass="p-datatable-gridlines">
            <ng-template pTemplate="caption" class="text-center">
                Thickness Overview 
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Thickness ({{ thicknessUnit   | lowercase }})</th>
                    <th class="text-center" *ngFor="let item of heightSummary">{{item.dimension}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td>Total Pieces</td>
                    <td class="text-center" *ngFor="let item of rowData">{{item.totalPieces}}</td>
                </tr>
            </ng-template>
        </p-table>

        <div style="padding: 1em 0;">
            <hr style="border-top: 1px solid #000;">
        </div>
        <!-- Length -->
        <p-table [value]="[lengthSummary]" styleClass="mt-5" styleClass="mt-5" styleClass="p-datatable-gridlines">
            <ng-template pTemplate="caption" class="text-center">
                Length Overview
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Length ({{ lengthUnit | lowercase }}) </th>
                    <th class="text-center" *ngFor="let item of lengthSummary">{{item.dimension}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td>Total Pieces</td>
                    <td class="text-center" *ngFor="let item of rowData">{{item.totalPieces}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</ng-template>