import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SawnTimberMeasurementComponent } from '../modules/sawn-timber/components/sawn-timber-measurement/sawn-timber-measurement.component';

@Injectable({
  providedIn: 'root'
})
export class MeasurementCanDeactivateGuard implements CanDeactivate<SawnTimberMeasurementComponent> {
  canDeactivate(component: SawnTimberMeasurementComponent): boolean | Observable<boolean> {
    return component.canDeactivate();
  }
  
}
