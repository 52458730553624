import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HistogramDataService {

  createHistogramBins(records: number[], totalBins: number) {
    let min = Math.min(...records);
    let max = Math.max(...records);
    
    // Calculate the bin size
    let binSize = Math.ceil((max - min + 1) / totalBins);

    // If the bin size is not a round number, increase it to the next round number
    if (binSize % 5 !== 0) {
        binSize = binSize + (5 - binSize % 5);
    }

    // Calculate the number of bins based on the new bin size
    totalBins = Math.floor((max - min + 1) / binSize);

    // Generate the bins
    let bins = Array(totalBins).fill(0).map((_, i) => min + i * binSize);

    // Add the last bin to include any remaining values
    if (bins[bins.length - 1] + binSize <= max) {
        bins.push(bins[bins.length - 1] + binSize);
    }
    
    return bins;
}

countRecordsInBins(records: number[], bins: number[]) {
    let histogramData = [];
    for (let i = 0; i < bins.length - 1; i++) {
        let count = records.filter(x => x >= bins[i] && x < bins[i + 1]).length;
        let range = bins[i + 1] === Math.max(...bins) && count > 0 ? bins[i] + "+" : bins[i] + "-" + (bins[i + 1] - 1);
        histogramData.push({ range: range, count: count });
    }
    return histogramData.filter(bin => bin.count > 0);  // Filter out bins with zero count
}

}

