import { HostListener, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShortcutCommonService {


  private saveShortcutSubject = new Subject<void>();
  saveShortcut = this.saveShortcutSubject.asObservable();

  // @HostListener('window:keydown.Meta.Shift.s', ['$event'])
  @HostListener('window:keydown.meta.shift.s', ['$event'])

  handleSaveShortcut(event: KeyboardEvent) {
    event.preventDefault();
    this.saveShortcutSubject.next();
  }


  constructor() {
    document.addEventListener('keydown', (event) => {
      // Check if Meta (Command) + Shift + S keys are pressed
      if (event.metaKey && event.shiftKey && event.key === 'S') {
        event.preventDefault();
        this.saveShortcutSubject.next();
      }
    });
  }
  
}
