<div class="pb-3" *ngIf="showBreadcrumb">
    <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
</div>
<p-card #card header="Subscription Master">
    <form [formGroup]="subscriptionForm" (ngSubmit)="onSubmit()">

        <!-- subscriptionId -> {{ subscriptionId}} -->
        <!-- Subscription Section -->
        <p-panel header="Subscription Details">
            <div formGroupName="subscription">
                <input type="hidden" formControlName="id">
                <!--  <label for="name">Name:</label>
            <input type="text" pInputText formControlName="name" id="name" /> -->
                <!-- Add more subscription fields with labels as needed -->
                <label for="sortOrder">Sort Order:</label>
                <p-inputNumber inputId="integeronly" [min]="0" formControlName="sortOrder" id="sortOrder">
                </p-inputNumber>
            </div>
        </p-panel>

        <!-- SubscriptionType Section -->
        <p-panel header="Subscription Type Details">
            <div formGroupName="subscriptionType">
                <div class="formgrid grid">
                    <div class="field col-12 md:col-6">
                        <input type="hidden" formControlName="id">

                        <label for="typeName" class="pt-2">Type Name:</label>
                        <input type="text" pInputText formControlName="name" id="typeName"
                            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                        <div
                            *ngIf="subscriptionForm.get('subscriptionType')?.get('name')?.touched || subscriptionForm.get('subscriptionType')?.get('name')?.dirty">
                            <span *ngIf="subscriptionForm.get('subscriptionType')?.get('name')?.errors?.['required']"
                                class="text-red-400">Name is required.</span>
                            <span *ngIf="subscriptionForm.get('subscriptionType')?.get('name')?.errors?.['minlength']"
                                class="text-red-400">Minimum length is {{
                                subscriptionForm.get('subscriptionType')?.get('name')?.errors?.['minlength']?.requiredLength
                                }}.</span>
                            <span *ngIf="subscriptionForm.get('subscriptionType')?.get('name')?.errors?.['maxlength']"
                                class="text-red-400">Maximum length is {{
                                subscriptionForm.get('subscriptionType')?.get('name')?.errors?.['maxlength']?.requiredLength
                                }}.</span>
                        </div>


                    </div>
                    <div class="field col-12 md:col-6">
                        <label for="description" class="pt-2">Description:</label>
                        <input type="text" pInputText formControlName="description" id="description"
                            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                        <div
                            *ngIf="subscriptionForm.get('subscriptionType')?.get('description')?.touched || subscriptionForm.get('subscriptionType')?.get('description')?.dirty">
                            <span
                                *ngIf="subscriptionForm.get('subscriptionType')?.get('description')?.errors?.['required']"
                                class="text-red-400">Desription is required.</span>
                            <span
                                *ngIf="subscriptionForm.get('subscriptionType')?.get('description')?.errors?.['maxlength']"
                                class="text-red-400">Maximum length is {{
                                subscriptionForm.get('subscriptionType')?.get('description')?.errors?.['maxlength']?.requiredLength
                                }}.</span>
                        </div>
                    </div>
                    <div class="field col-12 md:col-6">
                        <label for="header" class="pt-2">Header:</label>
                        <input type="text" pInputText formControlName="header" id="header"
                            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                        <div
                            *ngIf="subscriptionForm.get('subscriptionType')?.get('header')?.touched || subscriptionForm.get('subscriptionType')?.get('header')?.dirty">
                            <span *ngIf="subscriptionForm.get('subscriptionType')?.get('header')?.errors?.['required']"
                                class="text-red-400">Header is required.</span>
                            <span *ngIf="subscriptionForm.get('subscriptionType')?.get('header')?.errors?.['minlength']"
                                class="text-red-400">Minimum length is {{
                                subscriptionForm.get('subscriptionType')?.get('header')?.errors?.['minlength']?.requiredLength
                                }}.</span>
                            <span *ngIf="subscriptionForm.get('subscriptionType')?.get('header')?.errors?.['maxlength']"
                                class="text-red-400">Maximum length is {{
                                subscriptionForm.get('subscriptionType')?.get('header')?.errors?.['maxlength']?.requiredLength
                                }}.</span>
                        </div>
                    </div>
                    <div class="field col-12 md:col-6">
                        <label for="subHeader" class="pt-2">Sub Header:</label>
                        <input type="text" pInputText formControlName="subHeader" id="subHeader"
                            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                        <div
                            *ngIf="subscriptionForm.get('subscriptionType')?.get('subHeader')?.touched || subscriptionForm.get('subscriptionType')?.get('subHeader')?.dirty">
                            <span
                                *ngIf="subscriptionForm.get('subscriptionType')?.get('subHeader')?.errors?.['required']"
                                class="text-red-400">Sub Header is required.</span>
                            <span
                                *ngIf="subscriptionForm.get('subscriptionType')?.get('subHeader')?.errors?.['minlength']"
                                class="text-red-400">Minimum length is {{
                                subscriptionForm.get('subscriptionType')?.get('subHeader')?.errors?.['minlength']?.requiredLength
                                }}.</span>
                            <span
                                *ngIf="subscriptionForm.get('subscriptionType')?.get('subHeader')?.errors?.['maxlength']"
                                class="text-red-400">Maximum length is {{
                                subscriptionForm.get('subscriptionType')?.get('subHeader')?.errors?.['maxlength']?.requiredLength
                                }}.</span>
                        </div>
                    </div>

                    <div class="field col-6 md:col-3">
                        <label for="gracePeriod" class="pt-2">Grace Period:</label>
                        <p-inputNumber inputId="integeronly" [min]="0" formControlName="gracePeriod" id="gracePeriod"
                            styleClass="w-full"
                            class="text-base text-color surface-overlay p-2 surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                        <div
                            *ngIf="subscriptionForm.get('subscriptionType')?.get('gracePeriod')?.touched || subscriptionForm.get('subscriptionType')?.get('gracePeriod')?.dirty">
                            <span
                                *ngIf="subscriptionForm.get('subscriptionType')?.get('gracePeriod')?.errors?.['required']"
                                class="text-red-400">Grace Period is required.</span>
                        </div>
                    </div>

                    <div class="field col-6 md:col-3">
                        <label for="renewalReminderDays" class="pt-2">Renewal Reminder Days:</label>
                        <p-inputNumber inputId="integeronly" [min]="0" formControlName="renewalReminderDays"
                            id="renewalReminderDays" styleClass="w-full"
                            class="text-base text-color surface-overlay p-2 surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                        <div
                            *ngIf="subscriptionForm.get('subscriptionType')?.get('renewalReminderDays')?.touched || subscriptionForm.get('subscriptionType')?.get('renewalReminderDays')?.dirty">
                            <span
                                *ngIf="subscriptionForm.get('subscriptionType')?.get('renewalReminderDays')?.errors?.['required']"
                                class="text-red-400">Reminder Days is required.</span>
                        </div>
                    </div>

                </div>

            </div>
        </p-panel>

        <!-- SubscriptionLimit Section -->
        <p-panel header="Subscription Limit Details">
            <div formGroupName="subscriptionLimit">
                <div class="formgrid grid">
                    <div class="field col-4 md:col-3">
                        <input type="hidden" formControlName="id">
                        <label for="numberOfUsers" class="pt-2">Number of Users:</label>
                        <p-inputNumber inputId="integeronly" [min]="0" formControlName="numberOfUsers"
                            id="numberOfUsers" class="text-base text-color focus:border-primary w-full">
                        </p-inputNumber>
                        <div
                            *ngIf="subscriptionForm.get('subscriptionLimit')?.get('numberOfUsers')?.touched || subscriptionForm.get('subscriptionLimit')?.get('numberOfUsers')?.dirty">
                            <span
                                *ngIf="subscriptionForm.get('subscriptionLimit')?.get('numberOfUsers')?.errors?.['required']"
                                class="text-red-400">Duration is required.</span>
                        </div>
                    </div>
                    <div class="field col-4 md:col-3">
                        <label for="numberOfPackingList" class="pt-2">Number of Packing List:</label>
                        <p-inputNumber inputId="integeronly" [min]="0" formControlName="numberOfPackingList"
                            id="numberOfPackingList"
                            class="text-base text-color focus:border-primary w-full"></p-inputNumber>
                        <div
                            *ngIf="subscriptionForm.get('subscriptionLimit')?.get('numberOfPackingList')?.touched || subscriptionForm.get('subscriptionLimit')?.get('numberOfPackingList')?.dirty">
                            <span
                                *ngIf="subscriptionForm.get('subscriptionLimit')?.get('numberOfPackingList')?.errors?.['required']"
                                class="text-red-400">No. of Packing List is required.</span>
                        </div>
                    </div>
                    <div class="field col-4 md:col-3">
                        <label for="numberOfBuyers" class="pt-2">Number of Buyers:</label>
                        <p-inputNumber inputId="integeronly" [min]="0" formControlName="numberOfBuyers"
                            id="numberOfBuyers"
                            class="text-base text-color focus:border-primary w-full"></p-inputNumber>
                        <div
                            *ngIf="subscriptionForm.get('subscriptionLimit')?.get('numberOfPackingList')?.touched || subscriptionForm.get('subscriptionLimit')?.get('numberOfBuyers')?.dirty">
                            <span
                                *ngIf="subscriptionForm.get('subscriptionLimit')?.get('numberOfBuyers')?.errors?.['required']"
                                class="text-red-400">No. of Buyers is required.</span>
                        </div>
                    </div>
                    <div class="field col-4 md:col-3">
                        <label for="numberOfSellers" class="pt-2">Number of Sellers:</label>
                        <p-inputNumber inputId="integeronly" [min]="0" formControlName="numberOfSellers"
                            id="numberOfSellers"
                            class="text-base text-color focus:border-primary w-full"></p-inputNumber>
                        <div
                            *ngIf="subscriptionForm.get('subscriptionLimit')?.get('numberOfSellers')?.touched || subscriptionForm.get('subscriptionLimit')?.get('numberOfSellers')?.dirty">
                            <span
                                *ngIf="subscriptionForm.get('subscriptionLimit')?.get('numberOfSellers')?.errors?.['required']"
                                class="text-red-400">No. of Sellers is required.</span>
                        </div>
                    </div>
                    <div class="field col-4 md:col-3">
                        <label for="numberOfProducts" class="pt-2">Number of Products:</label>
                        <p-inputNumber inputId="integeronly" [min]="0" formControlName="numberOfProducts"
                            id="numberOfProducts"
                            class="text-base text-color focus:border-primary w-full"></p-inputNumber>
                        <div
                            *ngIf="subscriptionForm.get('subscriptionLimit')?.get('numberOfProducts')?.touched || subscriptionForm.get('subscriptionLimit')?.get('numberOfProducts')?.dirty">
                            <span
                                *ngIf="subscriptionForm.get('subscriptionLimit')?.get('numberOfProducts')?.errors?.['required']"
                                class="text-red-400">No. of Products is required.</span>
                        </div>
                    </div>
                    <div class="field col-4 md:col-3">
                        <label for="numberOfSites" class="pt-2">Number of Sites:</label>
                        <p-inputNumber inputId="integeronly" [min]="0" formControlName="numberOfSites"
                            id="numberOfSites" class="text-base text-color focus:border-primary w-full"></p-inputNumber>
                        <div
                            *ngIf="subscriptionForm.get('subscriptionLimit')?.get('numberOfSites')?.touched || subscriptionForm.get('subscriptionLimit')?.get('numberOfSites')?.dirty">
                            <span
                                *ngIf="subscriptionForm.get('subscriptionLimit')?.get('numberOfSites')?.errors?.['required']"
                                class="text-red-400">No. of Sites is required.</span>
                        </div>
                    </div>
                    <div class="field col-4 md:col-3">
                        <label for="numberOfBL" class="pt-2">Number of BL:</label>
                        <p-inputNumber inputId="integeronly" [min]="0" formControlName="numberOfBL" id="numberOfBL"
                            class="text-base text-color focus:border-primary w-full">
                        </p-inputNumber>
                        <div
                            *ngIf="subscriptionForm.get('subscriptionLimit')?.get('numberOfBL')?.touched || subscriptionForm.get('subscriptionLimit')?.get('numberOfBL')?.dirty">
                            <span
                                *ngIf="subscriptionForm.get('subscriptionLimit')?.get('numberOfBL')?.errors?.['required']"
                                class="text-red-400">No. of BL is required.</span>
                        </div>
                    </div>

                    <div class="field col-4 md:col-3">
                        <label for="numberOfPhotos" class="pt-2">Number of Images:</label>
                        <p-inputNumber inputId="integeronly" [min]="0" formControlName="numberOfPhotos"
                            id="numberOfPhotos" class="text-base text-color focus:border-primary w-full">
                        </p-inputNumber>
                        <div
                            *ngIf="subscriptionForm.get('subscriptionLimit')?.get('numberOfPhotos')?.touched || subscriptionForm.get('subscriptionLimit')?.get('numberOfPhotos')?.dirty">
                            <span
                                *ngIf="subscriptionForm.get('subscriptionLimit')?.get('numberOfPhotos')?.errors?.['required']"
                                class="text-red-400">No. of Images is required.</span>
                        </div>
                    </div>

                    <div class="field col-4 md:col-3">
                        <label for="retentionOfPhotos" class="pt-2">Image Retention:</label>
                        <p-inputNumber inputId="integeronly" [min]="0" formControlName="retentionOfPhotos"
                            id="retentionOfPhotos" class="text-base text-color focus:border-primary w-full">
                        </p-inputNumber>
                        <div
                            *ngIf="subscriptionForm.get('subscriptionLimit')?.get('retentionOfPhotos')?.touched || subscriptionForm.get('subscriptionLimit')?.get('retentionOfPhotos')?.dirty">
                            <span
                                *ngIf="subscriptionForm.get('subscriptionLimit')?.get('retentionOfPhotos')?.errors?.['required']"
                                class="text-red-400">Retention of Photo is required.</span>
                        </div>
                    </div>

                    <div class="field col-4 md:col-3">
                        <label for="numberOfContainers" class="pt-2">Number of Containers:</label>
                        <p-inputNumber inputId="integeronly" [min]="0" formControlName="numberOfContainers"
                            id="numberOfContainers" class="text-base text-color focus:border-primary w-full">
                        </p-inputNumber>
                        <div
                            *ngIf="subscriptionForm.get('subscriptionLimit')?.get('numberOfContainers')?.touched || subscriptionForm.get('subscriptionLimit')?.get('numberOfContainers')?.dirty">
                            <span
                                *ngIf="subscriptionForm.get('subscriptionLimit')?.get('numberOfContainers')?.errors?.['required']"
                                class="text-red-400">No. of Containers is required.</span>
                        </div>
                    </div>
                </div>


            </div>
        </p-panel>

        <p-panel header="Subscription Pricings">
            <div formArrayName="subscriptionPricings">
                <div *ngFor="let pricing of subscriptionPricings.controls; let i = index" [formGroupName]="i"
                    class="formgrid grid">

                    <!-- Pricing Fields -->
                    <div class="field col-12 md:col-4">
                        <label for="duration">Duration:
                        </label>
                        <p-dropdown [options]="durationList" formControlName="duration" optionLabel="period"
                            id="duration" [virtualScroll]="true" [virtualScrollItemSize]="38"
                            placeholder="Select Duration" [showClear]="true" styleClass="field col-12 w-full"
                            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full"></p-dropdown>
                        <div *ngIf="pricing.get('duration')?.touched || pricing.get('duration')?.dirty">
                            <span *ngIf="pricing.get('duration')?.errors?.['required']" class="text-red-400">Duration is
                                required.</span>
                        </div>
                    </div>
                    <div class="field col-12 md:col-4">
                        <label for="currency">Currency:</label>
                        <p-dropdown [options]="currencyList" formControlName="currency" optionLabel="isoCurrencyName"
                            id="currency" placeholder="Select Currency" [showClear]="true"
                            styleClass="field col-12 w-full"
                            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full"></p-dropdown>
                        <div *ngIf="pricing.get('currency')?.touched || pricing.get('currency')?.dirty">
                            <span *ngIf="pricing.get('currency')?.errors?.['required']" class="text-red-400">Currency is
                                required.</span>
                        </div>
                    </div>

                    <div class="field col-12 md:col-4">
                        <label for="message{{i}}">Message:</label>
                        <input type="text" pInputText formControlName="message" id="message" class="w-full" />
                        <div *ngIf="pricing.get('message')?.touched || pricing.get('message')?.dirty">
                            <span *ngIf="pricing.get('message')?.errors?.['required']" class="text-red-400">Message is
                                required.</span>
                        </div>
                    </div>
                    <div class="field col-12 md:col-3">
                        <label for="price{{i}}">Price:</label>
                        <p-inputNumber inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2"
                            [maxFractionDigits]="2" [min]="0" formControlName="price" id="price{{i}}"
                            styleClass="w-full"
                            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </p-inputNumber>
                        <div *ngIf="pricing.get('price')?.touched || pricing.get('price')?.dirty">
                            <span *ngIf="pricing.get('price')?.errors?.['required']" class="text-red-400">Price is
                                required.</span>
                        </div>
                    </div>

                    <div class="field col-12 md:col-3">
                        <label for="offerPrice{{i}}">Offer Price:</label>
                        <p-inputNumber inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2"
                            [maxFractionDigits]="2" [min]="0" formControlName="offerPrice" id="offerPrice{{i}}"
                            styleClass="w-full"
                            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </p-inputNumber>
                        <div *ngIf="pricing.get('offerPrice')?.touched || pricing.get('offerPrice')?.dirty">
                            <span *ngIf="pricing.get('offerPrice')?.errors?.['required']" class="text-red-400">Offer
                                Price
                                is
                                required.</span>
                        </div>
                    </div>

                    <div class="field col-12 md:col-3">
                        <label for="offerPrice{{i}}">Discount Amount: {{ pricing.get('discountPercentage')?.value?? 0 |
                            number:'1.0-0' }}%</label>

                         <p-inputNumber inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" [readonly]="true"
                            [maxFractionDigits]="2" [min]="0" formControlName="discountAmount" id="discountAmount{{i}}"
                            styleClass="read-only-field">
                        </p-inputNumber>
                       <!--  <p
                            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full">
                            {{ getDisplayValue(pricing.get('discountAmount')?.value) }}
                        </p> -->

                    </div>

                    <input type="hidden" formControlName="discountPercentage">


                    <input type="hidden" formControlName="amountSaved">


                    <div class="field col-12 md:col-3"
                        *ngIf="pricing.get('currency')?.value?.isoCurrencyName === 'INR'">
                        <label for="totalGst{{i}}">Total GST:</label>
                        <input type="hidden" formControlName="totalGst">
                       <!--  <p
                            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full">
                            {{ getDisplayValue(pricing.get('totalGst')?.value) }}
                        </p> -->
                        <p-inputNumber inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2"
                            [maxFractionDigits]="2" [min]="0" formControlName="totalGst" id="totalGst{{i}}"
                            styleClass="w-full read-only-field" [readonly]="true"
                            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full read-only-field">
                        </p-inputNumber>
                    </div>

                    <div class="field col-12 md:col-3"
                        *ngIf="pricing.get('currency')?.value?.isoCurrencyName   === 'INR'">
                        <label for="cgst{{i}}">CGST:</label>
                        <!-- <p
                            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full">
                            {{ getDisplayValue(pricing.get('cgst')?.value) }}
                        </p> -->

                         <p-inputNumber inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2"
                            [maxFractionDigits]="2" [min]="0" formControlName="cgst" id="cgst{{i}}" styleClass="w-full  read-only-field" [readonly]="true"
                            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full ">
                        </p-inputNumber>
                    </div>

                    <div class="field col-12 md:col-3"
                        *ngIf="pricing.get('currency')?.value?.isoCurrencyName === 'INR'">
                        <label for="sgst{{i}}">SGST:</label>
                        <!-- <p
                            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full">
                            {{ getDisplayValue(pricing.get('sgst')?.value) }}
                        </p> -->

                          <p-inputNumber inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2"
                            [maxFractionDigits]="2" [min]="0" formControlName="sgst" id="sgst{{i}}" styleClass="w-full read-only-field" [readonly]="true"
                            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </p-inputNumber>
                    </div>

                    <div class="field col-12 md:col-3"
                        *ngIf="pricing.get('currency')?.value?.isoCurrencyName === 'INR'">
                        <label for="igst{{i}}">IGST:</label>
                      <!--   <p
                            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full">
                            {{ getDisplayValue(pricing.get('igst')?.value) }}
                        </p> -->

                         <p-inputNumber inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2"
                            [maxFractionDigits]="2" [min]="0" formControlName="igst" id="igst{{i}}" styleClass="w-full read-only-field" [readonly]="true"
                            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </p-inputNumber>
                    </div>

                    <div class="field col-12 md:col-3">
                        <label for="grandTotal{{i}}">Grand Total:</label>
                        <!-- <p
                            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full">
                            {{ getDisplayValue(pricing.get('grandTotal')?.value) }}
                        </p> -->

                         <p-inputNumber inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" [readonly]="true"
                            [maxFractionDigits]="2" [min]="0" formControlName="grandTotal" id="grandTotal{{i}}"
                            styleClass="w-full read-only-field"
                            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </p-inputNumber>
                    </div>

                    <!-- Remove Button (only if there's more than one control) -->
                    <!-- <button *ngIf="subscriptionPricings.length > 1" pButton type="button" (click)="removePricingControl(i)"
                    label="Remove"></button>
                <button pButton type="button" (click)="addPricingControl()" label="Add Pricing"></button> -->

                    <p-button icon="pi pi-plus" styleClass="p-button-sm p-button-success p-button-outlined"
                        class="pr-2 pl-2 pb-2" (click)="addPricingControl()" label="Add Pricing"></p-button>
                    <p-button icon="pi pi-times" styleClass="p-button-sm p-button-danger p-button-outlined"
                        (click)="removePricingControl(i)" *ngIf="subscriptionPricings.length > 1" label="Remove Pricing"
                        class="pb-2"></p-button>

                </div>

            </div>

            <!-- Add Button -->
        </p-panel>


        <!-- SubscriptionFeatures Section -->
        <p-panel header="Subscription Features">
            <div formArrayName="subscriptionFeatures">
                <div *ngFor="let feature of subscriptionFeatures.controls; let i = index" [formGroupName]="i">
                    <!-- Feature Fields -->
                    <label for="featureName{{i}}">Feature Name:</label>
                    <input type="text" pInputText formControlName="name" id="featureName{{i}}" />
                    <div *ngIf="feature.get('name')?.touched || feature.get('name')?.dirty">
                        <span *ngIf="feature.get('name')?.errors?.['required']" class="text-red-400">Name is
                            required.</span>
                        <span *ngIf="feature.get('name')?.errors?.['minlength']" class="text-red-400">Minimum length is
                            {{
                            feature.get('name')?.errors?.['minlength']?.requiredLength
                            }}.</span>
                        <span *ngIf="feature.get('name')?.errors?.['maxlength']" class="text-red-400">Maximum length is
                            {{
                            feature.get('name')?.errors?.['maxlength']?.requiredLength
                            }}.</span>
                    </div>
                    <!-- ... other feature fields ... -->

                    <!-- Remove Button (only if there's more than one control) -->
                    <p-button icon="pi pi-plus"
                        styleClass="p-button-sm p-button-rounded p-button-success p-button-outlined" class="pr-2 pl-2"
                        (click)="addFeatureControl()"></p-button>
                    <p-button icon="pi pi-times"
                        styleClass="p-button-sm p-button-rounded p-button-danger p-button-outlined"
                        (click)="removeFeatureControl(i)" *ngIf="subscriptionFeatures.length > 1"></p-button>

                    <!--   <button *ngIf="subscriptionFeatures.length > 1" pButton type="button"
                        (click)="removeFeatureControl(i)" label="Remove" class="pl-3 pr-3"></button>
                    <button pButton type="button" (click)="addFeatureControl()" label="Add Feature"></button> -->
                </div>
            </div>

            <!-- Add Button -->

        </p-panel>

        <div class="pt-3">

            <p-button icon="pi pi-save" styleClass="p-button p-button-success w-7rem" class="p-2" label="Save"
                (onClick)="onSubmit()"></p-button>

            <p-button icon="pi pi-trash" styleClass="p-button p-button-danger w-7rem" (click)="confirmDelete()"
                label="Delete" class="p-2"></p-button>
        </div>

        <!-- <button pButton type="submit" label="Submit"></button>
        <button pButton type="button" label="Delete" (click)="confirmDelete()"></button> -->
    </form>

    <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
</p-card>

<p-blockUI [target]="card" [blocked]="blockedPanel">
    <!-- <i class="pi pi-lock" style="font-size: 6rem"></i> -->
    <i class="pi pi-spin pi-spinner" style="font-size: 6rem"></i>

</p-blockUI>