<div class="block w-full" (mousedown)="onSwipeStart($event)" (mouseup)="onSwipeEnd($event)"
    (touchstart)="onSwipeStart($event)" (touchend)="onSwipeEnd($event)">

    <p-fileUpload #fileUpload name="files" [url]="uploadUrl" [customUpload]="true" [multiple]="true"
        accept="image/*,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,text/csv"
        maxFileSize="1000000" (uploadHandler)="onTemplatedUpload($event)" (onSelect)="onSelectedFiles($event)"
        [fileLimit]="5">
        <ng-template pTemplate="header" let-files let-chooseCallback="chooseCallback" let-clearCallback="clearCallback"
            let-uploadCallback="uploadCallback">
            <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                <div class="flex gap-2">
                    <p-button (onClick)="choose($event, chooseCallback)" icon="pi pi-images" [rounded]="true"
                        [outlined]="true" />
                    <p-button (onClick)="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload" [rounded]="true"
                        [outlined]="true" severity="success"
                        [disabled]="!files || files.length === 0 || files.length > 5" />
                    <p-button (onClick)="clearCallback()" icon="pi pi-times" [rounded]="true" [outlined]="true"
                        severity="danger" [disabled]="!files || files.length === 0" />
                </div>
                <p-progressBar [value]="totalSizePercent" [showValue]="false"
                    styleClass="md:w-20rem h-1rem w-full md:ml-auto"
                    [ngClass]="{ 'exceeded-progress-bar': totalSizePercent > 100 }">
                    <span class="white-space-nowrap">{{ totalSize }}B / 1Mb</span>
                </p-progressBar>
            </div>
        </ng-template>
        <ng-template pTemplate="content" let-files let-uploadedFiles="uploadedFiles"
            let-removeFileCallback="removeFileCallback" let-removeUploadedFileCallback="removeUploadedFileCallback">
            <div *ngIf="files?.length > 0">
                <h5>{{uploadStatus}}</h5>
                <div class="flex flex-wrap p-0 sm:p-5 gap-5">
                    <div *ngFor="let file of files; let i = index"
                        class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
                        <div>
                            <img role="presentation" [alt]="file.name" [src]="file.objectURL" width="100" height="50" />
                        </div>
                        <span class="font-semibold">{{ file.name }}</span>
                        <div>{{ formatSize(file.size) }}</div>
                        <p-badge [value]="uploadStatus" [severity]="uploadStatusSeverity" />
                        <p-button icon="pi pi-times" *ngIf="uploadStatus === 'Pending' "
                            (onClick)="onRemoveTemplatingFile($event, file, removeFileCallback, i)" [outlined]="true"
                            [rounded]="true" severity="danger" />
                        <p-button icon="pi pi-check" *ngIf="uploadStatus === 'Uploaded' " [outlined]="true"
                            [rounded]="true" severity="success" />
                    </div>
                </div>
            </div>
            <div *ngIf="uploadedFiles?.length > 0">
                <h5>Completed</h5>
                <div class="flex flex-wrap p-0 sm:p-5 gap-5">
                    <div *ngFor="let file of uploadedFiles; let i = index"
                        class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
                        <div>
                            <img role="presentation" [alt]="file.name" [src]="file.objectURL" width="100" height="50" />
                        </div>
                        <span class="font-semibold">{{ file.name }}</span>
                        <div>{{ formatSize(file.size) }}</div>
                        <p-badge value="Completed" class="mt-3" severity="success" />
                        <p-button icon="pi pi-times" (onClick)="removeUploadedFileCallback(i)" [outlined]="true"
                            [rounded]="true" severity="danger" />
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="file"> </ng-template>
        <ng-template pTemplate="empty">
            <div class="flex align-items-center justify-content-center flex-column">
                <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400"></i>
                <p class="mt-4 mb-0">Drag and drop files here to upload.</p>
            </div>
        </ng-template>
    </p-fileUpload>
    <!-- <input type="hidden" [formControlName]="'documents'" /> -->

    <div class="card">
        <div class="flex justify-content-between align-items-center m-2">
            <!-- Left-aligned Button -->
            <p-button label="{{ allowSelectionImage ? 'Unselect' : 'Select' }}" (click)="toggleSelection()"
                [text]="true" [raised]="true" *ngIf="allowSelectionImage && (selectedImages && selectedImages.length > 0)">
            </p-button>

            <!-- Right-aligned Button -->
            <p-button icon="pi pi-bars" *ngIf="allowSelectionImage && (selectedImages && selectedImages.length > 0)"
                (click)="imageSelectionMenuFn($event)" [raised]="true" class="w-auto" [text]="true">
            </p-button>
        </div>

        <div class="card" style="position: relative; z-index: 1;" *ngIf="galleriaVisibility">

            <div *ngIf="images && images.length > 0; else showSkeleton">
                <div *ngFor="let image of images; let index = index" class="col-3" [attr.key]="index"
                    style="position: relative; display: inline-block; width: 100px; height: 100px; margin-right: 20px; margin-bottom: 20px;">
                    <div class="relative w-6rem h-6rem">
                        <!-- Thumbnail Image -->

                        <!-- Show Image if fileType includes 'image' -->
                        <img *ngIf="image.fileType.includes('image')" [src]="image.thumbnailUrl"
                            [alt]="image.thumbnailUrl" class="w-full h-full cursor-pointer "
                            (click)="allowSelectionImage ? onImageToggle(image) : imageClick(index, image.fileType)"
                            [ngClass]="{'opacity-50': isImageSelected(index), 'opacity-200 border-4': !isImageSelected(index)}" />

                        <!-- Show Icon if fileType is not 'image' -->
                        <!-- {{ !image.fileType.includes('image') }} ** *** -->
                        <i *ngIf="!image.fileType.includes('image')" [ngClass]="getIconClass(image.fileType, index)"
                            class="cursor-pointer"
                            (click)="allowSelectionImage ? onImageToggle(image) : imageClick(index, image.fileType)"
                            style="font-size: 6.25rem">
                        </i>

                        <!-- Top Right Icon Span -->
                        <span class="absolute top-0 right-0 z-3 shadow-3">
                            <!-- <i [class]="getImageClass(image.privacy)" (click)="confirmDeleteImage($event, index, true)" *ngIf="image.thumbnailUrl" size="medium"></i> -->
                            <p-button [icon]="getImageClass(image.privacy)" [rounded]="true" severity="danger"
                                size="small" [text]="true" [outlined]="true" />

                        </span>

                        <!-- Loader -->
                        <div *ngIf="loadingDeleteImage[index]"
                            class="absolute top-0 left-0 w-full h-full flex align-items-center justify-content-center bg-white-alpha-70 z-10">
                            <i class="pi pi-spin pi-spinner text-2xl"></i>
                        </div>

                        <!-- Footer with Menu Button -->
                        <div class="absolute bottom-0 left-0 w-full flex justify-content-start">
                            <p-button icon="pi pi-bars" *ngIf="!allowSelectionImage"
                                (click)="attachmentMenuItemClicked($event, index)" [raised]="true" class="w-auto"
                                size="small" [text]="true"></p-button>
                        </div>
                    </div>
                </div>
                <ng-template pTemplate=" footer">
                    <div style="z-index: 25">

                    </div>
                </ng-template>
            </div>
            <ng-template #showSkeleton>

                <div class="flex flex-wrap gap-5">
                    <p-skeleton *ngFor="let i of [].constructor(tempDocumentsSize); let index = index" width="100px"
                        height="100px">
                    </p-skeleton>
                </div>
            </ng-template>
            <!-- Galleria component with high z-index to act as overlay -->
            <!-- @ts-ignore -->
            <p-galleria [value]="images" (onValueChange)="updateGalleryValue($event)" [(visible)]="displayCustom"
                [(activeIndex)]="activeIndex" ngModelOptions="{standalone: true}"
                [responsiveOptions]="responsiveOptions" [containerStyle]="{ 'max-width': '850px', 'z-index': '9999' }"
                [numVisible]="7" [circular]="true" [fullScreen]="true" [showItemNavigators]="true"
                [showThumbnails]="false" (activeIndexChange)="changedGalleryIndex($event)">
                <ng-template pTemplate="item" let-item>
                    <!-- Ensure big-image-container overlays by z-index -->
                    <div class="big-image-container" style="position: relative; z-index: 16; padding: 16px;">
                        <p-button [icon]="getImageClass(item.privacy)" [rounded]="true"
                            class="absolute top-0 right-0 m-2 text-xl" severity="danger" size="large" [text]="true"
                            [outlined]="true" />

                        <p-button icon="pi pi-bars" [loading]="loadingDeleteImage[activeIndex]" severity="danger"
                            size="large"
                            (click)="attachmentMenuItemClicked($event, activeIndex, true)"
                            [raised]="true"
                            [ngStyle]="{'position': 'absolute', 'bottom': '25px', 'right': '20px', 'cursor': 'pointer'}"
                            [text]="true"></p-button>

                        <img *ngIf="item.fileType.includes('image')" (load)="showImageLoading()"
                            [class.hidden]="showSkeletonFullImage" [src]="item.url" [alt]="item.url"
                            style="width: 80vw; height: 80vh;">


                        <ngx-doc-viewer [url]="item.url" *ngIf="!item.fileType.includes('image')" viewer="google"
                            style="width: 80vw; height: 80vh;">
                        </ngx-doc-viewer>

                        <p-skeleton *ngIf="showSkeletonFullImage && item.fileType.includes('image')" width="80vw"
                            height="80vh">
                            <i class="pi pi-spin pi-spinner" style="font-size: 4rem"></i>
                        </p-skeleton>
                    </div>
                </ng-template>
                <ng-template pTemplate="caption" let-item>
                    <div style="z-index: 20;" class="relative pl-1">
                        <h4 style="margin-bottom: .5rem; color: #ffffff;">
                            {{ item.originalFilename || item.filename }}
                        </h4>
                        <p> {{ convertBytesToMb(item.size) }} | {{ item.documentType}} </p>
                    </div>
                </ng-template>

                <!-- <ng-template pTemplate="footer">
                                        <div style="z-index: 25">

                                        </div>
                                    </ng-template> -->
            </p-galleria>
        </div>

    </div>

</div>



<p-confirmPopup #confirmPopupRef key="confirmPopupRef">
    <ng-template pTemplate="headless" let-message let-test>
        <div class="border-round p-3">
            <span>{{ message.message }}</span>
            <div class="flex align-items-center gap-2 mt-3">
                <p-button (onClick)="acceptConfirmImage()" label="Yes" size="small" />
                <p-button (onClick)="rejectConfirmImage()" label="No" [outlined]="true" size="small" />
            </div>
        </div>
    </ng-template>
</p-confirmPopup>

<p-confirmDialog #cdSingleImagesDelete key="singleImageDelete">
    <ng-template pTemplate="headless" let-message>
        <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
            <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem">
                <i class="pi pi-question text-5xl"></i>
            </div>
            <span class="font-bold text-2xl block mb-2 mt-4">
                {{ message.header }}
            </span>
            <p class="mb-0">{{ message.message }}</p>
            <div class="flex align-items-center gap-2 mt-4">
                <button pButton label="Delete" (click)="removeImageByDocumentId(activeIndex)" class="w-8rem">
                </button>
                <button pButton label="Cancel" (click)="cdSingleImagesDelete.reject()"
                    class="p-button-outlined w-8rem ">
                </button>
            </div>
        </div>
    </ng-template>
</p-confirmDialog>

<p-confirmDialog #cdMultipleImagesDelete key="multipleImagesDelete">
    <ng-template pTemplate="headless" let-message>
        <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
            <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem">
                <i class="pi pi-question text-5xl"></i>
            </div>
            <span class="font-bold text-2xl block mb-2 mt-4">
                {{ message.header }}
            </span>
            <p class="mb-0">{{ message.message }}</p>
            <div class="flex align-items-center gap-2 mt-4">
                <button pButton label="Delete" (click)="removeMultipleImagesByDocumentId()" class="w-8rem">
                </button>
                <button pButton label="Cancel" (click)="cdMultipleImagesDelete.reject()"
                    class="p-button-outlined w-8rem ">
                </button>
            </div>
        </div>
    </ng-template>
</p-confirmDialog>

<p-blockUI [target]="fileUpload" [blocked]="blockedFileUploader">
    <i class="pi pi-spin pi-spinner" style="font-size: 4rem"></i>
</p-blockUI>

<p-dialog header="Select Privacy" key="selectPrivacy" [modal]="true" [(visible)]="showPrivacySelection"
    [style]="{ width: '25rem' }">
    <span class="p-text-secondary block mb-5">Select Privacy Preferences for Chosen Files</span>
    <div class="flex align-items-center gap-3 mb-2">
        <p-button (click)="privacyTypeSelected(privacyTypeOption.PUBLIC)" label="Public" icon="pi pi-globe"
            severity="success" />
    </div>
    <div class="flex align-items-center gap-3 mb-2">
        <p-button (click)="privacyTypeSelected(privacyTypeOption.PRIVATE)" label="Private" icon="pi pi-lock"
            severity="warning" />
    </div>
    <div class="flex align-items-center gap-3 mb-2">
        <p-button (click)="privacyTypeSelected(privacyTypeOption.PROTECTED)" label="Protected" icon="pi pi-shield"
            severity="info" />
    </div>
</p-dialog>

<!-- <p-menu #attachmentMenu [model]="attachmentMenuItems" [popup]="true" appendTo="body" /> -->
<p-tieredMenu #attachmentMenu [model]="attachmentMenuItems" [popup]="true" appendTo="body" />
<p-tieredMenu #imageSelectionMenu [model]="imageSelectionMenuItems" [popup]="true" appendTo="body" />

<!-- Dialog to View the File -->
<p-dialog header="Document Viewer" [(visible)]="showOpenFileDialog" [modal]="true"
    [style]="{width: '80vw', height: '80vh'}">
    <!-- ngx-doc-viewer Component -->
    <ngx-doc-viewer *ngIf="fileUrl" [url]="fileUrl" viewer="google" style="width: 100%; height: 100%;">
    </ngx-doc-viewer>
</p-dialog>