import {
  Component,
  Inject,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  Input,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
  NgZone,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FontSizeServiceService } from 'src/app/services/font-size-service.service';
import { SideBarMenuSharedService } from 'src/app/services/side-bar-menu-shared.service';
import { DOCUMENT } from '@angular/common';
import { ThemeService } from 'src/app/services/theme.service';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { UtilService } from 'src/app/services/util.service';
import { MenuItem, MessageService } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { LogoutService } from 'src/app/services/logout.service';
import { Subscription, filter } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from 'src/app/services/user.service';
import { CurrentUser } from 'src/app/interfaces/current-user';
import { Location } from '@angular/common';
import { SecretKeyService } from 'src/app/services/secret-key.service';
import { AppConstants } from 'src/app/commons/app-constants';
import { Theme } from 'src/app/commons/theme-constants';
import { SideBarComponent } from '../side-bar/side-bar.component';
import { SideBarMenuService } from 'src/app/services/side-bar-menu.service';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [ToastMessagesService, MessageService],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
 // @ViewChild('avatar', { read: ElementRef }) avatar!: ElementRef;
  @ViewChild('menu') menu!: ContextMenu;
  @Input() visibilityCondition: any;
  @Input() showLogo: boolean = false;
  @Input() showFontSize: boolean = false;
  @Input() showMenuButton: boolean = false;
  @Input() showUserProfileButton: boolean = false;
  @Input() showDayNightScheme: boolean = false;
  @Input() showHeader: boolean = false;
    @ViewChild('avatar1', { static: false }) avatar1: ElementRef | undefined;

  isContextMenuInitialized = false;
  @ViewChild(SideBarComponent) sidebarComponent!: SideBarComponent;
  userProfile: any;
  currentUser: CurrentUser | null = null;
  private subscription: Subscription | null = null;

  isDarkThemeEnabled = false;
  isMobile!: boolean;

  showMenuBar = false;
  fontSizes = [
    { label: 'XS', value: 0.75 },
    { label: 'SM', value: 0.875 },
    { label: 'MD', value: 1 },
    { label: 'LG', value: 1.125 },
    { label: 'XL', value: 1.25 },
    { label: '2XL', value: 1.5 },
  ];
  selectedFontSize: any;

  availableThemes = [
    { label: 'Bootstrap Light', value: Theme.BootstrapLight },
    { label: 'Bootstrap Dark', value: Theme.BootstrapDark },
    // Add other themes as needed
  ];
  selectedTheme = this.availableThemes[0].value;
  items!: MenuItem[];
  showFullHeader = true;
  isThisMobile = false;

  constructor(
    private sideBarMenuSharedService: SideBarMenuSharedService,
    private router: Router,
    private fontSizeService: FontSizeServiceService,
    private themeService: ThemeService,
    private logOutService: LogoutService,
    private userService: UserService,
    private location: Location,
    private cdr: ChangeDetectorRef,
    private toastMessageService: ToastMessagesService,
    private utilService: UtilService,
    private sideBarMenuService: SideBarMenuService
  ) {
    this.isDarkThemeEnabled = this.selectedTheme === Theme.BootstrapDark;

    // console.log(`this.router.url ${this.location.path().includes('login')}`);
    this.onResize();
    this.hideHeaderOnLoginPage();

    const url = this.router.url;
    if (!url.includes('public')) {
      this.showMenuBar = true;
    }

    this.isMobile = window.innerWidth < 768; // adjust the breakpoint as needed
  }

  ngAfterViewInit(): void {
    //this.getUserProfile();
    this.sideBarMenuService.getDecryptedUserProfile().then((profile) => {
      this.userProfile = profile;
      this.updateFontSizeFromStorage();
      this.updateThemeFromStorage();
      this.subscribeToCurrentUser();
    });
  }

  private getUserProfile() {
    this.sideBarMenuService.getUserProfile().subscribe(
      (responseUserProfile: any) => {
       console.log(`In getUserProfile data ${JSON.stringify(responseUserProfile)}`);
        this.userProfile = responseUserProfile;
        this.sideBarMenuService.setEncryptedUserProfile(responseUserProfile).then(() => {
        //  console.log('User profile encrypted and set successfully');
        }).catch((error: any) => {
          console.error('Failed to set encrypted user profile:', error);
        });
      },
      (error: any) => { },
      () => { });

  }

  private updateFontSizeFromStorage(): void {
    const storedFontSize = localStorage.getItem(AppConstants.FONT_SIZE);

    if (storedFontSize) {
      const storedFontSizeNumber = parseFloat(storedFontSize);
      const index = this.fontSizes.findIndex(
        (item) => item.value === storedFontSizeNumber
      );
      if (index !== -1) {
        this.selectedFontSize = this.fontSizes[index];
      }
      // console.log(index);
    } else {
      this.fontSizeService.changeFontSize(1);
      document.documentElement.style.fontSize = 1 + 'rem';
      this.selectedFontSize = this.fontSizes[2];
    }
  }

  private updateThemeFromStorage(): void {
    const storedTheme = localStorage.getItem(AppConstants.THEME);
    if (storedTheme) {
      // console.log(
      //   `Theme from Localstorage in headercomponent is ${storedTheme}`
      // );
      const index = this.availableThemes.findIndex(
        (item) => item.value === storedTheme
      );
      if (index !== -1) {
        this.selectedTheme = this.availableThemes[index].value;
      }
      // Set isDarkThemeEnabled based on storedTheme
      this.isDarkThemeEnabled = this.selectedTheme === Theme.BootstrapDark;
      // console.log(index);
    } else {
      this.selectedTheme = Theme.BootstrapLight; // default value
      // If default theme is light, isDarkThemeEnabled should be false
      this.isDarkThemeEnabled = false;
    }
  }

  private subscribeToCurrentUser(): void {
    this.subscription = this.userService.currentUser$.subscribe(
      (currentUser) => {
        this.currentUser = currentUser;
        this.initMenu();
        this.preloadImage();
        // Ensure that the change detection is run after all state updates
        setTimeout(() => {
          this.cdr.detectChanges();
          this.isContextMenuInitialized = true;
        }, 1000);
      }
    );
  }

  ngOnInit(): void {
    if (!this.location.path().includes('login')) {
      this.initMenu();
      // this.getProfilePicture();
    }
    // this.userService.getUser().subscribe((data) => { });

    // this.fontSizeService.changeFontSize(this.fontSizes[2].value);
  }

  showHideMenu() {
    this.sideBarMenuSharedService.isMenuExpanded.emit(true);
    if (this.sidebarComponent) {
      this.sidebarComponent.focusSearchInput();
    }
  }

  onFontSizeChange(event: any) {
    console.log(`this.selectedFontSize ${JSON.stringify(event.value)}`);
    this.fontSizeService.changeFontSize(event.value);
    document.documentElement.style.fontSize = event.value + 'rem';
    this.cdr.detectChanges();
  }

  /* updateTheme() {
    if (this.isDarkThemeEnabled) {
      this.selectedTheme = Theme.BootstrapDark;
    } else {
      this.selectedTheme = Theme.BootstrapLight;
    }

    this.themeService.switchTheme(this.selectedTheme);
  } */
  updateTheme() {
    console.log(this.isDarkThemeEnabled); // Add this line
    if (this.isDarkThemeEnabled) {
      this.selectedTheme = Theme.BootstrapDark;
    } else {
      this.selectedTheme = Theme.BootstrapLight;
    }
    //this.themeService.switchTheme(this.selectedTheme);
    this.cdr.detectChanges();
  }

  isSmallScreen = false;
  isMediumScreen = false;
  isLargeScreen = false;
  isExtraLargeScreen = false;

  private async initMenu() {
    this.items = [
      {
        label: 'Company ID: ' + (this.userProfile?.companyId ? this.userProfile.companyId : ''),
        styleClass: 'text-sm',
      },
      {
        separator: true,
      },
      {
        label: 'Edit Profile',
        icon: 'pi pi-fw pi-pencil',
        command: () => {
          this.router.navigateByUrl('register');
          //this.messageService.showInfoMessage('Comming soon', 'Page is under construction');
        },
      },
      {
        label: 'Logout',
        icon: 'pi pi-power-off',
        command: () => {
          this.logOutService.logout().subscribe((isSuccess) => {
            if (isSuccess) {
              // Do something if logout is successful
              this.toastMessageService.showSuccessMessage(
                'Logout Successful',
                'You have been successfully logged out.',
                false,
                'logout-toast'
              );
              localStorage.clear();
              this.router.navigate(['login']);
            } else {
              // Do something if logout failed
              this.toastMessageService.showErrorMessage(
                'Logout failed',
                'Error while log out '
              );
            }
          });
        },
      },
      /* {
        label: 'Reset ',
        icon: 'pi pi-fw pi-pencil',
        command: () => {
          this.userService.resetDatabase().subscribe(
          (response:any)=>{
            console.log(`response of resetdatabase is ${JSON.stringify(response)}`);
          },
          (error:any)=>{
            console.log(`error of resetdatabase is ${JSON.stringify(error)}`);
          },
          ()=>{},
          )
        },
      } */
    ];
  }

  @HostListener('window:resize')
  onResize() {
    const screenWidth = window.innerWidth;

    this.isSmallScreen = screenWidth >= 576 && screenWidth < 768;
    this.isMediumScreen = screenWidth >= 768 && screenWidth < 992;
    this.isLargeScreen = screenWidth >= 992 && screenWidth < 1200;
    this.isExtraLargeScreen = screenWidth >= 1200;

    this.isThisMobile = this.utilService.isMobile();
  }

  toggleMenu(event: MouseEvent) {
    this.menu.toggle(event);
    event.stopPropagation();
  }

  private hideHeaderOnLoginPage() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        // Add the route paths for which you want to hide the components
        const hiddenRoutes = ['login'];

        const shouldHide = hiddenRoutes.some((route) => {
          //  console.log(`route ${route} == hiddenRoutes ${hiddenRoutes}`);
          event.urlAfterRedirects.startsWith(`/${route}`);
        });

        this.showFullHeader = false;
      });
  }
  imageIsValid: boolean = true; // Start with true by default

  preloadImage() {
    const img = new Image();
    const url = this.currentUser?.imageUrl ?? '';
    // console.log("Header Image URL:"+ url);

    img.src = url;

    img.onload = () => {
      this.imageIsValid = true;
      // console.log("Header Image :: -> Image loaded successfully");
    };

    img.onerror = (event: any) => {
      this.imageIsValid = false;
      //  console.log("Header Image :: -> Error loading image   "+JSON.stringify(event));
    };
  }

  ngOnDestroy(): void {
    // Unsubscribe to prevent memory leak
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
