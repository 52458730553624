import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { PrivacyType } from 'src/app/commons/enums/privacy-type';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';
import { ContainerPrivacyService } from '../../services/container-privacy.service';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';

@Component({
  selector: 'app-container-privacy',
  standalone: true,
  imports: [SHARED_MODULES],
  templateUrl: './container-privacy.component.html',
  styleUrl: './container-privacy.component.css',
  providers: [ToastMessagesService]
})
export class ContainerPrivacyComponent implements OnInit {

  containerPrivacyForm!: FormGroup;

  items!: MenuItem[];
  home!: MenuItem;
  formHeader!: string;
  formSubHeader!: string;
  @Input() showBreadcrumb: boolean = true;
  privacyType: PrivacyType;

  defaultPrivacyOptions: any[] = [
    { name: 'Private', value: PrivacyType.PRIVATE },
    { name: 'Protected', value: PrivacyType.PROTECTED },
    { name: 'Public', value: PrivacyType.PUBLIC }
  ];

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private containerConfigService: ContainerPrivacyService,
    private toastService: ToastMessagesService) {
    this.containerPrivacyForm = this.fb.group({
      privacySettings: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.populateBreadCrumb();
    // this.initForm();

    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.setPageTitle();
    this.updateForm();

  }

  populateBreadCrumb() {
    this.items = [
      { label: 'Privacy', routerLink: '/privacy' },
      { label: 'Container Privacy' },
    ];
  }

  // fields = [
  //   'containerNumber',
  //   'sealNumber',
  //   'containerType',
  //   'productId',
  //   'supplierId',
  //   'loadingSiteId',
  //   'countryOfOrigin',
  //   'seaPort',
  //   'fumigation',
  // ];

  fields = [
    { fieldName: 'containerNumber', label: 'Container Number' },
    { fieldName: 'sealNumber', label: 'Seal Number' },
    { fieldName: 'containerType', label: 'Container Type' },
    { fieldName: 'productId', label: 'Product ID' },
    { fieldName: 'supplierId', label: 'Supplier ID' },
    { fieldName: 'loadingSiteId', label: 'Loading Site ID' },
    { fieldName: 'countryOfOrigin', label: 'Country of Origin' },
    { fieldName: 'seaPort', label: 'Sea Port' },
    { fieldName: 'fumigation', label: 'Fumigation' },
  ];


  setPageTitle() {
    console.log(`Settitle from container`);
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
      console.log(`this.formHeader ${this.formHeader}`);
      console.log(`this.formSubHeader ${this.formSubHeader}`);
      console.log(`data['title'] ${data['title']}`);
    });
  }

  initForm(): void {
    // this.containerPrivacyForm = new FormGroup({
    //   checked1: new FormControl<boolean>(false),
    //   checked2: new FormControl<boolean>(false),
    //   checked3: new FormControl<boolean>(false)

    // });


    this.updateForm();
  }

  updateForm(): void {
    this.containerConfigService.getContainerPrivacyConfigDetails().subscribe((data) => {
      console.log('Privacy settings:', JSON.stringify(data));
      const formArray = this.privacySettings;
      formArray.clear(); // Clear any existing controls

      data.forEach((field, index) => {
        if (this.fields[index]) {
          // Ensure there's a field name and label for each entry
          formArray.push(
            this.fb.group({
              id: [field.id],
              fieldName: [this.fields[index]?.fieldName],  // Add the fieldName here if required
              public: [field.public],
              protected: [field.protected],
              private: [field.private],
            })
          );
        } else {
          console.warn(`Missing field for index ${index}`);
        }
      });
    });
  }
  onSubmit(): void {
    console.log(`On Submit isForm Valid ${this.containerPrivacyForm.valid}`);
    console.log(`On Submit isForm Values ${JSON.stringify(this.containerPrivacyForm.value)}`);
    console.log(`On Submit privacySettings length ${JSON.stringify(this.privacySettings.length)}`);


    if (this.containerPrivacyForm.valid) {
      const updatedPrivacySettings = this.containerPrivacyForm.value.privacySettings.map((setting, index) => {
        return {
          id: setting.id,
          fieldName: this.fields[index].fieldName,  // Add the field name from the fields array
          public: setting.public,
          protected: setting.protected,
          private: setting.private
        };
      });

      console.log(`Submit Form is ${JSON.stringify(updatedPrivacySettings)}`);
      this.containerConfigService.updateContainerPrivacyConfig(updatedPrivacySettings).subscribe(
        (response) => {
          console.log('Privacy settings updated successfully:', response);
          this.toastService.showSuccessMessage(
            'Success',
            'Container Privacy Updated Successfully'
          );
        },
        (error) => {
          this.toastService.showErrorMessage('Error', error?.error?.message ?? 'Error while updating');

        });
    }
  }


  testContainerViewFn(): void {
    this.containerConfigService.testContainerView().subscribe(
      (data) => {
        console.log('Fetched privacy settings:', data);

      },
      (error) => {
        console.error('Error fetching privacy settings:', error);
        this.toastService.showErrorMessage('Error', 'Failed to fetch privacy settings');
      }
    );
  }

  updateFormWithFetchedData(data: any): void {
    const formArray = this.privacySettings;
    formArray.clear(); // Clear any existing controls

    data.forEach((field, index) => {
      if (this.fields[index]) {
        formArray.push(
          this.fb.group({
            id: [field.id],
            fieldName: [this.fields[index]?.fieldName],
            public: [field.public],
            protected: [field.protected],
            private: [field.private],
          })
        );
      } else {
        console.warn(`Missing field for index ${index}`);
      }
    });
  }


  get privacySettings(): FormArray {
    return this.containerPrivacyForm.get('privacySettings') as FormArray;
  }

}
