import { Component } from '@angular/core';
import { ChartModule } from 'primeng/chart';
import { ChartDataType } from 'src/app/interfaces/tally-sheet/chart-data-type';
import { ChartDataUnit } from 'src/app/interfaces/tally-sheet/chart-data-unit';
import { ChartTitle } from 'src/app/interfaces/tally-sheet/chart-title';
import { ColorType } from 'src/app/interfaces/tally-sheet/color-type';
import { ChartService } from 'src/app/services/tally-sheet/chart.service';


@Component({
  selector: 'app-chart',
  standalone: true,
  imports: [ChartModule],
  templateUrl: './chart.component.html',
  styleUrl: './chart.component.scss'
})
export class ChartComponent {

  basicData: any;
  basicOptions: any;

  private readonly summaryId = 1;

  constructor(private readonly chartService: ChartService) {
    this.initChart();
  }

  initChart() {
    this.chartService.getChartData(this.summaryId, ColorType.SOLID, ChartDataUnit.PERCENTAGE, ChartDataType.LENGTH, ChartTitle.GIRTH).subscribe((response) => {
      const labels = response.ranges.map((range: any) => {
        console.log(`Range ${JSON.stringify(range)}`)
        return range.endRange ? `${range.startRange} - ${range.endRange}` : `${range.startRange}`;

      });
      const documentStyle = getComputedStyle(document.documentElement);
      const data = response.ranges.map((range: any) => range.count);
      const backgroundColor = response.colors.map((color: any) => color[0]);
      const borderColor = response.colors.map((color: any) => color[1]);
      const hoverBackgroundColor = response.colors.map((color: any) => color[2]);
      const textColor = documentStyle.getPropertyValue('--text-color');


      this.basicData = {
        labels: labels,
        datasets: [
          {
            label: response.title || 'Sales',
            data: data,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            hoverBackgroundColor: hoverBackgroundColor,
            borderWidth: 1
          }
        ]
      };

      // pie chart
       this.basicOptions = {
         plugins: {
             legend: {
                 labels: {
                     usePointStyle: true,
                     color: textColor
                 }
             }
         }
     };

       /* //other than pie chart
      this.basicOptions = {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      };*/
    });
  }

}
