
<!-- <p-toast></p-toast> -->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" acceptLabel="Yes"
    rejectLabel="No"></p-confirmDialog>
<!-- 
<div class="pb-3">
    <p-breadcrumb [model]="breadCumbItems" [home]="home"></p-breadcrumb>
</div> -->

<p-card>
    <div class="formgrid grid col-*">


        <div class="field col-12 md:col-6">
            <p class="font-medium"> Features in this subscription :</p>
            <ul>
                <li *ngFor="let eachFeature of features">
                    {{ eachFeature.name }}
                </li>
            </ul>
        </div>



    </div>

</p-card>