import {
  trigger,
  transition,
  style,
  query,
  animate,
  group,
} from '@angular/animations';

export const fadeAnimation = trigger('routeAnimations', [
    transition('* <=> *', [
      query(
        ':enter, :leave',
        style({ position: 'absolute', top: 0, left: 0, width: '100%' }),
        { optional: true }
      ),
  
      query(
        ':enter',
        [style({ opacity: 0 }), animate('30s', style({ opacity: 1 }))],
        { optional: true }
      ),
  
      query(':leave', [animate('30s', style({ opacity: 0 }))], {
        optional: true,
      }), 
    ]),
  ]);
  

export const slideInAnimation = trigger('routeAnimations', [
  transition('* <=> *', [
    query(
      ':enter, :leave',
      style({ position: 'absolute', top: 0, left: 0, width: '100%' }),
      { optional: true }
    ),

    group([
      query(
        ':enter',
        [
          style({ left: '-100%' }),
          animate('0.2s ease-out', style({ left: '0%' })),
        ],
        { optional: true }
      ),
      query(
        ':leave',
        [
          style({ left: '0%' }),
          animate('0.2s ease-out', style({ left: '100%' })),
        ],
        { optional: true }
      ),
    ]),
  ]),
]);


export const scaleUpAnimation = 
  trigger('routeAnimations', [
    transition('* <=> *', [
      query(':enter, :leave', 
        style({ position: 'absolute', top: 0, left: 0, width: '100%' }), 
        { optional: true }), 

      query(':enter', [
        style({ transform: 'scale(0)' }),
        animate('0.5s', style({ transform: 'scale(1)' }))
      ], { optional: true }),

      query(':leave', [
        style({ transform: 'scale(1)' }),
        animate('0.5s', style({ transform: 'scale(0)' }))
      ], { optional: true })
    ]),
]);


export const rotateAnimation = 
  trigger('routeAnimations', [
    transition('* <=> *', [
      query(':enter, :leave', 
        style({ position: 'absolute', top: 0, left: 0, width: '100%' }), 
        { optional: true }), 

      query(':enter', [
        style({ transform: 'rotate(-90deg)' }),
        animate('0.5s', style({ transform: 'rotate(0deg)' }))
      ], { optional: true }),

      query(':leave', [
        style({ transform: 'rotate(0deg)' }),
        animate('0.5s', style({ transform: 'rotate(90deg)' }))
      ], { optional: true })
    ]),
]);


export const fadeInAnimation = trigger('routeAnimations', [
    transition('* <=> *', [
      query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, width: '100%' }), { optional: true }),
      query(':enter', [
        style({ opacity: 0 }),
        animate('0.1s', style({ opacity: 0.5 })),
        animate('0.1s', style({ opacity: 1 }))
      ], { optional: true }),
      query(':leave', [
        animate('0.1s', style({ opacity: 0 }))
      ], { optional: true }),
    ]),
  ]);
  