import { Injectable } from '@angular/core';
import { Buyer } from '../model/buyer'; 

@Injectable({
  providedIn: 'root'
})
export class BuyerSharedDataService {


  buyerSharedData!: any;

  constructor() { }


  saveDataToLocalStorage(data: Buyer) {
    localStorage.setItem(this.buyerSharedData, JSON.stringify(data));
  }

  getDataFromLocalStorage(): Buyer {
    const data = localStorage.getItem(this.buyerSharedData);
    return JSON.parse(data!);
  }
}
