<p-toast></p-toast>
<p-blockUI [blocked]="blockedDocument">
    <i class="pi pi-spin pi-spinner" style="font-size: 4rem !important"></i>
</p-blockUI>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" acceptLabel="Yes"
    rejectLabel="No"></p-confirmDialog>
<form [formGroup]="myForm" (ngSubmit)="onSubmit()">
    <div class="pb-3" *ngIf="showBreadcrumb">
        <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
    </div>

    <p-card [header]="formHeader" [subheader]="formSubHeader">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6">
                <label for="name">Company Name <i class="text-red-400">*</i></label>
                <input id="name" type="text" formControlName="name" pInputText (input)="nameValidation()"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="myForm.invalid && (myForm.controls?.['name']?.invalid && (myForm.controls?.['name']?.dirty || myForm.controls?.['name']?.touched))">
                    <div *ngIf="myForm.controls?.['name']?.errors?.['required']">Name is required.</div>
                    <div *ngIf="myForm.controls?.['name']?.errors?.['isNameExists'];">Name already exists.</div>
                </div>
            </div>
            <div class="field col-12 md:col-3">
                <label for="contactPerson">Contact Person</label>
                <input id="contactPerson" type="text" formControlName="contactPerson" pInputText maxlength="33"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="myForm.controls?.['contactPerson']?.invalid && (myForm.controls?.['contactPerson']?.dirty || myForm.controls?.['contactPerson']?.touched)">
                    <div *ngIf="myForm.controls?.['contactPerson']?.errors?.['minlength']">Min Length is 3</div>
                    <div *ngIf="myForm.controls?.['contactPerson']?.errors?.['maxlength']">Max Length is 32</div>
                </div>
            </div>
            <div class="field col-12 md:col-3">
                <label for="gst">Tax Id
                    <!-- <i class="text-red-400">*</i> -->
                </label>
                <input id="gst" type="text" formControlName="gst" pInputText maxlength="33" (input)="gstValidation()"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <!--  <p-inputMask id="gst" styleClass="w-full" formControlName="gst" type="text"  
                    [autoClear]="false"  (input)="gstValidation()"
                    class="text-base text-color w-full"></p-inputMask> -->
                <div class="text-red-400"
                    *ngIf="myForm.controls?.['gst']?.invalid && (myForm.controls?.['gst']?.dirty || myForm.controls?.['gst']?.touched)">
                    <div *ngIf="myForm.controls?.['gst']?.errors?.['minlength']">Should have min 5 characters</div>
                    <div *ngIf="myForm.controls?.['gst']?.errors?.['maxlength']">Should have max 20 characters</div>
                    <div *ngIf="myForm.controls?.['gst']?.errors?.['pattern']">Invalid patterns</div>
                    <div *ngIf="myForm.controls?.['gst']?.errors?.['isGstExists'];">Tax Id already exists.</div>
                </div>
                <!-- <label for="gst">GST <i class="text-red-400">*</i></label>
                <p-inputMask id="gst" styleClass="w-full" formControlName="gst" type="text" mask="99aaaaa9999a9Za"
                    [autoClear]="false" placeholder="e.g. 29ABCDE1234F1Z5" (input)="gstValidation()"
                    class="text-base text-color w-full"></p-inputMask>
                <div class="text-red-400"
                    *ngIf="myForm.controls?.['gst']?.invalid && (myForm.controls?.['gst']?.dirty || myForm.controls?.['gst']?.touched)">
                    <div *ngIf="myForm.controls?.['gst']?.errors?.['minlength']">Should have exact 15 characters</div>
                    <div *ngIf="myForm.controls?.['gst']?.errors?.['maxlength']">Should have exact 15 characters</div>
                    <div *ngIf="myForm.controls?.['gst']?.errors?.['pattern']">Invalid patterns</div>
                    <div *ngIf="myForm.controls?.['gst']?.errors?.['isGstExists'];">GST already exists.</div>
                </div> -->
            </div>
            <!-- <div class="field col-12 md:col-6">
                <label for="address1">Address 1</label>
                <input id="address1" type="text" formControlName="address1" pInputText maxlength="65"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="myForm.controls?.['address1']?.invalid && (myForm.controls?.['address1']?.dirty || myForm.controls?.['address1']?.touched)">
                    <div *ngIf="myForm.controls?.['address1']?.errors?.['minlength']">Min Length is 3</div>
                    <div *ngIf="myForm.controls?.['address1']?.errors?.['maxlength']">Max Length is 64</div>
                </div>
            </div>
            <div class="field col-12 md:col-6">
                <label for="address2">Address 2</label>
                <input id="address2" type="text" formControlName="address2" pInputText maxlength="65"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="myForm.controls?.['address2']?.invalid && (myForm.controls?.['address2']?.dirty || myForm.controls?.['address2']?.touched)">
                    <div *ngIf="myForm.controls?.['address2']?.errors?.['minlength']">Min Length is 3</div>
                    <div *ngIf="myForm.controls?.['address2']?.errors?.['maxlength']">Max Length is 64</div>
                </div>
            </div>

            <div class="field col-12 md:col-6">
                <label for="cityId">City <i class="text-red-400">*</i></label>
                <p-dropdown [options]="cities" formControlName="cityId" optionLabel="name" [filter]="true" [lazy]="true"
                    styleClass="field col-12 w-full" [virtualScroll]="true" [virtualScrollItemSize]="38" [lazy]="true"
                    (onChange)="onCityChange($event)" placeholder="Select City" [showClear]="true"></p-dropdown>
                <div class="text-red-400"
                    *ngIf="myForm.controls?.['cityId']?.invalid && (myForm.controls?.['cityId']?.dirty || myForm.controls?.['cityId']?.touched)">
                    <div *ngIf="myForm.invalid &&  (myForm.controls?.['cityId']?.errors?.['required'])">City is
                        required.</div>
                </div>
            </div>
            <div class="field col-12 md:col-3">
                <label for="state">State</label>
                <input id="state" type="text" pInputText formControlName="state" [readOnly]="true"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>
            <div class="field col-12 md:col-3">
                <label for="pincode">Pincode <i class="text-red-400">*</i></label>
                 <input id="pincode" type="text" formControlName="pincode" pInputText maxlength="6"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="myForm.invalid && (myForm.controls?.['pincode']?.invalid && (myForm.controls?.['pincode']?.dirty || myForm.controls?.['pincode']?.touched))">
                    <div *ngIf="myForm.controls?.['pincode']?.errors?.['required']">Pincode is required.</div>

                    <div *ngIf="myForm.controls?.['pincode']?.errors?.['pattern']">Invalid pincode</div>
                </div>
            </div> -->
            </div>

            <app-location-selection (resetFormRequest)="onReset()" [isFromSidebar]="isSubmittedFromSidebar"
                formControlName="location" (locationFormStatus)="onLocationFormStatus($event)"
                [locationFormValidators]="locationFormValidators" [existingLocationData]="existingLocationData"
                [isAddress2Visible]="true" [isAddress2Optional]="true" [isStateOptional]="true" [isCityOptional]="true" [isZipOptional]="true"
               ></app-location-selection>


   <div class="formgrid grid">
            <div class="field col-12 md:col-6">
                <label for="email">Email</label>
                <input id="email" type="email" formControlName="email" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="myForm.controls?.['email']?.invalid && (myForm.controls?.['email']?.dirty || myForm.controls?.['email']?.touched)">
                    <div *ngIf="myForm.controls?.['email']?.errors?.['email']">Invalid email format.</div>
                </div>
            </div>
            <div class="field col-12 md:col-3">
                <label for="phone1">Phone 1</label>
                <input id="phone1" type="tel" formControlName="phone1" pInputText maxlength="15"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="myForm.controls?.['phone1']?.invalid && (myForm.controls?.['phone1']?.dirty || myForm.controls?.['phone1']?.touched)">
                    <div *ngIf="myForm.controls?.['phone1']?.errors?.['pattern']">Invalid Phone</div>
                </div>
            </div>
            <div class="field col-12 md:col-3">
                <label for="phone2">Phone 2</label>
                <input id="phone2" type="tel" formControlName="phone2" pInputText maxlength="15"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="myForm.controls?.['phone2']?.invalid && (myForm.controls?.['phone2']?.dirty || myForm.controls?.['phone2']?.touched)">
                    <div *ngIf="myForm.controls?.['phone2']?.errors?.['pattern']">Invalid Phone</div>
                </div>
            </div>

        </div>

        <ng-template pTemplate="footer">
            <!-- <p-button [label]="addEditButton" [disabled]="!myForm.dirty" icon="pi pi-save"
                styleClass="p-button-success w-9rem" type="submit"> </p-button>

            <p-button label="Reset" icon="pi pi-sync" styleClass="p-button-secondary  w-9rem" *ngIf="!editMode"
                [style]="{'margin-left': '.5em'}" (onClick)="onReset()"></p-button>

            <p-button label="Delete" icon="pi pi-trash" styleClass="p-button-danger  w-9rem" *ngIf="editMode"
                [style]="{'margin-left': '.5em'}" (onClick)="onDelete(this.buyerEditData.buyerId)"></p-button> -->
            <div class="grid">
                <div class="col-fixed" class="sm:w-12rem md:w-12rem lg:w-12rem xl:w-12rem">
                    <div class="text-center p-3 font-bold">
                        <!-- <p-button [label]="addEditButton" [disabled]="!myForm.dirty" icon="pi pi-save"
                            styleClass="p-button-success w-9rem" type="submit"> </p-button> -->
                            <p-button [label]="addEditButton"   icon="pi pi-save"
                            styleClass="p-button-success w-9rem" type="submit"> </p-button>
                    </div>
                </div>
                <div class="col-fixed" class="sm:w-12rem md:w-12rem lg:w-12rem xl:w-12rem">
                    <div class="text-center p-3 font-bold">
                        <p-button label="Reset" icon="pi pi-sync" styleClass="p-button-secondary  w-9rem"
                            *ngIf="!editMode" [style]="{'margin-left': '.5em'}" (onClick)="onReset()"></p-button>

                        <p-button label="Delete" icon="pi pi-trash" styleClass="p-button-danger  w-9rem"
                            *ngIf="editMode" [style]="{'margin-left': '.5em'}"
                            (onClick)="onDelete(this.buyerEditData.buyerId)"></p-button>
                    </div>
                </div>
            </div>

        </ng-template>
    </p-card>

</form>