import { Injectable } from '@angular/core';
import { Billing } from '../model/billing';
import { AppConstants } from 'src/app/commons/app-constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(private httpClient: HttpClient) { }

  saveBilling(data: Billing): Observable<Billing> {
    return this.httpClient.post<Billing>(AppConstants.API_URL + 'api/v1/billing', data, { responseType: 'json' });
  }

}
