<p-speedDial [model]="sawnTimberSpeedDialItems" direction="up-left" type="quarter-circle" [radius]="120"
    class="ml-5 my-speed-dial" [rotateAnimation]="true" (onClick)="onSpeedDialItemClick($event)"
    (keydown.enter)="$event.preventDefault()"></p-speedDial>
<p-blockUI [blocked]="blockedPanel" styleClass="p-blockui-document">
    <i class="pi pi-spin pi-spinner" style="font-size: 4rem !important"></i>
</p-blockUI>



<form [formGroup]="tableForm" (ngSubmit)="onSubmit()">
    <!-- <p-button icon="pi pi-cog" styleClass="p-button-rounded" (onClick)="isConfigDialogVisible =true"></p-button> -->
    <!-- [style]="{ width: configDialogWidth }" -->
    <p-dialog key="configDialog" header="Configuration" [(visible)]="isConfigDialogVisible" [modal]="true"
        [draggable]="false" [resizable]="false" escape="true" [closable]="true"
        [style]="{ 'width': '70vw','min-width': '280px','max-width': '600px' }">
        <p class="text-red-400" *ngIf="showValidationMessage">
            Please fill all the fields or disable validation.
        </p>

        <div class="formgrid grid col-12" formGroupName="config">
            <div class="col-12 xs:col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12 pt-3">
                <p-card header="Copy Length">
                    <!-- Enable Validation -->

                    <div class="field grid">
                        <!-- <label for="lengthAllowance" style="width: 10rem">Enable Validation </label>
                    <p-toggleButton formControlName="enableValidation" onLabel="Yes" offLabel="No" binary="true"
                        (onChange)="onToggleChange($event)"></p-toggleButton> -->
                        <div class="col-* p-2">
                            <div class="ml-2"> <label class="vertical-align-middle">Auto-Copy Last Row Length?</label>
                            </div>
                        </div>
                        <div class="col-3 p-2">
                            <div><p-toggleButton formControlName="enableCopyLastRowGirth" onLabel="Yes" offLabel="No"
                                    binary="true"></p-toggleButton></div>
                        </div>
                    </div>
                </p-card>
            </div>
            <div class="col-12 pt-3">
                <p-card header="Auto Save">

                    <div class="field grid">
                        <div class="col-* p-2 pt-2">
                            <div class="ml-2"> <label>Enable Auto Saving? </label></div>
                        </div>
                        <div class="col-3 xs:col-6 sm:col-6 md:col-6 lg:col-3 xl:col-3  pt-2">
                            <div><p-toggleButton formControlName="saveOptionBoolean" onLabel="Yes" offLabel="No"
                                    binary="true" (onChange)="changeSaveOption()"></p-toggleButton></div>
                        </div>
                        <div class="col-*  p-2  pt-2" *ngIf="showSaveOptionDiv$ | async">
                            <div class="ml-2"> <label>Duration </label></div>
                        </div>
                        <div class="col-* ml-2 pt-2" *ngIf="showSaveOptionDiv$ | async">
                            <p-inputNumber formControlName="autoSaveDuration" inputId="minmax" inputId="minmax"
                                mode="decimal" [min]="1" [max]="60" [showButtons]="true" [step]="1" mode="decimal"
                                suffix=" mins" (ngModelChange)="updateAutoSave()">
                            </p-inputNumber>
                        </div>
                    </div>

                </p-card>
            </div>

            <div class="col-12 xs:col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6 pt-3">
                <p-card header="Unit of Measure">
                    <!-- Unit of Measure -->
                    <div class="field col-12 xs:col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
                        <label for="lengthUnit" style="width: 5rem">Length <i class="text-red-400">*</i></label>
                        <p-dropdown [options]="unitOptions" formControlName="lengthUnit" class="ml-2"></p-dropdown>
                    </div>
                    <div class="field col-12 xs:col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
                        <label for="girthUnit" style="width: 5rem">Girth <i class="text-red-400">*</i></label>
                        <p-dropdown [options]="unitOptions" formControlName="girthUnit" class="ml-2"></p-dropdown>
                    </div>
                </p-card>
            </div>
            <div class="col-12 xs:col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6 pt-3">
                <p-card header="Allowance">

                    <div class="field col-12 xs:col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
                        <label for="lengthAllowance" style="width: 5rem">Length <i class="text-red-400">*</i></label>
                        <p-inputNumber placeholder="Allowance" formControlName="lengthAllowance" mode="decimal"
                            [min]="0" [max]="100" (onKeyDown)="onRangeChange()"></p-inputNumber>

                    </div>
                    <div class="field col-12 xs:col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
                        <label for="girthUnit" style="width: 5rem">Girth <i class="text-red-400">*</i></label>
                        <p-inputNumber placeholder="Allowance" formControlName="girthAllowance" mode="decimal" [min]="0"
                            [max]="100" (onKeyDown)="onRangeChange()"></p-inputNumber>
                    </div>
                </p-card>
            </div>
            <div class="col-12 pt-3">
                <p-card header="Range Validation">

                    <div class="field grid">
                        <div class="col-* p-2 pt-2">
                            <div class="ml-2"> <label>Enable Validation?</label></div>
                        </div>
                        <div class="col-3 xs:col-6 sm:col-6 md:col-6 lg:col-3 xl:col-3  pt-2">
                            <div><p-toggleButton formControlName="enableValidation" onLabel="Yes" offLabel="No"
                                    binary="true" (onChange)="onToggleChange($event)"></p-toggleButton></div>
                        </div>
                        <div class="col-*  p-2  pt-2">
                            <div class="ml-2"> <label>Validate 5x Length? </label></div>
                        </div>
                        <div class="col-* ml-2 pt-2">
                            <div><p-toggleButton formControlName="validateMultipliesOfFive" onLabel="Yes" offLabel="No"
                                    binary="true"></p-toggleButton></div>
                        </div>
                    </div>

                    <div class="field grid" *ngIf="tableForm.get('config.enableValidation')?.value">
                        <div class="col-12 xs:col-6 sm:col-6 md:col-6 lg:col-3 p-2">
                            <div class="ml-2"> <label>Min Length <i class="text-red-400">*</i></label></div>
                        </div>
                        <div class="col-12 xs:col-6 sm:col-6  md:col-6 lg:col-3 p-2">
                            <div><p-inputNumber placeholder="Min Length" formControlName="minLength" mode="decimal"
                                    [min]="0" [max]="10000" (onKeyDown)="onRangeChange()"></p-inputNumber></div>
                        </div>
                        <div class="col-12 xs:col-6 sm:col-6  md:col-6 lg:col-3 p-2">
                            <div class="ml-2"> <label>Max Length <i class="text-red-400">*</i></label></div>
                        </div>
                        <div class="col-12 xs:col-6 sm:col-6  md:col-6 lg:col-3 p-2">
                            <div><p-inputNumber placeholder="Max Length" formControlName="maxLength" mode="decimal"
                                    [min]="0" [max]="10000" (onKeyDown)="onRangeChange()"></p-inputNumber></div>
                        </div>
                    </div>

                    <div class="field grid" *ngIf="tableForm.get('config.enableValidation')?.value">
                        <div class="col-12 xs:col-6 sm:col-6  md:col-6 lg:col-3 p-2">
                            <div class="ml-2"> <label>Min Girth <i class="text-red-400">*</i></label></div>
                        </div>
                        <div class="col-12 xs:col-6 sm:col-6  md:col-6 lg:col-3 p-2">
                            <div><p-inputNumber placeholder="Min Girth" formControlName="minGirth" mode="decimal"
                                    [min]="0" [max]="10000" (onKeyDown)="onRangeChange()"></p-inputNumber></div>
                        </div>
                        <div class="col-12 xs:col-6 sm:col-6  md:col-6 lg:col-3 p-2">
                            <div class="ml-2"> <label>Max Girth <i class="text-red-400">*</i></label></div>
                        </div>
                        <div class="col-12 xs:col-6 sm:col-6  md:col-6 lg:col-3 p-2">
                            <div><p-inputNumber placeholder="Max Girth" formControlName="maxGirth" mode="decimal"
                                    [min]="0" [max]="10000" (onKeyDown)="onRangeChange()"></p-inputNumber></div>
                        </div>
                    </div>

                </p-card>
            </div>


        </div>


        <ng-template pTemplate="footer">
            <p-button label="Close without Saving" styleClass="p-button-outlined p-button-danger"
                (click)="isConfigDialogVisible = false"></p-button>
            <p-button label="Save and Close" styleClass="p-button-outlined p-button-success"
                (click)="onDialogClose()"></p-button>

        </ng-template>

    </p-dialog>


    <div style="position: relative;">


        <div formArrayName="rows" class="pt-5" *ngIf="!(isLoading$ | async); else skeleton">
            <p-table #dt [value]="dummyArrayHorizontalGrid" scrollHeight="60vh" [scrollable]="true"
                styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped custom-table"
                selectionMode="single" [(selection)]="selectedRow" dataKey="uniqueKey" [showFirstLastIcon]="true"
                [loading]="loading">
                <button pButton pTooltip="Fixed Button" tooltipPosition="left"
                    class="p-button-rounded p-button-help p-button-lg p-fixed"
                    style="right: 1rem; top: 50%; transform: translateY(-50%);">
                    Fix
                </button>
                <!-- <thead style="background-color: rgba(0, 0, 0, 0.05)"> -->
                <ng-template pTemplate="header">
                    <tr>
                        <th rowspan="2" class="text-center">Review</th>
                        <th rowspan="2" class="text-center">SR</th>
                        <th class="text-center">G. Length</th>
                        <th class="text-center">
                            G. Girth
                        </th>

                        <th class="text-center">N. Length</th>
                        <th class="text-center">N. Girth</th>

                        <th rowspan="2" class="text-center">G. CBM</th>
                        <th rowspan="2" class="text-center">G. CFT</th>

                        <th rowspan="2" class="text-center">N. CBM</th>
                        <th rowspan="2" class="text-center">N. CFT</th>

                        <th class="text-center">Actions

                            <!--                             <p-button icon="pi pi-plus-circle"
                                styleClass="p-button-rounded p-button-success p-button-text"
                                (onClick)="createFirstRow()"></p-button> -->
                        </th>
                    </tr>
                    <tr>
                        <th class="text-center uppercase">
                            {{this.tableForm
                            .get('config.lengthUnit')?.value?? 'cm' }}
                        </th>
                        <th class="text-center uppercase">
                            {{this.tableForm
                            .get('config.girthUnit')?.value?? 'cm' }}
                        </th>

                        <th class="text-center uppercase">
                            {{this.tableForm
                            .get('config.lengthUnit')?.value?? 'cm' }}
                        </th>
                        <th class="text-center uppercase">
                            {{this.tableForm
                            .get('config.girthUnit')?.value?? 'cm' }}
                        </th>
                        <th class="text-center uppercase">
                            <p-button icon="pi pi-plus-circle" pTooltip="Insert Row at Top" tooltipPosition="bottom"
                                styleClass="p-button-rounded p-button-success p-button-text"
                                (onClick)="createFirstRow()"></p-button>

                            <p-button icon="pi pi-arrow-circle-down" pTooltip="Retrieve all records"
                                tooltipPosition="bottom" styleClass="p-button-rounded p-button-warning p-button-text"
                                (onClick)="subscribedToLoadDataAtOnce()"></p-button>
                        </th>
                    </tr>

                </ng-template>
                <!-- </thead> -->
                <!-- <tbody styleClass="p-datatable-gridlines  p-datatable-striped"> -->
                <ng-template pTemplate="body" let-product>
                    <ng-container *ngFor="let row of rows.controls; let i = index">
                        <!-- <input type="hidden" formControlName="lengthAllowance"/> -->

                        <!-- <input type="hidden" formControlName="girthAllowance"/> -->
                        <!--  <tr @fadeAnimation *ngFor="let row of rows.controls; let i = index" [formGroupName]="i"
                    [ngClass]="{'review': row.get('review')?.value, 'highlight': row.get('highlight')?.value}">
                     -->
                        <tr *ngIf="row.get('action')?.value !== 'REMOVED'" @fadeAnimation [formGroupName]="i"
                            [ngClass]="{'review': row.get('review')?.value, 'highlight': row.get('highlight')?.value}">
                            <!--     <input  type="hidden" formControlName="serialNumber"   [value]="i + 1" 
                    (input)="row.get('serialNumber')?.setValue(i + 1)"> -->
                            <input type="hidden" formControlName="serialNumber">
                            <input type="hidden" formControlName="lengthAllowance" />
                            <input type="hidden" formControlName="girthAllowance" />
                            <input type="hidden" formControlName="review">
                            <!--  <td class="text-center">
                            <p-checkbox  [binary]="true" inputId="binary" formControlName="review"  (onChange)="onReviewChange($event, row)"></p-checkbox> 
                        </td> -->
                            <td class="text-center">
                                <ng-container *ngIf="!loadingReviewRows[row.get('id')?.value]; else loadingTemplate">
                                    <p-checkbox [binary]="true" inputId="binary" formControlName="review"
                                        class="custom-checkbox" (onChange)="onReviewChange($event, row)"></p-checkbox>
                                </ng-container>
                                <ng-template #loadingTemplate>
                                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                </ng-template>
                            </td>

                            <td class="text-center"> {{ i +1 }} </td>

                            <td class="text-center">
                                <input pInputText type="number" pKeyFilter="num" formControlName="grossLength"
                                    class="w-5rem text-right" #grossLengthInput id="numberRegex"
                                    (input)="finishEditRow(i)"
                                    [class.is-out-of-range]="isOutOfRange(row,'grossLength','length')"
                                    [class.is-5x-multiplication]="isMultipliedByFive(row,'grossLength')"
                                    [class.required]="row.get('grossLength')?.errors?.['required']"
                                    (keydown.enter)="moveFocus(grossLengthInput, grossGirthInput, i, $event)"
                                    (keydown.meta.arrowdown)="navigateUpDown($event, 1, 'length', i)"
                                    (keydown.meta.arrowup)="navigateUpDown($event, -1, 'length', i)"
                                    (keydown.meta.arrowright)="setFocusOnElement(i,grossGirthInput)"
                                    (keydown.meta.arrowleft)="setFocusOnElement(i,grossLengthInput)"
                                    (focus)="startEditRow(i)" (blur)="finishEditRow(i)">

                            </td>
                            <td class="text-center">
                                <input pInputText type="number" pKeyFilter="num" formControlName="grossGirth"
                                    class="w-5rem text-right" #grossGirthInput
                                    [class.is-out-of-range]="isOutOfRange(row,'grossGirth','girth')"
                                    (input)="finishEditRow(i)"
                                    [class.required]="row.get('grossGirth')?.errors?.['required']"
                                    (keydown.enter)="moveFocus(grossGirthInput, grossLengthInput,  i, $event, true)"
                                    (keydown.meta.arrowdown)="navigateUpDown($event, 1, 'girth', i)"
                                    (keydown.meta.arrowup)="navigateUpDown($event, -1, 'girth', i)"
                                    (keydown.meta.arrowleft)="setFocusOnElement(i,grossLengthInput)"
                                    (focus)="startEditRow(i)" (blur)="finishEditRow(i)">
                            </td>
                            <td>
                                <!-- <input pInputText formControlName="netLength" class="w-7rem" readonly> -->
                                <input type="hidden" formControlName="netLength">
                                <p class="text-right"> {{ row.get('netLength')?.value }}</p>
                            </td>

                            <td>
                                <!-- <input pInputText formControlName="netGirth" class="w-7rem" readonly> -->
                                <input type="hidden" formControlName="netGirth">
                                <p class="text-right"> {{ row.get('netGirth')?.value }}</p>
                            </td>


                            <td>
                                <!-- <input pInputText formControlName="grossVolumeCbm" class="w-7rem" readonly> -->
                                <input type="hidden" formControlName="grossVolumeCbm">
                                <p class="text-right"> {{ row.get('grossVolumeCbm')?.value }}</p>
                            </td>
                            <td>
                                <!-- <input pInputText formControlName="grossVolumeCft" class="w-7rem" readonly> -->
                                <input type="hidden" formControlName="grossVolumeCft">
                                <p class="text-right"> {{ row.get('grossVolumeCft')?.value }}</p>
                            </td>

                            <td>
                                <!-- <input pInputText formControlName="netVolumeCbm" class="w-7rem" readonly> -->
                                <input type="hidden" formControlName="netVolumeCbm">
                                <p class="text-right"> {{ row.get('netVolumeCbm')?.value }}</p>
                            </td>


                            <td>
                                <!-- <input pInputText formControlName="netVolumeCft" class="w-7rem" readonly> -->
                                <input type="hidden" formControlName="netVolumeCft">
                                <p class="text-right"> {{ row.get('netVolumeCft')?.value }}</p>
                            </td>


                            <td class="text-center">

                                <!-- <p-button icon="pi pi-plus-circle" (click)="createRowFromTable(i,i+1)" class="mr-2"
                                styleClass="p-button-rounded p-button-success p-button-text"></p-button>

                            <p-button icon="pi pi-times-circle" (click)="removeRow(i)" *ngIf="rows.length > 1"
                                styleClass="p-button-rounded p-button-danger p-button-text"></p-button> -->
                                <div class="inline-flex">
                                    <div class="col-fixed">
                                        <div class="text-center p-0 font-bold">
                                            <p-button icon="pi pi-plus-circle" (click)="createRowFromTable(i,i+1)"
                                                tooltipPosition="bottom"
                                                styleClass="p-button-rounded p-button-success p-button-text"
                                                pTooltip="Insert Following Row"></p-button>
                                        </div>
                                    </div>
                                    <div class="col-fixed">
                                        <div class="text-center p-0 font-bold">
                                            <p-button icon="pi pi-times-circle" (click)="removeRow(i)"
                                                *ngIf="rows.length > 1" pTooltip="Remove Current Row"
                                                tooltipPosition="bottom"
                                                styleClass="p-button-rounded p-button-danger p-button-text"></p-button>
                                        </div>
                                    </div>
                                </div>



                            </td>
                        </tr>
                    </ng-container>
                </ng-template>
                <!-- </tbody> -->
                <ng-template pTemplate="footer">
                    <tr>
                        <td colspan="5" class="text-right" style="z-index: -99999;">Total</td>
                        <!-- <td class="text-right">{{ totalPieces }}</td> -->
                        <td class="text-right">{{ totalGrossVolumeCbm.toFixed(3) }}</td>
                        <td class="text-right">{{ totalGrossVolumeCft.toFixed(3) }}</td>
                        <td class="text-right">{{ totalNetVolumeCbm.toFixed(3) }}</td>
                        <td class="text-right">{{ totalNetVolumeCft.toFixed(3) }}</td>
                        <td></td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div class="flex align-items-center justify-content-between">In total there are {{ totalPieces?? 0
                        }}/{{ totalRecords?? 0 }}
                        pieces.</div>
                </ng-template>
            </p-table>
        </div>

        <!-- Button for scroll to top -->
        <p-button *ngIf="!isOnTop()" (onClick)="scrollToTop()" icon="pi pi-arrow-up"
            styleClass="p-button-rounded p-button-outlined" class="scroll-button scroll-top"
            [style]="{'position': 'absolute', 'left': '0', 'top': '50%'}"></p-button>



        <!--  <button (click)="scrollToTop()" class="scroll-button scroll-top" style="position: absolute; left: 0; top: 50%;">
            Scroll to Top
        </button> -->


        <!-- Button for scroll to bottom -->
        <p-button *ngIf="!isOnBottom()" (onClick)="scrollToBottom()" icon="pi pi-arrow-down"
            styleClass="p-button-rounded p-button-outlined" class="scroll-button scroll-bottom"
            [style]="{'position': 'absolute', 'right': '0', 'top': '50%'}"
            (dblclick)="subscribedToLoadDataAtOnce()"></p-button>
        <!-- <button (click)="scrollToBottom()" class="scroll-button scroll-bottom"
            style="position: absolute; right: 0; top: 50%;">
            Scroll to Bottom
        </button> -->
    </div>
    <ng-template #skeleton>


        <p-table [value]="dummyArrayHorizontalGrid"
            styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped" selectionMode="single"
            [(selection)]="selectedRow" dataKey="uniqueKey">
            <ng-template pTemplate="header">
                <tr>
                    <!-- <th rowspan="2" class="text-center">Review</th> -->
                    <th rowspan="2" class="text-center">SR</th>
                    <th class="text-center">G. Length</th>
                    <th class="text-center">
                        G. Girth
                    </th>

                    <th class="text-center">N. Length</th>
                    <th class="text-center">N. Girth</th>

                    <th rowspan="2" class="text-center">G. CBM</th>
                    <th rowspan="2" class="text-center">G. CFT</th>

                    <th rowspan="2" class="text-center">N. CBM</th>
                    <th rowspan="2" class="text-center">N. CFT</th>

                    <th rowspan="2" class="text-center">Actions</th>
                </tr>
                <tr>
                    <th class="text-center uppercase">
                        Unit
                    </th>
                    <th class="text-center uppercase">
                        Unit
                    </th>

                    <th class="text-center uppercase">
                        Unit
                    </th>
                    <th class="text-center uppercase">
                        Unit
                    </th>
                </tr>

            </ng-template>
            <ng-template pTemplate="body">
                <!-- <ng-container *ngFor="let row of rows.controls; let i = index"> -->

                <tr style="height:46px !important" *ngFor="let dummy of [1, 2, 3, 4, 5]">
                    <!-- <td class="text-center">
                        <p-skeleton></p-skeleton>
                    </td> -->
                    <td class="text-center"><p-skeleton></p-skeleton></td>

                    <td class="text-center">
                        <p-skeleton></p-skeleton>

                    </td>
                    <td class="text-center">
                        <p-skeleton></p-skeleton>
                    </td>
                    <td>
                        <p-skeleton></p-skeleton>
                    </td>

                    <td>
                        <p-skeleton></p-skeleton>
                    </td>


                    <td>
                        <p-skeleton></p-skeleton>
                    </td>
                    <td>
                        <p-skeleton></p-skeleton>
                    </td>

                    <td>
                        <p-skeleton></p-skeleton>
                    </td>


                    <td>
                        <p-skeleton></p-skeleton>
                    </td>

                    <td class="text-center">

                        <p-skeleton></p-skeleton>



                    </td>
                </tr>
                <!-- </ng-container> -->
            </ng-template>
            <!-- </tbody> -->
            <ng-template pTemplate="footer">
                <tr>
                    <td colspan="6" class="text-right"> <p-skeleton></p-skeleton></td>
                    <!-- <td class="text-right">{{ totalPieces }}</td> -->
                    <td class="text-right"> <p-skeleton></p-skeleton></td>
                    <td class="text-right"> <p-skeleton></p-skeleton></td>
                    <td class="text-right"> <p-skeleton></p-skeleton></td>
                    <td class="text-right"> <p-skeleton></p-skeleton></td>
                    <td> <p-skeleton></p-skeleton></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="flex align-items-center justify-content-between">In total there are {{ totalPieces?? 0 }}
                    pieces.</div>
            </ng-template>
        </p-table>
    </ng-template>

    <!--  <button pButton type="submit" label="Submit" (click)="printData()" class="mr-2"></button>
    <button pButton type="button" label="Difference" (click)="persistChanges()"></button> -->
</form>



<p-confirmDialog [visible]="dialogLoadAllData" [acceptVisible]="false" [rejectVisible]="false"
    [message]="'Hold on for a moment; your data is currently being configured.'" [header]="'One Moment, Please'"
    [closable]="false" [icon]="'pi pi-exclamation-triangle'">
</p-confirmDialog>



<!-- 
<button (click)="scrollToTop()">Scroll to Top</button>
<button (click)="scrollToBottom()">Scroll to Bottom</button>

<button (click)="isOnTop()">Is On Top</button>
<button (click)="isOnBottom()">Is On Bottom</button> 

<button (click)="exportExcel()">Export to Excel</button>
<button (click)="exportPdf()">Export to PDF</button>
<button (click)="subscribedToLoadDataAtOnce()">Load Data at Once</button>
<button (click)="testAutoSave()">testAutoSave()</button> 
-->
<!-- <p-button label="Show Console" styleClass="p-button-outlined p-button-danger"
    (click)="isConsoleDialogVisible = true"></p-button> -->


<p-dialog key="consoleDialog" header="Console" [(visible)]="isConsoleDialogVisible" [modal]="true" classs="w-9">
    <div>
        <h3>Before Submit</h3>
        <p>
            <!-- {{beforeSubmitConsoleContent}} -->
            <!-- {{ payload?.rows?? [] | json}}   -->
        </p>
        <table *ngIf="tableForm?.value?.rows?? []" class="my-table">
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Serial Number</th>
                    <th>Gross Length</th>
                    <th>Gross Girth</th>
                    <th>Net Length</th>
                    <th>Net Girth</th>
                    <th>Action</th>
                    <th>Edit Mode</th>
                    <!-- <th>UUID</th> -->
                    <th>Review</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of tableForm?.value?.rows?? []">
                    <td>{{ row.id }}</td>
                    <td>{{ row.serialNumber }}</td>
                    <td>{{ row.grossLength }}</td>
                    <td>{{ row.grossGirth }}</td>
                    <td>{{ row.netLength }}</td>
                    <td>{{ row.netGirth }}</td>
                    <td>{{ row.action }}</td>
                    <td>{{ row.editMode }}</td>
                    <!-- <td>{{ row.uuid }}</td> -->
                    <td>{{ row.review }}</td>
                </tr>
            </tbody>
        </table>

    </div>
    <h3>After Submit</h3>
    <div>
        <p>

        </p>
        <table *ngIf="(sawnTimberResponseObservable | async)?.rows" class="my-table">
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Serial Number</th>
                    <th>Gross Length</th>
                    <th>Gross Girth</th>
                    <th>Net Length</th>
                    <th>Net Girth</th>
                    <th>Action</th>
                    <th>Edit Mode</th>
                    <!-- <th>UUID</th> -->
                    <th>Review</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of payload.rows">
                    <td>{{ row.id }}</td>
                    <td>{{ row.serialNumber }}</td>
                    <td>{{ row.grossLength }}</td>
                    <td>{{ row.grossGirth }}</td>
                    <td>{{ row.netLength }}</td>
                    <td>{{ row.netGirth }}</td>
                    <td>{{ row.action }}</td>
                    <td>{{ row.editMode }}</td>
                    <!-- <td>{{ row.uuid }}</td> -->
                    <td>{{ row.review }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <h3>After Submit RESPONSE</h3>

    <div>
        <p>

        </p>

    </div>
    <ng-template pTemplate="footer">
        <p-button label="Hide Console" styleClass="p-button-outlined p-button-danger"
            (click)="isConsoleDialogVisible = false"></p-button>
    </ng-template>
</p-dialog>

<p-button icon="pi pi-save" styleClass="p-button-sm p-button-success" class="layout-save-button p-link"
    pTooltip="Save" tooltipPosition="left" (onClick)="onSubmit()"></p-button>