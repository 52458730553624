<div class="mt-3"></div>
<p-card  [style]="{ width: '75vw' }" >
    <ng-template pTemplate="header">

        <div class="flex align-items-center justify-content-center w-full h-4rem font-bold">
            <i class="pi pi-check-circle text-center" style="color: green;font-size: 2rem !important"></i>
        </div>
        <p class="text-center" style="color: green;font-size: 1.5rem !important">Payment Successful</p>
    </ng-template>
    <table class="payment-details" *ngIf="this.paymentDetails.currency">
        <tbody>
            <tr>
                <td class="text-bold">Amount</td>
                <td> {{this.paymentDetails.grandTotal | currency:this.paymentDetails.currency:'symbol':'1.2-2'  }} </td>
            </tr>
            <tr>
                <td class="text-bold">Currency</td>
                <td> {{this.paymentDetails.currency}}</td>
            </tr>
            <tr>
                <td class="text-bold">Transaction ID</td>
                <td>{{this.paymentDetails.transactionId}} </td>
            </tr>
            <tr>
                <td class="text-bold">Package Subscribed</td>
                <td>{{this.paymentDetails.subscriptionPackage}} </td>
            </tr>
            <tr>
                <td class="text-bold">Subscription Period</td>
                <td>{{this.paymentDetails.subscriptionPeriod}} </td>
            </tr>
            <tr>
                <td class="text-bold">Payment Gateway</td>
                <td>{{this.paymentDetails.paymentGateway}} </td>
            </tr>
            <tr>
                <td class="text-bold">Invoice Number</td>
                <!-- <td>{{this.paymentDetails.invoiceNo}} </td> -->
                <td>    <p-button pTooltip="Download Invoice" tooltipPosition="bottom" [label]="this.paymentDetails.invoiceNo" styleClass="p-button-link" (onClick)="onDownload(this.paymentDetails.subscriptionOrderId)"></p-button>

                </td>
            </tr>
        </tbody>
    </table>


    <ng-template pTemplate="footer">

        <div class="flex align-items-center justify-content-center w-full h-4rem font-bold">
            <p-button class="text-center" label="Logout" icon="pi pi-sign-out" (onClick)="onLogout()"></p-button>
            <p-button *ngIf="this.paymentDetails.currency" class="text-center" label="Download" icon="pi pi-download" class="ml-2" (onClick)="onDownload(this.paymentDetails.subscriptionOrderId)"></p-button>
        </div>
        <p class="text-xs">* Note: We recommend that you log out and then log in again after completing your payment. This ensures that all features associated with your new subscription are fully activated and available for use.</p>
    </ng-template>
</p-card>