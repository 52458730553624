import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of, from, switchMap } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BuyerService } from '../../buyer/services/buyer.service';
import { LoadingSiteService } from '../../loading-site/services/loading-site.service';
import { EncryptionService } from 'src/app/services/encryption.service';

@Injectable({
  providedIn: 'root',
})
export class LoadingSiteDetailsResolver implements Resolve<any> {
  constructor(
    private loadingsiteService: LoadingSiteService, 
    private router: Router,
    private encryptionService: EncryptionService) { }

  // resolve(route: ActivatedRouteSnapshot): Observable<any> {
  //   const encryptedId = route.paramMap.get('id');
  //   if (encryptedId) {
  //     const decodedId = atob(encryptedId); // getLoadingSiteById the ID
  //     return this.loadingsiteService.getLoadingSiteById(decodedId).pipe(
  //       tap((response) => {
  //         console.log(`LoadingSiteDetailsResolver response is ${JSON.stringify(response)}`)
  //       }),
  //       catchError((error) => {
  //         console.error(`LoadingSiteDetailsResolver error is ${JSON.stringify(error)}`);
  //         this.router.navigate(['/error']); // Redirect on error
  //         return of(null);
  //       })
  //     );
  //   } else {
  //     this.router.navigate(['/buyers']);
  //     return of(null);
  //   }
  // }
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const encryptedId = route.paramMap.get('id');
    if (encryptedId) {
      return from(this.encryptionService.decrypt(encryptedId)).pipe(
        switchMap((decodedId: any) =>
          this.loadingsiteService.getLoadingSiteById(decodedId).pipe(
            tap((response) => {
              console.log(`LoadingSiteDetailsResolver response is ${JSON.stringify(response)}`)
            }),
            catchError((error) => {
              console.error(`LoadingSiteDetailsResolver error is ${JSON.stringify(error)}`);
              this.router.navigate(['/error']); // Redirect on error
              return of(null);
            })
          )
        ),
        catchError((error: any) => {
          console.error(`Decryption error: ${error}`);
          this.router.navigate(['/loading-sites']);
          return of(null);
        })
      );
    } else {
      this.router.navigate(['/loading-sites']);
      return of(null);
    }
  }
}
