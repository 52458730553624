import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { Photo } from '../../container/model/photo';
import { ContainerService } from '../../container/services/container.service';
import { Photos } from '../model/photos';
import { MeasurementSharedDataService } from '../services/measurement-shared-data.service';
import { MeasurementService } from '../services/measurement.service';
import { ImageModule } from 'primeng/image';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { UtilService } from 'src/app/services/util.service';
import { SHARED_MODULES } from '../../shared-imports';


@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.css'],
  standalone:true,
  imports:[SHARED_MODULES]

})
export class PhotosComponent implements OnInit {


  @Input() inputPhotos!: Photos;
  @Input() containerId!: number;
  refreshFlag = true;

  fileName = '';
  progress = 0;
  currentIndex = 0;
  pScrollerWidth!: string;
  pScrollerImgWidth!: string;
  pScrollerHeight!: string;

  // containerId!: number;
  files: Photo[] = [];
  responsiveOptions!: any[];
  first: number = 0;
  images1!: any[];
  showProducts = false;
  isMobile: boolean;
  scrollHeight!: string;
  ;

  existingImages: Photo[] = [
    /* {
      originalFilename: 'originalFilename 1',
      fileExtension: '.jpeg',
      fileSize: 12400,
      imageName: 'imageName 1.jpeg',
      imageUrl: 'https://storage.googleapis.com/packing-list-storage/1d239f21-250c-45cc-bc75-fae06660437b_thumb.png',
      photoId: 1,
      thumbanailImageName: 'thumbanailImageName 1',
      thumbnailUrl: 'https://storage.googleapis.com/packing-list-storage/1d239f21-250c-45cc-bc75-fae06660437b_thumb.png',
      isNew: false,
      progress: 100
    },
    {
      originalFilename: 'originalFilename 2',
      fileExtension: '.jpeg',
      fileSize: 12400,
      imageName: 'imageName 2.jpeg',
      imageUrl: 'https://storage.googleapis.com/packing-list-storage/1d239f21-250c-45cc-bc75-fae06660437b_thumb.png',
      photoId: 2,
      thumbanailImageName: 'thumbanailImageName 2',
      thumbnailUrl: 'https://storage.googleapis.com/packing-list-storage/1d239f21-250c-45cc-bc75-fae06660437b_thumb.png',
      isNew: false,
      progress: 100
    } */
  ]
  activeIndex = 0;
  isFullScreenImage = false;;
  selectedImageUrlForFullScreen!: string;

  constructor(
    private containerService: ContainerService,
    private measurementService: MeasurementService,
    private measurementSharedDataService: MeasurementSharedDataService,
    private changeDetector: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private messageService: ToastMessagesService,
    private utilService: UtilService
  ) {
    this.isMobile = this.utilService.isMobile();
    this.setScrollHeight();
    // this.images1.push("https://storage.googleapis.com/packing-list-storage/177810d4-8c37-414f-b98f-8ff7a0bdba0f.jpg");
    // this.images1.push("https://storage.googleapis.com/packing-list-storage/43037a79-6515-44a7-b0f9-8e0ed6cb0570.jpg");
    // this.images1.push("https://storage.googleapis.com/packing-list-storage/3b0f1744-f0cb-4ef3-a8b2-7ccf7fe0f61d.jpg");
    this.dummyImages();
    this.containerId = this.measurementSharedDataService.getContainerIdStorage();
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 5
      },
      {
        breakpoint: '768px',
        numVisible: 3
      },
      {
        breakpoint: '560px',
        numVisible: 1
      }
    ];
  }

  dummyImages() {
    // this.measurementService.getImages().then(images => this.inputPhotos.images = images);
  }

  ngOnInit(): void {
    // this.initializeFormWithExistingData(this.existingImages);
    this.getContainerImages(this.containerId);
  }

  onUpload(event: any) {
    for (let file of event.files) {
      this.uploadFile(file);
    }
  }

  uploadFile(file: any) {
    if (!this.isValidFile(file)) {
      alert('Invalid file. Please select a valid file and try again.');
      return;
    }
    console.log(`File Values ${JSON.stringify(file)}`)
    const index = this.files.length;

    this.files.push({
      ...file,
      progress: 0,
      isNew: true,
      uploadInProgress: true
    });

    const newFile = this.files[index];

    this.containerService.uploadContainerImages(file, this.containerId).subscribe(
      (response: Photo[]) => {
        console.log(`response1 ${JSON.stringify(response)}`);
        response.forEach((eachFileResponse: Photo) => {
          console.log(`eachFileResponse ${JSON.stringify(JSON.stringify(eachFileResponse))}`);
          this.simulateProgress(newFile, eachFileResponse);
        });
      }, (error: any) => {
        console.log(error);
      });
  }

  simulateProgress(file: any, eachFileResponse: Photo) {
    const updateProgress = (progress: number) => {
      if (progress < 100) {
        setTimeout(() => {
          file.progress = progress;
          file.name = this.updateFileName(file.name);
          console.log(`finish uploading ${file.progress}`);
          updateProgress(progress + Math.floor(Math.random() * 10) + 1);
        }, 100); // Change this value to control the speed of the progress bar
      } else {
        this.finishUploading(file, eachFileResponse);
      }
    };
    updateProgress(0);
  }

  updateFileName(name: string): string {
    if (!name) return 'Uploading.';

    const dotCount = (name.match(/\./g) || []).length;
    if (dotCount == 5) {
      return 'Uploading';
    }
    return name + '.';
  }

  finishUploading(file: any, eachFileResponse: Photo) {
    console.log(`finish uploading file ${JSON.stringify(file)}`);
    console.log(`finish uploading eachFileResponse ${JSON.stringify(eachFileResponse)}`);
    file.progress = 100;
    file.isNew = false;
    file.uploadInProgress = false;
    this.files.push(eachFileResponse);
    this.files = this.files.filter(obj => obj !== file);
    this.ngOnInit();
  }

  isValidFile(file: any): boolean {
    const isImage = /^image\//.test(file.type);
    const isFileSizeValid = file.size <= 5 * 1024 * 1024;
    const isFileCountValid = this.files.length < 5;

    return isImage && isFileSizeValid && isFileCountValid;
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  initializeFormWithExistingData(existingFiles: any[]) {
    for (const file of existingFiles) {
      this.files.push({
        ...file,
        isNew: false
      });
    }
  }

  deleteUploadedFile(index: number, photo: Photo) {
    console.log(`deleteUploadedFile index is ${index} and fileObject is ${JSON.stringify(photo)}`);
    photo.deleteInProgress = true;

    this.containerService.deleteByPhotoId(photo.photoId).subscribe(
      (respons: any) => {
        setTimeout(() => {
          console.log(`File Removed Successfully`);
          const idsToRemove = this.files.map(obj => photo);
          this.files = this.files.filter(obj => !idsToRemove.includes(obj));
        }, 750);
      },
      (error: any) => {
        console.error(`Error while Removing Photo ${JSON.stringify(error)}`);
        photo.deleteInProgress = false;
      },
      () => {
      },
    );
  }

  onDeleteByGallaryActiveImageIndex() {
    console.log(`deleteUploadedFile index is ${this.activeIndex} and fileObject is ${JSON.stringify(this.existingImages[this.activeIndex])}`);
    const photo = this.existingImages[this.activeIndex];
    photo.deleteInProgress = true;
    this.confirmationService.confirm({
      message: 'Do you want to delete this image?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        //  this.deleteByGallaryActiveImageIndex();
      },
      reject: (type: any) => {

      }
    });
  }


  deleteByGallaryActiveImageIndex(id: number) {
    console.log(`deleteUploadedFile index is ${this.activeIndex} and fileObject is ${JSON.stringify(this.existingImages[this.activeIndex])}`);
    // const photo = this.existingImages[this.activeIndex];
    //photo.deleteInProgress = true;


    this.containerService.deleteByPhotoId(id).subscribe(
      (respons: any) => {
        setTimeout(() => {
          console.log(`File Removed Successfully`);
          //this.existingImages.splice(this.activeIndex, 1);
          this.messageService.showSuccessMessage('Image Deleted Successfully', 'The image has been deleted successfully.');
          //  const idsToRemove = this.files.map(obj => photo);
          //this.files = this.files.filter(obj => !idsToRemove.includes(obj));
        }, 750);
      },
      (error: any) => {
        console.error(`Error while Removing Photo ${JSON.stringify(error)}`);
        this.messageService.showErrorMessage('Error While Deleting Image', 'There was an error while deleting the image. Please try again later.');
        //photo.deleteInProgress = false;
      },
      () => {
        this.ngOnInit();
      },
    );
  }

  private getContainerImages(containerId: number) {
    console.log(`getContainerImages for ContainerID ${containerId}`);
    this.containerService.getContainerImages(containerId).subscribe(
      (data: Photo[]) => {
        console.log(`getContainerImages ${JSON.stringify(data)}`);
        this.existingImages = data;
        this.initializeFormWithExistingData(data);
      },
      (error: HttpErrorResponse) => {
        this.messageService.showErrorMessage('Error', 'Error while feching images');
      },
      () => {

      },

    )
  }

  onSubmit() {
    console.log(`onSubmit ${JSON.stringify(this.files)}`);
  }

  onActiveIndexChange(event: any) {
    this.activeIndex = event;
    console.log(`onActiveIndexChange ${JSON.stringify(event)}`)
  }

  fullScreenImage(imagePath: string) {
    this.isFullScreenImage = true;
    this.selectedImageUrlForFullScreen = imagePath;

  }

  testClick(image: any) {
    console.log(`test clicked on image ${JSON.stringify(image)}`);
  }

  changeImage(direction: string): void {
    if (direction === 'next') {
      this.currentIndex = (this.currentIndex + 1) % this.existingImages.length;
    } else {
      this.currentIndex = (this.currentIndex - 1 + this.existingImages.length) % this.existingImages.length;
    }
  }

  onPageChange(event: any) {
    this.first = event.first;
    // this.rows = event.rows;
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = this.utilService.isMobile();
    this.setScrollHeight();

  }

  setScrollHeight() {

    const width = window.innerWidth;
    const height = window.innerHeight;
    this.pScrollerWidth = (width - 200) + 'px';
    console.log(`Window width: ${window.innerWidth}, height: ${window.innerHeight}`);
    if (width < 576) {
      this.scrollHeight = `${14.2 * width / 620}rem`;
      this.pScrollerHeight = `${14 * width / 620}rem`;
      this.pScrollerImgWidth = `${14 * width / 620}rem`;
      this.pScrollerWidth = `${28 * width / 580}rem`;
    }
    else if (width > 576 && width < 767) {
      // this.scrollHeight ="14.2" + 'rem';
      // this.pScrollerHeight = "14" + 'rem';
      // this.pScrollerImgWidth  = "14" + 'rem';
      this.scrollHeight = `${14.2 * width / 620}rem`;
      this.pScrollerHeight = `${14 * width / 620}rem`;
      this.pScrollerImgWidth = `${14 * width / 620}rem`;
      this.pScrollerWidth = `${28 * width / 580}rem`;
      console.log(`Value is ${this.pScrollerWidth}`)
    }
    else if (width > 768 && width < 992) {
      this.scrollHeight = `${14.2 * width / 620}rem`;
      this.pScrollerHeight = `${14 * width / 620}rem`;
      this.pScrollerImgWidth = `${14 * width / 620}rem`;
      this.pScrollerWidth = `${28 * width / 580}rem`;
    }
    else if (width > 993) {
      this.scrollHeight = `${8.2 * width / 620}rem`;
      this.pScrollerHeight = `${8 * width / 620}rem`;
      this.pScrollerImgWidth = `${8 * width / 620}rem`;
      this.pScrollerWidth = `${20 * width / 580}rem`;
    }

  }

  refreshDiv() {
    this.refreshFlag = !this.refreshFlag;
  }
  confirm1: any;


  deleteImage($event: Event, photo: Photo) {
    console.log(`event ${JSON.stringify(event)}`);
    console.log(`Photo ${JSON.stringify(photo)}`);
    this.confirmationService.confirm({
      target: event!.target!,
      message: 'Are you sure you want to delete this image?',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirm1',
      accept: () => {
        this.deleteByGallaryActiveImageIndex(photo.photoId);
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
      },
      reject: () => {
        //this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
      }
    });
  }

  confirm(event: Event) {

  }

}


