import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AppConstants } from 'src/app/commons/app-constants';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';
import { SawnTimberService } from '../../../services/sawn-timber.service';
import { HistogramDataService } from 'src/app/services/histogram-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';

@Component({
  selector: 'app-sawn-timber-view-summary',
  templateUrl: './sawn-timber-view-summary.component.html',
  styleUrls: ['./sawn-timber-view-summary.component.css'],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class SawnTimberViewSummaryComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  isMobile = false;
  tableStyle: any;
  tableStyleOptions: any;
  @Input() data: any;
  histogramData!: any[];
  lengthSummary!: any[];
  widthSummary!: any[];
  heightSummary!: any[];
  containerDetails: any;
  containerId: any;
  containerDetailsArray!: { property: string; value: unknown }[];
  isPublic = false;
  containerUUID!: string | null;
  isLoading = true; // loading property
  lengthUnit: any;
  widthUnit: any;
  thicknessUnit: any;

  constructor(
    private encryptedStorageService: EncryptedStorageService,
    private sawnTimberService: SawnTimberService,
    private histogramDataService: HistogramDataService,
    private router: Router,
    private route: ActivatedRoute,
    private utilService: UtilService
  ) {
    this.isMobile = this.utilService.isMobile();
    this.tableStyle = this.utilService.getTableSize();
    this.tableStyleOptions = this.utilService.tableStyleOptions;
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.isPublic = this.router.url.includes('/public/view');
 
    try {
      if (!this.isPublic) {
        try {
          const data = await this.encryptedStorageService
            .getEncryptedDataFromStorage(AppConstants.VIEW)
            .toPromise();

          this.containerId = data.containerId;

          const summaryData = await this.sawnTimberService
            .getSawnTimberSummary(data.containerId)
            .toPromise();

          const timberData = await this.sawnTimberService
            .getTimbersFromContainerId(data.containerId)
            .toPromise();
           this.lengthUnit = timberData.config.lengthUnit;
           this.widthUnit = timberData.config.widthUnit;
           this.thicknessUnit = timberData.config.heightUnit;

            timberData.config.heightUnit;
            timberData.config.widthUnit;
            console.log(`TimberData is ${JSON.stringify(timberData.config.lengthUnit)}`)
          this.calculate(timberData.rows);

          await this.getPrivateContainerDetails(this.containerId);
        } catch (error) {
          console.error('Failed with VIEW, retrying with CONTAINER', error);

          const data = await this.encryptedStorageService
            .getEncryptedDataFromStorage(AppConstants.CONTAINER)
            .toPromise();

          this.containerId = data.containerId;

          const summaryData = await this.sawnTimberService
            .getSawnTimberSummary(data.containerId)
            .toPromise();

          const timberData = await this.sawnTimberService
            .getTimbersFromContainerId(data.containerId)
            .toPromise();

            this.lengthUnit = timberData.config.lengthUnit;
            this.widthUnit = timberData.config.widthUnit;
            this.thicknessUnit = timberData.config.heightUnit;

            console.log(`TimberData is ${JSON.stringify(timberData)}`)

          this.calculate(timberData.rows);

          await this.getPrivateContainerDetails(this.containerId);
        }
      } else {
        this.containerUUID = this.route.snapshot.paramMap.get('containerUUID');

        const timberData = await this.sawnTimberService
          .getPublicTimbersFromContainerUUID(this.containerUUID)
          .toPromise();

          this.lengthUnit = timberData.config.lengthUnit;
          this.widthUnit = timberData.config.widthUnit;
          this.thicknessUnit = timberData.config.heightUnit;

        this.calculate(timberData.rows);

        await this.getPublicContainerDetails(this.containerUUID);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  ngOnDestroy(): void {}
  ngAfterViewInit(): void {}

  /* calculate(data: any) {
    console.log(`Data of Rows is ${JSON.stringify(data)}`);
    let totalPieces = data.reduce((acc: any, item: any) => {
      let lengthKey = `length:${item.length}`;
      let widthKey = `width:${item.width}`;
      let heightKey = `height:${item.height}`;

      if (!acc[lengthKey]) {
        acc[lengthKey] = {
          dimension: item.length,
          totalPieces: 0,
          type: 'length',
        };
      } else {
        acc[lengthKey].totalPieces += item.pieces;
      }

      if (!acc[widthKey]) {
        acc[widthKey] = {
          dimension: item.width,
          totalPieces: 0,
          type: 'width',
        };
      } else {
        acc[widthKey].totalPieces += item.pieces;
      }

      if (!acc[heightKey]) {
        acc[heightKey] = {
          dimension: item.height,
          totalPieces: 0,
          type: 'height',
        };
      } else {
        acc[heightKey].totalPieces += item.pieces;
      }

      return acc;
    }, {});

    this.lengthSummary = [];
    this.widthSummary = [];
    this.heightSummary = [];

    for (let key in totalPieces) {
      if (totalPieces[key].type === 'length') {
        this.lengthSummary.push(totalPieces[key]);
      } else if (totalPieces[key].type === 'width') {
        this.widthSummary.push(totalPieces[key]);
      } else if (totalPieces[key].type === 'height') {
        this.heightSummary.push(totalPieces[key]);
      }
    }
    // Assuming lengthSummary, widthSummary, and heightSummary are class properties
    this.lengthSummary.sort((a, b) => a.dimension - b.dimension);
    this.widthSummary.sort((a, b) => a.dimension - b.dimension);
    this.heightSummary.sort((a, b) => a.dimension - b.dimension);
  }*/
  calculate(data: any) {
    console.log(`Data of Rows is ${JSON.stringify(data)}`);
    let totalPieces = data.reduce((acc: any, item: any) => {
      let lengthKey = `length:${item.length}`;
      let widthKey = `width:${item.width}`;
      let heightKey = `height:${item.height}`;

      if (!acc[lengthKey]) {
        acc[lengthKey] = {
          dimension: item.length,
          totalPieces: item.pieces, // change here
          type: 'length',
        };
      } else {
        acc[lengthKey].totalPieces += item.pieces;
      }

      if (!acc[widthKey]) {
        acc[widthKey] = {
          dimension: item.width,
          totalPieces: item.pieces, // change here
          type: 'width',
        };
      } else {
        acc[widthKey].totalPieces += item.pieces;
      }

      if (!acc[heightKey]) {
        acc[heightKey] = {
          dimension: item.height,
          totalPieces: item.pieces, // change here
          type: 'height',
        };
      } else {
        acc[heightKey].totalPieces += item.pieces;
      }

      return acc;
    }, {});

    this.lengthSummary = [];
    this.widthSummary = [];
    this.heightSummary = [];

    for (let key in totalPieces) {
      if (totalPieces[key].type === 'length') {
        this.lengthSummary.push(totalPieces[key]);
      } else if (totalPieces[key].type === 'width') {
        this.widthSummary.push(totalPieces[key]);
      } else if (totalPieces[key].type === 'height') {
        this.heightSummary.push(totalPieces[key]);
      }
    }
    // Assuming lengthSummary, widthSummary, and heightSummary are class properties
    this.lengthSummary.sort((a, b) => a.dimension - b.dimension);
    this.widthSummary.sort((a, b) => a.dimension - b.dimension);
    this.heightSummary.sort((a, b) => a.dimension - b.dimension);
  }


  async getPrivateContainerDetails(containerId: number) {
    try {
      const data = await this.sawnTimberService
        .getContainerDetail(containerId)
        .toPromise();
      this.containerDetails = data;
      this.containerDetailsArray = [
        { property: 'Container Number', value: data.containerNumber },
        { property: 'Net Volume', value: data.totalNetVolume },
        { property: 'Pieces', value: data.pieces },
        { property: 'Product', value: data.productName },
        { property: 'Formula', value: data.formula },
        { property: 'Seal Number', value: data.containerSeal },
      ];
    } catch (error) {
      console.error(error);
    }
  } 

  

  async getPublicContainerDetails(containerUuid: string | null) {
    try {
      const data = await this.sawnTimberService
        .getPublicContainerDetail(containerUuid)
        .toPromise();
      this.containerDetails = data;
      this.containerDetailsArray = [
        { property: 'Container Number', value: data.containerNumber },
        { property: 'Net Volume', value: data.totalNetVolume },
        { property: 'Pieces', value: data.pieces },
        { property: 'Product', value: data.productName },
        { property: 'Formula', value: data.formula },
        { property: 'Seal Number', value: data.containerSeal },
      ];
    } catch (error) {
      console.error(error);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = this.utilService.isMobile();
    this.tableStyle = this.utilService.getTableSize();
    console.log(`is mobile ${this.utilService.isMobile()}`);
  }

  async refresh() {
    this.ngOnInit();
  }

}


/* 
  histogram(data: any, totalBins: number) {
    let bins = this.histogramDataService.createHistogramBins(data, totalBins);
    this.histogramData = this.histogramDataService.countRecordsInBins(
      data,
      bins
    );

    console.log(JSON.stringify(data));
    console.log(this.histogramData);
  } */

/***** OLD CODE same functionalities as above only the difference is above code is refactored 
 ***** and below code is not refactored 
 export class SawnTimberViewSummaryComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() data: any;
  histogramData!: any[];
  lengthSummary!: any[];
  widthSummary!: any[];
  heightSummary!: any[];
  containerDetails: any;
  containerId: any;
  containerDetailsArray!: { property: string; value: unknown }[];
  isPublic = false;
  containerUUID!: string | null;

  constructor(
    private encryptedStorageService: EncryptedStorageService,
    private sawnTimberService: SawnTimberService,
    private histogramDataService: HistogramDataService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {

    this.isPublic = this.router.url.includes('/public/view');

    if (!this.isPublic) {
      this.encryptedStorageService
        .getEncryptedDataFromStorage(AppConstants.VIEW)
        .subscribe(
          (data: any) => {
            console.log(`data is ${JSON.stringify(data)}`);
            this.containerId = data.containerId;
            this.sawnTimberService
              .getSawnTimberSummary(data.containerId)
              .subscribe(
                (data: any) => {
                  console.log(`Response of Server is ${JSON.stringify(data)}`);
                },
                (error: any) => {},
                () => {}
              );

            this.sawnTimberService
              .getTimbersFromContainerId(data.containerId)
              .subscribe(
                (data: any) => {
                  console.log(`Data of rows is ${JSON.stringify(data.rows)}`);
                  // let widthArray = data.map((item: { width: any; }) => item.width);
                  const rows = data.rows;
                  let widthArray = rows.map(
                    (item: { width: number }) => item.width
                  );

                 // this.histogram(widthArray, 5);
                 // this.calculate(rows);
                  this.getPrivateContainerDetails(this.containerId);
                },
                (error: any) => {},
                () => {}
              );
          },
          (error: any) => {},
          () => {}
        );
    } else {
      this.containerUUID = this.route.snapshot.paramMap.get('containerUUID');
      console.log(`Container UUID is ${this.containerUUID}`);
      this.sawnTimberService
        .getPublicTimbersFromContainerUUID(this.containerUUID)
        .subscribe(
          (data: any) => {
            console.log(`Data of rows is ${JSON.stringify(data.rows)}`);
            const rows = data.rows;
            let widthArray = rows.map((item: { width: number }) => item.width);

            this.calculate(rows);
            this.getPublicContainerDetails(this.containerUUID);
          },
          (error: any) => {},
          () => {}
        );
    }
  }
  ngOnDestroy(): void {
  }
  ngAfterViewInit(): void {
  }


  calculate(data: any) {
    let totalPieces = data.reduce((acc: any, item: any) => {
      let lengthKey = `length:${item.length}`;
      let widthKey = `width:${item.width}`;
      let heightKey = `height:${item.height}`;

      if (!acc[lengthKey]) {
        acc[lengthKey] = {
          dimension: item.length,
          totalPieces: 0,
          type: 'length',
        };
      } else {
        acc[lengthKey].totalPieces += item.pieces;
      }

      if (!acc[widthKey]) {
        acc[widthKey] = {
          dimension: item.width,
          totalPieces: 0,
          type: 'width',
        };
      } else {
        acc[widthKey].totalPieces += item.pieces;
      }

      if (!acc[heightKey]) {
        acc[heightKey] = {
          dimension: item.height,
          totalPieces: 0,
          type: 'height',
        };
      } else {
        acc[heightKey].totalPieces += item.pieces;
      }

      return acc;
    }, {});

    this.lengthSummary = [];
    this.widthSummary = [];
    this.heightSummary = [];

    for (let key in totalPieces) {
      if (totalPieces[key].type === 'length') {
        this.lengthSummary.push(totalPieces[key]);
      } else if (totalPieces[key].type === 'width') {
        this.widthSummary.push(totalPieces[key]);
      } else if (totalPieces[key].type === 'height') {
        this.heightSummary.push(totalPieces[key]);
      }
    }
    // Assuming lengthSummary, widthSummary, and heightSummary are class properties
    this.lengthSummary.sort((a, b) => a.dimension - b.dimension);
    this.widthSummary.sort((a, b) => a.dimension - b.dimension);
    this.heightSummary.sort((a, b) => a.dimension - b.dimension);
  }

  getPrivateContainerDetails(containerId: number) {
    this.sawnTimberService.getContainerDetail(containerId).subscribe(
      (data: any) => {
        this.containerDetailsArray = [
          { property: 'Container Number', value: data.containerNumber },
          { property: 'Net Volume', value: data.totalNetVolume },
          { property: 'Pieces', value: data.pieces },
          { property: 'Product', value: data.productName },
          { property: 'Formula', value: data.formula },
          { property: 'Seal Number', value: data.containerSeal },
        ];
        this.containerDetails = data;
      },
      (error: any) => {},
      () => {}
    );
  }

  getPublicContainerDetails(containerUuid: string | null) {
    this.sawnTimberService.getPublicContainerDetail(containerUuid).subscribe(
      (data: any) => {
        this.containerDetailsArray = [
          { property: 'Container Number', value: data.containerNumber },
          { property: 'Net Volume', value: data.totalNetVolume },
          { property: 'Pieces', value: data.pieces },
          { property: 'Product', value: data.productName },
          { property: 'Formula', value: data.formula },
          { property: 'Seal Number', value: data.containerSeal },
        ];
        this.containerDetails = data;
      },
      (error: any) => {},
      () => {}
    );
  }

  
}

*/
