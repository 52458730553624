
<p-dialog
  [(visible)]="displayDialog"
  [modal]="true"
  [style]="{ width: '350px' }"
  [closable]="false"
  [header]="response.message"
  [breakpoints]="{ '960px': '75vw' }" 
>
  <div class="details">
    <p>Country: {{ response!.country }}</p>
    <p>City: {{ response.city }}</p>
    <p>OS: {{ response.os }}</p>
    <p>Browser: {{ response.browser }}</p>
    <p>IP: {{ response.ip }}</p>
    <p>Device: {{ response.device }}</p>
  </div>

  <p>
    Do you still want to log in? If you do, all other users will be logged out
    automatically.
  </p>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check"   label="Force Yes" styleClass="p-button-text"
    (click)="onForceYes()"></p-button>
    <p-button icon="pi pi-check" label="Force No" styleClass="p-button-text"
    (click)="onForceNo()"></p-button>
    
    <!-- <p-button icon="pi pi-check" (click)="displayDialog = false" label="Login" styleClass="p-button-text"
    (click)="onLogin()"></p-button>

    <p-button icon="pi pi-check" (click)="displayDialog = false" label="Cancel"  class="p-button-secondary"
    (click)="onCancel()"></p-button> -->
</ng-template>
  <!-- <p-footer>
    <button pButton type="button" label="Login" (click)="onLogin()"></button>
    <button
      pButton
      type="button"
      label="Cancel"
      class="p-button-secondary"
      (click)="onCancel()"
    ></button>
  </p-footer> -->
</p-dialog>

<!-- 
{{ response | json}}
<a href="{{ googleURL }}?" style="text-decoration: none;">
    <i class="pi pi-google" style="font-size: 2rem; color: #db4437;"></i>
</a> -->