<div class="card-container" *ngIf="!isLoading;else loadingBlock">
    <p-card *ngIf="(images?.length ?? 0) > 0 else noPhotosAvailable">
        <div class="flex flex-wrap md:flex-wrap card-container purple-container" style="max-width: 800px"
            [photoGalleryGroup]="{ arrowEl: true, indexIndicatorSep: ' / ', mouseUsed: true, shareEl: false, tapToToggleControls: true, showAnimationDuration: 200, hideAnimationDuration: 200, arrowKeys: true, closeEl: true }">
            <div class="flex align-items-center justify-content-center font-bold text-white m-2 border-round"
                style="width: 200px; height: 200px;" *ngFor="let image of images">
                <div class="image-container" [photoGallery]="image.imageUrl"
                    [style.backgroundImage]="'url(' + image.thumbnailUrl + ')'">
                    <ng-template pTemplate="indicator">
                        <i class="pi pi-check"></i>
                    </ng-template>
                </div>
            </div>
        </div>
    </p-card>

    <ng-template #noPhotosAvailable>
        <p> No photos available </p>
    </ng-template>
</div>


<ng-template #loadingBlock>
    <p-progressSpinner styleClass="w-4rem h-4rem"></p-progressSpinner>
</ng-template>