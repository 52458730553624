import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidationErrors,
  FormControl,
} from '@angular/forms';
import { UserRegistrationService } from '../services/user-registration.service';
import { UserRegistration } from '../model/user-registration';
import { DatePipe } from '@angular/common';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { UtilService } from 'src/app/services/util.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { LocationSelectionService } from '../../commons/location-selection/service/location-selection.service';
import { Location } from '../../commons/location-selection/model/location';
import { NgOtpInputComponent } from 'ng-otp-input';
import { PhoneValidatorService } from 'src/app/classes/phone-validator.service';
import { SHARED_MODULES } from '../../shared-imports';
import { LocationSelectionComponent } from '../../commons/location-selection/location-selection.component';

@Component({
  selector: 'app-user-registration',
  templateUrl: './add-user-to-existing-company.html',
  styleUrls: ['./add-user-to-existing-company.css'],
  providers: [ToastMessagesService],
  standalone: true,
  imports:[SHARED_MODULES, LocationSelectionComponent
  ]
})
export class AddUserToExistingCompanyComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  blockedPanel: boolean = false;

  selectedIsdCode!: any;
  isdCodes!: any[];
  existingLocationData!: Location;
  isEditMode = false;
  mobileVerified = false;
  userRegistrationForm!: FormGroup;
  userRegistrations: UserRegistration[] = [];
  mobileButtonStyles: any[] = [
    {
      icon: 'pi pi-times-circle',
      style: 'p-button-rounded p-button-danger p-button-text',
      tooltip: 'Not Verified',
      isVerified: false,
    },
    {
      icon: 'pi pi-check-circle',
      style: 'p-button-rounded p-button-success p-button-text',
      tooltip: 'Verified',
      isVerified: true,
    },
  ];
  submitButtonLabel = 'Register';
  showOTP = true;
  showOtpDialogHeader = true;
  navigate!: string;

  genderList: any[] = [
    { name: 'Male', key: 'Male' },
    { name: 'Female', key: 'Female' },
    { name: 'Other', key: 'Other' },
  ];
  mobileButtonStyle: any;
  data!: UserRegistration;
  showTimer: boolean = false;
  isMobile: any;
  maximumDate!: Date;
  isFirstLogin = false;
  tempIsdCode: any;

  constructor(
    private fb: FormBuilder,
    private userRegistrationService: UserRegistrationService,
    private datePipe: DatePipe,
    private messageService: ToastMessagesService,
    private router: Router,
    private utilityService: UtilService,
    private titleService: Title,
    private route: ActivatedRoute,
    private locationSelectionService: LocationSelectionService,
    private phoneValidator: PhoneValidatorService
  ) {
    this.route.url.subscribe((url: any) => {
      console.log(`URL IS && ${url}`);
      if (url.includes('registration/register-user')) {
        this.isFirstLogin = true;
      }
    });
    this.createForm();
    this.mobileButtonStyle = this.mobileButtonStyles[0];
    if (this.isMobile) {
      this.showOtpDialogHeader = false;
    }
    this.renameSubmitButton();
    this.setPageTitle();
    this.initCountryISDCodes();
  }

  ngOnInit() {
    this.userRegistrationService.userLoaded.subscribe(
      (data: UserRegistration) => {
        console.log('User registration data:', data); // Log the data to see if it's received
        this.tempIsdCode = data.isdCode;
        this.existingLocationData =
          this.locationSelectionService.getLocationData(data.location);
        this.userRegistrationForm.patchValue(data);
      },
      (error) => console.error('Error loading user registration:', error) // Log potential errors
    );
    this.populateRegistrationFromUserId(this.tempIsdCode);
  }

  populateRegistrationFromUserId(isdCode: any): void {
    this.userRegistrationService.getUserRegistrationByUserId().subscribe(
      (data) => {
        this.isEditMode = true;
        this.userRegistrationForm.patchValue({ termsAccepted: true });
        const initialDate = this.parseDateString(data.dateOfBirth); // Replace with the desired initial date string
        this.userRegistrationForm.patchValue({ dateOfBirth: initialDate });
        console.log(data);
        this.data = data;

        this.mobileVerified = data.user.mobileVerified;
        console.log(`this.mobileVerified ${data.user.mobileVerified}`);
        this.mobileButtonStyle = data.user.mobileVerified
          ? this.mobileButtonStyles[1]
          : this.mobileButtonStyles[0];
        if (data.user.mobileVerified) {
          this.userRegistrationForm.setErrors({});
        }
        this.checkInvalidFields();
        if (data) {
          this.markAllControlsAsDirtyAndTouched(this.userRegistrationForm);
        }
      },
      (error) => {
        console.error('Error in populateRegistrationFromUserId:', error);
      }
    );
  }

  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      //this.userRegistrationForm = data['header'];
      // this.userRegistrationForm = data['subHeader'];
    });
  }
  ngOnDestroy(): void {
    if (this.otpTimerSubscription) {
      this.otpTimerSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {}

  createForm() {
    this.userRegistrationForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      middleName: [''],
      email: ['', [Validators.required, Validators.email]],
      dateOfBirth: ['', Validators.required],
      gender: ['', Validators.required],
      alternateEmail: ['', Validators.email],
      // mobile: ['', Validators.required],

      employeeId: [''],
      // companyName: ['', Validators.required],
      // industry: ['', Validators.required],
      termsAccepted: ['', Validators.required],
      location: [],
      isdCode: ['', Validators.required],
      mobile: [
        '',
        [Validators.required, this.phoneValidator.phoneNumberValidator()],
      ],
    });
    let today = new Date();
    let year = today.getFullYear();
    this.maximumDate = new Date();

    this.maximumDate.setFullYear(year - 18);

    // this.userRegistrationForm.setValidators(this.phoneValidator.phoneNumberValidator());

    //    this.minDate.setFullYear(this.minDate.getFullYear() - 18);
  }

  getAllUserRegistrations(): void {
    this.userRegistrationService
      .getAll()
      .subscribe((data: UserRegistration[]) => {
        this.userRegistrations = data;
      });
  }

  getUserRegistration(id: number): void {
    this.userRegistrationService.get(id).subscribe((data: UserRegistration) => {
      console.log('%%%%%' + data);
    });
  }

  /* populateRegistrationFromUserId(): void {
    this.locationSelectionService.changeLocationDataLoading(true); // Set the flag to true as we start loading the data

    this.userRegistrationService.getUserRegistrationByUserId().subscribe(
      (data: UserRegistration) => {
       
        const initialDate = this.parseDateString(data.dateOfBirth); // Replace with the desired initial date string
        this.userRegistrationForm.patchValue({ dateOfBirth: initialDate });
        console.log(data);
        this.data = data;


        this.mobileVerified = data.user.mobileVerified;
        console.log(`this.mobileVerified ${data.user.mobileVerified}`);
        this.mobileButtonStyle = data.user.mobileVerified
          ? this.mobileButtonStyles[1]
          : this.mobileButtonStyles[0];
        if (data.user.mobileVerified) {
          this.userRegistrationForm.setErrors({});
        }
        this.checkInvalidFields();
        if (data) {
          this.markAllControlsAsDirtyAndTouched(this.userRegistrationForm);
        }
        
      },
      (error: any) => {
        console.log(`Errror ${JSON.stringify(error)}}`);
      },
      () => {
        // This is executed when the observable completes
        console.log(`UserRegistration complete data is ${JSON.stringify(this.data)}`);
        this.existingLocationData =
          this.locationSelectionService.getLocationData(this.data.location);
        this.userRegistrationForm.patchValue(this.data);
        this.locationSelectionService.changeLocationDataLoading(false); // Set the flag to false as we finished loading the data
      }
    );
  }  */

  addUserRegistration(userRegistration: UserRegistration): void {
    this.blockedPanel = true;
    this.userRegistrationService.save(userRegistration,false,null).subscribe(
      (data: UserRegistration) => {
        this.userRegistrations.push(data);
        if (this.isFirstLogin) {
          this.messageService.showSuccessMessage(
            'Saved',
            'Successfully Registered'
          );
        } else {
          this.messageService.showInfoMessage(
            'Updated',
            'Successfully Updated'
          );
        }
        this.blockedPanel = false;
      },
      (error) => {
        this.blockedPanel = false;
        if (error.error && error.error.response) {
          this.messageService.showErrorMessage(
            'Email already exist',
            error.error.response
          );
          // return error.error.response;
        } else {
          // Handle cases where the response property doesn't exist.
          this.messageService.showErrorMessage(
            'Error',
            'Error while registering'
          );
          // return 'Error occurred without a specific message.';
        }
      },
      () => {
        this.blockedPanel = false;
        this.router.navigate(['/registration/register-company']);
      }
    );
  }

  /* updateUserRegistration(id: number, userRegistration: UserRegistration): void {
    this.userRegistrationService.update(id, userRegistration).subscribe((data: UserRegistration) => {
      this.userRegistrations = this.userRegistrations.map((ur) => ur.id === id ? data : ur);
    });
  }
 */
  deleteUserRegistration(id: number): void {
    this.userRegistrationService.delete(id).subscribe(() => {
      this.userRegistrations = this.userRegistrations.filter(
        (ur) => ur.id !== id
      );
    });
  }
  onSubmit() {
    this.checkInvalidFields();

    if (!this.mobileVerified) {
      console.log(`!this.mobileVerified`);
      this.userRegistrationForm.setErrors({ mobileNotVerified: true });
    } else {
      this.userRegistrationForm.setErrors(null);
      console.log(`this.mobileVerified`);
    }
    const controlKeys = Object.keys(this.userRegistrationForm.controls);
    const invalidControlKeys = controlKeys.filter((key) => {
      const control = this.userRegistrationForm.controls[key];
      return control.invalid;
    });
    if (this.userRegistrationForm.valid) {
      const dob = this.userRegistrationForm.get('dateOfBirth')?.value || null;
      if (dob) {
        const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;

        if (dateRegex.test(dob)) {
          // If the date is in the correct format, use DatePipe to transform
          // const formattedDate = this.datePipe.transform(dob, 'dd/MM/yyyy');
          // console.log(formattedDate);
        } else {
          const formattedDate = this.datePipe.transform(dob, 'dd/MM/yyyy');
          this.userRegistrationForm.patchValue({ dateOfBirth: formattedDate });
          console.log(`DOB ${dob} and formattedDate ${formattedDate}`);
          // Handle the error scenario when dob is not in 'dd/MM/yyyy' format
          console.error('Date of Birth is not in the expected format.');
        }
      }
      // const formattedDate = this.formatDateToString(dob);
      // this.userRegistrationForm.patchValue({ dateOfBirth: formattedDate });

      console.log(this.userRegistrationForm.value);

      this.addUserRegistration(this.userRegistrationForm.value);
      // Submit the form data to your server
    }
  }

  checkInvalidFields() {
    for (const controlName in this.userRegistrationForm.controls) {
      const control = this.userRegistrationForm.controls[controlName];
      if (control.invalid) {
        console.log(`Invalid field: ${controlName}`);
      }
    }
  }
  private parseDateString(dateString: string) {
    const [day, month, year] = dateString.split('/');
    return new Date(+year, +month - 1, +day);
  }

  private formatDateToString(date: Date): string {
    return this.datePipe.transform(date, 'dd/MM/yyyy') || '';
  }

  private markAllControlsAsDirtyAndTouched(control: AbstractControl): void {
    if (control instanceof FormGroup) {
      for (const key in control.controls) {
        this.markAllControlsAsDirtyAndTouched(control.controls[key]);
      }
    } else {
      control.markAsDirty();
      control.markAsTouched();
    }
  }

  otpTimer = 0;
  timeLeft = 30;
  otp!: number;
  otpDialogVisible = false;
  otpTimerSubscription!: Subscription;

  startOtpTimer() {
    this.showTimer = true;
    this.otpTimerSubscription = interval(1000).subscribe(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.otpTimerSubscription.unsubscribe();
        this.showTimer = false;
      }
    });
  }

  getOTP() {
    this.otp = 0;
    const isdCode = this.userRegistrationForm.get('isdCode')?.value;
    if (this.userRegistrationForm.get('mobile')?.valid && isdCode) {
      const phoneNumber = this.userRegistrationForm.get('mobile')?.value;

      console.log(`isdCode ${JSON.stringify(isdCode)}`);
      const phone = isdCode.isdCode + phoneNumber;
      console.log(`phone with isdcode is ${JSON.stringify(phone)}`);
      this.userRegistrationService.sendOTP(phone).subscribe(
        (success) => {
          this.messageService.showSuccessMessage('Sent', 'OTP Sent');
          this.otpDialogVisible = true;
          this.startOtpTimer();
        },
        (error: HttpErrorResponse) => {
          this.messageService.showErrorMessage(
            'Error',
            'Please enter valid number '
          );
        }
      ),
        () => {};
    }
  }
  @ViewChild(NgOtpInputComponent, { static: false })
  ngOtpInput!: NgOtpInputComponent;

  validateOTP() {
    this.otp = Number(this.ngOtpInput.currentVal);
    console.log(`validateOTP ${this.otp} ${this.ngOtpInput.currentVal}`);
    if (this.otp) {
      // const phoneNumber = this.userRegistrationForm.get('mobile')?.value;
      const phoneNumber = this.userRegistrationForm.get('mobile')?.value;
      const isdCode = this.userRegistrationForm.get('isdCode')?.value;
      console.log(`isdCode ${JSON.stringify(isdCode)}`);
      const phone = isdCode.isdCode + phoneNumber;
      this.userRegistrationService.validateOTP(phone, this.otp).subscribe(
        (data) => {
          console.log(`validateOTP data ${JSON.stringify(data)}`);
          this.messageService.showSuccessMessage('Valid', 'OTP is Valid');
          this.mobileVerified = true;
          this.mobileButtonStyle = this.mobileButtonStyles[1];
          this.showTimer = false;
          this.otpTimerSubscription.unsubscribe();
          setTimeout(() => {
            this.otpDialogVisible = false;
          }, 1000);
        },
        (error) => {
          this.messageService.showErrorMessage(
            'Error',
            'Invalid or Expired OTP'
          );
        },
        () => {}
      );
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = this.utilityService.isMobile();
    if (this.isMobile) {
      this.showOtpDialogHeader = false;
    }
    console.log(`is mobile ${this.utilityService.isMobile()}`);
  }

  renameSubmitButton() {
    const url = this.router.url; // Get current URL

    if (url.includes('registration/register-user')) {
      this.submitButtonLabel = 'Next';
      this.navigate = '/registration/company';
    } else {
      this.submitButtonLabel = 'Update';
      this.navigate = "'/'";
    }
  }

  locationFormValidators = {
    country: [Validators.required],
    state: [Validators.required],
    city: [Validators.required],
    zipcode: [Validators.required],
  };

  onLocationFormStatus(isLocationFormValid: boolean): void {
    console.log(`onLocationFormStatus ${isLocationFormValid}`);
    if (isLocationFormValid) {
      this.userRegistrationForm.controls['location'].setErrors(null);
    } else {
      this.userRegistrationForm.controls['location'].setErrors({
        invalidLocationForm: true,
      });
    }
  }

  initCountryISDCodes() {
    this.blockedPanel = true;
    this.locationSelectionService.getCountriesNEW().subscribe(
      (data: any) => {
        this.isdCodes = data;
      },
      (error: any) => {
        this.blockedPanel = false;
      },
      () => {
        if (this.tempIsdCode) {
          setTimeout(() => {
            this.userRegistrationForm.patchValue({ isdCode: this.tempIsdCode });
            this.selectedIsdCode = this.tempIsdCode;
          }, 1000);
        }
        this.blockedPanel = false;

      }
    );
    /*  this.userRegistrationService.getAllISDCodes().subscribe(
      (data: any) => {
        this.isdCodes = data;
        // console.log(`ISD Codes are ${JSON.stringify(data,null,2)}`);
      },
      (error: any) => {},
      () => {
        if (this.tempIsdCode) {
          setTimeout(() => {
            this.userRegistrationForm.patchValue({ isdCode: this.tempIsdCode });
            this.selectedIsdCode = this.tempIsdCode;
          }, 1000);
        }
      }
    ); */
  }

  getViewportWidth() {
    const width = window.innerWidth;

    if (width <= 575) {
      // console.log("Width <= 575, setting width to 55vw");
      return '55vw';
    }
    if (width <= 768) {
      // console.log("Width <= 768, setting width to 45vw");
      return '45vw';
    }
    if (width <= 992) {
      // console.log("Width <= 992, setting width to 35vw");
      return '35vw';
    }
    if (width >= 1200) {
      // console.log("Width >= 1200, setting width to 30vw");
      return '30vw';
    }

    // default fallback
    // console.log("Width is not in a recognized range, setting width to 35vw");
    return '35vw';
  }

  getSelectedCountryIsoCode(event: any) {
    console.log(`Selected Country iso is ${JSON.stringify(event.value)}`);
    this.selectedIsdCode = event.value;
  }

  validatePhoneNumber() {
    this.userRegistrationForm.get('mobile')?.markAsTouched();
  }

  isPhoneNumberValid(): boolean | undefined {
    const mobileControl = this.userRegistrationForm.get('mobile');
    return (
      mobileControl?.touched && !mobileControl?.hasError('invalidPhoneNumber')
    );
  }
}
