import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/commons/app-constants';
import { ContainerService } from 'src/app/modules/container/services/container.service';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';
import { SawnTimberViewPhotosComponent } from '../sawn-timber-view-photos/sawn-timber-view-photos.component';
import { SawnTimberViewSummaryComponent } from '../sawn-timber-view-summary/sawn-timber-view-summary.component';
import { SawnTimberViewListComponent } from '../sawn-timber-view-list/sawn-timber-view-list.component';


@Component({
  selector: 'app-sawn-timber-view-main',
  templateUrl: './sawn-timber-view-main.component.html',
  styleUrls: ['./sawn-timber-view-main.component.css'],
  standalone:true,
  imports:[SHARED_MODULES, SawnTimberViewPhotosComponent, SawnTimberViewSummaryComponent, SawnTimberViewListComponent]
})
export class SawnTimberViewMainComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  data: any;
  isPublic: boolean;
  containerNumber: any;
  containerUUID!: string;
  isLoading$ = new BehaviorSubject<boolean>(true);
  
  constructor(
    private encryptedStorageService: EncryptedStorageService,
    private router: Router,
    private containerService: ContainerService,
    private activatedRoute: ActivatedRoute,
    private toastMessageService: ToastMessagesService
  ) {
    this.isPublic = this.router.url.includes('public');
    this.isLoading$.next(true);

    if (!this.isPublic) {
      this.encryptedStorageService
        .getEncryptedDataFromStorage(AppConstants.VIEW)
        .subscribe(
          (data: any) => {
            console.log(
              `Data from Encrypted Storage is ${JSON.stringify(data)}`
            );
            this.data = data;

            this.containerNumber = this.data.containerNumber;
          },
          (error: any) => {},
          () => {
            this.isLoading$.next(false);
          }
        );
    }else{
     this.containerUUID = this.activatedRoute.snapshot.paramMap.get('containerUUID')?? "";
      this.containerService.getContainerNumberFromUUID(this.containerUUID).subscribe(
        (data:any)=>{
          console.log(`Response is ${JSON.stringify(data.containerNumber)}`);
          this.containerNumber = data.containerNumber;
        },
        (error:HttpErrorResponse)=>{
        },
        ()=>{
          this.isLoading$.next(false);
        }
      )
    }
   
  }


  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }
  ngOnDestroy(): void {
    // throw new Error('Method not implemented.');
    // localStorage.removeItem(AppConstants.VIEW);
  }
  ngAfterViewInit(): void {
    //  throw new Error('Method not implemented.');
  }
}
