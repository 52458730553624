import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';

@Injectable({
  providedIn: 'root',
})
export class SawnTimberService {
  constructor(private httpClient: HttpClient) {}

  createSawnTimber(data: any): Observable<any> {
    const url = `${AppConstants.API_URL}api/v1/sawn-timbers`;
    console.log(`createSawnTimber postSawnTimber url is ${url}`);
    console.log(
      `createSawnTimber postSawnTimber data is ${JSON.stringify(data)}`
    );
    return this.httpClient.post<any>(url, data, { responseType: 'json' });
    // return of(null);
  }

  getSummarySawnTimber(containerIdOrUUID: string,mode: string): Observable<any> {
    // const url = `${AppConstants.API_URL}api/v1/sawn-timber-rows/units/${mode}/${containerIdOrUUID}`; //sawn-timber-rows/units/{container-id}
    const url = `${AppConstants.API_URL}public/api/v1/sawn-timber-rows/units/${mode}/${containerIdOrUUID}`; //sawn-timber-rows/units/{container-id}

    //  @GetMapping("/sawn-timber-rows/units/{mode}/{container-id}")
    console.log(`getSummarySawnTimber get url is ${url}`);
    return this.httpClient.get<any>(url);
  }

  updateSawnTimber(data: any, sawnTimberId: number): Observable<any> {
    const url = `${AppConstants.API_URL}api/v1/sawn-timber/${sawnTimberId}`;
    console.log(`updateSawnTimber postSawnTimber url is ${url}`);
    console.table(data.rows);

    return this.httpClient.put<any>(url, data, { responseType: 'json' });
    // return of(null);
  }

  // /container/{containerId}/sawn-timber-id-exists

  isSawnTimberExist(containerId: number): Observable<boolean> {
    const url = `${AppConstants.API_URL}api/v1/container/${containerId}/sawn-timber-id-exists`;
    console.log(`isSawnTimberExist url is ${url}`);
    return this.httpClient.get<boolean>(url);
  }

  // /container/{containerId}/sawn-timber-id

  getSawnTimberId(containerId: number): Observable<number> {
    const url = `${AppConstants.API_URL}api/v1/container/${containerId}/sawn-timber-id`;
    console.log(`isSawnTimberExist url is ${url}`);
    return this.httpClient.get<number>(url);
  }

  getTimbers(sawnTimberId: number): Observable<any> {
    const url = `${AppConstants.API_URL}api/v1/sawn-timber/${sawnTimberId}`;
    console.log(`getTimbers url is ${url}`);
    return this.httpClient.get<any>(url);
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  } //sawn-timber-config

  //"/sawn-timber-summary/{container-id}"

  getSawnTimberSummary(containerId: number): Observable<any> {
    const url = `${AppConstants.API_URL}api/v1/sawn-timber-summary/${containerId}`;
    console.log(`getTimbers Summary url is ${url}`);
    return this.httpClient.get<any>(url);
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  getTimbersFromContainerId(containerId: number): Observable<any> {
    const url = `${AppConstants.API_URL}api/v1/sawn-timber-container-id/${containerId}`;
    console.log(`getTimbersFromContainerId url is ${url}`);
    return this.httpClient.get<any>(url);
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  } //sawn-timber-config

  updateSawnTimberConfig(
    sawnTimberConfigId: number,
    data: any
  ): Observable<any> {
    const url = `${AppConstants.API_URL}api/v1/sawn-timber-config/${sawnTimberConfigId}`;
    console.log(`updateSawnTimberConfig url is ${url}`);
    return this.httpClient.put<any>(url, data, { responseType: 'json' });
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  } // sawn-timber-config

  createSawnTimberConfig(data: any): Observable<any> {
    const url = `${AppConstants.API_URL}api/v1/sawn-timber-config`;
    console.log(`updateSawnTimberConfig url is ${url}`);
    return this.httpClient.post<any>(url, data, { responseType: 'json' });
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  getLazyLoadingVirtualPackinglist(
    containerId: number,
    pageNumber: number,
    pageSize: number
  ): Observable<any> {
    // view packing list lazyloading virtual scroll implemented
    ///sawnTimberRows/{containerId} // /sawn-timber-rows/lazy-load
    const url = `${AppConstants.API_URL}api/v1/sawn-timber-rows/lazy-load/${containerId}?offset=${pageNumber}&limit=${pageSize}`;
    console.log(`getTimbers Virtual Scroll url is ${url}`);
    return this.httpClient.get<any>(url);
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  /// /sawn-timber-rows/lazy-load/{container-uuid}

  getPublicLazyLoadingVirtualPackinglist(
    containerId: number,
    pageNumber: number,
    pageSize: number
  ): Observable<any> {
    // view packing list lazyloading virtual scroll implemented
    ///sawnTimberRows/{containerId} // /sawn-timber-rows/lazy-load
    const url = `${AppConstants.API_URL}public/api/v1/sawn-timber-rows/lazy-load/${containerId}?offset=${pageNumber}&limit=${pageSize}`;
    console.log(`getTimbers Virtual Scroll url is ${url}`);
    return this.httpClient.get<any>(url);
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  getContainerDetail(containerId: number) {
    // sawn-timber-container-detail
    const url = `${AppConstants.API_URL}api/v1/sawn-timber-container-detail/${containerId}`;
    console.log(`getContainerDetail url is ${url}`);
    return this.httpClient.get<any>(url);
  }

  getPublicContainerDetail(containerUuid: string | null) {
    // sawn-timber-container-detail
    const url = `${AppConstants.API_URL}public/api/v1/sawn-timber-container-detail/${containerUuid}`;
    console.log(`getContainerDetail url is ${url}`);
    return this.httpClient.get<any>(url);
  }

  getPublicSawnTimberSummary(containerUuid: any): Observable<any> {
    const url = `${AppConstants.API_URL}public/api/v1/sawn-timber-summary/${containerUuid}`;
    console.log(`getTimbers Summary url is ${url}`);
    return this.httpClient.get<any>(url);
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  getPublicTimbersFromContainerUUID(
    containerUuid: string | null
  ): Observable<any> {
    const url = `${AppConstants.API_URL}public/api/v1/sawn-timber-container-uuid/${containerUuid}`;
    console.log(`getTimbersFromContainerId url is ${url}`);
    return this.httpClient.get<any>(url);
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  getPublicHoppusTimbersFromContainerUUID(
    containerUuid: string | null
  ): Observable<any> {
    const url = `${AppConstants.API_URL}public/api/v1/hoppus-timber-summary-container-id/${containerUuid}`; ///hoppus-timber-summary-container-id
    console.log(`getPublicHoppusTimbersFromContainerUUID url is ${url}`);
    return this.httpClient.get<any>(url);
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  getUUIDFromContainerId(containerId: number):  Observable<any>{
    const url = `${AppConstants.API_URL}api/v1/container-number-uuid-from-id/${containerId}`; ///hoppus-timber-summary-container-id
    console.log(`getUUIDFromContainerId url is ${url}`);
    return this.httpClient.get<any>(url);
  }

  private stepSource = new Subject<number>();
  step$ = this.stepSource.asObservable();

  changeStep(step: number) {
    this.stepSource.next(step);
  }
}
