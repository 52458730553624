import { EventEmitter, HostListener, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AbstractControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(private datePipe: DatePipe) {}

  private tableSizes = [
    {
      name: 'Small',
      class: 'p-datatable-striped p-datatable-gridlines p-datatable-sm',
    },
    { name: 'Normal', class: 'p-datatable-striped p-datatable-gridlines' },
    {
      name: 'Large',
      class: 'p-datatable-striped p-datatable-gridlines p-datatable-lg',
    },
  ];

  tableStyleOptions = {'min-width': '50rem'};//'min-width: 50rem';
  getCurrentDateTime(): Date {
    return new Date();
  }

  getCurrentDate(format: string): string {
    // format 'dd-MMM-yyyy'
    const now = new Date();
    const formattedDate = this.datePipe.transform(now, format);
    return formattedDate!;
  }

  getCurrentTime(): string {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0'); // add leading zero if necessary
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }

  getCurrentDeviceResolution(): { width: number; height: number } {
    return {
      width: screen.width,
      height: screen.height,
    };
  }

  isNight(): boolean {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    return currentHour >= 19 || currentHour <= 6; // consider night between 7pm and 6am
  }

  isMobile(): boolean {
    return window.innerWidth < 768 ? true : false;
  }

  isTab(): boolean {
    return window.innerWidth >= 768 && window.innerWidth < 1024 ? true : false;
  }

  getTableMinWidth(): any {
    if (this.isMobile()) {
      // {'min-width': '50rem'}
      this.tableStyleOptions = {'min-width': '40rem'};
    } else if (this.isTab()) {
      this.tableStyleOptions = {'min-width': '50rem'};
    } else {
      this.tableStyleOptions = {'min-width': '60rem'};
    }
    return this.tableStyleOptions;
  }

  getTableSizes(): Array<{ name: string; class: string }> {
    return this.tableSizes;
  }

  @HostListener('window:resize', ['$event'])
  getTableSize(): string {
    if (window.innerWidth < 768) {
      console.log(`< 768 ${this.tableSizes[0].class}`);
      return this.tableSizes[0].class;
    } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
      console.log(` >= 768 < 992 ${this.tableSizes[1].class}`);
      return this.tableSizes[1].class;
    } else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
      console.log(` >= 992  < 1200 ${this.tableSizes[2].class}`);
      return this.tableSizes[2].class;
    } else if (window.innerWidth >= 1200) {
      console.log(`>= 1200 ${this.tableSizes[2].class}`);
      return this.tableSizes[2].class;
    } else {
      console.log(`else ${this.tableSizes[1].class}`);
      return this.tableSizes[1].class;
    }
  }

  checkInvalidFields(formGroup: FormGroup) {
    for (const controlName in formGroup) {
      const control = formGroup.controls[controlName];
      if (control.invalid) {
        console.log(`Invalid fields: in from ${formGroup} are ${controlName}`);
      }
    }
  }

  markAllControlsAsDirtyAndTouched(control: AbstractControl): void {
    if (control instanceof FormGroup) {
      for (const key in control.controls) {
        this.markAllControlsAsDirtyAndTouched(control.controls[key]);
      }
    } else {
      control.markAsDirty();
      control.markAsTouched();
    }
  }

  public onResize = new EventEmitter<{ width: number, height: number }>();

  @HostListener('window:resize', ['$event'])
  handleResize(event: Event): void {
    this.onResize.emit({ width: window.innerWidth, height: window.innerHeight });
    this.getTableMinWidth();
  }


  private showDialogSource = new BehaviorSubject<boolean>(false);
  private errorCodeSource = new BehaviorSubject<string|null>(null);
  
  errorMessage$ = this.errorCodeSource.asObservable();
  
  showErrorDialog$ = this.showDialogSource.asObservable();
  private showErrorDialogMessage = new BehaviorSubject<boolean>(false);

  toggleDialog(show: boolean,  errorMessage: string) {
    this.showDialogSource.next(show);
    this.errorCodeSource.next(errorMessage);

  }
}
