import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { Page } from 'src/app/commons/models/page';
import { Seller } from '../model/seller';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class SellerService {
  constructor(private http: HttpClient) { }

  getData(): Observable<Seller> {
    return this.http.get<Seller>(AppConstants.API_URL + 'api/v1/supplier');
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  postData(data: Seller): Observable<Seller> {
    return this.http.post<Seller>(
      AppConstants.API_URL + 'api/v1/supplier',
      data,
      { responseType: 'json' }
    );
  }

  getBySellerName(name: string): Observable<any | undefined> {
    const response = this.http.get(
      AppConstants.API_URL + `api/v1/check-supplier-name?name=${name}`,
      httpOptions
    );
    console.log(`checkSellerName Response is ${JSON.stringify(response)}`);
    return response;
  }

  getByGhanaID(ghanaId: string): Observable<any | undefined> {
    const response = this.http.get(
      AppConstants.API_URL + `api/v1/check-ghana-id?ghanaId=${ghanaId}`,
      httpOptions
    );
    console.log(`getByGhanaID Response is ${JSON.stringify(response)}`);
    return response;
  }

  // <Page<Book>
  getListSeller(httpParams?: HttpParams): Observable<Page<Seller>> {
    let url = `${AppConstants.API_URL}api/v1/suppliers/filter?`;
    if (httpParams) {
      url = url + httpParams;
    }
    console.log(`url is ${url}`);
    return this.http.get<Page<Seller>>(url);
  }

  getSellersGlobalFilter(httpParams?: HttpParams): Observable<Page<Seller>> {
    let url = `${AppConstants.API_URL}api/v1/suppliers/search?`;
    if (httpParams) {
      url = url + httpParams;
    }
    console.log(`url is ${url}`);
    return this.http.get<Page<Seller>>(url);
  }

  deleteSellers(id: any, forceDelete: boolean = false): Observable<string> {
    let url = `${AppConstants.API_URL}api/v1/suppliers/${id}?forceDelete=${forceDelete}`;
    console.log(`url is ${url}`);
    return this.http.delete<string>(url);
  }

  getSellerById(id: any): Observable<Seller> {
    const url = AppConstants.API_URL + 'api/v1/seller/' + id;
    console.log(`getSellerById Url is ${url}`);
    return this.http.get<Seller>(url);

    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }
}
