<div class="w-full">
    <form #fileUploadForm="ngForm">

        <p-fileUpload #fileUpload name="files[]" [customUpload]="true" [multiple]="true"
            (uploadHandler)="onUpload($event)" [auto]="false" [showUploadButton]="true" accept="image/*"
            (onClear)="onClear()" (onImageError)="onImage()" (onRemove)="onRemove($event)" (onError)="onError($event)">
            <ng-template pTemplate="content">
                <div>
                    <span class="p-text-center">Drag and Drop files here</span>
                </div>
            </ng-template>
        </p-fileUpload>
    </form>
    <!-- <div *ngIf="files.length > 0" class="w-18rem mt-2"> -->
    <!-- <div class="w-full  m-5">
        <ng-container *ngFor="let file of files">
            <p-progressBar [value]="file.progress" [showValue]="true" *ngIf="file.isNew"></p-progressBar>
        </ng-container>
    </div> -->
    <div class="p-grid">
        <ng-container *ngFor="let file of files">
            <p-progressBar class="p-col-6 m-5" [value]="file.progress" [showValue]="true"
                *ngIf="file.isNew"></p-progressBar>
        </ng-container>
    </div>

    <!--  <div class="flex flex-wrap md:flex-wrap card-container purple-container photodiv">
        <div class="images-item" *ngFor="let image of existingImages">
            <div class=" m-2 imagediv">
                <div (click)="deleteImage($event, image)">
                    <button pButton pRipple class="p-button-rounded p-button-danger p-button-text closebuttondiv">
                        <div class="bg-black opacity-70 rounded-circle">
                            <i class="pi pi-times text-white font-weight-bold p-1"></i>
                        </div>
                    </button>
                </div>
                <img [src]="image.thumbnailUrl" class="thumbnaildiv">

                <p-confirmPopup key="confirm1"></p-confirmPopup>

            </div>
        </div>
    </div> -->

    <!-- <div class="flex flex-wrap md:flex-wrap card-container purple-container photodiv">
        <div class="images-item" *ngFor="let image of existingImages$ | async">
            <div class=" m-2 imagediv">
                <div (click)="deleteImage($event, image)">
                    <button pButton pRipple class="p-button-rounded p-button-danger p-button-text closebuttondiv">
                        <div class="bg-black opacity-70 rounded-circle">
                            <i class="pi pi-times text-white font-weight-bold p-1"></i>
                        </div>
                    </button>
                </div>
                <img [src]="image.thumbnailUrl" class="thumbnaildiv">
    
                <p-confirmPopup key="confirm1"></p-confirmPopup>
            </div>
        </div>
    </div> -->

   <!--  <div class="flex flex-wrap md:flex-wrap card-container purple-container photodiv">
        <div class="images-item" *ngFor="let image of existingImages$ | async">

            {{ image.isLoaded}}
            <div class="m-2 imagediv">
                <div (click)="deleteImage($event, image)">
                    <button pButton pRipple class="p-button-rounded p-button-danger p-button-text closebuttondiv">
                        <div class="bg-black opacity-70 rounded-circle">
                            <i class="pi pi-times text-white font-weight-bold p-1"></i>
                        </div>
                    </button>
                </div>
                <img [src]="image.thumbnailUrl" (error)="onImageError(image)" (load)="onImageLoad(image)"
                    class="thumbnaildiv">
            </div>
            <p-confirmPopup key="confirm1"></p-confirmPopup>
            <ng-template #loading>
                <div class="m-2 imagediv">
                    <p-skeleton width="10rem" height="4rem" borderRadius="16px"></p-skeleton>

                </div>
            </ng-template>
        </div>
    </div> -->

    <div class="flex flex-wrap md:flex-wrap card-container purple-container photodiv">
        <div class="images-item" *ngFor="let image of existingImages$ | async">
            <div class="m-2 imagediv">
                <div (click)="deleteImage($event, image)">
                    <button pButton pRipple class="p-button-rounded p-button-danger p-button-text closebuttondiv">
                        <div class="bg-black opacity-70 rounded-circle">
                            <i class="pi pi-times text-white font-weight-bold p-1"></i>
                        </div>
                    </button>
                </div>
                <img [src]="image.thumbnailUrl" (error)="onImageError(image)" (load)="onImageLoad(image)" [style.display]="imageLoadStatus.get(image.thumbnailUrl) ? 'block' : 'none'" class="thumbnaildiv">
                <p-skeleton width="16rem" height="16rem" borderRadius="16px" *ngIf="!imageLoadStatus.get(image.thumbnailUrl)"></p-skeleton>
                <p-confirmPopup key="confirm1"></p-confirmPopup>
            </div>
        </div>
    </div>
    
<ng-template #noImages>
    <!-- Content to display when existingImages is undefined, null, or has a length of 0 -->
    <p>No have been added.</p>
</ng-template>