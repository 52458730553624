import { Injectable } from '@angular/core';
import { Timber } from '../components/volume-estimate/volume-estimate.component';

@Injectable({
  providedIn: 'root'
})
export class VolumeEstimateService {

  unitConversionService: any = {
    mm: { mtr: 0.001 },
    cm: { mtr: 0.01 },
    in: { mtr: 0.0254 },
    ft: { mtr: 0.3048 },
    mtr: { mtr: 1 },
  };

  constructor() { }

  convertUnit(value: number, unit: string) {
    return value * this.unitConversionService[unit].mtr;
  }

  calculateCbm(width: number, thickness: number, length: number, quantity: number): number {
    const cbm = width * thickness * length * quantity;
    return parseFloat(cbm.toFixed(3));
  }

  calculateCft(cbm: number): number {
    const cft = cbm * 35.315;
    return parseFloat(cft.toFixed(3));
  }

  calculateTotal(price: number, priceUnit: string, cbm: number, cft: number): number | null {
    let total: number | null = null;
    if (price && priceUnit) {
      switch (priceUnit) {
        case 'cbm':
          total = price * cbm;
          break;
        case 'cft':
          total = price * cft;
          break;
        default:
          total = 0;
      }
      total = parseFloat(total.toFixed(2));
    }
    return total;
  }

  calculateRow(row: any): any {
    if (
      row.width &&
      row.thickness &&
      row.length &&
      row.quantity &&
      row.widthUnit &&
      row.thicknessUnit &&
      row.lengthUnit &&
      row.price &&
      row.priceUnit
    ) {
      let widthMtr = this.convertUnit(row.width, row.widthUnit);
      let thicknessMtr = this.convertUnit(row.thickness, row.thicknessUnit);
      let lengthMtr = this.convertUnit(row.length, row.lengthUnit);

      row.cbm = this.calculateCbm(widthMtr, thicknessMtr, lengthMtr, row.quantity);
      row.cft = this.calculateCft(row.cbm);
      row.total = this.calculateTotal(row.price, row.priceUnit, row.cbm, row.cft);
    } else {
      row.cbm = null;
      row.cft = null;
      row.total = null;
    }

    return row;
  }

  calculateAllRows(timbers: Timber[], useCommonUnits: boolean, commonWidthUnit: string, commonThicknesUnit: string, commonLengthUnit: string): void {
    timbers.forEach((row) => {
      if (
        row.width &&
        row.thickness &&
        row.length &&
        row.quantity &&
        row.widthUnit &&
        row.thicknessUnit &&
        row.lengthUnit &&
        row.price &&
        row.priceUnit
      ) {
        let widthMtr;
        let thicknessMtr;
        let lengthMtr;
        if (useCommonUnits) {
          widthMtr = this.convertUnit(row.width, commonWidthUnit);
          thicknessMtr = this.convertUnit(row.thickness, commonThicknesUnit);
          lengthMtr = this.convertUnit(row.length, commonLengthUnit);
          this.saveCommonUnitsToLocalStorage(commonWidthUnit,commonThicknesUnit,commonLengthUnit);
        } else {
          widthMtr = this.convertUnit(row.width, row.widthUnit);
          thicknessMtr = this.convertUnit(row.thickness, row.thicknessUnit);
          lengthMtr = this.convertUnit(row.length, row.lengthUnit);
        }

        row.cbm = this.calculateCbm(widthMtr, thicknessMtr, lengthMtr, row.quantity);
        row.cft = this.calculateCft(row.cbm);
        row.total = this.calculateTotal(row.price, row.priceUnit, row.cbm, row.cft);
      } else {
        row.cbm = null;
        row.cft = null;
        row.total = null;
      }
    });
   
  }

  calculateGrandTotals(timbers: Timber[]): {
    totalQuantity: number;
    totalCbm: string;
    totalCft: string;
    grandTotal: string;
  } {
    let totalQuantity = 0;
    let totalCbm = 0;
    let totalCft = 0;
    let grandTotal = 0;

    timbers.forEach((row) => {
      if (row.quantity) totalQuantity += row.quantity;
      if (row.cbm) totalCbm += row.cbm;
      if (row.cft) totalCft += row.cft;
      if (row.total) grandTotal += row.total;
    });

    let format2Decimal = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2, 
      maximumFractionDigits: 2 
    });

    let format3Decimal = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 3, 
      maximumFractionDigits: 3 
    });

    return {
      totalQuantity: totalQuantity,
      totalCbm: format3Decimal.format(totalCbm),
      totalCft: format3Decimal.format(totalCft),
      grandTotal: format2Decimal.format(grandTotal),
    };
  }

      toFixed(num: number, fixed: number) {
    const re = new RegExp(`^-?\\d+(?:\.\\d{0,${(fixed || -1)}})?`);
    const res = num.toString().match(re);
    if (res) {
      let result = res[0];
      while (result.length - result.indexOf('.') <= fixed) {
        result += '0';
      }
      return result;
    } else {
      throw new Error(`Failed to format the number to ${fixed} decimal places.`);
    }
  }
  private saveCommonUnitsToLocalStorage(
    widthUnit: any,
    thicknessUnit: any,
    lengthUnit: any
  ): void {
    localStorage.setItem(
      'common-units',
      JSON.stringify({
        widthUnit: widthUnit,
        thicknessUnit: thicknessUnit,
        lengthUnit: lengthUnit,
      })
    );
  }

  
}
