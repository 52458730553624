import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, map, of, tap } from 'rxjs';
import { AppConstants } from '../commons/app-constants';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { CurrentUser } from '../interfaces/current-user';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  apiUrl = AppConstants.API_URL;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private router: Router,
  ) {}

  login(credentials: any): Observable<any> {
    return this.http.post(
      AppConstants.AUTH_API + 'signin',
      {
        email: credentials.username,
        password: credentials.password,
      },
      httpOptions
    );
  }

  register(user: any): Observable<any> {
    return this.http.post(
      AppConstants.AUTH_API + 'signup',
      {
        name: user.name,
        email: user.email,
        password: user.password,
        matchingPassword: user.matchingPassword,
        socialProvider: 'LOCAL',
      },
      httpOptions
    );
  }


  isUserLoggedIn(): Observable<boolean> {
    
    const isUserLoggedInUrl = `${AppConstants.API_URL}openforall/api/v1/login/is-user-loggedin`;
    // console.log(`isUserLoggedInUrl is ${isUserLoggedInUrl}`);
    this.callAppComponentInit();
    return this.http.get<boolean>(isUserLoggedInUrl);
  }

  getSecretKey(): Observable<string> {
    return this.http.get<string>(`${AppConstants.API_URL}api/v1/login/secret-key`);
  }

  getUserDetails(): Observable<string> {
    return this.http.get<string>(`${AppConstants.API_URL}api/v1/login/user-details`);
  }

  private invokeInitSource = new Subject<void>();
  invokeInit$ = this.invokeInitSource.asObservable();

  callAppComponentInit() {
   // this.invokeInitSource.next();
  }
}
