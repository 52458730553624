import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionViewService {
  constructor(private httpClient: HttpClient) {}

  getOpted(): Observable<any> {
    const url = AppConstants.API_URL + 'api/v1/subscriptions/opted';
    console.log(`Url for getOpted() ${url} `);
    return this.httpClient.get<any>(url);
  }

  getFeatures(): Observable<any> {
    const url = AppConstants.API_URL + 'api/v1/subscriptions/opted/features';
    console.log(`Url for getFeatures() ${url} `);
    return this.httpClient.get<any>(url);
  }

  getHistory(): Observable<any> {
    const url = AppConstants.API_URL + 'api/v1/subscriptions/opted/history';
    console.log(`Url for getHistory() ${url} `);
    return this.httpClient.get<any>(url);
  }

  downloadInvoice(subscriptionOrderId: number): Observable<any> {
    const url =
      AppConstants.API_URL +
      `public/api/v1/invoice-email/${subscriptionOrderId}`;
    console.log(`Url for downloadInvoice() ${url} `);
    return this.httpClient.get<Blob>(url, { responseType: 'blob' as 'json' });
    // return this.httpClient.get<any>(url,{ responseType: 'blob' });
  }
}
