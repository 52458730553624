import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SubscriptionType } from '../../model/subscription-type';
import { CurrencyPipe } from '@angular/common';
import { SubscriptionService } from '../../services/subscription.service';
import { Subscription } from 'rxjs';
import { SubscriptionPackageSelected } from '../../model/subscription-package-selected';
import { SubscriptionPackagesView } from '../../model/subscription-packages-view';
import { SubscriptionDurationSelected } from '../../model/subscription-duration-selected';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { MessageService } from 'primeng/api';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';

@Component({
  selector: 'app-subscription-package-details',
  templateUrl: './subscription-package-details.component.html',
  styleUrls: ['./subscription-package-details.component.css'],
  providers: [MessageService, ToastMessagesService],
  imports: [SHARED_MODULES],
  standalone: true,
})
export class SubscriptionPackageDetailsComponent implements OnInit, OnDestroy {
  @Input() selectedPackagePricingPlans!: SubscriptionDurationSelected[];
  @Input() selectedPackage!: SubscriptionPackageSelected | null; // assuming the package type is 'any' for this example
  @Input() selectedCurrency: any; // assuming the package type is 'any' for this example
  @Output() paymentCycleSelectedOutput =
    new EventEmitter<SubscriptionDurationSelected>();
  @Output() autoDebitOutput = new EventEmitter<any>();
  autoDebitTooltip =
    'When Auto Debit is enabled, payments for your subscriptions will be automatically deducted from your account on the due date. Ensure you have sufficient balance in your account to avoid any issues. You can turn off Auto Debit at any time.';
  //paymentCycle!: { name: string; code: string; }[];
  autoDebit = false;
  obligationPaymentMessage!: string;
  private subscription!: Subscription;
  selectedPricingPackageView!: SubscriptionDurationSelected;
  // pricePlans!: SubscriptionPackagesView;

  paymentObligation: number = 0;
  mainSelectedPackage: any;
  // selectedPackage: any = null;
  constructor(
    private currencyPipe: CurrencyPipe,
    private subscriptionService: SubscriptionService,
    private showToastMessage: ToastMessagesService,
    private messageService: MessageService
  ) {}
  ngOnInit(): void {
    this.subscription = this.subscriptionService.currentCurrency.subscribe(
      (currency) => {
        this.selectedCurrency = currency;
        console.log(
          `Selected Currency from Behaviour is ${this.selectedCurrency}`
        );
        this.selectedPackage = null;
        this.autoDebit = false;
        this.selectedPackagePricingPlans = [];
      }
    );
  }

  onDurationSelected(event: any) {
    const value = event.value;
    console.log(`onDurationSelected ${JSON.stringify(value)}`);
    //console.log(`onDurationSelected ${value.duration}}`);
    if (value) {
      switch (value.duration) {
        case 'MONTHLY':
          this.obligationPaymentMessage = `Your monthly payment contribution will be ${this.currencyPipe.transform(
            value.offerPrice,
            value.isoCurrency
          )}`;
          break;
        case 'QUARTERLY':
          this.obligationPaymentMessage = `Your quarterly payment contribution will be ${this.currencyPipe.transform(
            value.offerPrice,
            value.isoCurrency
          )}`;
          break;
        case 'HALF_YEARLY':
          this.obligationPaymentMessage = `Your half yearly payment contribution will be ${this.currencyPipe.transform(
            value.offerPrice,
            value.isoCurrency
          )}`;
          break;
        case 'YEARLY':
          this.obligationPaymentMessage = `Your yearly payment contribution will be ${this.currencyPipe.transform(
            value.offerPrice,
            value.isoCurrency
          )}`;
          break;
        default:
          this.obligationPaymentMessage = '';
          break;
      }
      // this.paymentCycleSelectedOutput.next(event.value);
      this.paymentCycleSelectedOutput.emit(event.value);
    } else {
      this.obligationPaymentMessage = '';
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onAutoDebit(event: any) {
     
    this.showToastMessage.showErrorMessage(
      'Unavailable',
      'Auto Debit Feature Temporarily Unavailable'
    );
    setTimeout(() => {
      this.autoDebit = false;
    });
    // console.log(`onAutoDebit is ${JSON.stringify(event)}`);
    this.autoDebitOutput.emit(this.autoDebit);
  }

  transformMessageToFirstLetterCapital(value: string): string {
    if (value && typeof value === 'string') {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
  }

  clearMessage() {
    this.obligationPaymentMessage = '';
  }
}
