import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { PrivacyField} from 'src/app/modules/container/model/container-privacy-field';

@Injectable({
  providedIn: 'root'
})
export class ContainerPrivacyService {



  constructor(private http: HttpClient) { }

  getContainerPrivacyConfigDetails(): Observable<any> {
    const API_URL = `${AppConstants.API_URL}api/v1/container/privacy`;
    console.log(`API URL is ${API_URL}`);
    return this.http.get<PrivacyField[]>(API_URL);
  }

  updateContainerPrivacyConfig(data: any): Observable<any> {
    const API_URL = `${AppConstants.API_URL}api/v1/container/privacy`;
    return this.http.post(API_URL, data);
  }

  testContainerView(): Observable<any> {
    const API_URL = `${AppConstants.API_URL}api/v1/container-view/test`;
    return this.http.get(API_URL, { responseType: 'text' });
  }
}
