import {
  AfterContentInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { ScrollPanel } from 'primeng/scrollpanel';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  debounceTime,
  switchMap,
} from 'rxjs';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { PopulateDropdownService } from 'src/app/services/populate-dropdown.service';
import { Container } from '../../container/model/container';
import { ContainerNumbers } from '../../container/model/container-numbers';
import { ContainerSummary } from '../../container/model/container-summary';
import { ContainerService } from '../../container/services/container.service';
import { Measurement } from '../../measurement/model/measurement';
import { Photos } from '../../measurement/model/photos';
import { Summary } from '../../measurement/model/summary';
import { MeasurementSharedDataService } from '../../measurement/services/measurement-shared-data.service';
import { MeasurementService } from '../../measurement/services/measurement.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';
import { AppConstants } from 'src/app/commons/app-constants';
import { SHARED_MODULES } from '../../shared-imports';
import { ContainerComponent } from '../../container/container/container.component';

const BATCH_DELAY = 100;

const BATCH_SIZE = 50;

@Component({
  selector: 'app-hoppus-measurement',
  templateUrl: './hoppus-measurement.component.html',
  styleUrls: ['./hoppus-measurement.component.css'],
  providers: [ToastMessagesService],
  standalone:true,
  imports:[SHARED_MODULES, ContainerComponent]
})
export class HoppusMeasurementComponent
  implements OnInit, AfterContentInit, OnDestroy
{
  // @Input() container: any; // This is the input property that will receive the data from the parent
  subscription!: Subscription;

  searchContainerNumberSubject: Subject<string> = new Subject<string>();
  @ViewChild('nextButton') nextButton!: ElementRef;
  protected measurementSpeedDialItems!: MenuItem[];
  containerSidebarVisible: boolean = false;
  @ViewChild('ITable') tableBody!: ElementRef;
  @ViewChild('ITableFooter') tableFooter!: ElementRef; // #scrollPanel
  //@ViewChild('scrollPanel') scrollPanel!: ElementRef; // #scrollPanel
  // @ViewChild('scrollPanel') scrollPanel!: ScrollPanel;
  // @ViewChild('scrollContainer') scrollContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('scrollPanel') scrollContainer!: ScrollPanel;
  //@ViewChild('scrollContainer') scrollContainer2!: ElementRef;
  @Input() isAddFromMeasurement!: boolean;
  // private selectedContainer$: BehaviorSubject<Container> = new BehaviorSubject<Container>(null);
  private selectedContainer$: BehaviorSubject<ContainerNumbers | null> =
    new BehaviorSubject<ContainerNumbers | null>(null);

  showScrollTop = false;
  showScrollBottom = false;

  editMode = false;
  editModeContainerNumber = false;
  progress = 0;
  fileName = '';
  formHeader!: string;
  disableTableDiv = true;
  formSubHeader!: string;
  containerNumbers: ContainerNumbers[] = [];
  selectedContainer = new ContainerNumbers();
  selectedContainer1!: any;
  saveOrUpdateResponse!: Measurement[];

  selectedGroup: any[] = [];
  protected breadCrumbitems!: MenuItem[];
  intervalId!: any;
  criteriaMenu!: MenuItem[];
  showCriteriaDialog = false;
  saveInterval = 5;
  copyOptionSelected = 'off';
  saveOptions!: any[];
  copyOptions!: any[];
  saveOption: string = 'off';
  saveOptionBoolean = false;
  copyOptionBoolean = true;
  public lengthSummary: Array<any> = [];
  public girthSummary: Array<any> = [];
  public overallSummary: ContainerSummary = new ContainerSummary();
  home!: MenuItem;
  // public fieldArray: Array<any> = [{ sr: 1, length: 250, girth: 90 }];
  public fieldArray: Measurement[] = []; // for dummy data initialisation only
  /* public fieldArray: Array<any> = [
    { sr: 1, length: 250, girth: 90 },
    { sr: 2, length: 245, girth: 85 },
    { sr: 3, length: 235, girth: 75 },
    { sr: 4, length: 230, girth: 70 },
    { sr: 5, length: 260, girth: 70 },
    { sr: 6, length: 220, girth: 20 },
    { sr: 7, length: 270, girth: 106 },
    { sr: 8, length: 230, girth: 60 },
    { sr: 7, length: 230, girth: 50 },
    { sr: 7, length: 250, girth: 80 },
    { sr: 7, length: 250, girth: 84 },
    { sr: 7, length: 250, girth: 64 },
    { sr: 7, length: 230, girth: 86 },
    { sr: 7, length: 270, girth: 106 },
  ]; */

  // public copyLength: boolean = false;
  private cbmDecimalPoints = 3;
  //public allowance = 2;

  public newAttribute: Measurement = {};
  minLengthConstraint: number = 190;
  minGirthConstraint: number = 30;
  maxLengthConstraint: number = 250;
  maxGirthConstraint: number = 100;
  girthAllowance: number = 2;
  lengthAllowance: number = 0;
  error!: string;

  totalGrossVol: number = 0;
  totalNetVol: number = 0;
  logsCount: number = 0;
  grossCftAvg: number = 0;
  netCftAvg: number = 0;
  minLength: number = 0;
  maxLength: number = 0;
  minGirth: number = 0;
  maxGirth: number = 0;

  @ViewChild('aForm')
  aForm!: ElementRef;

  @ViewChild('newAttributeLength')
  newAttributeLength!: ElementRef;

  @ViewChild('newAttributeGirth')
  newAttributeGirth!: ElementRef;

  @ViewChild('ITable')
  ITableRef!: ElementRef;

  @ViewChild('newAttributeNetCbm')
  newAttributeNetCbm!: ElementRef;

  @ViewChild('newAttributeNetGirth')
  newAttributeNetGirth!: ElementRef;

  @ViewChild('newAttributeGrossCbm')
  newAttributeGrossCbm!: ElementRef;
  intervalDuration: number | undefined;
  dummyArrayHorizontalGrid = ['Helo'];
  summary = new Summary();
  photos = new Photos();
  images!: any[];
  responsiveOptions!: any[];
  // isLoading = false;
  isLoading$ = new BehaviorSubject<boolean>(false);

  showAutoSaveIntervalDialog = false;

  activeIndex: number = 0;

  constructor(
    private measurementService: MeasurementService,
    private containerService: ContainerService,
    private toastMessagesService: ToastMessagesService,
    private route: ActivatedRoute,
    private titleService: Title,
    private populateDropdownService: PopulateDropdownService,
    private confirmationService: ConfirmationService,
    private encryptedStorageService: EncryptedStorageService,
    private ngZone: NgZone
  ) {
    this.setPageTitle();
  }

  ngOnDestroy(): void {
    this.stopAutoSave();
    this.fieldArray = [];
    this.saveOptionBoolean = false;
    this.changeSaveOption();

    if (
      this.scrollContainer &&
      this.scrollContainer.containerViewChild &&
      this.scrollContainer.containerViewChild.nativeElement
    ) {
      const container = this.scrollContainer.containerViewChild.nativeElement;
      container.removeEventListener('scroll', this.onScroll.bind(this));
    }
    this.selectedContainer$.unsubscribe();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  containerNumber!: string;
  results!: string[];

  ngAfterContentInit(): void {
    // this.fieldArray = [];
  }
  ngOnInit(): void {
    console.log(`NGINIT on Hoppus Measurement`);
    console.log(`getContainerFromStorage `);
    this.encryptedStorageService
      .getEncryptedDataFromStorage(AppConstants.CONTAINER)
      .subscribe(
        (data) => {
          console.log('Decrypted data:', JSON.stringify(data));
          //  this.container = data;
          this.selectedContainer = data;
          this.containerNumber = data;
          this.initPopUpMenu();
          // console.log(`OnInit  ${JSON.stringify(this.container)}`);
          this.populateBreadCrumb();
          // this.populateContainers();
          this.home = { icon: 'pi pi-home', routerLink: '/' };
          // this.dummyData(300);

          this.initSteps();
          this.initContainerNumberAutocomplete();
          this.initSpeedDial();

          this.editView();
          console.log(`AfterContentInit  ${JSON.stringify(this.fieldArray)}`);
          console.log(`addFieldValue  length ${this.fieldArray.length}`);

          this.intervalDuration = this.saveInterval * 60000;
          this.startAutoSave();
          this.setStatus();
          this.setCreateCriteriaMenu();
          if (this.scrollContainer?.containerViewChild?.nativeElement) {
            const container =
              this.scrollContainer.containerViewChild.nativeElement;
            container.addEventListener('scroll', this.onScroll.bind(this));
            // ... rest of your code
          }
        },
        (error) => {
          console.error('Failed to decrypt data:', error);
        }
      );
    //  this.getContainerFromStorage();
  }

  /*  private initSteps() {
    this.items = [
      {
        label: 'Step 1',
        command: (event: any) => {
          this.activeIndex = 0;
        },
      },
      {
        label: 'Step 2',
        disabled: (!this.selectedContainer.containerId),
        command: (event: any) => {
          this.activeIndex = 1;
        },
      },
    ];
  } */

  onEnterGirth_OLD(i: number) {
    console.log(`onEnterGirth `);
    this.getActiveElement();
    /*  this.newAttributePrice.nativeElement.focus(); */
    // this.focusTD(i, 2);
  }

  onEnterLength_OLD(i: number) {
    console.log(`onEnterLength `);
    this.getActiveElement(); // write condition here...

    this.newAttributeGirth.nativeElement.focus();
    // this.newAttribute.price.focus();
    // this.ITableRef.nativeElement
    // this.addFieldValue();
    // this.focusTD(i, 1);
  }

  onEnterPrice(i: number) {
    console.log(`onEnterPrice index ${i}`);
    let x = this.getActiveElement();
    console.log(`onEnterPriceonEnterPrice -${this.getActiveElement()}-`);
    console.log('newAttributePrice' === 'newAttributePrice');
    if (x === 'newAttributePrice') {
      console.log(`in if`);
      if (
        this.newAttributeLength.nativeElement.value &&
        this.newAttributeGirth.nativeElement.value
      ) {
        this.newAttributeLength.nativeElement.focus();
        this.addFieldValue();
      }
    } else {
      console.log(`Else part`);
      this.focusTD(i, 1);
    }
  }

  onEnterLength(i: number) {
    console.log(`onEnterLength `);
    let x = this.getActiveElement();
    console.log(`onEnterLengthonEnterLength -${this.getActiveElement()}-`);
    console.log('newAttributeLength' === 'newAttributeLength');
    if (x === 'newAttributeLength') {
      console.log(`in if newAttributeLength`);
      if (this.newAttributeLength.nativeElement.value) {
        console.log(
          `newAttributeLength HAS A VALUE = ${JSON.stringify(
            this.newAttributeLength.nativeElement.value
          )}`
        );
        this.newAttribute.lengthError = false;
        this.newAttributeGirth.nativeElement.focus();
        this.error = '';
        if (
          this.newAttributeLength.nativeElement.value.length.toString() == 2
        ) {
          this.newAttribute.grossLength =
            200 + Number(this.newAttributeLength.nativeElement.value);
        }
      } else {
        this.newAttribute.lengthError = true;
        this.error = 'Enter Length ';
        console.error(`ERROR NO VALUE ${JSON.stringify(this.newAttribute)}`);
      }
      // this.addFieldValue();
    } else {
      console.log(
        `Else part newAttributeLength  ${JSON.stringify(this.fieldArray[i])}`
      );
      console.log(`At ${i} Length is ${this.fieldArray[i].grossLength}}`);

      if (this.fieldArray[i].grossLength) {
        this.fieldArray[i].lengthError = false;
        console.log(
          `newAttributeLength HAS A VALUE = ${JSON.stringify(
            this.fieldArray[i]
          )}`
        );
        // this.newAttributeGirth.nativeElement.focus();
        this.focusTD(i, 3);
        this.error = '';
      } else {
        this.fieldArray[i].lengthError = true;
        this.error = 'Enter Length ';
        console.error(`ERROR NO VALUE ${JSON.stringify(this.fieldArray[i])}`);
      }
    }
  }
  onEnterGirth(i: number) {
    console.log(`onEnterGirth `);
    let x = this.getActiveElement();
    console.log(
      `onEnterGirthonEnterGirthonEnterGirth -${this.getActiveElement()}-`
    );
    console.log('newAttributeGirth' === 'newAttributeGirth');
    if (x === 'newAttributeGirth') {
      console.log(`in if newAttributeGirth`);
      if (this.newAttributeGirth.nativeElement.value) {
        console.log(
          `newAttributeGirth HAS A VALUE = ${JSON.stringify(
            this.newAttributeGirth.nativeElement.value
          )}`
        );
        this.newAttribute.girthError = false;
        this.newAttributeLength.nativeElement.focus();
        this.addFieldValue();
        this.error = '';
      } else {
        this.newAttribute.girthError = true;
        this.error = 'Enter Girth ';
        console.error(`ERROR NO VALUE ${JSON.stringify(this.newAttribute)}`);
      }
    } else {
      console.log(`Else part newAttributeGirth`);
      if (this.fieldArray[i].grossGirth) {
        this.fieldArray[i].girthError = false;
        console.log(
          `newAttributeGirth HAS A VALUE = ${JSON.stringify(
            this.fieldArray[i]
          )}`
        );
        // this.newAttributeGirth.nativeElement.focus();
        this.focusTD(i, 3);
        this.error = '';
        this.setStatus();
      } else {
        this.fieldArray[i].girthError = true;
        this.error = 'Enter Girth ';
        console.error(`ERROR NO VALUE ${JSON.stringify(this.fieldArray[i])}`);
      }
    }
  }

  addFieldValue() {
    if (this.newAttributeLength.nativeElement.value) {
      console.log(
        `newAttributeLength HAS A VALUE = ${JSON.stringify(
          this.newAttributeLength.nativeElement.value
        )}`
      );
      this.newAttribute.lengthError = false;
      this.newAttributeLength.nativeElement.focus();
      this.error = '';
    } else {
      this.newAttribute.lengthError = true;
      this.error = 'Enter Length ';
      console.error(`ERROR NO VALUE ${JSON.stringify(this.newAttribute)}`);
    }
    if (this.newAttribute.grossLength && this.newAttribute.grossGirth) {
      this.newAttribute.sr = this.fieldArray.length + 1;
      console.log(`Enable Auto Copy ${this.copyOptionSelected}`);
      if (this.copyOptionBoolean) {
        this.fieldArray.push(this.newAttribute);
        this.newAttribute = {};
        this.newAttribute.grossLength =
          this.newAttributeLength.nativeElement.value;
        this.newAttributeLength.nativeElement.select();
      } else {
        this.fieldArray.push(this.newAttribute);
        console.log(`Result is ${JSON.stringify(this.newAttribute)} `);
        this.addMeasurement(this.newAttribute);
        this.newAttribute = {};
      }
      /* this.fieldArray.push(this.newAttribute)
      this.newAttribute = {}; */

      this.setStatus();
    }
    console.log(`Result is ${JSON.stringify(this.newAttribute)} `);
  }

  addFieldValueAtIndex(i: any) {
    console.log(`Length ${this.fieldArray.length} clicked on index ${i}`);

    if (this.fieldArray[i].grossLength && this.fieldArray[i].grossGirth) {
      for (var ind = 0; ind < this.fieldArray.length; ind++) {
        console.log(this.fieldArray[ind]);
      }
      this.fieldArray.join();
      this.fieldArray.splice(i + 1, 0, this.newAttribute);
      this.newAttribute = {};
      this.fieldArray.join();
      for (var ind = 0; ind < this.fieldArray.length; ind++) {
        console.log(
          `#### index ${ind} Value ${JSON.stringify(this.fieldArray[ind])}`
        );
      }
      for (var ind = 0; ind < this.fieldArray.length; ind++) {
        this.fieldArray[ind].sr = ind + 1;
        console.log(
          `#### Again index ${ind} Value ${JSON.stringify(
            this.fieldArray[ind]
          )}`
        );
      }
    }
    setTimeout(() => {
      this.focusTD(i + 1, 2);
    }, 50);
  }

  deleteFieldValue(index: number) {
    this.fieldArray.splice(index, 1);
    for (var i = 0; i < this.fieldArray.length; i++) {
      this.fieldArray[i].sr = i + 1;
    }
  }

  setFocus(name: any) {
    const ele = this.aForm.nativeElement[name];
    if (ele) {
      ele.focus();
    }
  }

  test(i: number) {
    console.log(`Test index ${i}`);
    /// this.focusTD(i-1,0);
  }

  printTable() {
    this.saveList();
    /*  for (var ind = 0; ind < this.fieldArray.length; ind++) {
       console.log(`####Again index ${ind} Value ${JSON.stringify(this.fieldArray[ind])}`);
     } */
  }

  focusTD(rowNum: number, cellNum: number) {
    //  this.ITableRef.tBodies[0].rows[rowNum].cells[cellNum].focus()
    this.ITableRef.nativeElement.tBodies[0].rows[rowNum].cells[cellNum]
      .getElementsByTagName('input')[0]
      .focus();
  }
  X: any;
  getActiveElement(): any {
    // setTimeout(() => {
    this.X = document.activeElement;
    console.log(`Active Element is ${this.X.name}`);
    //});
    return this.X.name;
  }

  focusOnNewRow() {
    console.log(`Control Enter Event`);
    this.newAttributeLength.nativeElement.focus();
  }

  focusOut($event: any, fieldName: string) {
    let fieldValue = (event?.target as HTMLInputElement).value;
    if (fieldValue == '') {
      this.error = 'Error';
    } else {
      this.error = '';
    }
  }

  onCtrlArrowDown(i: number, column: number) {
    console.log(`onLengthKeyDown index ${i}`);
    if (i < this.fieldArray.length) {
      i = i + 1;
    }
    this.focusTD(i, column);
  }

  onCntrlArrowUp(i: number, column: number) {
    console.log(`onLengthKeyDown index ${i}`);
    if (i > 0) {
      i = i - 1;
    }
    if (i == -1) {
      i = this.fieldArray.length - 1;
    }
    this.focusTD(i, column);
  }

  onCtrlArrowLeft(i: number, column: number) {
    console.log(`onCtrlArrowLeft column ${column} i=${i}`);
    if (column > 1 && i != -1) {
      column = column - 1;
      this.focusTD(i, column);
    } else if (column == 1 && i == -1) {
      console.log(`@@@@@`);
      column = column + 1;
      this.newAttributeLength.nativeElement.focus();
    } else if (column == 2 && i == -1) {
      console.log(`@@@@@`);
      column = column + 1;
      this.newAttributeLength.nativeElement.focus();
    } else if (column == 3 && i == -1) {
      console.log(`@@@@@`);
      column = column + 1;
      this.newAttributeGirth.nativeElement.focus();
    }
  }

  onCtrlArrowRight(i: number, column: number) {
    console.log(`onCtrlArrowRight column ${column} i=${i}`);
    if (column < 3 && i != -1) {
      column = column + 1;
      this.focusTD(i, column);
    } else if (column == 1 && i == -1) {
      console.log(`@@@@@`);
      column = column + 1;
      this.newAttributeGirth.nativeElement.focus();
    } else if (column == 2 && i == -1) {
      console.log(`@@@@@`);
      column = column + 1;
      /*   this.newAttributePrice.nativeElement.focus(); */
    } else if (column == 3 && i == -1) {
      console.log(`@@@@@`);
      column = column + 1;
      /*  this.newAttributePrice.nativeElement.focus(); */
    }
  }
  getInvalidValue() {
    for (var i = 0; i < this.fieldArray.length; i++) {
      const length = this.fieldArray[i].grossLength!;
      console.log(`Array Length is ${length}`);
      if (length > 250) {
        console.log(`Invalid Lengths is at ${i} and value is ${length}`);
      }
    }
  }

  setStatus() {
    (this.logsCount = 0),
      (this.totalNetVol = 0),
      (this.totalGrossVol = 0),
      (this.netCftAvg = 0),
      (this.grossCftAvg = 0);
    this.totalGrossVol = 0;
    for (var i = 0; i < this.fieldArray.length; i++) {
      const length = this.fieldArray[i].grossLength;
      const grossGirth = this.fieldArray[i].grossGirth;
      const netGirth = this.fieldArray[i].grossGirth! - this.girthAllowance;

      if (
        grossGirth! > this.maxGirthConstraint ||
        grossGirth! < this.minGirthConstraint
      ) {
        //  console.log(`invalid at index ${i} length ${length} girth ${girth}`);
        this.fieldArray[i].status = false;
        this.fieldArray[i].girthError = true;
      } else {
        // console.log(`valid at index ${i} length ${length} girth ${girth}`);
        this.fieldArray[i].status = true;
        this.fieldArray[i].girthError = false;
      }

      if (
        length! > this.maxLengthConstraint ||
        length! < this.minLengthConstraint
      ) {
        // console.log(`invalid at index ${i} length ${length} girth ${girth}`);
        this.fieldArray[i].status = false;
        this.fieldArray[i].lengthError = true;
      } else {
        //console.log(`valid at index ${i} length ${length} girth ${girth}`);
        this.fieldArray[i].status = true;
        this.fieldArray[i].lengthError = false;
      }

      if (grossGirth && length) {
        this.fieldArray[i].netCbm = this.calculateCBM(length, netGirth);
        this.fieldArray[i].netGirth = netGirth;
        this.fieldArray[i].grossCbm = this.calculateCBM(length, grossGirth);
        this.fieldArray[i].girthAllowance = this.girthAllowance;
        this.fieldArray[i].lengthAllowance = this.lengthAllowance;
        this.fieldArray[i].netLength =
          this.fieldArray[i]!.grossLength! - this.lengthAllowance;
        this.fieldArray[i].netCft = this.fieldArray[i]!.netCbm! * 35.315;
        this.fieldArray[i].grossCft = this.fieldArray[i]!.grossCbm! * 35.315;
      }
      // console.warn(`totalGrossVol ${this.totalGrossVol}`);
      //console.warn(`totalGrossVol2 ${this.fieldArray[i].grossCbm}`);
      this.totalGrossVol =
        Number(this.totalGrossVol) + Number(this.fieldArray[i].grossCbm);
      this.totalNetVol =
        Number(this.totalNetVol) + Number(this.fieldArray[i].netCbm);
      this.logsCount = this.logsCount + 1;
      this.totalGrossVol = Number(this.totalGrossVol.toFixed(3));
    }
    this.grossCftAvg = (this.totalGrossVol * 35.315) / this.logsCount;
    this.netCftAvg = (this.totalNetVol * 35.315) / this.logsCount;
    this.getLengthSummary(this.fieldArray);
    this.getGirthSummary(this.fieldArray);
    this.setOverAllSummary(
      this.logsCount,
      this.totalNetVol,
      this.totalGrossVol,
      this.netCftAvg,
      this.grossCftAvg
    );
    this.summary.totalGrossVol = this.totalGrossVol;
    this.summary.totalNetVol = this.totalNetVol;
    this.summary.netCftAvg = this.netCftAvg;
    this.summary.grossCftAvg = this.grossCftAvg;
    this.summary.lengthSummary = this.lengthSummary;
    this.summary.girthSummary = this.girthSummary;
    this.summary.logsCount = this.logsCount;
    // console.log(`overall summary ${JSON.stringify(this.overallSummary)}`)
  }

  calculateCBM(length: number, girth: number): any {
    const cbm = (length * girth * girth) / 16000000;
    return cbm.toFixed(this.cbmDecimalPoints);
  }

  getLengthSummary(arr: any) {
    this.lengthSummary = [];
    this.girthSummary = [];
    var count = 0;
    this.minLength = Math.min.apply(
      Math,
      this.fieldArray.map((o) => o.grossLength!)
    );
    this.maxLength = Math.max.apply(
      Math,
      this.fieldArray.map((o) => o.grossLength!)
    );
    // console.log(`Minimum length is ${this.minLength}`);
    // console.log(`Maximum length is ${this.maxLength}`);
    // console.log(`&&`)
    for (var i = this.minLength; i <= this.maxLength; i += 5) {
      count = 0;
      for (var j = 0; j < this.fieldArray.length; j++) {
        if (i == this.fieldArray[j].grossLength) {
          count = count + 1;
          // console.log(`Length steps are ${i} ${this.fieldArray[j].girth} * ${this.fieldArray[j].length} ${count}`);
        }
        // console.log(`Length steps are ${i} ${this.fieldArray[j].girth} * ${this.fieldArray[j].length} ${count}`);
      }

      this.lengthSummary.push({ length: i, count: count });
      // console.log(`Length steps are ${i} ${count}`);
      // console.log(`Length steps are ${i}`);
    }
    // console.log(JSON.stringify(this.lengthSummary));
  }

  getGirthSummary(arr: any) {
    var count = 0;
    this.minGirth = Math.min.apply(
      Math,
      this.fieldArray.map((o) => o.grossGirth!)
    );
    this.maxGirth = this.roundUpGirth(
      Math.max.apply(
        Math,
        this.fieldArray.map((o) => o.grossGirth!)
      )
    );

    let minGirthRange = Math.floor(this.minGirth / 5) * 5;

    for (var i = minGirthRange; i < this.maxGirth; i += 10) {
      count = 0;
      for (var j = 0; j < this.fieldArray.length; j++) {
        if (
          this.fieldArray[j].grossGirth! >= i &&
          this.fieldArray[j].grossGirth! < i + 10
        ) {
          count = count + 1;
        }
      }
      this.girthSummary.push({ girth: `${i}-${i + 9}`, count: count });
    }

    //    console.log(JSON.stringify(this.girthSummary));
  }

  /* getGirthSummary(arr: any) {
    var count = 0;
    this.minGirth = Math.min.apply(Math, this.fieldArray.map(o => o.grossGirth!));
    // this.maxGirth = Math.max.apply(Math, this.fieldArray.map(o => o.girth));
    this.maxGirth = this.roundUpGirth(Math.max.apply(Math, this.fieldArray.map(o => o.grossGirth!)));
    // console.log(`~~ Max Girth Slab is ~~ ${( this.maxGirth / 100, 100) + 1 * 10}`)


    // for (var i = this.minGirth; i <= this.maxGirth; i += 10) {
    for (var i = this.minGirth; i < this.maxGirth; i += 10) { //parseInt(i / 10, 10) + 1 * 10;  
      count = 0;
      for (var j = 0; j < this.fieldArray.length; j++) {
        if (this.fieldArray[j].grossGirth! >= i && this.fieldArray[j].grossGirth! < i + 10) {
          count = count + 1;
        }
      }
      this.girthSummary.push({ "girth": `${i}-${i + 9}`, "count": count });
    }
    console.log(JSON.stringify(this.girthSummary));
  } */

  private roundUpGirth(girth: number): number {
    const num = 102;
    const mod = num % 100;
    const remainder = 10 - mod;
    let finalNumber = num + remainder;
    // console.log(`%%%%%%%% ${finalNumber}`);
    return finalNumber;
  }

  private setOverAllSummary(
    totalPcs: number,
    netVol: number,
    grossVol: number,
    netCftAvg: number,
    grossCftAvg: number
  ) {
    this.overallSummary.pieces = totalPcs;
    this.overallSummary.netVolume = netVol;
    this.overallSummary.grossVolume = grossVol;
    this.overallSummary.netAverage = netCftAvg;
    this.overallSummary.grossAverage = grossCftAvg;
    this.overallSummary.pieces = totalPcs;
  }

  private dummyData(count: number) {
    // this.fieldArray = [];
    for (var i = 1; i <= count; i++) {
      var girth = this.generateGirth();
      var length = this.generateLength();
      // console.log(`Girth ${girth} Length ${length}`);
      this.fieldArray.push({ sr: i, grossLength: length, grossGirth: girth });
      // this.fieldArray[i].sr = i;
      // this.fieldArray[i].length = length;
      // this.fieldArray[i].girth = girth;
    }
  }

  private generateGirth() {
    let difference = 110 - 30;
    let rand = Math.random();
    rand = Math.floor(rand * difference);
    rand = rand + 30;
    return rand;
  }

  private generateLength() {
    let difference = 250 - 230;
    let rand = Math.random();
    rand = Math.floor(rand * difference);
    rand = rand + 230;
    if (rand % 5 === 0) {
      // console.log(`Girth ^^ ${rand}`);
      length = rand;
    } else {
      this.generateLength();
    }
    return length;
  }

  getCSSClass(value: number) {
    if (value > this.maxGirthConstraint) {
      return 'error';
    } else if (value < this.minGirthConstraint) {
      return 'error';
    } else {
      return 'td-align';
    }
  }

  getAllValues() {
    console.log(`Group Value ${this.selectedGroup}`);
    // console.log(`Group Value ${this. }`);
  }

  addMeasurement(measurement: Measurement) {
    /*  this.measurementService.addMeasurement(measurement).subscribe(
       (response) => {
         console.log(`Response is ${JSON.stringify(response)}`);
       },
       (error) => {
         console.error(`Error is ${JSON.stringify(error)}`);
       },
       () => { },
     ) */
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.ngZone.run(() => {
      if (event.altKey && event.key.toLowerCase() === 's') {
      }
    });
  }
  @HostListener('window:keydown.meta.shift.s', ['$event'])
  handleShortcut(event: KeyboardEvent) {
    console.log('Meta + Shift + S pressed 1 !');
    this.saveList();
  }

  /* @HostListener('document:keydown.meta.shift.s', ['$event'])
  onSave1(event: KeyboardEvent) {
    // Handle the keydown event
    console.log('onKeyDown  onSave1 shortcut pressed!');
 
    // this.saveList();

  }

  @HostListener('document:keydown.control.shift.s', ['$event'])
  onSave(event: KeyboardEvent) {
    // Handle the keydown event
    console.log('onKeyDown  onSave shortcut pressed!');
  } */
  changeSaveOption() {
    console.log(
      `changeSaveOption  ${this.saveOptionBoolean} this.saveInterval ${this.saveInterval}`
    );
    if (this.saveOptionBoolean) {
      this.startAutoSave();
    } else {
      this.stopAutoSave();
    }
  }
  containerObject = new Container();
  saveList() {
    this.containerObject.measurements = this.fieldArray!;
    this.containerObject.containerSummary = this.overallSummary;
    //  console.log(`Final Object is ${JSON.stringify(this.containerObject)}`)
    //console.log(`this.selectedContainer!.containerId ${this.selectedContainer!.containerId}`);

    if (this.saveOrUpdateResponse) {
      // this block checks if last saved and current is saved!
      const targetArray1 = this.saveOrUpdateResponse.map(
        ({ grossGirth, grossLength }) => ({ grossGirth, grossLength })
      );
      console.log(`~~ AEK 1 ~~~ ${JSON.stringify(targetArray1)}`);
      const targetArray2 = this.fieldArray.map(
        ({ grossGirth, grossLength }) => ({ grossGirth, grossLength })
      );
      console.log(`~~ 2 ~~~ ${JSON.stringify(targetArray2)}`);
      if (targetArray1.toString() === targetArray2.toString()) {
        console.log(`No need to save `);
        this.toastMessagesService.showInfoMessage(
          'Uptodate',
          'Data is up to date since last save.'
        );
      } else {
        console.log(`Save Now`);
        this.saveIt();
      }
    } else {
      // this block checks first fresh save or after loading no save is called
      if (
        this.containerObject.measurements.length > 1 &&
        this.selectedContainer!.containerId
      ) {
        this.saveIt();
      } else {
        this.toastMessagesService.showErrorMessage(
          'Insufficient Data',
          'Please fill in all mandatory fields'
        );
      }
    }
  }

  private saveIt() {
    console.log(
      ` saveIt ContainerObject ${JSON.stringify(this.containerObject)}`
    );
    this.measurementService
      .addMeasurements(
        this.containerObject,
        this.selectedContainer!.containerId!
      )
      .subscribe(
        (response) => {
          this.saveOrUpdateResponse = response;
          console.log(`Response is ${JSON.stringify(response)}`);
          // this.toastMessagesService.clearMessage();
          this.toastMessagesService.showSuccessMessage(
            'Success',
            'Saved successfully'
          );
        },
        (error) => {
          console.error(`Error is ${JSON.stringify(error)}`);
          // this.toastMessagesService.clearMessage();
          this.toastMessagesService.showErrorMessage(
            'Error',
            'An error occured while saving'
          );
        },
        () => {}
      );
  }

  startAutoSave() {
    // const intervalMinutes = this.saveInterval;
    console.log(`Start Auto Save Interval at ${this.intervalDuration}`);
    this.intervalId = setInterval(() => {
      if (this.saveOptionBoolean) {
        this.saveList();
      }
    }, this.intervalDuration);
  }

  stopAutoSave() {
    clearInterval(this.intervalId);
    console.log(`stopAutoSave`);
  }

  onChangeAutoSaveDuration() {
    if (this.saveOptionBoolean) {
      console.log(
        `onChangeAutoSaveDuration duration is now ${this.intervalDuration}`
      );
      this.intervalDuration = this.saveInterval * 60000;

      this.stopAutoSave();
      this.startAutoSave();
    } else {
      this.stopAutoSave();
    }
  }
  setCreateCriteriaMenu() {
    this.criteriaMenu = [
      {
        label: 'Set Criteria',
        command: () => {
          this.showCriteriaDialog = true;
        },
      },
    ];
  }
  private populateBreadCrumb() {
    this.breadCrumbitems = [
      { label: 'Measurements', routerLink: '/measurements' },
      { label: 'Add Measurement' },
    ];
  }

  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }

  /* private populateContainers() {
    this.populateDropdownService.getContainerNumbersNotInSummary().subscribe(
      (response) => {
        this.containerNumbers = response;
        // console.log(`populateContainersResponse is ${JSON.stringify(response)}`)
      },
      (error) => {},
      () => {}
    );
  } */

  /* searchContainerNumber(event: any) {
    console.log(`searchContainerNumber ${JSON.stringify(event)}`);
    this.populateDropdownService.getContainerNumbersNotInSummary().subscribe(
      (response) => {
        console.log(`response is ${JSON.stringify(response)}`);
        this.containerNumbers = response;
      },
      (error) => {
        this.toastMessagesService.showErrorMessage(
          'Error',
          'An error occured while fetching data'
        );
      },
      () => {}
    );
  } */

  /* search(event) {
    this.mylookupservice.getResults(event.query).then(data => {
        this.results = data;
    }); 
  }*/
  onContainerAutoCompleteSelect(event: ContainerNumbers) {
    console.log(`onContainerAutoCompleteSelect  ${JSON.stringify(event)}`);
    this.selectedContainer = event;
    this.selectedContainer$.next(this.selectedContainer); // Update the BehaviorSubject

    // event.containerId;
  }

  onFileUpload(event: any) {
    const fileUpload: FileUpload = event.files[0];
    const file: File = fileUpload.files[0];
    this.fileName = fileUpload.name || 'defaultFilename.txt'; // Provide a default value if necessary

    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener('progress', (event: ProgressEvent) => {
      if (event.lengthComputable) {
        this.progress = Math.round((event.loaded / event.total) * 100);
      }
    });

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        console.log('File uploaded successfully');
      }
    };

    xhr.open('POST', fileUpload.url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.send(file);
  }

  editView() {
    // this.isLoading = true;
    this.isLoading$.next(true);

    if (this.selectedContainer.containerId) {
      this.containerService
        .getByContainerId(this.selectedContainer.containerId)
        .subscribe(
          (response) => {
            console.log(`%%%%%% *****  ${JSON.stringify(response)}`);
            this.selectedContainer$.next({
              containerId: response.containerId,
            });
            this.containerNumbers.push({
              containerNumber: response.containerNumber,
              containerId: response.containerId,
            });
            this.selectedContainer.containerId = response.containerId;
            this.selectedContainer.containerNumber = response.containerNumber;

            this.containerNumbers
              .filter((a) => a.containerId == response.containerId)
              .map((element) => element.containerId);

            this.fieldArray = [{}];
            console.log(`^^^^^ ${JSON.stringify(response.measurements)}`);
            const measurements = response.measurements;
            this.processMeasurementsInBatches(
              measurements,
              BATCH_SIZE,
              BATCH_DELAY
            );

            // this.isLoading = false;
            this.isLoading$.next(false);
          },
          (error) => {},
          () => {
            this.deleteFieldValue(0);
            // this.onEnterGirth(0);
            this.setStatus();
          }
        );
    }
  }

  onEditContainerNumber() {
    this.confirmationService.confirm({
      header: 'Are you sure that you want to perform this action?',
      message:
        'Executing this action will permanently erase all present data associated with container number <strong>' +
        this.selectedContainer.containerNumber +
        '</strong>. Once executed, this action is irreversible.',
      acceptButtonStyleClass: 'p-button-danger',
      accept: () => {
        this.editModeContainerNumber = false;
      },
    });
  }

  onTabChange(event: any, inputValue: string | undefined) {
    console.log(
      `Event ${JSON.stringify(event)} and inputValue is ${inputValue}`
    );
    if (!inputValue) {
      event.originalEvent.preventDefault();
    }
  }

  scrollToBottom1() {
    //tableFooter
    const tableFooterHeight = this.tableFooter.nativeElement.scrollHeight;
    const tableBodyHeight = this.tableBody.nativeElement.scrollHeight;
    const scroll = tableFooterHeight + tableBodyHeight;

    console.log(
      `scrollToBottom this.tableBody.nativeElement.scrollTop ${this.tableBody.nativeElement.scrollTop}`
    );
    console.log(
      `scrollToBottom this.tableBody.nativeElement.scrollHeight ${this.tableBody.nativeElement.scrollHeight}`
    );
    //this.tableBody.nativeElement.scrollTop = this.tableBody.nativeElement.scrollHeight;
    setTimeout(() => {
      //this.tableBody.nativeElement.scrollTop = this.tableBody.nativeElement.scrollHeight;
      window.scrollTo({ top: scroll, behavior: 'smooth' });
    }, 0);
  }
  scrollToTop() {
    this.scrollContainer.scrollTop(0);
  }
  scrollToBottom2() {
    this.scrollContainer.containerViewChild.nativeElement.scrollTop =
      this.scrollContainer.containerViewChild.nativeElement.scrollHeight;
    console.log(
      `this.scrollContainer.containerViewChild.nativeElement.scrollTop ${this.scrollContainer.containerViewChild.nativeElement.scrollTop}`
    );
    console.log(
      `this.scrollContainer.containerViewChild.nativeElement.scrollHeight ${this.scrollContainer.containerViewChild.nativeElement.scrollHeight}`
    );

    setTimeout(() => {
      //this.tableBody.nativeElement.scrollTop = this.tableBody.nativeElement.scrollHeight;
      window.scrollTo({
        top: this.scrollContainer.containerViewChild.nativeElement.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  }
  showScrollToTop: boolean = false;
  showScrollToBottom: boolean = false;

  scrollToBottom() {
    console.log(`scrollToBottom`);
    const container = this.scrollContainer.contentViewChild.nativeElement;
    console.log(`Container ${container}`);
    container.scrollTop = container.scrollHeight - container.clientHeight;
    console.log(`Container ${container.scrollHeight - container.clientHeight}`);
  }

  onScroll() {
    console.log(`OnScroll`);
    const container = this.scrollContainer.containerViewChild.nativeElement;
    const atBottom =
      container.scrollHeight - container.scrollTop === container.clientHeight;
    const atTop = container.scrollTop === 0;
    this.showScrollToTop = !atTop;
    this.showScrollToBottom = !atBottom;
  }
  public testEnter($event: any) {
    console.log(`testEnter ${JSON.stringify(event)}`);
    console.log(`Enter Detected `);
  }

  toolbarPopUpMenu!: MenuItem[];
  stepMenu!: MenuItem[];

  private initPopUpMenu() {
    this.toolbarPopUpMenu = [
      {
        label: 'Options',
        items: [
          {
            label: 'Save',
            icon: 'pi pi-save',
            command: () => {
              this.saveList();
            },
          },
          {
            label: 'Copy Length',
            icon: this.copyOptionBoolean ? 'pi pi-check' : 'pi pi-times',
            command: () => {
              this.copyOptionBoolean = !this.copyOptionBoolean;
              console.log(` this.copyOptionBoolean ${this.copyOptionBoolean}`);
              if (
                this.toolbarPopUpMenu &&
                this.toolbarPopUpMenu.length > 0 &&
                this.toolbarPopUpMenu[0].items &&
                this.toolbarPopUpMenu[0].items.length > 1
              ) {
                const copyLengthItem = this.toolbarPopUpMenu[0].items[1];
                if (copyLengthItem) {
                  copyLengthItem.icon = this.copyOptionBoolean
                    ? 'pi pi-times'
                    : 'pi pi-check';
                }
              }
            },
          },
          {
            label: 'Auto Save',
            icon: this.saveOptionBoolean ? 'pi pi-check' : 'pi pi-times',
            command: () => {
              this.saveOptionBoolean = !this.saveOptionBoolean;
              console.log(` this.saveOptionBoolean ${this.saveOptionBoolean}`);
              this.changeSaveOption();
              if (
                this.toolbarPopUpMenu &&
                this.toolbarPopUpMenu.length > 0 &&
                this.toolbarPopUpMenu[0].items &&
                this.toolbarPopUpMenu[0].items.length > 1
              ) {
                console.log(
                  `this.toolbarPopUpMenu[0 ${JSON.stringify(
                    this.toolbarPopUpMenu[0]
                  )}`
                );
                const autoSaveLengthItem = this.toolbarPopUpMenu[0].items[2];
                if (autoSaveLengthItem) {
                  autoSaveLengthItem.icon = this.saveOptionBoolean
                    ? 'pi pi-check'
                    : 'pi pi-times';
                }
              }
              // this.startAutoSave();
            },
          },
          {
            label: 'Auto Save Interval',
            icon: 'pi pi-clock',
            command: () => {
              this.showAutoSaveIntervalDialog = true;
            },
            //routerLink: '/fileupload'
          },
        ],
      },
    ];
  }

  processMeasurementsInBatches(
    measurements: any,
    batchSize: number,
    delay: number
  ) {
    console.log(`$$$$$ {}`, JSON.stringify(measurements));
    let i = 0;

    const processBatch = () => {
      const end = Math.min(i + batchSize, measurements.length);
      for (; i < end; i++) {
        var girth = measurements[i]!.grossGirth! | 0;
        var length = measurements[i]!.grossLength! | 0;
        var netLength = measurements[i]!.netLength! | 0;
        var netGirth = measurements[i]!.netGirth! | 0;
        var netCbm = measurements[i]!.netCbm! | 0;
        var grossCbm = measurements[i]!.grossCbm! | 0;
        var netCft = measurements[i]!.netCft! | 0;
        var grossCft = measurements[i]!.grossCft! | 0;

        this.fieldArray.push({
          sr: i,
          grossLength: length,
          grossGirth: girth,
          netLength: netLength,
          netGirth: netGirth,
          netCbm: netCbm,
          grossCbm: grossCbm,
          netCft: netCft,
          grossCft: grossCft,
        });
      }

      if (i < measurements.length) {
        setTimeout(processBatch, delay);
      } else {
        this.isLoading$.next(false);
        //  this.deleteFieldValue(0);
        this.setStatus();
      }
    };

    processBatch();
  }

  onContainerSubmit(event: Event) {
    // this.populateContainers();
  }

  private initSteps() {
    this.selectedContainer$.subscribe((container) => {
      // Update the items array whenever the container changes
      this.stepMenu = [
        {
          label: 'Container',
          command: (event: any) => {
            this.activeIndex = 0;
          },
        },
        {
          label: 'Measurement',
          disabled: !(container && container.containerId),
          command: (event: any) => {
            this.activeIndex = 1;
          },
        },
      ];
    });
  }

  onNext() {
    if (this.selectedContainer.containerId) {
      this.activeIndex = 1;
    }
  }

  searchContainer(event: any) {
    console.log(`event ${JSON.stringify(event)}`);
    this.searchContainerNumberSubject.next(event.query);
  }

  initContainerNumberAutocomplete() {
    this.searchContainerNumberSubject
      .pipe(
        debounceTime(400),
        switchMap((query) =>
          this.populateDropdownService.getContainerNumbersNotInSummary(query)
        ) //this.populateService.getFilteredCities(query))
      )
      .subscribe(
        (response: any) => {
          console.log(`Response is ${JSON.stringify(response)}`);
          this.containerNumbers = response;
        },
        (error: any) => {
          console.log(`Error is ${JSON.stringify(error)}`);
        }
      );
  }
  initSpeedDial() {
    this.measurementSpeedDialItems = [
      {
        icon: 'pi pi-save',
        command: () => {
          this.saveList();
        },
        tooltipOptions: {
          tooltipLabel: 'Save',
          tooltipPosition: 'left',
        },
      },
      /* {
        icon: 'pi pi-clone',
        command: () => {
          this.copyOptionBoolean = !this.copyOptionBoolean;
          console.log(` this.copyOptionBoolean ${this.copyOptionBoolean}`);
          if (
            this.toolbarPopUpMenu &&
            this.toolbarPopUpMenu.length > 0 &&
            this.toolbarPopUpMenu[0].items &&
            this.toolbarPopUpMenu[0].items.length > 1
          ) {
            const copyLengthItem = this.toolbarPopUpMenu[0].items[1];
            if (copyLengthItem) {
              copyLengthItem.icon = this.copyOptionBoolean
                ? 'pi pi-times'
                : 'pi pi-check';
            }
          }
        },
        tooltipOptions: {
          tooltipLabel: 'Enable Length Auto Copy',
          tooltipPosition: 'left'
        },
      }, */

      {
        icon: this.saveOptionBoolean ? 'pi pi-save' : 'pi pi-power-off', // auto save on off
        command: () => {
          this.saveOptionBoolean = !this.saveOptionBoolean;
          this.changeSaveOption();
          this.initSpeedDial(); // Update menu items when saveOptionBoolean changes
        },
        tooltipOptions: {
          tooltipLabel: this.getSaveOptionTooltip(),
          tooltipPosition: 'left',
        },
      },
      {
        icon: 'pi pi-clock',
        command: () => {
          this.showAutoSaveIntervalDialog = true;
        },
        tooltipOptions: {
          tooltipLabel: 'Configure Auto Save Interval',
          tooltipPosition: 'left',
        },
      },
      {
        icon: this.copyOptionBoolean ? 'pi pi-clone' : 'pi pi-copy', // enable disable length auto copy
        command: () => {
          this.initSpeedDial();
          this.copyOptionBoolean = !this.copyOptionBoolean;
          console.log(` this.copyOptionBoolean ${this.copyOptionBoolean}`);
          if (
            this.toolbarPopUpMenu &&
            this.toolbarPopUpMenu.length > 0 &&
            this.toolbarPopUpMenu[0].items &&
            this.toolbarPopUpMenu[0].items.length > 1
          ) {
            const copyLengthItem = this.toolbarPopUpMenu[0].items[1];
            if (copyLengthItem) {
              copyLengthItem.icon = this.copyOptionBoolean
                ? 'pi pi-times'
                : 'pi pi-check';
            }
          }
        },
        tooltipOptions: {
          tooltipLabel: this.getCopyOptionTooltip(),
          tooltipPosition: 'left',
        },
      },
    ];
  }

  getCopyOptionTooltip(): string {
    return this.copyOptionBoolean
      ? 'Disable Length Auto Copy'
      : 'Enable Length Auto Copy';
  }
  getSaveOptionTooltip(): string {
    return this.saveOptionBoolean ? 'Auto Save is ON' : 'Auto Save is OFF';
  }

  getContainerFromStorage(): any {
    console.log(`getContainerFromStorage `);
    this.encryptedStorageService
      .getEncryptedDataFromStorage(AppConstants.CONTAINER)
      .subscribe(
        (data) => {
          console.log('Decrypted data:', data);
        },
        (error) => {
          console.error('Failed to decrypt data:', error);
        }
      );
  }
}
