import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { Buyer } from '../../buyer/model/buyer';
import { DurationModel } from '../model/duration-model';
import { CurrencyModel } from '../model/currency-model';
import { SubscriptionDataModel } from '../model/subscription-data-model';

@Injectable({
  providedIn: 'root'
})
export class RootService {


  constructor(private http: HttpClient) { }

  getDuration(): Observable<DurationModel> {
    return this.http.get<DurationModel>(AppConstants.API_URL + 'api/v1/util/duration');
  }

  getCurrencies(): Observable<CurrencyModel> {
    return this.http.get<CurrencyModel>(AppConstants.API_URL + 'api/v1/util/currencies');
  }

  getSubscriptionData(id: number): Observable<SubscriptionDataModel> {
    return this.http.get<SubscriptionDataModel>(AppConstants.API_URL + `root/api/v1/master-subscription/${id}`);
  }

  postData(data: SubscriptionDataModel): Observable<SubscriptionDataModel> { 
    return this.http.post<SubscriptionDataModel>(AppConstants.API_URL + 'root/api/v1/master-subscription', data);
  }
 
  deleteSubscription(id: number): Observable<SubscriptionDataModel> {
    return this.http.delete<SubscriptionDataModel>(AppConstants.API_URL + `root/api/v1/master-subscription/${id}`);
  }

  //subscription-package-price-details
  getAllSubscriptions(): Observable<any> {
    return this.http.get<any>(AppConstants.API_URL + `root/api/v1/master-subscriptions`);
  }

  createTrialPackage(): Observable<any> { 
    return this.http.post<any>(AppConstants.API_URL + 'root/api/v1/create-trial-package', {});
  }

}
