<div>
    <ng-template #templateBody>
        <p-card>

            <p-table [value]="dummyArrayHorizontalGrid" styleClass="mt-5">
                <ng-template pTemplate="caption" class="text-center">
                    Container Details
                </ng-template>
                <ng-template pTemplate="header">
                    <!--  <tr>
                <th>Property</th>
                <th>Value</th>
            </tr> -->
                </ng-template>
                <ng-template pTemplate="body">
                    <tr>
                        <td>Container Number</td>
                        <td>{{containerNumber}}</td>
                    </tr>
                    <tr>
                        <td>Product</td>
                        <td>{{productName}}</td>
                    </tr>
                    <tr>
                        <td>Formula</td>
                        <td> {{formula }}</td>
                    </tr>
                    <tr>
                        <td>Seal Number</td>
                        <td> {{ sealNumber }}</td>
                    </tr>
                </ng-template>
            </p-table>

            <hr />
            <p-table [value]="dummyArrayHorizontalGrid" styleClass="mt-2">
                <ng-template pTemplate="caption">
                    Container Summary
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="text-center">G. Vol</th>
                        <th class="text-center">N. Vol</th>
                        <th class="text-center">G. Avg</th>
                        <th class="text-center">N. Avg</th>
                        <th class="text-center">Pieces</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                    <tr>
                        <td class="text-center">{{totalGrossVol | number:'1.3-3'}}</td>
                        <td class="text-center">{{totalNetVol | number:'1.3-3'}}</td>
                        <td class="text-center">{{grossCftAvg | number:'1.2-2' }}</td>
                        <td class="text-center">{{netCftAvg | number:'1.2-2' }}</td>
                        <td class="text-center">{{logsCount}}</td>
                    </tr>
                </ng-template>
            </p-table>

            <hr />
            <p-table [value]="dummyArrayHorizontalGrid" styleClass="mt-5">
                <ng-template pTemplate="caption" class="text-center">
                    Length Overview
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Range</th>
                        <th class="text-center" *ngFor="let field of lengthSummary let i = index">{{field.range}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                    <tr>
                        <td>Count</td>
                        <td class="text-center" *ngFor="let field of lengthSummary let i = index">{{field.count}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>

            <hr />
            <p-table [value]="dummyArrayHorizontalGrid" styleClass="mt-5">
                <ng-template pTemplate="caption">
                    Girth Overview
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Range</th>
                        <th class="text-center" *ngFor="let field of girthSummary let i = index">{{field.range}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                    <tr>
                        <td>Count</td>
                        <td class="text-center" *ngFor="let field of girthSummary let i = index">{{field.count}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-card>
    </ng-template>

    <div *ngIf="isLoading$ | async; else templateBody" class="spinner-container">
        <p-progressSpinner styleClass="w-6rem h-6rem"></p-progressSpinner>
    </div>

</div>