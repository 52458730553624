import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SlabGeneratorService } from '../../services/slab-generator.service';
import { MenuItem } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';

@Component({
  selector: 'app-slab-generator',
  templateUrl: './slab-generator.component.html',
  styleUrls: ['./slab-generator.component.css'],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class SlabGeneratorComponent implements OnInit {
  slabs: { min: number; max: number; rate: number }[] = [];
  baseRateRange: any;
  home!: MenuItem;

  slabForm;
  items!: ({ label: string; routerLink: string; } | { label: string; routerLink?: undefined; })[];
  formHeader: any;
  formSubHeader: any;

  constructor(
    private slabCalculationService: SlabGeneratorService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private titleService: Title,
  ) {
    this.setPageTitle();
    this.slabForm = this.fb.group({
      rate: ['', Validators.required],
      slabCft: ['', Validators.required],
      slabPriceSize: ['', Validators.required],
      slabSize: ['', Validators.required],
      upperSlabs: ['', Validators.required],
      lowerSlabs: ['', Validators.required],
    });
  }

  ngOnInit() {
    const savedForm = localStorage.getItem('slabForm');
    if (savedForm) {
      this.slabForm.setValue(JSON.parse(savedForm));
    } else {
      this.slabForm.setValue({
        rate: '',
        slabCft: '',
        slabPriceSize: '',
        slabSize: '0.25',
        upperSlabs: '1',
        lowerSlabs: '1',
      });
    }
    this.generateSlabs();
    this.populateBreadCrumb();
  }

  generateSlabs() {
    if (this.slabForm.valid) {
      const formValues = this.slabForm.value;
      this.slabs = this.slabCalculationService.generateSlabs(
        Number(formValues.rate),
        Number(formValues.slabCft),
        Number(formValues.slabPriceSize),
        Number(formValues.slabSize),
        Number(formValues.upperSlabs),
        Number(formValues.lowerSlabs)
      );
      this.getBaseRateRange();
      localStorage.setItem('slabForm', JSON.stringify(formValues));
    } else {
      console.log('Form is not valid');
    }
  }

  getBaseRateRange() {
    if (this.slabForm.valid) {
      const formValues = this.slabForm.value;
    this.baseRateRange =   this.slabCalculationService.getBaseRateRange(
        Number(formValues.slabCft),
        Number(formValues.slabSize),
      );
      // console.log(`Range is ${JSON.stringify(this.baseRateRange)}`)
    }
  }

  populateBreadCrumb() {
    this.items = [
      { label: 'Utility', routerLink: '/buyers' },
      { label: 'Slab Calculator' },
    ];
  }

  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }

  clear(){
    localStorage.removeItem('slabForm');
    this.baseRateRange = null;
    this.slabs = [];
    this.slabForm.reset();
  }

  
}
