<!-- <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" acceptLabel="Yes"
    rejectLabel="No"></p-confirmDialog>
<form [formGroup]="billOfLadingForm" (ngSubmit)="onSubmit()">

    <div class="pb-3">
        <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
    </div>

    <p-card [header]="formHeader" [subheader]="formSubHeader">
        <div class="formgrid grid">
            <div class="field col-12 sm:6 md:col-4 lg:col-4 xl:col-4">
                <label for="blNumber">BL Number <i class="text-red-400">*</i></label>
                <input id="blNumber" name="blNumber" type="text" formControlName="blNumber" pInputText maxlength="64"
                    (input)="nameValidation()"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="billOfLadingForm.invalid && (billOfLadingForm.controls?.['blNumber']?.invalid && (billOfLadingForm.controls?.['blNumber']?.dirty || billOfLadingForm.controls?.['blNumber']?.touched))">
                    <div *ngIf="billOfLadingForm.controls?.['blNumber']?.errors?.['required']">BL Number is required.
                    </div>
                    <div *ngIf="billOfLadingForm.controls?.['blNumber']?.errors?.['isBLNumberExists'];">BL Number
                        already exists.
                    </div>
                </div>
            </div>
            <div class="field col-12 sm:6 md:col-6 lg:col-6 xl:col-6">
            </div>

            <div class="field col-12 sm:6 md:col-4 lg:col-4 xl:col-4">
                <label for="contactPerson">Buyer </label>
                <div class="p-inputgroup">
                    <p-dropdown [options]="buyers" formControlName="buyerId" optionLabel="name" [filter]="true"
                        [lazy]="true" styleClass="field col-12 w-full" [virtualScroll]="true"
                        [virtualScrollItemSize]="38" placeholder="Select Buyer" [showClear]="true"></p-dropdown>
                    <p-button icon="pi pi-plus" styleClass="p-button-sm p-button-default ml-1"
                        (click)="buyerSidebarVisible = true" pTooltip="Add new buyer" tooltipPosition="top"
                        [showDelay]="300">
                    </p-button>

                </div>
            </div>
            <div class="field col-12 sm:6 md:col-6 lg:col-6 xl:col-6">
            </div>

            <div class="field col-12 sm:12 md:col-4 lg:col-4 xl:col-4">
                <label for="contactPerson">Shipping Line </label>
                <p-dropdown [options]="shippingLines" formControlName="shippingLineId" optionLabel="name"
                    [filter]="true" styleClass="field col-12 w-full" [virtualScroll]="true" [virtualScrollItemSize]="38"
                    [lazy]="true" placeholder="Select Shipping Line" [showClear]="true"></p-dropdown>
            </div>

            <div class="field col-12 sm:12 md:col-4 lg:col-4 xl:col-4">
                <label for="contactPerson">Vessel Name </label>
                <input id="vesselName" name="vesselName" type="text" formControlName="vesselName" pInputText
                    maxlength="64"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="billOfLadingForm.controls?.['vesselName']?.invalid && (billOfLadingForm.controls?.['vesselName']?.dirty || billOfLadingForm.controls?.['vesselName']?.touched)">
                    <div *ngIf="billOfLadingForm.controls?.['vesselName']?.errors?.['maxlength']">Max Length is 64</div>
                    <div *ngIf="billOfLadingForm.controls?.['vesselName']?.errors?.['minlength']">Min Length is 3</div>
                </div>
            </div>

            <div class="field col-12 sm:12 md:col-4 lg:col-4 xl:col-4">
                <label for="contactPerson">Vessel Code </label>
                <input id="vesselCode" name="vesselCode" type="text" formControlName="vesselCode" pInputText
                    maxlength="32"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="billOfLadingForm.controls?.['vesselCode']?.invalid && (billOfLadingForm.controls?.['vesselCode']?.dirty || billOfLadingForm.controls?.['vesselCode']?.touched)">
                    <div *ngIf="billOfLadingForm.controls?.['vesselCode']?.errors?.['maxlength']">Max Length is 32</div>
                    <div *ngIf="billOfLadingForm.controls?.['vesselCode']?.errors?.['minlength']">Min Length is 3</div>

                </div>
            </div>

            <div class="grid nested-grid">
                <div class="col-8">
                    <div class="grid">
                        <div class="col-6">
                            <div class="field p-2">
                                <label for="portOfLoading">Port of Loading </label>

                                <p-dropdown [options]="seaPortsSuggestion" [(ngModel)]="selectedPortOfLoading"
                                    optionLabel="mainPortName" [showClear]="true" placeholder="Select a Country"
                                    formControlName="portOfLoading" styleClass="field col-12 w-full"
                                    placeholder="Select a Sea  Port" [filter]="true"
                                    filterBy="mainPortName,portCode,countryCode" [virtualScroll]="true"
                                    [virtualScrollItemSize]="38" [showClear]="true" class="w-full"
                                    tooltipPosition="bottom">
                                    <ng-template pTemplate="selectedItem">
                                        <div class="flex align-items-center gap-2" *ngIf="selectedPortOfLoading">

                                            <div>{{ selectedPortOfLoading.mainPortName }}</div>
                                        </div>
                                    </ng-template>
                                    <ng-template let-port pTemplate="item">
                                        <div class="flex align-items-center gap-2">
                                            <div>{{ port.mainPortName }} ({{ port.portCode }}, {{port.countryCode}})
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="field p-2">
                                <label for="contactPerson">Port of Destination </label>
                                 
                                <p-dropdown [options]="seaPortsSuggestion" [(ngModel)]="selectedPortOfDestination"
                                    optionLabel="mainPortName" [showClear]="true" placeholder="Select a Country"
                                    formControlName="portOfDestination" styleClass="field col-12 w-full"
                                    placeholder="Select a Sea  Port" [filter]="true"
                                    filterBy="mainPortName,portCode,countryCode" [virtualScroll]="true"
                                    [virtualScrollItemSize]="38" [showClear]="true" class="w-full"
                                    tooltipPosition="bottom">
                                    <ng-template pTemplate="selectedItem">
                                        <div class="flex align-items-center gap-2" *ngIf="selectedPortOfDestination">

                                            <div>{{ selectedPortOfDestination.mainPortName }}</div>
                                        </div>
                                    </ng-template>
                                    <ng-template let-port pTemplate="item">
                                        <div class="flex align-items-center gap-2">
                                            <div>{{ port.mainPortName }} ({{ port.portCode }}, {{port.countryCode}})
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>

                            </div>
                        </div>
                        <div class="col-6">
                            <div class="field pr-2 pl-2">
                                <label for="etd">ETD <i class="ml-2 text-xs">dd/mm/yyyy</i></label>
                                <p-calendar [showIcon]="true" inputId="icon" formControlName="etd" styleClass="w-full"
                                    dateFormat="dd/mm/yy" (onSelect)="onETDSelect($event)" [touchUI]="false"
                                    [showButtonBar]="true">
                                </p-calendar>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="field p-2">
                                <label for="field p-2">ETA <i class="ml-2 text-xs">dd/mm/yyyy</i></label>
                                <p-calendar [showIcon]="true" inputId="icon" formControlName="eta" styleClass="w-full"
                                    dateFormat="dd/mm/yy" [minDate]="minETADate" [touchUI]="false"
                                    [showButtonBar]="true"></p-calendar>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="field ">
                        <label for="float-input">Notes</label>
                        <textarea id="notes" formControlName="notes" [autoResize]="true" rows="7" cols="30"
                            class="w-full" pInputTextarea maxlength="1000"></textarea>
                    </div>
                </div>
            </div>
            <div class="field col-12 sm:12 md:col-12 lg:col-12 xl:col-12">
                <label for="containerNumbers">Add container(s) to Bill of Lading<i class="text-red-400">*</i></label>
                <p-multiSelect [options]="containerNumbers" formControlName="containers" optionLabel="containerNumber"
                    optionValue="containerId" name="containerNumbers" id="containerNumbers" display="chip"
                    styleClass="w-full" emptyMessage="No data available" emptyFilterMessage="No match found"
                    (onChange)="onChangeMultiselect($event)"></p-multiSelect>
                <div class="text-red-400"
                    *ngIf="billOfLadingForm.invalid && (billOfLadingForm.controls?.['containerNumbers']?.invalid && (billOfLadingForm.controls?.['containerNumbers']?.dirty || billOfLadingForm.controls?.['containerNumbers']?.touched))">
                    <div *ngIf="billOfLadingForm.controls?.['containerNumbers']?.errors?.['required']">Container
                        Number(s) is required.
                    </div>
                </div>
            </div>



        </div>



        <ng-template pTemplate="footer">
            <p-button [label]="addEditButton" [disabled]="!billOfLadingForm.dirty" icon="pi pi-check"
                type="submit"></p-button>
            <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary"
                [style]="{'margin-left': '.5em'}"></p-button>

        </ng-template>
    </p-card>

</form>

<p-sidebar [(visible)]="buyerSidebarVisible" position="right" [style]="{'width': '50vw'}" (onHide)="setPageTitle()">
    <app-buyer *ngIf="buyerSidebarVisible" [showBreadcrumb]="false" [isSubmittedFromSidebar]="true"
        (buyerSubmit)="onBuyerSubmit($event)" titleFromSidebar="Add New Buyer" headerFromSidebar="Create Buyer"
        subHeaderFromSidebar="Input new buyer details"></app-buyer>
</p-sidebar>

<p-dialog header="Port of Loading Mismatch" [(visible)]="portOfLoadingMismatchVisible" [style]="{width: '50vw'}">
    <p class="line-height-2 text-xs text-justify">
        The Port of Loading mentioned in the Bill of Lading does not match with the Port of Loading specified for
        some of the Containers.
    </p>
    <p-table [value]="mismatchContainers" [tableStyle]="{ 'min-width': '20rem' }" styleClass="p-datatable-sm">

        <ng-template pTemplate="header">
            <th>Container Number</th>
            <th>PoL in Container</th>
            <th>PoL in BL</th>
        </ng-template>
        <ng-template pTemplate="body" let-containers>
            <tr>
                <td class="text-center">{{ containers.containerNumber }}</td>
                <td class="text-center">{{ containers.containerPortOfLoading }}</td>
                <td class="text-center">{{ containers.billOfLadingPortOfLoading }}</td>
            </tr>
        </ng-template>
    </p-table>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="continueAnywayContainerMismatch()" label="Continue anyway"
            styleClass="p-button-text p-button-danger"></p-button>
        <p-button icon="pi pi-times" (click)="portOfLoadingMismatchVisible = false" label="Stop and Review"
            styleClass="p-button-text"></p-button>
    </ng-template>

</p-dialog> -->

<p-blockUI [blocked]="blockedDocument">
    <i class="pi pi-spin pi-spinner" style="font-size: 4rem !important"></i>
</p-blockUI>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" acceptLabel="Yes"
    rejectLabel="No"></p-confirmDialog>
<form [formGroup]="billOfLadingForm" (ngSubmit)="onSubmit()">

    <div class="pb-3">
        <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
    </div>

    <p-card [header]="formHeader" [subheader]="formSubHeader">
        <div class="formgrid grid">
            <div class="field col-12 sm:6 md:col-4 lg:col-4 xl:col-4">
                <label for="blNumber">BL Number <i class="text-red-400">*</i></label>
                <input id="blNumber" name="blNumber" type="text" formControlName="blNumber" pInputText maxlength="64"
                    (input)="nameValidation()"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="billOfLadingForm.invalid && (billOfLadingForm.controls?.['blNumber']?.invalid && (billOfLadingForm.controls?.['blNumber']?.dirty || billOfLadingForm.controls?.['blNumber']?.touched))">
                    <div *ngIf="billOfLadingForm.controls?.['blNumber']?.errors?.['required']">BL Number is required.
                    </div>
                    <div *ngIf="billOfLadingForm.controls?.['blNumber']?.errors?.['maxlength']">Max Length is 64</div>
                    <div *ngIf="billOfLadingForm.controls?.['blNumber']?.errors?.['minlength']">Min Length is 5</div>

                    <div *ngIf="billOfLadingForm.controls?.['blNumber']?.errors?.['isBLNumberExists'];">BL Number
                        already exists.
                    </div>
                </div>
            </div>
            <div class="field col-12 sm:6 md:col-6 lg:col-6 xl:col-6">
            </div>

            <div class="field col-12 sm:6 md:col-4 lg:col-4 xl:col-4">
                <label for="contactPerson">Buyer </label>
                <div class="p-inputgroup">
                    <p-dropdown [options]="buyers" formControlName="buyerId" optionLabel="name" [filter]="true"
                        [lazy]="true" styleClass="field col-12 w-full" [virtualScroll]="true"
                        [virtualScrollItemSize]="38" placeholder="Select Buyer" [showClear]="true"></p-dropdown>
                    <!--  <p-button icon="pi pi-plus" styleClass="p-button-sm p-button-default ml-1"
                        (click)="buyerSidebarVisible = true" pTooltip="Add new buyer" tooltipPosition="top"
                        [showDelay]="300">
                    </p-button> -->
                    <p-button icon="pi pi-plus-circle" styleClass="p-button-rounded p-button-secondary p-button-text"
                        (click)="buyerSidebarVisible = true" pTooltip="Add new buyer" tooltipPosition="top"
                        [showDelay]="300"></p-button>


                </div>
            </div>
            <div class="field col-12 sm:6 md:col-6 lg:col-6 xl:col-6">
            </div>

            <div class="field col-12 sm:12 md:col-4 lg:col-4 xl:col-4">
                <label for="contactPerson">Shipping Line </label>
                <p-dropdown [options]="shippingLines" formControlName="shippingLineId" optionLabel="name"
                    [filter]="true" styleClass="field col-12 w-full" [virtualScroll]="true" [virtualScrollItemSize]="38"
                    [lazy]="true" placeholder="Select Shipping Line" [showClear]="true"></p-dropdown>
            </div>

            <div class="field col-12 sm:12 md:col-4 lg:col-4 xl:col-4">
                <label for="contactPerson">Vessel Name </label>
                <input id="vesselName" name="vesselName" type="text" formControlName="vesselName" pInputText
                    maxlength="64"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="billOfLadingForm.controls?.['vesselName']?.invalid && (billOfLadingForm.controls?.['vesselName']?.dirty || billOfLadingForm.controls?.['vesselName']?.touched)">
                    <div *ngIf="billOfLadingForm.controls?.['vesselName']?.errors?.['maxlength']">Max Length is 64</div>
                    <div *ngIf="billOfLadingForm.controls?.['vesselName']?.errors?.['minlength']">Min Length is 3</div>
                </div>
            </div>

            <div class="field col-12 sm:12 md:col-4 lg:col-4 xl:col-4">
                <label for="contactPerson">Vessel Code </label>
                <input id="vesselCode" name="vesselCode" type="text" formControlName="vesselCode" pInputText
                    maxlength="32"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="billOfLadingForm.controls?.['vesselCode']?.invalid && (billOfLadingForm.controls?.['vesselCode']?.dirty || billOfLadingForm.controls?.['vesselCode']?.touched)">
                    <div *ngIf="billOfLadingForm.controls?.['vesselCode']?.errors?.['maxlength']">Max Length is 32</div>
                    <div *ngIf="billOfLadingForm.controls?.['vesselCode']?.errors?.['minlength']">Min Length is 3</div>

                </div>
            </div>


            <div class="grid nested-grid">
                <div class="sm:col-12 md:col-12 lg:col-8 xl:col-8 p-3">
                    <div class="grid">
                        <div class="field xs:col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                            <!-- Port of Loading code here -->
                            <label for="portOfLoading">Port of Loading </label>

                            <p-dropdown [options]="seaPortsSuggestion" [(ngModel)]="selectedPortOfLoading"
                                optionLabel="mainPortName" [showClear]="true" placeholder="Select a Country"
                                formControlName="portOfLoading" styleClass="field col-12 w-full"
                                placeholder="Select a Sea  Port" [filter]="true"
                                filterBy="mainPortName,portCode,countryCode" [virtualScroll]="true"
                                [virtualScrollItemSize]="38" [showClear]="true" class="w-full" tooltipPosition="bottom">
                                <ng-template pTemplate="selectedItem">
                                    <div class="flex align-items-center" *ngIf="selectedPortOfLoading">

                                        <div>{{ selectedPortOfLoading.mainPortName }}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-port pTemplate="item">
                                    <div class="flex align-items-center">
                                        <div>{{ port.mainPortName }} ({{ port.portCode }}, {{port.countryCode}})
                                        </div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <div class="field xs:col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                            <!-- Port of Destination code here -->
                            <label for="portOfDestination">Port of Destination </label>

                            <p-dropdown [options]="seaPortsSuggestion" [(ngModel)]="selectedPortOfDestination"
                                optionLabel="mainPortName" [showClear]="true" placeholder="Select a Country"
                                formControlName="portOfDestination" styleClass="field col-12 w-full"
                                placeholder="Select a Sea  Port" [filter]="true"
                                filterBy="mainPortName,portCode,countryCode" [virtualScroll]="true"
                                [virtualScrollItemSize]="38" [showClear]="true" class="w-full" tooltipPosition="bottom">
                                <ng-template pTemplate="selectedItem">
                                    <div class="field flex align-items-center" *ngIf="selectedPortOfDestination">

                                        <div>{{ selectedPortOfDestination.mainPortName }}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-port pTemplate="item">
                                    <div class="field flex align-items-center">
                                        <div>{{ port.mainPortName }} ({{ port.portCode }}, {{port.countryCode}})
                                        </div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <div class="field xs:col-12 sm:col-12 md:col-6 lg:col-6 md:col-6 sm:col-12">
                            <!-- ETD code here -->
                            <label for="etd">ETD <i class="ml-2 text-xs">dd/mm/yyyy</i></label>
                            <p-calendar [showIcon]="true" inputId="icon" formControlName="etd" styleClass="field w-full"
                                dateFormat="dd/mm/yy" (onSelect)="onETDSelect($event)" [touchUI]="false"
                                [showButtonBar]="true">
                            </p-calendar>
                        </div>
                        <div class="field xs:col-12 sm:col-12 md:col-6 lg:col-6 md:col-6 sm:col-12">
                            <!-- ETA code here -->
                            <label for="field p-2">ETA <i class="ml-2 text-xs">dd/mm/yyyy</i></label>
                            <p-calendar [showIcon]="true" inputId="icon" formControlName="eta" styleClass="field w-full"
                                dateFormat="dd/mm/yy" [minDate]="minETADate" [touchUI]="false"
                                [showButtonBar]="true"></p-calendar>
                        </div>
                    </div>
                </div>
                <div class="field lg:col-4 xl:col-4 md:col-12 sm:col-12 xs:col-12 p-3">
                    <!-- Notes code here -->
                    <label for="float-input">Notes</label>
                    <textarea id="notes" formControlName="notes" [autoResize]="true" rows="7" cols="30" class="w-full"
                        pInputTextarea maxlength="1000"></textarea>
                </div>
            </div>

            <div class="field col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
                <label for="containerNumbers">Add container(s) to Bill of Lading</label>
                <p-multiSelect [options]="containerNumbers" formControlName="containers" optionLabel="containerNumber"
                    optionValue="containerId" name="containerNumbers" id="containerNumbers" display="chip"
                    styleClass="w-full" emptyMessage="No data available" emptyFilterMessage="No match found"
                    (onChange)="onChangeMultiselect($event)"></p-multiSelect>
                <div class="text-red-400"
                    *ngIf="billOfLadingForm.invalid && (billOfLadingForm.controls?.['containerNumbers']?.invalid && (billOfLadingForm.controls?.['containerNumbers']?.dirty || billOfLadingForm.controls?.['containerNumbers']?.touched))">
                    <div *ngIf="billOfLadingForm.controls?.['containerNumbers']?.errors?.['required']">Container
                        Number(s) is required.
                    </div>
                </div>
            </div>

            <!-- <div> -->
            <!-- Privacy Types -->
            <div class="field col-12 sm:12 md:col-6 lg:col-4 xl:col-4">
                <label for="productId">Privacy Type </label>

                <select formControlName="privacy" id="privacy"
                    class="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                    style="appearance: auto">
                    <option value="PRIVATE">Private</option>
                    <option value="PROTECTED">Protected</option>
                    <option value="PUBLIC">Public</option>
                    <option value="SOLD">Sold</option>
                    <option value="CUSTOM">Custom</option>

                </select>
            </div>
            <!-- </div> -->



        </div>



        <ng-template pTemplate="footer">

            <!-- <p-button [label]="addEditButton" [disabled]="!billOfLadingForm.dirty" icon="pi pi-save"
                styleClass="p-button-success w-9rem" type="submit"> </p-button>

            <p-button label="Reset" icon="pi pi-sync" styleClass="p-button-secondary  w-9rem" *ngIf="!editMode"
                [style]="{'margin-left': '.5em'}" (onClick)="onReset()"></p-button>

            <p-button label="Delete" icon="pi pi-trash" styleClass="p-button-danger  w-9rem" *ngIf="editMode"
                [style]="{'margin-left': '.5em'}"
                (onClick)="onDelete(this.billOfLadingEditData.billOfLadingId)"></p-button> -->
            <div class="grid">
                <div class="col-fixed" class="sm:w-12rem md:w-12rem lg:w-12rem xl:w-12rem">
                    <div class="text-center p-3 font-bold">
                        <p-button [label]="addEditButton" icon="pi pi-save" styleClass="p-button-success w-9rem"
                            type="submit"> </p-button>
                    </div>
                </div>
                <div class="col-fixed" class="sm:w-12rem md:w-12rem lg:w-12rem xl:w-12rem">
                    <div class="text-center p-3 font-bold">
                        <p-button label="Reset" icon="pi pi-sync" styleClass="p-button-secondary  w-9rem"
                            *ngIf="!editMode" [style]="{'margin-left': '.5em'}" (onClick)="onReset()"></p-button>

                        <p-button label="Delete" icon="pi pi-trash" styleClass="p-button-danger  w-9rem"
                            *ngIf="editMode" [style]="{'margin-left': '.5em'}"
                            (onClick)="onDelete(this.billOfLadingEditData.billOfLadingId)"></p-button>
                    </div>
                </div>
            </div>

        </ng-template>
    </p-card>

</form>

<p-sidebar [(visible)]="buyerSidebarVisible" position="right" [style]="{'width': '50vw'}" (onHide)="setPageTitle()">
    <app-buyer *ngIf="buyerSidebarVisible" [showBreadcrumb]="false" [isSubmittedFromSidebar]="true"
        (buyerSubmit)="onBuyerSubmit($event)" titleFromSidebar="Add New Buyer" headerFromSidebar="Create Buyer"
        subHeaderFromSidebar="Input new buyer details"></app-buyer>
</p-sidebar>

<p-dialog header="Port of Loading Mismatch" [(visible)]="portOfLoadingMismatchVisible" [style]="{width: '50vw'}">
    <p class="line-height-2 text-xs text-justify">
        The Port of Loading mentioned in the Bill of Lading does not match with the Port of Loading specified for
        some of the Containers.
    </p>
    <p-table [value]="mismatchContainers" [tableStyle]="{ 'min-width': '20rem' }" styleClass="p-datatable-sm">

        <ng-template pTemplate="header">
            <th>Container Number</th>
            <th>PoL in Container</th>
            <th>PoL in BL</th>
        </ng-template>
        <ng-template pTemplate="body" let-containers>
            <tr>
                <td class="text-center">{{ containers.containerNumber }}</td>
                <td class="text-center" class="text-red-600">{{ containers.containerPortOfLoading }}</td>
                <td class="text-center">{{ containers.billOfLadingPortOfLoading }}</td>
            </tr>
        </ng-template>
    </p-table>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="continueAnywayContainerMismatch()" label="Continue anyway"
            styleClass="p-button-text p-button-danger"></p-button>
        <p-button icon="pi pi-times" (click)="portOfLoadingMismatchVisible = false" label="Stop and Review"
            styleClass="p-button-text"></p-button>
    </ng-template>

</p-dialog>

<p-confirmDialog #showPermissionCD key="permissionMismatch" [visible]="showPermissionConflictsDialog" [closable]="false" [acceptVisible]="false" [rejectVisible]="false" 
    [closeOnEscape]="false" [style]="{ width: '60rem' }">

    <ng-template pTemplate="message" let-message>
        <div class="flex flex-column align-items-center text-center w-full gap-3 p-3">
            <i class="pi pi-exclamation-circle text-lg text-warning"></i>
            <p class="text-lg font-bold">
                {{ errorMessage }}
            </p>
            <p class="text-secondary">
                Please ensure container permissions are consistent with the BL settings.
            </p>
            <div class="flex align-items-center gap-2 mt-4">
                <button pButton label="Save Anyway" (click)="continueAnywayPermissionConflict();" class="w-8rem">
                </button>
                <button pButton label="Cancel" (click)="showPermissionCD.reject()" class="p-button-outlined w-8rem ">
                </button>
            </div>
        </div>
    </ng-template>
</p-confirmDialog>