import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, CanDeactivate, Route, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AppConstants } from 'src/app/commons/app-constants';
import { ContainerService } from 'src/app/modules/container/services/container.service';
import { HoppusMeasurementComponent } from 'src/app/modules/hoppus-measurement/hoppus-measurement/hoppus-measurement.component';
import { AddHoppusMeasurementComponent } from 'src/app/modules/hoppus/add-hoppus-measurement/add-hoppus-measurement.component';
import { MeasurementCanDeactivateGuardServiceService } from 'src/app/modules/hoppus/services/measurement-can-deactivate-guard-service.service';
import { Formula } from 'src/app/modules/product/product/product.component';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';
import { SawnTimberPhotosComponent } from '../sawn-timber-photos/sawn-timber-photos.component';
import { SawnTimberViewSummaryComponent } from '../sawn-timber-view/sawn-timber-view-summary/sawn-timber-view-summary.component';
import { ViewHoppusSummaryComponent } from 'src/app/modules/hoppus/view-hoppus-summary/view-hoppus-summary.component';
import { SawnTimberRowComponent } from '../sawn-timber-row/sawn-timber-row.component';

@Component({
  selector: 'app-sawn-timber-measurement',
  templateUrl: './sawn-timber-measurement.component.html',
  styleUrls: ['./sawn-timber-measurement.component.css'],
  standalone:true,
  imports:[SHARED_MODULES, AddHoppusMeasurementComponent, SawnTimberPhotosComponent, SawnTimberViewSummaryComponent, ViewHoppusSummaryComponent,
    SawnTimberRowComponent
  ]
})
export class SawnTimberMeasurementComponent
  implements CanDeactivate<SawnTimberMeasurementComponent>
{
  containerNumber: any;
  hoppusSummary: any; // type should be whatever type you expect hoppusSummary to be
  formulaType = Formula;
  constructor(
    private containerService: ContainerService,
    private encryptedStorageService: EncryptedStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private measurementDeactivateService: MeasurementCanDeactivateGuardServiceService,
    private confirmationService: ConfirmationService
  ) {
    this.getFromStorage();
    /*  this.containerService.currentContainerNumber.subscribe(
      (containerNumber) => (this.containerNumber = containerNumber)
    ); */
    this.setPageTitle();
  }

  getFromStorage() {
    this.encryptedStorageService
      .getEncryptedDataFromStorage(AppConstants.CONTAINER)
      .subscribe(
        (data: any) => {
          console.log(`Data response is ${JSON.stringify(data)}`);
          if (data && data.containerId) {
            this.containerNumber = data;
          } else {
            this.router.navigate(['timber/container']);
          }
        },
        (error: any) => {
          console.log(`Data error is ${JSON.stringify(error)}`);
          this.router.navigate(['timber/container']);
        },
        () => {}
      );
  }

  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
    });
  }

  

  /* canDeactivate(): boolean | Observable<boolean> {
    if (this.measurementDeactivateService.tableForm.dirty) {
      return window.confirm('Data is not saved. Do you really want to leave?');
    } 
    return true;
  } */

  canDeactivate(): Observable<boolean> | boolean {
    const subject = new Subject<boolean>();

    if (this.measurementDeactivateService.tableForm.dirty) {
      this.confirmationService.confirm({
        message: 'Do you want to exit without saving your changes?',
        header: 'Unsaved Changes Detected',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmNavigation',
        accept: () => {
          subject.next(true);
          subject.complete();
        },
        reject: () => {
          subject.next(false);
          subject.complete();
        },
      });
      return subject.asObservable();
    }
    return true;
  }
}
