<div class="full-width">
<p-tabView>
    <div class="mb-3">
        <!--  <span class="font-bold"> Container Number:</span>  
        <span class="font-bold"> {{ containerNumber }} </span> -->
        <div class="pt-5">
            <span class="font-bold"> Container Number:</span>
            <span *ngIf="!(isLoading$ | async); else loadingBlock" class="font-bold"> {{ containerNumber }} </span>
            <ng-template #loadingBlock>
                <i class="pi pi-spin pi-spinner ml-2" style="font-size: 2rem"></i>
            </ng-template>
        </div>
    </div>
    <p-tabPanel header="Photos">
        <!-- <app-bl-photos [containerId]="INPUT_CONTAINER_ID"></app-bl-photos> -->
        <app-sawn-timber-view-photos [data]="data"></app-sawn-timber-view-photos>
    </p-tabPanel>


    <p-tabPanel header="Summary">
        <ng-template pTemplate="content">
            <!-- <app-bl-summary [containerId]="INPUT_CONTAINER_ID"></app-bl-summary> -->
            <app-sawn-timber-view-summary [data]="data"></app-sawn-timber-view-summary>
        </ng-template>
    </p-tabPanel>


    <p-tabPanel header="Packing List">
        <ng-template pTemplate="content">
            <!-- <app-bl-packing-list [containerId]="INPUT_CONTAINER_ID"></app-bl-packing-list> -->
            <app-sawn-timber-view-list [data]="data"></app-sawn-timber-view-list>
        </ng-template>
    </p-tabPanel>  


</p-tabView>
</div>

