import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription, fromEvent, debounceTime, filter, Observable } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';
import { UtilService } from 'src/app/services/util.service';
import { Container } from '../../container/model/container';
import { ContainerService } from '../../container/services/container.service';
import { Measurement } from '../../measurement/model/measurement';
import { SawnTimberService } from '../../sawn-timber/services/sawn-timber.service';
import { VIEW_MODE } from '../bill-of-lading-main/bill-of-lading-main.component';
import { Formula } from '../../product/product/product.component';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-bl-volumetric-packing-list',
  templateUrl: './bl-volumetric-packing-list.component.html',
  styleUrls: ['./bl-volumetric-packing-list.component.css'],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class BlVolumetricPackingListComponent  implements OnInit, OnDestroy {
  @Input() data: any;
  isLoading = false; // <-- declare your isLoading variable here
  isLoading1 = new BehaviorSubject<boolean>(false);
  @Input() viewMode!: VIEW_MODE;
  @Input() formula!: Formula;
  // define loadData property which can hold a function reference
  loadData!: () => void;
  VIEW_MODE = VIEW_MODE;
  dummyArrayHorizontalGrid = ['Helo'];
  container!: Container;
  measurements: Measurement[] = [];
  //data: any[] = [];

  totalRecords: number = 0; // total number of records in the dataset
  limit: number = 50; // number of records to fetch per request
  offset: number = 0; // current offset in the dataset
  scrollSubscription: Subscription | undefined; // subscription to scroll event
  @Input() containerId!: any;
  @Input() containerUUID!: any;

  tableStyle!: any;
  isMobile!: boolean;
  cols!: { field: string; header: string }[];

  constructor(
    private httpClient: HttpClient,
    private containerService: ContainerService,
    private router: Router,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private encryptedStorageService: EncryptedStorageService,
    private sawnTimberService: SawnTimberService,

  ) {
    console.log(`SawnTimberViewListComponent `);
  }

  initColumns() {
    this.cols = [
      { field: 'length', header: 'Length' },
      { field: 'width', header: 'Width' },
      { field: 'height', header: 'Height' },
      { field: 'pieces', header: 'Pieces' },
      { field: 'cbm', header: 'Cbm' },
      { field: 'cft', header: 'Cft' },
    ];
  }

  ngOnInit(): void {
    this.isLoading = true; // <-- set loading to true before making requests

    this.initColumns();
    this.loadData = this.loadPublicData.bind(this);
      this.loadData();
      this.subscribeToScrollEvent(this.loadData);
    /* if (this.viewMode = VIEW_MODE.public) {
      this.loadData = this.loadPublicData.bind(this);
      this.loadData();
      this.subscribeToScrollEvent(this.loadData);
    } else {
      //this.encryptedStorageService
       // .getEncryptedDataFromStorage(AppConstants.VIEW)
       // .subscribe(
          //(response: any) => {
           // this.containerId = response.containerId;
            this.loadData = this.loadPrivateData.bind(this, this.containerId);
            this.loadData();
            this.subscribeToScrollEvent(this.loadData);
         // },
         // (error: any) => {
         //   this.isLoading = false; // <-- set loading to false if there's an error
         // },
         // () => {
          //  this.isLoading = false; // <-- set loading to false if there's an error
//}
       // );
    } */
    this.tableStyle = this.utilService.getTableSize();
    this.isLoading = false; // <-- set loading to false once the entire init process is completed
  }

  subscribeToScrollEvent(callback: () => void) {
    this.scrollSubscription = fromEvent(window, 'scroll')
      .pipe(
        debounceTime(300),
        filter(
          () =>
            !this.isLoading &&
            this.offset < this.totalRecords &&
            this.isScrolledToBottom()
        )
      )
      .subscribe(() => {
        this.offset += this.limit;
        callback();
      });
  }

  loadInitialData() {
    this.isLoading = true; // <-- set loading to true before making requests
    if (this.loadData) {
      this.loadData();
    }
    this.isLoading = false; // <-- set loading to false once data is loaded
  }

  createScrollSubscription() {
    this.scrollSubscription = fromEvent(window, 'scroll')
      .pipe(
        debounceTime(300),
        filter(
          () =>
            !this.isLoading &&
            this.offset < this.totalRecords &&
            this.isScrolledToBottom()
        )
      )
      .subscribe(() => {
        this.offset += this.limit;
        if (this.loadData) {
          this.loadData();
        }
      });
  }

  loadPrivateData(containerId: number) {
    this.loadDataFromService(
      this.sawnTimberService.getLazyLoadingVirtualPackinglist(
        containerId,
        this.offset,
        this.limit
      )
    );
  }

  loadPublicData() {
    console.log(`Load Publicdata ${ this.containerUUID}`);
    this.loadDataFromService(
      this.sawnTimberService.getPublicLazyLoadingVirtualPackinglist(
        this.containerUUID,
        this.offset,
        this.limit
      )
    );
  }

  loadDataFromService(serviceCall$: Observable<any>) {
    this.isLoading = true;  // <-- set loading to true before making requests
    this.isLoading1.next(true); // use next to change the value

    console.log(`isLoading to True; value is ${this.isLoading}`);
    setTimeout(() => {
      serviceCall$.subscribe(
        (response) => {
          this.measurements = this.measurements.concat(response.data);
          this.totalRecords = response.totalRecords;
          this.isLoading = false;
          // console.log(`Set isFashion to False`);
          this.isLoading1.next(false); // use next to change the value

          console.log(`isLoading to False; value is ${this.isLoading}`);
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.isLoading1.next(false); // use next to change the value
        }
      );
    }, 200);
  }
  dummyRows = Array(10).fill(0);

  isScrolledToBottom(): boolean {
    const windowHeight =
      'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const documentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    return windowBottom >= documentHeight;
  }

  ngOnDestroy(): void {}
}
