import { Injectable } from '@angular/core';
import { Client, IFrame, Message } from '@stomp/stompjs';
import SockJS from 'sockjs-client';  // Correctly import SockJS
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthService } from './auth.service';
import { CommonFields } from 'src/app/interfaces/tally-sheet/common-fields';
import { TallySheet } from 'src/app/interfaces/tally-sheet/tally-sheet';
import { TallySheetSummaryResponse } from 'src/app/interfaces/tally-sheet/tally-sheet-summary-response';
import { environment } from 'src/environments/environment';
import { AppConstants } from 'src/app/commons/app-constants';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private client!: Client;
  public ackSubject: Subject<any> = new Subject<any>();
  private currentRecordId = new BehaviorSubject<string | null>(null);


  public recordsSubject: Subject<any> = new Subject<any>();
  public allRecordsSubject: Subject<any> = new Subject<any>();
  public summarySubject: Subject<any> = new Subject<any>();
  public tallySheetSubject: Subject<any> = new Subject<any>();

  public errorSubject: Subject<string> = new Subject<string>(); // Subject for errors
  private retryAttempts: number = 0;
  private maxRetries: number = 2;
  /* private httpProtocol = 'http://'; //'https://';
   private port = ':8080'; //':8080' ':80' ''
 
   private ipaddress = '192.168.1.71' //'192.168.1.71' //'192.168.1.3' // 'localhost' // '192.168.249.152' // 'riksb.tunn.dev'
   private baseUrl = `${this.httpProtocol}${this.ipaddress}${this.port}/test/`;
   private webSocketUrl = `${this.httpProtocol}${this.ipaddress}${this.port}/ws`*/

  private baseUrl = `${AppConstants.API_URL}test/`;
  //private webSocketUrl = `${environment.httpProtocol}${environment.ipaddress}${environment.port}/ws`;


  private connectionSubject: Subject<void> = new Subject<void>();

  /*  constructor() {
     console.log('WebSocketService constructor called');
     this.client = new Client({
       webSocketFactory: () => {
         console.log('Creating SockJS instance');
         return new SockJS(this.webSocketUrl);
       },

       reconnectDelay: 5000, // Reconnect after 5 seconds if connection is lost

       connectHeaders: {}, // Ensure no credentials are sent
     });

     this.client.onConnect = (frame: IFrame) => this.onConnect(frame);
     this.client.onStompError = (frame: IFrame) => this.onError(frame.headers['message']);

     console.log('Activating STOMP client');
     this.client.activate();
   } */

  constructor(private http: HttpClient,
    private confirmationService: ConfirmationService, private messageService: MessageService, private authService: AuthService
  ) {
    // this.initializeClient();
  }

/*  private initializeClient() {
    this.client = new Client({
      webSocketFactory: () => {
        console.log('Creating SockJS instance');
        return new SockJS(this.webSocketUrl);
      },
      reconnectDelay: 5000, // Reconnect after 5 seconds if connection is lost

      connectHeaders: {}, // Ensure no credentials are sent

      onConnect: (frame: IFrame) => this.onConnect(frame),
      onDisconnect(frame) {
        alert(`disconnected`)
      },
      onStompError: (frame: IFrame) => this.onError(frame.headers['message']),
      onWebSocketClose: () => this.handleConnectionFailure(),
    });

    console.log('Activating STOMP client');
    this.client.activate();
  }*/

  private handleConnectionFailure(): void {
    this.retryAttempts++;
    console.error(`WebSocket connection failed. Attempt ${this.retryAttempts} of ${this.maxRetries}`);

    if (this.retryAttempts < this.maxRetries) {
      console.log('Retrying connection...');
      this.client.deactivate(); // Clean up before retrying
      setTimeout(() => this.client.activate(), 5000); // Retry after 5 seconds
    } else {
      console.error('Max retry attempts reached. Connection failed.');
      this.errorSubject.next('Unable to connect to WebSocket server after multiple attempts.');
      //alert('Connection failed after 5 attempts. Please check your network and try again.');
      this.client.deactivate(); // Deactivate the client to stop further attempts
    }
  }

  private onConnect(frame: IFrame): void {
    console.log('Connected to WebSocket', frame);
    this.retryAttempts = 0; // Reset retry attempts on successful connection
    this.client.subscribe('/topic/ack', this.onAckReceived.bind(this));
    this.client.subscribe('/topic/records', this.onRecordsReceived.bind(this));
    this.client.subscribe('/topic/summary', this.onSummaryReceived.bind(this));
    this.client.subscribe('/topic/all-records', this.onAllRecordsReceived.bind(this));
    this.client.subscribe('/topic/tallysheet', this.onTallySheetReceived.bind(this));
    this.connectionSubject.next();
  }
  private onError(error: string): void {
    this.errorSubject.next(error);
    console.error('WebSocket error:', error);
  }

  private onAckReceived(message: Message): void {
    console.log('Acknowledgment received:', message.body);
    this.ackSubject.next(message.body);
  }

  private onRecordsReceived(message: Message): void {

    const records = JSON.parse(message.body);
    console.log('Parsed records:', records);
    this.recordsSubject.next(records);
  }

  private onAllRecordsReceived(message: Message): void {

    const records = JSON.parse(message.body);
    console.log('Parsed records:', records);
    this.allRecordsSubject.next(records);
  }


  private onSummaryReceived(message: Message): void {
    console.log('Records received:', message.body);
    const records = JSON.parse(message.body);
    console.log('Parsed summary:', records);
    this.summarySubject.next(records);
  }

  private onTallySheetReceived(message: Message): void {
    console.log('onTallySheetReceived Records received:', message.body);
    const records = JSON.parse(message.body);
    console.log('Parsed TallySheet Response:', records);
    this.tallySheetSubject.next(records);
  }

  private isConnected(): boolean {
    return this.client.connected;
  }


  public sendRecord(record: any): void {
    if (this.isConnected()) {
      console.log('Sending record:', record);
      this.client.publish({ destination: '/app/saveRecord', body: JSON.stringify(record) });
    } else {
      console.error('Cannot send message: STOMP client is not connected.');
      this.errorSubject.next('Cannot send message: STOMP client is not connected.');
    }
  }

  public sendTallySheet(tallySheet: TallySheet): void {
    if (this.isConnected()) {
      console.log('Sending record:', tallySheet);
      this.client.publish({ destination: '/app/saveTallySheet', body: JSON.stringify(tallySheet) });
    } else {
      console.error('Cannot send message: STOMP client is not connected.');
      this.errorSubject.next('Cannot send message: STOMP client is not connected.');
    }
  }

  public sendSummary(summary: CommonFields): void {
    if (this.isConnected()) {
      console.log('Sending summary:', summary);
      this.client.publish({ destination: '/app/saveSummary', body: JSON.stringify(summary) });
    } else {
      console.error('Cannot send message: STOMP client is not connected.');
      this.errorSubject.next('Cannot send message: STOMP client is not connected.');
    }
  }

  /*public deleteRecord(recordId: number): void {
    if (this.isConnected()) {
      console.log('Deleting record with ID:', recordId);
      this.client.publish({ destination: '/app/deleteRecord', body: recordId.toString() });
    } else {
      console.error('Cannot send message: STOMP client is not connected.');
      this.errorSubject.next('Cannot send message: STOMP client is not connected.');
    }
  }*/

  public getRecords(id: number, page: number, size: number): void {
    if (this.isConnected()) {
      console.log('Requesting records with summaryId:', id, 'page:', page, 'size:', size);
      this.client.publish({ destination: '/app/getRecords', body: JSON.stringify({ id, page, size }) });

    } else {
      console.error('Cannot send message: STOMP client is not connected.');
      this.errorSubject.next('Cannot send message: STOMP client is not connected.');
    }
  }

  public getAllRecords(id: number): void {
    if (this.isConnected()) {
      console.log('Requesting all records with summaryId:', id);
      this.client.publish({ destination: '/app/getAllRecords', body: JSON.stringify({ id }) });

    } else {
      console.error('Cannot send message: STOMP client is not connected.');
      this.errorSubject.next('Cannot send message: STOMP client is not connected.');
    }
  }

  public getSummary(id: number): void {
    if (this.isConnected()) {
      console.log('Requesting summary with summaryId:', id);
      this.client.publish({ destination: '/app/getSummary', body: JSON.stringify({ id }) });
    } else {
      console.error('Cannot send message: STOMP client is not connected.');
      this.errorSubject.next('Cannot send message: STOMP client is not connected.');
    }
  }


  public getTallySheet(summaryId: number): void {
    if (this.isConnected()) {
      console.log('Requesting getTallySheet with summaryId:', summaryId);
      this.client.publish({ destination: '/app/getTallySheet', body: JSON.stringify({ summaryId }) });
    } else {
      console.error('Cannot send message: STOMP client is not connected.');
      this.errorSubject.next('Cannot send message: STOMP client is not connected.');
    }
  }


  public getAckObservable(): Observable<string> {
    console.log('Getting acknowledgment observable');
    return this.ackSubject.asObservable();
  }

  public getRecordsObservable(): Observable<any> {
    console.log('Getting records observable');
    return this.recordsSubject.asObservable();
  }

  public getAllRecordsObservable(): Observable<any> {
    console.log('Getting allrecords observable');
    return this.allRecordsSubject.asObservable();
  }

  public getSummaryObservable(): Observable<any> {
    console.log('Getting summary observable');
    return this.summarySubject.asObservable();
  }

  public getTallySheetObservable(): Observable<TallySheetSummaryResponse> {
    console.log('Getting tallysheet observable');
    return this.tallySheetSubject.asObservable();
  }

  public getConnectionObservable(): Observable<void> {
    return this.connectionSubject.asObservable();
  }

  public getErrorObservable(): Observable<string> {
    return this.errorSubject.asObservable(); // Expose error observable
  }


  exportTallySummary(summaryId: number) {
    const url = `${this.baseUrl}tally-summary/${summaryId}/export`;
    return this.http.get(url, { responseType: 'blob' });
  }

  saveTallySheetRest(tallySheet: TallySheet): Observable<TallySheetSummaryResponse> {
    const url = `${this.baseUrl}saveTallySheet`;
    return this.http.post<TallySheetSummaryResponse>(url, tallySheet);
  }

 

  public getRecordsRestful(id: number, page: number, size: number): Observable<any> {
    const url = `${this.baseUrl}getRecords`;
    console.log(`Url ${url}`)
    const params = new HttpParams()
      .set('id', id.toString())
      .set('page', page.toString())
      .set('size', size.toString());

    return this.http.get(url, { params });
  }

  public getTallysheetRecordsByContainerId(id: number, page: number, size: number): Observable<any> {
    const url = `${this.baseUrl}getRecordsByContainerId`;
    
    const params = new HttpParams()
      .set('id', id.toString())
      .set('page', page.toString())
      .set('size', size.toString());
      const fullUrl = `${url}?${params.toString()}`;
      console.log(`Full URL for getTallysheetRecordsByContainerId is ${fullUrl}`);
    return this.http.get(url, { params });
  }

  public getAllRecordsRestful(id: number): Observable<any> {
    const url = `${this.baseUrl}getAllRecords`;

    const params = new HttpParams()
      .set('id', id.toString())


    return this.http.get(url, { params });
  }

  public getSummaryRestful(id: number): Observable<any> {
    const url = `${this.baseUrl}summary`;

    const params = new HttpParams()
      .set('id', id.toString())
    // const token = this.authService.getToken();
    // console.log(`getSummaryRestful Token  ${token}`);
    // const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get(url, { params });//, headers, withCredentials: true  });
    // return this.http.get(url, {params, headers, withCredentials: true  });

  }

  public getTallysheetSummaryByContainerId(id: number): Observable<any> {
    const url = `${this.baseUrl}summaryByContainerId`;

    const params = new HttpParams()
      .set('id', id.toString());
    const fullUrl = `${url}?${params.toString()}`;
    console.log(`Full URL for getTallysheetSummaryByContainerId is ${fullUrl}`);
    return this.http.get(url, { params });//, headers, withCredentials: true  });
  }

  // Method to update container number
  updateContainerNumber(id: number, containerNumber: any): Observable<any> {
    return this.http.put(`${this.baseUrl}${id}/container-number`, containerNumber);
  }

  // Method to save a new summary
  saveSummary(summary: any): Observable<any> {
    console.log(`url is ${this.baseUrl}saveSummary`)
    return this.http.post(`${this.baseUrl}saveSummary`, summary);
  }

  // Method to delete a record by ID
  deleteRecord(id: number): Observable<any> {
    let params = new HttpParams().set('id', id.toString());
    return this.http.delete(`${this.baseUrl}deleteRecord`, { params }); 
  }

  // Method to update isRowModeEnabled
  updateRowModeEnabled(id: number, isRowModeEnabled: boolean): Observable<any> {
    return this.http.put(`${this.baseUrl}${id}/row-mode-enabled`, null, {
      params: {
        isRowModeEnabled: isRowModeEnabled.toString()
      }
    });
  }

  updateSelectedColumns(summaryId: number, selectedColumnNames: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/${summaryId}/selected-columns`, selectedColumnNames);
    /*.subscribe(response => {
      console.log('Selected columns updated successfully:', response);
    }, error => {
      console.error('Error updating selected columns:', error);
    });*/
  }

  updateLengthIncreament(id: number, lengthIncreament: number): Observable<any> {
    const url = `${this.baseUrl}${id}/length-increament`;
    console.log(`url ${url}`)
    return this.http.put(url, null, {
      params: { lengthIncreament: lengthIncreament.toString() },
    });
  }

  updateCopyPreviousRowLength(id: number, copyPreviousRowLength: boolean): Observable<any> {
    const url = `${this.baseUrl}${id}/copy-previous-row-length`;
    console.log(`url ${url}`)
    return this.http.put(url, null, {
      params: { copyPreviousRowLength: copyPreviousRowLength.toString() },
    });
  }

  updateSummaryType(id: number, summaryType: 'PUBLIC' | 'PRIVATE' | 'SOLD'): Observable<any> {
    const url = `${this.baseUrl}${id}/summary-type`;
    console.log(`url ${url}`)
    return this.http.put(url, null, {
      params: { summaryType: summaryType },
    });
  }

  //
  getShareabelUrl(id: number): Observable<any> {
    const url = `${this.baseUrl}share-url/${id}`;
    console.log(`url ${url}`)
    return this.http.get(url);
  }

  setSummaryIdSessionStorage(id: string) {
    sessionStorage.setItem('recordId', id);
    this.currentRecordId.next(id);
  }

  getSummaryIdSessionStorage() {
    const id = sessionStorage.getItem('recordId');
    if (id) {
      this.currentRecordId.next(id);
    }
    return this.currentRecordId.asObservable();
  }

  clearSummaryIdSessionStorage() {
    sessionStorage.removeItem('recordId');
    this.currentRecordId.next(null);
  }


  confirm(
    message: string,
    header: string,
    acceptTitle: string = 'Confirmed',
    rejectTitle: string = 'Rejected',
    acceptDetail: string = 'Request accepted',
    rejectDetail: string = 'Request rejected',
    icon: string = 'pi pi-info-circle',
    acceptLabel: string = 'Yes',
    rejectLabel: string = 'No',
    position: string = 'center',
    acceptCallback?: () => void,
    rejectCallback?: () => void,

  ) {
    this.confirmationService.confirm({
      message,
      header,
      icon,
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        if (acceptCallback) {
          this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Request accepted' });
          acceptCallback();
        }
      },
      reject: () => {
        if (rejectCallback) {
          this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Request rejected', life: 3000 });
          rejectCallback();
        }
      },
      key: 'positionDialog'
    });
  }
}
