<!-- <p-toast></p-toast> -->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" acceptLabel="Yes"
    rejectLabel="No"></p-confirmDialog>

<!-- <div class="pb-3">
    <p-breadcrumb [model]="breadCumbItems" [home]="home"></p-breadcrumb>
</div> -->

<p-card>
    <div class="formgrid grid col-8">

        <div class="field col-8">
            <p> Customer Code :</p>
        </div>
        <div class="field col-4">
            <p> {{ subscriptionOpted?.customerCode?? '' }}</p>
        </div>


        <div class="field col-8">
            <p> Subscription Package :</p>
        </div>
        <div class="field col-4">
            <p> {{ subscriptionOpted?.name?? '' }}</p>
        </div>

        <div class="field col-8">
            <p> Subscribed On :</p>
        </div>
        <div class="field col-4">
            <p> {{ subscriptionOpted?.startDate?? '' | date:'dd/MM/yyyy' }}</p>
        </div>


        <div class="field col-8">
            <p> Billing Date:</p>
        </div>
        <div class="field col-4">
            <p> {{ subscriptionOpted?.billingDate?? '' | date:'dd/MM/yyyy' }}</p>
        </div>

        <div class="field col-8">
            <p> Current Subscription Validity :</p>
        </div>
        <div class="field col-4">
            <p> {{ subscriptionOpted?.currentSubscriptionEndDate?? '' | date:'dd/MM/yyyy' }}</p>
        </div>

        <div class="field col-8">
            <p> Subscription Validity :</p>
        </div>
        <div class="field col-4">
            <p> {{ subscriptionOpted?.endDate?? '' | date:'dd/MM/yyyy' }}</p>
        </div>

        <!-- <div class="field col-12 md:col-6">
            <p> Payment Status :</p>
        </div>
        <div class="field col-12 md:col-6">
            <p> {{ subscriptionOpted?.paymentStatus?? ''}}</p>
        </div> -->

        <div class="field col-8">
            <p> Subscription Status :</p>
        </div>
        <div class="field col-4">
            <p class="text-green-400" *ngIf="subscriptionOpted?.subscriptionStatus; else subscriptionIsNotActive"> ACTIVE
            </p>
            <ng-template #subscriptionIsNotActive>
                <p class="text-red-400"> SUSPENDED </p>
            </ng-template>
        </div>


    </div>
    <!-- <ng-template pTemplate="footer">
            <p-button [label]="addEditButton" [disabled]="!myForm.dirty" icon="pi pi-check" type="submit"></p-button>
            <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary"
                [style]="{'margin-left': '.5em'}"></p-button>
        </ng-template> -->
    <div  class="grid col-12 width: 95vw;"
    class="text-xs  text-justify sm:w-9 md:w-9 lg:w-6 xl:w-6 surface-overlay border-round border-0 border-gray-500 font-normal">
        <!-- class="text-xs sm:w-9 md:w-9 lg:w-6 xl:w-6 surface-overlay border-round border-1 border-gray-500 font-normal"> -->
        <p class="p-1 justify-content-evenly "> <span>*</span>
            Please note that our system operates based on Coordinated Universal Time (UTC). Therefore, the time of your
            subscription's activation and expiration will be calculated based on this time zone. If you are in a
            different
            time zone, there may be a perceived difference in the timing of your subscription's start and end dates
        </p>
    </div>
</p-card>