<div class="flex lg:justify-content-center flex-wrap m-0 p-0 md:p-1">


  <p-fieldset legend="Account Details" class="col-12 md:col-9 p-0 md:p-1 m-0 p-0 md:p-1">
    <form [formGroup]="accountForm" (ngSubmit)="onSubmit()" class="p-fluid">
      <div class="formgrid grid col-9 md:col-12 m-0 w-full p-0 md:p-1 m-0 p-0 md:p-1">


        <div class="field col-12 md:col-6 pt-2">
          <label for="email" class="block mb-2">Email <i class="ml-1 text-red-500">*</i></label>
          <!-- Ensure label takes full width -->
          <p-iconField>
            <p-inputIcon>
              <i class="pi pi-envelope"></i>
            </p-inputIcon>
            <input pInputText formControlName="email" id="email" type="email" class="text-base text-color surface-overlay  border-1 border-solid surface-border border-round
          appearance-none outline-none focus:border-primary w-full"   [readonly]="isEmailReadonly"/>
          </p-iconField>
          <div *ngIf="isControlInvalid('accountForm','email')" class="text-red-500">
            {{ getErrorAccountMessage('email') }}
          </div>
        </div>
        <div class="field col-12 md:col-6">
        </div>

        <div class="field col-12 md:col-6 pt-2">
          <label for="mobile" class="block mb-2">Mobile <i class="ml-1 text-red-500">*</i></label>
          <!-- Ensure label takes full width -->
          <p-inputGroup>
            <input pInputText id="mobile" formControlName="mobile" type="tel" class="text-base text-color surface-overlay  border-1 border-solid surface-border border-round
          appearance-none outline-none focus:border-primary w-full"/>
            <button type="button" pButton icon="pi pi-check-square" class="p-button-warning" pTooltip="Get OTP"
              tooltipPosition="bottom" showDelay="500" hideDelay="250"></button>
          </p-inputGroup>
          <div *ngIf="isControlInvalid('accountForm','mobile')" class="text-red-500">
            {{ getErrorAccountMessage('mobile') }}
          </div>
        </div>
        <div class="field col-12 md:col-6">
        </div>

        <div class="field col-12 md:col-6 pt-2" [ngClass]="{'hidden': accountForm.get('socialLogin').value}">
          <label for="password">Password <i class="ml-1 text-red-500">*</i></label>
          <p-password formControlName="password"
            styleClass="w-full text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary"
            inputStyleClass="w-full" [toggleMask]="true">
          </p-password>
          <div *ngIf="isControlInvalid('accountForm','password')" class="text-red-500">
            {{ getErrorAccountMessage('password') }}
          </div>
        </div>
        <div class="field col-12 md:col-6">
        </div>

        <div class="field col-12 md:col-6 pt-2" [ngClass]="{'hidden': accountForm.get('socialLogin').value}">
          <label for="confirmPassword">Confirm Password <i class="ml-1 text-red-500">*</i></label>
          <p-password formControlName="confirmPassword"
            styleClass="w-full text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary"
            inputStyleClass="w-full" [toggleMask]="true">
          </p-password>
          <div *ngIf="accountForm.errors && accountForm.errors['passwordsMismatch'] &&
          (accountForm.get('confirmPassword')?.touched || accountForm.get('password')?.touched)" class="text-red-500">
            Passwords do not match.
          </div>
          <div *ngIf="isControlInvalid('accountForm','confirmPassword')" class="text-red-500">
            {{ getErrorAccountMessage('confirmPassword') }}
          </div>
        </div>
        <div class="field col-12 md:col-6">
        </div>

      </div>
      <input type="hidden" formControlName="isMobileVerified" class="hidden" />
      <input type="hidden" formControlName="isEmailVerified" class="hidden" />
      <input type="hidden" formControlName="socialLogin" class="hidden" />


    </form>
  </p-fieldset>

  <p-fieldset legend="Personal Details" class="col-12 md:col-9 p-0 md:p-1 m-0 p-0 md:p-1 pt-2 mt-2">
    <form [formGroup]="personalForm" (ngSubmit)="onSubmit()" class="p-fluid">

      <div class="formgrid grid col-9 md:col-12 m-0 w-full p-0 md:p-1 m-0 p-0 md:p-1  pt-2">
        <div class="field col-12 md:col-6 pt-2">
          <label for="firstName">First Name <i class="ml-1 text-red-500">*</i></label>
          <input pInputText id="firstName" type="text" formControlName="firstName"
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
          <div *ngIf="isPersonalFormControlInvalid('personalForm','firstName')" class="text-red-500">
            {{ getErrorPersonalMessage('firstName') }}
          </div>
        </div>
        <div class="field col-12 md:col-6 pt-2">
          <label for="lastName">Lastname <i class="ml-1 text-red-500">*</i></label>
          <input pInputText id="lastName" type="text" formControlName="lastName"
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
          <div *ngIf="isPersonalFormControlInvalid('personalForm','lastName')" class="text-red-500">
            {{ getErrorPersonalMessage('lastName') }}
          </div>
        </div>

        <div class="field col-12 md:col-6 pt-2">
          <label for="dateOfBirth">Date of Birth</label>
          <p-calendar formControlName="dateOfBirth"
            styleClass="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full"
            *ngIf="!showNativeCalendar" [showIcon]="true" [showOnFocus]="true" dateFormat="mm-dd-yy" />
          <div *ngIf="isPersonalFormControlInvalid('personalForm','dateOfBirth')" class="text-red-500">
            {{ getErrorPersonalMessage('dateOfBirth') }}
          </div>
          <input type="date" formControlName="dateOfBirth" class="input text-base text-color surface-overlay p-3 surface-border
        border-round appearance-none outline-none focus:border-primary w-full md:hidden" *ngIf="showNativeCalendar" />


        </div>
        <div class="field col-12 md:col-6 pt-2">
          <label for="gender">Gender</label>

          <div class="flex flex-wrap gap-3">
            <div class="flex align-items-center">
              <div *ngFor="let gender of genderType" class="field-checkbox">
                <p-radioButton [inputId]="gender" [value]="gender" formControlName="gender"
                  class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary " />
                <label [for]="gender" class="ml-2 mr-2 gap-2">
                  {{ gender }}
                </label>
              </div>
            </div>
            <div *ngIf="isPersonalFormControlInvalid('personalForm','password')" class="text-red-500">
              {{ getErrorPersonalMessage('gender') }}
            </div>
          </div>
        </div>

        <app-location-selection #locationSelectionComponent (resetFormRequest)="onReset()" formControlName="location"
          (locationFormStatus)="onLocationFormStatus($event)" [locationFormValidators]="locationFormValidators"
          [existingLocationData]="existingLocationData" [isAddress2Visible]="true" [isAddress2Optional]="true"
          [isStateOptional]="true" [isCityOptional]="true" [isZipOptional]="true"
          (countriesLoaded)="onCountriesLoaded()" (citiesLoaded)="onCitiesLoaded()"
          (statesLoaded)="onStatesLoaded()"></app-location-selection>

        <!-- <div class="field col-12">
          <label for="address">Address 1 <i class="ml-1 text-red-500">*</i></label>
          <input pInputText id="address" type="text" formControlName="address1"
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
          <div *ngIf="isPersonalFormControlInvalid('personalForm','address1')" class="text-red-500">
            {{ getErrorPersonalMessage('address1') }}
          </div>
        </div>
        <div class="field col-12">
          <label for="address">Address 2</label>
          <input pInputText id="lastname6" type="text" formControlName="address2"
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
          <div *ngIf="isPersonalFormControlInvalid('personalForm','address2')" class="text-red-500">
            {{ getErrorPersonalMessage('address2') }}
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <label for="country">Country <i class="ml-1 text-red-500">*</i></label>
          <p-dropdown formControlName="country" [options]="dummy" optionLabel="name" placeholder="Select a Country"
            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full" />

          <div *ngIf="isPersonalFormControlInvalid('personalForm','country')" class="text-red-500">
            {{ getErrorPersonalMessage('country') }}
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <label for="state">State</label>
          <p-dropdown formControlName="state" [options]="dummy" optionLabel="name" placeholder="Select a State"
            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full" />
          
          <div *ngIf="isPersonalFormControlInvalid('personalForm','state')" class="text-red-500">
            {{ getErrorPersonalMessage('state') }}
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <label for="state">City</label>
          <p-dropdown formControlName="city" [options]="dummy" optionLabel="name" placeholder="Select a City"
            class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full" />
          <div *ngIf="isPersonalFormControlInvalid('personalForm','city')" class="text-red-500">
            {{ getErrorPersonalMessage('city') }}
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <label for="zip">Zip <i class="ml-1 text-red-500">*</i></label>
          <input pInputText id="zip" type="number" formControlName="zip"
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
          <div *ngIf="isPersonalFormControlInvalid('personalForm','zip')" class="text-red-500">
            {{ getErrorPersonalMessage('zip') }}
          </div>
        </div> -->

      </div>
    </form>
    <div class="flex pt-4 justify-content-end">
      <p-button label="Next" icon="pi pi-arrow-right" iconPos="right" (onClick)="onSubmit()" />
    </div>

  </p-fieldset>

</div>