<!-- <p-toast></p-toast> -->
<p-blockUI [blocked]="blockedDocument">
    <i class="pi pi-spin pi-spinner" style="font-size: 4rem !important"></i>
</p-blockUI>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" acceptLabel="Yes"
    rejectLabel="No"></p-confirmDialog>
<form [formGroup]="sellerForm" (ngSubmit)="onSubmit()">

    <div class="pb-3">
        <p-breadcrumb [model]="items" [home]="home" *ngIf="showBreadcrumb"></p-breadcrumb>
    </div>

    <p-card [header]="formHeader" [subheader]="formSubHeader">

        <div class="formgrid grid">

            <div class="field col-12 sm:12 md:col-12 lg:col-6 xl:col-6">

                <label for="name">Company Name <i class="text-red-400">*</i></label>
                <input id="name" name="name" type="text" formControlName="name" pInputText maxlength="32"
                    (input)="nameValidation()"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="sellerForm.invalid && (sellerForm.controls?.['name']?.invalid && (sellerForm.controls?.['name']?.dirty || sellerForm.controls?.['name']?.touched))">
                    <div *ngIf="sellerForm.controls?.['name']?.errors?.['required']">Name is required.</div>
                    <div *ngIf="sellerForm.controls?.['name']?.errors?.['isSellerNameExists'];">Name already exists.
                    </div>
                </div>
            </div>
            <div class="field col-12 sm:6 md:col-6 lg:col-3 xl:col-3">
                <label for="contactPerson">Contact Person </label>
                <input id="contactPerson" name="contactPerson" type="text" formControlName="contactPerson" pInputText
                    maxlength="33"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="sellerForm.controls?.['contactPerson']?.invalid && (sellerForm.controls?.['contactPerson']?.dirty || sellerForm.controls?.['contactPerson']?.touched)">
                    <div *ngIf="sellerForm.controls?.['contactPerson']?.errors?.['minlength']">Min Length is 3</div>
                    <div *ngIf="sellerForm.controls?.['contactPerson']?.errors?.['maxlength']">Max Length is 32</div>
                </div>
            </div>
            <!-- <div class="field col-12 md:col-3"> -->
            <div class="field col-12 sm:6 md:col-6 lg:col-3 xl:col-3">
                <label for="ghanaId">Tax ID </label>
                <input id="ghanaId" name="ghanaId" type="text" formControlName="ghanaId" pInputText maxlength="20"
                    (input)="ghanaIDValidation()"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="sellerForm.controls?.['ghanaId']?.invalid && (sellerForm.controls?.['ghanaId']?.dirty || sellerForm.controls?.['ghanaId']?.touched)">
                    <div *ngIf="sellerForm.controls?.['ghanaId']?.errors?.['minlength']">Min Length 5 characters
                    </div>
                    <div *ngIf="sellerForm.controls?.['ghanaId']?.errors?.['maxlength']">Max Length 20 characters
                    </div>
                    <div *ngIf="sellerForm.controls?.['ghanaId']?.errors?.['isGhanaIdExists'];">Tax Id already exists.
                    </div>
                </div>
            </div>
            <!-- Phone and reference -->
            <div class="field col-12 sm:12 md:col-12 lg:col-6 xl:col-6">

                <label for="reference">Reference</label>
                <input id="reference" type="text" name="reference" formControlName="reference" pInputText
                    maxlength="128"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="sellerForm.controls?.['reference']?.invalid && (sellerForm.controls?.['reference']?.dirty || sellerForm.controls?.['reference']?.touched)">
                    <div *ngIf="sellerForm.controls?.['reference']?.errors?.['minlength']">Min Length 3
                        characters
                    </div>
                    <div *ngIf="sellerForm.controls?.['reference']?.errors?.['maxlength']">Max Length 128
                        characters
                    </div>
                </div>
            </div>
            <div class="field col-12 sm:6 md:col-6 lg:col-3 xl:col-3">
                <label for="phone1">Phone 1</label>
                <input id="phone1" name="phone1" type="tel" formControlName="phone1" pInputText maxlength="16"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="sellerForm.controls?.['phone1']?.invalid && (sellerForm.controls?.['phone1']?.dirty || sellerForm.controls?.['phone1']?.touched)">
                    <div *ngIf="sellerForm.controls?.['phone1']?.errors?.['pattern']">Invalid Phone</div>
                </div>
            </div>
            <!-- <div class="field col-12 md:col-3"> -->
            <div class="field col-12 sm:6 md:col-6 lg:col-3 xl:col-3">
                <label for="phone2">Phone 2</label>
                <input id="phone2" name="phone2" type="tel" formControlName="phone2" pInputText maxlength="16"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="sellerForm.controls?.['phone2']?.invalid && (sellerForm.controls?.['phone2']?.dirty || sellerForm.controls?.['phone2']?.touched)">
                    <div *ngIf="sellerForm.controls?.['phone2']?.errors?.['pattern']">Invalid Phone</div>
                </div>
            </div>
            <app-location-selection formControlName="location" (locationFormStatus)="onLocationFormStatus($event)"
                [existingLocationData]="existingLocationData" [isFromSidebar]="isSubmittedFromSidebar"
                [locationFormValidators]="locationFormValidators" [isAddress2Optional]="true"
                class="w-full m-2" [isStateOptional]="true" [isZipOptional]="true"></app-location-selection>

        </div>

        <div class="formgrid grid">
            <div class="field col-12">
                <label for="notes">Notes</label>
                <textarea id="notes" type="text" name="notes" formControlName="notes" pInputTextarea maxlength="1023"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                    style="height:85%">
                </textarea>
                <div class="text-red-400"
                    *ngIf="sellerForm.controls?.['notes']?.invalid && (sellerForm.controls?.['notes']?.dirty || sellerForm.controls?.['notes']?.touched)">
                    <div *ngIf="sellerForm.controls?.['notes']?.errors?.['maxlength']">Max Length 1024 characters
                    </div>
                </div>
            </div>
            <!-- <div class="field col-6">
                <label for="contactPerson">Attachment(s)</label>
                <p-fileUpload name="myfile[]" url="./upload.php" accept="image/*" [auto]="true"
                    [draggable]="true"></p-fileUpload>
            </div> -->





        </div>

        <ng-template pTemplate="footer">

            <!-- <p-button [label]="addEditButton" [disabled]="!sellerForm.dirty" icon="pi pi-save"
                styleClass="p-button-success w-9rem" type="submit"> </p-button>

            <p-button label="Reset" icon="pi pi-sync" styleClass="p-button-secondary  w-9rem" *ngIf="!editMode"
                [style]="{'margin-left': '.5em'}" (onClick)="onReset()"></p-button>

            <p-button label="Delete" icon="pi pi-trash" styleClass="p-button-danger  w-9rem" *ngIf="editMode"
                [style]="{'margin-left': '.5em'}" (onClick)="onDelete(this.sellerEditData.supplierId)"></p-button> -->

            <div class="grid">
                <div class="col-fixed" class="sm:w-12rem md:w-12rem lg:w-12rem xl:w-12rem">
                    <div class="text-center p-3 font-bold">
                        <!-- <p-button [label]="addEditButton" [disabled]="!sellerForm.dirty" icon="pi pi-save"
                            styleClass="p-button-success w-9rem" type="submit"> </p-button> -->
                            <p-button [label]="addEditButton" icon="pi pi-save"
                            styleClass="p-button-success w-9rem" type="submit"> </p-button>
                    </div>
                </div>
                <div class="col-fixed" class="sm:w-12rem md:w-12rem lg:w-12rem xl:w-12rem">
                    <div class="text-center p-3 font-bold">
                        <p-button label="Reset" icon="pi pi-sync" styleClass="p-button-secondary  w-9rem"
                            *ngIf="!editMode" [style]="{'margin-left': '.5em'}" (onClick)="onReset()"></p-button>

                        <p-button label="Delete" icon="pi pi-trash" styleClass="p-button-danger  w-9rem"
                            *ngIf="editMode" [style]="{'margin-left': '.5em'}"
                            (onClick)="onDelete(this.sellerEditData.supplierId)"></p-button>
                    </div>
                </div>
            </div>

        </ng-template>
    </p-card>

</form>