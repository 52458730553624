import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class MeasurementCanDeactivateGuardServiceService {

  constructor() { }

  tableForm!: FormGroup;

  setForm(form: FormGroup) {
    this.tableForm = form;
  }

}
