<router-outlet></router-outlet>
<div class="custom-card">
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
    <div class="pb-3">
        <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
    </div>

    <!-- <div class="card" style="width: 98vw"> -->
    <p-card [header]="formHeader" [subheader]="formSubHeader" class="w-full">
        <p-speedDial [model]="billOfLadingSpeedDialItems" direction="up-left" type="quarter-circle" [radius]="120"
            class="ml-5 my-speed-dial" [rotateAnimation]="true"></p-speedDial>
        <p-table #dt [lazy]="true" [value]="billOfLadings" (onLazyLoad)="sortBillOfLading($event)" [rowHover]="true"
            [customSort]="true" sortMode="multiple" dataKey="billOfLadingId"
            [globalFilterFields]="['name','contactPerson','city','gst','etd','eta']" [showCurrentPageReport]="true"
            [rows]="rows" [rowsPerPageOptions]="rowsPerPageOptions" [paginator]="true" [first]="first"
            currentPageReportTemplate="Showing {{first}} to {{last}} of {{totalRecords}} entries"
            [totalRecords]="totalRecords" [columns]="cols" [(selection)]="selectedBillOfLadings"
            (onRowSelect)="onRowSelect($event)" [styleClass]="tableStyle" [tableStyle]="tableStyleOptions"
            [loading]="loading" paginatorDropdownAppendTo="body">


            <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-between">

                    <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" class="multiselectfields"
                        selectedItemsLabel="{0} Selected" placeholder="Choose Columns" defaultLabel="Choose Columns"
                        [maxSelectedLabels]="3"> </p-multiSelect>
                    <!-- <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="globalFilter($event)" #globalFiterInput
                        class="searchglobally" placeholder="Search keyword" />
                </span> -->
                    <span class="p-input-icon-left">
                        <div class="p-inputgroup">
                            <input pInputText type="text" (input)="globalFilter($event,dt)" #globalFiterInput
                                class="searchglobally" placeholder="Search keyword" />
                            <button type="button" pButton icon="pi pi-filter-slash" styleClass="p-button-warn"
                                (click)="clearAllFilter(dt,globalFiterInput)"></button>
                        </div>
                    </span>
                </div>
            </ng-template>


            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th>
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th class="text-center"><i class="pi pi-pencil"></i></th>
                    <th class="text-center"><i class="pi pi-eye"></i></th>
                    <th class="text-center"><i class="pi pi-share-alt"></i></th>
                    <th pSortableColumn="blNumber" p-toggleableColumn>BL Number
                        <p-columnFilter type="text" field="blNumber" display="menu" matchMode="contains"
                            [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                        <p-sortIcon field="blNumber"></p-sortIcon>
                    </th>
                    <th pSortableColumn="vesselName" *ngIf="_selectedColumns!.includes('Vessel Name')">Vessel Name
                        <p-columnFilter type="text" field="vesselName" display="menu" matchMode="contains"
                            [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                        <p-sortIcon field="vesselName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="vesselCode" *ngIf="_selectedColumns!.includes('Vessel Code')">Vessel Code
                        <p-columnFilter type="text" field="vesselCode" display="menu" matchMode="contains"
                            [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                        <p-sortIcon field="vesselCode"></p-sortIcon>
                    </th>
                    <th pSortableColumn="etd" *ngIf="_selectedColumns!.includes('ETD')">ETD
                        <!-- <p-columnFilter type="text" field="etd" display="menu" matchMode="contains" [showMatchModes]="true"
                        [showOperator]="false" [showAddButton]="false"  [matchModeOptions]="dateFilterOptions"></p-columnFilter> -->
                        <p-columnFilter #etd field="etd" matchMode="in" display="menu" [showMatchModes]="true"
                            [showOperator]="false" [showAddButton]="false" [matchModeOptions]="dateFilterOptions">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-calendar [ngModel]="value" (onSelect)="debug($event); filter($event)"
                                    [showButtonBar]="true" [showIcon]="true" dateFormat="dd/mm/yy"></p-calendar>
                            </ng-template>
                        </p-columnFilter>
                        <p-sortIcon field="etd"></p-sortIcon>
                    </th>
                    <th pSortableColumn="eta" *ngIf="_selectedColumns!.includes('ETA')">ETA
                        <p-columnFilter #eta field="eta" matchMode="in" display="menu" [showMatchModes]="true"
                            [showOperator]="false" [showAddButton]="false" [matchModeOptions]="dateFilterOptions">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-calendar [ngModel]="value" (onSelect)="debug($event); filter($event)"
                                    [showButtonBar]="true" [showIcon]="true" dateFormat="dd/mm/yy"></p-calendar>
                            </ng-template>
                        </p-columnFilter>
                        <p-sortIcon field="eta"></p-sortIcon>
                    </th>
                    <th pSortableColumn="shippingLine" *ngIf="_selectedColumns!.includes('Shipping Line')">Shipping Line
                        <p-columnFilter type="text" field="shippingLine" display="menu" matchMode="contains"
                            [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                        <p-sortIcon field="shippingLine"></p-sortIcon>
                    </th>
                    <th pSortableColumn="buyer" *ngIf="_selectedColumns!.includes('Buyer')">Buyer
                        <p-columnFilter type="text" field="buyer" display="menu" matchMode="contains"
                            [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                        <p-sortIcon field="buyer"></p-sortIcon>

                    </th>

                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-bl let-columns="columns" let-rowIndex="rowIndex">
                <tr [pSelectableRow]="columns" (click)="onRowClick(bl)" p-selectable-row>
                    <td (click)="$event.stopPropagation()">
                        <p-tableCheckbox [value]="bl"></p-tableCheckbox>
                    </td>
                    <td (click)="$event.stopPropagation()" class="text-center cursor-pointer" (click)="onEditBL(bl)"><i
                            class="pi pi-pencil"></i>
                    </td>

                    <td (click)="$event.stopPropagation()" class="text-center cursor-pointer" (click)="onViewBL(bl)"><i
                        class="pi pi-eye"></i>
                </td>

                    <td (click)="$event.stopPropagation();" class="text-center cursor-pointer">
                        <i #share class="pi pi-share-alt" style="cursor: pointer;"
                            (click)="showShareContextMenu($event, rowIndex,bl, cm )"></i>
                        <p-contextMenu #cm [target]="share" [model]="shareItems" appendTo="body"></p-contextMenu>
                    </td>
                    <td> {{bl.blNumber}}</td>
                    <td *ngIf="_selectedColumns!.includes('Vessel Name')">{{bl?.vesselName?? '' }}</td>
                    <td *ngIf="_selectedColumns!.includes('Vessel Code')">{{ bl?.vesselCode?? '' }}</td>
                    <td *ngIf="_selectedColumns!.includes('ETD')">{{bl?.etd?? '' }}</td>
                    <td *ngIf="_selectedColumns!.includes('ETA')"> {{bl?.eta?? '' }}</td>
                    <td *ngIf="_selectedColumns!.includes('Shipping Line')"> {{bl?.shippingLineId?.name ?? ''}}</td>
                    <td *ngIf="_selectedColumns!.includes('Buyer')"> {{bl?.buyerId?.name ?? ''}}</td>

                </tr>
            </ng-template>


            <ng-template pTemplate="emptymessage">
                <tr>
                    <!-- <td colspan="(_selectedColumns?.length ?? 6) + 2">No records found.</td> -->
                    <td [attr.colspan]="(_selectedColumns ? _selectedColumns.length + 4 : 4)" class="text-center">No
                        records
                        found.</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="loadingbody" let-columns="columns">
                <tr style="height:46px">
                    <td>
                        <p-skeleton></p-skeleton>
                    </td>
                    <td>
                        <p-skeleton></p-skeleton>
                    </td>
                    <td>
                        <p-skeleton></p-skeleton>
                    </td>
                    <td>
                        <p-skeleton></p-skeleton>
                    </td>
                    <td *ngIf="_selectedColumns!.includes('Vessel Name')">
                        <p-skeleton></p-skeleton>
                    </td>
                    <td *ngIf="_selectedColumns!.includes('Vessel Code')">
                        <p-skeleton></p-skeleton>
                    </td>
                    <td *ngIf="_selectedColumns!.includes('ETD')">
                        <p-skeleton></p-skeleton>
                    </td>
                    <td *ngIf="_selectedColumns!.includes('ETA')">
                        <p-skeleton></p-skeleton>
                    </td>
                    <td *ngIf="_selectedColumns!.includes('Shipping Line')">
                        <p-skeleton></p-skeleton>
                    </td>
                    <td *ngIf="_selectedColumns!.includes('Buyer')">
                        <p-skeleton></p-skeleton>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-card>
</div>