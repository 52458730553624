import { Component, HostListener } from '@angular/core';
import { AppConstants } from 'src/app/commons/app-constants';
import { UtilService } from 'src/app/services/util.service';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class FooterComponent {
  isMobile: boolean;

  constructor(private utilService: UtilService) {
    this.isMobile = utilService.isMobile();
  //  console.log(`ISMOBILE ${ this.isMobile }`);
  }

  lumberlinq_url = AppConstants.LUMBER_LINQ_WEBSITE;

  openSocialUrl(socialName: string) {
    let url = '';

    switch (socialName.toLowerCase()) {
      case 'facebook':
        url = 'https://www.facebook.com/';
        break;
      case 'twitter':
        url = 'https://www.twitter.com/';
        break;
      case 'linkedin':
        url = 'https://www.linkedin.com/';
        break;
      case 'instagram':
        url = 'https://www.instagram.com/';
        break;
      default:
        console.log('Unknown social platform');
        return;
    }

    window.open(url, '_blank');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.handleResize();
  }

  handleResize(): void {
    // console.log('Window has been resized!');
    // console.log(`ISMOBILE ${ this.isMobile }`);
  }
}
