import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { VolumeEstimateService } from '../../services/volume-estimate.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';

export interface Timber {
  width: number | null;
  widthUnit: string | null;
  thickness: number | null;
  thicknessUnit: string | null;
  length: number | null;
  lengthUnit: string | null;
  quantity: number | null;
  cbm: number | null;
  cft: number | null;
  price: number | null;
  priceUnit: string | null;
  total: number | null;
}

@Component({
  selector: 'app-volume-estimate',
  templateUrl: './volume-estimate.component.html',
  styleUrls: ['./volume-estimate.component.css'],
  providers: [ToastMessagesService],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class VolumeEstimateComponent implements OnInit {
  showButtons = true;
  protected items!: MenuItem[];
  @Input() showBreadcrumb: boolean = true;
  home!: MenuItem;
  formHeader!: string;
  formSubHeader!: string;
  units = [
    { label: 'Unit', value: null },
    { label: 'mm', value: 'mm' },
    { label: 'cm', value: 'cm' },
    { label: 'in', value: 'in' },
    { label: 'ft', value: 'ft' },
    { label: 'mtr', value: 'mtr' },
  ];

  priceUnits = [
    { label: 'Unit', value: null },
    { label: 'CFT', value: 'cft' },
    { label: 'CBM', value: 'cbm' },
  ];

  formGroup!: FormGroup;
  volumes: any[] = [];
  grandTotals!: {
    totalQuantity: number;
    totalCbm: number;
    totalCft: number;
    grandTotal: number;
  };

  estimatePurpose: any[] = [
    { name: 'Volume Estimation', key: 0 },
    { name: 'Volume and Cost Estimation', key: 1 },
  ];
  selectedPurpose: any;

  constructor(
    private fb: FormBuilder,
    //  private volumeCalculationService: VolumeCalculationService,
    private toastMessageService: ToastMessagesService,
    private volumeEstimateService: VolumeEstimateService,
    private route: ActivatedRoute,
    private titleService: Title
  ) {
    this.populateBreadCrumb();
    this.setPageTitle();

    this.home = { icon: 'pi pi-home', routerLink: '/' };
  }

  commonWidthUnit: any;
  commonThicknesUnit: any;
  commonLengthUnit: any;
  useCommonUnits: boolean = true;

  /* ngOnInit() {
    this.formGroup = this.fb.group({
      width: [],
      widthUnit: [],
      thickness: [],
      thicknessUnit: [],
      length: [],
      lengthUnit: [],
      quantity: [],
    });
    // this.addBlankRow();
    this.addRowAtIndex(0);
    this.loadTimbersFromLocalStorage();
    this.loadCommonUnitsFromLocalStorage();
    this.calculateGrandTotals();
  } */
  ngOnInit() {
    this.formGroup = this.fb.group({
      width: [],
      widthUnit: [],
      thickness: [],
      thicknessUnit: [],
      length: [],
      lengthUnit: [],
      quantity: [],
    });

   // this.addRowAtIndex(0);
    this.loadTimbersFromLocalStorage();
    this.loadCommonUnitsFromLocalStorage();
    this.loadCommonUnitsFlagFromLocalStorage();

    // loop over the timbers array and call the calculate method for each item
    this.timbers.forEach((rowData, index) => {
      console.log(`Record is fr ${index} and ${JSON.stringify(rowData)}`);
    });

    this.calculateGrandTotals();
    this.selectedPurpose = this.estimatePurpose[1];
    this.loadEstimatePurposeFromLocalStorage();
    this.loadCommonUnitsFlagFromLocalStorage();
  }

  timbers: Timber[] = [
    {
      width: null,
      widthUnit: null,
      thickness: null,
      thicknessUnit: null,
      length: null,
      lengthUnit: null,
      quantity: null,
      cbm: null,
      cft: null,
      price: null,
      priceUnit: null,
      total: null,
    },
  ];

  ngOnDestroy(): void {
    // Destroy logic here
  }

  calculate(index: number): void {
    console.log(`calculateValues`);
    const row = this.timbers[index];
    const unitConversionService: any = {
      mm: { mtr: 0.001 },
      cm: { mtr: 0.01 },
      in: { mtr: 0.0254 },
      ft: { mtr: 0.3048 },
      mtr: { mtr: 1 },
    };

    if (
      row.width &&
      row.thickness &&
      row.length &&
      row.quantity &&
      row.widthUnit &&
      row.thicknessUnit &&
      row.lengthUnit
    ) {
      // calculate CBM in MTR
      let widthMtr = row.width * unitConversionService[row.widthUnit].mtr;
      let thicknessMtr =
        row.thickness * unitConversionService[row.thicknessUnit].mtr;
      let lengthMtr = row.length * unitConversionService[row.lengthUnit].mtr;
      row.cbm = widthMtr * thicknessMtr * lengthMtr * row.quantity;
      row.cbm = parseFloat(row.cbm.toFixed(3));

      // calculate CFT from CBM
      row.cft = row.cbm * 35.315;
      row.cft = parseFloat(row.cft.toFixed(3));
      console.log(`row.cft is ${row.cft}`);

      // calculate total
      if (row.price && row.priceUnit) {
        switch (row.priceUnit) {
          case 'cbm':
            row.total = row.price * row.cbm;
            break;
          case 'cft':
            row.total = row.price * row.cft;
            break;
          default:
            row.total = 0;
        }
        row.total = parseFloat(row.total.toFixed(2));
      } else {
        row.total = null;
      }
    } else {
      row.cbm = null;
      row.cft = null;
      row.total = null;
    }
    this.saveTimbersToLocalStorage();
    this.calculateGrandTotals();
    this.calculateRowZero();
  }

  calculateAllRows(): void {
    console.log(`calculateValues for all rows`);
    this.calculateRowZero();
    const unitConversionService: any = {
      mm: { mtr: 0.001 },
      cm: { mtr: 0.01 },
      in: { mtr: 0.0254 },
      ft: { mtr: 0.3048 },
      mtr: { mtr: 1 },
    };

    this.timbers.forEach((row) => {
      if (
        row.width &&
        row.thickness &&
        row.length &&
        row.quantity &&
        row.widthUnit &&
        row.thicknessUnit &&
        row.lengthUnit
      ) {
        // calculate CBM in MTR
        let widthMtr;
        let thicknessMtr;
        let lengthMtr;
        if (this.useCommonUnits) {
          widthMtr =
            row.width * unitConversionService[this.commonWidthUnit].mtr;
          thicknessMtr =
            row.thickness * unitConversionService[this.commonThicknesUnit].mtr;
          lengthMtr =
            row.length * unitConversionService[this.commonLengthUnit].mtr;
          this.saveCommonUnitsToLocalStorage(
            this.commonWidthUnit,
            this.commonThicknesUnit,
            this.commonLengthUnit
          );
        } else {
          widthMtr = row.width * unitConversionService[row.widthUnit].mtr;
          thicknessMtr =
            row.thickness * unitConversionService[row.thicknessUnit].mtr;
          lengthMtr = row.length * unitConversionService[row.lengthUnit].mtr;
        }

        row.cbm = widthMtr * thicknessMtr * lengthMtr * row.quantity;
        row.cbm = parseFloat(row.cbm.toFixed(3));

        // calculate CFT from CBM
        row.cft = row.cbm * 35.315;
        row.cft = parseFloat(row.cft.toFixed(3));

        // calculate total
        if (row.price && row.priceUnit) {
          switch (row.priceUnit) {
            case 'cbm':
              row.total = row.price * row.cbm;
              break;
            case 'cft':
              row.total = row.price * row.cft;
              break;
            default:
              row.total = 0;
          }
          row.total = parseFloat(row.total.toFixed(2));
        } else {
          row.total = null;
        }
      } else {
        row.cbm = null;
        row.cft = null;
        row.total = null;
      }
    });

    this.saveTimbersToLocalStorage();
    this.calculateGrandTotals();
    this.calculateRowZero();
  }

  calculateGrandTotals(): {
    totalQuantity: number;
    totalCbm: number;
    totalCft: number;
    grandTotal: number;
  } {
    let totalQuantity = 0;
    let totalCbm = 0;
    let totalCft = 0;
    let grandTotal = 0;

    this.timbers.forEach((row) => {
      if (row.quantity) totalQuantity += row.quantity;
      if (row.cbm) totalCbm += row.cbm;
      if (row.cft) totalCft += row.cft;
      if (row.total) grandTotal += row.total;
    });

    this.grandTotals = {
      totalQuantity: parseFloat(totalQuantity.toFixed(2)),
      totalCbm: parseFloat(totalCbm.toFixed(3)),
      totalCft: parseFloat(totalCft.toFixed(3)),
      grandTotal: parseFloat(grandTotal.toFixed(2)),
    };
    console.log(`grand total ${JSON.stringify(this.grandTotals)}`);
    this.calculateRowZero();
    return this.grandTotals;
  }

/*  addRowAtIndex(rowIndex: number): void {
  console.log(`Use Common Units ${this.useCommonUnits}`);
  const row = this.timbers[rowIndex];
  if (
    !row.width ||
    !row.thickness ||
    !row.length ||
    !row.price ||
    !row.quantity
  ) {
    if (this.timbers.length> 0) {
      this.toastMessageService.showWarningMessage(
        `Enter fields`,
        `Please enter all row fields`
      );
      return;
    }
  }
  const index = rowIndex + 1;
  let newTimber;
  if (
    !this.commonWidthUnit ||
    (!this.commonLengthUnit && !this.commonThicknesUnit)
  ) {
    if (this.timbers.length>0) {
      this.toastMessageService.showWarningMessage(
        `Select units`,
        `Please select units first`
      );
      return;
    }
  }

  newTimber = {
    width: this.useCommonUnits ? row.width : null,
    widthUnit: this.commonWidthUnit,
    thickness: this.useCommonUnits ? row.thickness : null,
    thicknessUnit: this.commonThicknesUnit,
    length: this.useCommonUnits ? row.length : null,
    lengthUnit: this.commonLengthUnit,
    quantity: this.useCommonUnits ? row.quantity : null,
    cbm: null,
    cft: null,
    price: this.useCommonUnits ? row.price : null,
    priceUnit: null,
    total: null,
  };

  this.timbers.splice(index, 0, newTimber);
  this.saveTimbersToLocalStorage();
} */

addRowAtIndex(rowIndex: number): void {
  console.log(`Use Common Units ${this.useCommonUnits}`);
  const row = this.timbers[rowIndex];
  if (
    !row.width ||
    !row.thickness ||
    !row.length ||
    !row.price ||
    !row.quantity
  ) {
    if (this.timbers.length> 0) {
      this.toastMessageService.showWarningMessage(
        `Enter fields`,
        `Please enter all row fields`
      );
      return;
    }
  }
  const index = rowIndex + 1;
  let newTimber;
  if (
    !this.commonWidthUnit ||
    (!this.commonLengthUnit && !this.commonThicknesUnit)
  ) {
    if (this.timbers.length>0) {
      this.toastMessageService.showWarningMessage(
        `Select units`,
        `Please select units first`
      );
      return;
    }
  }

  newTimber = {
    width: this.useCommonUnits ? row.width : null,
    widthUnit: this.commonWidthUnit,
    thickness: this.useCommonUnits ? row.thickness : null,
    thicknessUnit: this.commonThicknesUnit,
    length: this.useCommonUnits ? row.length : null,
    lengthUnit: this.commonLengthUnit,
    quantity: this.useCommonUnits ? row.quantity : null,
    cbm: null,
    cft: null,
    price: this.useCommonUnits ? row.price : null,
    priceUnit: null,
    total: null,
  };

  this.timbers.splice(index, 0, newTimber);
  this.saveTimbersToLocalStorage();
}


  removeRow(index: number): void {
    if (this.timbers.length > 1) {
      this.timbers.splice(index, 1);
      this.saveTimbersToLocalStorage();
    }
    this.calculateGrandTotals();
  }

  private saveTimbersToLocalStorage(): void {
    localStorage.setItem('timbers', JSON.stringify(this.timbers));
  }

  private saveCommonUnitsToLocalStorage(
    widthUnit: any,
    thicknessUnit: any,
    lengthUnit: any
  ): void {
    localStorage.setItem(
      'common_units',
      JSON.stringify({
        widthUnit: widthUnit,
        thicknessUnit: thicknessUnit,
        lengthUnit: lengthUnit,
      })
    );
  }

  private loadCommonUnitsFromLocalStorage(): void {
    const storedCommonUnits = localStorage.getItem('common_units');
    if (storedCommonUnits) {
      const commonUnits = JSON.parse(storedCommonUnits);
      console.log(`Common Units ${JSON.stringify(commonUnits)}`);
      this.commonWidthUnit = commonUnits.widthUnit;
      this.commonLengthUnit = commonUnits.lengthUnit;
      this.commonThicknesUnit = commonUnits.thicknessUnit;
    }
  }

  private loadTimbersFromLocalStorage(): void {
    const storedTimbers = localStorage.getItem('timbers');
    if (storedTimbers) {
      this.timbers = JSON.parse(storedTimbers);
    }
  }

  calculateRowZero() {
    console.log(`calculateRowZero ${this.useCommonUnits}`);
    if (
      this.useCommonUnits &&
      (!this.timbers[0].widthUnit ||
        !this.timbers[0].lengthUnit ||
        !this.timbers[0].thicknessUnit)
    ) {
      console.log(`this.timbers[0] ${JSON.stringify(this.timbers[0])}`);
      this.timbers[0].widthUnit = this.commonWidthUnit;
      this.timbers[0].lengthUnit = this.commonLengthUnit;
      this.timbers[0].thicknessUnit = this.commonThicknesUnit;
    }
  }

  saveEstimatePurpose(event: any) {
    console.log(`Save Estimaet Purpose ${JSON.stringify(event.value)}`);
    localStorage.setItem('estimate_purpose', event.value.key);
  }

  loadEstimatePurposeFromLocalStorage(): void {
    const estimatePurpose = localStorage.getItem('estimate_purpose');
    if (estimatePurpose) {
      this.selectedPurpose = this.estimatePurpose.find(
        (purpose) => purpose.key == estimatePurpose
      );
    }
  }

  saveCommonUnitsFlag() {
    console.log(
      `Save Estimaet Purpose ${JSON.stringify(this.useCommonUnits.toString())}`
    );
    localStorage.setItem('common_units_flag', this.useCommonUnits.toString());
  }

  loadCommonUnitsFlagFromLocalStorage(): void {
    const commonUnitsFlag = localStorage.getItem('common_units_flag');
    if (commonUnitsFlag) {
      console.log(`commonUnitsFlag ${commonUnitsFlag}`);
      let boolVal = JSON.parse(commonUnitsFlag);
      this.useCommonUnits = boolVal;
    }
  }

  populateBreadCrumb() {
    this.items = [
      // { label: 'Buyers', routerLink: '/buyers' },
      { label: 'Cost Estimate' },
    ];
  }
  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }
}
