<header *ngIf="showHeader">
    <div class="left-nav">
        <p-button type="button" class="mr-2" (click)="showHideMenu()" icon="pi pi-bars" styleClass="p-button-sm"
            *ngIf="showMenuButton"></p-button>

    </div>
    <div class="center-logo pt-2" *ngIf="showLogo">
        <a href="https://www.lumberlinq.com">
            <img src="https://rikexim.com/assets/images/resources/rikexim_200x50_dark.webp" alt="Lumberlinq"
                class="logo-img">
        </a>
    </div>
    <div class="right-user">

        <div *ngIf="!isThisMobile">
            <ng-container *ngIf="userProfile?.profilePicture; else noImageUrl">
                <p-avatar #avatar1  [image]="userProfile?.profilePicture?? ''" [ngStyle]="{'cursor': 'pointer'}" styleClass="mr-2"
                    size="large" shape="circle" (click)="toggleMenu($event)" />



                <!--  <p-avatar #avatar [image]="userProfile?.profilePicture?? ''" styleClass="useravatar mr-2"
                    class="useravatar" shape="circle" alt="Open Menu" [ngStyle]="{'cursor': 'pointer'}"
                    (click)="toggleMenu($event)"></p-avatar> -->
            </ng-container>
            <ng-template #noImageUrl>
                <p-avatar #avatar1 [label]="userProfile?.firstName?.charAt(0) || 'U'" styleClass="mr-2" size="normal"
                    [style]="{ 'background-color': '#2196F3', color: '#ffffff' }" shape="circle"
                    (click)="toggleMenu($event)"></p-avatar>
            </ng-template>

            <p-contextMenu   [target]="avatar1?.nativeElement"
                [model]="items" #menu triggerEvent="click"></p-contextMenu>
        </div>
    </div>

</header>

<p-toast class="width: 80vw" key="logout-toast" [preventOpenDuplicates]="true"
    [showTransformOptions]="'translateY(100%)'" [showTransitionOptions]="'700ms'" [hideTransitionOptions]="'1000ms'"
    [showTransformOptions]="'translateX(100%)'" position="top-center"></p-toast>