import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UnitConversionService {
  constructor() {}

  private conversionRates: { [unit: string]: number } = {
    mm: 1,
    cm: 10,
    in: 25.4,
    ft: 304.8,
    mtr: 1000,
  };

  convert(
    fromValue: number,
    fromUnit: string,
    toUnit: string,
    decimalPlaces: number
  ): number {
    if (fromValue && fromUnit && toUnit) {
      const baseValue =
        fromValue *
        this.conversionRates[fromUnit as keyof typeof this.conversionRates];
      const toValue =
        baseValue /
        this.conversionRates[toUnit as keyof typeof this.conversionRates];

      // round to the number of decimal places specified
      return parseFloat(toValue.toFixed(decimalPlaces));
    }

    return 0; // Return 0 or null or appropriate value when conversion is not possible
  }
}
