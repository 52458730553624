<div class="w-full" style="width: 100%;">
    <form [formGroup]="containerPrivacyForm" class="w-full">
        <div class="pb-3">
            <p-breadcrumb [model]="items" [home]="home" *ngIf="showBreadcrumb"></p-breadcrumb>
        </div>
        <p-card styleClass="m-0 p-0" [header]="formHeader" [subheader]="formSubHeader">
            

            <!-- <div class="formgrid grid grid-nogutter pt-3">
                <div class="col-6">
                    <div class="font-bold border-1 h-3rem">
                        <p>Container Number</p>
                    </div>
                </div>
                <div class="col-2">
                    <div class="text-center border-1 h-3rem"> 
                        <p-toggleButton formControlName="checked1" onLabel="On" offLabel="Off" />
                    </div>
                </div>
                <div class="col-2">
                    <div class="text-center border-1 h-3rem"> <p-toggleButton formControlName="checked2" onLabel="On"
                            offLabel="Off" /></div>
                </div>
                <div class="col-2">
                    <div class="text-center border-1 font-bold h-3rem">
                        <p-toggleButton formControlName="checked3" onLabel="On" offLabel="Off" />
                    </div>
                </div>
            </div> -->
            <div
                class="formgrid grid grid-nogutter align-items-center bg-gray-200 text-primary border-2 surface-border font-bold">
                <div class="col-6 border-right-1 surface-border">
                    <div class="h-3rem flex align-items-center pl-2">
                        <p>Field Name</p>
                    </div>
                </div>
                <div class="col-2 border-right-1 surface-border">
                    <div class="h-3rem flex align-items-center justify-content-center">
                        <p>Private</p>
                    </div>
                </div>
                <div class="col-2 border-right-1 surface-border">
                    <div class="h-3rem flex align-items-center justify-content-center">
                        <p>Protected</p>
                    </div>
                </div>
                <div class="col-2">
                    <div class="h-3rem flex align-items-center justify-content-center">
                        <p>Public</p>
                    </div>
                </div>
            </div>

            <!-- Container Number Starts 
            <div class="formgrid grid grid-nogutter align-items-center border-1">
                <div class="col-6 border-right-1">
                    <div class="font-bold h-3rem flex align-items-center pl-2">
                        <p>Container Number</p>
                    </div>
                </div>
                <div class="col-2 border-right-1">
                    <div class="text-center h-3rem flex align-items-center justify-content-center">
                        <p-toggleButton formControlName="checked1" onLabel="On" offLabel="Off"></p-toggleButton>
                    </div>
                </div>
                <div class="col-2 border-right-1">
                    <div class="text-center h-3rem flex align-items-center justify-content-center">
                        <p-toggleButton formControlName="checked2" onLabel="On" offLabel="Off"></p-toggleButton>
                    </div>
                </div>
                <div class="col-2">
                    <div class="text-center font-bold h-3rem flex align-items-center justify-content-center">
                        <p-toggleButton formControlName="checked3" onLabel="On" offLabel="Off"></p-toggleButton>
                    </div>
                </div>
            </div>
              -->

            <div formArrayName="privacySettings" *ngIf="privacySettings && privacySettings.length>0">
                <div *ngFor="let field of fields; let i = index" [formGroupName]="i"
                    class="formgrid grid grid-nogutter align-items-center border-1">
                    <div class="col-6 border-right-1">
                        <div class="font-bold h-3rem flex align-items-center pl-2">
                            <p>{{ field.label }}</p>
                        </div>
                    </div>
                    <div class="col-2 border-right-1">
                        <div class="text-center h-3rem flex align-items-center justify-content-center">
                            <p-toggleButton formControlName="public" onLabel="On" offLabel="Off"></p-toggleButton>
                        </div>
                    </div>
                    <div class="col-2 border-right-1">
                        <div class="text-center h-3rem flex align-items-center justify-content-center">
                            <p-toggleButton formControlName="protected" onLabel="On" offLabel="Off"></p-toggleButton>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="text-center font-bold h-3rem flex align-items-center justify-content-center">
                            <p-toggleButton formControlName="private" onLabel="On" offLabel="Off"></p-toggleButton>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" (click)="onSubmit()" class="p-button p-component">Save</button>

        </p-card>
    </form>
</div>
<div class="pt-3">
    <button pButton type="button" label="Test Container View" (click)="testContainerViewFn()"></button>
</div>