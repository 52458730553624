import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { MeasurementSharedDataService } from '../../measurement/services/measurement-shared-data.service';
import { MeasurementService } from '../../measurement/services/measurement.service';
import { ContainerService } from '../services/container.service';
import { finalize } from 'rxjs';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-container-photos',
  templateUrl: './container-photos.component.html',
  styleUrls: ['./container-photos.component.css'],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class ContainerPhotosComponent implements OnInit {
  @Input() containerId!: any; // = CONTAINER_ID; //////
  images!: any[];
  isLoading = false;

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5,
    },
    {
      breakpoint: '960px',
      numVisible: 4,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
    },
  ];
  isPublic!: boolean;
  containerUUID: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private containerService: ContainerService,
    private toastMessageService: ToastMessagesService,
    private measurementSharedDataService: MeasurementSharedDataService
  ) {}

  ngOnInit(): void {
    this.isPublic = this.router.url.includes('public');

    console.log(`url is ${this.router.url}`);
    console.log(`this.isPublic ${this.isPublic}`);
    if (this.isPublic) {
      this.route.params.subscribe((params) => {
        this.containerUUID = params['containerUUID']; //`fe5329eb-b27b-4949-85b5-b21ff4de0897`;// params['containerUUID'];
        console.log(`UUID is ${this.containerUUID}`);
        if (this.containerUUID) {
          this.containerId = this.containerUUID;
        }
        this.loadPublicData();
      });
    } else {
      this.containerId =
        this.measurementSharedDataService.getContainerIdStorage();
      this.loadPrivateData();
    }
  }

  /* loadPublicData() {
    //getContainerImagesByContainerUUID
    console.log(`LoadPublic Data`);
    this.isLoading = true;
    this.containerService
      .getContainerImagesByContainerUUID(this.containerId)
      .subscribe(
        (data) => {
          this.images = data;
        },
        (error) => {
          this.toastMessageService.showErrorMessage(
            'Error',
            'Error occured while fetching photos'
          );
        },
        () => {
          this.isLoading = false;
        }
      );
  } */
  loadPublicData() {
    console.log(`LoadPublic Data`);
    this.isLoading = true;

    this.containerService
      .getContainerImagesByContainerUUID(this.containerId)
      .pipe(
        finalize(() => (this.isLoading = false)) // this will be run after success or error
      )
      .subscribe(
        (data) => {
          this.images = data;
        },
        (error) => {
          this.toastMessageService.showErrorMessage(
            'Error',
            'Error occured while fetching photos'
          );
        }
      );
  }

  loadPrivateData() {
    console.log(`Load Private Data`);
    this.isLoading = true;

    this.containerService
      .getContainerImages(this.containerId)
      .pipe(
        finalize(() => (this.isLoading = false)) // this will be run after success or error
      )
      .subscribe(
        (data) => {
          this.images = data;
        },
        (error) => {
          this.toastMessageService.showErrorMessage(
            'Error',
            'Error occured while fetching photos'
          );
        }
      );
  }
}
