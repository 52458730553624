import {
   AfterViewInit,
   Component,
   EventEmitter,
   HostListener,
   Input,
   OnInit,
   Output,
   ViewChild,
} from '@angular/core';
import { MenuItem, Message, MessageService, PrimeNGConfig } from 'primeng/api';
import {
   FormGroup,
   FormBuilder,
   Validators,
   ValidationErrors,
   FormArray,
} from '@angular/forms';
import { NameCheckValidator } from 'src/app/validators/name-check-validator';
import { PopulateDropdownService } from 'src/app/services/populate-dropdown.service';
import { ConfirmationService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { Container } from '../model/container';
import { ContainerService } from '../services/container.service';
import { ContainerSharedDataService } from '../services/container-shared-data.service';
import { Seller } from '../../seller/model/seller';
import { Product } from '../../product/model/product';
import { LoadingSite } from '../../loading-site/model/loading-site';
import { Country } from '../../commons/location-selection/model/country';
import { LocationSelectionService } from '../../commons/location-selection/service/location-selection.service';
import { ConfirmDeleteCommonService } from 'src/app/commons/services/confirm-delete-common.service';
import { ResetCommonService } from 'src/app/commons/services/reset-common.service';
import { ROOTModule } from '../../root/root.module';
// import { AppModule } from 'src/app/app.module';
import { SHARED_MODULES } from '../../shared-imports';
import { TallySheetFourComponent } from '../../tally-sheet/tally-sheet-four/tally-sheet-four.component';
import { ProductComponent } from '../../product/product/product.component';
import { SellerComponent } from '../../seller/seller/seller.component';
import { LoadingSiteComponent } from '../../loading-site/loading-site/loading-site.component';
import { ConfirmPopup } from 'primeng/confirmpopup';
import { FileUpload } from 'primeng/fileupload';
import { AppConstants } from 'src/app/commons/app-constants';
import { PrivacyType } from 'src/app/commons/enums/privacy-type';
import { AttachmentComponent } from '../attachment/attachment.component';
import { SquaredTallysheetComponent } from '../squared-tallysheet/squared-tallysheet.component';
import { EncryptionService } from 'src/app/services/encryption.service';
// import { ProductComponent } from '../../product/product/product.component';
// import { ProductComponent } from './modules/product/product/product.component';

@Component({
   selector: 'app-container',
   templateUrl: './container.component.html',
   styleUrls: ['./container.component.css'],
   providers: [ToastMessagesService],
   imports: [SHARED_MODULES, ROOTModule, TallySheetFourComponent, ProductComponent, SellerComponent, LoadingSiteComponent, AttachmentComponent,
      SquaredTallysheetComponent
   ],
   standalone: true
})
export class ContainerComponent implements OnInit, AfterViewInit {

   groupedContainerType: any;
   selectedContainer!: any;
   portOfLoadingMismatchVisible = false;
   selectedSeaPort!: any | undefined;
   selectedFumigationType!: any | undefined;
   selectedCountry!: Country | undefined;
   countrySuggestions: Country[] = [];
   isFromContainerSidebar = true;
   containerSidebarVisible: boolean = false;
   productSidebarVisible: boolean = false;
   supplierSidebarVisible: boolean = false;
   loadingSiteSidebarVisible: boolean = false;
   isAddLoadingSiteFromContainer: boolean = false;
   mismatchContainers!: any[];
   blockedDocument = false;


   disabledStyles = {
      // 'opacity': '0.5', 
      cursor: 'not-allowed',
   };

   @Input() isAddLoadingSiteFromMeasurement: boolean = false;
   activeTabIndex: number = 0;

   @Input() showBreadcrumb: boolean = true;

   @Input() titleFromSidebar!: string;
   @Input() headerFromSidebar!: string;
   @Input() subHeaderFromSidebar!: string;
   @Input() isSubmittedFromSidebar: boolean = false;
   @Output() containerSubmit: EventEmitter<any> = new EventEmitter();

   editMode = false;
   sellers!: Seller[];
   products!: Product[];
   loadingSites!: LoadingSite[];
   isSubmitting = false;
   addEditButton = 'Save';
   containerEditData!: Container;
   seaPortsSuggestion: any;
   fumigationTypeSuggestion: any;
   initialContainerNumber!: string;
   initialSealNumber!: string;
   images: any[];// = ['https://rikexim.com/assets/images/resources/rikexim_200x50_dark.webp', 'https://rikexim.com/assets/images/resources/rikexim_200x50_dark.webp']; // Add this property
   @ViewChild(ConfirmPopup) confirmPopupDeleteImage!: ConfirmPopup;


   saveForm() {
      throw new Error('Method not implemented.');
   }
   tallysheetSummary!: any;
   tallysheetRecord!: any;
   tallysheetSquareRecord!: any;

   formHeader!: string;
   formSubHeader!: string;
   protected items!: MenuItem[];
   home!: MenuItem;
   containerForm!: FormGroup;
   //form: any;
   loading: boolean = false;



   constructor(
      private fb: FormBuilder,
      private nameValidator: NameCheckValidator,
      private populateDropdownService: PopulateDropdownService,
      private containerService: ContainerService,
      private messageService: MessageService,
      private confirmationService: ConfirmationService,
      private router: Router,
      private containerSharedDataService: ContainerSharedDataService,
      private titleService: Title,
      private route: ActivatedRoute,
      private toastService: ToastMessagesService,
      private locationSelectionService: LocationSelectionService,
      private confirmDeleteServiceCommon: ConfirmDeleteCommonService,
      private resetServiceCommon: ResetCommonService,
      private config: PrimeNGConfig,
      private encryptionService: EncryptionService
   ) {
      this.populateDropDown();
      this.containerService.createContainerType();

   }

   ngAfterViewInit(): void {

   }
   ngOnInit(): void {
      this.fragmentNavigation();
      this.messages = [
         { severity: 'success', summary: 'Success', detail: 'Message Content' },
      ];

      this.populateBreadCrumb();

      this.home = { icon: 'pi pi-home', routerLink: '/' };
      this.createForm();
      const url = this.router.url;
      this.groupedContainerType = this.containerService.groupedContainerType;
      if (url.includes('edit/container')) {
         this.editMode = true;
         this.route.data.subscribe((data: any) => {
            console.log(`Container Data from Resolver to Component is ${JSON.stringify(data)}`);
            this.containerEditData = data.container.tallysheetSummary.container;
            this.tallysheetSummary = data.container.tallysheetSummary;
            this.tallysheetRecord = data.container.tallysheetRecord;
            this.tallysheetSquareRecord = data.container.tallysheetSquareRecord;
            // alert(`@@ Data is ${JSON.stringify( this.containerEditData)}`);

            console.log(`Data ** Container is ${JSON.stringify(this.containerEditData)}`);
            console.log(`Data ** Tallysheet Summary is ${JSON.stringify(this.tallysheetSummary)}`);
            console.log(`Data ** Tallysheet Record is ${JSON.stringify(this.tallysheetRecord)}`);


            /*  this.containerEditData =
               this.containerSharedDataService.getDataFromLocalStorage();
             console.log(
               `Shared Data ContainerComponent is ${JSON.stringify(
                 this.containerEditData
               )}`
             ); */
            this.containerForm.patchValue(this.containerEditData);
            this.fetchFumigationType(this.containerEditData.fumigation);
            this.fetchSeaPorts(this.containerEditData.seaPort);
            this.fetchCountries(this.containerEditData.countryOfOrigin);

            this.initialContainerNumber = this.containerEditData.containerNumber;
            this.initialSealNumber = this.containerEditData.sealNumber;
            console.log(`initial Container Number is ${this.initialContainerNumber}`);
            const a = this.selectContainer(this.containerEditData.containerType);
            this.selectCountry(this.containerEditData.countryOfOrigin);
            if (this.selectedFumigationType) {
               this.containerForm.get('fumigation').setValue(this.selectedFumigationType);
            }

            this.containerForm!.get('containerNumber')!.setAsyncValidators([]);
            this.containerForm!.get('sealNumber')!.setAsyncValidators([]);
            this.addEditButton = 'Update';

         },
            (error: any) => { },
            () => { }
         );
      } else {
         this.fetchSeaPorts();
         this.fetchFumigationType();
         this.fetchCountries();
      }
      this.setPageTitle();

      this.initSidebar();
      this.isFromContainerSidebar = false;



      // this.fetchSeaPorts();
      // this.fetchFumigationType();
   }

   private createForm() {
      this.containerForm = this.fb.group({
         containerId: [],
         privacy: ['PRIVATE'],
         containerNumber: [
            '',
            [
               Validators.required,
               Validators.minLength(5),
               Validators.maxLength(16),
            ],
            // this.editMode ? null : [this.nameValidator.checkContainerNumberValidator()],

            [
               this.nameValidator.checkContainerNumberValidator(
                  this.initialContainerNumber
               ),
            ],
         ],
         sealNumber: [
            '',
            [
               //Validators.required,
               Validators.minLength(5),
               Validators.maxLength(16),
            ],
            [
               this.nameValidator.checkContainerSealValidator(
                  this.initialSealNumber
               ),
            ],
         ],
         productId: ['', Validators.required],
         supplierId: [], //, [Validators.required]],
         loadingSiteId: [], //, [Validators.required]],
         containerType: [],
         seaPort: [],
         fumigation: [],
         countryOfOrigin: [],
         billOfLading: [],
         documents: [null]
      });
   }

   private populateDropDown() {

      this.populateDropdownService
         .getSuppliers()
         .subscribe((sellers) => (this.sellers = sellers));

      this.populateDropdownService
         .getProducts()
         .subscribe((products) => (this.products = products));

      this.populateDropdownService
         .getLoadingSites()
         .subscribe((loadingSites) => (this.loadingSites = loadingSites));
   }

   populateBreadCrumb() {
      this.items = [
         { label: 'Containers', routerLink: '/containers' },
         { label: 'Add Container' },
      ];
   }

   onSubmit2(containerMismatchContinueAnyway: boolean = false) {
      console.log(`onSubmit called ${JSON.stringify(this.containerForm.value)}`);
   }
   onSubmit(containerMismatchContinueAnyway: boolean = false) {
      this.isSubmitting = true;

      this.getFormValidationErrors();
      Object.keys(this.containerForm.controls).forEach((key) => {
         //console.log(`Key is ${key}`)
         //  this.containerForm.get(key)?.updateValueAndValidity();
      });
      this.containerForm.markAllAsTouched();
      const controlKeys = Object.keys(this.containerForm.controls);

      const invalidControlKeys = controlKeys.filter((key) => {
         const control = this.containerForm.controls[key];
         return control.invalid;
      });
      console.log('Invalid control keys:', invalidControlKeys);
      console.log(this.containerForm.value);

      if (this.containerForm.valid && !this.editMode) {
         console.log(this.containerForm.value);
         this.blockedDocument = true;
         ///
         this.containerService.postData(this.containerForm.value).subscribe(
            (resp) => {
               console.log(`On Submit Response is ${JSON.stringify(resp)}`);
               if (this.editMode) {
                  this.toastService.showSuccessMessage(
                     'Success',
                     'Container Edited Successfully'
                  );
                  this.containerEditData = resp;
                  //  this.router.navigate(['/containers']);
               } else {
                  //  this.onReset();
                  this.toastService.showSuccessMessage(
                     'Success',
                     'Container Created Successfully'
                  );
                  this.containerEditData = resp;

                  if (!this.isSubmittedFromSidebar) {
                     // this.confirmationService.confirm({
                     //    message: 'Do you want to add more container?',
                     //    accept: () => {
                     //       this.containerForm.reset();
                     //    },
                     //    reject: () => {
                     //       this.containerForm.reset();
                     //       this.router.navigate(['/containers']);
                     //    },
                     // });
                  } else {
                     this.containerSubmit.emit();
                     this.containerForm.reset();
                  }
               }
               this.blockedDocument = false;
               // resp.containerId
               // path: 'add/container/:id',
               
               this.encryptionService.encrypt("" + resp.containerId).then((encryptedId) => {
                  // this.router.navigate(['/edit/buyer', encryptedId]);
                  this.router.navigate([`/edit/container/${encryptedId}`]);
               });
            },
            (error) => {
               console.log(`On Submit error is ${JSON.stringify(error)}`);
               this.blockedDocument = false;

               this.toastService.showErrorMessage('Error', error?.error?.message ?? 'Error while saving');
               // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
            },
            () => {
               console.log(`Finish onSubmit`);
               this.blockedDocument = false;
            }
         );

         ///
      } else if (this.containerForm.valid && this.editMode) {
         console.log(this.containerForm.value);
         this.blockedDocument = true;

         ///
         this.containerService
            .updateContainer(
               this.containerForm.value,
               containerMismatchContinueAnyway
            )
            .subscribe(
               (resp) => {
                  console.log(`On Submit Response is ${JSON.stringify(resp)}`);
                  if (this.editMode) {
                     this.toastService.showSuccessMessage(
                        'Success',
                        'Container Edited Successfully'
                     );
                     this.router.navigate(['/containers']);
                  } else {
                     this.toastService.showSuccessMessage(
                        'Success',
                        'Container Created Successfully'
                     );

                     this.confirmationService.confirm({
                        message: 'Do you want to add more container?',
                        accept: () => {
                           this.containerForm.reset();
                        },
                        reject: () => {
                           this.router.navigate(['/containers']);
                        },
                     });
                  }
                  this.blockedDocument = false;
               },
               (error) => {
                  console.log(`On Submit error is ${JSON.stringify(error)}`);

                  if (error.error.mismatchedContainerDetails) {
                     this.mismatchContainers = error.error.mismatchedContainerDetails;
                     this.portOfLoadingMismatchVisible = true;
                  } else {
                     this.toastService.showErrorMessage('Error', error.error.message);
                  }
                  this.blockedDocument = false;

                  // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
               },
               () => {
                  console.log(`Finish onSubmit`);
                  this.blockedDocument = false;
               }
            );

         ///
      } else {
         console.error(`Form is not valid`);
         this.blockedDocument = false;
      }
   }

   setPageTitle() {
      console.log(`Settitle from container`);
      this.route.data.subscribe((data) => {
         this.titleService.setTitle(data['title']);
         this.formHeader = data['header'];
         this.formSubHeader = data['subHeader'];
         console.log(`this.formHeader ${this.formHeader}`);
         console.log(`this.formSubHeader ${this.formSubHeader}`);
         console.log(`data['title'] ${data['title']}`);
      });
   }

   // nameValidation  is used while editMode=true, if name is changed check if already exist, if name is same as it was entered then ignore async validation
   // add even function (input) in html
   containerNumberValidation() {
      if (this.editMode) {
         const isValueChanged =
            this.containerEditData.containerNumber ==
            this.containerForm.get('containerNumber')?.value;
         if (!isValueChanged) {
            console.log(`is value changed isValueChanged}`);
            this.containerForm!.get('containerNumber')!.setAsyncValidators([
               this.nameValidator.checkContainerNumberValidator(),
            ]);
         } else {
            this.containerForm!.get('containerNumber')!.setAsyncValidators([]);
            this.containerForm.markAllAsTouched();
            this.containerForm.get('containerNumber')?.updateValueAndValidity();
            console.log(`is not value changed isValueChanged}`);
         }
      }
   }

   convertToUpperCase() {
      let tempContainerNumber = this.containerForm
         .get('containerNumber')
         ?.value.toUpperCase();
      console.log(
         `Upper Case of ContainerNumber is tempContainerNumber ${tempContainerNumber}`
      );
      this.containerForm.patchValue({ containerNumber: tempContainerNumber });
   }

   containerSealValidation() {
      console.log(`containerSealValidation `);
      if (this.editMode) {
         const isValueChanged =
            this.containerEditData.sealNumber ==
            this.containerForm.get('sealNumber')?.value;
         if (!isValueChanged) {
            console.log(`is value changed isValueChanged}`);
            this.containerForm!.get('sealNumber')!.setAsyncValidators([
               this.nameValidator.checkContainerNumberValidator(),
            ]);
         } else {
            this.containerForm!.get('sealNumber')!.setAsyncValidators([]);
            this.containerForm.markAllAsTouched();
            this.containerForm.get('sealNumber')?.updateValueAndValidity();
            console.log(`is not value changed isValueChanged}`);
         }
      }
   }

   getErrorMessage() {
      let myFieldControl = this.containerForm.get('containerNumber');
      if (myFieldControl!.hasError('required')) {
         return 'This field is required.';
      }
      if (myFieldControl!.hasError('invalid')) {
         return 'This field is invalid.';
      }
      //console.log(`Error ${JSON.stringify(myFieldControl?.hasError)} `)
      return 'Unknown error.';
   }

   getFormValidationErrors() {
      Object.keys(this.containerForm.controls).forEach((key) => {
         console.log(
            `Each key is ${key} value is ${this.containerForm!.get(key)?.value
            } and hasError ${this.containerForm!.get(key)!.errors!}`
         );
         const controlErrors: ValidationErrors =
            this.containerForm!.get(key)!.errors!;

         if (controlErrors != null) {
            Object.keys(controlErrors).forEach((keyError) => {
               console.log(
                  'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
                  controlErrors[keyError]
               );
            });
         }
      });
   }

   onProductSubmit(event: any) {
      this.populateDropDown();
   }

   onSupplierSubmit(event: any) {
      this.populateDropDown();
   }
   onLoadingSiteSubmit(event: any) {
      this.populateDropDown();
   }

   onKeyUpToUppercase(event: any) {
      console.log(`onKeyUpToUppercase event ${event.value}`);
      const value = event.target.value;
      this.containerForm.patchValue({
         containerNumer: value.toUpperCase(),
      });
   }
   messages!: Message[];

   get isValidationInProgress(): boolean {
      return this.nameValidator.isContainerNumberValidationInProgress;
   }

   get isSealValidationInProgress(): boolean {
      return this.nameValidator.isContainerSealValidationInProgress;
   }

   initSidebar() {
      console.log(`initSidebar  `);
      console.log(`initSidebar ${this.isSubmittedFromSidebar} `);
      if (this.isSubmittedFromSidebar) {
         console.log(`initSidebar  ${this.headerFromSidebar}`);
         this.formHeader = this.headerFromSidebar;
         this.formSubHeader = this.subHeaderFromSidebar;
         this.titleService.setTitle(this.titleFromSidebar);
      }
   }

   onCountrySelection(event: any) {
      console.log(
         `Country selected is ${JSON.stringify(event.value.countryName)}`
      );
      console.log(
         `Country selected geonameId is ${JSON.stringify(event.value.geonameId)}`
      );
   }

   private fetchCountries(selected?: any): void {
      this.locationSelectionService.getCountries().subscribe(
         (countries) => {
            this.countrySuggestions = countries;
         },
         (error) => console.log(error),
         () => {
            if (selected) {
               // console.log(`Selected Country is to be ${JSON.stringify(selected)}`);
               const found = this.countrySuggestions.find(
                  (country: { countryName: string }) => {
                     // console.log(`Country name is ${JSON.stringify(country)}`);
                     return country.countryName === selected.countryName; // You need to return this comparison
                  }
               );
               if (found) {
                  this.containerForm.patchValue({ countryOfOrigin: found }); // patch the form value
                  this.selectedCountry = found; // update the selectedCountry for the dropdown
               }
            }
         }
      );
   }

   private fetchSeaPorts(selected?: any) {
      this.containerService.getSeaPorts().subscribe(
         (data: any) => {
            // console.log(`searports response is ${JSON.stringify(data)}`);
            this.seaPortsSuggestion = data;
            if (selected) {
               console.log(`Selected ${JSON.stringify(selected)}`);
               this.containerForm.patchValue({ seaPort: selected });
            }
         },
         (error: any) => { }
      );
   }

   private fetchFumigationType(selected?: any) {
      this.containerService.getFumigationType().subscribe(
         (data: any) => {
            // console.log(`FumigationType response is ${JSON.stringify(data)}`);
            this.fumigationTypeSuggestion = data;
            if (selected) {
               // console.log(`Selected ${JSON.stringify(selected)}`);
               this.containerForm.patchValue({ fumigation: selected });
            }
         },
         (error: any) => { }
      );
   }

   /* private selectContainer(
     selectedContainer: any
   ): { label: string; value: string } | undefined {
     console.log(`selectContainer `);
     for (let group of this.groupedContainerType) {
       console.log(`this.groupedContainerType `);
       const found = group.items.find((item: { label: any }) => {
         console.log(`item.label ${item.label} === ${selectedContainer}`);
         return item.label === selectedContainer; // add return statement here
       });
       if (found) {
         return found;
       }
     }
     return undefined;
   } */

   private findMatchingItem<T extends { name: string }>(items: T[], target: T): T | undefined {
      return items.find(item => item.name === target.name);
   }

   private selectContainer(containerType: string): void {
      for (const group of this.groupedContainerType) {
         const matchingContainer = group.items.find(item => item.value === containerType);
         if (matchingContainer) {
            this.selectedContainer = matchingContainer;
            this.containerForm.patchValue({ containerType: matchingContainer.value });
            console.log(`Selected Container is ${JSON.stringify(matchingContainer)}`);
            break;
         }
      }
   }



   private selectCountry(selectedCountry: string): void {
      console.log(
         `selectCountry selectedCountry is ${JSON.stringify(selectedCountry)}`
      );
      const found = this.countrySuggestions.find(
         (country: { countryName: string }) => {
            console.log(`Country name is ${JSON.stringify(country)}`);
            console.log(
               `selectedCountry name is ${JSON.stringify(selectedCountry)}`
            );
            country.countryName === selectedCountry;
         }
      );

      if (found) {
         this.containerForm.patchValue({ countryOfOrigin: found }); // patch the form value
         this.selectedCountry = found; // update the selectedCountry for the dropdown
      }
   }

   test() {
      console.log(`TEST**** current value ${this.containerForm.get('containerNumber')?.value
         } = 
    initial value = ${this.initialContainerNumber}`);
      if (
         this.editMode &&
         this.initialContainerNumber ===
         this.containerForm.get('containerNumber')?.value
      ) {
         console.log(`in if ****** 1`);
         this.containerForm.get('containerNumber')?.clearAsyncValidators();
         this.containerForm.get('containerNumber')?.updateValueAndValidity(); // updat
      } else {
         this.containerForm
            .get('containerNumber')
            ?.setAsyncValidators([
               this.nameValidator.checkContainerNumberValidator(),
            ]);
      }
      if (
         this.editMode &&
         this.initialSealNumber === this.containerForm.get('sealNumber')?.value
      ) {
         this.containerForm.get('sealNumber')?.clearAsyncValidators();
         this.containerForm.get('sealNumber')?.updateValueAndValidity();
      } else {
         this.containerForm
            .get('sealNumber')
            ?.setAsyncValidators([this.nameValidator.checkSellerNameValidator()]);
      }
   }

   continueAnywayContainerMismatch() {
      this.onSubmit(true);
   }

   private executeDeleteService(id: any): Promise<void> {
      return new Promise((resolve, reject) => {
         // Call your actual delete service here
         console.log(`id is ${id}`);
         this.blockedDocument = true;

         this.containerService.deleteContainers(id).subscribe(
            () => {
               resolve();
               this.blockedDocument = false;
            },
            (error) => {
               reject(error);
               this.blockedDocument = false;
            }
         );
      });
   }

   onDelete(id: any): void {
      this.containerForm.markAsPristine();
      this.confirmDeleteServiceCommon.confirm(
         id,
         () => this.executeDeleteService(id),
         '/containers'
      );
   }

   onReset() {
      console.log('onReset called in buyer');
      this.resetServiceCommon.resetForm(this.containerForm);
   }


   onTabChange(index: number) {
      this.activeTabIndex = index;
      if (this.activeTabIndex === 0) {
         this.formHeader = 'Container Details';
         this.formSubHeader = 'Enter Container Details';
         this.router.navigate([], { fragment: 'container' });

      }
      else if (this.activeTabIndex === 1) {
         this.formHeader = 'Container Attachments';
         this.formSubHeader = 'Upload Container Attachments';
         //container
         this.router.navigate([], { fragment: 'attachment' });

      }
      if (index === 2) {
         // this.loadStoredFiles();
         this.formHeader = 'Container Tallysheet';
         this.formSubHeader = 'Add Container Tallysheet Records';
         this.router.navigate([], { fragment: 'tallysheet' });
      }
   }

   private fragmentNavigation() {
      this.route.fragment.subscribe((fragment: string | null) => {
         console.log('URL Fragment:', fragment);
         if (fragment === 'tallysheet') {
            this.activeTabIndex = 2; // Tab indices start from 0, so Tab 3 is index 2
         }
         else if (fragment === 'attachment') {
            this.activeTabIndex = 1; // Tab indices start from 0, so Tab 3 is index 2
         }
         else if (fragment === 'container') {
            this.activeTabIndex = 0; // Tab indices start from 0, so Tab 3 is index 2
         } else {
            this.activeTabIndex = 0; // Tab indices start from 0, so Tab 3 is index 2
         }
      });
   }

   @HostListener('window:keydown.meta.shift.s', ['$event'])
   handleSaveShortcut(event: KeyboardEvent) {
      this.onSubmit();
   }

   @HostListener('window:keydown.meta.shift.d', ['$event'])
   handleDeleteShortcut(event: KeyboardEvent) {
      if (this.editMode) {
         this.onDelete(this.containerEditData.containerId);
      }
   }

}
