<!-- <p-toast></p-toast> -->
<p-blockUI [blocked]="blockedDocument">
    <i class="pi pi-spin pi-spinner" style="font-size: 4rem !important"></i>
</p-blockUI>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" acceptLabel="Yes"
    rejectLabel="No"></p-confirmDialog>

<form [formGroup]="loadingSiteForm" (ngSubmit)="onSubmit()">

    <div class="pb-3" *ngIf="showBreadcrumb">
        <p-breadcrumb [model]="breadCrumbItems" [home]="breadCrumbHome"></p-breadcrumb>
    </div>

    <p-card class="align-content-center" [header]="formHeader" [subheader]="formSubHeader">

        <div class="formgrid grid">

            <!--   <div class="field col-12 sm:12 md:col-6 lg:col-6 xl:col-6">
                <label for="name">Name <i class="text-red-400">*</i></label>
                <input id="name" type="text" formControlName="name" pInputText (input)="nameValidation()" maxlength="64"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="loadingSiteForm.invalid && (loadingSiteForm.controls?.['name']?.invalid && (loadingSiteForm.controls?.['name']?.dirty || loadingSiteForm.controls?.['name']?.touched))">
                    <div *ngIf="loadingSiteForm.controls?.['name']?.errors?.['required']">Name is required.</div>
                    <div *ngIf="loadingSiteForm.controls?.['name']?.errors?.['isLoadingSiteNameExists'];">Name already
                        exists.</div>
                </div>
            </div>
            

            <div class="field col-12 sm:12 md:col-6 lg:col-6 xl:col-6">

                <label for="supplierId">Supplier
                </label>
                <div class="p-inputgroup">
                    <p-dropdown [options]="supplierIdList" formControlName="supplierId" optionLabel="name"
                        [filter]="true" [lazy]="true" styleClass="field col-12 w-full" [virtualScroll]="true"
                        [virtualScrollItemSize]="38" [lazy]="true" placeholder="Select Supplier"
                        [showClear]="true"></p-dropdown>
                    <p-button icon="pi pi-plus-circle" (click)="supplierSidebarVisible = true"
                        pTooltip="Add new product" tooltipPosition="left"
                        styleClass="p-button-rounded p-button-secondary p-button-text" pTooltip="Add new product"
                        tooltipPosition="top" [showDelay]="200" *ngIf="!isAddFromContainer"></p-button>
                </div>
            </div> -->

            <div class="field col-12 sm:col-12 md:col-12 lg:col-4 xl:col-4">

                <label for="name">Loading Site Name<i class="ml-2 text-red-400">*</i></label>
                <input id="name" type="text" formControlName="name" pInputText maxlength="64"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="loadingSiteForm.controls?.['name']?.invalid && (loadingSiteForm.controls?.['name']?.dirty || loadingSiteForm.controls?.['name']?.touched)">
                    <div *ngIf="loadingSiteForm.controls?.['name']?.errors?.['minlength']">Min Length is 3
                    </div>
                    <div *ngIf="loadingSiteForm.controls?.['name']?.errors?.['required']">Loading Site Name is required
                    </div>
                    <div *ngIf="loadingSiteForm.controls?.['name']?.errors?.['maxlength']">Max Length is 64
                    </div>
                    <div *ngIf="loadingSiteForm.controls?.['name']?.errors?.['isLoadingSiteNameExists'];">Name already
                        exists.</div>
                </div>
            </div>

            <div class="field col-12 sm:col-6 md:col-6 lg:col-4 xl:col-4">
                <label for="contactPerson">Contact Person</label>
                <input id="contactPerson" type="text" formControlName="contactPerson" pInputText maxlength="32"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>

            <div class="field col-12 sm:col-6 md:col-6 lg:col-4 xl:col-4">

                <label for="phone">Phone</label>
                <input id="phone" type="tel" formControlName="phone" pInputText maxlength="20"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>
            <div class="field col-12">
                <app-location-selection formControlName="location" (locationFormStatus)="onLocationFormStatus($event)"
                    [locationFormValidators]="locationFormValidators" [existingLocationData]="existingLocationData"
                    [isAddress2Visible]="true" [isAddress2Optional]="true" [isZipOptional]="true"
                    [isStateOptional]="true" [isCityOptional]="true"></app-location-selection>
            </div>
            <div class="field col-12 sm:col-6 md:col-6 lg:col-3 xl:col-3">

                <label for="operatingHours">Operating Hours</label>
                <input id="operatingHours" type="text" formControlName="operatingHours" pInputText maxlength="15"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>

            <div class="field col-12 sm:col-6 md:col-6 lg:col-3 xl:col-3">

                <label for="capacity">Capacity</label>
                <input id="capacity" type="text" formControlName="capacity" pInputText maxlength="20"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>

            <div class="field col-12 sm:col-6 md:col-6 lg:col-3 xl:col-3">
                <label for="currentVolume">Current Volume</label>
                <input id="currentVolume" type="text" formControlName="currentVolume" pInputText maxlength="20"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>
            <div class="field col-12 sm:col-6 md:col-6 lg:col-3 xl:col-3">

                <label for="machinesInstalled">Machine(s) Installed</label>
                <input id="machinesInstalled" type="text" formControlName="machinesInstalled" pInputText maxlength="64"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>
            <div class="field col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
                <label for="notes">Notes</label>
                <textarea id="notes" name="notes" type="text" formControlName="notes" pInputTextarea maxlength="2048"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                    style="height:80%">
                </textarea>
                <div class="text-red-400"
                    *ngIf="loadingSiteForm.controls?.['notes']?.invalid && (loadingSiteForm.controls?.['notes']?.dirty || loadingSiteForm.controls?.['notes']?.touched)">
                    <div *ngIf="loadingSiteForm.controls?.['notes']?.errors?.['minlength']">Min Length 3 characters
                    </div>
                    <div *ngIf="loadingSiteForm.controls?.['notes']?.errors?.['maxlength']">Max Length 512 characters
                    </div>
                </div>
            </div>

        </div>
        <ng-template pTemplate="footer">
            <!-- <p-button [label]="addEditButton" [disabled]="!loadingSiteForm.dirty" icon="pi pi-save"
                styleClass="p-button-success w-9rem" type="submit"> </p-button>

            <p-button label="Reset" icon="pi pi-sync" styleClass="p-button-secondary  w-9rem" *ngIf="!editMode"
                [style]="{'margin-left': '.5em'}" (onClick)="onReset()"></p-button>

            <p-button label="Delete" icon="pi pi-trash" styleClass="p-button-danger  w-9rem" *ngIf="editMode"
                [style]="{'margin-left': '.5em'}" (onClick)="onDelete(this.loadingSiteData.loadingSiteId)"></p-button> -->
            <div class="grid">
                <div class="col-fixed" class="sm:w-12rem md:w-12rem lg:w-12rem xl:w-12rem">
                    <div class="text-center p-3 font-bold">
                        <p-button [label]="addEditButton" [disabled]="!loadingSiteForm.dirty" icon="pi pi-save"
                            styleClass="p-button-success w-9rem" type="submit"> </p-button>
                    </div>
                </div>
                <div class="col-fixed" class="sm:w-12rem md:w-12rem lg:w-12rem xl:w-12rem">
                    <div class="text-center p-3 font-bold">
                        <p-button label="Reset" icon="pi pi-sync" styleClass="p-button-secondary  w-9rem"
                            *ngIf="!editMode" [style]="{'margin-left': '.5em'}" (onClick)="onReset()"></p-button>

                        <p-button label="Delete" icon="pi pi-trash" styleClass="p-button-danger  w-9rem"
                            *ngIf="editMode" [style]="{'margin-left': '.5em'}"
                            (onClick)="onDelete(this.loadingSiteData.loadingSiteId)"></p-button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-card>
</form>



<p-sidebar [(visible)]="supplierSidebarVisible" position="right" [style]="{'width': '50vw'}" (onHide)="setPageTitle()">
    <app-seller [isFromContainerSidebar]="true" *ngIf="supplierSidebarVisible" [showBreadcrumb]="false"
        [isSubmittedFromSidebar]="true" (supplierSubmit)="onSupplierSubmit($event)" titleFromSidebar="Add New Supplier"
        headerFromSidebar="Create Seller" subHeaderFromSidebar="Input new seller details"></app-seller>
</p-sidebar>