import { Location } from "../../commons/location-selection/model/location";

export class Seller {
    supplierId!: number;
    name!: string;
    reference!: string;
    phone1!: string;
    phone2!: string;
    address!: string;
    ghanaId!: string;
    identityLink1!: string;
    identityLink2!: string;
    identityLink3!: string;
    notes!: string;
    contactPerson!: string;
    location!: Location
}
