import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class AddHoppusMeasurementv2Service {
  constructor(private httpClient: HttpClient) {}

  createHoppusTimber(data: any): Observable<any> {
    const url = `${AppConstants.API_URL}api/v2/hoppus-rows`;
    console.log(`createHoppusTimber postHoppusTimber url is ${url}`);
    console.log(
      `createHoppusTimber postHoppusTimber data is ${JSON.stringify(data)}`
    );
    return this.httpClient.post<any>(url, data, { responseType: 'json' });
    // return of(null);
  }

  updateHoppusTimber(data: any, hoppusTimberId: number): Observable<any> {
    const url = `${AppConstants.API_URL}api/v2/hoppus-rows/${hoppusTimberId}`;
    console.log(`updateHoppusTimber postHoppusTimber url is ${url}`);
      console.table(data.rows);

    return this.httpClient.put<any>(url, data, { responseType: 'json' });
    // return of(null);
  }

  // /container/{containerId}/sawn-timber-id-exists

  isSawnTimberExist(containerId: number): Observable<boolean> {
    const url = `${AppConstants.API_URL}api/v1/container/${containerId}/sawn-timber-id-exists`;
    console.log(`isSawnTimberExist url is ${url}`);
    return this.httpClient.get<boolean>(url);
  }

  // /container/{containerId}/sawn-timber-id

  getHoppusTimberId(containerId: number): Observable<number> {
    const url = `${AppConstants.API_URL}api/v2/container/${containerId}/hoppus-timber-id`;
    console.log(`getHoppusTimberId url is ${url}`);
    return this.httpClient.get<number>(url);
  }

  getTimbers(sawnTimberId: number): Observable<any> {
    const url = `${AppConstants.API_URL}api/v1/sawn-timber/${sawnTimberId}`;
    console.log(`getTimbers url is ${url}`);
    return this.httpClient.get<any>(url);
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  } //sawn-timber-config

  //"/sawn-timber-summary/{container-id}"

  getSawnTimberSummary(containerId: number): Observable<any> {
    const url = `${AppConstants.API_URL}api/v1/sawn-timber-summary/${containerId}`;
    console.log(`getTimbers Summary url is ${url}`);
    return this.httpClient.get<any>(url);
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  getHoppusTimbersFromContainerId(containerId: number): Observable<any> {
    const url = `${AppConstants.API_URL}api/v2/hoppus-timber-container-id/${containerId}`;
    console.log(`getHoppusTimbersFromContainerId url is ${url}`);
    return this.httpClient.get<any>(url);
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  } //sawn-timber-config

  updateHoppusTimberConfig(
    hoppusTimberConfigId: number,
    data: any
  ): Observable<any> {
    const url = `${AppConstants.API_URL}api/v2/hoppus-timber-config/${hoppusTimberConfigId}`;
    console.log(
      `updateHoppusTimberConfig url is ${url} data ${JSON.stringify(
        data,
        null,
        2
      )}`
    );
    return this.httpClient.put<any>(url, data, { responseType: 'json' });
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  } // sawn-timber-config

  createHoppusTimberConfig(data: any): Observable<any> {
    const url = `${AppConstants.API_URL}api/v2/hoppus-timber-config`;
    console.log(`updateHoppusTimberConfig url is ${url}`);
    return this.httpClient.post<any>(url, data, { responseType: 'json' });
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  getLazyLoadingVirtualPackinglist(
    containerId: number,
    pageNumber: number,
    pageSize: number
  ): Observable<any> {
    ///hoppus-timber-rows/lazy-load/{container-id}")
    ///sawnTimberRows/{containerId} // /sawn-timber-rows/lazy-load
    const url = `${AppConstants.API_URL}api/v2/hoppus-timber-rows/lazy-load/${containerId}?offset=${pageNumber}&limit=${pageSize}`;
    console.log(`getTimbers Virtual Scroll url is ${url}`);
    return this.httpClient.get<any>(url);
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  ////hoppus-timber-rows/lazy-load/{container-id}

  getPublicLazyLoadingHoppusTimberVirtualPackinglist(
    containerId: number,
    pageNumber: number,
    pageSize: number
  ): Observable<any> {
    ///hoppus-timber-rows/lazy-load/{container-id}")
    ///sawnTimberRows/{containerId} // /sawn-timber-rows/lazy-load
    const url = `${AppConstants.API_URL}public/api/v1/hoppus-timber-rows/lazy-load/${containerId}?offset=${pageNumber}&limit=${pageSize}`;
    console.log(`getTimbers Virtual Scroll url is ${url}`);
    return this.httpClient.get<any>(url);
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  getPublicLazyLoadingVirtualPackinglist(
    containerId: number,
    pageNumber: number,
    pageSize: number
  ): Observable<any> {
    // view packing list lazyloading virtual scroll implemented
    ///sawnTimberRows/{containerId} // /sawn-timber-rows/lazy-load
    const url = `${AppConstants.API_URL}public/api/v1/sawn-timber-rows/lazy-load/${containerId}?offset=${pageNumber}&limit=${pageSize}`;
    console.log(`getTimbers Virtual Scroll url is ${url}`);
    return this.httpClient.get<any>(url);
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  getContainerDetail(containerId: number) {
    // sawn-timber-container-detail
    const url = `${AppConstants.API_URL}api/v1/sawn-timber-container-detail/${containerId}`;
    console.log(`getContainerDetail url is ${url}`);
    return this.httpClient.get<any>(url);
  }

  getPublicContainerDetail(containerUuid: string | null) {
    // sawn-timber-container-detail
    const url = `${AppConstants.API_URL}public/api/v1/sawn-timber-container-detail/${containerUuid}`;
    console.log(`getContainerDetail url is ${url}`);
    return this.httpClient.get<any>(url);
  }

  getPublicSawnTimberSummary(containerUuid: any): Observable<any> {
    const url = `${AppConstants.API_URL}public/api/v1/sawn-timber-summary/${containerUuid}`;
    console.log(`getTimbers Summary url is ${url}`);
    return this.httpClient.get<any>(url);
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  getPublicTimbersFromContainerUUID(
    containerUuid: string | null
  ): Observable<any> {
    const url = `${AppConstants.API_URL}public/api/v1/sawn-timber-container-uuid/${containerUuid}`;
    console.log(`getTimbersFromContainerId url is ${url}`);
    return this.httpClient.get<any>(url);
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  getHoppusTimberSummary(containerId: number) {
    // sawn-timber-container-detail
    const url = `${AppConstants.API_URL}api/v2/hoppus-timber-summary-container-id/${containerId}`;
    console.log(`getHoppusTimberSummary url is ${url}`);
    return this.httpClient.get<any>(url);
  }

  updateReview(rowId: number, data: any) {
    //hoppusTimberRows/{id}/review
    const url = `${AppConstants.API_URL}api/v2/hoppusTimberRows/${rowId}/review`;
    console.log(`updateReview url is ${url}`);

    return this.httpClient.put<any>(url, data, { responseType: 'json' });
  }

  // New method with lazy-loading
  lazyLoadHoppusTimbers(
    containerId: number,
    pageNumber: number,
    pageSize: number
  ): Observable<any> {
    // Construct URL
    const url = `${AppConstants.API_URL}api/v2/lazy-load-hoppus-timbers/${containerId}?offset=${pageNumber}&limit=${pageSize}`;

    // Debugging line
    console.log(`lazyLoadHoppusTimbers URL is ${url}`);

    // Make the request
    return this.httpClient.get<any>(url);
  }

  getHoppusTimbersConfigContainerId(containerId: number): Observable<any> {
    const url = `${AppConstants.API_URL}api/v2/hoppus-timbers-config/${containerId}`;
    console.log(`getHoppusTimbersConfigContainerId url is ${url}`);
    return this.httpClient.get<any>(url);
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  getHoppusTimbersFromContainerIdFromIndex(containerId: number,fromIndex: number): Observable<any> {
    const url = `${AppConstants.API_URL}api/v2/hoppus-timber-container-id-from-index/${containerId}?fromIndex=${fromIndex}`;
    console.log(`getHoppusTimbersFromContainerIdFromIndex url is ${url}`);
    return this.httpClient.get<any>(url);
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  } //sawn-timber-config

  getHoppusTimberContainerDetails(containerId: number){
   
    const url = `${AppConstants.API_URL}api/v2/hoppus-timber-details-container/${containerId}`;
    console.log(`getHoppusTimberContainerDetails url is ${url}`);
    return this.httpClient.get<any>(url);
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  generateUUID() {
    return uuidv4(); // Generates and returns a new UUID
  }
}
