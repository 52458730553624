<div *ngIf="isLoading; else templateBody" class="spinner-container">
    <p-progressSpinner styleClass="w-6rem h-6rem"></p-progressSpinner>
</div>

<ng-template #templateBody>
    <p-table [value]="containerDetailsArray" styleClass="mt-5">
        <ng-template pTemplate="caption" class="text-center">
            Container Summary
        </ng-template>
        <ng-template pTemplate="header">
            <!-- <tr>
            <th>Property</th>
            <th>Value</th>
        </tr> -->
        </ng-template>
        <ng-template pTemplate="body" let-containerData>
            <tr>
                <td>{{containerData.property}}</td>
                <td>{{containerData.value}}</td>
            </tr>
        </ng-template>
    </p-table>



    <div style="padding: 1em 0;">
        <hr style="border-top: 1px solid #000;">
    </div>

    <!-- Width -->
    <p-table [value]="[widthSummary]" styleClass="mt-5" styleClass="p-datatable-gridlines">
        <ng-template pTemplate="caption" class="text-center">
            Width Overview
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Width</th>
                <th class="text-center" *ngFor="let item of widthSummary">{{item.dimension}}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td>Total Pieces</td>
                <td class="text-center" *ngFor="let item of rowData">{{item.totalPieces}}</td>
            </tr>
        </ng-template>
    </p-table>


    <div style="padding: 1em 0;">
        <hr style="border-top: 1px solid #000;">
    </div>
    <!-- Height -->
    <p-table [value]="[heightSummary]" styleClass="mt-5" styleClass="mt-5" styleClass="p-datatable-gridlines">
        <ng-template pTemplate="caption" class="text-center">
            Height Overview
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Height</th>
                <th class="text-center" *ngFor="let item of heightSummary">{{item.dimension}}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td>Total Pieces</td>
                <td class="text-center" *ngFor="let item of rowData">{{item.totalPieces}}</td>
            </tr>
        </ng-template>
    </p-table>

    <div style="padding: 1em 0;">
        <hr style="border-top: 1px solid #000;">
    </div>
    <!-- Length -->
    <p-table [value]="[lengthSummary]" styleClass="mt-5" styleClass="mt-5" styleClass="p-datatable-gridlines">
        <ng-template pTemplate="caption" class="text-center">
            Length Overview
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Length</th>
                <th class="text-center" *ngFor="let item of lengthSummary">{{item.dimension}}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td>Total Pieces</td>
                <td class="text-center" *ngFor="let item of rowData">{{item.totalPieces}}</td>
            </tr>
        </ng-template>
    </p-table>
</ng-template>