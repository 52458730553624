import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { MegaMenuItem } from 'primeng/api'; //required when using MegaMenu
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  Router,
} from '@angular/router';
import { LogoutService } from './services/logout.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PanelModule } from 'primeng/panel';
import { SideBarMenuSharedService } from './services/side-bar-menu-shared.service';
import { catchError, filter, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { FontSizeServiceService } from './services/font-size-service.service';
import { ToastModule } from 'primeng/toast';
import { SharedSubscriptionNotificationService } from './services/shared-subscription-notification.service';
import { UserService } from './services/user.service';
import { SubscriptionStatus } from './components/extras/subscription-status.enum';
import { AuthService } from './services/auth.service';
import { fadeAnimation } from './commons/fadeAnimation';
import { RouterOutlet, NavigationStart } from '@angular/router';
import {
  fadeInAnimation,
  rotateAnimation,
  scaleUpAnimation,
  slideInAnimation,
} from './commons/route-animations';
import { SideBarComponent } from './modules/layout/side-bar/side-bar.component';
import { UtilService } from './services/util.service';
import { ToastMessagesService } from './commons/services/toast-messages.service';
import {
  AppConfig,
  AppConfigService,
} from './modules/layout/app-config.service';
import { AppConstants } from './commons/app-constants';
import { Theme } from './commons/theme-constants';
import { ThemeService } from './services/theme.service';
import { SHARED_MODULES } from './modules/shared-imports';
import { HeaderComponent } from './modules/layout/header/header.component';
import { BrowserModule } from '@angular/platform-browser';
import { BuyerModule } from './modules/buyer/buyer.module';
import { ContainerModule } from './modules/container/container.module';
import { FooterComponent } from './modules/layout/footer/footer.component';

(window as any).global = window;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [MessageService, ToastMessagesService],
  animations: [fadeInAnimation], // scaleUpAnimation, rotateAnimation, slideInAnimation, fadeAnimation,
  standalone: true,
  imports: [SHARED_MODULES, HeaderComponent, HttpClientModule,
    BuyerModule, ContainerModule, SideBarComponent, FooterComponent
  ]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() minimal: boolean = false;
  isOffline: boolean = !navigator.onLine;

  scales: number[] = [12, 13, 14, 15, 16];

  loading: boolean = false;
  @ViewChild(SideBarComponent) sidebarComponent!: SideBarComponent;
  showErrorDialog: boolean = false;
  errorMessage!: string | null;

  isMenuExpand = false;
  //  items!: MenuItem[];
  items!: MegaMenuItem[];
  // showHeader = true;
  showFooter = true;
  showMenu = true;
  // showLogo = true;
  showLogoSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  ); // initial value
  showLogo$: Observable<boolean> = this.showLogoSubject.asObservable();

  private showHeaderSubject = new BehaviorSubject<boolean>(true); // Assuming it's initialized with true
  showHeader$ = this.showLogoSubject.asObservable();

  showSideBar = false;
  showFontSize = false;
  showMenuButton = false;
  showDayNightScheme = false;
  showUserProfileButton = false;

  currentFontSize!: number;
  private fontSizeSubscription!: Subscription;
  constructor(
    private primengConfig: PrimeNGConfig,
    private router: Router,
    private userService: UserService,
    private fontSizeService: FontSizeServiceService,
    private messageService: MessageService,
    private authService: AuthService,
    private utilService: UtilService,
    private appConfig: AppConfigService,
    private cdr: ChangeDetectorRef,
    private themeService: ThemeService
  ) {
    this.updateBodyPadding();
    window.addEventListener('resize', this.updateBodyPadding.bind(this));
    // this.isDarkThemeEnabled = this.selectedTheme === Theme.BootstrapDark;
    this.isDarkThemeEnabled = this.selectedTheme === Theme.LaraDark;
    this.initNetworkStatus();


    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationError ||
            event instanceof NavigationCancel
        )
      )
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.loading = true;
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationError ||
          event instanceof NavigationCancel
        ) {
          this.loading = false;
        }
        if (event instanceof NavigationEnd) {
          // console.log('Navigation ended!');
          let currentUrl = event.url;
          if (currentUrl.includes('public/')) {
            this.showFooter = true;
            this.showMenu = false;
            // this.showHeader = true;
            this.showHeaderSubject.next(true);
            // this.showLogo = true;
            this.showLogoSubject.next(true);

            this.showFontSize = true;
            this.showMenuButton = false;
            this.showDayNightScheme = false;
            this.showUserProfileButton = false;
          } else if (currentUrl.includes('login')) {
            this.showFooter = false;
            this.showMenu = false;
            // this.showHeader = false;
            this.showHeaderSubject.next(false);

            // this.showLogo = false;
            this.showLogoSubject.next(false);

            this.showFontSize = false;
            this.showMenuButton = false;
            this.showDayNightScheme = false;
            this.showUserProfileButton = false;
            document.body.style.padding = '0';
          } else {
            // console.log("The URL does not contain the word 'public/'");
            // if (this.showMenu) {
            this.showFooter = true;
            // this.showHeader = true;
            this.showHeaderSubject.next(true);

            this.showMenu = true;
            this.showLogoSubject.next(true);

            this.showFontSize = true;
            this.showMenuButton = true;
            this.showDayNightScheme = true;
            this.showUserProfileButton = true;
          }
        }
      });
    this.utilService.showErrorDialog$.subscribe((show) => {
      this.showErrorDialog = show;
      // console.log('Dialog Status:', this.showErrorDialog);
    });
    this.utilService.errorMessage$.subscribe((code) => {
      this.errorMessage = code;
      // console.log('Error Message:', this.errorMessage);
    });
  }

  ngAfterViewInit(): void {
    const currentUrl = this.router.url;
    this.updateFontSizeFromStorage();
    this.updateThemeFromStorage();
  }

  ngOnInit(): void {
    // call resize event

    this.utilService.onResize.subscribe((dimensions) => {
      console.log(`Width: ${dimensions.width}, Height: ${dimensions.height}`);
    });

    this.fontSizeSubscription = this.fontSizeService.currentFontSize.subscribe(
      (fontSize) => {
        this.currentFontSize = fontSize;
        document.documentElement.style.fontSize = fontSize + 'rem';
      }
    );

    this.primengConfig.ripple = true;
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        // Add the route paths for which you want to hide the components
        const hiddenRoutes = [
          'login',
          'forbidden',
          'hidden-route-3',
          'error',
          'test',
          'public',
        ];

        const shouldHide = hiddenRoutes.some((route) =>
          event.urlAfterRedirects.startsWith(`/${route}`)
        );

        // this.showHeader = !shouldHide;
        this.showHeaderSubject.next(!shouldHide);

        // this.showFooter = !shouldHide;
        this.showMenu = !shouldHide;
      });

    /* this.authService.isUserLoggedIn().subscribe((isLoggedIn: any) => {
      // console.log(`check via url for isUserLoggedIn ${isLoggedIn}`);
      // console.log(`checking for isUserLoggedIn ${isLoggedIn}`);
      if (isLoggedIn) {
        //  console.log(`checking for isUserLoggedIn ${isLoggedIn}`);
        this.setToastNotificationOnSubscriptionStatus();
      }
    }); */
  }

  visibleSidebar1(flag: boolean) {
    // console.log(`Flag ${flag}`);
    this.showSideBar = flag;
  }

  showSticky() {
    this.messageService.add({
      key: 'toast1',
      severity: 'info',
      summary: 'Sticky',
      detail: 'Message Content',
      sticky: true,
    });
  }

  showBillingDueToast() {
    this.messageService.add({
      key: 'billingDueToast',
      severity: 'info',
      summary: 'Billing Due',
      detail: 'Your billing is due. Please make a payment.',
      sticky: true,
    });
  }

  showExpiredToast() {
    this.messageService.add({
      key: 'expiredToast',
      severity: 'error',
      summary: 'Expired',
      detail: 'Your subscription has expired.',
      sticky: true,
    });
  }

  showOverDueToast() {
    this.messageService.add({
      key: 'overDueToast',
      severity: 'warn',
      summary: 'Over Due',
      detail: 'Payment overdue. Please pay immediately.',

      sticky: true,
    });
    //Payment overdue. Please pay immediately.
  }

  ngOnDestroy() {
    console.log(`AppComponent Destroy`);
    this.fontSizeSubscription.unsubscribe();
    document.body.style.margin = ''; // Set this to the default margin you use in other pages
  }

  setToastNotificationOnSubscriptionStatus() {
    this.userService.getSubscriptionStatus().subscribe(
      (response: SubscriptionStatus | null) => {
        // console.log(`Subscription Status is ${response}`);
        switch (response) {
          case SubscriptionStatus.UNDER_GRACE_PERIOD:
            /* console.log(
              `setToastNotificationOnSubscriptionStatus UNDER_GRACE_PERIOD`
            ); */
            this.showBillingDueToast();
            break;
          case SubscriptionStatus.EXPIRED:
            //  console.log(`setToastNotificationOnSubscriptionStatus EXPIRED`);
            this.showExpiredToast();
            break;
          case SubscriptionStatus.UNDER_RENEWAL:
            /*  console.log(
              `setToastNotificationOnSubscriptionStatus UNDER_RENEWAL`
            ); */
            this.showOverDueToast();
            break;
          case SubscriptionStatus.ACTIVE:
            //  console.log(`setToastNotificationOnSubscriptionStatus ACTIVE`);
            // this.showBillingDueToast();
            //  this.showOverDueToast();
            break;
          default:
            // console.log(`setToastNotificationOnSubscriptionStatus DEFAULT`);
            break;
        }
      },
      (error: any) => {
        console.error(`Error while fetching status ${error}`);
      },
      () => { }
    );
  }

  public getRouterOutletState(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }

  updateBodyPadding() {
    let padding: string;
    if (window.matchMedia('(min-width: 1200px)').matches) {
      padding = '1.5em'; // Extra Large
    } else if (window.matchMedia('(min-width: 992px)').matches) {
      padding = '1em';
    } else if (window.matchMedia('(min-width: 768px)').matches) {
      padding = '1em';
    } else if (window.matchMedia('(min-width: 576px)').matches) {
      padding = '0.75em';
    } else {
      padding = '0.5em';
    }
    if (window.location.href.includes('/login')) {
      this.showLogoSubject.next(false);

      padding = '0em';
    }
    document.body.style.padding = padding;
  }

  onConfigButtonClick() {
    this.appConfig.showConfigSidebar();
  }
  get visible(): boolean {
    return this.appConfig.state.configSidebarVisible;
  }
  set visible(_val: boolean) {
    this.appConfig.state.configSidebarVisible = _val;
  }
  sliderValue: number = 2; // Default to 'MD' (index 2 in the fontSizes array)


  fontSizes = [
    { label: 'XS', value: 0.75 },
    { label: 'SM', value: 0.875 },
    { label: 'MD', value: 1 },
    { label: 'LG', value: 1.125 },
    { label: 'XL', value: 1.25 },
    { label: '2XL', value: 1.5 },
  ];

  selectedFontSize: any = this.fontSizes[this.sliderValue].value;

  isDarkThemeEnabled = false;
  availableThemes = [
    { label: 'Bootstrap Light', value: Theme.BootstrapLight },
    { label: 'Bootstrap Dark', value: Theme.BootstrapDark },
    { label: 'Lara Light', value: Theme.LaraLight },
    { label: 'Lara Dark', value: Theme.LaraDark },
    // Add other themes as needed
  ];
  selectedTheme = this.availableThemes[0].value;

  private updateFontSizeFromStorage(): void {
    const storedFontSize = localStorage.getItem(AppConstants.FONT_SIZE);

    if (storedFontSize) {
      const storedFontSizeNumber = parseFloat(storedFontSize);
      const index = this.fontSizes.findIndex(
        (item) => item.value === storedFontSizeNumber
      );
      if (index !== -1) {
        this.selectedFontSize = this.fontSizes[index];
      }
      // console.log(index);
    } else {
      this.fontSizeService.changeFontSize(1);
      document.documentElement.style.fontSize = 1 + 'rem';
      this.selectedFontSize = this.fontSizes[2];
    }
  }

  onFontSizeChange(event: any) {
    console.log(`this.selectedFontSize ${JSON.stringify(event)}`);
    this.fontSizeService.changeFontSize(event);
    document.documentElement.style.fontSize = event + 'rem';
    this.cdr.detectChanges();
  }

  updateTheme() {
    console.log(this.isDarkThemeEnabled); // Add this line
    if (this.isDarkThemeEnabled) {
      //   this.selectedTheme = Theme.BootstrapDark;
      this.selectedTheme = Theme.LaraDark;
    } else {
      // this.selectedTheme = Theme.BootstrapLight;
      this.selectedTheme = Theme.LaraLight;
    }
    this.themeService.switchTheme(this.selectedTheme);
    this.cdr.detectChanges();
  }

  private updateThemeFromStorage(): void {
    const storedTheme = localStorage.getItem(AppConstants.THEME);
    if (storedTheme) {
      // console.log(
      //   `Theme from Localstorage in headercomponent is ${storedTheme}`
      // );
      const index = this.availableThemes.findIndex(
        (item) => item.value === storedTheme
      );
      if (index !== -1) {
        this.selectedTheme = this.availableThemes[index].value;
      }
      // Set isDarkThemeEnabled based on storedTheme
      // this.isDarkThemeEnabled = this.selectedTheme === Theme.BootstrapDark;
      this.isDarkThemeEnabled = this.selectedTheme === Theme.LaraDark;
      // console.log(index);
    } else {
      // this.selectedTheme = Theme.BootstrapLight; // default value
      this.selectedTheme = Theme.LaraLight; // default value
      // If default theme is light, isDarkThemeEnabled should be false
      this.isDarkThemeEnabled = false;
    }
  }



  onSliderChange(event: any) {
    console.log(`onSliderChange ${JSON.stringify(event)}`)
    this.selectedFontSize = this.fontSizes[this.sliderValue].value;
    console.log(`this.selectedFontSize ${this.selectedFontSize}`)
    this.onFontSizeChange(this.selectedFontSize);
  }


  private initNetworkStatus() {

  }

  @HostListener('window:online')
  @HostListener('window:offline')
  updateNetworkStatus() {
    this.isOffline = !navigator.onLine;
  }



  /* @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // metaKey is for Mac, ctrlKey is for Windows
    if (event.metaKey && event.shiftKey && event.key.toLowerCase() === 'o') { 
      // this.isMenuExpand = true;
      this.sideBarMenuSharedService.isMenuExpanded.emit(true);
      setTimeout(() => {
        // this.searchInput.nativeElement.focus();  
        this.sidebarComponent.focusSearchInput();

      }, 500);
      

    }
  } */
}
