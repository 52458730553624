<p-card header="Simple Card" class="p-card m-0 p-0 md:m-1 md:p-1">
  <div class="p-card-body p-0 md:p-2 m-0">


    <p-stepper [(activeStep)]="active" (activeStepChange)="onStepChange($event)" [linear]="false"
     >
     <!-- [orientation]="orientation" -->
      <!-- Personal Details -->

      <p-stepperPanel>
        <ng-template [orientation]="vertical"  pTemplate="header" let-onClick="onClick" let-index="index">
          <button class="bg-transparent border-none inline-flex flex-column gap-2" (click)="onClick.emit()"
            pTooltip="Personal Details" tooltipPosition="bottom" showDelay="500" hideDelay="250">
            <span class="border-round border-2 w-3rem h-3rem inline-flex align-items-center justify-content-center"
              [ngClass]="{ 'bg-primary border-primary': index <= active, 'surface-border': index > active }">
              <i class="pi pi-user"></i>
            </span>
          </button>
        </ng-template>
        <ng-template pTemplate="content" class="m-0 p-0 md:p-1">

          <router-outlet></router-outlet>

        </ng-template>

      </p-stepperPanel>

      <!-- Company Details -->

      <p-stepperPanel>
        <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
          <button class="bg-transparent border-none inline-flex flex-column gap-2" (click)="onClick.emit()"
            pTooltip="Company Details" showDelay="500" hideDelay="250" tooltipPosition="bottom">
            <span class="border-round border-2 w-3rem h-3rem inline-flex align-items-center justify-content-center"
              [ngClass]="{ 'bg-primary border-primary': index <= active, 'surface-border': index > active }">
              <i class="pi pi-briefcase"></i>
            </span>
          </button>
        </ng-template>
        <ng-template pTemplate="content">

          <router-outlet></router-outlet>

        </ng-template>
      </p-stepperPanel>

      <!-- Subscription Package Selection -->

      <p-stepperPanel>
        <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
          <button class="bg-transparent border-none inline-flex flex-column gap-2" (click)="onClick.emit()"
            pTooltip="Subscription" showDelay="500" hideDelay="250" tooltipPosition="bottom">
            <span class="border-round border-2 w-3rem h-3rem inline-flex align-items-center justify-content-center"
              [ngClass]="{ 'bg-primary border-primary': index <= active, 'surface-border': index > active }">
              <i class="pi pi-tags"></i>
            </span>
          </button>
        </ng-template>
        <ng-template pTemplate="content">

          <router-outlet></router-outlet>

        </ng-template>
      </p-stepperPanel>

      <!-- Payment Details -->

      <p-stepperPanel>
        <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
          <button class="bg-transparent border-none inline-flex flex-column gap-2" (click)="onClick.emit()"
            pTooltip="Payment" showDelay="500" hideDelay="250" tooltipPosition="bottom">
            <span class="border-round border-2 w-3rem h-3rem inline-flex align-items-center justify-content-center"
              [ngClass]="{ 'bg-primary border-primary': index <= active, 'surface-border': index > active }">
              <i class="pi pi-credit-card"></i>
            </span>
          </button>
        </ng-template>
        <ng-template pTemplate="content">

          <router-outlet></router-outlet>

        </ng-template>
      </p-stepperPanel>

      <!-- Confirmation Details -->

      <p-stepperPanel>
        <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
          <button class="bg-transparent border-none inline-flex flex-column gap-2" (click)="onClick.emit()"
            pTooltip="Confirmation" showDelay="500" hideDelay="250" tooltipPosition="bottom">
            <span class="border-round border-2 w-3rem h-3rem inline-flex align-items-center justify-content-center"
              [ngClass]="{ 'bg-primary border-primary': index <= active, 'surface-border': index > active }">
              <i class="pi pi-check-square"></i>
            </span>
          </button>
        </ng-template>
        <ng-template pTemplate="content">

          <router-outlet></router-outlet>

        </ng-template>
      </p-stepperPanel>

    </p-stepper>
  </div>

</p-card>
