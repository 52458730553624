import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import FileSaver from 'file-saver';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';
import { SubscriptionViewService } from 'src/app/modules/subscription-view/services/subscription-view.service';
import { LogoutService } from 'src/app/services/logout.service';

@Component({
  selector: 'app-payment-failure',
  templateUrl: './payment-failure.component.html',
  styleUrls: ['./payment-failure.component.css'],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class PaymentFailureComponent  implements OnInit {
  paymentDetails: any = {};

  constructor(
    private route: ActivatedRoute,
    private subscriptionViewService: SubscriptionViewService,
    private logOutService: LogoutService,
    private router: Router,
    private toastMessageService: ToastMessagesService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      // Check if 'data' query param exists
      const dataParam = params['data'];
      if (dataParam) {
        // Decode the URL-encoded JSON string
        const decodedData = decodeURIComponent(dataParam);

        try {
          // Parse the JSON string into an object
          const dataObject = JSON.parse(decodedData);

          // Assign the parsed object to the paymentDetails property
          this.paymentDetails = {
            subscriptionPeriod: dataObject.subscriptionPeriod || 'Not provided',
            grandTotal: dataObject.grandTotal || 'Not provided',
            currency: dataObject.currency || 'Not provided',
            subscriptionPackage:
              dataObject.subscriptionPackage || 'Not provided',
            invoiceNo: dataObject.invoiceNo || 'Not provided',
            transactionId: dataObject.transactionId || 'Not provided',
            paymentGateway: dataObject.paymentGateway || 'Not provided',
            subscriptionOrderId: dataObject.subscriptionOrderId || -1,
          };
        } catch (e) {
          console.error('Error parsing JSON data:', e);
          // Handle error, maybe set default values or show an error message
        }
      } else {
        // Handle the absence of 'data' query param
        console.error('Data parameter is missing in the URL');
        // Set default values or show an error message
      }
    });
  }

  onDownload(subscriptionOrderId: number) {
    console.log(
      `downloadInvoice -> subscriptionOrderId ${subscriptionOrderId}`
    );
    this.subscriptionViewService.downloadInvoice(subscriptionOrderId).subscribe(
      (response: Blob) => {
        FileSaver.saveAs(response, 'invoice.pdf'); // Replace 'invoice.pdf' with your desired file name
        console.log(`downloadInvoice response is ${JSON.stringify(response)}`);
      },
      (error: any) => {
        console.log(`downloadInvoice error is ${JSON.stringify(error)}`);
      },
      () => {}
    );
  }

  redirectToHome() {
    this.router.navigate(['/dashboard']);
  }
}
