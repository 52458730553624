import { Component, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { StepperModule } from 'primeng/stepper';
import { InputTextModule } from 'primeng/inputtext';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';
import { CardModule } from 'primeng/card';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { AccountSetupStepperService } from 'src/app/services/tally-sheet/account-setup-stepper.service';
 



@Component({
  selector: 'app-account-setup',
  standalone: true,
  imports: [StepperModule, ButtonModule, ToggleButtonModule, CommonModule, InputTextModule, IconFieldModule, InputIconModule, FormsModule,
    RouterModule, TooltipModule, CardModule, ScrollPanelModule
  ],
  templateUrl: './account-setup.component.html',
  styleUrl: './account-setup.component.scss'
})
export class AccountSetupComponent implements OnInit {
  active: number = 0;
  orientation!: 'vertical' | 'horizontal';


  constructor(
    private router: Router,
    private stepperService: AccountSetupStepperService
  ) {
    window.onresize = () => this.getDeviceType();
    this.getDeviceType();
  }

  ngOnInit(): void {
    this.stepperService.activeStep$.subscribe((step: number) => {
      this.active = step;
    });
  }


  public getDeviceType(): string {
    const width = window.innerWidth;

    if (width <= 768) {
      this.orientation = 'vertical';
      return 'Mobile';
    } else if (width > 768 && width <= 1024) {
      this.orientation = 'horizontal';
      return 'Tablet';
    } else {
      this.orientation = 'horizontal';
      return 'Laptop/Computer';
    }
  }


  onStepChange(index: number) {

    this.active = index;
    this.stepperService.setActiveStep(this.active);  // Update step in service
    switch (this.active) {
      case 0:
        this.router.navigate(['/account-setup/account-details']);
        break;
      case 1:
        this.router.navigate(['/account-setup/company-details']);
        break;
      case 2:
        this.router.navigate(['/account-setup/subscription']);
        break;
      case 3:
        this.router.navigate(['/account-setup/payment']);
        break;
      default:
        this.router.navigate(['/account-setup/confirmation']);
        break;
    }
  }

  navigateTo(type: 'account'|'company'|'subscription'|'payment'|'confirmation'){
    if(type === 'account'){}
    if(type === 'company'){}
    if(type === 'subscription'){}
    if(type === 'payment'){}
    if(type === 'confirmation'){}

  }
}
