<div style="width: 90vw">
    <p-card>
        <p-table [value]="measurements"   [styleClass]="tableStyle"    [loading]="loading">
            
            <ng-template pTemplate="header">
                <tr>
                    <!-- <th pSortableColumn="sr">SR <p-sortIcon field="sr"></p-sortIcon></th> -->
                    <th>SR </th>
                    <th pSortableColumn="grossLength">Length <p-sortIcon field="grossLength"></p-sortIcon></th>
                    <th pSortableColumn="grossGirth">G. Girth <p-sortIcon field="grossGirth"></p-sortIcon></th>
                    <th pSortableColumn="netGirth">N. Girth <p-sortIcon field="netGirth"></p-sortIcon></th>
                    <th pSortableColumn="grossCbm">G. CBM <p-sortIcon field="grossCbm"></p-sortIcon></th>
                    <th pSortableColumn="netCbm">N. CBM <p-sortIcon field="netCbm"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-measurement let-index="rowIndex">
                
                <tr>
                    <td>{{ index + 1}}</td>
                    <td>{{measurement.grossLength}}</td>
                    <td>{{measurement.grossGirth}}</td>
                    <td>{{measurement.netGirth}}</td>
                    <td>{{measurement.grossCbm}}</td>
                    <td>{{measurement.netCbm}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="loadingbody" let-columns="columns">
                <tr style="height:46px">
                    <td *ngFor="let col of columns; let even = even">
                        <p-skeleton
                            [ngStyle]="{'width': even ? (col.field === 'year' ? '30%' : '40%') : '60%'}"></p-skeleton>
                    </td>
                <tr style="height:46px">
                    <td> <p-skeleton></p-skeleton></td>
                    <td> <p-skeleton></p-skeleton></td>
                    <td> <p-skeleton></p-skeleton></td>
                    <td> <p-skeleton></p-skeleton></td>
                    <td> <p-skeleton></p-skeleton></td>
                    <td> <p-skeleton></p-skeleton></td>
                </tr>
            </ng-template>

        </p-table>
        <!-- {{ measurements | json}}    -->
        <!--  <p-table [value]="measurements" [tableStyle]="{'min-width': '50rem'}" [loading]="loading">
        <ng-template pTemplate="caption">
            Default
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th>Name </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-columns="columns">
            <tr>
                <td>{{product.measurementId }}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody" let-columns="columns">
            <tr style="height:46px">
                <td *ngFor="let col of columns; let even = even">
                    <p-skeleton [ngStyle]="{'width': even ? (col.field === 'year' ? '30%' : '40%') : '60%'}"></p-skeleton>
                </td>
            </tr>
        </ng-template>
    </p-table> -->
    </p-card>
</div>