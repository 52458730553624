<h1>Page Not Found</h1>

<div class="card flex justify-content-center">
    <p-card>
        <ng-template pTemplate="header">
            <h3>The page you are looking for could not be found.</h3>
        </ng-template>

        <p>Please check the URL {{url}} you entered and try again.</p>
        <p>If you are still having trouble, please contact us for assistance.</p>

        <ng-template pTemplate="footer">
            <p>Copyright &copy; 2023, Lumberlinq</p>
        </ng-template>
    </p-card>
</div>

<p>The requested URL {{url}} was not found on this server.</p>