import { Injectable } from '@angular/core';
import { Measurement } from '../model/measurement';
import { MeasurementSummary } from '../model/measurement-summary';

@Injectable({
  providedIn: 'root'
})
export class MeasurementSharedDataService {

  measurementSharedData!: any;

  constructor() { }


  saveDataToLocalStorage(data: MeasurementSummary) {
    localStorage.setItem('MEASUREMENT_DATA', JSON.stringify(data));
  }

  getDataFromLocalStorage(): MeasurementSummary {
    const data = localStorage.getItem('MEASUREMENT_DATA');
    return JSON.parse(data!);
  }

  removeFromLocalStorage(): void {
    const data = localStorage.removeItem('MEASUREMENT_DATA');
  }

  saveContainerIdStorage(data: number) {
    localStorage.setItem('CONTAINER_ID', JSON.stringify(data));
  }

  getContainerIdStorage(): number {
    const data = localStorage.getItem('CONTAINER_ID');
    return JSON.parse(data!);
  }

}
