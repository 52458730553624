import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from 'src/app/interfaces/tally-sheet/user';
import { Subscription } from 'src/app/interfaces/tally-sheet/subscription';
import { AppConstants } from 'src/app/commons/app-constants';
 

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private baseUrl =  AppConstants.API_URL + `auth`;

  constructor(private http: HttpClient) {}

  loginUser(formLogin: any): Observable<any> {
    const url = AppConstants.API_URL + `login`; 
    console.log(`url ${url}`)
    return this.http.post(url, formLogin);
  }

  registerUser(user: User): Observable<any> {
    return this.http.post(`${this.baseUrl}/register`, user, { responseType: 'text' as 'json' });
  }

  registerSocialUser(user: User): Observable<any> {
    return this.http.post(`${this.baseUrl}/register/social`, user, { responseType: 'text' as 'json' });
  }

  subscribeUser(subscription: Subscription): Observable<any> {
    return this.http.post(`${this.baseUrl}/subscribe`, subscription, { responseType: 'text' as 'json' });
  }
}
