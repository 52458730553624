export class TallySummary {
  id?: number;
  girthAllowance!: number;
  lengthAllowance!: number;
  uomLength!: string; // Unit of Measurement for Length
  uomGirth!: string;  // Unit of Measurement for Girth
  uomThickness!: string;
  uomWidth!: string;
  containerNumber!: string;
  roundingDirection!: 'Up' | 'Down';
  decimalPlaces!: number;
  totalPieces!: number;
  totalNetVolumeCBM!: number;
  totalNetVolumeCFT!: number;
  totalGrossVolumeCBM!: number;
  totalGrossVolumeCFT!: number;
  isRowModeEnabled: boolean = false;
  lengthIncreament: number = 0;
  copyPreviousRowLength: boolean = false;
  summaryType!: 'PUBLIC' | 'PRIVATE' | 'SOLD';
  container!:any;
  // selectedColumnNames: string[] =[];
}