import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Observable } from 'rxjs';
import { SellerComponent } from '../seller/seller.component';

@Injectable({
  providedIn: 'root'
})
export class SellerGuard implements CanDeactivate<SellerComponent> {
  constructor(private confirmationService: ConfirmationService) { }

  canDeactivate(component: SellerComponent): Observable<boolean> | Promise<boolean> | boolean {
    if (component.isSubmitting && component.sellerForm.dirty) { // check if component has unsaved changes
      return true;
    }
    if (component.sellerForm.dirty) { // check if component has unsaved changes
      return new Promise((resolve) => {
        this.confirmationService.confirm({
          header: 'Warning',
          message: "Changes to the form have not been saved. Do you want to continue without saving?",
          accept: () => {
            resolve(true);
          },
          reject: () => { 
            resolve(false);
          }
        });
      });
    } else {
      return true; // allow deactivation if no unsaved changes
    }
  }

}
