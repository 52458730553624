import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { Page } from 'src/app/commons/models/page';
import { Product } from '../model/product';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
 
@Injectable({
  providedIn: 'root'
})
export class ProductService {


  constructor(private http: HttpClient) { }

  /* getData(): Observable<Product> {
    return this.http.get<Product>(AppConstants.API_URL + 'prodcuts');
  } */

  postData(data: Product): Observable<Product> {
    return this.http.post<Product>(AppConstants.API_URL + 'api/v1/products', data, { responseType: 'json' });
  }

  getByProductName(name: string): Observable<any | undefined> {
    const response = this.http.get(AppConstants.API_URL + `api/v1/check-product-name?name=${name}`, httpOptions);
    console.log(`getByProductName Response is ${JSON.stringify(response)}`)
    return response;
  }

  // <Page<Book>
  getProductsList(httpParams?: HttpParams): Observable<Page<Product>> {
    let url = `${AppConstants.API_URL}api/v1/products/filter?`;
    if (httpParams) {
      url = url + httpParams;
    }
    console.log(`url is ${url}`)
    return this.http.get<Page<Product>>(url);
  }

  getProductsListGlobalFilter(httpParams?: HttpParams): Observable<Page<Product>> {
    let url = `${AppConstants.API_URL}api/v1/products/search?`;
    if (httpParams) {
      url = url + httpParams;
    }
    console.log(`url is ${url}`)
    return this.http.get<Page<Product>>(url);
  }

  deleteProducts(id: any, forceDelete: boolean = false): Observable<string> {
    let url = `${AppConstants.API_URL}api/v1/products/${id}?forceDelete=${forceDelete}`;
    console.log(`url is ${url}`)
    return this.http.delete<string>(url);
  }

  getProductById(id: string): Observable<Product> {
    const url = `${AppConstants.API_URL}api/v1/products/${id}`;
    console.log(`url is ${url}`);
    return this.http.get<Product>(url);
  }
}
