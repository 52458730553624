<form [formGroup]="locationForm" (ngSubmit)="onSubmit()">
    <!-- <p-card> -->
    <div class="formgrid grid">
        <input type="hidden" formControlName="id">
        <div class="field col-12 md:col-6" *ngIf="isAddress1Visible">
            <label for="address1">
                Address 1
                <i class="text-red-400" *ngIf="!isAddress1Optional">*</i>
            </label>
            <input id="address1" type="text" formControlName="address1" pInputText maxlength="65"
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            <div class="text-red-400"
                *ngIf="locationForm.controls?.['address1']?.invalid && (locationForm.controls?.['address1']?.dirty || locationForm.controls?.['address1']?.touched)">
                <div *ngIf="locationForm.controls?.['address1']?.errors?.['minlength']">Min Length is 3</div>
                <div *ngIf="locationForm.controls?.['address1']?.errors?.['maxlength']">Max Length is 64</div>
                <div *ngIf="locationForm.controls?.['address1']?.errors?.['required']">Address 1 is required</div>
            </div>
            <!-- <textarea id="address" type="text" rows="4"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"></textarea> -->
        </div>
        <div class="field col-12 md:col-6" *ngIf="isAddress2Visible">
            <label for="address2">
                Address 2
                <i class="text-red-400" *ngIf="!isAddress2Optional">*</i>
            </label>
            <input id="address2" type="text" formControlName="address2" pInputText maxlength="65"
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            <div class="text-red-400"
                *ngIf="locationForm.controls?.['address2']?.invalid && (locationForm.controls?.['address2']?.dirty || locationForm.controls?.['address2']?.touched)">
                <div *ngIf="locationForm.controls?.['address2']?.errors?.['minlength']">Min Length is 3</div>
                <div *ngIf="locationForm.controls?.['address2']?.errors?.['maxlength']">Max Length is 64</div>
            </div>
        </div>
        <!-- Country -->
        <div [class]="applyStyle" *ngIf="isCountryVisible">
            <label for="country">
                Country
                <i class="text-red-400" *ngIf="!isCountryOptional">*</i>
            </label>
            <div class="p-inputgroup">
                <!-- {{ countrySuggestions.length }} {{  selectedCountry | json}} -->
                <!-- <p-dropdown [options]="countrySuggestions" [(ngModel)]="selectedCountry" optionLabel="name"
                    formControlName="country" styleClass="field col-12 w-full" placeholder="Select a Country"
                    [filter]="true" filterBy="name" [virtualScroll]="true" [virtualScrollItemSize]="38"
                    (onChange)="onCountrySelection($event)" class="w-full" [tooltip]="countryTooltip"
                      tooltipPosition="bottom">
                </p-dropdown> -->
                <p-dropdown [options]="countrySuggestions" [(ngModel)]="selectedCountry" optionLabel="name"
                    formControlName="country" styleClass="field col-12 w-full" [filter]="true" [virtualScroll]="true"
                    [virtualScrollItemSize]="38" (onChange)="onCountrySelection($event)" class="w-full"
                    [tooltip]="countryTooltip" tooltipPosition="bottom" filterBy="name" [showClear]="true"
                    placeholder="Select a Country" appendTo="body">
                 
                    <ng-template pTemplate="selectedItem">
                        <div class="flex align-items-center gap-2" *ngIf="selectedCountry">
                            <div>{{ selectedCountry.emoji }}</div>
                            <div>{{ selectedCountry.name }}</div>
                        </div>
                    </ng-template>
                    <ng-template let-country pTemplate="item">
                        <div class="flex align-items-center gap-2">
                            <div>{{ country.emoji }}</div>
                            <div>{{ country.name }}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
                <p-progressSpinner styleClass="w-2rem h-2rem" *ngIf="isCountryLoading"></p-progressSpinner>
            </div>
            <div class="text-red-400"
                *ngIf="locationForm.controls?.['country']?.invalid && (locationForm.controls?.['country']?.dirty || locationForm.controls?.['country']?.touched)">
                <div *ngIf="locationForm.invalid &&  (locationForm.controls?.['country']?.errors?.['required'])">
                    Country is
                    required.</div>
            </div>

        </div>
        <!-- State -->
        <div [class]="applyStyle" *ngIf="isStateVisible">
            <label for="state">State
                <i class="text-red-400" *ngIf="!isStateOptional">*</i>
            </label>
            <div class="p-inputgroup">

                <!-- <p-dropdown [options]="stateSuggestions" [(ngModel)]="selectedState" optionLabel="name"
                    formControlName="state" styleClass="field col-12 w-full" placeholder="Select a State"
                    [filter]="true" filterBy="name" [virtualScroll]="true" [virtualScrollItemSize]="38"
                    (onChange)="onStateSelection($event)" class="w-full" [tooltip]="stateTooltip"
                    [readonly]="isStateDisabled" tooltipPosition="bottom"> -->
                <p-dropdown [options]="stateSuggestions" [(ngModel)]="selectedState" optionLabel="name"
                    formControlName="state" styleClass="field col-12 w-full" placeholder="Select a State"
                    [filter]="true" filterBy="name" [virtualScroll]="true" [virtualScrollItemSize]="38"
                    (onChange)="onStateSelection($event)" class="w-full" [tooltip]="stateTooltip"
                    (onClick)="onStateClick()" tooltipPosition="bottom" appendTo="body">
                </p-dropdown>
                <p-progressSpinner styleClass="w-2rem h-2rem" *ngIf="isStateLoading"></p-progressSpinner>
            </div>
            <div class="text-red-400"
                *ngIf="locationForm.controls?.['state']?.invalid && (locationForm.controls?.['state']?.dirty || locationForm.controls?.['state']?.touched)">
                <div *ngIf="locationForm.invalid &&  (locationForm.controls?.['state']?.errors?.['required'])">
                    State is
                    required.
                </div>
            </div>
        </div>

        <!-- City -->
        <div [class]="applyStyle">
            <label for="city" *ngIf="isCityVisible">City
                <i class="text-red-400" *ngIf="!isCityOptional">*</i>
            </label>
            <div class="p-inputgroup">

                <p-dropdown [options]="citySuggestions" [(ngModel)]="selectedCity" optionLabel="name"
                    formControlName="city" styleClass="field col-12 w-full" placeholder="Select a City" [filter]="true"
                    filterBy="name" [virtualScroll]="true" [virtualScrollItemSize]="38" class="w-full"
                    [tooltip]="cityTooltip" (onChange)="onCitySelection($event)" tooltipPosition="bottom"
                    (onClick)="onCityClick()" appendTo="body">

                </p-dropdown>
                <p-progressSpinner styleClass="w-2rem h-2rem" *ngIf="isCityLoading"></p-progressSpinner>
            </div>
            <div class="text-red-400"
                *ngIf="locationForm.controls?.['city']?.invalid && (locationForm.controls?.['city']?.dirty || locationForm.controls?.['city']?.touched)">
                <div *ngIf="locationForm.invalid &&  (locationForm.controls?.['city']?.errors?.['required'])">
                    City is
                    required.
                </div>
            </div>

        </div>

        <!-- Pin/Zip Code -->
        <div [class]="applyStyle" *ngIf="isZipVisible">
            <label for="zipcode">Zipcode
                <i class="text-red-400" *ngIf="!isZipOptional">*</i>
            </label>
            <input id="name" type="number" formControlName="zipcode" pInputText
                class="text-base text-color surface-overlay border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                tooltipPosition="bottom">
            <div class="text-red-400"
                *ngIf="locationForm.controls?.['zipcode']?.invalid && (locationForm.controls?.['zipcode']?.dirty || locationForm.controls?.['zipcode']?.touched)">
                <div *ngIf="locationForm.invalid &&  (locationForm.controls?.['zipcode']?.errors?.['required'])">
                    Zipcode is
                    required.
                </div>
            </div>

        </div>

    </div>
    <!-- <p-button  label="Submit"  [disabled]="!locationForm.dirty" icon="pi pi-check" type="submit"></p-button> -->
    <!-- <p-button  label="Submit"    icon="pi pi-check" type="submit"></p-button> -->
    <!-- </p-card> -->
</form>