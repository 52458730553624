import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { CompanyService } from '../services/company.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PrimeIcons } from 'primeng/api';
import { ChipsModule } from 'primeng/chips';
import { CustomEmailValidator } from 'src/app/classes/custom-email.validator';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-add-user-to-company',
  templateUrl: './add-user-to-company.component.html',
  styleUrls: ['./add-user-to-company.component.css'],
  providers: [ToastMessagesService],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class AddUserToCompanyComponent implements OnInit {
  isSubmitting = false;
  blockedDocument = false;

  addUserToCompanyForm!: FormGroup;
  formHeader!: string;
  formSubHeader!: string;
  protected items!: MenuItem[];
  home!: MenuItem;
  companyUsers: any;
  // emailAddresses!: string[];
  isVisible = true;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private toastMessageService: ToastMessagesService
  ) {}
  ngOnInit(): void {
    this.addUserToCompanyForm = new FormGroup({
      emailAddresses: new FormControl('', [
        Validators.required,
        this.validateEmailArray,
      ]),
      /*  emailAddresses: new FormArray([
         new FormControl([], [this.validateEmail]) // here
       ]) */
    });
    this.populateBreadCrumb();
    this.setPageTitle();
    this.companyService.getInvitedUsers().subscribe(
      (response: any) => {
        this.companyUsers = response;
        // console.log(`getCompanyUsers response is ${JSON.stringify(response)}`);
      },
      (error: HttpErrorResponse) => {
        console.error(`getCompanyUsers error is ${JSON.stringify(error)}`);
      },
      () => {}
    );
  }

  resendInvitation(user: any) {
    console.log(`resendInvitation ${JSON.stringify(user)}`);
    let emailData = {
      emailAddresses: [user.email],
    };
    this.sendInvitation(emailData);
  }

  onSubmit() {
    console.log('onSubmit');

    this.isSubmitting = true;
    const controlKeys = Object.keys(this.addUserToCompanyForm.controls);

    const invalidControlKeys = controlKeys.filter((key) => {
      const control = this.addUserToCompanyForm.controls[key];
      return control.invalid;
    });
    console.log('Invalid control keys:', invalidControlKeys);
    //  this.myForm.markAllAsTouched();
    Object.keys(this.addUserToCompanyForm.controls).forEach((key) => {
      console.log(`Key is ${key}`);
      this.addUserToCompanyForm.get(key)?.updateValueAndValidity();
    });

    console.log(
      `before validattion ${JSON.stringify(this.addUserToCompanyForm.value)}`
    );
    if (this.addUserToCompanyForm.valid) {
      console.log(
        `after  validattion ${JSON.stringify(this.addUserToCompanyForm.value)}`
      );
      this.addUserToCompanyForm.get('emailAddresses');
      console.log(
        `Email on url is ${JSON.stringify(this.addUserToCompanyForm.value)}`
      );
      this.sendInvitation(this.addUserToCompanyForm.value);
    } else {
      console.error(`Form is not valid`);
    }
  }

  private sendInvitation(data: any) {
    this.blockedDocument = true;
    this.companyService.sendInviteRequest(data).subscribe(
      (response: any) => {
        console.log(`response is ${response}`);
        this.toastMessageService.showSuccessMessage(
          'Success',
          'User invitation sent'
        );
        //this.isVisible = false;
        this.addUserToCompanyForm.reset();
        this.blockedDocument = false;
      },
      (error: HttpErrorResponse) => {
        this.blockedDocument = false;
        console.error(`Error is ${JSON.stringify(error)}`);
        console.error(`Error is ${JSON.stringify(error)}`);
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
              `body was: ${JSON.stringify(error.error)}` +
              `\n\n\n trace was: ${JSON.stringify(error.error.trace)}`
          );
        }
        console.error(`TRRRACCCE ${JSON.stringify(error.error)} `);
        console.error(`TRRRACCCE ${JSON.stringify(error.error.message)} `);
        console.error(`TRRRACCCE ${JSON.stringify(error.error.email)} `);

        let userFriendlyErrorMsg = 'An unexpected error occurred.';
        if (error && error.error && error.error.message) {
          console.log(`Error is ${JSON.stringify(error.error.email)}`);
          userFriendlyErrorMsg = error.error.message; // + "\nEmail: " + error.error.email;
        }
        // this.toastMessageService.showWarningMessage('User Exists ', userFriendlyErrorMsg);
        this.toastMessageService.showWarningMessage(
          'User Already Exists',
          'Cannot add this user as they already exist in the system.'
        );
      },
      () => {}
    );
  }

  populateBreadCrumb() {
    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.items = [
      // { label: 'Buyers', routerLink: '/list/buyers' },
      { label: 'Add User' },
    ];
  }

  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }

  removeUser(user: any) {
    console.log(`Remove User ${JSON.stringify(user)}`);
  }

  validateEmail(control: AbstractControl): ValidationErrors | null {
    let email = control.value;
    if (email) {
      const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      return emailRegex.test(email)
        ? null
        : { validateEmail: { valid: false } };
    }
    return null;
  }

  validateEmailArray(control: AbstractControl): ValidationErrors | null {
    const emails: string[] = control.value;
    if (emails && emails.length > 0) {
      const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      for (const email of emails) {
        if (!emailRegex.test(email)) {
          return { validateEmail: { valid: false } };
        }
      }
    }
    return null;
  }
}
