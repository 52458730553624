<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" acceptLabel="Yes"
    rejectLabel="No"></p-confirmDialog>

<div class="pb-3">
    <p-breadcrumb [model]="items" [home]="home" *ngIf="! this.isPublic"></p-breadcrumb>
</div>


<div style="width: 93vw">
    <p-card [header]="formHeader" [subheader]="formSubHeader">
        <span class="font-bold"> BL Number: </span> {{blNumber}}
        <!-- <span>{{blSummaryCombined | json}}</span> -->
        <div class="flex justify-content-start flex-wrap card-container blue-container"
            *ngIf="(loadingSubject | async)">
            <div class="flex align-items-center justify-content-center w-full text-white border-round m-2">
                <p-progressSpinner styleClass="w-4rem h-4rem"></p-progressSpinner>
            </div>
            <div class="flex align-items-center justify-content-center w-full text-white border-round m-2">
                <div class="p-mt-2">Please wait while loading...</div>
            </div>
        </div>
        <ng-container *ngIf="blSummaryHoppus.length > 0 || blSummaryRectanglePrism.length>0; else noDataFound">

            <p-tabView [scrollable]="true" [(activeIndex)]="tabIndex">
                <p-tabPanel header="Bill of Loading Summary" class="pt-3">
                    <div style="width: 88vw">

                        <!-- Hoppus Table -->
                        <div *ngIf="blSummaryHoppus.length">
                            <h2>Hoppus Formula</h2>
                            <p-table [value]="blSummaryHoppus" [styleClass]="tableStyle"
                                [tableStyle]="{ 'min-width': '30rem' }">
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th> SR</th>
                                        <th> Container Number </th>
                                        <th> Net Vol </th>
                                        <th> Gross Vol </th>
                                        <th> Net Avg </th>
                                        <th> Gross Avg </th>
                                        <th> Pieces </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                                    <tr>
                                        <td>{{rowIndex+1 }} </td>
                                        <td (click)="tabIndex = rowIndex+1" class="cursor-pointer">
                                            {{rowData.containerNumber}}
                                        </td>
                                        <td> {{rowData.netVolume | number:'1.3-3'}}</td>
                                        <td> {{rowData.grossVolume | number:'1.3-3'}}</td>
                                        <td> {{rowData.netAverage | number:'1.3-3'}}</td>
                                        <td> {{rowData.grossAverage | number:'1.3-3'}}</td>
                                        <td> {{rowData.pieces}}</td>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer" let-columns>
                                    <tr>
                                        <td colspan="2" class="text-center"> TOTAL </td>
                                        <td> {{totalNetVolumeHoppus | number:'1.3-3'}} </td>
                                        <td> {{totalGrossVolumeHoppus | number:'1.3-3'}} </td>
                                        <td> {{totalNetAvgHoppus | number:'1.3-3'}} </td>
                                        <td> {{totalGrossAvgHoppus | number:'1.3-3'}} </td>
                                        <td> {{totalPiecesHoppus}} </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="summary">
                                    Total {{blSummaryHoppus.length}} containers in Bill of Lading (Hoppus).
                                </ng-template>
                            </p-table>
                        </div>

                        <!-- RectanglePrism Table -->
                        <div *ngIf="blSummaryRectanglePrism.length">
                            <hr />
                            <h2>Volumetric Formula</h2>
                            <p-table [value]="blSummaryRectanglePrism" [styleClass]="tableStyle"
                                [tableStyle]="{ 'min-width': '30rem' }">
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th> SR</th>
                                        <th> Container Number </th>
                                        <th> Net Vol </th>
                                        <th> Gross Vol </th>
                                        <th> Net Avg </th>
                                        <th> Gross Avg </th>
                                        <th> Pieces </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                                    <tr>
                                        <td>{{rowIndex+1 }} </td>
                                        <td (click)="tabIndex = rowIndex+1" class="cursor-pointer">
                                            {{rowData.containerNumber}}
                                        </td>
                                        <td> {{rowData.netVolume | number:'1.3-3'}}</td>
                                        <td> {{rowData.grossVolume | number:'1.3-3'}}</td>
                                        <td> {{rowData.netAverage | number:'1.3-3'}}</td>
                                        <td> {{rowData.grossAverage | number:'1.3-3'}}</td>
                                        <td> {{rowData.pieces}}</td>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer" let-columns>
                                    <tr>
                                        <td colspan="2" class="text-center"> TOTAL </td>
                                        <td> {{totalNetVolumeRectanglePrism | number:'1.3-3'}} </td>
                                        <td> {{totalGrossVolumeRectanglePrism | number:'1.3-3'}} </td>
                                        <td> {{totalNetAvgRectanglePrism | number:'1.3-3'}} </td>
                                        <td> {{totalGrossAvgRectanglePrism | number:'1.3-3'}} </td>
                                        <td> {{totalPiecesRectanglePrism}} </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="summary">
                                    Total {{blSummaryRectanglePrism.length}} containers in Bill of Lading
                                    (RectanglePrism).
                                </ng-template>
                            </p-table>
                        </div>


                    </div>
                </p-tabPanel>


                <p-tabPanel *ngFor="let blData of blSummaryCombined" [header]="blData!.containerNumber!">
                    <ng-template pTemplate="content">
                        <app-bill-of-lading-main [containerId]="blData?.containerId?? 0"
                            [containerUUID]="blData?.containerUUID?? ''"
                            [formula]="blData!.formula!" [loading]="(loadingSubject | async)?? false"></app-bill-of-lading-main>
                    </ng-template>
                </p-tabPanel>
            </p-tabView>
        </ng-container>
    </p-card>
</div>

<ng-template #noDataFound>
    <!-- display message when blSummary is empty -->
    <!-- <p>No data found.</p> -->
</ng-template>