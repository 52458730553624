<form [formGroup]="slabForm">
    <div class="pb-3">
        <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
    </div>
    <p-card [header]="formHeader" [subheader]="formSubHeader"> 

        <div class="grid nested-grid">
            <div class="col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
                <!-- <div [ngClass]="{'col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8': slabs.length > 0, 'col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6': slabs.length == 1}"> -->

                <div class="grid border-round-sm border-1">
                    <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 ">
                        <!-- Rate input -->
                        <div class="text-center p-3 border-round-sm font-bold">
                            <label for="rate">Rate</label>
                            <p-inputNumber formControlName="rate" [autofocus]="true" id="rate" [showButtons]="true" [step]="5" [min]="0.00" styleClass="w-full"
                                [minFractionDigits]="2" [maxFractionDigits]="2" (onInput)=" generateSlabs()"
                                class="text-base text-color surface-overlay p-2 surface-border border-round appearance-none outline-none focus:border-primary w-full"></p-inputNumber>
                        </div>
                    </div>
                    <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                        <!-- Slab of Rate input -->
                        <div class="text-center p-3 border-round-sm font-bold">
                            <label for="rate">Slab of Rate <span class="text-primary">{{ baseRateRange?.min?? 0 | number:'1.2-2'}} - {{ baseRateRange?.max?? 0 | number:'1.2-2'}}</span> </label>
                            <p-inputNumber formControlName="slabCft" id="slabCft" [showButtons]="true" [step]="0.05"
                                [min]="0.00" [max]="100" [minFractionDigits]="2" [maxFractionDigits]="2" styleClass="w-full"
                                class="text-base text-color surface-overlay p-2 surface-border border-round appearance-none outline-none focus:border-primary w-full"
                                (onInput)=" generateSlabs()"></p-inputNumber>
                        </div>
                    </div>
                    <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                        <!-- Slab Price Size input -->
                        <div class="text-center p-3 border-round-sm font-bold">
                            <label for="slabPriceSize">Slab Price Size</label>
                            <p-inputNumber formControlName="slabPriceSize" id="slabPriceSize" [showButtons]="true"
                                [step]="5" [min]="1.00" [minFractionDigits]="2" [maxFractionDigits]="2" styleClass="w-full"
                                (onInput)=" generateSlabs()"
                                class="text-base text-color surface-overlay p-2 surface-border border-round appearance-none outline-none focus:border-primary w-full"></p-inputNumber>

                        </div>
                    </div>
                    <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                        <!-- Slab Size input -->
                        <div class="text-center p-3 border-round-sm font-bold">
                            <label for="slabSize">Slab Size</label>
                            <p-inputNumber formControlName="slabSize" id="slabSize" [showButtons]="true" [step]="0.05"
                                [min]="0.00" [minFractionDigits]="2" [maxFractionDigits]="2" styleClass="w-full"
                                (onInput)=" generateSlabs()"
                                class="text-base text-color surface-overlay p-2 surface-border border-round appearance-none outline-none focus:border-primary w-full"
                                (onInput)=" generateSlabs()"></p-inputNumber>
                        </div>
                    </div>
                    <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                        <!-- Upper Slabs input -->
                        <div class="text-center p-3 border-round-sm font-bold">
                            <label for="upperSlabs">Upper Slabs</label>
                            <p-inputNumber formControlName="upperSlabs" id="upperSlabs" [showButtons]="true" [min]="1"
                                [max]="50" (onInput)=" generateSlabs()" styleClass="w-full"
                                class="text-base text-color surface-overlay p-2 surface-border border-round appearance-none outline-none focus:border-primary w-full"></p-inputNumber>

                        </div>
                    </div>
                    <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                        <!-- Lower Slabs input -->
                        <div class="text-center p-3 border-round-sm font-bold">
                            <label for="lowerSlabs">Lower Slabs</label>
                            <p-inputNumber formControlName="lowerSlabs" id="lowerSlabs" [showButtons]="true" [min]="1"
                                [max]="50" (onInput)=" generateSlabs()" styleClass="w-full"
                                class="text-base text-color surface-overlay p-2 surface-border border-round appearance-none outline-none focus:border-primary w-full"></p-inputNumber>

                        </div>
                        
                    </div>
                    <div class="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6">
                        <!-- Any other content -->
                        <p-button label="Calculate" (onClick)="generateSlabs()" ></p-button>
                        <p-button label="Clear"  class="pl-2 w-5rem"  (onClick)="clear()" styleClass="p-button-secondary"></p-button>
                    </div>
                     
                </div>
            </div>
            <div class="col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4" *ngIf="slabs.length>0">
                <!-- The right column content -->
                <div class="text-center border-round-sm h-full font-bold  border-1">
                    <p class="underline">Slab Price</p>
                    <div *ngFor="let slab of slabs">
                        <p>{{slab.min | number:'1.2-2'}} - {{slab.max | number:'1.2-2'}} : {{slab.rate | currency}}</p>
                    </div>
                </div>
            </div>
        </div>

    </p-card>
</form>