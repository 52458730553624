<p-tabView>
       <!--  <div class="relative w-full mx-3 my-3 md:my-0 border-round">
            <div class="absolute top-0 right-0 flex align-items-center justify-content-center">
                <p-chip class="p-chip" label="Sold"></p-chip>
            </div>
        </div> -->

    <div class="mb-3">  
        <!-- <span class="font-bold"> Container Number:</span>   -->
        <!-- <p-chip label="Action"></p-chip> -->

        <!-- formula == Formula.RectanglePrism {{ formula == Formula.RectanglePrism }} <br/> -->
        <!-- formula == Formula.Hoppus {{ formula == Formula.Hoppus }} -->
    </div>
    <p-tabPanel header="Photos" [disabled]="(loadingSubject | async)?? false">
        <app-bl-photos [containerId]="containerId" [containerUUID]="containerUUID" [viewMode]="mode" [formula]="formula"></app-bl-photos>
    </p-tabPanel>


    <p-tabPanel header="Summary"  [disabled]="(loadingSubject | async)?? false">
        <ng-template pTemplate="content">
            <!-- <app-bl-hoppus-summary [containerId]="containerId" [viewMode]="mode" [formula]="formula"
                *ngIf="formula == Formula.Hoppus"></app-bl-hoppus-summary> --> 
                <app-view-hoppus-summary [containerIdInput]="containerId"  [containerUUID]="containerUUID" [viewMode]="mode" [formula]="formula"
                *ngIf="formula == Formula.Hoppus"></app-view-hoppus-summary>
           
                <app-bl-volumetric-summary [containerId]="containerId"  [containerUUID]="containerUUID"  [viewMode]="mode" [formula]="formula"
                *ngIf="formula == Formula.RectanglePrism"></app-bl-volumetric-summary>
        </ng-template>
    </p-tabPanel>


    <p-tabPanel header="Packing List"  [disabled]="(loadingSubject | async)?? false">
        <ng-template pTemplate="content">
            <app-bl-volumetric-packing-list [containerId]="containerId" [containerUUID]="containerUUID"   [viewMode]="mode" [formula]="formula"
                *ngIf="formula == Formula.RectanglePrism"></app-bl-volumetric-packing-list>
         
         
                <!-- <app-bl-hoppus-packing-list [containerId]="containerId" [viewMode]="mode" [formula]="formula"
                *ngIf="formula == Formula.Hoppus"></app-bl-hoppus-packing-list> -->

                <app-view-hoppus-measurement [containerIdInput]="containerId" [containerUUID]="containerUUID"   [viewMode]="mode" [formula]="formula"
                *ngIf="formula == Formula.Hoppus"></app-view-hoppus-measurement>
        </ng-template>
    </p-tabPanel>


</p-tabView>