import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConstants } from 'src/app/commons/app-constants';
import { SHARED_MODULES } from '../../shared-imports';


@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css'],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class PageNotFoundComponent {

  url!: string;


  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
   const url = AppConstants.WEBSITE_URL;
   // this.url = this.route.snapshot.url.map(segment => segment.path).join('/');
   window.location.href = url;

  }

}
