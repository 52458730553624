export const environment = {
  production: false,
  // apiUrl: 'https://1d1e-122-170-152-85.ngrok-free.app/',
  webUrl: 'http://localhost:4200/#/notfound',
  shareUrl: 'http://localhost:4300/p/',
  apiUrl: 'http://localhost:8080/',
  // apiUrl: 'https://api.rikexim.com/',
  forceRedirectUrl: 'http://localhost:4200/login?forceLogin=true',
  redirectUrl: 'http://localhost:4200/login?forceLogin=false',

//  httpProtocol: 'http://',
 // port: ':8080',
  //ipaddress: 'localhost',
  razorpayKey: 'rzprzp_test_BTK1Uy8am7Fi8d',
  baseUrl: '',
};
environment.baseUrl = environment.apiUrl;
// environment.ipaddress = environment.apiUrl;

