import { Injectable } from '@angular/core';
import { LoadingSite } from '../model/loading-site';

@Injectable({
  providedIn: 'root'
})
export class LoadingSiteSharedDataService {

  loadingSiteSharedData!: any;

  constructor() { }


  saveDataToLocalStorage(data: LoadingSite) {
    localStorage.setItem(this.loadingSiteSharedData, JSON.stringify(data));
  }

  getDataFromLocalStorage(): LoadingSite {
    const data = localStorage.getItem(this.loadingSiteSharedData);
    return JSON.parse(data!);
  }
}
