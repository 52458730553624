import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  MinLengthValidator,
} from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { City } from 'src/app/commons/models/city';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { PopulateDropdownService } from 'src/app/services/populate-dropdown.service';
import { BillingService } from '../../services/billing.service';
import { UserRegistrationService } from '../../services/user-registration.service';
import { Subscription } from 'rxjs';
import { SubscriptionService } from '../../services/subscription.service';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';

interface Country {
  id: number;
  name: string;
  code: string;
}
 
@Component({
  selector: 'app-subscription-billing-details',
  templateUrl: './subscription-billing-details.component.html',
  styleUrls: ['./subscription-billing-details.component.css'],
  standalone: true,
  imports: [SHARED_MODULES],
})
export class SubscriptionBillingDetailsComponent
  implements OnInit, AfterViewInit
{
  @Output() onBillDetailsSubmit = new EventEmitter<any>(); //
  @Output() billDetailsSubmitObject = new EventEmitter<any>(); //
  @Input() selectedCurrency: any;
  subscription!: Subscription;
  @Output() selectedCurrencyChange = new EventEmitter<string>();
  @Output() billingId = new EventEmitter<any>(); //

  updateCurrency(newCurrency: string) {
    this.selectedCurrency = newCurrency;
    this.selectedCurrencyChange.emit(newCurrency);
  }
  ngOnChanges(changes: SimpleChanges) {
    this.countries = [];
    if (
      changes['selectedCurrency'] &&
      !changes['selectedCurrency'].firstChange
    ) {
      const currencySelected = changes['selectedCurrency'].currentValue;
      if (currencySelected !== 'INR') {
        this.getAllCountries(true);
      } else {
        this.getAllCountries(false);
      }
    }
  }
  billingDetailsForm!: FormGroup;
  dummy: string[];
  cities!: City[];
  countries!: any[];

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: ToastMessagesService,
    private fb: FormBuilder,
    private userRegistrationService: UserRegistrationService,
    private populateDropdownService: PopulateDropdownService,
    private billingService: BillingService,
    private subscriptionService: SubscriptionService
  ) {
    this.dummy = ['Helo'];
    this.createForm();
  }
  ngAfterViewInit(): void {
    this.updateValidationWhenCurrencyChange();
  }
  ngOnInit(): void {
    this.subscription = this.subscriptionService.currentCurrency.subscribe(
      (currency) => {
        this.selectedCurrency = currency;
        console.log(
          `Currency Chang from Billing Details ${this.selectedCurrency}`
        );
        this.billingDetailsForm.reset();
      }
    );
  }

  hasGSTINOptions: any[] = [
    { name: 'Yes', key: true },
    { name: 'No', key: false },
  ];

  private async createForm() {
    this.billingDetailsForm = this.fb.group({
      id: [],
      currency: [],
      companyName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(64),
          Validators.pattern('^[a-zA-Z][a-zA-Z0-9-_\\s]*$'),
        ],
      ],
      address: ['', Validators.required],
      phone: ['', [Validators.minLength(8), Validators.pattern('^[0-9]*$')]], // Minimum length and digit only validator added here
      country: ['', [Validators.required]],
      state: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(32),
          Validators.pattern('^[a-zA-Z ]*$'),
        ],
      ],
      city: ['', [Validators.required, Validators.minLength(3)]],
      pincode: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(10),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      hasGSTIN: ['', Validators.required],
      gstin: [],
      // cityInput: ['', Validators.required],
      hasAgreeToTerms: ['', Validators.requiredTrue], // this will be checked against true
    });
    this.populateCities();
    this.getAllCountries();
  }

  private populateCities() {
    console.log(`populateCities `);
    this.populateDropdownService
      .getCities()
      .subscribe((cities) => (this.cities = cities));
  }

  onGstinClick(event: any) {
    console.log(`onGstinClick ${JSON.stringify(event)}`);
    const value = event.value;
    console.log(`onGstinClick ${value.key}`);
    const checked = value.key;
    if (checked === 'yes') {
    } else if (checked === 'no') {
    }
    this.billingDetailsForm?.get('hasGSTIN')?.valueChanges.subscribe((val) => {
      if (val && val.key === 'yes') {
        this.billingDetailsForm
          .get('gstin')
          ?.setValidators([Validators.required]);
      } else {
        this.billingDetailsForm.get('gstin')?.setValidators(null);
      }
      this.billingDetailsForm.get('gstin')?.updateValueAndValidity();
    });
  }
  /*  onCityChange(event: Event) {
     const city = this.billingDetailsForm.controls['city'].value;
     console.log(`onCity Change ${event.target} value is ${JSON.stringify(city)}`);
     if (city == null) {
       this.billingDetailsForm.controls['state'].patchValue('');
     } else
       this.billingDetailsForm.controls['state'].patchValue(city.stateId.name);
   } */

  onCityChange(event: any) {
    const city = this.billingDetailsForm.controls['city'].value;
    console.log(
      `onCity Change ${event.target} value is ${JSON.stringify(city)}`
    );
    if (city == null) {
      this.billingDetailsForm.controls['state'].patchValue('');
    } else {
      // Make sure city.stateId and city.stateId.name are defined
      if (city.stateId && city.stateId.name) {
        this.billingDetailsForm.controls['state'].patchValue(city.stateId.name);
      } else {
        console.log(
          `stateId or stateId.name not defined for city: ${JSON.stringify(
            city
          )}`
        );
      }
    }
    console.log(
      `Form Value after city change: ${JSON.stringify(
        this.billingDetailsForm.value
      )}`
    );
  }

  private getAllCountries(hideIndia: boolean = false) {
    this.userRegistrationService.getAllCountries().subscribe(
      (data) => {
        this.countries = data;
        this.countries = data.filter((country: Country) => country.id !== 77);

        console.log(`Data Countries ${JSON.stringify(data)}`); // {"id":77,"name":"India","code":"IN"},
      },
      (error) => {},
      () => {}
    );
  }

  onSubmit() {
    this.billingDetailsForm.markAllAsTouched();
    if (this.selectedCurrency !== 'INR') {
      console.log(
        `Selected Currency is ${this.selectedCurrency} ${
          this.selectedCurrency !== 'INR'
        }`
      );
      this.billingDetailsForm.get('gstin')?.setValidators(null);
      this.billingDetailsForm.get('hasGSTIN')?.setValidators(null);
      this.billingDetailsForm.get('hasGSTIN')?.clearValidators();
      // this.billingDetailsForm.get('city')?.setValidators(null);
      // this.billingDetailsForm.get('city')?.clearValidators();

      this.billingDetailsForm.get('hasGSTIN')?.updateValueAndValidity();
      this.billingDetailsForm.get('city')?.updateValueAndValidity();
      this.billingDetailsForm.get('cityInput')?.updateValueAndValidity();
      console.log(
        `City When Foreign its Country ${
          this.billingDetailsForm.get('city')?.value
        }`
      );
      //const cityInput = this.billingDetailsForm.get('cityInput')?.value;
      // this.billingDetailsForm.patchValue({ city: cityInput });
    } else {
      this.billingDetailsForm.get('cityInput')?.setValidators(null);
      this.billingDetailsForm.get('cityInput')?.clearValidators();

      this.billingDetailsForm.get('hasGSTIN')?.updateValueAndValidity();
      this.billingDetailsForm.get('city')?.updateValueAndValidity();
      this.billingDetailsForm.get('cityInput')?.updateValueAndValidity();
    }

    const controlKeys = Object.keys(this.billingDetailsForm.controls);
    const invalidControlKeys = controlKeys.filter((key) => {
      const control = this.billingDetailsForm.controls[key];
      return control.invalid;
    });
    console.log('Invalid control keys:', invalidControlKeys);

    console.log(`onSubmit isFormValid ${this.billingDetailsForm.valid}`);
    this.findInvalidControls();
    if (this.billingDetailsForm.valid) {
      //hasGSTIN
      this.billingDetailsForm.patchValue({ currency: this.selectedCurrency });
      const tempCountry = this.billingDetailsForm.get('country')?.value;
      const tempCity = this.billingDetailsForm.get('city')?.value;
      const tempState = this.billingDetailsForm.get('state')?.value;
      console.log(`tempCity ${JSON.stringify(tempCity)} `);
      console.log(`Country Selected is ${JSON.stringify(tempCountry)}`);
      this.billingDetailsForm.patchValue({ country: tempCountry.name });

      if (this.selectedCurrency !== 'INR') {
        // this.billingDetailsForm.patchValue({ city: tempCity.name });
      } else {
        this.billingDetailsForm.patchValue({ city: tempCity.name });
      }

      this.billingDetailsForm.patchValue({ state: tempState });

      const tempHasGSTIN = this.billingDetailsForm.get('hasGSTIN')?.value;
      if (tempHasGSTIN) {
        this.billingDetailsForm.patchValue({ hasGSTIN: tempHasGSTIN.key });
      }

      console.log(
        `Form Value is ${JSON.stringify(this.billingDetailsForm.value)}`
      );

      const tempCity1 = this.billingDetailsForm.get('city')?.value;
      console.log(`tempCity1  ${tempCity1}`);
      this.billingService.saveBilling(this.billingDetailsForm.value).subscribe(
        (data) => {
          console.log(`Data Saved Successfully ${JSON.stringify(data)}`);
          // this.billingId  = data.id;
          this.billingId.emit(data.id);
          this.messageService.showSuccessMessage(
            'Saved',
            'Data Successfully saved'
          );
          this.onBillDetailsSubmit.emit(true);
          this.billDetailsSubmitObject.emit(data);
          this.billingDetailsForm.patchValue(data);
          let countryToSelect = null;
          let cityToSelect = null;
          let hasGSTINToSelect = null;
          /// Country
          if (this.selectedCurrency === 'INR') {
            countryToSelect = this.countries.find((country) => {
              return country.name.toLowerCase() === 'India'.toLowerCase();
            });
            console.log(
              `country3 from DropDown is ${JSON.stringify(countryToSelect)}`
            );
            this.billingDetailsForm.patchValue({ country: countryToSelect });

            /// City

            cityToSelect = this.cities.find((city) => {
              return city.name.toLowerCase() === data.city.toLowerCase();
            });
            console.log(
              `cityToSelect from DropDown is ${JSON.stringify(cityToSelect)}`
            );
            this.billingDetailsForm.patchValue({ city: cityToSelect });

            //// hasGSTIN

            hasGSTINToSelect = this.hasGSTINOptions.find((options) => {
              return options.key === data.hasGSTIN;
            });
            console.log(
              `hasGSTINToSelect from DropDown is ${JSON.stringify(
                hasGSTINToSelect
              )}`
            );
            this.billingDetailsForm.patchValue({ hasGSTIN: hasGSTINToSelect });
          } else {
            const contrySaved = data.country;
            console.log(`After Saved COuntry is ${contrySaved}`);

            this.billingDetailsForm.patchValue({ country: contrySaved });
            countryToSelect = this.countries.find((country) => {
              return country.name.toLowerCase() === data.country.toLowerCase();
            });
            console.log(
              `country3 from DropDown is ${JSON.stringify(countryToSelect)}`
            );
            this.billingDetailsForm.patchValue({ country: countryToSelect });

            /// City
            /*  const cityInput = this.billingDetailsForm.get('cityInput')?.value;
               this.billingDetailsForm.patchValue({ city: cityInput });
               console.log(`cityInput ${JSON.stringify(cityInput)}`); */

            console.log(
              `cityToSelect from DropDown is ${JSON.stringify(cityToSelect)}`
            );
            // this.billingDetailsForm.patchValue({ city: cityToSelect });
          }
        },
        (error) => {
          this.messageService.showErrorMessage(
            'Error',
            'Error occured while saving data'
          );
          console.error(
            `Error occured while saving data ${JSON.stringify(error)}`
          );
          this.onBillDetailsSubmit.emit(false);
        },
        () => {}
      );
    }
  }

  updateValidationWhenCurrencyChange() {
    this.billingDetailsForm.patchValue({ city: '' });
    console.log(
      `updateValidationWhenCurrencyChange currency is ${this.selectedCurrency}`
    );
    if (this.selectedCurrency !== 'INR') {
      console.log(
        `Selected Currency is ${this.selectedCurrency} ${
          this.selectedCurrency !== 'INR'
        }`
      );
      this.billingDetailsForm.get('gstin')?.setValidators(null);
      this.billingDetailsForm.get('hasGSTIN')?.setValidators(null);
      this.billingDetailsForm.get('hasGSTIN')?.clearValidators();
      // this.billingDetailsForm.get('city')?.setValidators(null);
      // this.billingDetailsForm.get('city')?.clearValidators();

      this.billingDetailsForm.get('hasGSTIN')?.updateValueAndValidity();
      this.billingDetailsForm.get('city')?.updateValueAndValidity();
      console.log(
        `City When Foreign its Country ${
          this.billingDetailsForm.get('city')?.value
        }`
      );
      //const cityInput = this.billingDetailsForm.get('cityInput')?.value;
      // this.billingDetailsForm.patchValue({ city: cityInput });
    } else {
      this.billingDetailsForm.get('hasGSTIN')?.updateValueAndValidity();
      this.billingDetailsForm.get('city')?.updateValueAndValidity();
    }
  }

  private findInvalidControls() {
    const invalid = [];
    const controls = this.billingDetailsForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        // console.log(`findInvalidControls {}`, controls[name])
        invalid.push(name);
      }
    }
    return invalid;
  }
}
