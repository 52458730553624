import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastMessagesService {

  constructor(private messageService: MessageService) { }

  showSuccessMessage(summary: string, detail: string, sticky:boolean = false, key?:string | undefined): void {
    this.messageService.add({
      severity: 'success',
      summary,
      detail,
      sticky,
      key
    });
  }

  showErrorMessage(summary: string, detail: string, sticky:boolean = false): void {
    this.messageService.add({
      severity: 'error',
      summary,
      detail,
      sticky
    });
  }

  showWarningMessage(summary: string, detail: string, sticky:boolean = false): void {
    this.messageService.add({
      severity: 'warn',
      summary,
      detail,
      sticky
    });
  }

  showInfoMessage(summary: string, detail: string, sticky:boolean = false): void {
    this.messageService.add({
      severity: 'info',
      summary,
      detail,
      sticky
    });
  }

  clearMessage(){
    this.messageService.clear();
  }
}
