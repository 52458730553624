import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { Page } from 'src/app/commons/models/page';
import { Container } from '../../container/model/container';
import { Measurement } from '../model/measurement';
import { Image } from '../model/image';
import { MeasurementSummary } from '../model/measurement-summary';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class MeasurementService {
  constructor(private http: HttpClient) {}

  getData(): Observable<Measurement> {
    return this.http.get<Measurement>(AppConstants.API_URL + 'api/v1/measurements');
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  postData(data: Measurement): Observable<Measurement> {
    return this.http.post<Measurement>(
      AppConstants.API_URL + 'api/v1/measurements',
      data,
      { responseType: 'json' }
    );
  }

  addMeasurement(data: Measurement): Observable<Measurement> {
    return this.http.post<Measurement>(
      AppConstants.API_URL + 'api/v1/measurement',
      data,
      { responseType: 'json' }
    );
  }

  addMeasurements(
    data: Container,
    containerId: number
  ): Observable<Measurement[]> {
    // const url = AppConstants.API_URL + 'special/' + containerId; /containers/{containerId}/add-measurement
    const url = `${AppConstants.API_URL}api/v1/containers/${containerId}/add-measurement`;
    console.log(`Url is ${url}`);
    console.log(`Data to be saved is ${JSON.stringify(data)}`);
    return this.http.post<Measurement[]>(url, data, { responseType: 'json' });
  }

  /* getByMeasurementName(name: string): Observable<any | undefined> {
     const response = this.http.get(AppConstants.AUTH_API + `checkBuyerName?name=${name}`, httpOptions);
     console.log(`getByCompanyName Response is ${JSON.stringify(response)}`)
     return response;
   } 
 
   getByGST(gst: string): Observable<any | undefined> {
     const response = this.http.get(AppConstants.AUTH_API + `checkGST?gst=${gst}`, httpOptions);
     console.log(`getByGST Response is ${JSON.stringify(response)}`)
     return response;
   }*/
  // <Page<Book>
  getListMeasurements(httpParams?: HttpParams): Observable<Page<Measurement>> {
    let url = `${AppConstants.API_URL}api/v1/buyers?`;
    if (httpParams) {
      url = url + httpParams;
    }
    console.log(`url is ${url}`);
    return this.http.get<Page<Measurement>>(url);
  }

  getMeasurementsGlobalFilter(
    httpParams?: HttpParams
  ): Observable<Page<Measurement>> {
    let url = `${AppConstants.API_URL}api/v1/buyers/search?`;
    if (httpParams) {
      url = url + httpParams;
    }
    console.log(`url is ${url}`);
    return this.http.get<Page<Measurement>>(url);
  }

  deleteMeasurements1(id: any): Observable<string> {
    let url = `${AppConstants.API_URL}buyers/${id}`;
    console.log(`url is ${url}`);
    return this.http.delete<string>(url);
  }
  getImages() {
    return this.http
      .get<any>('assets/showcase/data/photos.json')
      .toPromise()
      .then((res) => <Image[]>res.data)
      .then((data) => {
        return data;
      });
  }

  getListContainerSummary(
    httpParams?: HttpParams
  ): Observable<Page<MeasurementSummary>> {
    let url = `${AppConstants.API_URL}api/v1/container-summary-filter?`;
    console.log(`getListContainerSummary url is ${url}`);
    if (httpParams) {
      url = url + httpParams;
    }
    return this.http.get<Page<MeasurementSummary>>(url);
  }

  getContainerSummaryByContainerId(
    containerId: number
  ): Observable<MeasurementSummary> {
    // let url = `${AppConstants.API_URL}containerSummaryByContainerId/${containerId}`; ///containers/{container-id}/summary
    let url = `${AppConstants.API_URL}api/v1/containers/${containerId}/summary`; ///containers/{container-id}/summary
    console.log(`url is ${url}`);
    return this.http.get<MeasurementSummary>(url);
  }

  getMeasurementSummaryGlobalFilter(
    httpParams?: HttpParams
  ): Observable<Page<MeasurementSummary>> {
    let url = `${AppConstants.API_URL}api/v1/container-summary-search?`;
    if (httpParams) {
      url = url + httpParams;
    }
    console.log(`url is ${url}`);
    return this.http.get<Page<MeasurementSummary>>(url);
  }

  deleteContainerSummary(id: any): Observable<string> {
    let url = `${AppConstants.API_URL}api/v1/delete-container/${id}`;
    console.log(`url is ${url}`);
    return this.http.delete<string>(url);
  }

  getListContainerSummary1(data: any,page:number,size:number): Observable<Page<MeasurementSummary>> {
    const url = AppConstants.API_URL + `api/v1/container-summary-filter?page=${page}&size=${size}`;
    console.log(`Url is getListContainerSummary1 ${url}`);
    return this.http.post<Page<MeasurementSummary>>(
      url,
      data,
      { responseType: 'json' }
    );
  }

  getListContainerSummaries(data: any,page:number,size:number): Observable<Page<MeasurementSummary>> {
    const url = AppConstants.API_URL + `test/tally-summaries?page=${page}&size=${size}`;
    console.log(`Url is getListContainerSummary1 ${url} and Data is ${JSON.stringify(data)}`);
    return this.http.post<any>(
      url,
      data,
      { responseType: 'json' }
    );
  }


  getContainerSummaryByContainerUUID(containerUUID: string): Observable<any> {
    let url = `${AppConstants.API_URL}public/api/v1/containers/${containerUUID}/summary`; ///containers/{container-uuid}/summary"
    console.log(`url is ${url}`);
    return this.http.get<any>(url);
  }

  getContainerOTTags(containerUUID: String): Observable<any> {
    let url = `${AppConstants.API_URL}public/api/v1/containers/${containerUUID}/og-tags`;  ///containers/{container-uuid}/og-tags
    console.log(`url is ${url}`);
    return this.http.get<any>(url);
  }
}
