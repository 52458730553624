import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { Location } from '../model/location';

@Injectable({
  providedIn: 'root',
})
export class LocationSelectionService {
  constructor(private httpClient: HttpClient) {}

  //   this.isDarkThemeEnabled = this.selectedTheme === Theme.BootstrapDark;
  /*

http://api.geonames.org/countryInfoJSON?username=rikexim

http://api.geonames.org/childrenJSON?geonameId=1269750&username=rikexim

http://api.geonames.org/childrenJSON?geonameId= 1270770&username=rikexim
 */

  getCountries(): Observable<any> {
    const url = `${AppConstants.API_URL}api/v1/geonames/countries`;
    console.log(`url getCountry ${url} `);
    return this.httpClient.get<any>(url);
  }

  getCountriesNEW(): Observable<any> {
    const url = `${AppConstants.API_URL}public/api/v1/countrystatecity/countries`;
    console.log(`url getCountry ${url} `);
    return this.httpClient.get<any>(url);
  }

  getStates(countryId: number): Observable<any> {
    const url = `${AppConstants.API_URL}api/v1/geonames/states/${countryId}`;
    console.log(`url getState ${url} `);
    return this.httpClient.get<any>(url);
  }

  getStatesNEW(countryId: number): Observable<any> {
    const url = `${AppConstants.API_URL}public/api/v1/countrystatecity/states?countryId=${countryId}`;
    console.log(`url getState ${url} `);
    return this.httpClient.get<any>(url);
  }

  getCities(stateId: number | undefined): Observable<any> {
    const url = `${AppConstants.API_URL}api/v1/geonames/cities/${stateId}`;
    console.log(`url getCity ${url} `);
    return this.httpClient.get<any>(url);
  }

  getCitiesNEW(stateId: number | undefined): Observable<any> {
    const url = `${AppConstants.API_URL}public/api/v1/countrystatecity/cities?stateId=${stateId}`;
    console.log(`url getCity ${url} `);
    return this.httpClient.get<any>(url);
  }

  getLocationData(location: Location): Location {
    // Transform the location data if necessary or just return the location as is
    return location;
  }

  private locationDataLoadingSource = new BehaviorSubject<boolean>(false);
  currentLocationDataLoading = this.locationDataLoadingSource.asObservable();

  changeLocationDataLoading(status: boolean) {
    this.locationDataLoadingSource.next(status);
  }
}
