<div class="flex justify-content-end flex-wrap card-container green-container">
    <div class="flex align-items-center justify-content-center m-2">

        <p-dropdown #currency [options]="currencies" optionLabel="name" [(ngModel)]="selectedCurrencyDropDown"
            (onChange)="onCurrencyChange($event)"></p-dropdown>
    </div>
</div>
<div class="formgrid grid justify-content-center">

    <div *ngIf="(packagesList?.length?? 0) > 0; else noPackageFound"></div>
    <div class="field col-12 xl:col-3 lg:col-3 md:col-2 sm:col-1 xs:col-1 border-round"
        *ngFor="let package of packagesList">
        <!-- {{ package?.sortOrder?? 0}} -->

        <div [ngClass]="{'border-solid border-primary-500 border-round': selectedPackage === package}">
            <p-card (click)="selectPackage(package)">
                <p class="text-2xl font-bold">{{ package.name }}</p>
                <div class="text-base m-0">
                    <p class="text-xl  m-0">
                        <p-chip [style]="{'background-color': '#D4AF37', 'color': 'white'}" styleClass="font-bold">
                            {{ (package.titleOfferPrice) | currency:
                            package.isoCurrencyName }}
                        </p-chip>


                    </p>
                    <p class="text-sm line-through ml-2" *ngIf="package.titlePrice !== package.titleOfferPrice">
                        <!-- {{ (package?.subscriptionPrice?.price ?? 0) | currency: package?.currency }} -->
                        {{ (package.titlePrice) | currency:
                        package.isoCurrencyName }}
                    </p>
                </div>
                <ul *ngIf="!package.subscriptionFeatures || package.subscriptionFeatures.length > 1">
                    <li *ngFor="let feature of package.subscriptionFeatures">
                        {{feature}}
                    </li>
                </ul>
                <ng-template pTemplate="footer" class="align-content-center">

                </ng-template>
            </p-card>
        </div>


    </div>
    <ng-template #noPackageFound>
        <div class="flex flex-wrap align-items-center justify-content-center">
            <p class="text-justify line-height-3" style="width: 50vw;">
                We're currently at full capacity and temporarily unable to accept new subscriptions or renewals.
            </p>
            <p class="text-justify line-height-3" style="width: 50vw;">
                We're expanding our capabilities and will be ready to welcome you soon. Please check back in a short
                while.
            <p class="text-justify line-height-3" style="width: 50vw;">
                Your
                patience and support are greatly appreciated!
            </p>
        </div>
    </ng-template>
</div>

<div class="formgrid grid">
    <!-- p-button label="Subscribe" [disabled]="!selectedPackage"
        (click)="confirmPackageSelection($event)"></p-button> -->
</div>