import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of, from, switchMap } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { BuyerService } from '../../buyer/services/buyer.service';
import { EncryptionService } from 'src/app/services/encryption.service';
import { ContainerViewService } from '../../container/services/view-container.service';

@Injectable({
  providedIn: 'root',
})
export class ContainerViewResolver implements Resolve<any> {
  constructor(
    private containerViewService: ContainerViewService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const id = route.paramMap.get('id'); // Get the ID directly from the URL
    console.log(`Container ID IS ${id}`);
    if (id) {
      return this.containerViewService.getViewContainer(id).pipe(
        map((response) => ({
          id, // Include the ID with the response
          containerData: response, // Actual response data
        })),
        tap((resolved) => {
          console.log(`Resolved data: ${JSON.stringify(resolved)}`);
        }),
        catchError((error) => {
          console.error(`ContainerViewResolver error: ${JSON.stringify(error)}`);
          // Optionally redirect on error
          // this.router.navigate(['/error']);
          return of(null);
        })
      );
    } else {
      console.error('ID is missing in the route.');
      // Optionally redirect on missing ID
      // this.router.navigate(['/error']);
      return of(null);
    }
  }
}

