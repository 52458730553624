<div class="card">
    <p-chart type="bar" [data]="basicData" [options]="basicOptions" [responsive]="true"></p-chart>
</div>

<div class="card">
    <p-chart type="line" [data]="basicData" [options]="basicOptions" [responsive]="true" />
</div>

<div class="card flex justify-content-center">
    <p-chart type="pie" [data]="basicData" [options]="basicOptions" [responsive]="true" />
</div>
