import { Injectable } from '@angular/core';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { Observable, from } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor() { }

  async getDeviceId(): Promise<string> {
     const fp = await FingerprintJS.load();
    const result = await fp.get();
    // This is the visitor identifier:
    const visitorId = result.visitorId;
   // console.log(`Visitor Id is ${visitorId}`); 
    return visitorId;
  }

  getDeviceIdObservable(): Observable<string> {
    return from(
      new Promise<string>((resolve, reject) => {
        FingerprintJS.load()
          .then((fp) => fp.get())
          .then((result) => {
            const visitorId = result.visitorId;
         //   console.log(`Visitor Id is ${visitorId}`);
            resolve(visitorId);
          })
          .catch(reject);
      })
    );
  }
}
