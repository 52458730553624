import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { RazorpayPayment } from '../model/razorpay-payment';
import { SubscriptionType } from '../model/subscription-type';
import { SubscriptionPackagesView } from '../model/subscription-packages-view';
import { SubscriptionDurationSelected } from '../model/subscription-duration-selected';
import { RazorpayPaymentResponse } from '../model/razorpay-payment-response';
import { SelectedSubscriptionPackage } from '../../tally-sheet/account-setup/subscription-package/subscription-package.component';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private currencySource = new BehaviorSubject<string>('INR'); // set default value

  currentCurrency = this.currencySource.asObservable();

  constructor(private httpClient: HttpClient) { }

  getAllSubscriptionPackages(currency: string): Observable<SubscriptionType> {
    const url =
      AppConstants.API_URL +
      'api/v1/subscription-packages?currency=' +
      currency;
    console.log(`getAllSubscriptionPackages ----> ${url}`);
    return this.httpClient.get<SubscriptionType>(`${url}`);
  }

  getSubscriptionDetails(
    currencyId: number,
    subscriptionTypeId: number
  ): Observable<SubscriptionDurationSelected[]> {
    const url = `${AppConstants.API_URL}api/v1/subscription-package-price-details?currencyId=${currencyId}&subscriptionTypeId=${subscriptionTypeId}`;
    console.log(`getAllSubscriptionPackages ${url}`);
    return this.httpClient.get<SubscriptionDurationSelected[]>(`${url}`);
  }

  changeCurrencyBehaviorSubjectb(currency: string) {
    this.currencySource.next(currency);
  }

  createOrder(data: any): Observable<RazorpayPayment> {
    const url = AppConstants.API_URL + 'api/v1/subscription-orders';
    console.log(`createOrder on Server ${url}`);
    return this.httpClient.post<any>(`${url}`, data);
  }

  getUserCountry(): Observable<SubscriptionType> {
    const url = AppConstants.API_URL + 'api/v1/util/user-country';
    console.log(`getUserCountry Url: ${url}`);
    return this.httpClient.get<any>(`${url}`);
  }

  private accordionStateSource = new Subject<boolean>();
  accordionState$ = this.accordionStateSource.asObservable();

  changeAccordionState(newState: boolean) {
    this.accordionStateSource.next(newState);
  }

  setApplyPromocode(
    promoCode: string,
    subscriptionId: number,
    subscriptionPricingsId: number
  ): Observable<any> {
    const url =
      AppConstants.API_URL +
      `api/v1/apply-promotions?promoCode=${promoCode}&subscriptionId=${subscriptionId}&subscriptionPricingId=${subscriptionPricingsId}`;
    console.log(`getAllSubscriptionPackages ${url}`);
    return this.httpClient.post<SubscriptionType>(`${url}`, '');
  }
  onPaymentResponse(response: RazorpayPaymentResponse) {
    console.log(`onPaymentResponse i ${JSON.stringify(response)}`);
    const url = AppConstants.API_URL + 'api/v1/handle-payment-response';
    return this.httpClient.put<any>(url, response);
  }  

  getSubscriptionPackages(): Observable<any> {
    const url = `${AppConstants.API_URL}api/v1/subscriptions`;
    console.log(`getAllSubscriptionPackages ${url}`);
    return this.httpClient.get<any>(`${url}`, { withCredentials: true });
  }


  saveCart(selectedPackage: SelectedSubscriptionPackage): Observable<any> {
    const url = `${AppConstants.API_URL}api/v1/subscriptions/cart`;
    console.log(`save Cart ${url}`);
    return this.httpClient.post<any>(`${url}`, selectedPackage, { withCredentials: true });
  }

  saveTempCart(selectedPackage: any): Observable<any> {
    const url = `${AppConstants.API_URL}api/v1/subscriptions/temp-cart`;
    console.log(`save Cart ${url}`);
    return this.httpClient.post<any>(`${url}`, selectedPackage, { withCredentials: true });
  }

  getCart(): Observable<any> {
    const url = `${AppConstants.API_URL}api/v1/subscriptions/cart`;
    console.log(`get Cart ${url}`);
    return this.httpClient.get<any>(`${url}`, { withCredentials: true });
  }

  getCartSubscriptionPeriod(address?: any) {
    let url;

    url = `${AppConstants.API_URL}api/v1/subscriptions/setup-cart`;

    console.log(`get getCartSubscriptionPeriod url is ${url} and body is ${JSON.stringify(location)}`);
    return this.httpClient.post<any>(`${url}`, address, { withCredentials: true });
  }

  applyPromocode(data: any) {
    const url = `${AppConstants.API_URL}api/v1/subscriptions/apply-promo`;
    console.log(`get Cart ${url}`);
    return this.httpClient.put<any>(`${url}`, data, { withCredentials: true });
  }


}
