import { Injectable } from '@angular/core';
import { Seller } from '../model/seller';

@Injectable({
  providedIn: 'root'
})
export class SellerSharedDataService {
  sellerSharedData!: any;

  constructor() { }


  saveDataToLocalStorage(data: Seller) {
    localStorage.setItem(this.sellerSharedData, JSON.stringify(data));
  }

  getDataFromLocalStorage(): Seller {
    const data = localStorage.getItem(this.sellerSharedData);
    return JSON.parse(data!);
  }
}
