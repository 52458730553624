import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { ActivatedRoute, Router } from '@angular/router';
 
import { WebSocketService } from 'src/app/services/tally-sheet/socket.service';
import { AuthService } from 'src/app/services/tally-sheet/auth.service';


@Component({
  selector: 'app-confirm-logout-devices',
  standalone: true,
  imports: [ConfirmDialogModule, ButtonModule, ToastModule],
  templateUrl: './confirm-logout-devices.component.html',
  styleUrl: './confirm-logout-devices.component.scss',
  providers: [ConfirmationService, MessageService]
})
export class ConfirmLogoutDevicesComponent implements OnInit {

  position: string = 'center';

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private service: WebSocketService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) { }

  confirm(position: string) {
    this.confirmationService.confirm({
      header: 'Confirmation',
      message: 'A session is already active on another device. Do you want to log out from other devices?',
      acceptIcon: 'pi pi-times mr-2',
      rejectIcon: 'pi pi-check mr-2',
      rejectButtonStyleClass: 'p-button-sm',
      acceptButtonStyleClass: 'p-button-outlined p-button-sm',
      acceptLabel: 'No',
      rejectLabel: 'Yes',
      accept: () => {
        sessionStorage.clear();
        this.router.navigate(['/register'])
      },
      reject: () => {
        //alert(this.authService.getToken());
         this.authService.logoutAllOtherDevices().subscribe(
          (success: any) => {
            console.log(`logoutAllOtherDevices success ${JSON.stringify(success)}`);
          //  this.authService.saveToken(success.token);
            
            window.location.replace(success.redirectUrl);
          },
          (error: any) => {
            console.log(`logoutAllOtherDevices error ${JSON.stringify(error)}`)
          },
        ); 
        
      }
    });
  }

  ngOnInit(): void {
    this.confirm('center');
    //const token = this.authService.getToken();
    // console.log(`ConfirmLogoutDevicesComponent ${token}`)

    this.getTokenFromQueryParameter();
  }


  private getTokenFromQueryParameter() {
    this.route.queryParams.subscribe(params => {
      const token = params['pendingToken'];
      // Now use this token to set your headers for subsequent API requests.
      console.log(token);
      if(token){
       // this.authService.saveToken(token);
      }
    });
  }
}
