<!-- <p-toast></p-toast> -->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"
[style]="{ 'width': '70vw','min-width': '280px','max-width': '600px' }" ></p-confirmDialog>

<div style="padding: 0;margin: 0;">
    <div class="pb-3">
        <p-breadcrumb [model]="breadCrumbitems" [home]="home"></p-breadcrumb>
    </div>


    


    <div style="width: 90vw">
        <p-card [header]="formHeader" [subheader]="formSubHeader" class="w-full m-0 p-0">

           
            <p-steps [model]="stepMenu" [readonly]="false" [activeIndex]="activeIndex"></p-steps>




            <!-- STEP 1 -->
            <div *ngIf="activeIndex === 0">



                <div class="flex justify-content-center flex-wrap">
                    <div class="flex align-items-center justify-content-center m-2">
                        <div class="field col-12 md:col-12 mt-3">
                            <label class="text-lg pb-2" for="containerNumbers">Container Number <i class="text-red-400 mr-2">*</i></label>
                            <div class="p-inputgroup" class="w-full" *ngIf="!editModeContainerNumber; else editMode">
                                <!--  <p-autoComplete [(ngModel)]="selectedContainer" [suggestions]="containerNumbers"
                                    [minLength]="2" field="containerNumber" dataKey="containerId"
                                    (completeMethod)="searchContainerNumber($event)"
                                    (onSelect)="onContainerAutoCompleteSelect($event); disableTableDiv=false"
                                    placeholder="Select a container" [forceSelection]="true" [required]="true"
                                    [showEmptyMessage]="true" emptyMessage="No matching results found"></p-autoComplete> -->
                                <p-autoComplete [minLength]="3" [(ngModel)]="selectedContainer" field="containerNumber"
                                    [suggestions]="containerNumbers" (completeMethod)="searchContainer($event)"
                                    placeholder="Start typing to search..." [forceSelection]="true" [required]="true"
                                    [showEmptyMessage]="true" emptyMessage="No matching results found"></p-autoComplete>

                                    <!-- <p-button icon="pi pi-check" styleClass="p-button-rounded p-button-text"></p-button> -->

                                <p-button icon="pi pi-plus" styleClass="p-button-sm p-button-default ml-1"
                                    *ngIf="!containerSidebarVisible" (click)="containerSidebarVisible = true"
                                    pTooltip="Add new supplier" tooltipPosition="top" [showDelay]="300" #nextButton>
                                </p-button>
                            </div>
                            <button pButton pRipple type="button" icon="pi pi-arrow-right" label="Next" iconPos="right"
                                class="p-button-default p-button-lg mt-3" (click)="onNext()"
                                [disabled]="!this.selectedContainer?.containerId?? null"></button>

                            <ng-template #editMode>
                                <div style="display: flex; justify-content: center; align-items: center;"
                                    *ngIf="!this.selectedContainer.containerNumber">
                                    <p style="margin-right: 10px;"> <i class="pi pi-spin pi-spinner"
                                            style="font-size: 2rem"></i></p>
                                </div>
                                <div style="display: flex; justify-content: center; align-items: center;"
                                    *ngIf="this.selectedContainer.containerNumber">
                                    <p style="margin-right: 10px;">{{this.selectedContainer.containerNumber}}</p>
                                    <p-button icon="pi pi-pencil"
                                        styleClass="p-button-rounded p-button-danger p-button-text p-button-sm"
                                        (click)="onEditContainerNumber()"></p-button>
                                </div>



                            </ng-template>
                            <div class="field col-12 md:col-6"></div>
                            <div class="field col-12 md:col-12">

                            </div>
                        </div>
                    </div>
                </div>



                <!-- <div class="field col-12 md:col-12 mt-3">
                        <label for="containerNumbers">Container Number <i class="text-red-400 mr-2">*</i></label>
                        <div class="p-inputgroup" class="w-full">
                            <p-autoComplete [(ngModel)]="selectedContainer" [suggestions]="containerNumbers"
                                [minLength]="2" *ngIf="!editModeContainerNumber; else editMode" field="containerNumber"
                                dataKey="containerId" (completeMethod)="searchContainerNumber($event)"
                                (onSelect)="onContainerAutoCompleteSelect($event); disableTableDiv=false"
                                placeholder="Select a container" [forceSelection]="true" [required]="true"
                                [showEmptyMessage]="true" emptyMessage="No matching results found"></p-autoComplete>

                            <p-button icon="pi pi-plus" styleClass="p-button-sm p-button-default ml-1"
                                *ngIf="!containerSidebarVisible" (click)="containerSidebarVisible = true"
                                pTooltip="Add new supplier" tooltipPosition="top" [showDelay]="300">
                            </p-button>
                        </div>
                        <div class="field col-12 md:col-12 mt-0">
                            <span class="text-xs">Enter container number to start</span>
                        </div>
                        <ng-template #editMode>

                            <button pButton pRipple type="button" icon="pi pi-pencil"
                                class="p-button-danger p-button-rounded p-button-sm"
                                (click)="onEditContainerNumber()"></button>
                        </ng-template>
                        <div class="field col-12 md:col-6"></div>
                        <div class="field col-12 md:col-12">

                        </div>
                    </div> -->

            </div>


            <!-- STEP 2 -->

            <div *ngIf="activeIndex === 1 && selectedContainer && selectedContainer.containerId">
                <p-speedDial [model]="measurementSpeedDialItems" direction="up-left" type="quarter-circle" [radius]="120"
    class="ml-5 my-speed-dial" [rotateAnimation]="true"></p-speedDial>
                <div class="formgrid grid">
                    <p-tabView (onChange)="onTabChange($event, selectedContainer.containerNumber)" class="w-full mt-6"
                        *ngIf="selectedContainer.containerId">

                        <p-tabPanel header="Packing List">
                            <ng-container *ngIf="!isLoading else loadingPackingList">
                                <!-- <p-toolbar>

                                    <div class="card flex justify-content-left mb-2">
                                        <p-menu #menu [model]="toolbarPopUpMenu" [popup]="true">
                                        </p-menu>
                                        <button pButton type="button" (click)="menu.toggle($event)" icon="pi pi-bars"
                                            label="Menu"></button>
                                        <p-button class="ml-2" label="Save" (click)="saveList()"
                                            icon="pi pi-save"></p-button>
                                    </div>
                                </p-toolbar> -->
                                <div class="scroll-panel-wrapper">
                                    <div class="scroll-top-button">
                                        <button pButton pRipple icon="pi pi-chevron-up" (click)="scrollToTop()"
                                            *ngIf="fieldArray.length >5"></button>
                                    </div>
                                    <p-scrollPanel #scrollContainer styleClass="h-30rem" [style]="{ width: '100%' }"
                                        #scrollPanel (scroll)="onScroll()">
                                        <div style="width: 90vw">
                                            <table class="tableFixHead mt-5" style="width:100%">
                                                <thead>
                                                    <tr>
                                                        <th class="w-3rem"> </th>
                                                        <th class="td-align w-3rem" style="min-width: 3rem">SR</th>
                                                        <th class="td-align w-5rem" style="min-width: 5rem">Length</th>
                                                        <th class="td-align w-5rem" style="min-width: 5rem">Girth</th>
                                                        <th class="td-align w-5rem" style="min-width: 4rem">Girth -
                                                            {{girthAllowance}}</th>
                                                        <th class="td-align w-5rem" style="min-width: 4rem">G Vol</th>
                                                        <th class="td-align w-5rem" style="min-width: 4rem">N Vol</th>
                                                        <th class="td-align w-6rem" style="min-width: 4rem">Add</th>
                                                        <th class="td-align w-6rem" style="min-width: 4rem">Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody #ITable>
                                                    <tr *ngFor="let field of fieldArray; let i = index">
                                                        <td class="status-valid"
                                                            *ngIf="field.status===true;else elseBlock">
                                                            <i class="pi pi-circle-fill"></i>
                                                        </td>
                                                        <ng-template #elseBlock>
                                                            <td class="status-invalid">
                                                            </td>
                                                        </ng-template>
                                                        <td class="td-align text-center">
                                                            {{ i +1 }}
                                                        </td>
                                                        <td class="td-align"
                                                            [ngClass]="{'error': field.grossLength! > maxLengthConstraint ||  field.grossLength! < minLengthConstraint ||  field.grossLength! %5!==0 }">
                                                            <input type="text" pInputText class="input-cell text-center"
                                                                type="text" [(ngModel)]="field.grossLength"
                                                                name="{{field.grossLength!}}" #lengthInput
                                                                (keyup.enter)="onEnterLength(i)"
                                                                (keydown.meta.enter)="focusOnNewRow()"
                                                                (keydown.meta.arrowdown)="onCtrlArrowDown(i,2)"
                                                                (keydown.meta.arrowup)="onCntrlArrowUp(i,2)"
                                                                (keydown.meta.arrowleft)="onCtrlArrowLeft(i,2)"
                                                                (keydown.meta.arrowright)="onCtrlArrowRight(i,2)"
                                                                (keyup)="setStatus()"
                                                                onkeypress="return String.fromCharCode(event.charCode).match(/[0-9.]/g) != null" />
                                                        </td>
                                                        <td [ngClass]="getCSSClass(field.grossGirth!)">
                                                            <input class="input-cell text-center" type="text" pInputText
                                                                [(ngModel)]="field.grossGirth!"
                                                                name="{{field.grossGirth!}}"
                                                                (keyup.enter)="onEnterGirth(i)"
                                                                (keydown.meta.enter)="focusOnNewRow()"
                                                                (keydown.meta.arrowdown)="onCtrlArrowDown(i,3)"
                                                                (keydown.meta.arrowup)="onCntrlArrowUp(i,2)"
                                                                (keydown.meta.arrowleft)="onCtrlArrowLeft(i,3)"
                                                                (keydown.meta.arrowright)="onCtrlArrowRight(i,3)"
                                                                (keyup)="setStatus()"
                                                                onkeypress="return String.fromCharCode(event.charCode).match(/[0-9.]/g) != null" />
                                                        </td>

                                                        <td class="td-align text-center">{{field.netGirth}} </td>
                                                        <td class="td-align text-center">{{field.grossCbm}} </td>
                                                        <td class="td-align text-center">{{field.netCbm}} </td>
                                                        <td class="td-align text-center">
                                                            <p-button icon="pi pi-plus"
                                                                styleClass="p-button-rounded p-button-success p-button-outlined plusbutton"
                                                                class="plusbutton"
                                                                (click)="addFieldValueAtIndex(i)"></p-button>

                                                        </td>
                                                        <td class="td-align text-center">
                                                            <p-button icon="pi pi-minus"
                                                                styleClass="p-button-rounded p-button-danger p-button-outlined plusbutton"
                                                                class="plusbutton"
                                                                (click)="deleteFieldValue(i)"></p-button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="status-valid">
                                                        </td>
                                                        <ng-template #elseBlock>
                                                            <td class="status-invalid">
                                                            </td>
                                                        </ng-template>
                                                        <td class="td-align">
                                                        </td>
                                                        <td class="td-align"
                                                            [ngClass]="{'error': newAttribute.grossLength! > maxLengthConstraint ||  newAttribute.grossLength! < minLengthConstraint || newAttribute.lengthError === true ||  newAttribute.grossLength! %5!==0  }">

                                                            <input class="input-cell" pInputText pAutoFocus
                                                                [autofocus]="true" type="type" id="newAttributeLength"
                                                                [(ngModel)]="newAttribute.grossLength!"
                                                                name="newAttributeLength" #newAttributeLength
                                                                (keyup.enter)="onEnterLength(0)"
                                                                (focusout)="focusOut($event,'newAttributeLength')"
                                                                (keydown.meta.arrowup)="onCntrlArrowUp(-1,2)"
                                                                (keydown.meta.arrowleft)="onCtrlArrowLeft(-1,2)"
                                                                (keydown.meta.arrowright)="onCtrlArrowRight(-1,2)"
                                                                onkeypress="return String.fromCharCode(event.charCode).match(/[0-9.]/g) != null" />
                                                        </td>
                                                        <td class="td-align"
                                                            [ngClass]="{'error': newAttribute.grossGirth! > maxGirthConstraint ||  newAttribute.grossGirth! < minGirthConstraint  || newAttribute.girthError === true}">
                                                            <input class="input-cell" pInputText type="type"
                                                                id="newAttributeGirth"
                                                                [(ngModel)]="newAttribute.grossGirth!"
                                                                name="newAttributeGirth" #newAttributeGirth
                                                                (keyup.enter)="onEnterGirth(0)"
                                                                (focusout)="focusOut($event,'newAttributeGirth')"
                                                                (keydown.meta.arrowup)="onCntrlArrowUp(-1,3)"
                                                                (keydown.meta.arrowleft)="onCtrlArrowLeft(-1,3)"
                                                                (keydown.meta.arrowright)="onCtrlArrowRight(-1,3)"
                                                                onkeypress="return String.fromCharCode(event.charCode).match(/[0-9.]/g) != null" />
                                                        </td>

                                                        <td class="td-align">{{newAttributeNetCbm}}</td>
                                                        <td class="td-align">{{newAttributeNetGirth}}</td>
                                                        <td class="td-align">{{newAttributeGrossCbm}} </td>
                                                        <td style="text-align: center;" colspan="2" class="td-align">
                                                            <p-button icon="pi pi-check"
                                                                styleClass="p-button-rounded p-button-info p-button-outlined plusbutton"
                                                                class="plusbutton" (click)="addFieldValue()"></p-button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot #ITableFooter style="text-align: center;height: 3rem;">
                                                    <tr style="border: 1px solid" class="font-bold">
                                                        <td>Total</td>
                                                        <td>{{logsCount}}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{{totalGrossVol | number:'1.3-3'}}</td>
                                                        <td>{{totalNetVol | number:'1.3-3'}}</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                            </table>
                                        </div>
                                    </p-scrollPanel>
                                    <div class="scroll-bottom-button">
                                        <button pButton pRipple icon="pi pi-chevron-down" *ngIf="fieldArray.length >5"
                                            (click)="scrollToBottom()"></button>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-template #loadingPackingList>
                                <div class="flex justify-content-start flex-wrap card-container blue-container"
                                    *ngIf="isLoading">
                                    <div
                                        class="flex align-items-center justify-content-center w-full text-white border-round m-2">
                                        <p-progressSpinner styleClass="w-4rem h-4rem"></p-progressSpinner>
                                    </div>
                                    <div
                                        class="flex align-items-center justify-content-center w-full text-white border-round m-2">
                                        <div class="p-mt-2">Please wait while loading...</div>
                                    </div>




                                </div>
                            </ng-template>
                        </p-tabPanel>


                        <p-tabPanel header="Photos" class="w-full" [disabled]="!selectedContainer.containerId">
                            <ng-template pTemplate="content" class="w-100">
                                <app-photos [inputPhotos]="photos"
                                    [containerId]="selectedContainer.containerId || 0"></app-photos>
                            </ng-template>
                        </p-tabPanel>



                        <p-tabPanel header="Summary" [disabled]="!selectedContainer.containerId"
                            [disabled]="!selectedContainer.containerId">
                            <app-summary [inputSummary]="summary"></app-summary>
                        </p-tabPanel>
                    </p-tabView>
                </div>
            </div>

        </p-card>
    </div>

    <p-dialog [(visible)]="showCriteriaDialog" [style]="{ 'width': '70vw','min-width': '280px','max-width': '600px' }"
        [closeOnEscape]="true"  >
        <ng-template pTemplate="header">
            <p class="font-bold"> Set Criteria </p>
        </ng-template>

        <div class="card">
            <div class="formgrid grid">
                <div class="formgrid grid">
                    <div class="field col-12 md:col-6">
                        <label for="firstname2">Girth Allowance</label> <i class="ml-2 text-red-500">*</i>
                        <input id="firstname2" type="text" [(ngModel)]="girthAllowance"
                            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    </div>
                    <div class="field col-12 md:col-6">
                        <label for="lastname2">Length Allowance</label> <i class="ml-2 text-red-500">*</i>
                        <input id="lastname2" type="text" [(ngModel)]="lengthAllowance"
                            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    </div>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="firstname2">Minimum Girth</label> <i class="ml-2 text-red-500">*</i>
                    <input id="firstname2" type="text" [(ngModel)]="minGirthConstraint"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                </div>
                <div class="field col-12 md:col-6">
                    <label for="lastname2">Maximium Girth</label> <i class="ml-2 text-red-500">*</i>
                    <input id="lastname2" type="text" [(ngModel)]="maxGirthConstraint"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-12 md:col-6">
                    <label for="firstname2">Minimum Length</label> <i class="ml-2 text-red-500">*</i>
                    <input id="firstname2" type="text" [(ngModel)]="minLengthConstraint"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                </div>
                <div class="field col-12 md:col-6">
                    <label for="lastname2">Maximum Length</label> <i class="ml-2 text-red-500">*</i>
                    <input id="lastname2" type="text" [(ngModel)]="maxLengthConstraint"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                </div>
            </div>
        </div>

        <ng-template pTemplate="footer">
            *Data here will not be persist.
            <p-button label="Close" (onClick)="showCriteriaDialog = false"></p-button>
        </ng-template>
    </p-dialog>

    <p-dialog header="Auto Save Interval" [(visible)]="showAutoSaveIntervalDialog" [style]="{ 'width': '70vw','min-width': '280px','max-width': '600px' }">
        <div class="col-12 sm:12 md:col-6 lg:col-6 xl:col-6">
            <label for="saveInterval">Automatically save after minutes entered</label>
            <p-inputNumber [(ngModel)]="saveInterval" inputId="minmax" inputId="minmax" mode="decimal" [min]="2"
                [max]="20" [showButtons]="true" [step]="1" mode="decimal" suffix=" mins">
            </p-inputNumber>
        </div>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" (click)="showAutoSaveIntervalDialog = false" label="Ok"
                styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
</div>
<p-sidebar [(visible)]="containerSidebarVisible" position="right" [style]="{'width': '50vw'}" (onHide)="setPageTitle()">
    <app-container *ngIf="containerSidebarVisible" [showBreadcrumb]="false" [isSubmittedFromSidebar]="false"
        [isAddLoadingSiteFromMeasurement]="true" (containerSubmit)="onContainerSubmit($event)"
        titleFromSidebar="Add New Container" headerFromSidebar="Create Container"
        subHeaderFromSidebar="Input new container details"></app-container>
</p-sidebar>