import { Injectable } from "@angular/core";
import { AbstractControl, AsyncValidatorFn, FormControl, ValidatorFn } from "@angular/forms";
import { Observable, of } from "rxjs";
import { map, debounceTime, take, switchMap, tap } from "rxjs/operators";
import { UserService } from "../services/user.service";
import { Validators } from '@angular/forms';

/*
    this is implemented to test Async validations
*/


function isEmptyInputValue(value: any): boolean {
    // we don't check for string here so it also works with arrays
    return value === null || value.length === 0;
}

@Injectable({
    providedIn: "root"
})

export class CustomEmailValidator {

    constructor(private userService: UserService) { }

    remoteValidator(): AsyncValidatorFn {
        // return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
        return (
            control: AbstractControl
        ):
            | Promise<{ [key: string]: any } | null>
            | Observable<{ [key: string]: any } | null> => {
            return this.userService.getByEmail(control.value).pipe(

                map(resp => {
                    console.log('response is ' + JSON.stringify(resp))
                    if (resp!["success"] == true) {
                        console.log(`in if and return null`)
                        return null; // no error 
                    } else {
                        console.log(`in else and return remoteError true`)
                        return { 'remoteError': true };
                    }
                }),

            )
          /*.delay(1000).map(res => {
            if (res.status === 'ok') {
              return null; // no error
            } else {
              return { 'remoteError': true }; // validation error
            }
          })*/ ;
        };
    }


    existingEmailValidator(initialEmail: string = ""): AsyncValidatorFn {
        return (
            control: AbstractControl
        ):
            | Promise<{ [key: string]: any } | null>
            | Observable<{ [key: string]: any } | null> => {
            if (isEmptyInputValue(control.value)) {
                return of(null);
            } else if (control.value === initialEmail) {
                return of(null);
            } else {
                return control.valueChanges.pipe(
                    debounceTime(500),
                    take(1),
                    switchMap(_ =>
                        this.userService
                            .getByEmail(control.value)
                            .pipe(
                                /* map(user =>
                                    user ? { existingEmail: { value: control.value } } : null
                                ) */
                                map(resp => {
                                    console.log('response is ' + JSON.stringify(resp))
                                    if (resp!["success"] == true) {
                                        console.log(`in if and return null`)
                                        return null; // no error 
                                    } else {
                                        console.log(`in else and return remoteError true`)
                                        return { 'existingEmail': true };
                                    }
                                }),
                            )
                    )
                );
            }
        };
    }

    static emailList: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
        const emails = (control.value || '').split(',');
        for (let email of emails) {
            email = email.trim();
            if (email.length > 0) { // && !emailPattern.test(email)) { // "emailPattern" should be a RegExp for email validation
                return { 'emailList': { value: control.value } };
            }
        }
        return null;
    }
}
