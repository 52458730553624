<router-outlet></router-outlet>
<div class="custom-card w-full">
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"
        [style]="{ 'width': '70vw','min-width': '280px','max-width': '600px' }"></p-confirmDialog>
    <div class="pb-3">
        <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
    </div>
    <p-card [header]="formHeader" [subheader]="formSubHeader" class="w-full">
        <div>

            <p-table #dt [lazy]="true" [value]="subscriptions" [rowHover]="true" [showCurrentPageReport]="true"
            styleClass="p-datatable-striped">
            <ng-template pTemplate="caption">
                <div class="flex">
                    <button pButton label="Add New Master Subscription" class="p-button-outlined" icon="pi pi-plus" (click)="addNewSubscription()"></button>
                </div>
            </ng-template>

                <ng-template pTemplate="header" let-columns>
                    <tr>

                        <th p-toggleableColumn style="white-space: normal;">Name
                        </th>
                        <th>Price
                        </th>
                        <th>Offer
                        </th>
                        <th>Duration
                        </th>
                        <th>Features
                        </th>
                        <th>Limit
                        </th>
                        <!-- <th>Deleted?
                        </th> -->

                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-subscription let-columns="columns">
                    <tr [pSelectableRow]="columns" (click)="onRowClick(subscription)" p-selectable-row>

                        <td> {{subscription.type.name}}</td>
                        <td>
                    <tr *ngFor="let pricing of sortPricing(subscription.subscriptionPricings)">
                        <td>
                            {{ pricing.price | currency:(pricing.currency.isoCurrencyName):'symbol':'1.2-2' }}

                        </td>
                        <!-- <td>{{pricing.currency.symbol}} {{pricing.offerPrice}}</td> -->
                        <!-- <td> Duration {{pricing.duration.period}} </td> -->
                    </tr>
                    <td>
                        <tr *ngFor="let pricing of sortPricing(subscription.subscriptionPricings)">
                            <td>{{ pricing.offerPrice | currency:(pricing.currency.isoCurrencyName):'symbol':'1.2-2' }}
                            </td>
                        </tr>
                    </td>
                    <td>
                        <tr *ngFor="let pricing of sortPricing(subscription.subscriptionPricings)">
                            <td>  {{pricing.duration.period}} </td>
                        </tr>
                    </td>

                    <td>
                        <tr *ngFor="let feature of subscription.subscriptionFeatures">
                            <td>{{feature.name}}</td>
                        </tr>
                    </td>
                    <td>
                        <table>
                            <tr>
                                <td>No. of Users</td>
                                <td>{{subscription.subscriptionLimit.numberOfUsers}}</td>
                            </tr>
                            <tr>
                                <td>No. of Packing List</td>
                                <td>{{subscription.subscriptionLimit.numberOfPackingList}}</td>
                            </tr>
                            <tr>
                                <td>No. of Buyers</td>
                                <td>{{subscription.subscriptionLimit.numberOfBuyers}}</td>
                            </tr>
                            <tr>
                                <td>No. of Sellers</td>
                                <td>{{subscription.subscriptionLimit.numberOfSellers}}</td>
                            </tr>
                            <tr>
                                <td>No. of Products</td>
                                <td>{{subscription.subscriptionLimit.numberOfProducts}}</td>
                            </tr>
                            <tr>
                                <td>No. of Sites</td>
                                <td>{{subscription.subscriptionLimit.numberOfSites}}</td>
                            </tr>
                            <tr>
                                <td>Number of BL</td>
                                <td>{{subscription.subscriptionLimit.numberOfBL}}</td>
                            </tr>
                            <tr>
                                <td>No. of Phots</td>
                                <td>{{subscription.subscriptionLimit.numberOfPhotos}}</td>
                            </tr>
                            <tr>
                                <td>Phots Retention</td>
                                <td>{{subscription.subscriptionLimit.retentionOfPhotos}}</td>
                            </tr>
                            <tr>
                                <td>No. of Containers</td>
                                <td>{{subscription.subscriptionLimit.numberOfContainers}}</td>
                            </tr>
                            <!--  <tr>
                                <td>Deleted</td>
                                <td>{{subscription.subscriptionLimit.deleted}}</td>
                            </tr> -->
                            <!-- Add more rows as needed for other properties of subscriptionLimit -->
                        </table>

                    </td>
                    <!-- <td> {{subscription.deleted}}</td> -->
                    
                    </tr>

                </ng-template>

                <!--  <ng-template pTemplate="paginatorleft">
            <p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button>
        </ng-template>
        <ng-template pTemplate="paginatorright">
            <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button>
        </ng-template> -->
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <!-- <td colspan="(_selectedColumns?.length ?? 6) + 2">No records found.</td> -->
                        <td [attr.colspan]="6" class="text-center">No
                            records found.</td>

                    </tr>

                </ng-template>
                <ng-template pTemplate="loadingbody" let-columns="columns">
                    <tr style="height:46px">
                        <td>
                            <p-skeleton></p-skeleton>
                        </td>
                        <td>
                            <p-skeleton></p-skeleton>
                        </td>
                        <td>
                            <p-skeleton></p-skeleton>
                        </td>
                        <td>
                            <p-skeleton></p-skeleton>
                        </td>
                        <td>
                            <p-skeleton></p-skeleton>
                        </td>
                        <td>
                            <p-skeleton></p-skeleton>
                        </td>
                        
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-card>



</div>