import { Component } from '@angular/core';
import { BuyerService } from '../../buyer/services/buyer.service';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.css'],
  standalone:true,
 imports:[SHARED_MODULES]
})
export class ForbiddenComponent {


  constructor(
    private service: BuyerService
  ) { }
}
