<!-- <div style="width: 90vw;">


  <div class="pb-3" *ngIf="showBreadcrumb">
    <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
  </div>
  <p-card [header]="formHeader" [subheader]="formSubHeader">
    <div class="w-2">
      <p-inputNumber [(ngModel)]="decimalPlaces" placeholder="Decimal places"></p-inputNumber>


    </div>
    <div>
      <form [formGroup]="formGroup">
        <p-inputNumber formControlName="from" [mode]="'decimal'" [maxFractionDigits]="decimalPlaces"
          placeholder="From"></p-inputNumber>
        <p-dropdown formControlName="fromUnit" [options]="units" placeholder="Unit"></p-dropdown>
        <p-dropdown formControlName="toUnit" [options]="units" placeholder="Unit"></p-dropdown>
        <p-inputNumber formControlName="to" [mode]="'decimal'" [maxFractionDigits]="decimalPlaces"
          placeholder="To"></p-inputNumber>
      </form>
    </div>
    <div class="mt-3 mb-3">
      <p-button icon="pi pi-replay" styleClass="p-button-rounded p-button-outlined mr-2" (click)="reverse()"></p-button>
      <p-button icon="pi pi-ban" styleClass="p-button-rounded p-button-outlined mr-2 p-button-help"
        (click)="clear()"></p-button>
      <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-outlined mr-2 p-button-danger"
        (click)="add()"></p-button>
    </div>

    <p-table [value]="conversions" [tableStyle]="{ 'min-width': '10rem' }" *ngIf="conversions.length>0"
      styleClass="p-datatable-gridlines p-datatable-striped" selectionMode="single" [(selection)]="selectedConversion"
      dataKey="code">
      <ng-template pTemplate="header">
        <tr>
          <th>From Value</th>
          <th>From Unit</th>
          <th>To Value</th>
          <th>To Unit</th>
          <th>Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-conversion let-rowIndex="rowIndex">
        <tr>
          <td>{{ conversion.from }}</td>
          <td>{{ conversion.fromUnit }}</td>
          <td>{{ conversion.to }}</td>
          <td>{{ conversion.toUnit }}</td>
          <td><button pButton type="button" (click)="remove(rowIndex)" label="Remove"></button></td>

        </tr>
      </ng-template>
    </p-table>
  </p-card>

</div>

<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog> -->

<div style="width: 90vw;">


  <div class="pb-3" *ngIf="showBreadcrumb">
    <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
  </div>
    <p-card [header]="formHeader" [subheader]="formSubHeader">
      <div class="formgrid grid">
        <div class="field col-12 md:col-2">
          <label for="firstname6">Decimal Place</label>
          <input [(ngModel)]="decimalPlaces" placeholder="Decimal places" type="text"
            class="w-6 text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
        </div>
         
      </div>

      <div>
        <form [formGroup]="formGroup">
          <p-inputNumber formControlName="from" [mode]="'decimal'" [maxFractionDigits]="decimalPlaces"
            placeholder="From"></p-inputNumber>
          <p-dropdown formControlName="fromUnit" [options]="units" placeholder="Unit"></p-dropdown>
          <p-dropdown formControlName="toUnit" [options]="units" placeholder="Unit"></p-dropdown>
          <p-inputNumber formControlName="to" [mode]="'decimal'" [maxFractionDigits]="decimalPlaces"
            placeholder="To"></p-inputNumber>
        </form>
      </div>
      <div class="mt-3 mb-3">
        <p-button icon="pi pi-replay" styleClass="p-button-rounded p-button-outlined mr-2"
          (click)="reverse()" pTooltip="Reverse" tooltipPosition="bottom" ></p-button>
        <p-button icon="pi pi-ban" styleClass="p-button-rounded p-button-outlined mr-2 p-button-help"
          (click)="clear()" pTooltip="Clear"></p-button>
        <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-outlined mr-2 p-button-danger"
          (click)="add()" pTooltip="Add"></p-button>
      </div>

      <p-table [value]="conversions" [tableStyle]="{ 'min-width': '10rem' }" *ngIf="conversions.length>0"
        styleClass="p-datatable-gridlines p-datatable-striped" selectionMode="single" [(selection)]="selectedConversion"
        dataKey="code">
        <ng-template pTemplate="header">
          <tr>
            <th>From Value</th>
            <th>From Unit</th>
            <th>To Value</th>
            <th>To Unit</th>
            <th>Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-conversion let-rowIndex="rowIndex">
          <tr>
            <td>{{ conversion.from }}</td>
            <td>{{ conversion.fromUnit }}</td>
            <td>{{ conversion.to }}</td>
            <td>{{ conversion.toUnit }}</td>
            <td><button pButton type="button" (click)="remove(rowIndex)" label="Remove"></button></td>

          </tr>
        </ng-template>
      </p-table>
    </p-card>
</div>


<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>