<p-blockUI [blocked]="blockedDocument">
    <i class="pi pi-spin pi-spinner" style="font-size: 4rem !important"></i>
</p-blockUI>

<div class="flex justify-content-center flex-wrap">
    <div class="flex align-items-center justify-content-center m-2">
        <div class="field col-12 md:col-12 mt-3">
            <label class="text-lg pb-2" for="containerNumbers">Container Number <i
                    class="text-red-400 mr-2">*</i></label>
            <div class="p-inputgroup" class="w-full" *ngIf="!editModeContainerNumber; else editMode">
                <!--  <p-autoComplete [(ngModel)]="selectedContainer" [suggestions]="containerNumbers"
                    [minLength]="2" field="containerNumber" dataKey="containerId"
                    (completeMethod)="searchContainerNumber($event)"
                    (onSelect)="onContainerAutoCompleteSelect($event); disableTableDiv=false"
                    placeholder="Select a container" [forceSelection]="true" [required]="true"
                    [showEmptyMessage]="true" emptyMessage="No matching results found"></p-autoComplete> -->
                <p-autoComplete [minLength]="3" [(ngModel)]="selectedContainer" field="containerNumber"
                    [suggestions]="containerNumbers" (completeMethod)="searchContainer($event)"
                    placeholder="Start typing to search..." [forceSelection]="true" [required]="true"
                    [showEmptyMessage]="true" emptyMessage="No matching results found"
                    (onKeyUp)="onKeydown($event)"></p-autoComplete>

                <!-- <p-button icon="pi pi-check" styleClass="p-button-rounded p-button-text"></p-button> -->

                <!--   <p-button icon="pi pi-plus" styleClass="p-button-sm p-button-default ml-1"
                    *ngIf="!containerSidebarVisible" (click)="containerSidebarVisible = true"
                    pTooltip="Add new supplier" tooltipPosition="top" [showDelay]="300" #nextButton>
                </p-button> -->
                <!-- <p-button icon="pi pi-plus-circle" (click)="containerSidebarVisible = true" pTooltip="Add new supplier"
                    tooltipPosition="left" styleClass="p-button-rounded p-button-secondary p-button-text"
                    [showDelay]="200" *ngIf="!containerSidebarVisible"></p-button> -->
            </div>
            <button pButton pRipple type="button" icon="pi pi-arrow-right" label="Next" iconPos="right"
                class="p-button-default p-button-lg mt-3" (click)=" isContainerNumberExist()"
                [disabled]="!this.selectedContainer?.containerId?? null"></button>

            <ng-template #editMode>
                <div style="display: flex; justify-content: center; align-items: center;"
                    *ngIf="!this.selectedContainer.containerNumber">
                    <p style="margin-right: 10px;"> <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></p>
                </div>
                <div style="display: flex; justify-content: center; align-items: center;"
                    *ngIf="this.selectedContainer.containerNumber">
                    <p style="margin-right: 10px;">{{this.selectedContainer.containerNumber}}</p>
                    <p-button icon="pi pi-pencil"
                        styleClass="p-button-rounded p-button-danger p-button-text p-button-sm"
                        (click)="onEditContainerNumber()"></p-button>
                </div>



            </ng-template>
            <div class="field col-12 md:col-6"></div>
            <div class="field col-12 md:col-12">

            </div>
        </div>
    </div>
</div>