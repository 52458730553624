import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Observable } from 'rxjs';
import { LoadingSiteComponent } from '../loading-site/loading-site.component';

@Injectable({
  providedIn: 'root'
})
export class DeactivatesLoadingSiteGuard implements CanDeactivate<LoadingSiteComponent> {
  constructor(private confirmationService: ConfirmationService) { }

  canDeactivate(component: LoadingSiteComponent): Observable<boolean> | Promise<boolean> | boolean {
    if (component.isSubmitting && component.loadingSiteForm.dirty) { // check if component has unsaved changes
      return true;
    }
    if (component.loadingSiteForm.dirty) { // check if component has unsaved changes
      return new Promise((resolve) => {
        this.confirmationService.confirm({
          header: 'Warning',
          message: "Changes to the form have not been saved. Do you want to continue without saving?",
          accept: () => {
            resolve(true);
          },
          reject: () => {
            resolve(false);
          }
        });
      });
    } else {
      return true; // allow deactivation if no unsaved changes
    }
  }
}
