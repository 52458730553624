<div style="width: 90vw">
    <ng-container *ngIf="!isMobile; else elseBlock">
        <p-card>
            <div #scrollable style="height: 50vh; overflow-y: auto;">
                <p-table #myTable [value]="measurements" [styleClass]="tableStyle" [tableStyle]="tableStyleOptions"
                    [loading]="(isLoading1 | async) ?? false">

                    <ng-template pTemplate="header">
                        <tr>
                            <th rowspan="2" class="text-center">SR</th>
                            <th *ngFor="let col of cols" [attr.rowspan]="col.unit ? null : 2" class="text-center">{{col.header}}</th>
                        </tr>
                        <tr>
                            <ng-container *ngFor="let col of cols">
                                <th *ngIf="col.unit" class="text-center">{{col.unit}}</th>
                            </ng-container>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-measurement let-index="rowIndex">
                        <tr>
                            <td class="text-center">{{ index + 1}}</td>
                            <td *ngFor="let col of cols" class="text-center">{{measurement[col.field]}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="loadingbody">
                        <tr *ngFor="let row of dummyRows">
                            <td>
                                <p-skeleton></p-skeleton>
                            </td>
                            <td *ngFor="let col of cols">
                                <p-skeleton></p-skeleton>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <p-button *ngIf="showBottomButton && (measurements?.length ?? 0) > 20" (click)="scrollToBottom()" id="scroll-to-bottom" class="scroll-btn"
                    styleClass="p-button-rounded p-button-help p-button-text p-button-raised"
                    icon="pi pi-arrow-circle-down"></p-button>
                <p-button *ngIf="showTopButton && (measurements?.length ?? 0) > 20" (click)="scrollToTop()" id="scroll-to-top" class="scroll-btn"
                    styleClass="p-button-rounded p-button-help p-button-text p-button-raised"
                    icon="pi pi-arrow-circle-up"></p-button>
            </div>
        </p-card>
    </ng-container>
    <ng-template #elseBlock>

        <p-table [value]="measurements" [styleClass]="tableStyle" [tableStyle]="tableStyleOptions"
            [loading]="(isLoading1 | async) ?? false">

            <ng-template pTemplate="header">
                <tr>
                    <th>SR </th>
                    <th *ngFor="let col of cols" pSortableColumn="{{col.field}}">{{col.header}} <p-sortIcon
                            field="{{col.field}}"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-measurement let-index="rowIndex">
                <tr>
                    <td>{{ index + 1}}</td>
                    <td *ngFor="let col of cols">{{measurement[col.field]}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="loadingbody">
                <tr *ngFor="let row of dummyRows">
                    <td>
                        <p-skeleton></p-skeleton>
                    </td>
                    <td *ngFor="let col of cols">
                        <p-skeleton></p-skeleton>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-template>
</div>