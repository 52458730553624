import { Component, OnInit } from '@angular/core';
import { SubscriptionViewService } from '../services/subscription-view.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MenuItem } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-subscription-opted',
  templateUrl: './subscription-opted.component.html',
  styleUrls: ['./subscription-opted.component.css'],
  standalone: true,
  imports: [SHARED_MODULES]
})
export class SubscriptionOptedComponent implements OnInit {

  subscriptionOpted!: any;
  protected breadCumbItems!: MenuItem[];
  home!: MenuItem;

  constructor(private subscriptionViewService: SubscriptionViewService) {

  }

  ngOnInit(): void {
    console.log(`SubscriptionOptedComponent Loading now... `);
    this.subscriptionViewService.getOpted().subscribe(
      (response: any) => {
        console.log(`Response for SubscriptionOptedComponent is ${JSON.stringify(response)}`);
        this.subscriptionOpted = response;
      },
      (error: HttpErrorResponse) => {
        console.error(`Error for SubscriptionOptedComponent is ${JSON.stringify(error)}`);
      },
      () => { }
    )
   
    this.populateBreadCrumb();
  }

  populateBreadCrumb() {
    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.breadCumbItems = [ 

      { label: 'Subscription' },
      { label: 'Subcription Package' },
    ];
  }


}
