 


<p-tabView class="full-width" #tabview >
    <div class="mb-3"> 
        <div class="pt-5">
            <span class="font-bold"> Container Number:</span>
            <span *ngIf="!(isLoading$ | async); else loadingBlock" class="font-bold"> {{ containerNumber }} </span>
            <ng-template #loadingBlock>
                <i class="pi pi-spin pi-spinner ml-2" style="font-size: 2rem"></i>
            </ng-template>
        </div>
    </div>
    <p-tabPanel header="Photos" [disabled]="(isLoading$ | async)?? false">
        <!-- <app-bl-photos [containerId]="INPUT_CONTAINER_ID"></app-bl-photos> -->
        <app-container-photos></app-container-photos>
    </p-tabPanel>


    <p-tabPanel header="Summary" [disabled]="(isLoading$ | async)?? false">
        <ng-template pTemplate="content">
            <!-- <app-bl-summary [containerId]="INPUT_CONTAINER_ID"></app-bl-summary> -->
            <!-- <app-container-summary></app-container-summary> -->
            <app-view-hoppus-summary [containerUUID]="containerUUID" ></app-view-hoppus-summary>
        </ng-template>
    </p-tabPanel>


    <p-tabPanel header="Packing List" [disabled]="(isLoading$ | async)?? false">
        <ng-template pTemplate="content">
            <!-- <app-container-packing-list></app-container-packing-list> -->
            <app-view-hoppus-measurement [containerUUID]="containerUUID"></app-view-hoppus-measurement>
            <!-- <app-bl-packing-list [containerId]="INPUT_CONTAINER_ID"></app-bl-packing-list> -->
        </ng-template>
    </p-tabPanel>


</p-tabView>

<p-dialog [header]="errorDialogHeader" [(visible)]="errorDialoagVisible"   [closable]="false" [closeOnEscape]="false"
[draggable]="false" [modal]="true">
    <p>
        {{errorDialogContent}}
    </p>
</p-dialog>