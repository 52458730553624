import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UnitConversionService } from '../../services/unit-conversion.service';
import { ConfirmationService, ConfirmEventType, MenuItem } from 'primeng/api';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';

@Component({
  selector: 'app-unit-conversion',
  templateUrl: './unit-conversion.component.html',
  styleUrls: ['./unit-conversion.component.css'],
  providers: [ConfirmationService,ToastMessagesService],
  standalone:true,
  imports:[SHARED_MODULES]
}) 
export class UnitConversionComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  protected items!: MenuItem[];
  home!: MenuItem;
  @Input() showBreadcrumb: boolean = true;
  formHeader!: string;
  formSubHeader!: string;

  units = [
    { label: 'Select Unit', value: null },
    { label: 'mm', value: 'mm' },
    { label: 'cm', value: 'cm' },
    { label: 'in', value: 'in' },
    { label: 'ft', value: 'ft' },
    { label: 'mtr', value: 'mtr' },
  ];
  decimalPlaces: number;

  private conversionRates: { [unit: string]: number } = {
    mm: 1,
    cm: 10,
    in: 25.4,
    ft: 304.8,
    mtr: 1000,
  };

  conversions: any[] = [];
  selectedConversion!: any;


  constructor(
    private fb: FormBuilder,
    private unitConversionService: UnitConversionService,
    private confirmationService: ConfirmationService,
    private toastMessageService: ToastMessagesService,
    private route: ActivatedRoute,
    private titleService: Title,
  ) {
    this.formGroup = this.fb.group({
      from: [],
      fromUnit: [],
      to: [],
      toUnit: [],
    });
    this.decimalPlaces = 3;
  }

  ngOnInit(): void {
    this.populateBreadCrumb();
    this.setPageTitle();

    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.formGroup.get('from')?.valueChanges.subscribe(() => {
      this.convert('from');
    });

    this.formGroup.get('fromUnit')?.valueChanges.subscribe(() => {
      this.convert('from');
    });

    this.formGroup.get('to')?.valueChanges.subscribe(() => {
      this.convert('to');
    });

    this.formGroup.get('toUnit')?.valueChanges.subscribe(() => {
      this.convert('from');
    });

    this.loadFormFromLocalStorage();
    this.loadConversionsFromLocalStorage();

    this.formGroup.valueChanges.subscribe((val) => {
      this.saveFormToLocalStorage(val);
    });
  }

  ngOnDestroy(): void {
   // throw new Error('Method not implemented.');
  }

  reverse() {
    const fromValue = this.formGroup.get('from')?.value;
    const fromUnit = this.formGroup.get('fromUnit')?.value;
    const toValue = this.formGroup.get('to')?.value;
    const toUnit = this.formGroup.get('toUnit')?.value;

    this.formGroup.get('from')?.setValue(toValue, { emitEvent: false });
    this.formGroup.get('fromUnit')?.setValue(toUnit, { emitEvent: false });
    this.formGroup.get('to')?.setValue(fromValue, { emitEvent: false });
    this.formGroup.get('toUnit')?.setValue(fromUnit, { emitEvent: false });
  }

  convert(direction: string) {
    if (direction === 'from') {
      const fromValue = this.formGroup.get('from')?.value;
      const fromUnit = this.formGroup.get('fromUnit')?.value;
      const toUnit = this.formGroup.get('toUnit')?.value;

      if (fromValue && fromUnit && toUnit) {
        const toValue = this.unitConversionService.convert(
          fromValue,
          fromUnit,
          toUnit,
          this.decimalPlaces
        );
        this.formGroup.get('to')?.setValue(toValue);
      }
    } else {
      const toValue = this.formGroup.get('to')?.value;
      const toUnit = this.formGroup.get('toUnit')?.value;
      const fromUnit = this.formGroup.get('fromUnit')?.value;

      if (toValue && fromUnit && toUnit) {
        const fromValue = this.unitConversionService.convert(
          toValue,
          toUnit,
          fromUnit,
          this.decimalPlaces
        );
        this.formGroup.get('from')?.setValue(fromValue, { emitEvent: false });
      }
    }
  }

  clear() {
    this.formGroup.reset();
    this.conversions = [];
    localStorage.removeItem('conversions');
  }

  private loadFormFromLocalStorage(): void {
    const storedFormValue = localStorage.getItem('formValue');
    if (storedFormValue) {
      this.formGroup.setValue(JSON.parse(storedFormValue));
    }
  }

  private loadConversionsFromLocalStorage(): void {
    const storedConversions = localStorage.getItem('conversions');
    if (storedConversions) {
      this.conversions = JSON.parse(storedConversions);
    }
  }

  private saveFormToLocalStorage(formValue: any): void {
    localStorage.setItem('formValue', JSON.stringify(formValue));
  }

  private saveConversionsToLocalStorage(): void {
    localStorage.setItem('conversions', JSON.stringify(this.conversions));
  }

  add() {
    const formValue = this.formGroup.value;

    if (
      !formValue.from ||
      !formValue.fromUnit ||
      !formValue.to ||
      !formValue.toUnit ||
      !this.decimalPlaces
    ) {
      this.toastMessageService.showInfoMessage('Incomplete Data', 'Please ensure all fields are filled and decimal places are set before adding.');

      return;
    }
    const epsilon = 0.0001;

    let doesExist = false;

    for (let i = 0; i < this.conversions.length; i++) {
      const conversion = this.conversions[i];
      console.log(`Conversions ${i} ==> ${JSON.stringify(conversion)}`);

      const fromEqual =
        Math.abs(Number(conversion.from) - Number(formValue.from)) < epsilon;
      const fromUnitEqual = conversion.fromUnit === formValue.fromUnit;
      const toEqual =
        Math.abs(Number(conversion.to) - Number(formValue.to)) < epsilon;
      const toUnitEqual = conversion.toUnit === formValue.toUnit;
      const decimalPlacesEqual =
        conversion.decimalPlaces === this.decimalPlaces;
      console.log(
        `conversion.decimalPlaces: ${conversion.decimalPlaces} === this.decimalPlaces ${this.decimalPlaces}`
      );

      console.log(`fromEqual: ${fromEqual}`);
      console.log(`fromUnitEqual: ${fromUnitEqual}`);
      console.log(`toEqual: ${toEqual}`);
      console.log(`toUnitEqual: ${toUnitEqual}`);
      console.log(`decimalPlacesEqual: ${decimalPlacesEqual}`);

      if (fromEqual && fromUnitEqual && toEqual && toUnitEqual) {
        doesExist = true;
        break;
      }
    }

    if (doesExist) {
      this.confirmSimilarValueAdd(this.formGroup.value);
      return;
    }

    this.conversions.push(this.formGroup.value);
    this.saveConversionsToLocalStorage();
  }

  remove(index: number) {
    this.conversions.splice(index, 1);
    this.saveConversionsToLocalStorage();
  }

  confirmSimilarValueAdd(value: any) {
    this.confirmationService.confirm({
      message:
        'The same conversion data already exists. Are you sure you still want to add it?',
      header: 'Confirm Addition',
      icon: 'pi pi-exclamation-circle', //
      accept: () => {
        this.conversions.push(value);
        this.saveConversionsToLocalStorage();
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'The conversion data has been added.' });
      },
      reject: (type: any) => {
        // this.messageService.add({ severity: 'info', summary: 'Cancelled', detail: 'You have cancelled the addition.' });
      },
    });
  }


  populateBreadCrumb() {
    this.items = [
      { label: 'Unit Conversion' },
    ];
  }

  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }

  

}
