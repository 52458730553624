<!-- <form> -->
<div class="pb-3" *ngIf="showBreadcrumb">
    <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
</div>
<p-card [header]="formHeader" [subheader]="formSubHeader">


    <div class="formgrid grid text-sm" style="width: 90vw;">
        <div class="field col-12 md:col-6">
            <p-checkbox [(ngModel)]="useCommonUnits" [binary]="true" inputId="binary"
                label="Use common units for all rows" (onChange)="saveCommonUnitsFlag()"></p-checkbox>
        </div>

        <div class="card flex justify-content-start">
            <div class="flex flex-row gap-3 justify-content-space-between">
                <div *ngFor="let estimate of estimatePurpose" class="field-checkbox">
                    <p-radioButton [inputId]="estimate.key" name="category" [value]="estimate"
                        [(ngModel)]="selectedPurpose" (onClick)="saveEstimatePurpose($event)"></p-radioButton>
                    <label [for]="estimate.key" class="ml-2">{{ estimate.name }}</label>
                </div>
            </div>
        </div>

    </div>
    <div style="width: 90vw;font-size: .75rem !important;">
        <p-table [value]="timbers" class="my-table"
            styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm  text-sm" [scrollable]="true"
            [tableStyle]="{ 'width': '10rem' }" *ngIf="timbers.length>0" [resizableColumns]="true" editMode="row">

            <ng-template pTemplate="header">
                <ng-container *ngIf="useCommonUnits; else specificUnits">
                    <tr>
                        <th class="text-center">Width</th>
                        <th class="text-center">Thickness</th>
                        <th class="text-center">Length</th>
                        <th rowspan="2" class="text-center" *ngIf="selectedPurpose.key==1">Price</th>
                        <th rowspan="2" class="text-center" *ngIf="selectedPurpose.key==1">Price Unit</th>
                        <th rowspan="2" class="text-center">Quantity</th>
                        <th rowspan="2" class="text-center">CBM</th>
                        <th rowspan="2" class="text-center">CFT</th>

                        <th rowspan="2" class="text-center" *ngIf="selectedPurpose.key==1">Total</th>
                        <th rowspan="2" class="text-center">Actions</th>
                    </tr>
                    <tr>
                        <th><p-dropdown [options]="units" class="numbers" [(ngModel)]="commonWidthUnit"
                                (onChange)="calculateAllRows();" [appendTo]="'body'"></p-dropdown></th>
                        <th><p-dropdown [options]="units" class="numbers" [(ngModel)]="commonThicknesUnit"
                                (onChange)="calculateAllRows();" [appendTo]="'body'"></p-dropdown>
                        </th>
                        <th><p-dropdown [options]="units" class="numbers" [(ngModel)]="commonLengthUnit"
                                (onChange)="calculateAllRows();" [appendTo]="'body'"></p-dropdown></th>
                    </tr>
                </ng-container>
                <ng-template #specificUnits>
                    <tr>
                        <th>Width</th>
                        <th>Width Unit</th>
                        <th>Thickness</th>
                        <th>Thickness Unit</th>
                        <th>Length</th>
                        <th>Length Unit</th>
                        <th *ngIf="selectedPurpose.key==1">Price</th>
                        <th *ngIf="selectedPurpose.key==1">Price Unit</th>
                        <th>Quantity</th>
                        <th>CBM</th>
                        <th>CFT</th>

                        <th *ngIf="selectedPurpose.key==1">Total</th>
                        <th>Actions</th>
                    </tr>
                </ng-template>
            </ng-template>

            <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
                <tr>
                    <td><p-inputNumber type="number" [(ngModel)]="row.width" (onInput)="calculate(rowIndex)"
                            mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" [min]="0" [step]="0.50"
                            [showButtons]="showButtons"></p-inputNumber></td>
                    <td *ngIf="!useCommonUnits"><p-dropdown [(ngModel)]="row.widthUnit" [options]="units"
                            class="numbers" (onChange)="calculate(rowIndex)" [appendTo]="'body'"></p-dropdown></td>
                    <td><p-inputNumber type="number" [(ngModel)]="row.thickness" (onInput)="calculate(rowIndex)"
                            mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" [min]="0" [step]="0.50"
                            [showButtons]="showButtons"></p-inputNumber></td>
                    <td *ngIf="!useCommonUnits"><p-dropdown [(ngModel)]="row.thicknessUnit" [options]="units"
                            (onChange)="calculate(rowIndex)" [appendTo]="'body'"></p-dropdown></td>
                    <td><p-inputNumber type="number" [(ngModel)]="row.length" (onInput)="calculate(rowIndex)"
                            mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" [min]="0" [step]="0.50"
                            [showButtons]="showButtons"></p-inputNumber></td>
                    <td *ngIf="!useCommonUnits"><p-dropdown [(ngModel)]="row.lengthUnit" [options]="units"
                            (onChange)="calculate(rowIndex)" [appendTo]="'body'"></p-dropdown></td>
                    <td *ngIf="selectedPurpose.key==1"><p-inputNumber type="number" [(ngModel)]="row.price"
                            (onInput)="calculate(rowIndex)" mode="decimal" [minFractionDigits]="0"
                            [maxFractionDigits]="0" [min]="0" [step]="10" [showButtons]="showButtons"></p-inputNumber>
                    </td>
                    <td *ngIf="selectedPurpose.key==1"><p-dropdown [(ngModel)]="row.priceUnit"
                            (onChange)="calculate(rowIndex)" [options]="priceUnits" [appendTo]="'body'"></p-dropdown>
                    </td>
                    <td><p-inputNumber type="number" [(ngModel)]="row.quantity" (onInput)="calculate(rowIndex)"
                            mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0" [min]="0" [step]="5"
                            [showButtons]="showButtons"></p-inputNumber></td>
                    <td class="text-right">{{ row.cbm | number:'1.3-3' }}</td>
                    <td class="text-right">{{ row.cft | number:'1.3-3'}}</td>


                    <td class="text-right" *ngIf="selectedPurpose.key==1">{{ row.total |
                        currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                    <td>
                        <button pButton pRipple type="button" icon="pi pi-plus" (click)="addRowAtIndex(rowIndex)"
                            class="p-button-rounded p-button-text p-button-success mr-2"></button>
                        <button pButton pRipple type="button" icon="pi pi-times" (click)="removeRow(rowIndex)"
                            class="p-button-rounded p-button-text p-button-danger" *ngIf="timbers.length>1"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <ng-container *ngIf="useCommonUnits; else specificUnits">
                    <tr>
                        <td *ngIf="selectedPurpose.key==1; else elseBlock" colspan="5" class="text-right">Total</td>

                        <ng-template #elseBlock>
                            <td colspan="3" class="text-right">Total</td>
                        </ng-template>
                        <td class="text-right"> {{ grandTotals?.totalQuantity?? 0 }}</td>
                        <td class="text-right">{{grandTotals?.totalCbm?? 0 | number:'1.3-3' }}</td>
                        <td class="text-right">{{grandTotals?.totalCft?? 0 | number:'1.3-3' }}</td>
                        <td class="text-right" *ngIf="selectedPurpose.key==1">{{grandTotals?.grandTotal?? 0 |
                            currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                        <td></td>
                    </tr>
                </ng-container>
                <ng-template #specificUnits>
                    <tr>

                        <td *ngIf="selectedPurpose.key==1; else elseBlock" colspan="8" class="text-right">Total</td>
                        <ng-template #elseBlock>
                            <td colspan="6" class="text-right">Total</td>
                        </ng-template>
                        <td class="text-right"> {{ grandTotals?.totalQuantity?? 0 }}</td>
                        <td class="text-right">{{grandTotals?.totalCbm?? 0 | number:'1.3-3'}}</td>
                        <td class="text-right">{{grandTotals?.totalCft?? 0 | number:'1.3-3'}}</td>
                        <td class="text-right" *ngIf="selectedPurpose.key==1">
                            <p>{{grandTotals?.grandTotal?? 0 | currency:'INR':'symbol':'1.2-2':'en-IN' }} </p>
                        </td>
                        <td></td>
                    </tr>
                </ng-template>
            </ng-template>

        </p-table>
    </div>
</p-card>
<!-- </form> -->