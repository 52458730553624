import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';
import { ContainerService } from '../services/container.service';
import { AttachmentService } from '../services/attachment.service';
import { AppConstants } from 'src/app/commons/app-constants';
import { PrivacyType } from 'src/app/commons/enums/privacy-type';
import { ConfirmationService, MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { ConfirmPopup } from 'primeng/confirmpopup';
import { FileUpload } from 'primeng/fileupload';
import { ConfirmDialog } from 'primeng/confirmdialog';

@Component({
  selector: 'app-attachment',
  standalone: true,
  templateUrl: './attachment.component.html',
  styleUrl: './attachment.component.css',
  imports: [SHARED_MODULES],
  providers: [ConfirmationService, MessageService]

})
export class AttachmentComponent implements OnInit, AfterViewInit {
  @Input() attachmentData!: any;
  @Input() containerId!: number;
  @Input() containerEditData!: any;
  @Input() containerForm!: FormGroup;
  @ViewChild('attachmentMenu') attachmentMenu!: any;
  @ViewChild('imageSelectionMenu') imageSelectionMenu!: any;
  @ViewChild(ConfirmPopup) confirmPopupDeleteImage!: ConfirmPopup;
  @ViewChild('fileUpload') fileUpload: FileUpload;

  uploadStatus: 'Pending' | 'Uploaded' | 'Uploading' = 'Pending';
  uploadStatusSeverity: 'warning' | 'success' | 'danger' = 'warning';
  showPrivacySelection = false;
  displayBasic: boolean | undefined;

  privacyType: PrivacyType | PrivacyType.PRIVATE;
  selectedImages: any[] = []; // Array to store selected image indices
  images: any[];// = ['https://rikexim.com/assets/images/resources/rikexim_200x50_dark.webp', 'https://rikexim.com/assets/images/resources/rikexim_200x50_dark.webp']; // Add this property

  attachmentMenuItems!: MenuItem[] | undefined;
  imageSelectionMenuItems!: MenuItem[] | undefined;
  blockedFileUploader = false;
  blockedDocument = false;
  displayCustom: boolean | undefined;
  activeIndex: number = 0;
  loadingDeleteImage: boolean[] = [];
  public privacyTypeOption = PrivacyType; // Add this line to make PrivacyType accessible in the template
  tempDocumentsSize = 0;

  @ViewChild('cdSingleImagesDelete') cdSingleImagesDelete!: ConfirmDialog;
  @ViewChild('cdMultipleImagesDelete') cdMultipleImagesDelete!: ConfirmDialog;



  responsiveOptions: any[] = [
    {
      breakpoint: '1500px',
      numVisible: 5
    },
    {
      breakpoint: '1024px',
      numVisible: 3
    },
    {
      breakpoint: '768px',
      numVisible: 2
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
  allowSelectionImage = false;

  uploadUrl = ''; 


  constructor(private httpClient: HttpClient,
    private containerService: ContainerService,
    private attachmentService: AttachmentService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private fb: FormBuilder,
    private router: Router,
    private titleService: Title,
    private route: ActivatedRoute,
    private toastService: ToastMessagesService,
    private config: PrimeNGConfig,


  ) { }

  ngOnInit(): void {
    this.initAttachmentMenu();
    this.initMultipleAttachmentMenu();
    this.loadStoredFiles();
    this.loadingDeleteImage = new Array(this.images.length).fill(false);
    const url = this.router.url;
    if (url.includes('edit/container')) {
      this.tempDocumentsSize = this.containerEditData.documents.length;
      console.log(`Documents Size is ${this.tempDocumentsSize}`);
    }

  }

  ngAfterViewInit(): void {
    console.log(`after init`);
    if (this.containerEditData) {
      this.uploadUrl = AppConstants.API_URL + `api/v1/containers/${this.containerEditData?.containerId}/uploads`;
    }
  }

  files = [];

  totalSize: number = 0;

  totalSizePercent: number = 0;

  choose(event, callback) {
    this.files = null;
    this.uploadStatus = 'Pending';
    this.uploadStatusSeverity = 'warning';
    callback();
  }

  onRemoveTemplatingFile(event, file, removeFileCallback, index) {
    removeFileCallback(event, index);
    this.totalSize -= parseInt(this.formatSize(file.size));
    this.totalSizePercent = this.totalSize / 10;
  }

  onClearTemplatingUpload(clear) {
    clear();
    this.totalSize = 0;
    this.totalSizePercent = 0;
  }



  onTemplatedUpload(event: any) {
    console.log(`Upload Url is ${this.uploadUrl}`);
    this.showPrivacySelection = true;

    // this.messageService.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded 2', life: 3000 });
  }

  privacyTypeSelected(privacyType: PrivacyType) {
    this.privacyType = privacyType;
    this.showPrivacySelection = false;
    this.fileUploadWithPrivacyType(privacyType);
  }

  private fileUploadWithPrivacyType(privacyType: PrivacyType) {
    console.log(`Files are ${JSON.stringify(this.files)}`);
    this.containerService.uploadFilesToContainer(this.files, this.containerEditData.containerId, privacyType).subscribe(
      response => {
        console.log(`File(s) uploaded are ${JSON.stringify(response)}`);
        response.forEach((file: any) => {
          console.log(`File of Each Response is ${JSON.stringify(file)}`);
          this.containerForm.get('documents').setValue(response); // Store
        });
        /*  this.files.forEach((file: any) => {
            this.documents.push(this.fb.control(file));
         }); */
        this.messageService.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded 1', life: 3000 });
        this.uploadStatus = 'Uploaded';
        this.uploadStatusSeverity = 'success';
        this.containerService.getDocumentsByContainerId(this.containerEditData.containerId).subscribe(
          (response: any) => {
            console.log(`Response for getDocumentsSecuredUrlchr is ${JSON.stringify(response)}`);
            // this.images = response;
            this.files = null;

            this.fileUpload.clear(); // This clears the file input and resets the component
            this.blockedFileUploader = false;
            this.loadStoredFilesFromServer();
          },
          (error: any) => {
            this.blockedFileUploader = false;

          },
          () => { }
        );

      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File Upload Failed', life: 3000 });
      }
    );
  }

  toggleSelection(): void {
    this.allowSelectionImage = !this.allowSelectionImage;
    this.selectedImages = [];
  }


  onSelectedFiles(event) {
    console.log(`Upload Url is ${this.uploadUrl}`);

    console.log(`Selected Files are ${JSON.stringify(event)}   ${this.containerEditData.containerId} `);
    this.files = event.currentFiles;
    console.log(`Files are ${JSON.stringify(this.files)}`);
    this.files.forEach((file) => {
      console.log(`File is ${JSON.stringify(file)}`);
      this.totalSize += parseInt(this.formatSize(file.size));
      console.log(`Total Size is ${this.totalSize}`);
    });
    this.totalSizePercent = this.totalSize / 10;
  }

  uploadEvent(callback) {
    callback();
    this.uploadStatus = 'Uploading';
    this.uploadStatusSeverity = 'danger';
    this.blockedFileUploader = true;
  }

  formatSize(bytes) {
    const k = 1024;
    const dm = 3;
    const sizes = this.config.translation.fileSizeTypes;
    if (bytes === 0) {
      return `0 ${sizes[0]}`;
    }

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

    return `${formattedSize} ${sizes[i]}`;
  }


  get documents(): FormArray {
    return this.containerForm.get('documents') as FormArray;
  }



  private loadStoredFiles() {
    // this.containerService.getDocumentsSecuredUrl(this.containerEditData.containerId).subscribe(
    //    (response: any) => {
    //       console.log(`Response for getDocumentsSecuredUrlchr is ${JSON.stringify(response)}`);
    //       this.images = response;
    //    },
    //    (error: any) => { },
    //    () => { }
    // );
    if (this.containerEditData && this.containerEditData.documents && this.containerEditData.documents.length > 0) {
      console.warn(`Documents are ${JSON.stringify(this.containerEditData.documents)}`);
      this.images = this.containerEditData.documents;
    }

  }
  galleriaVisibility = true;
  private loadStoredFilesFromServer() {
    this.containerService.getDocumentsSecuredUrl(this.containerEditData.containerId).subscribe(
      (response: any) => {
        console.log(`Response for getDocumentsSecuredUrlchr is ${JSON.stringify(response)}`);
        this.images = response;
        //this.galleriaVisibility = false;

      },
      (error: any) => { },
      () => { }
    );
  }

  private loadStoredFilesFromServerOLD() {
    this.containerService.getDocumentsSecuredUrl(this.containerEditData.containerId).subscribe(
      (response: any) => {
        console.log(`Response for getDocumentsSecuredUrlchr is ${JSON.stringify(response)}`);

        // Filter out only new images that are not already in `this.images`
        const uniqueNewImages = response.filter((img: string) => !this.images.includes(img));

        // Add the new images to the existing `this.images` array
        this.images = [...this.images, ...uniqueNewImages];

        this.galleriaVisibility = false;
      },
      (error: any) => { },
      () => { }
    );
  }




  changedGalleryIndex(index: number) {
    this.showSkeletonFullImage = true;
  }

  getIconClass(fileType: string, index: number): { [key: string]: boolean } {
    const baseClass = {};

    // Determine the icon based on fileType
    if (fileType.includes('sheet')) {
      baseClass['pi pi-file-excel'] = true;
    } else if (fileType.includes('document') || fileType.includes('word')) {
      baseClass['pi pi-file-word'] = true;
    } else if (fileType.includes('pdf')) {
      baseClass['pi pi-file-pdf'] = true;
    } else if (fileType.includes('csv')) {
      baseClass['pi pi-file-csv'] = true;
    } else if (fileType.includes('text')) {
      baseClass['pi pi-file'] = true;
    } else {
      baseClass['pi pi-check'] = true; // Default icon
    }

    // Add conditional classes for selection
    baseClass['opacity-50'] = this.isImageSelected(index);
    baseClass['opacity-200'] = !this.isImageSelected(index);
    baseClass['border-4'] = !this.isImageSelected(index);

    return baseClass;
  }


  /* changedGalleryIndex(newIndex: number) {
     this.showSkeletonFullImage = true;
   
     const imagesLength = this.images.length;
     if (imagesLength === 0) {
       // If the images array is empty, no valid navigation is possible
       this.showFullImage = false;
       this.activeIndex = -1; // Indicate no valid index
       return;
     }
   
     // Determine direction: true if moving forward, false if backward
     const isMovingForward = newIndex > this.activeIndex;
     let index = newIndex;
     const startIndex = this.activeIndex; // Remember the starting index
   
     while (true) {
       // Check if the current file is an image
       if (this.images[index].fileType.includes('image')) {
         // Found a valid image
         this.showFullImage = true;
         this.activeIndex = index;
         return;
       }
   
       // If not an image, decide the next step based on direction
       if (isMovingForward) {
         index = (index + 1) % imagesLength; // Move forward with wrap-around
       } else {
         index = (index - 1 + imagesLength) % imagesLength; // Move backward with wrap-around
       }
   
       // Stop if we've looped back to the starting point
       if (index === startIndex) {
         break;
       }
     }
   
     // No valid images found after looping through all options
     this.showFullImage = false;
     this.activeIndex = -1; // Indicate no valid index
   } */


  fileUrl: string = '';
  fileType: string = '';
  showOpenFileDialog = false;
  restrictPrint = false;

  openFile(fileUrl: string, fileType: string) {
    this.fileUrl = fileUrl;
    this.fileType = fileType.toLowerCase();
    this.showOpenFileDialog = true;
  }

  downloadFile(fileUrl: string) {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = ''; // Set a filename if required
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // Print functionality based on restriction flag
  printFile() {
    if (!this.restrictPrint) {
      window.open(this.fileUrl, '_blank')?.print();
    }
  }

  imageClick(index: number, fileType: string) {
    if (fileType.includes('image')) {
      this.activeIndex = index;
      this.displayCustom = true;
      this.showSkeletonFullImage = true;
      this.showFullImage = false;
    } else {
      this.openFile(this.images[index].url, this.images[index].fileType);
    }
  }

  // Swipe Left: Move to next image
  onSwipeLeft() {
    // alert(`onSwipeLeft`);
    this.activeIndex = (this.activeIndex + 1) % this.images.length;
  }

  // Swipe Right: Move to previous image
  onSwipeRight() {
    // alert(`onSwipeRight`)

    this.activeIndex = (this.activeIndex - 1 + this.images.length) % this.images.length;
  }

  showImageLoading() {

    this.showSkeletonFullImage = false;
    this.showFullImage = true;

  }

  showSkeletonFullImage = false;
  showFullImage = true;

  private startX: number | null = null;
  swipeDirection: string | null = null;

  onSwipeStart(event: MouseEvent | TouchEvent) {
    this.startX = this.getX(event);
    this.swipeDirection = null; // Reset swipe direction

    //  console.log('Swipe Start Detected');
    // console.log('Start X Position:', this.startX);
  }

  onSwipeEnd(event: MouseEvent | TouchEvent) {
    if (this.startX === null) {
      // console.warn('Swipe End Triggered Without Start');
      return; // Skip if startX wasn't set
    }

    const endX = this.getX(event);
    //  console.log('Swipe End Detected');
    //console.log('End X Position:', endX);

    const deltaX = this.startX - endX;
    // console.log('Delta X (Start - End):', deltaX);

    if (Math.abs(deltaX) > 50) {
      this.swipeDirection = deltaX > 0 ? 'left' : 'right';
      console.log('Swipe Direction Detected:', this.swipeDirection);
      if (this.swipeDirection === 'right') {
        this.onSwipeRight();
      }
      else if (this.swipeDirection === 'left') {
        this.onSwipeLeft();
      }
    } else {
      // console.log('Swipe Ignored: Delta too small');
    }

    this.startX = null; // Reset startX after each swipe
  }

  private getX(event: MouseEvent | TouchEvent): number {
    let x = 0;
    if (event instanceof MouseEvent) {
      x = event.clientX;
      //   console.log('Mouse Event Detected - X:', x);
    } else if (event.changedTouches && event.changedTouches.length > 0) {
      x = event.changedTouches[0].clientX;
      // console.log('Touch Event Detected - X:', x);
    } else {
      // console.warn('Unknown Event Type Detected');
    }
    return x;
  }

  removeImage(index: number) {
    if (index >= 0 && index < this.images.length) {
      // Remove the current image
      this.loadingDeleteImage[this.tempConfirmDeleteImageIndex] = true;
      const document = this.images[this.tempConfirmDeleteImageIndex];
      const documentId = document.id;
      console.log(`Document Id is ${documentId}`);
      console.log(`Documetn object is ${JSON.stringify(document)}`);
      this.containerService.deleteDocument(documentId).subscribe
        (
          () => {
            this.images.splice(index, 1);
            this.loadingDeleteImage[this.tempConfirmDeleteImageIndex] = false;
            // Check if there are still images left
            if (this.images.length > 0) {
              // Update the active index to the next image if possible
              if (index < this.images.length) {
                this.activeIndex = index + 1; // Move to the next image in the list
              } else {
                this.activeIndex = this.images.length - 1; // Move to the last image if index is out of bounds
              }
            } else {
              // Close full-screen view if no images are left
              this.displayCustom = false;
            }
            this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Image deleted successfully', life: 3000 });
          },
          (error) => {
            this.loadingDeleteImage[this.tempConfirmDeleteImageIndex] = false;

            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to delete image', life: 3000 });
          }
        );
    }
  }


  removeImageByDocumentId(index: number) {
    // Remove the current image
    this.cdSingleImagesDelete.visible = false; // Hide the dialog
    if (this.fullImageOperation) {
      index = this.activeIndex;
    }
    this.loadingDeleteImage[index] = true;
    const document = this.images[index];
    //const documentId = document.id;
    console.log(`Document Id is ${document.id}`);
    console.log(`Documetn object is ${JSON.stringify(document)}`);

    this.containerService.deleteDocument(document.id).subscribe
      (
        () => {
          this.images.splice(index, 1);
          this.loadingDeleteImage[index] = false;
          // Check if there are still images left
          if (this.images.length > 0) {
            // Update the active index to the next image if possible
            if (index < this.images.length) {
              this.activeIndex = index + 1; // Move to the next image in the list
            } else {
              this.activeIndex = this.images.length - 1; // Move to the last image if index is out of bounds
            }
          } else {
            // Close full-screen view if no images are left
            this.displayCustom = false;
          }
          this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Image deleted successfully', life: 3000 });

        },
        (error) => {
          this.loadingDeleteImage[index] = false;

          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to delete image', life: 3000 });
        }
      );
  }

  removeMultipleImagesByDocumentId() {
    for (var a = 0; a < this.selectedImages.length; a++) {
      const image = this.selectedImages[a];
      this.loadingDeleteImage[image.index] = true;
    }
    this.cdMultipleImagesDelete.visible = false; // Hide the dialog
    for (var i = 0; i < this.selectedImages.length; i++) {
      const image = this.selectedImages[i];
      console.log(`Image is ${JSON.stringify(image)}`);
      console.log(`Image is ${JSON.stringify(image.index)}`);
      console.log(`Image is ${JSON.stringify(image.image)}`);


      this.containerService.deleteDocument(image.image.id).subscribe
        (
          () => {
            this.images.splice(image.index, 1);
            // Check if there are still images left
            if (this.images.length > 0) {
              // Update the active index to the next image if possible
              if (image.index < this.images.length) {
                this.activeIndex = image.index + 1; // Move to the next image in the list
              } else {
                this.activeIndex = this.images.length - 1; // Move to the last image if index is out of bounds
              }
            } else {
              // Close full-screen view if no images are left
              this.displayCustom = false;
            }
            this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Image deleted successfully', life: 3000 });
            this.loadingDeleteImage[image.index] = false;
            //            this.cdMultipleImagesDelete.close();

          },
          (error) => {
            this.loadingDeleteImage[image.index] = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to delete image', life: 3000 });
          }
        );

    }
    this.selectedImages = []; // Clear the selected images.
    this.allowSelectionImage = false;
  }

  acceptConfirmImage() {
    // this.images.splice(this.tempConfirmDeleteImageIndex, 1);
    this.removeImage(this.tempConfirmDeleteImageIndex);
    this.confirmPopupDeleteImage.accept();
  }

  rejectConfirmImage() {
    this.loadingDeleteImage[this.tempConfirmDeleteImageIndex] = false;
    this.confirmPopupDeleteImage.reject();
  }
  tempConfirmDeleteImageIndex = -1;

  confirmDeleteSingleImage() {
    this.confirmationService.confirm({
      header: 'Confirm Deletion',
      message: 'Are you sure you want to delete the selected image?',
      key: 'singleImageDelete',
      accept: () => {
        this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
      },
      reject: () => {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
      }
    });
  }

  confirmDeleteMultipleImage() {
    this.confirmationService.confirm({
      header: 'Confirm Deletion',
      message: `Are you sure you want to delete the ${this.selectedImages.length} selected images?`,
      key: 'multipleImagesDelete',
      accept: () => {
        this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
      },
      reject: () => {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
      }
    });
  }

  convertBytesToMb(bytes: number): string {
    return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
  }

  updateGalleryValue(event: any) {
    this.images = event;  // Manually handle value updates
  }

  initAttachmentMenu() {
    console.log('Selected Image Index:', this.indexDocumentSelectedByAttachmentMenu);
    console.log('Selected Image:', this.images?.[this.indexDocumentSelectedByAttachmentMenu]);

    this.attachmentMenuItems = [
      {
        label: 'Options',
        items: [
          {
            label: 'Delete',
            icon: 'pi pi-trash',
            command: () => {
              this.confirmDeleteSingleImage();
            }
          },
          {
            label: 'Privacy',
            icon: 'pi pi-eye',
            items: [
              {
                label: 'Private',
                icon: this.getIconForPrivacy(PrivacyType.PRIVATE),
                disabled: this.isDisabledForPrivacy(PrivacyType.PRIVATE),
                command: () => {
                  console.log('Updating privacy to PRIVATE for image index:', this.indexDocumentSelectedByAttachmentMenu);
                  this.updatePrivacyForSelectedImage(
                    PrivacyType.PRIVATE,
                    [this.images[this.indexDocumentSelectedByAttachmentMenu].id],
                    this.indexDocumentSelectedByAttachmentMenu
                  );
                }
              },
              {
                label: 'Public',
                icon: this.getIconForPrivacy(PrivacyType.PUBLIC),
                disabled: this.isDisabledForPrivacy(PrivacyType.PUBLIC),
                command: () => {
                  console.log('Updating privacy to PUBLIC for image index:', this.indexDocumentSelectedByAttachmentMenu);
                  this.updatePrivacyForSelectedImage(
                    PrivacyType.PUBLIC,
                    [this.images[this.indexDocumentSelectedByAttachmentMenu].id],
                    this.indexDocumentSelectedByAttachmentMenu
                  );
                }
              },
              {
                label: 'Protected',
                icon: this.getIconForPrivacy(PrivacyType.PROTECTED),
                disabled: this.isDisabledForPrivacy(PrivacyType.PROTECTED),
                command: () => {
                  console.log('Updating privacy to PROTECTED for image index:', this.indexDocumentSelectedByAttachmentMenu);
                  this.updatePrivacyForSelectedImage(
                    PrivacyType.PROTECTED,
                    [this.images[this.indexDocumentSelectedByAttachmentMenu].id],
                    this.indexDocumentSelectedByAttachmentMenu
                  );
                }
              }
            ]
          },
          {
            separator: true
          },
          {
            label: 'Open',
            icon: 'pi pi-file-o',
            command: () => this.openFile(this.images?.[this.indexDocumentSelectedByAttachmentMenu]?.url, 'pdf'),
            visible: this.images?.[this.indexDocumentSelectedByAttachmentMenu]?.fileType &&
              !this.images?.[this.indexDocumentSelectedByAttachmentMenu]?.fileType.includes('image')
          },
          {
            label: 'Download',
            icon: 'pi pi-download',
            command: () => this.downloadFile(this.images?.[this.indexDocumentSelectedByAttachmentMenu]?.url),
            visible: this.images?.[this.indexDocumentSelectedByAttachmentMenu]?.fileType &&
              !this.images?.[this.indexDocumentSelectedByAttachmentMenu]?.fileType.includes('image')
          }
        ]
      }
    ];
  }

  getIconForPrivacy(privacyType) {
    const currentPrivacy = this.images?.[this.indexDocumentSelectedByAttachmentMenu]?.privacy;
    console.log(`Checking icon for privacyType ${privacyType}:`, currentPrivacy);
    return currentPrivacy === privacyType ? 'pi pi-check' : '';
  }

  isDisabledForPrivacy(privacyType) {
    const currentPrivacy = this.images?.[this.indexDocumentSelectedByAttachmentMenu]?.privacy;
    console.log(`Checking disabled for privacyType ${privacyType}:`, currentPrivacy);
    return currentPrivacy === privacyType;
  }

  initMultipleAttachmentMenu() {
    this.imageSelectionMenuItems = [
      {
        label: 'Options',
        items: [
          {
            label: 'Delete',
            icon: 'pi pi-trash',
            command: () => {
              //  this.refreshImage() 
              // const documentId = this.images[this.indexDocumentSelectedByAttachmentMenu].id;
              // const privacyType = this.images[this.indexDocumentSelectedByAttachmentMenu].privacy;
              //alert(`Document Index Clicked is ${this.indexDocumentSelectedByAttachmentMenu} and documentId is ${documentId} and privacyType is ${privacyType}`);
              // this.removeMultipleImagesByDocumentId();
              this.confirmDeleteMultipleImage();
            }
          },
          {
            label: 'Privacy',
            icon: 'pi pi-eye',
            items: [
              {
                label: 'Private',
                icon: 'pi pi-lock',
                command: () => {
                  this.updatePrivacyForMultipleImages(PrivacyType.PRIVATE);
                }
              },
              {
                label: 'Public',
                icon: 'pi pi-globe',
                command: () => {
                  this.updatePrivacyForMultipleImages(PrivacyType.PUBLIC);
                }
              },
              {
                label: 'Protected',
                icon: 'pi pi-shield',
                command: () => {
                  this.updatePrivacyForMultipleImages(PrivacyType.PROTECTED);
                }
              }
            ]
          }
        ]
      }
    ];
  }
  fullImageOperation = false;
  indexDocumentSelectedByAttachmentMenu = -1;
  attachmentMenuItemClicked(event: any, index: number, fullImageOperation: boolean = false) {
    console.log(`attachmentMenuItemCicked is ${index}`);
    this.indexDocumentSelectedByAttachmentMenu = index;
    this.initAttachmentMenu(); // Reinitialize menu dynamically

    this.fullImageOperation = fullImageOperation;
    this.attachmentMenu.toggle(event);
  }

  imageSelectionMenuFn(event: any) {
    this.imageSelectionMenu.toggle(event);
  }



  // Function to handle checkbox change event
  // onImageSelectChangeByCheckbox(event: Event, index: number): void {
  //    const checkbox = event.target as HTMLInputElement;

  //    if (checkbox.checked) {
  //       // Add the index to selectedImages if checked
  //       if (!this.selectedImagesByCheckbox.includes(index)) {
  //          this.selectedImagesByCheckbox.push(index);
  //       }
  //    } else {
  //       // Remove the index from selectedImages if unchecked
  //       const idx = this.selectedImagesByCheckbox.indexOf(index);
  //       if (idx !== -1) {
  //          this.selectedImagesByCheckbox.splice(idx, 1);
  //       }
  //    }

  //    console.log('Selected Images:', this.selectedImagesByCheckbox);
  // }

  getIndexById(id: number): number {
    const index = this.images.findIndex(image => image.id === id);
    return index; // Returns -1 if the id is not found
  }

  onImageToggle(image: any): void {
    const index = this.getIndexById(image.id);
    const imageWithIndex = { image, index };
    const existingIndex = this.selectedImages.findIndex(item => item.index === index);

    if (existingIndex === -1) {
      // If the index is not in selectedImagesByCheckbox, add it
      this.selectedImages.push(imageWithIndex);
    } else {
      // If the index is already in selectedImagesByCheckbox, remove it
      this.selectedImages.splice(existingIndex, 1);
    }
    console.log('Selected Images:', this.selectedImages);
  }


  isImageSelected(index: number): boolean {
    return this.selectedImages.some(item => item.index === index);
  }

  updatePrivacyForSelectedImage(privacyType: PrivacyType, documentId: number[], index: number): void {
    this.loadingDeleteImage[index] = true;  // Set loading to true for this button
    this.containerService.updatePrivacy(documentId, privacyType).subscribe(
      response => {
        console.log(`Privacy updated successfully: ${response}`);
        this.images[this.indexDocumentSelectedByAttachmentMenu].privacy = privacyType;
        this.messageService.add({ severity: 'info', summary: 'Success', detail: 'Privacy updated successfully', life: 3000 });
        this.loadingDeleteImage[index] = false;  // Set loading to true for this button
        this.initAttachmentMenu();
      },
      error => {
        console.error(`Failed to update privacy: ${error}`);
        this.loadingDeleteImage[index] = false;  // Set loading to true for this button
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to update privacy', life: 3000 });
      }
    );
  }

  updatePrivacyForMultipleImages(privacyType: PrivacyType): void {
    for (var a = 0; a < this.selectedImages.length; a++) {
      const image = this.selectedImages[a];
      this.loadingDeleteImage[image.index] = true;
    }

    for (var i = 0; i < this.selectedImages.length; i++) {
      const image = this.selectedImages[i].image;
      const index = this.selectedImages[i].index;
      this.containerService.updatePrivacy([image.id], privacyType).subscribe(
        response => {
          console.log(`Privacy updated successfully: ${response}`);
          this.images[index].privacy = privacyType;
          this.messageService.add({ severity: 'info', summary: 'Success', detail: 'Privacy updated successfully', life: 3000 });
          this.loadingDeleteImage[index] = false;  // Set loading to true for this button
          this.initAttachmentMenu();
        },
        error => {
          console.error(`Failed to update privacy: ${error}`);
          this.loadingDeleteImage[index] = false;  // Set loading to true for this button
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to update privacy', life: 3000 });
        }
      );
    }
    this.selectedImages = []; // Clear the selected images
    this.allowSelectionImage = false;
  }

  getImageClass(privacyType: PrivacyType): string {
    if (privacyType === 'PUBLIC') {
      return 'pi pi-globe';
    }
    else if (privacyType === 'PRIVATE') {
      return 'pi pi-lock';
    }
    else if (privacyType === 'PROTECTED') {
      return 'pi pi-shield';
    } else {
      return 'pi pi-lock';

    }

  }
 
}
