import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { Observable, of } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { Photo } from 'src/app/modules/container/model/photo';
import { ContainerService } from 'src/app/modules/container/services/container.service';
import { Photos } from 'src/app/modules/measurement/model/photos';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';


@Component({
  selector: 'app-sawn-timber-photos',
  templateUrl: './sawn-timber-photos.component.html',
  styleUrls: ['./sawn-timber-photos.component.css'],
  providers: [ToastMessagesService],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class SawnTimberPhotosComponent implements OnInit, AfterViewInit {
  @Input() inputPhotos!: Photos;
  @Input() containerId!: number;
  refreshFlag = true;

  fileName = '';
  progress = 0;
  currentIndex = 0;
  pScrollerWidth!: string;
  pScrollerImgWidth!: string;
  pScrollerHeight!: string;

  // containerId!: number;
  files: Photo[] = [];
  responsiveOptions!: any[];
  first: number = 0;
  images1!: any[];
  showProducts = false;
  isMobile!: boolean;
  scrollHeight!: string;
  existingImages: Photo[] = [];

  activeIndex = 0;
  isFullScreenImage = false;
  selectedImageUrlForFullScreen!: string;
  errorMessages!: any[];

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: ToastMessagesService,
    private containerService: ContainerService,
    private encryptedStorageService: EncryptedStorageService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.setScrollHeight();
    //this.containerId = this.measurementSharedDataService.getContainerIdStorage();
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 5,
      },
      {
        breakpoint: '768px',
        numVisible: 3,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
      },
    ];
  }
  ngAfterViewInit(): void {
    // throw new Error('Method not implemented.');
    this.getContainerFromStorage();
  }

  ngOnInit(): void {
    // this.getContainerFromStorage();
  }

  onUpload(event: any) {
    // Check if total file count will exceed the maximum limit
    if (this.files.length + event.files.length > AppConstants.MAX_NUMBER_OF_IMAGES_UPLOAD) {
      this.messageService.showErrorMessage('File Count Exceeded',`Total file count should not exceed ${AppConstants.MAX_NUMBER_OF_IMAGES_UPLOAD}. Please select fewer files.`);
      return;
    }

    this.errorMessages = []; // initialize errorMessages array before processing files
    for (let file of event.files) {
      this.uploadFile(file);
    }
    if (this.errorMessages.length > 0) {

    }
  }

  deleteImage($event: Event, photo: Photo) {
    console.log(`event ${JSON.stringify(event)}`);
    console.log(`Photo ${JSON.stringify(photo)}`);
    this.confirmationService.confirm({
      target: event!.target!,
      message: 'Are you sure you want to delete this image?',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirm1',
      accept: () => {
        this.deleteByGallaryActiveImageIndex(photo.photoId);
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
      },
      reject: () => {
        //this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
      },
    });
  }

  deleteByGallaryActiveImageIndex(id: number) {
    console.log(
      `deleteUploadedFile index is ${
        this.activeIndex
      } and fileObject is ${JSON.stringify(
        this.existingImages[this.activeIndex]
      )}`
    );
    // const photo = this.existingImages[this.activeIndex];
    //photo.deleteInProgress = true;

    this.containerService.deleteByPhotoId(id).subscribe(
      (respons: any) => {
        this.totalFileCount--;
        /* setTimeout(() => {
          console.log(`File Removed Successfully`);
          this.messageService.showSuccessMessage(
            'Image Deleted Successfully',
            'The image has been deleted successfully.'
          );
        }, 750); */
      },
      (error: any) => {
        console.error(`Error while Removing Photo ${JSON.stringify(error)}`);
        this.messageService.showErrorMessage(
          'Error While Deleting Image',
          'There was an error while deleting the image. Please try again later.'
        );
        //photo.deleteInProgress = false;
      },
      () => {
        this.ngAfterViewInit();
        this.changeDetector.detectChanges();

        this.messageService.showSuccessMessage(
          'Image Deleted Successfully',
          'The image has been deleted successfully.'
        );
      }
    );
  }

  totalFileCount = 0; // this.files.length; // Initialize with already selected files count
  filesBeingUploaded = 0; // increment counter when a file starts uploading

  uploadFile(file: any) {
    if (!this.isValidFile(file)) {
     this.messageService.showErrorMessage('Invalid File', 'Invalid file. Please select a valid file and try again.');


      return;
    }
    let validationMessage = this.isValidFile(file);
    if (validationMessage !== 'valid') {
      this.errorMessages.push(validationMessage);
      return;
    }
    this.filesBeingUploaded++; // increment counter when a file starts uploading

    console.log(`File Values ${JSON.stringify(file)}`);
    const index = this.files.length;

    this.files.push({
      ...file,
      progress: 0,
      isNew: true,
      uploadInProgress: true,
    });

    const newFile = this.files[index];

    // Start the progress simulation as soon as the file is set for upload
    this.simulateProgress(newFile);

    this.containerService
      .uploadContainerImages(file, this.containerId)
      .subscribe(
        (response: Photo[]) => {
          console.log(`response1 ${JSON.stringify(response)}`);
          response.forEach((eachFileResponse: Photo) => {
            console.log(
              `eachFileResponse ${JSON.stringify(
                JSON.stringify(eachFileResponse)
              )}`
            );
            // Remove progress simulation and update the file data once upload is complete
            this.finishUploading(newFile, eachFileResponse);
          });
        },
        (error: any) => {
          console.log(error);
          // decrease totalFileCount if the upload fails
          this.totalFileCount--;
          this.files = [];

        },
        ()=>{
          this.files = [];
        }
      );
  }

  simulateProgress(file: any) {
    const updateProgress = (progress: number) => {
      if (progress < 100) {
        setTimeout(() => {
          file.progress = progress;
          console.log(`finish uploading ${file.progress}`);
          updateProgress(progress + Math.floor(Math.random() * 10) + 1);
        }, 100); // Change this value to control the speed of the progress bar
      }
    };
    updateProgress(0);
  }

  /* uploadFile(file: any) {
    if (!this.isValidFile(file)) {
      return;
    }
     let validationMessage = this.isValidFile(file);
    if (validationMessage !== 'valid') {
      this.errorMessages.push(validationMessage);
      return;
    }
    this.filesBeingUploaded++; // increment counter when a file starts uploading

    console.log(`File Values ${JSON.stringify(file)}`);
    const index = this.files.length;

    this.files.push({
      ...file,
      progress: 0,
      isNew: true,
      uploadInProgress: true,
    });

    const newFile = this.files[index];

    this.containerService
      .uploadContainerImages(file, this.containerId)
      .subscribe(
        (response: Photo[]) => {
          console.log(`response1 ${JSON.stringify(response)}`);
          response.forEach((eachFileResponse: Photo) => {
            console.log(
              `eachFileResponse ${JSON.stringify(
                JSON.stringify(eachFileResponse)
              )}`
            );
            this.simulateProgress(newFile, eachFileResponse);

          });
        },
        (error: any) => {
          console.log(error);
          // decrease totalFileCount if the upload fails
          this.totalFileCount--;
        }
      ), ()=>{
        

      };
  }

  simulateProgress(file: any, eachFileResponse: Photo) {
    const updateProgress = (progress: number) => {
      if (progress < 100) {
        setTimeout(() => {
          file.progress = progress;
          file.name = this.updateFileName(file.name);
          console.log(`finish uploading ${file.progress}`);
          updateProgress(progress + Math.floor(Math.random() * 10) + 1);
        }, 100); // Change this value to control the speed of the progress bar
      } else {
        this.finishUploading(file, eachFileResponse);
      }
    };
    updateProgress(0);
  }
 */
  updateFileName(name: string): string {
    if (!name) return 'Uploading.';

    const dotCount = (name.match(/\./g) || []).length;
    if (dotCount == 5) {
      return 'Uploading';
    }
    return name + '.';
  }
  @ViewChild('fileUploadForm') fileUploadForm!: NgForm;
  @ViewChild('fileUpload') fileUpload!: FileUpload;

  finishUploading(file: any, eachFileResponse: Photo) {
    console.log(`finish uploading file ${JSON.stringify(file)}`);
    console.log(
      `finish uploading eachFileResponse ${JSON.stringify(eachFileResponse)}`
    );
    file.progress = 100;
    file.isNew = false;
    file.uploadInProgress = false;
    this.files.push(eachFileResponse);
    this.files = this.files.filter((obj) => obj !== file);

    // Important: Update totalFileCount accordingly.
    this.totalFileCount = this.files.length;
    this.filesBeingUploaded--; // decrement counter when a file finishes uploading
    if (this.filesBeingUploaded === 0) {
      // if no more files are being uploaded
      this.fileUpload.clear(); // clear the file upload component
      this.fileUploadForm.resetForm(); // reset the form

      this.fileUploadForm.resetForm(); // reset the form
    }
    this.ngAfterViewInit();
  }

  isValidFile(file: any): string {
    const isImage = /^image\//.test(file.type);
    const isFileSizeValid = file.size <= AppConstants.MAX_IMAGE_SIZE_IN_MB * 1024 * 1024;
    // const isFileCountValid = (this.totalFileCount + 1) <= 5;
    const isFileCountValid = this.files.length + 1 <= AppConstants.MAX_NUMBER_OF_IMAGES_UPLOAD;

    if (isImage && isFileSizeValid && isFileCountValid) {
      this.totalFileCount++; // Increment the counter if the file is valid
      return 'valid';
    } else {
      if (!isImage) {
        return 'Invalid file type. Only image files are allowed.';
      } else if (!isFileSizeValid) {
        return `File size exceeds the limit of ${AppConstants.MAX_IMAGE_SIZE_IN_MB}MB.`;
      } else {
        return `File count exceeds the limit of ${AppConstants.MAX_NUMBER_OF_IMAGES_UPLOAD}.`;
      }
    }
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  initializeFormWithExistingData(existingFiles: any[]) {
    for (const file of existingFiles) {
      this.files.push({
        ...file,
        isNew: false,
      });
    }
  }

  deleteUploadedFile(index: number, photo: Photo) {
    console.log(
      `deleteUploadedFile index is ${index} and fileObject is ${JSON.stringify(
        photo
      )}`
    );
    photo.deleteInProgress = true;

    this.containerService.deleteByPhotoId(photo.photoId).subscribe(
      (respons: any) => {
        /*  setTimeout(() => {
          console.log(`File Removed Successfully`);
          const idsToRemove = this.files.map((obj) => photo);
          this.files = this.files.filter((obj) => !idsToRemove.includes(obj));
        }, 750); */
      },
      (error: any) => {
        console.error(`Error while Removing Photo ${JSON.stringify(error)}`);
        photo.deleteInProgress = false;
      },
      () => {
        const idsToRemove = this.files.map((obj) => photo);
        this.files = this.files.filter((obj) => !idsToRemove.includes(obj));
      }
    );
  }

  setScrollHeight() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    this.pScrollerWidth = width - 200 + 'px';
    console.log(
      `Window width: ${window.innerWidth}, height: ${window.innerHeight}`
    );
    if (width < 576) {
      this.scrollHeight = `${(14.2 * width) / 620}rem`;
      this.pScrollerHeight = `${(14 * width) / 620}rem`;
      this.pScrollerImgWidth = `${(14 * width) / 620}rem`;
      this.pScrollerWidth = `${(28 * width) / 580}rem`;
    } else if (width > 576 && width < 767) {
      // this.scrollHeight ="14.2" + 'rem';
      // this.pScrollerHeight = "14" + 'rem';
      // this.pScrollerImgWidth  = "14" + 'rem';
      this.scrollHeight = `${(14.2 * width) / 620}rem`;
      this.pScrollerHeight = `${(14 * width) / 620}rem`;
      this.pScrollerImgWidth = `${(14 * width) / 620}rem`;
      this.pScrollerWidth = `${(28 * width) / 580}rem`;
      console.log(`Value is ${this.pScrollerWidth}`);
    } else if (width > 768 && width < 992) {
      this.scrollHeight = `${(14.2 * width) / 620}rem`;
      this.pScrollerHeight = `${(14 * width) / 620}rem`;
      this.pScrollerImgWidth = `${(14 * width) / 620}rem`;
      this.pScrollerWidth = `${(28 * width) / 580}rem`;
    } else if (width > 993) {
      this.scrollHeight = `${(8.2 * width) / 620}rem`;
      this.pScrollerHeight = `${(8 * width) / 620}rem`;
      this.pScrollerImgWidth = `${(8 * width) / 620}rem`;
      this.pScrollerWidth = `${(20 * width) / 580}rem`;
    }
  }

  /* private getContainerImages(containerId: number) {
    console.log(`getContainerImages for ContainerID ${containerId}`);
    this.containerService.getContainerImages(containerId).subscribe(
      (data: Photo[]) => {
        console.log(`getContainerImages ${JSON.stringify(data)}`);
        this.existingImages = data;
        this.initializeFormWithExistingData(data);
        //this.totalFileCount = data.length;
        this.files.length = data.length;
      },
      (error: HttpErrorResponse) => {
        this.messageService.showErrorMessage(
          'Error',
          'Error while feching images'
        );
      },
      () => {}
    );
  }

  private getContainerFromStorage(): any {
    console.log(`getContainerFromStorage `);
    this.encryptedStorageService
      .getEncryptedDataFromStorage(AppConstants.CONTAINER)
      .subscribe(
        (data) => {
          this.containerId = data.containerId;
          this.getContainerImages(this.containerId);
          console.log(
            'Decrypted data: for getContainerFromStorage in sawn-timber-photos --> ',
            data
          );
        },
        (error) => {
          console.error('Failed to decrypt data:', error);
        }
      );
  } */

  /* existingImages$!: Observable<Photo[]>;
  private getContainerImages(containerId: number): Observable<Photo[]> {
    console.log(`getContainerImages for ContainerID ${containerId}`);
    return this.containerService.getContainerImages(containerId).pipe(
      tap((data: Photo[]) => {
        console.log(`getContainerImages ${JSON.stringify(data)}`);
        this.initializeFormWithExistingData(data);
        //this.totalFileCount = data.length;
        this.files.length = data.length;
      }),
      catchError((error: HttpErrorResponse) => {
        this.messageService.showErrorMessage(
          'Error',
          'Error while fetching images'
        );
        return of([]); // return an empty array on error
      })
    );
  }
  
  private getContainerFromStorage(): void {
    console.log(`getContainerFromStorage `);
    this.encryptedStorageService
      .getEncryptedDataFromStorage(AppConstants.CONTAINER)
      .pipe(
        tap(data => console.log(
          'Decrypted data: for getContainerFromStorage in sawn-timber-photos --> ',
          data
        )),
        switchMap(data => this.getContainerImages(data.containerId)),
        catchError(error => {
          console.error('Failed to decrypt data:', error);
          return of([]);
        })
      )
      .subscribe(data => this.existingImages$ = of(data));
  } */
  existingImages$!: Observable<Photo[]>;

  /* private getContainerImages(containerId: number): Observable<Photo[]> {
    console.log(`getContainerImages for ContainerID ${containerId}`);
    return this.containerService.getContainerImages(containerId).pipe(
      map((data: Photo[]) =>
        data.map((photo) => ({ ...photo, isLoaded: false }))
      ), // Add isLoaded property to each Photo
      tap((data: Photo[]) => {
        console.log(`getContainerImages ${JSON.stringify(data)}`);
        this.initializeFormWithExistingData(data);
        //this.totalFileCount = data.length;
        this.files.length = data.length;
      }),
      catchError((error: HttpErrorResponse) => {
        this.messageService.showErrorMessage(
          'Error',
          'Error while fetching images'
        );
        return of([]); // return an empty array on error
      })
    );
  }
 */
  imageLoadStatus = new Map<string, boolean>();

  private getContainerImages(containerId: number): Observable<Photo[]> {
    console.log(`getContainerImages for ContainerID ${containerId}`);
    return this.containerService.getContainerImages(containerId).pipe(
      map((data: Photo[]) => data),  // Don't add isLoaded property to each Photo
      tap((data: Photo[]) => {
        console.log(`getContainerImages ${JSON.stringify(data)}`);
        this.initializeFormWithExistingData(data);
        this.files.length = data.length;
        data.forEach(image => this.imageLoadStatus.set(image.thumbnailUrl, false));
      }),
      catchError((error: HttpErrorResponse) => {
        this.messageService.showErrorMessage(
          'Error',
          'Error while fetching images'
        );
        return of([]); // return an empty array on error
      })
    );
  }

  private getContainerFromStorage(): void {
    console.log(`getContainerFromStorage `);
    this.encryptedStorageService
      .getEncryptedDataFromStorage(AppConstants.CONTAINER)
      .pipe(
        tap((data) => {
          console.log(
            'Decrypted data: for getContainerFromStorage in sawn-timber-photos --> ',
            data
          )
          this.containerId = data.containerId;
        }),
        switchMap((data) => this.getContainerImages(data.containerId)),
        catchError((error) => {
          console.error('Failed to decrypt data:', error);
          return of([]);
        })
      )
      .subscribe((data) => (this.existingImages$ = of(data)));
  }

  /* private getContainerFromStorage(): void {
    console.log(`getContainerFromStorage `);
    this.encryptedStorageService
      .getEncryptedDataFromStorage(AppConstants.CONTAINER)
      .pipe(
        tap((data) =>
        
          console.log(
            'Decrypted data: for getContainerFromStorage in sawn-timber-photos --> ',
            data
          )
          
        ),
        switchMap((data) => this.getContainerImages(data.containerId)),
        catchError((error) => {
          console.error('Failed to decrypt data:', error);
          return of([]);
        })
      )
      .subscribe((data) => (this.existingImages$ = of(data)));
  } */

 /*  onImageLoad(image: Photo): void {
    this.changeDetector.detectChanges();
    image.isLoaded = true;
    console.log(`Image ${image.thumbnailUrl} is loaded`);
  } */

  onImageLoad(image: Photo): void {
    this.imageLoadStatus.set(image.thumbnailUrl, true);
    this.changeDetector.detectChanges();
    console.log(`Image ${image.thumbnailUrl} is loaded`);
  }

  onImageError(image: Photo): void {
    console.log(`Failed to load image ${image.thumbnailUrl}`);
  }

  onClear(){
    this.files = [];

  }

  onImage(){
  }
   onRemove(event: any){
  }

  onError(event: any ){
  }
}
