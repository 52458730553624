<p-card>
    <ng-template pTemplate="title">
      Your account has not been approved yet
    </ng-template>
    <p>
      Please wait for approval from an administrator. If you have any questions, please contact support.
    </p>
    <p-footer>
      <button pButton type="button" label="Go back to login" routerLink="/login"></button>
    </p-footer>
  </p-card>
  