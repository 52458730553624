import { HttpParams } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  HostListener,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Page } from 'src/app/commons/models/page';
import { LoadingSite } from '../model/loading-site';
import { LoadingSiteSharedDataService } from '../services/loading-site-shared-data.service';
import { LoadingSiteService } from '../services/loading-site.service';
import { UtilService } from 'src/app/services/util.service';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { SHARED_MODULES } from '../../shared-imports';
import { LoadingSiteComponent } from '../loading-site/loading-site.component';
import { EncryptionService } from 'src/app/services/encryption.service';

@Component({
  selector: 'app-loading-site-list',
  templateUrl: './loading-site-list.component.html',
  styleUrls: ['./loading-site-list.component.css'],
  providers: [ToastMessagesService],
  standalone:true,
  imports:[SHARED_MODULES, LoadingSiteComponent]
})
export class LoadingSiteListComponent implements OnInit {
  formHeader!: string;
  formSubHeader!: string;
  showNameColumn = true;
  showContactPersonColumn = true;
  showGstColumn = true;
  showCityColumn = true;
  showPhoneColumn = false;
  showEmailColumn = false;
  tableStyleOptions: any;
  loading: boolean = false;

  loadingSiteSidebarVisible: boolean = false;

  @Input() titleFromSidebar!: string;
  @Input() headerFromSidebar!: string;
  @Input() subHeaderFromSidebar!: string;
  protected items!: MenuItem[];
  loadingSiteSpeedDialItems!: MenuItem[];
  home!: MenuItem;
  rows = 5;
  loadingSites!: LoadingSite[];
  multiSortMeta!: any[];
  totalRecords: number = 0;
  first: number = 0;
  last: number = 0;
  rowsPerPageOptions = [5, 10, 20]; // set the options for the number of rows per page
  @ViewChild('globalFiterInput') globalFiterInput!: ElementRef;
  @ViewChild('dt') table!: Table;
  cols!: any[];
  isMobile = false;

  _selectedColumns!: any[];
  selectedLoadingSites: LoadingSite[] = [];
  tableStyle: any;
  constructor(
    private loadingSiteService: LoadingSiteService,
    private confirmationService: ConfirmationService,
    private loadingSiteSharedDataService: LoadingSiteSharedDataService,
    private router: Router,
    private titleService: Title,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private toastMessagesService: ToastMessagesService,
    private encryptionService: EncryptionService

  ) {
    this.setPageTitle();
    this.isMobile = this.utilService.isMobile();
  }

  ngOnInit() {
    this.initTableColumns();
    this.populateBreadCrumb();
    this.tableStyle = this.utilService.getTableSize();
    this.initSpeedDial();
    this.initTableStyle();
  }

  sortLoadingSites(event: any) {
    this.loading = true;
    //const globalFilter = this.globalFiterInput.nativeElement.value;
    /* console.log(`value ${globalFilter}`);
    console.log(`%%% ${globalFilter}`) */
    const pageNumber = Math.floor(event.first / event.rows) + 1;
    console.log(`Page Number ${pageNumber}`);
    console.log(`Event List ${JSON.stringify(event)}`);
    let params = new HttpParams();
    let sortOrder = '';
    console.log(`customSort field ${[event!.field!]}`);
    // console.log(`customSort data ${[event!.data!]}`)
    console.log(`customSort mode ${[event!.mode!]}`);
    console.log(`customSort order ${[event!.order!]}`);
    console.log(
      `customSort multiSortMeta ${JSON.stringify([event!.multiSortMeta!])}`
    );
    console.log(`multiSortMeta ${event!.multiSortMeta?.length}`);
    const multiSortLength = event!.multiSortMeta?.length;
    params = params.set('size', event.rows);
    params = params.set('page', pageNumber - 1);
    for (var i = 0; i < multiSortLength!; i++) {
      if (event!.multiSortMeta![i].order === -1) {
        sortOrder = 'DESC';
      } else {
        sortOrder = 'ASC';
      }
      console.log(`field ${event!.multiSortMeta![i].field}`);
      console.log(`order ${event!.multiSortMeta![i].order}`);

      if (multiSortLength! > 0) {
        console.log(`in if`);
        params = params.set(
          'sort',
          event!.multiSortMeta![i].field + ',' + sortOrder
        );
      }
    }
    console.log(`Params ${params.toString()}`);
    this.loadingSites = [];
    // filter implementation
    const filters = event.filters;
    if (event.filters.hasOwnProperty('name')) {
      console.log(`filters ${JSON.stringify(filters.name.length)}`);
      for (var i = 0; i < filters.name.length; i++) {
        if (filters.name[i].value) {
          params = params.set('name', filters.name[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('contactPerson')) {
      console.log(`filters ${JSON.stringify(filters.contactPerson.length)}`);
      for (var i = 0; i < filters.contactPerson.length; i++) {
        if (filters.contactPerson[i].value) {
          params = params.set('contactPerson', filters.contactPerson[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('city')) {
      console.log(`filters ${JSON.stringify(filters.city.length)}`);
      for (var i = 0; i < filters.city.length; i++) {
        if (filters.city[i].value) {
          params = params.set('city', filters.city[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('state')) {
      console.log(`filters ${JSON.stringify(filters.state.length)}`);
      for (var i = 0; i < filters.state.length; i++) {
        if (filters.state[i].value) {
          params = params.set('state', filters.state[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('country')) {
      console.log(`filters ${JSON.stringify(filters.country.length)}`);
      for (var i = 0; i < filters.country.length; i++) {
        if (filters.country[i].value) {
          params = params.set('country', filters.country[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('capacity')) {
      console.log(`filters ${JSON.stringify(filters.capacity.length)}`);
      for (var i = 0; i < filters.capacity.length; i++) {
        if (filters.capacity[i].value) {
          params = params.set('capacity', filters.capacity[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('machinesInstalled')) {
      console.log(
        `filters ${JSON.stringify(filters.machinesInstalled.length)}`
      );
      for (var i = 0; i < filters.machinesInstalled.length; i++) {
        if (filters.machinesInstalled[i].value) {
          params = params.set(
            'machinesInstalled',
            filters.machinesInstalled[i].value
          );
        }
      }
    }
    if (event.filters.hasOwnProperty('notes')) {
      console.log(`filters ${JSON.stringify(filters.notes.length)}`);
      for (var i = 0; i < filters.notes.length; i++) {
        if (filters.notes[i].value) {
          params = params.set('notes', filters.notes[i].value);
        }
      }
    }

    this.loadingSiteService.getListLoadingSites(params).subscribe(
      (response: Page<LoadingSite>) => {
        this.loadingSites = response.content;
        this.first =
          response.pageable.pageNumber * response.pageable.pageSize + 1;
        const max =
          response.pageable.pageNumber * response.pageable.pageSize +
          response.pageable.pageSize;
        this.last = max < response.totalElements ? max : response.totalElements;
        this.totalRecords = response.totalElements;
      },
      (error) => { },
      () => {
        this.loading = false;
      }
    );
  }

  globalFilter(value: any, table: Table) {
    console.log(`globalFilter  ${JSON.stringify(value)}`);
    const globalFilter = this.globalFiterInput.nativeElement.value;
    console.log(`value ${globalFilter}`);
    if (globalFilter) {
      let params = new HttpParams();
      params = params.append('q', globalFilter);
      this.loadingSiteService.getListLoadingSitesGlobalFilter(params).subscribe(
        (response: Page<LoadingSite>) => {
          this.loadingSites = response.content;
          this.first =
            response.pageable.pageNumber * response.pageable.pageSize + 1;
          const max =
            response.pageable.pageNumber * response.pageable.pageSize +
            response.pageable.pageSize;
          this.last =
            max < response.totalElements ? max : response.totalElements;
          this.totalRecords = response.totalElements;
        },
        (error) => { },
        () => { }
      );
    } else {
      table.clear();
    }
  }

  clear(table: Table) {
    table.clear();
  }

  clearAllFilter(table: Table, field: HTMLInputElement) {
    field.value = ''; // cl
    table.clear();
  }

  initTableColumns() {
    this._selectedColumns = [
      'Contact Person',
      'Country',
      'Capacity',
      'Machines Installed',
      'Notes',
    ];
    this.cols = [
      'Contact Person',
      'Country',
      'State',
      'City',
      'Capacity',
      'Machines Installed',
      'Notes',
    ];
  }

  deleteRows() {
    const ids: number[] = this.selectedLoadingSites.map(
      (site) => site.loadingSiteId
    );

    if (ids.length === 0) {
      this.toastMessagesService.showWarningMessage(
        'No Selection',
        'No rows selected for deletion.'
      );
      return;
    }

    this.confirmationService.confirm({
      header: 'Delete Loading Site',
      message: 'Are you sure you want to delete this loading site?',
      accept: () => {
        /* this.loadingSiteService.deleteListLoadingSites(ids).subscribe(
          () => {
            this.toastMessagesService.showInfoMessage(
              'Success',
              'Loading Site Deleted Successfully'
            );
            this.refreshTable();
          },
          () => {
            this.toastMessagesService.showErrorMessage(
              'Operation Unsuccessful',
              'An error occurred. Please try again.'
            );
          }
        ); */
        this.loadingSiteService.deleteListLoadingSites(ids).subscribe(
          () => {
            this.toastMessagesService.showInfoMessage(
              'Success',
              'Loading Site Successfully'
            );
            this.refreshTable();
          },
          (error) => {
            if (error.status === 409) {
              console.error(`Error while deleting is ${JSON.stringify(error)}`);
              // Assuming 409 is the HTTP status code for 'Conflict'
              // Show PrimeNG Confirm Dialog
              this.confirmationService.confirm({
                message: 'Warning: Deleting this loading site will also result in the permanent removal of all linked records, such as containers, measurements, and BL. This action is irreversible. Would you like to proceed?',
                accept: () => {
                  // User confirmed, proceed with force delete
                  this.loadingSiteService.deleteListLoadingSites(ids, true).subscribe(
                    () => {
                      this.toastMessagesService.showInfoMessage(
                        'Success',
                        'Loading Site Force-Deleted Successfully'
                      );
                      this.refreshTable();
                    },
                    () => {
                      this.toastMessagesService.showErrorMessage(
                        'Operation Unsuccessful',
                        'An error occurred. Please try again.'
                      );
                    }
                  );
                },
              });
            } else {
              this.toastMessagesService.showErrorMessage(
                'Operation Unsuccessful',
                'An error occurred. Please try again.'
              );
            }
          }
        );
      },
    });
  }

  refreshTable() {
    this.loadingSiteService
      .getListLoadingSites()
      .subscribe((response: Page<LoadingSite>) => {
        this.loadingSites = response.content;
        this.first =
          response.pageable.pageNumber * response.pageable.pageSize + 1;
        const max =
          response.pageable.pageNumber * response.pageable.pageSize +
          response.pageable.pageSize;
        this.last = max < response.totalElements ? max : response.totalElements;
        this.totalRecords = response.totalElements;
        this.selectedLoadingSites = [];
      });
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) => val.includes(col));
  }
  getSelectedLoadingSites() {
    console.log(`Selected Loading Sitess length is ${this.selectedLoadingSites.length
      } 
     and \nData is ${JSON.stringify(this.selectedLoadingSites)}`);
  }

  onRowSelect(event: any) {
    //this.messageService.add({ severity: 'info', summary: 'Product Selected', detail: event.data.name });
  }

  onRowClick(data: LoadingSite) {
    /* console.log(`onRowClick Data is ${JSON.stringify(data)}`);
    this.loadingSiteSharedDataService.saveDataToLocalStorage(data);
    console.log(
      `Get data ${JSON.stringify(
        this.loadingSiteSharedDataService.getDataFromLocalStorage()
      )}`
    );
    this.router.navigate(['/edit/loading-sites']); */
    console.log(`onRowClick Data is ${JSON.stringify(data)}`);
    const id = "" + data.loadingSiteId;
    // const encryptedId = btoa(id); // Encrypt the ID
    // this.router.navigate(['/edit/loading-sites', encryptedId]);
    this.encryptionService.encrypt(id).then((encryptedId) => {
      this.router.navigate(['/edit/loading-sites', encryptedId]);
    });
  }

  populateBreadCrumb() {
    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.items = [{ label: 'Loading Sites' }];
  }
  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    // this.isMobile = this.utilService.isMobile();
    console.log(`is mobile ${this.utilService.isMobile()}`);
    // this.tableStyle = this.utilService.getTableSize();
    this.initTableStyle();
  }

  initSpeedDial() {
    this.loadingSiteSpeedDialItems = [
      {
        icon: 'pi pi-filter-slash',
        command: () => {
          this.globalFiterInput.nativeElement.value = '';
          this.table.clear();
        },
      },
      {
        icon: 'pi pi-search',
        command: () => {
          this.focusOnGlobalFilter();
        },
      },
      {
        icon: 'pi pi-trash',
        command: () => {
          this.deleteRows();
          //this.messageService.add({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
        },
      },
      {
        icon: 'pi pi-plus',
        command: () => {
          this.loadingSiteSidebarVisible = true;
          // this.deleteRows()
          //this.messageService.add({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
        },
      },
    ];
  }

  focusOnGlobalFilter() {
    this.globalFiterInput.nativeElement.focus();
  }

  clearFilter(dt: any) {
    this.table.clear();
  }

  onLoadingSiteSubmit(event: Event) {
    this.table.reset();
  }

  initTableStyle() {
    this.isMobile = this.utilService.isMobile();
    this.tableStyle = this.utilService.getTableSize();
    this.tableStyleOptions = this.utilService.getTableMinWidth();
  }
}
