import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { LogoutService } from 'src/app/services/logout.service';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-user-added-to-existing-company-successfully-message',
  templateUrl: './user-added-to-existing-company-successfully-message.component.html',
  styleUrls: ['./user-added-to-existing-company-successfully-message.component.css'],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class UserAddedToExistingCompanySuccessfullyMessageComponent  implements OnInit, OnDestroy {

  countdown: number = 5;
  private countdownSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private logOutService: LogoutService,
    private toastMessageService: ToastMessagesService
  ) {}

  ngOnInit() {
    this.startCountdown();
  }

  ngOnDestroy() {
    this.countdownSubscription?.unsubscribe();
  }

  startCountdown() {
    this.countdownSubscription = timer(0, 1000).subscribe(() => {
      this.countdown--;
      if (this.countdown === 0) {
        this.logout();
      }
    });
  }

  logout() {
    this.countdownSubscription?.unsubscribe();
    this.logOutService.logout().subscribe(isSuccess => {
      if (isSuccess) {
        this.toastMessageService.showSuccessMessage('Logout Successful', 'You have been successfully logged out.', false, 'logout-toast');
        this.router.navigate(['login']);
      } else {
        this.toastMessageService.showErrorMessage('Logout failed', 'Error while logging out');
      }
    });
  }
}
