import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { Company } from '../model/company';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private apiUrl = AppConstants.API_URL;
  private api = AppConstants.API_URL;

  constructor(private http: HttpClient) { }

  getAll(): Observable<Company[]> {
    return this.http.get<Company[]>(`${this.apiUrl}api/v1/company/`);
  }

  get(id: number): Observable<Company> {
    return this.http.get<Company>(`${this.apiUrl}api/v1/company/${id}`);
  }

  save(company: Company): Observable<Company> {
    console.log(`Save Url ${this.apiUrl}`);
    return this.http.post<Company>(`${this.apiUrl}api/v1/company`, company);
  }

  update(id: number, company: Company): Observable<Company> {
    return this.http.put<Company>(`${this.apiUrl}api/v1/company/${id}`, company);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}api/v1/company/${id}`);
  }


  getCompanyByUserId(): Observable<Company> {
    const url = this.apiUrl+'api/v1/company';
    console.log(`Url is ${url}`);
    return this.http.get<Company>(`${url}`);
  }

  getInvitedUsers(): Observable<any> {
    const url = this.apiUrl + "api/v1/company/invitedUsers";
    console.log(`Url is ${url}`);
    return this.http.get<any>(`${url}`);
  }


  getCompanyUsers(): Observable<any> {
    const url = this.apiUrl + "api/v1/company/users";
    console.log(`Url is ${url}`);
    return this.http.get<any>(`${url}`);
  }

  sendInviteRequest(inviteRequest: any): Observable<any> {
    const apiUrl = `${this.api}api/v1/invite`;
    console.log(`url is ${apiUrl}`);
    return this.http.post<any>(apiUrl, inviteRequest);
  }

  deleteUser(id: number): Observable<any> {
    const url = `${this.api}users/${id}`;
    console.log(`url is ${url}`);
    return this.http.delete<any>(url);
  }


}
