import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ConfirmEventType,
  ConfirmationService,
  MessageService,
} from 'primeng/api';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { City } from 'src/app/commons/models/city';
import { PopulateDropdownService } from 'src/app/services/populate-dropdown.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CurrencyPipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { Accordion } from 'primeng/accordion';
import { ActivatedRoute, Router } from '@angular/router';

import { Toast } from 'primeng/toast';
import { LogoutService } from 'src/app/services/logout.service';
import { CreateSubscriptionOrder, PaymentGateway } from '../../extras/model/create-subscription-order';
import { RazorpayPayment } from '../../extras/model/razorpay-payment';
import { SubscriptionDurationSelected } from '../../extras/model/subscription-duration-selected';
import { SubscriptionPackageSelected } from '../../extras/model/subscription-package-selected';
import { SubscriptionPackagesView } from '../../extras/model/subscription-packages-view';
import { SubscriptionPromotionResponse } from '../../extras/model/subscription-promotion-response';
import { RazorpayService } from '../../extras/services/razorpay.service';
import { SubscriptionService } from '../../extras/services/subscription.service';
import { UserRegistrationService } from '../../extras/services/user-registration.service';
import { SHARED_MODULES } from '../../shared-imports';
import { SubscriptionPackageDetailsComponent } from '../../extras/subscription/subscription-package-details/subscription-package-details.component';
import { SubscriptionBillingDetailsComponent } from '../../extras/subscription/subscription-billing-details/subscription-billing-details.component';
import { SubscriptionEvaluateOrderComponent } from '../../extras/subscription/subscription-evaluate-order/subscription-evaluate-order.component';
import { SubscriptionPackageSelectionComponent } from '../../extras/subscription/subscription-package-selection/subscription-package-selection.component';

@Component({
  selector: 'app-subscription-management',
  templateUrl: './subscription-management.component.html',
  styleUrls: ['./subscription-management.component.css'],
  standalone: true,
  imports: [SHARED_MODULES, SubscriptionPackageDetailsComponent, SubscriptionBillingDetailsComponent, SubscriptionEvaluateOrderComponent,
    SubscriptionPackageSelectionComponent
  ],
})
export class SubscriptionManagementComponent implements OnInit, AfterViewInit {
  activeTabIndex = 0;

  activeIndexAccordion = 0;
  selectedPackage!: SubscriptionPackageSelected | null;
  enableSecondIndexAccordion = false;
  countries: any;
  ingredient: any;
  mainSelectedPackage!: SubscriptionPackageSelected | null;
  selectedDuration!: SubscriptionDurationSelected | null; // this object to get duration selected Monthly, Quartlerly ...
  isBillingDetailsSubmitted: any;
  paymentCycle!: any;
  packages!: SubscriptionPackagesView[];
  selectedCurrency: any;

  autoDebit = false;
  totalBillAmount: any;
  sgst: number = 0;
  cgst: number = 0;
  duration!: string;
  disablePackageDetailsAccordion = true;
  billingId: any;
  listPackagePricingPlans!: SubscriptionDurationSelected[]; // duration selected object
  promoCode: any;
  promoErrorMessage: any;
  promoAppliedMessage: any;
  promoObject!: SubscriptionPromotionResponse | null;
  grandTotal!: number;
  billingObject: any;
  public isPackageLoading = new BehaviorSubject<boolean>(false);

  constructor(
    private userRegistrationService: UserRegistrationService,
    private subscriptionService: SubscriptionService,
    private currencyPipe: CurrencyPipe,
    private confirmationService: ConfirmationService,
    private razorPayService: RazorpayService,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessagesService,
    private logOutService: LogoutService,
    private messageService: MessageService,
    private router: Router
  ) {}

  ngAfterViewInit(): void {
    // this.confirmLogout();
  }

  ngOnInit() {
    this.isPackageLoading.next(true);

    this.route.queryParams.subscribe((params) => {
      if (params['currency'] && this.isValidCurrency(params['currency'])) {
        console.log(
          `getAllSubscriptionPackages parameter is ${params['currency']}`
        );
        this.selectedCurrency = params['currency'];
      } else {
        this.selectedCurrency = null;
        if (params['currency']) {
          alert('Invalid currency parameter!');
        }
      }
    });
    this.fetchCountryAndSelectCurrency(this.selectedCurrency);
    this.getAllCountries();

    this.subscriptionService.currentCurrency.subscribe((currency) => {
      this.selectedCurrency = currency;
      console.log(
        `Selected Currency from Behaviour is ${this.selectedCurrency}`
      );
      
      this.resetPayNow();
    });
  }

  resetPayNow() {
    this.mainSelectedPackage = null;
    this.selectedDuration = null;
    this.billingId = null;
    this.promoObject = null;
  }

  hasGSTINOptions: any[] = [
    { name: 'Yes', key: true },
    { name: 'No', key: false },
  ];

  private async createForm() {
    this.getAllSubscriptionPackages(this.selectedCurrency);
  }

  confirmPackageSelection(event: any) {
    console.log(`Event ${JSON.stringify(event)}`);
    this.disablePackageDetailsAccordion = false;
    this.gotoAccordionIndex(1);
  }

  selectPackage(package1: any) {
    console.log(`Selected Package is ${JSON.stringify(package1)}`);
    this.selectedPackage = package1;
  }

  private getAllCountries() {
    this.userRegistrationService.getAllCountries().subscribe(
      (data) => {
        this.countries = data;
      },
      (error) => {},
      () => {}
    );
  }

  private getAllSubscriptionPackages(currencyChangedDropDown: string) {
    console.log(
      `getAllSubscriptionPackages this.selectedCurrency ${currencyChangedDropDown}`
    );
    if (currencyChangedDropDown) {
      this.selectedCurrency = currencyChangedDropDown;
    }
    this.subscriptionService
      .getAllSubscriptionPackages(this.selectedCurrency)
      .subscribe(
        (data: any) => {
          this.packages = data;
          console.log(`Subscription Packages are ${JSON.stringify(data)}`);
          this.isPackageLoading.next(false);
        },
        (error) => {
          console.log(
            `Subscription while loading Packages are ${JSON.stringify(error)}`
          );
        },
        () => {}
      );
  }

  gotoAccordionIndex(indexNumber: number) {
    this.activeIndexAccordion = indexNumber;
    // this.disablePackageDetailsAccordion = false;
  }

  onSubmit() {
    console.log(`onSubmit `);
  }

  handlePackageSelected(event: any) {
    console.log(
      `Selected Package from main subscription is ${JSON.stringify(event)}`
    );
    this.mainSelectedPackage = event;
    this.getSubscriptionDetailsForPricing();
  }

  handlePaymentCycleSelected(event: SubscriptionDurationSelected) {
    console.log(
      `Selected Payment Cycle from app-subscription-package-details subscription is ${JSON.stringify(
        event
      )}`
    );
    this.selectedDuration = event;
    this.grandTotal = event.grandTotal;
  }

  handleBillingDetailsSubmission(event: any): void {
    console.log(
      `Bill Details Submitted? from main subscription is ${JSON.stringify(
        event
      )}`
    );
    this.isBillingDetailsSubmitted = event;
    this.gotoAccordionIndex(3);
  }

  handleBillingDetailsSubmitObject(event: any) {
    console.log(`Billing Details Submit Objet is ${JSON.stringify(event)}`);
    this.billingObject = event;
    this.billingId = event.id;
  }

  handleCurrencySelected(event: any) {
    this.disablePackageDetailsAccordion = true;
    this.selectedDuration = null;
    this.mainSelectedPackage = null;
    this.isBillingDetailsSubmitted = false;
    console.log(
      `Selected Currency is Changed from main subscription is ${JSON.stringify(
        event
      )}`
    );
    this.selectedCurrency = event;
    this.subscriptionService.changeCurrencyBehaviorSubjectb(
      this.selectedCurrency
    );
    this.getAllSubscriptionPackages(event);
    // this.calculateBilling();
  }

  handleBillingId(event: any) {
    console.log(`handleBillingId event ${JSON.stringify(event)}`);
    this.billingId = event;
  }

  handleAutoDebit(event: any) {
    console.log(
      `Selected Auto Debit Option is from main subscription is ${JSON.stringify(
        event
      )}`
    );
    this.autoDebit = event;
  }

  async onPayNow() {
    console.log(
      `Selected Package is ${JSON.stringify(this.mainSelectedPackage)}`
    );
    console.log(`Selected Currency is ${this.selectedCurrency}`);
    console.log(
      `Selected Payment Cycle is ${JSON.stringify(this.selectedDuration)}`
    );
    console.log(`Selected AutoDebit option is ${this.autoDebit}`);
    console.log(`Selected billingId is ${this.billingId}`);
    console.log(`Selected Promo Code ${JSON.stringify(this.promoObject)}`);
    if (
      this.mainSelectedPackage !== null &&
      this.selectedDuration !== null &&
      this.billingId !== null  
    ) {
     
    
      const order = new CreateSubscriptionOrder();
      if(this.selectedCurrency === 'USD'){
       order.paymentGateway = PaymentGateway[PaymentGateway.STRIPE];
      }
      else if(this.selectedCurrency === 'INR'){
       order.paymentGateway =PaymentGateway[PaymentGateway.PHONE_PE];
      }

      //  order.currency = this.selectedCurrency;
      //order.durationUnit = this.selectedDuration;
      order.autoDebit = this.autoDebit;
      order.billingId = this.billingId;
      order.currencyId = this.mainSelectedPackage?.currencyId ?? 0;
      order.subscriptionId = this.mainSelectedPackage?.subscriptionId ?? 0;
      order.subscriptionPricingsId =
        this.selectedDuration.subscriptionPricingsId;
      order.billingCompanyName = this.billingObject.companyName;
      order.billingAddress = this.billingObject.address;
      order.billingCity = this.billingObject.city;
      order.billingState = this.billingObject.state;
      order.billingPincode = this.billingObject.pincode;
      order.billingCountry = this.billingObject.country;
      order.billingGst = this.billingObject.gstin;
      order.billingPhone = this.billingObject.phone;

      if (this.promoObject) {
        order.hasPromoApplied = true;
        order.promoCodeId = this.promoObject.promotionId;
        order.promoCode = this.promoCode;
      } else {
        order.hasPromoApplied = false;
        order.promoCodeId = -1;
        order.promoCode = '';
      }

      // order.subscriptionTypeId = this.mainSelectedPackage.id;

     
      let payResult: Promise<any>; // Declare the payResult variable outside the if-else block

      this.subscriptionService.createOrder(order).subscribe(
        (response: any) => {
        
          if(response.paymentGateway === PaymentGateway[PaymentGateway.PHONE_PE]){
            console.log(`CreateSubscriptionOrder is ${JSON.stringify(order)}`);
            window.location.href =  response.url ;
          }else  if(response.paymentGateway === PaymentGateway[PaymentGateway.STRIPE]){
            console.log(`CreateSubscriptionOrder is ${JSON.stringify(order)}`);
            window.location.href =  response.url ;
          }
          if (
            response.notes1 === 'TRIAL' &&
            response.notes2 === '9999' &&
            response.amount === 0
          ) {
            this.toastMessageService.showSuccessMessage(
              'Trail Successful',
              'Your trial has been registered successfully.'
            );
            this.confirmLogout();
          } else {
            console.log(
              `CreateSubscriptionOrder Response is ${JSON.stringify(response)}`
            );
            console.log(
              `this.billingObject ${JSON.stringify(this.billingObject)}`
            );
            // response.razorpayBillCompanyName = this.billingObject.;
           /*  payResult = this.razorPayService
              .pay(response)
              .then((response) => {
                // This will run if the promise is resolved (payment successful)
                console.log('Payment was successful. Response:', response);
                this.toastMessageService.showSuccessMessage(
                  'Payment Successful',
                  'Your payment has been processed successfully.'
                );
                this.confirmLogout();
                // Put your success callback code here
              })
              .catch((error) => {
                // This will run if the promise is rejected (payment failed)
                console.log('Payment failed. Error:', error);
                this.toastMessageService.showErrorMessage(
                  'Payment Failed',
                  'An error occurred while processing your payment. Please try again.'
                );
                // Put your failure callback code here
              }); */
          }
        },
        (error: HttpErrorResponse) => {
          console.error(
            `CreateSubscriptionOrder Error is ${JSON.stringify(error)}`
          );
        },
        () => {
          // Completion callback, if needed
          console.log(`payResult ${JSON.stringify(payResult)}`);
          if (!payResult && order.subscriptionId == 9999) {
            this.router.navigate(['/login']);
          }
        }
      );
    } else {
     this.toastMessageService.showErrorMessage('Incomplete','Please check all the fields.');
    }

    //
  }

  onApplyPromoCode() {
    const currencyId = this.mainSelectedPackage?.currencyId ?? 0;
    const subscriptionId = this.mainSelectedPackage?.subscriptionId ?? 0;
    const subscriptionPricingsId =
      this.selectedDuration?.subscriptionPricingsId;
    const isoCurrency = this.mainSelectedPackage?.isoCurrencyName;

    console.log(`promocode is ${this.promoCode}`);
    console.log(
      `Selected Package is ${JSON.stringify(this.mainSelectedPackage)}`
    );
    console.log(`Selected Currency is ${this.selectedCurrency}`);
    console.log(
      `Selected Payment Cycle is ${JSON.stringify(this.selectedDuration)}`
    );
    console.log(`Selected AutoDebit option is ${this.autoDebit}`); //handleBillingId
    console.log(`Selected billingId is ${this.billingId}`); //handleBillingId
    this.promoAppliedMessage = '';
    this.promoErrorMessage = '';
    if (subscriptionPricingsId) {
      this.subscriptionService
        .setApplyPromocode(
          this.promoCode,
          subscriptionId,
          subscriptionPricingsId
        )
        .subscribe(
          (response: SubscriptionPromotionResponse) => {
            console.log(`Promotion reponse is ${JSON.stringify(response)}`);
            // const savings = this.currencyPipe.transform(100, 'USD', 'symbol', '1.0-0'); // Change 'USD' to your desired currency code if different
            const savings = this.currencyPipe.transform(
              response.discountAmount,
              isoCurrency,
              'symbol',
              '1.2-2'
            ); // Change 'USD' to your desired currency code if different
            this.promoAppliedMessage = `Congratulations! You've just saved ${savings}.`;
            this.promoObject = response;
            this.grandTotal = response.grandTotal;
          },
          (error: HttpErrorResponse) => {
            const message = error.error;
            console.error(
              `Promotion error response is ${JSON.stringify(message)}`
            );
            this.promoErrorMessage = message;
            this.grandTotal = this.selectedDuration?.grandTotal ?? 0;
          },
          () => {}
        );
    }

    // const order = new CreateSubscriptionOrder();
  }

  private getSubscriptionDetailsForPricing() {
    console.log(`this.selectedCurrency is ${this.selectedCurrency}`);
    console.log(
      ` this.mainSelectedPackage is ${JSON.stringify(this.mainSelectedPackage)}`
    );
    this.subscriptionService
      .getSubscriptionDetails(
        this.mainSelectedPackage?.currencyId ?? 0,
        this.mainSelectedPackage?.subscriptionId ?? 0
      )
      .subscribe({
        next: (data: any) => {
          console.log(`calculateBilling ${JSON.stringify(data)}`);
          console.log(`calculateBilling ${JSON.stringify(data.length)}`);
          // this.disablePackageDetailsAccordion = false;
          this.listPackagePricingPlans = data;
        },
        error: (error) => {
          console.log('Error:', error);
        },
        complete: () => {
          console.log('Complete');
        },
      });
    /*  if (this.selectedCurrency === 'INR') {
       this.totalBillAmount = this.mainSelectedPackage?.subscriptionPrice.offerPrice;
       console.log(`offerPrice is ${this.totalBillAmount}`);
       this.sgst = this.totalBillAmount * 0.09;
       this.cgst = this.totalBillAmount * 0.09;
       this.totalBillAmount = this.totalBillAmount + this.sgst + this.cgst;
       let formattedTotal = this.currencyPipe.transform(this.totalBillAmount, 'INR', 'symbol', '1.2-2', 'en');
       console.log(`formattedTotal is ${formattedTotal}`);
       // this.duration = this.getDurationInMonths(this.mainSelectedPackage?.subscriptionPrices[0].durationUnit);
     } else {
       this.totalBillAmount = this.mainSelectedPackage?.subscriptionPrice.offerPrice;
     }
     this.duration = this.getDurationInMonths(this.mainSelectedPackage?.subscriptionPrice.durationUnit); */
  }

  private getDurationInMonths(durationUnit: string) {
    let duration: string;
    switch (durationUnit) {
      case 'MONTHLY':
        duration = '1 Month';
        break;
      case 'QUARTERLY':
        duration = '3 Months';
        break;
      case 'HALF_YEARLY':
        duration = '6 Months';
        break;
      case 'YEARLY':
        duration = '12 Months';
        break;
      default:
        duration = 'Invalid duration unit';
    }
    return duration;
  }

  fetchCountryAndSelectCurrency(changeCurrencyFromDropDown?: string) {
    console.log(
      `getAllSubscriptionPackages  ££ Response is ${changeCurrencyFromDropDown}`
    );
    if (changeCurrencyFromDropDown) {
      this.createForm();
      this.selectedCurrency = changeCurrencyFromDropDown;
      this.subscriptionService.changeCurrencyBehaviorSubjectb(
        this.selectedCurrency
      );
    } else
      this.subscriptionService.getUserCountry().subscribe(
        (data: any) => {
          console.log(
            `fetchCountryAndSelectCurrency Response is ${JSON.stringify(data)}`
          );

          const iso_code = data.country.iso_code;
          let countryName = data.country.names.en;
          console.log(`ISO iso_code Response is ${iso_code}`);
          console.log(`ISO COUNTRY Response is ${countryName}`);
          if (iso_code === 'IN') {
            console.log(`IN IF ISO COUNTRY Response is ${countryName}`);
            this.selectedCurrency = 'INR';
          } else {
            this.selectedCurrency = 'USD';
          }
          this.createForm();
        },
        (error: HttpErrorResponse) => {
          console.error(
            `fetchCountryAndSelectCurrency HttpErrorResponse is ${JSON.stringify(
              error
            )}`
          );
        },
        () => {}
      );
  }

  handleTabOpen(event: any) {
    console.log(`Tab opened: , ${JSON.stringify(event)}`);
    // Insert your code to handle the open event here
  }

  handleTabClose(event: any) {
    console.log(`Tab closed: ,${JSON.stringify(event)} `);
    // Insert your code to handle the close event here
  }

  isValidCurrency(currency: string) {
    // Add your own validation logic here
    // For example, you can check if 'currency' is in a list of valid currency codes
    const validCurrencies = ['USD', 'INR']; // replace with your actual valid currencies
    return validCurrencies.includes(currency);
  }

  confirmLogout() {
    this.confirmationService.confirm({
      message:
        "Congratulations on your purchase! In order to activate your new benefits, we will need to refresh your session. This means you'll be logged out shortly. But don't worry, just log back in to start enjoying your new features. Ready to proceed?",
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
    });
  }

  logoutAndRedirectToLogin() {
    // this.logOutService.logout();
    this.logOutService.logout().subscribe(isSuccess => {
      if (isSuccess) {
        // Do something if logout is successful
        this.toastMessageService.showSuccessMessage('Logout Successful', 'You have been successfully logged out.',false,'logout-toast');
        this.router.navigate(['login']);;
      } else {
        // Do something if logout failed
        this.toastMessageService.showErrorMessage('Logout failed', 'Error while log out ');
      }
    });
  }

  /*  packages: any[] = [
     {
       name: 'Trial 7 days',
       price: '0',
       features: ['Feature 1']
     },
     {
       name: 'Silver',
       price: '10',
       features: ['Feature 1', 'Feature 2', 'Feature 3']
     },
     {
       name: 'Gold',
       price: '20',
       features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4', 'Feature 5']
     },
     {
       name: 'Platinum',
       price: '30',
       features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4', 'Feature 5', 'Feature 6', 'Feature 7']
     }
   ]; */
}
