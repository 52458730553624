import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  AbstractControl,
} from '@angular/forms';
import { RootService } from '../services/root-service.service';
import { SubscriptionDataModel } from '../model/subscription-data-model';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConfirmEventType,
  ConfirmationService,
  MenuItem,
  MessageService,
} from 'primeng/api';
import { Observable, of } from 'rxjs';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-master-subscription',
  templateUrl: './master-subscription.component.html',
  styleUrls: ['./master-subscription.component.css'],
  standalone: true,
  imports: [SHARED_MODULES],
})
export class MasterSubscriptionComponent implements OnInit, AfterViewInit {
  subscriptionForm!: FormGroup;
  blockedPanel: boolean = false;

  durationList!: any;
  currencyList!: any;
  @Input() showBreadcrumb: boolean = true;
  protected items!: MenuItem[];
  // subscriptionId: number = 10045;
  subscriptionId!: number;
  home!: MenuItem;

  constructor(
    private fb: FormBuilder,
    private rootService: RootService,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private toastMessageService: ToastMessagesService,
    private router: Router
  ) {
    this.initDropDown();
    this.prePopulateData();
  }
  
  ngAfterViewInit(): void {
    if (!this.subscriptionId) {
      this.addPricingControl();
      this.addFeatureControl();
    }
  }

  ngOnInit(): void {
    this.populateBreadCrumb();
    this.home = { icon: 'pi pi-home', routerLink: '/dashboard' };
    this.subscriptionForm = this.fb.group({
      subscription: this.fb.group({
        id: [],
        sortOrder: [],
        name: [''],
      }),
      subscriptionType: this.fb.group({
        id: [null],
        name: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(32),
          ],
        ],
        description: [''],
        header: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(32),
          ],
        ],
        subHeader: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(64),
          ],
        ],
        gracePeriod: [null, Validators.required],
        renewalReminderDays: [null, Validators.required],
        // Define subscriptionType fields here
      }),

      subscriptionLimit: this.fb.group({
        id: [null],
        numberOfUsers: [0, Validators.required],
        numberOfPackingList: [0, Validators.required],
        numberOfBuyers: [0, Validators.required],
        numberOfSellers: [0, Validators.required],
        numberOfProducts: [0, Validators.required],
        numberOfSites: [0, Validators.required],
        numberOfBL: [0, Validators.required],
        numberOfPhotos: [0, Validators.required],
        retentionOfPhotos: [0, Validators.required],
        numberOfContainers: [0, Validators.required],
        // Define subscriptionLimit fields here
      }),

      subscriptionPricings: this.fb.array([]), // Initialize as empty FormArray
      subscriptionTitlePrices: this.fb.array([]),
      subscriptionFeatures: this.fb.array([]),
    });

    // Call methods to populate FormArrays if editing an existing subscription
    // Initialize with one combined control
    this.synchronizeValues();
    this. initMessage();
  }

  get subscriptionPricings(): FormArray {
    return this.subscriptionForm.get('subscriptionPricings') as FormArray;
  }

  get subscriptionTitlePrices(): FormArray {
    return this.subscriptionForm.get('subscriptionTitlePrices') as FormArray;
  }

  get subscriptionFeatures(): FormArray {
    return this.subscriptionForm.get('subscriptionFeatures') as FormArray;
  }

  // Helper getter to simplify the template
  get combinedPricingControls() {
    return Array.from({ length: this.subscriptionPricings.length });
  }

  addPricingControl(): void {
    this.subscriptionPricings.push(
      this.fb.group({
        id: [null],
        price: ['', Validators.required],
        offerPrice: ['', Validators.required],
        duration: [null, Validators.required],
        currency: [null, Validators.required],
        discountPercentage: [],
        discountAmount: [],
        amountSaved: [],
        totalGst: [],
        cgst: [],
        sgst: [],
        igst: [],
        grandTotal: [],
        message: ['', Validators.required],
      })
    );
    this.subscriptionTitlePrices.push(
      this.fb.group({
        id: [],
        price: [],
        offerPrice: [],
        currency: [],
        subscription: [],
        test: [],
      })
    );
    const newIndex = this.subscriptionPricings.length - 1;
    this.addListenersToPricingControl(newIndex);
  }

  removePricingControl(index: number): void {
    if (this.subscriptionPricings.length > 1) {
      this.subscriptionPricings.removeAt(index);
    }
  }

  addFeatureControl(): void {
    this.subscriptionFeatures.push(
      this.fb.group({
        id: [],
        name: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(32),
          ],
        ],
        // ... other feature fields if required ...
      })
    );
  }

  removeFeatureControl(index: number): void {
    if (this.subscriptionFeatures.length > 1) {
      this.subscriptionFeatures.removeAt(index);
    }
  }

  initDropDown() {
    this.rootService.getCurrencies().subscribe(
      (data: any) => {
        this.currencyList = data;
        console.log(`currencies are ${JSON.stringify(data)}`);
      },
      (error: any) => {
        console.error(`currencies error are ${JSON.stringify(error)}`);
      },
      () => {}
    );

    this.rootService.getDuration().subscribe(
      (data: any) => {
        this.durationList = data.filter((item: any) => item.id !== 9999);

        // this.durationList = data;
        console.log(`duration are ${JSON.stringify(data)}`);
      },
      (error: any) => {
        console.error(`duration error are ${JSON.stringify(error)}`);
      },
      () => {}
    );
  }

  prePopulateData() {
    this.route.paramMap.subscribe((params) => { 
      let id = params.get('id');
      if (id) {
        this.blockedPanel = true;
        this.subscriptionId = parseInt(id);
        if (this.subscriptionId) {
          this.rootService.getSubscriptionData(this.subscriptionId).subscribe(
            (response: SubscriptionDataModel) => {
              console.log(`Subscription Data is ${JSON.stringify(response)}`);
              this.populateForm(response);
              this.blockedPanel = false;
            },
            (error: HttpErrorResponse) => {
              console.error(
                `Error in getAllSubscriptions is ${JSON.stringify(error)}`
              );
              if (error.status === 403) {
                // Handle 403 Forbidden error
                console.log('Access Denied. You do not have permission to access this resource.');
                this.router.navigate(['error/forbidden']);
                // You can also redirect the user, show a message, etc.
              } else {
                // Handle other types of errors
                console.log(`An error occurred: ${error.message}`);
              }
            },
            () => {
              this.blockedPanel = false;
            }
          );
        }
      }else{
        this.rootService.getSubscriptionData(0).subscribe(
          (response: SubscriptionDataModel) => {
            console.log(`Subscription Data is ${JSON.stringify(response)}`);
            this.blockedPanel = false;
          },
          (error: HttpErrorResponse) => {
            console.error(
              `Error in getAllSubscriptions is ${JSON.stringify(error)}`
            );
            if (error.status === 403) {
              // Handle 403 Forbidden error
              console.log('Access Denied. You do not have permission to access this resource.');
              this.router.navigate(['error/forbidden']);
              // You can also redirect the user, show a message, etc.
            } else {
              // Handle other types of errors
              console.log(`An error occurred: ${error.message}`);
            }
          },
          () => {
            this.blockedPanel = false;
          }
        );
      }
    });
  }

  populateForm(data: SubscriptionDataModel): void {
    console.log(
      `data.subscriptionType ${JSON.stringify(data.subscriptionFeatures)}`
    );
    this.subscriptionForm.patchValue({
      subscription: data.subscription,
      subscriptionType: data.subscription?.type,
      subscriptionLimit: data.subscriptionLimit,
      //   subscriptionTitlePrices: data.subscriptionTitlePrices,
      // ...other simple fields...
    });

    this.setFormArrayPrice(
      this.subscriptionPricings,
      data.subscriptionPricings || []
    );
    // this.setFormArray(this.subscriptionTitlePrices, data.subscriptionTitlePrices);
    this.setFormArrayTitlePrices(
      this.subscriptionTitlePrices,
      data.subscriptionPricings || [],
      data.subscription?.id ?? 0
    );
    this.setFormArrayFeatures(
      this.subscriptionFeatures,
      data.subscriptionFeatures || []
    );
  }

  setFormArrayPrice(formArray: FormArray, items: any[]): void {
    items.forEach((item) => {
      formArray.push(
        this.fb.group({
          id: [item.id],
          price: [item.price, Validators.required],
          offerPrice: [item.offerPrice, Validators.required],
          duration: [item.duration, Validators.required],
          currency: [item.currency, Validators.required],
          discountPercentage: [
            { value: item.discountPercentage, disabled: false },
          ],
          discountAmount: [{ value: item.discountAmount, disabled: false }],
          amountSaved: [{ value: item.amountSaved, disabled: false }],
          totalGst: [{ value: item.totalGst, disabled: false }],
          cgst: [{ value: item.cgst, disabled: false }],
          sgst: [{ value: item.sgst, disabled: false }],
          igst: [{ value: item.igst, disabled: false }],
          grandTotal: [{ value: item.grandTotal, disabled: false }],
          message: [item.message, Validators.required],
        })
      );
    });
  }

  setFormArrayFeatures(formArray: FormArray, items: any[]): void {
    items.forEach((item) => {
      formArray.push(
        this.fb.group({
          id: [item.id],
          name: [
            item.name,
            [
              Validators.required,
              Validators.minLength(3),
              Validators.maxLength(32),
            ],
          ],
        })
      );
    });
  }
  setFormArrayTitlePrices(
    formArray: FormArray,
    items: any[],
    subscriptionId: number
  ): void {
    items.forEach((item) => {
      console.log(`>>>> ${JSON.stringify(item)}`);
      formArray.push(
        this.fb.group({
          id: { subscriptionId: subscriptionId, currencyId: item.currency.id },
          price: item.price,
          offerPrice: item.offerPrice,
          currency: item.currency,
          subscription: item.subscription,
        })
      );
    });
  }

  /* markAllAsTouched(control: AbstractControl) {
    if (control.hasOwnProperty('controls')) {
      const ctrl = control as FormGroup;
      for (const inner in ctrl.controls) {
        this.markAllAsTouched(ctrl.controls[inner]);
      }
    } else {
      control.markAsTouched();
    }
  } */
  markAllAsTouched(control: AbstractControl) {
    if (control instanceof FormGroup) {
      Object.values(control.controls).forEach(c => this.markAllAsTouched(c));
    } else if (control instanceof FormArray) {
      control.controls.forEach(c => this.markAllAsTouched(c));
    } else {
      control.markAsTouched();
    }
  }
  
  
  
  
  onDelete() {
    if (this.subscriptionId) {
      this.rootService.deleteSubscription(this.subscriptionId).subscribe(
        (response: any) => {
          console.log(`response is ${JSON.stringify(response)}`);
          // alert(`Deleted Successfully`);
          this.toastMessageService.showInfoMessage('Subscription Package Deleted','The subscription package has been successfully deleted', false);
          this.router.navigate(['/root/master-subscriptions']);

        },
        (error: any) => {
          console.log(`error is ${JSON.stringify(error)}`);
          this.toastMessageService.showErrorMessage('Subscription Package Deletion Failed','The new subscription package failed to delete', false);

        },
        () => {}
      );
    }
    // Call to backend service to save the subscription
  }

  

  synchronizeValues() {
    this.subscriptionPricings.valueChanges.subscribe((values) => {
      // console.log(`VALUES OF SUBSCRIPTION PRICINGS are ${JSON.stringify(values)}`);
      values.forEach((pricing: any, index: any) => {
        console.log(`pricing -> ${JSON.stringify(pricing)}`);
        const titlePriceForm = this.subscriptionTitlePrices.at(index);
        if (titlePriceForm) {
          // Check if data exists
          if (pricing.currency) {
            this.calculateDiscounts(index);
            titlePriceForm.patchValue(
              {
                price: pricing.price,
                offerPrice: pricing.offerPrice,
                currency: pricing.currency,
                id: {
                  subscriptionId: this.subscriptionId,
                  currencyId: pricing.currency.id,
                },
                subscription: pricing.subscription,
              },
              { emitEvent: false }
            );
          }
         
          console.log(
            `After Patching: ${JSON.stringify(titlePriceForm.value)}`
          );
        }
      });
    });
  }

  calculateDiscounts(index: number): void {
    const pricingControl = (
      this.subscriptionForm.get('subscriptionPricings') as FormArray
    ).at(index);
    const price = pricingControl.get('price')?.value || 0;
    const offerPrice = pricingControl.get('offerPrice')?.value || 0;
    const currency = pricingControl.get('currency')?.value;

    const amountSaved = parseFloat((price - offerPrice).toFixed(2));
    const discountPercentage =
      price !== 0 ? parseFloat(((amountSaved / price) * 100).toFixed(2)) : 0;

    if (currency.isoCurrencyName === 'INR') {
      const gstRate = 0.18; // 18% GST
      const totalGst = parseFloat((offerPrice * gstRate).toFixed(2));
      const cgst = parseFloat((totalGst / 2).toFixed(2)); // 50% of total GST
      const sgst = parseFloat((totalGst / 2).toFixed(2)); // 50% of total GST
      const igst = parseFloat(totalGst.toFixed(2)); // 100% of total GST

      const grandTotal = parseFloat((offerPrice + totalGst).toFixed(2));

      pricingControl.get('totalGst')?.setValue(totalGst, { emitEvent: false });
      pricingControl.get('cgst')?.setValue(cgst, { emitEvent: false });
      pricingControl.get('sgst')?.setValue(sgst, { emitEvent: false });
      pricingControl.get('igst')?.setValue(igst, { emitEvent: false });
      pricingControl
        .get('grandTotal')
        ?.setValue(grandTotal, { emitEvent: false });
    } else {
      pricingControl.get('totalGst')?.setValue(0, { emitEvent: false });
      pricingControl.get('cgst')?.setValue(0, { emitEvent: false });
      pricingControl.get('sgst')?.setValue(0, { emitEvent: false });
      pricingControl.get('igst')?.setValue(0, { emitEvent: false });
      pricingControl
        .get('grandTotal')
        ?.setValue(offerPrice, { emitEvent: false });
    }

    pricingControl
      .get('amountSaved')
      ?.setValue(this.getDisplayValue(amountSaved), { emitEvent: false });
    pricingControl
      .get('discountPercentage')
      ?.setValue(discountPercentage, { emitEvent: false });
    pricingControl
      .get('discountAmount')
      ?.setValue(amountSaved, { emitEvent: false });
  }

  confirmDelete() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
        this.onDelete();
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
             
            break;
          case ConfirmEventType.CANCEL:
            
            break;
        }
      },
    });
  }

  populateBreadCrumb() {
    this.items = [
      { label: 'View All',routerLink:'/root/master-subscriptions' },
      { label: 'Root' },
    ];
  }

  getDisplayValue(value: any): string {
    if (value && !isNaN(value)) {
      return value.toFixed(2);
    }
    return '0.00';
  }
  
  onSubmit() {
    console.log(this.subscriptionForm.value);
     this.markAllAsTouched(this.subscriptionForm);
    if (this.subscriptionForm.invalid) {
      this.toastMessageService.showWarningMessage('Incomplete Form Submission','Please ensure all required fields are filled out.')
      const invalidFields: string[] = [];
      const controls = this.subscriptionForm.controls;

      Object.keys(controls).forEach((key) => {
        if (controls[key].invalid) {
          console.log(`control's key ${controls[key]}`);
          invalidFields.push(key);
        }
      });

      // console.log('Invalid Fields:', invalidFields);
      // Handle the invalid form case (e.g., show a message to the user)
    } 
    if (this.subscriptionForm.valid) {
      // Process the form data
      console.log(this.subscriptionForm.value);
      this.rootService.postData(this.subscriptionForm.value).subscribe(
        (response: any) => {
          console.log(`response is ${JSON.stringify(response)}`);
          this.toastMessageService.showInfoMessage('Subscription Package Update','The new subscription package has been successfully saved or updated', false);
          this.router.navigate(['/root/master-subscriptions']);
        },
        (error: any) => {
          console.log(`error is ${JSON.stringify(error)}`);
          this.toastMessageService.showErrorMessage('Subscription Package Update Failed','The new subscription package failed to save or update', false);
        },
        () => {}
      );
      // Call to backend service to save the subscription
    } 
  }

  createTrial(){
    this.rootService.createTrialPackage().subscribe(
      (response: any)=>{
        console.log(`response is ${JSON.stringify(response)}`);
      },
      (error: any)=>{
        console.log(`error is ${JSON.stringify(error)}`)
      },
      ()=>{},
    )
  }

  setupListenersForExistingControls() {
    this.subscriptionForm.get('subscriptionType.name')?.valueChanges.subscribe(() => {
      this.updatePricingMessages();
    });
  
    this.subscriptionPricings.controls.forEach((_, index) => {
      this.addListenersToPricingControl(index);
    });
  }

  addListenersToPricingControl(index: number) {
    const pricingControl = this.subscriptionPricings.at(index);
    
    pricingControl.get('currency')?.valueChanges.subscribe(() => {
      this.updateSinglePricingMessage(index);
    });
    pricingControl.get('duration')?.valueChanges.subscribe(() => {
      this.updateSinglePricingMessage(index);
    });
  }

  initMessage(){
    this.subscriptionForm?.get('subscriptionType.name')?.valueChanges.subscribe(value => {
      this.updatePricingMessages();
    });
  
    this.subscriptionPricings.controls.forEach((control, index) => {
      control?.get('currency.isoCurrencyName')?.valueChanges.subscribe(() => {
        this.updateSinglePricingMessage(index);
      });
      control?.get('duration.period')?.valueChanges.subscribe(() => {
        this.updateSinglePricingMessage(index);
      });
    });
  }

  updatePricingMessages() {
    this.subscriptionPricings.controls.forEach((control, index) => {
      this.updateSinglePricingMessage(index);
    });
  }
  
  updateSinglePricingMessage(index: number) {
    const subscriptionType = this.subscriptionForm.get('subscriptionType.name')?.value;
  
    // Access the nested values correctly
    const currency = this.subscriptionPricings.at(index).get('currency')?.value?.isoCurrencyName;
    const duration = this.subscriptionPricings.at(index).get('duration')?.value?.period;
  
    let message = `${subscriptionType}`;
    message += currency ? ` - ${currency}` : '';
    message += duration ? ` - ${duration}` : '';
  
    this.subscriptionPricings.at(index).get('message')?.setValue(message);
  }
  
  

}

