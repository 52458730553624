import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Observable } from 'rxjs';
import { DeactivateComponent } from '../commons/interfaces/deactivate-component';
import { BuyerComponent } from '../modules/buyer/buyer/buyer.component';

@Injectable({
  providedIn: 'root'
})
export class DeactivatesGuard implements CanDeactivate<BuyerComponent> {
  constructor(private confirmationService: ConfirmationService) { }

  canDeactivate(component: BuyerComponent): Observable<boolean> | Promise<boolean> | boolean {
    console.log(`component.isSubmitting ${component.isSubmitting}`);
    console.log(` component.myForm.dirty ${ component.myForm.dirty}`);
    if (component.isSubmitting && component.myForm.dirty) { // check if component has unsaved changes
      return true;
    }
    if (component.myForm.dirty) { // check if component has unsaved changes
      return new Promise((resolve) => {
        this.confirmationService.confirm({
          header: 'Warning',
          message: "Changes to the form have not been saved. Do you want to continue without saving?",
          accept: () => {
            resolve(true);
          },
          reject: () => {
            resolve(false);
          }
        });
      });
    } else {
      return true; // allow deactivation if no unsaved changes
    }
  }
}
