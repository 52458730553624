import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContainerComponent } from './container/container.component';
import { ContainerListComponent } from './container-list/container-list.component';
import { MessagesModule } from 'primeng/messages';
import { SidebarModule } from 'primeng/sidebar';
import { ProductModule } from '../product/product.module';
import { LoadingSiteModule } from '../loading-site/loading-site.module';
import { SellerModule } from '../seller/seller.module';
import { SpeedDialModule } from 'primeng/speeddial';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ContainerPhotosComponent } from './container-photos/container-photos.component';
import { PhotoGalleryModule } from '@twogate/ngx-photo-gallery';
import { ContainerSummaryComponent } from './container-summary/container-summary.component';
import { ContainerPackingListComponent } from './container-packing-list/container-packing-list.component';
import { DividerModule } from 'primeng/divider';
import { SkeletonModule } from 'primeng/skeleton';
import { ProgressSpinner, ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { BlockUIModule } from 'primeng/blockui';
import { FileUploadModule } from 'primeng/fileupload';
import { TabViewModule } from 'primeng/tabview';
import { GalleriaModule } from 'primeng/galleria';

import { TallySheetFourComponent } from '../tally-sheet/tally-sheet-four/tally-sheet-four.component';

@NgModule({
  declarations: [
    // ContainerComponent,
    // ContainerListComponent,
    // ContainerPhotosComponent,
    // ContainerSummaryComponent,
    // ContainerPackingListComponent,
     
  ],
  imports: [
    // CommonModule,
    // CommonModule,
    // CardModule,
    // ButtonModule,
    // BreadcrumbModule,
    // ReactiveFormsModule,
    // DropdownModule,
    // InputTextareaModule,
    // InputMaskModule,
    // InputTextModule,
    // ToastModule,
    // ConfirmDialogModule,
    // TableModule,
    // PaginatorModule,
    // MultiSelectModule,
    // MessagesModule,
    // SidebarModule,
    // ProductModule,
    // LoadingSiteModule,
    // SellerModule,
    // SpeedDialModule,
    // SidebarModule,
    // AutoCompleteModule,
    // PhotoGalleryModule.forRoot({ defaultOptions: { showHideOpacity: true, arrowEl: true,  indexIndicatorSep: '/'} }),
    // DividerModule,
    // SkeletonModule,
    // ProgressSpinnerModule,
    // DialogModule,
    // BlockUIModule,
    // FileUploadModule,
    // TabViewModule,
    // GalleriaModule,
    // TallySheetFourComponent,

    ContainerComponent,
    ContainerListComponent,
    ContainerPhotosComponent,
    ContainerSummaryComponent,
    ContainerPackingListComponent,
  ],
  exports: [ContainerComponent, ContainerListComponent, ContainerPhotosComponent, ContainerPhotosComponent, ContainerPackingListComponent, ContainerSummaryComponent],
  providers: [ConfirmationService, MessageService]
})
export class ContainerModule { }
