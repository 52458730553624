import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Observable } from 'rxjs';
import { ProductComponent } from '../product/product.component';

@Injectable({
  providedIn: 'root'
})
export class DeactivatesProductGuard implements CanDeactivate<ProductComponent> {

  constructor(private confirmationService: ConfirmationService) { }

  canDeactivate(component: ProductComponent): Observable<boolean> | Promise<boolean> | boolean {
    if (component.isSubmitting && component.productForm.dirty) { // check if component has unsaved changes
      return true;
    }
    if (component.productForm.dirty) { // check if component has unsaved changes
      return new Promise((resolve) => {
        this.confirmationService.confirm({
          header: 'Warning',
          message: "Changes to the form have not been saved. Do you want to continue without saving?",
          accept: () => {
            resolve(true);
          },
          reject: () => {
            resolve(false);
          }
        });
      });
    } else {
      return true; // allow deactivation if no unsaved changes
    }
  }
}