<router-outlet></router-outlet><!--  -->

<!-- <p-toast></p-toast> -->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="pb-3">
    <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
</div>

<div class="card">
    <p-speedDial [model]="measurementSpeedDialItems" direction="up-left" type="quarter-circle" [radius]="120" class="ml-5 my-speed-dial"
    [rotateAnimation]="true"></p-speedDial>
    <div style="width: 90vw">
        <!-- [tableStyle]="{'min-width': '50rem'}" -->
        <p-table #dt [tableStyle]="{'min-width': '40rem'}" [lazy]="true" [value]="measurementSummarys"
            (onLazyLoad)="sortSummary($event)" [rowHover]="true" [customSort]="true" sortMode="multiple"
            dataKey="containerSummaryId" [globalFilterFields]="['name','contactPerson','city','gst']"
            [showCurrentPageReport]="true" [rows]="rows" [rowsPerPageOptions]="rowsPerPageOptions" [paginator]="true"
            [first]="first" currentPageReportTemplate="Showing {{first}} to {{last}} of {{totalRecords}} entries"
            [styleClass]="tableStyle" [totalRecords]="totalRecords" [columns]="cols" [(selection)]="selectedSummary"
            (onRowSelect)="onRowSelect($event)">


            <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-between">
                    <!-- <p-button [label]="isMobile ? '' : 'Clear'"
                        styleClass="p-button-outlined sm:p-button-sm md:p-button-md mr-1" icon="pi pi-filter-slash"
                        (click)="clear(dt)" class="clear-button">
                    </p-button>

                    <p-button [label]="isMobile ? '' : 'Delete'"
                        styleClass="p-button-outlined sm:p-button-sm md:p-button-md" icon="pi pi-trash"
                        (click)="deleteRows()" label="Delete" class="clear-button"></p-button> -->
                    <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" class="multiselectfields"
                        selectedItemsLabel="{0} Selected" placeholder="Choose Columns" defaultLabel="Choose Columns"
                        [maxSelectedLabels]="3"> </p-multiSelect>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="globalFilter($event)" #globalFiterInput
                            class="searchglobally" placeholder="Search keyword" />
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <!-- style="width: 4rem" -->
                    <th>
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th class="text-center"><i class="pi pi-eye"></i></th>
                    <th class="text-center"><i class="pi pi-share-alt"></i></th>
                    <th pSortableColumn="containerId.containerNumber" p-toggleableColumn>Container Number
                        <p-columnFilter type="text" field="containerNumber" display="menu" matchMode="contains"
                            [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                        <p-sortIcon field="containerId.containerNumber"></p-sortIcon>
                    </th>
                    <th pSortableColumn="pieces" *ngIf="_selectedColumns!.includes('Pieces')">Pieces
                        <p-columnFilter type="text" field="pieces" display="menu" [matchModeOptions]="[{value: 'equals', label: 'Equals'},{value: 'lt', label: 'Less than'}, 
                    {value: 'lte', label: 'Less than or equal'},{value: 'gt', label: 'Greater than'},
                    {value: 'gte', label: 'Greater than or equal'}]" [showMatchModes]="true" [showOperator]="false"
                            [showAddButton]="false"></p-columnFilter>
                        <p-sortIcon field="pieces"></p-sortIcon>
                    </th>
                    <th pSortableColumn="grossVolume" p-toggleableColumn *ngIf="_selectedColumns!.includes('G Vol')">G
                        Vol
                        <p-columnFilter type="text" field="grossVolume" display="menu" [matchModeOptions]="[{value: 'equals', label: 'Equals'},{value: 'lt', label: 'Less than'}, 
                        {value: 'lte', label: 'Less than or equal'},{value: 'gt', label: 'Greater than'},
                        {value: 'gte', label: 'Greater than or equal'}]" [showOperator]="false"
                            [showAddButton]="false"></p-columnFilter>
                        <p-sortIcon field="grossVolume"></p-sortIcon>
                    </th>
                    <th pSortableColumn="netVolume" *ngIf="_selectedColumns!.includes('N Vol')">N Vol
                        <p-columnFilter type="text" field="netVolume" display="menu" matchMode="contains"
                            [matchModeOptions]="[{value: 'equals', label: 'Equals'},{value: 'lt', label: 'Less than'}, 
                    {value: 'lte', label: 'Less than or equal'},{value: 'gt', label: 'Greater than'},
                    {value: 'gte', label: 'Greater than or equal'}]" [showOperator]="false"
                            [showAddButton]="false"></p-columnFilter>
                        <p-sortIcon field="netVolume"></p-sortIcon>
                    </th>
                    <th pSortableColumn="grossAverage" *ngIf="_selectedColumns!.includes('G Avg')">G Avg
                        <p-columnFilter type="text" field="grossAverage" display="menu" [matchModeOptions]="[{value: 'equals', label: 'Equals'},{value: 'lt', label: 'Less than'}, 
                        {value: 'lte', label: 'Less than or equal'},{value: 'gt', label: 'Greater than'},
                        {value: 'gte', label: 'Greater than or equal'}]" [showOperator]="false"
                            [showAddButton]="false"></p-columnFilter>
                        <p-sortIcon field="grossAverage"></p-sortIcon>
                    </th>
                    <th pSortableColumn="netAverage" *ngIf="_selectedColumns!.includes('N Avg')">N Avg
                        <p-columnFilter type="text" field="netAverage" display="menu" [matchModeOptions]="[{value: 'equals', label: 'Equals'},{value: 'lt', label: 'Less than'}, 
                        {value: 'lte', label: 'Less than or equal'},{value: 'gt', label: 'Greater than'},
                        {value: 'gte', label: 'Greater than or equal'}]" [showOperator]="false"
                            [showAddButton]="false"></p-columnFilter>
                        <p-sortIcon field="netAverage"></p-sortIcon>
                    </th>
                    <th pSortableColumn="containerId.productId.name" *ngIf="_selectedColumns!.includes('Product')">
                        Product
                        <p-columnFilter type="text" field="product" display="menu" matchMode="contains"
                            [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                        <p-sortIcon field="containerId.productId.name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="containerId.supplierId.name" *ngIf="_selectedColumns!.includes('Supplier')">
                        Supplier
                        <p-columnFilter type="text" field="supplier" display="menu" matchMode="contains"
                            [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                        <p-sortIcon field="containerId.supplierId.name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="containerId.loadingSiteId.name"
                        *ngIf="_selectedColumns!.includes('Loading Site')">
                        Loading Site
                        <p-columnFilter type="text" field="loadingSite" display="menu" matchMode="contains"
                            [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                        <p-sortIcon field="containerId.loadingSiteId.name"></p-sortIcon>
                    </th>

                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-summary let-columns="columns" let-rowIndex="rowIndex">
                <tr [pSelectableRow]="columns" (click)="onRowClick(summary)" p-selectable-row>
                    <td (click)="$event.stopPropagation()">
                        <p-tableCheckbox [value]="summary"></p-tableCheckbox>
                    </td>
                    <td (click)="$event.stopPropagation()" class="text-center cursor-pointer">
                        <i class="pi pi-eye" (click)="onViewMeasurement(summary.containerId.containerId)"></i>
                    </td>
                    <td  (click)="$event.stopPropagation();" class="text-center cursor-pointer">

                        <i #share  class="pi pi-share-alt" style="cursor: pointer;" (click)="showShareContextMenu($event, rowIndex,summary )"  ></i> 
                        <p-contextMenu #cm [target]="share" [model]="shareItems" ></p-contextMenu>
                        <!-- <i class="pi pi-share-alt"  
                            (click)="onViewMeasurement(summary.containerId.containerId)"></i> -->
                        <!-- <p-speedDial [model]="items" direction="up" [mask]="true"></p-speedDial> -->
                        <!-- <p-speedDial [model]="speedDialItems" [id]="summary.containerId.containerId"  direction="up" [transitionDelay]="80" showIcon="pi pi-share-alt" hideIcon="pi pi-times" buttonClassName="p-button-outlined"></p-speedDial> -->
                        <!-- <p-splitButton label="Save" icon="pi pi-plus"   [model]="items"></p-splitButton> -->



                    </td>
                    <td> {{summary.containerId.containerNumber}}</td>
                    <td *ngIf="_selectedColumns!.includes('Pieces')"> {{summary.pieces}}</td>
                    <td *ngIf="_selectedColumns!.includes('G Vol')"> {{summary.grossVolume}}</td>
                    <td *ngIf="_selectedColumns!.includes('N Vol')"> {{summary.netVolume}}</td>
                    <td *ngIf="_selectedColumns!.includes('G Avg')"> {{summary.grossAverage}}</td>
                    <td *ngIf="_selectedColumns!.includes('N Avg')"> {{summary.netAverage}}</td>
                    <td *ngIf="_selectedColumns!.includes('Product')"> {{summary.containerId.productId.name|| ''}}</td>
                    <td *ngIf="_selectedColumns!.includes('Supplier')"> {{summary.containerId!.supplierId!.name! || ''}}
                    </td>
                    <td *ngIf="_selectedColumns!.includes('Loading Site')"> {{summary.containerId.loadingSiteId.name||
                        ''}}
                    </td>

                </tr>
            </ng-template>

            <!--  <ng-template pTemplate="paginatorleft">
            <p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button>
        </ng-template>
        <ng-template pTemplate="paginatorright">
            <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button>
        </ng-template> -->
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">No record found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<!-- 
<button pButton icon="pi pi-eye" (click)="showNameColumn = !showNameColumn"
    [label]="showNameColumn ? 'Hide Name' : 'Show Name'"></button>

<button pButton icon="pi pi-eye" (click)="getSelectedContainerSummary()"
    label="Get Selected Container Summary"></button> -->