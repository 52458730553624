<router-outlet></router-outlet>
<div class="custom-card">
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
    <div class="pb-3">
        <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
    </div>

    <p-card [header]="formHeader" [subheader]="formSubHeader" class="w-full">
        <p-speedDial [model]="containersSpeedDialItems" direction="up-left" type="quarter-circle" [radius]="120"
            class="ml-5 my-speed-dial" [rotateAnimation]="true"></p-speedDial>
        <div>

            <p-table #dt [lazy]="true" [value]="containers" (onLazyLoad)="sortContainers($event)" [rowHover]="true"
                [customSort]="true" sortMode="multiple" dataKey="containerId"
                [globalFilterFields]="['name','hsCoe','description']" [showCurrentPageReport]="true" [rows]="rows"
                [rowsPerPageOptions]="rowsPerPageOptions" [paginator]="true" [first]="first"
                currentPageReportTemplate="Showing {{first}} to {{last}} of {{totalRecords}} entries"
                [totalRecords]="totalRecords" [columns]="cols" [(selection)]="selectedContainers"
                (onRowSelect)="onRowSelect($event)" [styleClass]="tableStyle" [tableStyle]="tableStyleOptions"
                [loading]="loading" paginatorDropdownAppendTo="body">


                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-between">
                        <!--  <p-button [label]="isMobile ? '' : 'Clear'"
                        styleClass="p-button-outlined sm:p-button-sm md:p-button-md mr-1" icon="pi pi-filter-slash"
                        (click)="clear(dt)" class="clear-button">
                    </p-button>

                    <p-button [label]="isMobile ? '' : 'Delete'"
                        styleClass="p-button-outlined sm:p-button-sm md:p-button-md" icon="pi pi-trash"
                        (click)="deleteRows()" label="Delete" class="clear-button"></p-button> -->
                        <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" class="multiselectfields"
                            selectedItemsLabel="{0} Selected" placeholder="Choose Columns" defaultLabel="Choose Columns"
                            [maxSelectedLabels]="3"> </p-multiSelect>
                        <span class="p-input-icon-left">
                            <div class="p-inputgroup">
                                <input pInputText type="text" (input)="globalFilter($event,dt)" #globalFiterInput
                                    class="searchglobally" placeholder="Search keyword" />
                                <button type="button" pButton icon="pi pi-filter-slash" styleClass="p-button-warn"
                                    (click)="clearAllFilter(dt,globalFiterInput)"></button>
                            </div>
                        </span>
                    </div>
                </ng-template>

                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th class="w-3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th class="w-3rem text-center text-2xl">
                            <p-button icon="pi pi-eye" severity="secondary" [outlined]="true" />
                            <!-- <i class="pi pi-eye text-center text-2xl"></i> -->

                        </th>
                        <th pSortableColumn="containerNumber">Container Number
                            <p-columnFilter type="text" field="containerNumber" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                            <p-sortIcon field="containerNumber"></p-sortIcon>
                        </th>
                        <th pSortableColumn="sealNumber" *ngIf="_selectedColumns!.includes('Seal')">Seal
                            <p-columnFilter type="text" field="sealNumber" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                            <p-sortIcon field="sealNumber"></p-sortIcon>
                        </th>
                        <th *ngIf="_selectedColumns!.includes('Product')">Product
                            <p-columnFilter type="text" field="product" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                        </th>
                        <th *ngIf="_selectedColumns!.includes('Seller')">Seller
                            <p-columnFilter type="text" field="supplier" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                        </th>
                        <th *ngIf="_selectedColumns!.includes('Site')">Loading Site
                            <p-columnFilter type="text" field="loadingSite" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-containers let-columns="columns">
                    <!-- <tr [pSelectableRow]="columns" p-selectable-row> -->
                    <!-- <td (click)="$event.stopPropagation()">
                            <p-tableCheckbox [value]="containers" (click)="$event.stopPropagation()"></p-tableCheckbox>
                        </td> -->
                    <tr [pSelectableRow]="columns" (click)="onRowClick(containers)" p-selectable-row>
                        <td (click)="$event.stopPropagation()">
                            <p-tableCheckbox [value]="containers"></p-tableCheckbox>
                        </td>
                        <td (click)="$event.stopPropagation()" >
                            <p-button icon="pi pi-eye" severity="secondary" size="small" (click)="viewContainer(containers.shortenUrl)" />
                        </td>
                        <td> {{containers.containerNumber }}</td>
                        <td *ngIf="_selectedColumns!.includes('Seal')">{{containers.sealNumber }}</td>
                        <td *ngIf="_selectedColumns!.includes('Product')">{{ containers?.productId?.name ?? '' }}</td>
                        <td *ngIf="_selectedColumns!.includes('Seller')">{{containers?.supplierId?.name ?? '' }}</td>
                        <td *ngIf="_selectedColumns!.includes('Site')">{{containers?.loadingSiteId?.name ?? '' }}</td>

                    </tr>
                </ng-template>

                <!--  <ng-template pTemplate="paginatorleft">
            <p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button>
        </ng-template>
        <ng-template pTemplate="paginatorright">
            <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button>
        </ng-template> -->
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <!-- <td colspan="(_selectedColumns?.length ?? 6) + 2">No records found.</td> -->
                        <td [attr.colspan]="(_selectedColumns ? _selectedColumns.length + 2 : 6)" class="text-center">No
                            records found.</td>

                    </tr>
                </ng-template>
                <ng-template pTemplate="loadingbody" let-columns="columns">
                    <tr style="height:46px">
                        <td>
                            <p-skeleton></p-skeleton>
                        </td>
                        <td> <p-skeleton></p-skeleton> </td>
                        <td *ngIf="_selectedColumns!.includes('Seal')"> <p-skeleton></p-skeleton> </td>
                        <td *ngIf="_selectedColumns!.includes('Product')"> <p-skeleton></p-skeleton> </td>
                        <td *ngIf="_selectedColumns!.includes('Seller')"> <p-skeleton></p-skeleton> </td>
                        <td *ngIf="_selectedColumns!.includes('Site')"> <p-skeleton></p-skeleton> </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-card>
</div>

<p-sidebar [(visible)]="containerSidebarVisible" position="right" [style]="{'width': '50vw'}" (onHide)="setPageTitle()">
    <app-container *ngIf="containerSidebarVisible" [showBreadcrumb]="false" [isSubmittedFromSidebar]="true"
        (containerSubmit)="onContainerSubmit($event)" titleFromSidebar="Add New Container"
        headerFromSidebar="Create Container" [isAddLoadingSiteFromMeasurement]="true"
        subHeaderFromSidebar="Input new container details"></app-container>
</p-sidebar>