import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubscriptionPackageSelected } from '../../model/subscription-package-selected';
import { SubscriptionDurationSelected } from '../../model/subscription-duration-selected';
import { SubscriptionPromotionResponse } from '../../model/subscription-promotion-response';
import { SubscriptionService } from '../../services/subscription.service';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';

@Component({
  selector: 'app-subscription-evaluate-order',
  templateUrl: './subscription-evaluate-order.component.html',
  styleUrls: ['./subscription-evaluate-order.component.css'],
  standalone: true,
  imports:[SHARED_MODULES]
})
export class SubscriptionEvaluateOrderComponent implements OnInit {
  @Input() selectedCurrency: any | null;
  @Input() selectedPackage!: SubscriptionPackageSelected | null; // assuming the package type is 'any' for this exampleselectedPackage
  @Input() sgst: any | null;
  @Input() cgst: any | null;
  @Input() duration: any | null;
  @Input() totalBillAmount: any | null; //selectedDuration!: SubscriptionDurationSelected;
  @Input() selectedDuration!: SubscriptionDurationSelected | null;
  @Input() promoApplied!: SubscriptionPromotionResponse | null;
  @Input() grandTotal!: number | null;

  dummy!: any[];

  constructor(
    private currencyPipe: CurrencyPipe,
    private subscriptionService: SubscriptionService
  ) {
    this.dummy = ['hello'];
  }
  ngOnInit(): void {
    this.subscriptionService.currentCurrency.subscribe((currency) => {
      this.selectedCurrency = currency;
      console.log(
        `Selected Currency from Behaviour is ${this.selectedCurrency}`
      );
      this.selectedCurrency = null;
      this.selectedPackage = null;
      this.sgst = null;
      this.cgst = null;
      this.duration = null;
      this.totalBillAmount = null;
      this.selectedDuration = null;
      this.promoApplied = null;
      this.grandTotal = null;
    });
  }

  getValues() {
    console.log(
      `getValues of selectedPackage is ${JSON.stringify(this.selectedPackage)}`
    );
    console.log(
      `getValues of selectedCurrency is ${JSON.stringify(
        this.selectedCurrency
      )}`
    );
  }
}
