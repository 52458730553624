import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject, Subject, debounceTime, switchMap } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { ContainerNumbers } from 'src/app/modules/container/model/container-numbers';
import { ContainerService } from 'src/app/modules/container/services/container.service';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';
import { PopulateDropdownService } from 'src/app/services/populate-dropdown.service';

@Component({
  selector: 'app-sawn-timber-container-selection',
  templateUrl: './sawn-timber-container-selection.component.html',
  styleUrls: ['./sawn-timber-container-selection.component.css'],
  providers: [ToastMessagesService],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class SawnTimberContainerSelectionComponent implements OnInit {
  editModeContainerNumber = false;
  selectedContainer = new ContainerNumbers();
  containerNumbers: ContainerNumbers[] = [];
  searchContainerNumberSubject: Subject<string> = new Subject<string>();
  containerSidebarVisible: boolean = false;
  activeIndex: number = 0;
  blockedDocument = false;
  private selectedContainer$: BehaviorSubject<ContainerNumbers | null> =
    new BehaviorSubject<ContainerNumbers | null>(null);

  constructor(
    private confirmationService: ConfirmationService,
    private populateDropdownService: PopulateDropdownService,
    private router: Router,
    private containerService: ContainerService,
    private encryptedStorageService: EncryptedStorageService,
    private toastMessageService: ToastMessagesService,
    private titleService: Title,
    private route: ActivatedRoute
  ) {
    this.setPageTitle();
  }

  onContainerAutoCompleteSelect(event: ContainerNumbers) {
    console.log(`onContainerAutoCompleteSelect  ${JSON.stringify(event)}`);
    this.selectedContainer = event;
    this.selectedContainer$.next(this.selectedContainer); // Update the BehaviorSubject
  }

  onEditContainerNumber() {
    this.confirmationService.confirm({
      header: 'Are you sure that you want to perform this action?',
      message:
        'Executing this action will permanently erase all present data associated with container number <strong>' +
        this.selectedContainer.containerNumber +
        '</strong>. Once executed, this action is irreversible.',
      acceptButtonStyleClass: 'p-button-danger',
      accept: () => {
        this.editModeContainerNumber = false;
      },
    });
  }

  initContainerNumberAutocomplete() {
    this.searchContainerNumberSubject
      .pipe(
        debounceTime(400),
        switchMap((query) =>
          // this.populateDropdownService.getContainerNumbersNotInSummary(query)
          this.populateDropdownService.getContainerNumbers(query)
        ) //this.populateService.getFilteredCities(query))
      )
      .subscribe(
        (response: any) => {
          console.log(`Response is ${JSON.stringify(response)}`);
          this.containerNumbers = response;
        },
        (error: any) => {
          console.log(`Error is ${JSON.stringify(error)}`);
        }
      );
  }

  onNext() {
    this.containerService.changeContainerNumber(this.selectedContainer);
    console.log(`on next before storage`);
    // this.containerService.setContainerInStorage(this.selectedContainer);
    this.encryptedStorageService
      .setEncryptedDataToStorage(AppConstants.CONTAINER, this.selectedContainer)
      .subscribe(
        () => {
          console.log('Data encrypted and stored successfully.');

          // Get encrypted data from local storage
          this.encryptedStorageService
            .getEncryptedDataFromStorage(AppConstants.CONTAINER)
            .subscribe(
              (data) => {
                console.log('Decrypted data:', data);
              },
              (error) => {
                console.error('Failed to decrypt data:', error);
              }
            );
        },
        (error) => {
          console.error('Failed to encrypt and store data:', error);
        },
        () => {
          this.router.navigate(['timber/pl']);
        }
      );
    console.log(`on next after storage`);

    // this.router.navigate(['/sawn/row'], { skipLocationChange: true });

    /*  if (
      this.selectedContainer.containerId &&
      this.selectedContainer.productFormula
    ) {
      this.activeIndex = 1;
      if (this.selectedContainer.productFormula === 1) {
        this.router.navigate(['sawn/hoppus']);
      } else if (this.selectedContainer.productFormula === 2) {
        this.router.navigate(['sawn/row']);
      }
    } */
    // console.log(`this.selectedContainer.containerId ${JSON.stringify(this.selectedContainer)}`)
    // this.router.navigate(['sawn/row']);
  }

  searchContainer(event: any) {
    console.log(`event ${JSON.stringify(event)}`);
    this.searchContainerNumberSubject.next(event.query);
  }

  ngOnInit(): void {
    this.initContainerNumberAutocomplete();
    this.encryptedStorageService
      .getEncryptedDataFromStorage(AppConstants.CONTAINER)
      .subscribe(
        (data: any) => {
          console.log(`Data response is ${JSON.stringify(data)}`);
          if (data && data.containerId) {
            this.selectedContainer = data;
          }
        },
        (error: any) => {
          console.log(`Data error is ${JSON.stringify(error)}`);
        },
        () => {}
      );
  }

  lastKeypress: number = 0;

  onKeydown(event: any) {
    console.log(
      `this.selectedContainer ${JSON.stringify(
        this.selectedContainer.containerId
      )}`
    );
    if (event.key === 'Enter' && this.selectedContainer.containerId) {
      let currentKeypress = new Date().getTime();
      if (currentKeypress - this.lastKeypress <= 300) {
        // 300ms
        console.log('Double Enter detected');
        this.onNext();
        // Put your logic here
      }
      this.lastKeypress = currentKeypress;
    }
  }

  isContainerNumberExist() {
    console.log(`isContainerNumberExist `);
    const containerNumber = this.selectedContainer;
    if (containerNumber?.containerId) {
      this.blockedDocument = true;
      this.containerService
        .isContainerIdExists(containerNumber?.containerId)
                // .isContainerIdExists(100) to test

        .subscribe(
          (response: any) => {
            if (response) {
              this.blockedDocument = false;
              this.onNext();
            } else {
              // Show toast message here
              this.toastMessageService.showErrorMessage(
                'Container Number Unavailable',
                'The container may have been deleted or does not exist.'
              );
              this.blockedDocument = false;
              this.encryptedStorageService.deleteKey(AppConstants.CONTAINER);
              this.selectedContainer = new ContainerNumbers();
            }
          },
          (error: any) => {
            this.blockedDocument = false;
            this.encryptedStorageService.deleteKey(AppConstants.CONTAINER);
            this.toastMessageService.showErrorMessage(
              'Container Status',
              'This container number or ID has been moved or removed.'
            );
          },
          () => {
            this.blockedDocument = false;
          }
        );
    } else {
    }
  }
  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
    });
  }
}
