<!-- (ngSubmit)="saveChanges()" -->

<!-- <p-blockUI [blocked]="blockedForm">
  <i class="pi pi-lock" style="font-size: 3rem"></i>
  <p> Connection to server is lost</p>
</p-blockUI> -->
<!-- <p>You are in {{currentMode}} Mode. Click here to switch to
  </p>
  <p-button [label]="changeToMode" [link]="true" (click)="onEditModeButtonClick()" /> -->
<div class="no-scroll">
  <!-- <div style="margin: 0; padding: 0; width: 100%; position: fixed; top: 0; z-index: 1000;" *ngIf="summaryId">
    <div class="flex justify-content-between align-items-center bg-primary-reverse p-2" style="width: 100%;">

      <div class="flex justify-content-center flex-grow-1 ">
        <p class="m-0">You are in {{currentMode}} Mode. {{summaryId?? ''}}</p>
      </div>

      <div class="flex align-items-center gap-2">
        <p-button (click)="onEditModeButtonClick()" [icon]="changeToModeIcon" rounded="true"
          styleClass="w-2rem h-2rem p-0" />

        <div class="ml-4"></div>

        <p-button (click)="activeIndex =0" rounded="true" icon="pi pi-cog" styleClass="w-2rem h-2rem p-0"
          [outlined]="activeIndex !== 0" pTooltip="Tallysheet Settings" tooltipPosition="left" />
        <p-button (click)="activeIndex = 1" rounded="true" icon="pi pi-list" styleClass="w-2rem h-2rem p-0"
          [outlined]="activeIndex !== 1" pTooltip="Tallysheet Records" tooltipPosition="left" />
      </div>
    </div>
  </div> -->




  <div class="pb-5"></div>
  <p-blockUI [blocked]="(showProgressBar$ | async) ?? false">
    <i class="pi pi-lock" style="font-size: 5rem"></i>
    <p> Pls wait while loading data...</p>
  </p-blockUI>
  <p-sidebar [(visible)]="tallysheetSettingsSidebar" position="right" styleClass="w-6"
    class="fadeinright animation-duration-1000 animation-iteration-1" [showCloseIcon]="!isAnyInplaceActive()"
    [dismissible]="!isAnyInplaceActive()" [modal]="!isAnyInplaceActive()" [closeOnEscape]="!isAnyInplaceActive()">
    <p-card>

      <ng-template pTemplate="header">
        <div class="flex justify-content-between align-items-center m-2">
          <!-- Left Section: Title -->
          <div class="flex align-items-center">
            <h2>Settings</h2>
          </div>
      
          <!-- Right Section: Button -->
          <p-button (click)="onEditModeButtonClick()" [icon]="changeToModeIcon" class="ml-2" *ngIf="!isMobile" />
        </div>
      </ng-template>
      


      <div styleClass="vertical-align-middle p-0 m-0">
        <form [formGroup]="summaryForm" (ngSubmit)="saveSummary()" (keydown.enter)="submitForm()" #summaryFormElement>

          <input type="text" formControlName="id" *ngIf="false" value="10" />
          <input formControlName="totalPieces" id="totalPieces" type="number" *ngIf="false">
          <input formControlName="totalNetVolumeCBM" id="totalNetVolumeCBM" type="number" *ngIf="false">
          <input formControlName="totalGrossVolumeCBM" id="total  " type="number" *ngIf="false">

          <div class="common-fields mb-5">

            <div class="formgrid grid"
              *ngIf="summaryId && tallySheetRecordsArray.controls.length > 0; else newTallySheet">
              <div class="field col-6 md:col-6">
                <label for="containerNumber">Container Number
                </label>
                <p-inplace [preventClick]="'true'">
                  <ng-template pTemplate="display">
                    <span class="inline-block w-7rem">{{ summaryForm.value.container.containerNumber?? '' }}</span>
                  </ng-template>
                  <!-- <ng-template pTemplate="content">
                  <p-inputGroup>
                  

                    <p-autoComplete formControlName="container" [suggestions]="filteredContainerNumbers"
                      (completeMethod)="filterContainerNumbers($event)" optionLabel="containerNumber" [dropdown]="false"
                      [forceSelection]="true"
                      styleClass="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                    <button type="button" pButton icon="pi pi-check" class="p-button-success"
                      (click)="acceptUpdateContainerNumber()"></button>
                    <button type="button" pButton icon="pi pi-times" class="p-button-danger"
                      (click)="denyUpdateContainerNumber()"></button>
                  </p-inputGroup>

                  <div
                    *ngIf="summaryForm.get('containerNumber')?.invalid && summaryForm.get('containerNumber')?.touched"
                    class="text-red-500">
                    {{ getErrorMessage('containerNumber') }}
                  </div>
                </ng-template> -->
                </p-inplace>

              </div>
              <div class="field col-6 md:col-6">
                <label for="typeOfShipment">Type of Shipment</label>
                <p-inplace [preventClick]="'true'">
                  <ng-template pTemplate="display">
                    <span class="inline-block w-7rem">{{ summaryForm.value.typeOfShipment?? 'Round'
                      }}</span>

                  </ng-template>
                  <ng-template pTemplate="content">
                    <p-inputGroup>
                      <select formControlName="typeOfShipment" id="typeOfShipment"
                        class="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                        style="appearance: auto">
                        <option value="Round">Round</option>
                        <option value="Square">Square</option>
                      </select>
                    </p-inputGroup>
                    <div
                      *ngIf="summaryForm.get('typeOfShipment')?.invalid && summaryForm.get('typeOfShipment')?.touched"
                      class="text-red-500">
                      {{ getErrorMessage('typeOfShipment') }}
                    </div>
                  </ng-template>
                </p-inplace>

              </div>
              <div class="field col-6 md:col-6">
                <label for="girthAllowance">Girth Allowance:</label>
                <p-inplace #inplaceGirth [preventClick]="!isCommonFieldsEditable || isAnyInplaceActive()"
                  [active]="activeInplace[0]" (onActivate)="activateInplace(0)" (onDeactivate)="deactivateInplace(0)">
                  <ng-template pTemplate="display">
                    <span class="inline-block w-7rem"
                      (click)="showInPlaceAlreadyActive(summaryForm.value.girthAllowance)">{{
                      summaryForm.value.girthAllowance?? 0 }}</span>

                  </ng-template>
                  <ng-template pTemplate="content">
                    <p-inputGroup>
                      <input formControlName="girthAllowance" id="girthAllowance" type="number" min="0" max="100"
                        step="1"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                      <button type="button" pButton icon="pi pi-check" class="p-button-success"
                        (click)="updateCommonField(true,'girthAllowance')"></button>
                      <button type="button" pButton icon="pi pi-times" class="p-button-danger"
                        (click)="updateCommonField(false,'girthAllowance')"></button>
                    </p-inputGroup>
                    <div
                      *ngIf="summaryForm.get('girthAllowance')!.invalid && summaryForm.get('girthAllowance')!.touched"
                      class="text-red-500">
                      {{ getErrorMessage('girthAllowance') }}
                    </div>
                  </ng-template>
                </p-inplace>

              </div>
              <div class="field col-6 md:col-6">
                <label for="city">Girth Measurement Unit:</label>

                <p-inplace #inplaceUomGirth [preventClick]="!isCommonFieldsEditable || isAnyInplaceActive()"
                  [active]="activeInplace[1]" (onActivate)="activateInplace(1)" (onDeactivate)="deactivateInplace(1)">
                  <ng-template pTemplate="display">

                    <span class="inline-block w-7rem"
                      (click)="showInPlaceAlreadyActive(summaryForm?.value?.uomGirth)">{{
                      summaryForm?.value?.uomGirth?? 0 }}</span>


                  </ng-template>
                  <ng-template pTemplate="content">
                    <p-inputGroup>
                      <select formControlName="uomGirth" id="uomGirth"
                        class="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                        style="appearance: auto">
                        <option value="mm">mm</option>
                        <option value="cm">cm</option>
                        <option value="in">in</option>
                        <option value="ft">ft</option>
                        <option value="meter">meter</option>
                      </select>
                      <button type="button" pButton icon="pi pi-check" class="p-button-success"
                        (click)="updateCommonField(true,'uomGirth')"></button>
                      <button type="button" pButton icon="pi pi-times" class="p-button-danger"
                        (click)="updateCommonField(false,'uomGirth')"></button>
                      <div *ngIf="summaryForm.get('uomGirth')?.invalid && summaryForm.get('uomGirth')?.touched"
                        class="text-red-500">
                        {{ getErrorMessage('uomGirth') }}
                      </div>
                    </p-inputGroup>

                  </ng-template>
                </p-inplace>
              </div>


              <div class="field col-6 md:col-6">
                <label for="lengthAllowance">Length Allowance:</label>
                <p-inplace #inplaceLength [preventClick]="!isCommonFieldsEditable || isAnyInplaceActive()"
                  [active]="activeInplace[2]" (onActivate)="activateInplace(2)" (onDeactivate)="deactivateInplace(2)">
                  <ng-template pTemplate="display">

                    <span class="inline-block w-7rem"
                      (click)="showInPlaceAlreadyActive(summaryForm.value.lengthAllowance)">{{
                      summaryForm.value.lengthAllowance?? 0 }}</span>

                  </ng-template>
                  <ng-template pTemplate="content">
                    <p-inputGroup>
                      <input formControlName="lengthAllowance" id="lengthAllowance" type="number" min="0" max="100"
                        step="1"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">

                      <button type="button" pButton icon="pi pi-check" class="p-button-success"
                        (click)="updateCommonField(true,'lengthAllowance')"></button>
                      <button type="button" pButton icon="pi pi-times" class="p-button-danger"
                        (click)="updateCommonField(false,'lengthAllowance')"></button>
                      <div
                        *ngIf="summaryForm.get('lengthAllowance')?.invalid && summaryForm.get('lengthAllowance')?.touched"
                        class="text-red-500">
                        {{ getErrorMessage('lengthAllowance') }}
                      </div>
                    </p-inputGroup>

                  </ng-template>
                </p-inplace>

              </div>
              <div class="field col-6 md:col-6">
                <label for="city">Length Measurement Unit:</label>

                <p-inplace #inplaceUomLength [preventClick]="!isCommonFieldsEditable || isAnyInplaceActive()"
                  [active]="activeInplace[3]" (onActivate)="activateInplace(3)" (onDeactivate)="deactivateInplace(3)">
                  <ng-template pTemplate="display">
                    <span class="inline-block w-7rem"
                      (click)="showInPlaceAlreadyActive(summaryForm?.value?.uomLength)">{{
                      summaryForm?.value?.uomLength ?? 'N/A' }}</span>

                  </ng-template>
                  <ng-template pTemplate="content">
                    <p-inputGroup>
                      <select formControlName="uomLength" id="uomLength"
                        class="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                        style="appearance: auto">
                        <option value="mm">mm</option>
                        <option value="cm">cm</option>
                        <option value="in">in</option>
                        <option value="ft">ft</option>
                        <option value="meter">meter</option>
                      </select>
                      <button type="button" pButton icon="pi pi-check" class="p-button-success"
                        (click)="updateCommonField(true, 'uomLength')"></button>
                      <button type="button" pButton icon="pi pi-times" class="p-button-danger"
                        (click)="updateCommonField(false, 'uomLength')"></button>
                      <div *ngIf="summaryForm.get('uomLength')?.invalid && summaryForm.get('uomLength')?.touched"
                        class="text-red-500">
                        {{ getErrorMessage('uomLength') }}
                      </div>
                    </p-inputGroup>
                  </ng-template>
                </p-inplace>

              </div>


              <div class="field col-6 md:col-6">
                <label for="decimalPlaces">Decimal Places:</label>
                <p-inplace #inplaceDecimalPlaces [preventClick]="!isCommonFieldsEditable || isAnyInplaceActive()"
                  [active]="activeInplace[4]" (onActivate)="activateInplace(4)" (onDeactivate)="deactivateInplace(4)">
                  <ng-template pTemplate="display">
                    <span class="inline-block w-7rem"
                      (click)="showInPlaceAlreadyActive(summaryForm.value.decimalPlaces)">{{
                      summaryForm.value.decimalPlaces?? 0 }}</span>

                  </ng-template>
                  <ng-template pTemplate="content">
                    <p-inputGroup>
                      <input type="number" formControlName="decimalPlaces" value="3" min="0" max="6" step="1"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                      <button type="button" pButton icon="pi pi-check" class="p-button-success"
                        (click)="updateCommonField(true,'decimalPlaces')"
                        [disabled]="checkIfAnyFieldIsDirty()"></button>
                      <button type="button" pButton icon="pi pi-times" class="p-button-danger"
                        (click)="updateCommonField(false,'decimalPlaces')"
                        [disabled]="checkIfAnyFieldIsDirty()"></button>
                    </p-inputGroup>
                    <div *ngIf="summaryForm.get('decimalPlaces')?.invalid && summaryForm.get('decimalPlaces')?.touched"
                      class="text-red-500">
                      {{ getErrorMessage('decimalPlaces') }}
                    </div>
                  </ng-template>
                </p-inplace>
              </div>


              <div class="field col-6 md:col-6">
                <label for="city">Rounding-Off Direction:</label>

                <p-inplace #inplaceRoundingDirection [preventClick]="!isCommonFieldsEditable || isAnyInplaceActive()"
                  [active]="activeInplace[5]" (onActivate)="activateInplace(5)" (onDeactivate)="deactivateInplace(5)">
                  <ng-template pTemplate="display">
                    <span class="inline-block w-7rem"
                      (click)="showInPlaceAlreadyActive(summaryForm.value.roundingDirection)">{{
                      summaryForm.value.roundingDirection?? 0
                      }}</span>

                  </ng-template>
                  <ng-template pTemplate="content">
                    <p-inputGroup>
                      <select formControlName="roundingDirection"
                        class="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary">
                        <option value="Up">Up</option>
                        <option value="Down">Down</option>
                      </select>
                      <button type="button" pButton icon="pi pi-check" class="p-button-success"
                        (click)="updateCommonField(true,'roundingDirection')"></button>
                      <button type="button" pButton icon="pi pi-times" class="p-button-danger"
                        (click)="updateCommonField(false,'roundingDirection')"></button>
                    </p-inputGroup>
                  </ng-template>
                  <div
                    *ngIf="summaryForm.get('roundingDirection')?.invalid && summaryForm.get('roundingDirection')?.touched"
                    class="text-red-500">
                    {{ getErrorMessage('roundingDirection') }}
                  </div>
                </p-inplace>
              </div>

              <!-- ------------------------------------------------------ -->
              <div class="field col-6 md:col-6">
                <label for="copyPreviousRow">Enable Copy Previous Row:</label>

                <i class="pi pi-question-circle font-semibold  ml-2" [pTooltip]="tooltipContentCopyPreviousRow"
                  tooltipStyleClass="custom-tooltip-class" fitContent="true" severity="secondary"
                  tooltipPosition="bottom" showDelay="50" placeholder="bottom" fitContent="true" tooltipEvent="focus"
                  showDelay="100" hideDelay="200" tabindex="0">
                </i>

                <p-inplace #inplaceCopyPreviousRow [preventClick]="!isCommonFieldsEditable || isAnyInplaceActive()"
                  [active]="activeInplace[6]" (onActivate)="activateInplace(6)" (onDeactivate)="deactivateInplace(6)">
                  <ng-template pTemplate="display">
                    <span class="inline-block w-7rem"
                      (click)="showInPlaceAlreadyActive(summaryForm.value.copyPreviousRowLength)">
                      {{ summaryForm.value.copyPreviousRowLength??false ? 'Yes' : 'No' }}
                    </span>

                  </ng-template>
                  <ng-template pTemplate="content">
                    <p-inputGroup>
                      <p-inputSwitch formControlName="copyPreviousRowLength"
                        (onChange)="onChangeCopyPreviousRowLength($event)" />
                    </p-inputGroup>
                  </ng-template>
                </p-inplace>
              </div>


              <div class="field col-6 md:col-6">
                <label for="city">Length Increament:
                  <i class="pi pi-question-circle font-semibold  ml-2" [pTooltip]="tooltipContentLengthIncreament"
                    severity="secondary" tooltipPosition="bottom" showDelay="50" placeholder="bottom" fitContent="true"
                    tooltipEvent="focus" showDelay="100" hideDelay="200" tabindex="0"></i></label>

                <p-inplace #inplaceLengthIncreament [preventClick]="!isCommonFieldsEditable || isAnyInplaceActive()"
                  [active]="activeInplace[7]" (onActivate)="activateInplace(7)" (onDeactivate)="deactivateInplace(7)">
                  <ng-template pTemplate="display">
                    <span class="inline-block w-7rem"
                      (click)="showInPlaceAlreadyActive(summaryForm.get('lengthIncreament')?.value)">{{
                      summaryForm.get('lengthIncreament')?.value?? 0
                      }}</span>

                  </ng-template>
                  <ng-template pTemplate="content">
                    <!-- {{ summaryForm.get('islengthIncreamentEnabled')?.value?? false}} ** -->
                    <p-inputGroup>

                      <input type="number" min="0" max="1000" step="1" formControlName="lengthIncreament"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-5rem" />
                      <button type="button" pButton icon="pi pi-check" class="p-button-success"
                        (click)="updateLengthIncreament(true,'lengthIncreament')"></button>
                      <button type="button" pButton icon="pi pi-times" class="p-button-danger"
                        (click)="updateLengthIncreament(false,'lengthIncreament')"></button>
                    </p-inputGroup>

                  </ng-template>
                  <!-- <p-inputGroup>
                  <button type="button" pButton icon="pi pi-check" class="p-button-success"
                    (click)="updateCommonField(true,'roundingDirection')"></button>
                  <button type="button" pButton icon="pi pi-times" class="p-button-danger"
                    (click)="updateCommonField(false,'roundingDirection')"></button>
                </p-inputGroup> -->

                  <div
                    *ngIf="summaryForm.get('roundingDirection')?.invalid && summaryForm.get('roundingDirection')?.touched"
                    class="text-red-500">
                    {{ getErrorMessage('roundingDirection') }}
                  </div>
                </p-inplace>
              </div>
              <!-- ------------------------------------------------------ -->


              <div class="field col-12 md:col-6">
                <label for="rowMode">
                  Record Mode: <i class="pi pi-question-circle font-semibold  ml-2" [pTooltip]="tooltipContentRowMode"
                    severity="secondary" tooltipPosition="bottom" showDelay="50" placeholder="bottom" fitContent="true"
                    tooltipEvent="focus" showDelay="100" hideDelay="200" tabindex="0"></i>
                </label>

                <p-inplace #inplaceIsRowModeEnabled [preventClick]="!isCommonFieldsEditable || isAnyInplaceActive()"
                  [active]="activeInplace[8]" (onActivate)="activateInplace(8)" (onDeactivate)="deactivateInplace(8)">
                  <ng-template pTemplate="display">
                    <span class="inline-block w-7rem"
                      (click)="showInPlaceAlreadyActive(summaryForm.get('isRowModeEnabled')?.value)">
                      {{ summaryForm.get('isRowModeEnabled')?.value?? false ? 'Column Mode' : 'Row Mode'
                      }}
                    </span>

                  </ng-template>
                  <ng-template pTemplate="content">
                    <p-toggleButton formControlName="isRowModeEnabled" offLabel="Switch to Column Mode"
                      onLabel="Switch to Row Mode" iconPos="right" offIcon="pi pi-th-large" onIcon="pi pi-bars"
                      styleClass="small" (onChange)="onToggleRowModeEnabled($event,inplaceIsRowModeEnabled)" />
                  </ng-template>

                </p-inplace>
              </div>



              <div class="field col-12 md:col-6">
                <label for="rowMode">
                  Summary Mode: <i class="pi pi-question-circle font-semibold  ml-2"
                    [pTooltip]="tooltipContentSummaryType" severity="secondary" tooltipPosition="bottom" showDelay="50"
                    placeholder="bottom" fitContent="true" tooltipEvent="focus" showDelay="100" hideDelay="200"
                    tabindex="0"></i>
                </label>

                <p-inplace #inplaceSummaryType [preventClick]="!isCommonFieldsEditable || isAnyInplaceActive()"
                  [active]="activeInplace[9]" (onActivate)="activateInplace(9)" (onDeactivate)="deactivateInplace(9)">
                  <ng-template pTemplate="display">
                    <span class="inline-block w-7rem"
                      (click)="showInPlaceAlreadyActive(summaryForm.get('summaryType')?.value)">
                      {{ summaryForm.get('summaryType')?.value?? ''
                      }}
                    </span>

                  </ng-template>
                  <ng-template pTemplate="content">
                    <p-inputGroup>
                      <select formControlName="summaryType" id="summaryType"
                        class="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                        style="appearance: auto">
                        <option value="PUBLIC">Public</option>
                        <option value="PRIVATE">Private</option>
                        <option value="SOLD">Sold</option>
                      </select>
                      <button type="button" pButton icon="pi pi-check" class="p-button-success"
                        (click)="updateSummaryType(true,'summaryType')"></button>
                      <button type="button" pButton icon="pi pi-times" class="p-button-danger"
                        (click)="updateSummaryType(false,'summaryType')"></button>
                    </p-inputGroup>

                  </ng-template>
                </p-inplace>
              </div>
            </div>

            <!-- ***** -->
            <ng-template #newTallySheet>
              <p-card>

                <div class="formgrid grid">
                  <div class="field col-12 md:col-6">
                    <label for="containerNumber">Container Number:</label>
                    <!--  <input formControlName="containerNumber" id="containerNumber" type="text"
                  [readOnly]="!isCommonFieldsEditable" (click)="showAlertIfContainerNumberReadonly($event)"
                  (keydown)="showAlertIfContainerNumberReadonly($event)"
                  class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"> -->
                    dd <p-autoComplete formControlName="container" [suggestions]="filteredContainerNumbers"
                      (completeMethod)="filterContainerNumbers($event)" optionLabel="containerNumber" [dropdown]="true"
                      [forceSelection]="true"
                      styleClass="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                    <div
                      *ngIf="summaryForm.get('containerNumber')?.invalid && summaryForm.get('containerNumber')?.touched"
                      class="text-red-500">
                      {{ getErrorMessage('containerNumber') }}
                    </div>

                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="typeOfShipment">Type of Shipment</label>
                    <p-inputGroup>
                      <select formControlName="typeOfShipment" id="typeOfShipment"
                        class="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                        style="appearance: auto">
                        <option value="Round">Round</option>
                        <option value="Square">Square</option>
                      </select>

                    </p-inputGroup>
                    <div
                      *ngIf="summaryForm.get('typeOfShipment')?.invalid && summaryForm.get('typeOfShipment')?.touched"
                      class="text-red-500">
                      {{ getErrorMessage('typeOfShipment') }}
                    </div>

                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="girthAllowance">Girth Allowance:</label>
                    <input formControlName="girthAllowance" id="girthAllowance" type="number" min="0" max="100" step="1"
                      class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    <div
                      *ngIf="summaryForm.get('girthAllowance')!.invalid && summaryForm.get('girthAllowance')!.touched"
                      class="text-red-500">
                      {{ getErrorMessage('girthAllowance') }}
                    </div>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="city">Girth Measurement Unit:</label>
                    <select formControlName="uomGirth" id="uomGirth"
                      class="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                      style="appearance: auto">
                      <option value="mm">mm</option>
                      <option value="cm">cm</option>
                      <option value="in">in</option>
                      <option value="ft">ft</option>
                      <option value="meter">meter</option>
                    </select>
                    <div *ngIf="summaryForm.get('uomGirth')?.invalid && summaryForm.get('uomGirth')?.touched"
                      class="text-red-500">
                      {{ getErrorMessage('uomGirth') }}
                    </div>
                  </div>


                  <div class="field col-12 md:col-6">
                    <label for="lengthAllowance">Length Allowance:</label>
                    <input formControlName="lengthAllowance" id="lengthAllowance" type="number" min="0" max="100"
                      step="1"
                      class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    <div
                      *ngIf="summaryForm.get('lengthAllowance')?.invalid && summaryForm.get('lengthAllowance')?.touched"
                      class="text-red-500">
                      {{ getErrorMessage('lengthAllowance') }}
                    </div>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="city">Length Measurement Unit:</label>
                    <select formControlName="uomLength" id="uomLength"
                      class="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                      style="appearance: auto">
                      <option value="mm">mm</option>
                      <option value="cm">cm</option>
                      <option value="in">in</option>
                      <option value="ft">ft</option>
                      <option value="meter">meter</option>
                    </select>
                    <div *ngIf="summaryForm.get('uomLength')?.invalid && summaryForm.get('uomLength')?.touched"
                      class="text-red-500">
                      {{ getErrorMessage('uomLength') }}
                    </div>
                  </div>

                  <div class="field col-12 md:col-6">
                    <label for="decimalPlaces">Decimal Places:</label>
                    <input type="number" formControlName="decimalPlaces" value="3" min="0" max="6"
                      class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    <div *ngIf="summaryForm.get('decimalPlaces')?.invalid && summaryForm.get('decimalPlaces')?.touched"
                      class="text-red-500 ">
                      {{ getErrorMessage('decimalPlaces') }}
                    </div>
                  </div>


                  <div class="field col-12 md:col-6">
                    <label for="city">Rounding-Off Direction:</label>
                    <select formControlName="roundingDirection"
                      class="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary">
                      <option value="Up">Up</option>
                      <option value="Down">Down</option>
                    </select>
                    <div
                      *ngIf="summaryForm.get('roundingDirection')?.invalid && summaryForm.get('roundingDirection')?.touched"
                      class="text-red-500">
                      {{ getErrorMessage('roundingDirection') }}
                    </div>
                  </div>


                  <div class="field col-12 md:col-6">
                    <label for="isRowModeEnabled">{{ summaryForm.get('isRowModeEnabled')?.value?? false ? 'Record
                      Mode:
                      Column Mode' :
                      'Record Mode: Row Mode'
                      }} <i class="pi pi-question-circle font-semibold  ml-2" [pTooltip]="tooltipContentRowMode"
                        severity="secondary" tooltipPosition="bottom" showDelay="50" placeholder="bottom"
                        fitContent="true" tooltipEvent="focus" showDelay="100" hideDelay="200" tabindex="0"></i></label>
                    <div>
                      <p-toggleButton formControlName="isRowModeEnabled" offLabel="Switch to Column Mode"
                        class="w-full text-base text-color surface-overlay p-2 outline-none focus:border-primary"
                        onLabel="Switch to Row Mode" iconPos="right" offIcon="pi pi-th-large" onIcon="pi pi-bars"
                        styleClass="h-2rem text-sm" (onChange)="onToggleRowModeEnabled($event)" />

                    </div>
                  </div>

                </div>





                <ng-template pTemplate="footer">
                  <div class="flex gap-3 mt-1">
                    <p-button label="Clear" severity="secondary" class="w-full" icon="pi pi-eraser"
                      (onClick)="summaryForm.reset();summaryForm.markAllAsTouched()" />
                    <p-button label="Save" icon="pi pi-save" [loading]="isSaveSummaryInProgress"
                      (onClick)="saveSummary()" [disabled]="!summaryForm.valid" />
                  </div>
                  <p *ngIf="!summaryForm.valid" class="text-red-500"><i
                      class="pi pi-exclamation-circle font-medium "></i>
                    Some fields are either invalid or left blank. Please ensure all fields are correctly filled out to
                    enable
                    the
                    Save button.
                  </p>
                </ng-template>
              </p-card>

              <!-- <div>

      <p-button label="Save Summary" icon="pi pi-save" [loading]="isSaveSummaryInProgress" (onClick)="saveSummary()"
        [disabled]="!summaryForm.valid" />

    </div> -->
            </ng-template>
          </div>
          <p-progressBar mode="indeterminate" [style]="{ height: '26px' }" *ngIf="showProgressBar$ | async" />

        </form>
      </div>
    </p-card>
  </p-sidebar>

  <!-- <p-accordionTab header="Tallysheet Settings" #tab1> -->

  <!-- </p-accordionTab> -->

  <form [formGroup]="recordsForm" #tallyCard class="m-0 p-0">
    <p-card class="m-0 p-0">
      

      <div (scroll)="onScroll($event)" style="overflow-y: auto; height: 55vh;" class="table-container m-0 p-0">
         
        <table *ngIf="summaryId; else noSummary" style="table-layout: fixed;" class="min-content lg:w-full">
          
          <thead style="position: sticky; top: 0; background-color: white; z-index: 1 !important;">

            <tr> 
                <th [attr.colspan]="4 + (recordsForm.get('selectedColumnNames').value?.length || 0) * 2 + (editModeChecked ? 1 : 0)">
                <p-toolbar class="w-full mb-1 sm:mb-4 gap-2" [ngClass]="{'justify-content-end': isMobile, 'justify-content-between': !isMobile}">
                  <ng-template pTemplate="left">
                    <p-multiSelect [maxSelectedLabels]="isMobile ? 1 : 3" 
                    selectedItemsLabel="{0} Options Selected" [showHeader]="false"
                    [options]="tableColumnNames"
                      formControlName="selectedColumnNames" optionLabel="name" placeholder="Select Headers"
                      (onChange)="onSelectionChange($event)" appendTo="body" variant="filled" display="chip">
                    </p-multiSelect>
                  </ng-template>
                  <ng-template pTemplate="right">
                    <p-button (click)="saveTallySheetDimentions()" *ngIf="editModeChecked && !isMobile" icon="pi pi-save"
                      class="mr-2"   />
                    <p-button (click)="onEditModeButtonClick()" [icon]="changeToModeIcon" class="mr-2" *ngIf="!isMobile" />
                    <p-button (onClick)="tallysheetSettingsSidebar = true" icon="pi pi-cog" class="mr-2" *ngIf="!isMobile"></p-button>
                    <p-button (onClick)="menu1.toggle($event)" icon="pi pi-ellipsis-v"    />
                    <p-menu #menu1 [model]="speedDialMenuItems" [popup]="true" appendTo="body" />
                  </ng-template>
                </p-toolbar>
              </th>
            </tr>
            <tr>
              <th rowspan="2" class="p-2">No</th>
              <th rowspan="2" class="p-2">SR</th>

              <th colspan="2">Gross Dimensions</th>
              <th colspan="2" *ngIf="isColumnSelected('NetDimensions')">Net Dimensions</th>
              <th colspan="2" *ngIf="isColumnSelected('GrossVolume')">Gross Volume</th>
              <th colspan="2" *ngIf="isColumnSelected('NetVolume')">Net Volume</th>
              <th rowspan="2" *ngIf="editModeChecked" style="width: 295px">Actions</th>
            </tr>
            <tr>
              <th class="p-2"> Length</th>
              <th class="p-2">Girth</th>
              <th class="p-2" *ngIf="isColumnSelected('NetDimensions')"> Length</th>
              <th class="p-2" *ngIf="isColumnSelected('NetDimensions')">Girth</th>
              <th class="p-2" style="width: 95px" *ngIf="isColumnSelected('GrossVolume')">CBM</th>
              <th class="p-2" style="width: 95px" *ngIf="isColumnSelected('GrossVolume')">CFT</th>
              <th class="p-2" style="width: 95px" *ngIf="isColumnSelected('NetVolume')">CBM</th>
              <th class="p-2" style="width: 95px" *ngIf="isColumnSelected('NetVolume')">CFT</th>
            </tr>
          </thead>

          <tbody formArrayName="tallySheetRecordsArray">
            <ng-container
              *ngIf="tallySheetRecordsArray.controls.length>0; then thenBlock; else elseBlock"></ng-container>
            <ng-template #thenBlock>

              <!-- tr *ngFor="let record of tallySheetRecordsArray.controls; let i = index" [formGroupName]="i"
                    [id]="'row-' + i" (focus)="onFieldFocus(i)" (blur)="onFieldBlur()" tabindex="0"
                    [ngClass]="{'p-highlight': focusedRowIndex === i}" class="p-2" pAnimateOnScroll enterClass="scalein"
                    leaveClass="fadeout" -->

              <!-- tr *ngFor="let record of tallySheetRecordsArray.controls; let i = index" [formGroupName]="i"
                    [id]="'row-' + i" (focus)="onFieldFocus(i)" (blur)="onFieldBlur()" tabindex="0"
                    [ngClass]="{'fadeoutright animation-duration-1000': deleteRowLoading[i], 'p-highlight': focusedRowIndex === i}"
                    pAnimateOnScroll enterClass="scalein" leaveClass="fadeout" -->

              <!-- tr *ngFor="let record of tallySheetRecordsArray.controls; let i = index" [formGroupName]="i"
                    [id]="'row-' + i" (focus)="onFieldFocus(i)" (blur)="onFieldBlur()" tabindex="0" 
                    [ngClass]="{ 'fadeoutright animation-duration-1000': deleteRowLoading[i],  
                    'p-highlight': focusedRowIndex === i, 
                    'fadein animation-duration-1000': addRowAnimation[i]}"
                     pAnimateOnScroll enterClass="flipup" leaveClass="fadeout" -->
              <tr *ngFor="let record of tallySheetRecordsArray.controls; let i = index" [formGroupName]="i"
                [id]="'row-' + i" (focus)="onFieldFocus(i)" (blur)="onFieldBlur()" tabindex="0" [ngClass]="{ 
        'fadeoutright animation-duration-500': deleteRowLoading[i],  
        'p-highlight': focusedRowIndex === i, 
        'fadein animation-duration-500': addRowAnimation[i] && !rowAnimationPlayed[i]
    }" (animationend)="onAnimationEnd(i)" pAnimateOnScroll enterClass="flipup" leaveClass="fadeout">

                <!-- 1. enterClass="flipup" leaveClass="fadeout"
                      2. enterClass="zoomin" leaveClass="fadeout"
                      3. enterClass="scalein" leaveClass="fadeout" -->

                <input type="text" formControlName="isPreviousRowCopy" *ngIf="false" />

                <input type="text" formControlName="id" *ngIf="false" />
                <td style="text-align: center;">
                  <p-badge *ngIf="focusedRowIndex === i" [value]="i + 1"></p-badge>
                  <span *ngIf="focusedRowIndex !== i">{{ i + 1 }}</span>
                </td>
                <td style="text-align: center;">
                  <span>{{ record.get('serialNumber')?.value }}</span>
                </td>
                <td *ngIf="editModeChecked; else viewModeField">

                  <input formControlName="length" #lengthInput type="number"
                    (focus)="recordHistoryFn(record.value);selectValueIfExist($event)"
                    [readonly]="addRowLoading[i] || deleteRowLoading[i] || !isEditable[i]"
                    [ngClass]="{'ng-invalid-border': record?.get('length')?.invalid?? false && record?.get('length')?.touched?? false}"
                    style="width: var(--input-width-tallysheet);"
                    [min]="summaryForm?.get('lengthAllowance')?.value??  2" max="10000"
                    class="text-color surface-overlay border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary"
                    (focus)="scrollToField($event.target);onFieldFocus(i)" (blur)="onFieldBlur()" />
                  <div *ngIf="record?.get('length')?.invalid?? false && record?.get('length')?.touched?? false"
                    class="invalid-feedback">
                    This field can't be left blank.
                  </div>
                </td>
                <ng-template #viewModeField>
                  <td class="text-center">
                    {{record?.get('length')?.value?? ''}}
                  </td>
                </ng-template>
                <td *ngIf="editModeChecked; else viewModeGirth">
                  <input type="number" formControlName="girth"
                    [readOnly]="addRowLoading[i] || deleteRowLoading[i] || !isEditable[i]"
                    (focus)="recordHistoryFn(record.value);selectValueIfExist($event);onFieldFocus(i)"
                    (blur)="onFieldBlur()"
                    [ngClass]="{'ng-invalid': record?.get('girth')?.invalid?? false && record?.get('girth')?.touched?? false}"
                    style="width: var(--input-width-tallysheet);" [min]="summaryForm?.get('girthAllowance')?.value??  2"
                    max="10000"
                    class="text-base text-color surface-overlay border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary" />
                  <div *ngIf="record?.get('girth')?.invalid?? false && record?.get('girth')?.touched?? false"
                    class="invalid-feedback">
                    This field can't be left blank.
                  </div>
                </td>
                <ng-template #viewModeGirth>
                  <td class="text-center">
                    {{record?.get('girth')?.value??''}}
                  </td>
                </ng-template>
                <td class="text-center" *ngIf="isColumnSelected('NetDimensions')">{{ record.get('netLength')?.value
                  }}
                </td>

                <td class="text-center" *ngIf="isColumnSelected('NetDimensions')">{{ record.get('netGirth')?.value
                  }}
                </td>
                <td class="text-center" *ngIf="isColumnSelected('GrossVolume')">{{
                  record.get('grossVolumeCbm')?.value.toFixed(this.summaryForm?.value.decimalPlaces ?? 3) }}</td>

                <td class="text-center" *ngIf="isColumnSelected('GrossVolume')">{{
                  record.get('grossVolumeCft')?.value.toFixed(this.summaryForm?.value.decimalPlaces ?? 3) }}</td>

                <td class="text-center" *ngIf="isColumnSelected('NetVolume')">{{
                  record.get('netVolumeCbm')?.value.toFixed(this.summaryForm?.value.decimalPlaces ?? 3) }}</td>
                <td class="text-center" *ngIf="isColumnSelected('NetVolume')">{{
                  record.get('netVolumeCft')?.value.toFixed(this.summaryForm?.value.decimalPlaces ?? 3) }}</td>
                <!-- <td class="text-center" *ngIf="editModeChecked">
                  <p-button icon="pi pi-plus-circle" class="custom-button" [loading]="addRowLoading[i]"
                    (click)="addRow(i, $event)" size="small" id="i" [rounded]="true" [text]="true" severity="success" />
                  <p-button icon="pi pi-minus-circle" class="custom-button" [loading]="deleteRowLoading[i]" id="i"
                    size="small" (click)="deleteRow(i, $event)" [rounded]="true" [text]="true" severity="danger" />
                  <p-button icon="pi pi-exclamation-circle" class="custom-button" id="i" size="small"
                    (click)="onRowBlur(i, $event)" *ngIf="record.get('isNew')?.value === true" [rounded]="true"
                    [text]="true" severity="warning" />
                </td> -->
                <td class="text-center" *ngIf="editModeChecked">
                  <div class="flex align-items-center gap-2"> <!-- PrimeFlex grid utility -->
                    <p-button icon="pi pi-plus-circle" class="custom-button" [loading]="addRowLoading[i]"
                      (click)="addRow(i, $event)" size="small" id="i" [rounded]="true" [text]="true"
                      severity="success"></p-button>

                    <p-button icon="pi pi-minus-circle" class="custom-button" [loading]="deleteRowLoading[i]" id="i"
                      size="small" (click)="deleteRow(i, $event)" [rounded]="true" [text]="true"
                      severity="danger"></p-button>

                    <p-button icon="pi pi-exclamation-circle" class="custom-button" id="i" size="small"
                      (click)="onRowBlur(i, $event)" *ngIf="record.get('isNew')?.value === true" [rounded]="true"
                      [text]="true" severity="warning"></p-button>
                  </div>
                </td>

              </tr>
            </ng-template>
            <ng-template #elseBlock>
              <!-- Content to display when 'condition' is false -->
              <!-- <input type="button" (click)="addZeroRow()" value="Add Row" /> -->
              <!-- <p-button  value="Add Row" icon="pi pi-plus" /> -->
              <p-button (click)="addZeroRow()" label="Add Row" icon="pi pi-plus" iconPos="left" size="small" />


            </ng-template>
          </tbody>
          <tfoot>
            <tr class="bg-green-100">
              <!-- Adjust colspan dynamically for the "Totals" label -->
              <td [attr.colspan]="calculateColspanForTotals()" class="text-center font-bold text-xl">Totals</td>

              <!-- Total Pieces column -->
              <td style="text-align: right;">
                <!-- {{summaryForm?.value?.totalPieces ?? 0}}
                    <i class="pi pi-exclamation-circle font-medium" style="color: black" *ngIf="editModeChecked"
                      pTooltip="Total non-blank records" tooltipEvent="focus"></i> --!>
                  </td>
  
                  <!-- Gross Volume totals -->
              <td *ngIf="isColumnSelected('GrossVolume')" class="text-center font-bold text-xl"> {{
                calculateTotal('grossVolumeCbm').toFixed(this.summaryForm?.value.decimalPlaces ?? 3) }}</td>
              <td *ngIf="isColumnSelected('GrossVolume')" class="text-center font-bold text-xl"> {{
                calculateTotal('grossVolumeCft').toFixed(this.summaryForm?.value.decimalPlaces ?? 3) }}</td>

              <!-- Net Volume totals -->
              <td *ngIf="isColumnSelected('NetVolume')" class="text-center font-bold text-xl"> {{
                calculateTotal('netVolumeCbm').toFixed(this.summaryForm?.value.decimalPlaces ?? 3) }}</td>
              <td *ngIf="isColumnSelected('NetVolume')" class="text-center font-bold text-xl"> {{
                calculateTotal('netVolumeCft').toFixed(this.summaryForm?.value.decimalPlaces ?? 3) }}</td>

              <!-- Empty td for actions if in edit mode -->
              <td *ngIf="editModeChecked"></td>
            </tr>
          </tfoot>



        </table>


      </div>
      <ng-template #noSummary>
        <!-- Render something else when summaryId is falsy -->
        <p>Please save settings first to start the tally sheet.</p>
        <p-button (click)="addZeroRow()" label="Add Row" icon="pi pi-plus" iconPos="left" size="small"
          *ngIf="summaryId" />

      </ng-template>

      <!-- <input type="button" (click)="printRecords()" value="Print Records"> -->
      <!-- <input type="button" (click)="trackChanges()" value="Track Changes"> -->
    </p-card>
  </form>


  <!-- <input type="button" (click)="generateSerial()" value="Get Serial" /> -->


  <p-toast />
  <p-confirmDialog #cd>
    <ng-template let-message>
      <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
        <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem">
          <i class="pi pi-question text-5xl"></i>
        </div>
        <span class="font-bold text-2xl block mb-2 mt-4">
          {{ message.header }}
        </span>
        <p class="mb-0">{{ message.message }}</p>
        <div class="flex align-items-center gap-2 mt-4">
          <button pButton label="Cancel" (click)="cd.reject()" class="p-button-outlined w-8rem ">
          </button>
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>

 

  <p-dialog [(visible)]="displaySummaryIdInputDialog" [modal]="true" [header]="'Enter Summary ID'" [closable]="false"
    [dismissableMask]="false">
    <div class="p-fluid">
      <div class="p-field">
        <label for="summaryId">Summary ID</label>
        <input id="summaryId" type="text" pInputText [(ngModel)]="summaryId" (keydown.enter)="setSummaryId()">
      </div>
      <p-button *ngIf="summaryId" label="Create a new Summary" [link]="true"
        (click)="confirmCreateNewSummary($event)" />
      <p-confirmPopup key="confirmPopupCreateNewSummary" />

    </div>
    <p-footer>
      <button pButton type="button" label="Open" icon="pi pi-check" (click)="setSummaryId()"></button>
      <button pButton type="button" label="Close" icon="pi pi-times" class="p-button-secondary"
        (click)="displaySummaryIdInputDialog=false"></button>
    </p-footer>
  </p-dialog>

  <p-dialog [(visible)]="displayGoToRowNoInputDialog" [modal]="true" [header]="'Enter Row Number'" [closable]="false"
    [dismissableMask]="false">
    <div class="p-fluid">
      <div class="p-field">
        <label for="rowNo">Row No</label>
        <p-inputNumber id="rowNo" type="number" pInputText [(ngModel)]="rowNo" inputmode="numeric" pattern="[0-9]*"
          step="1" min="1" [max]="this.tallySheetRecordsArray.length"
          (keydown.enter)="goToRow(''+rowNo)"></p-inputNumber>
      </div>
    </div>
    <p-footer>
      <button pButton type="button" label="Ok" icon="pi pi-check" (click)="goToRow(''+rowNo)"></button>
      <button pButton type="button" label="Cancel" icon="pi pi-times" class="p-button-secondary"
        (click)="displayGoToRowNoInputDialog=false;"></button>
    </p-footer>
  </p-dialog>


  <!-- input type="button" (click)="loadAll()" value="All Records" />
    <input type="button" (click)="saveTallySheetDimentions()" value="New Save" />
    <input type="button" (click)="downloadTallySummary(summaryId)" value="Download Excel" />
    <input type="button" (click)="checkIfAnyFieldIsDirty()" value="alert(checkIfAnyFieldIsDirty())" />
    <button (click)="navigateToRoute()">Go to Another Route</button>
    <button (click)="testPrisineAndDirty()">checkPrisineAndDirty</button>
    <button pButton type="button" label="Open SummaryId" (click)="openSummaryIdInputDialog()" size="small"></button>
    <button pButton type="button" label="Open GotoRow Number" (click)="openGoToRowNoDialog()" size="small"></button -->
</div>

<ng-template #tooltipContentRowMode>
  <p> Row Mode: Focus will move down to the next row</p>
  <p> Column Mode: Focus will move across to the next column</p>
</ng-template>

<ng-template #tooltipContentLengthIncreament>
  <p>Length Increment: The value entered in the tally record length will have the increment value added to it.</p>
  <p>Example: If you set the length increment to 200 and enter 30 as the length in a tally record, it will automatically
    become 230.</p>
</ng-template>


<ng-template #tooltipContentSummaryType>
  <p><strong>Summary Type:</strong></p>
  <p><strong>Public:</strong> The tally sheet can be shared via a URL, and anyone can view it in view-only mode.</p>
  <p><strong>Private:</strong> Only you and your team members can access this tally sheet.</p>
  <p><strong>Sold:</strong> If the tally sheet URL is accessed, it will be displayed in view mode and labeled as SOLD.
  </p>
</ng-template>


<ng-template #tooltipContentCopyPreviousRow class="w-5rem">
  <p>Copy Previous Row: If enabled, the length value from the previous row will be automatically copied to the new row.
  </p>
  <p>This helps when you want to use the same length for consecutive rows without re-entering the value manually.</p>
</ng-template>

<p-confirmDialog #cd2 key="modeButtonChangeConfirm">
  <ng-template pTemplate="headless" let-message>
    <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
      <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem">
        <i class="pi pi-question text-5xl"></i>
      </div>
      <span class="font-bold text-2xl block mb-2 mt-4">
        {{ message.header }}
      </span>
      <p class="mb-0">{{ message.message }}</p>
      <div class="flex align-items-center gap-2 mt-4">
        <button pButton label="Save" (click)="cd2.accept()" class="w-8rem">
        </button>
        <button pButton label="Cancel" (click)="cd2.reject()" class="p-button-outlined w-8rem ">
        </button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>