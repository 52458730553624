import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, filter } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { ContainerNumbers } from 'src/app/modules/container/model/container-numbers';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';

@Component({
  selector: 'app-sawn-timber',
  templateUrl: './sawn-timber.component.html',
  styleUrls: ['./sawn-timber.component.css'],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class SawnTimberComponent implements OnInit {
 
  protected breadCrumbitems!: MenuItem[];
  home!: MenuItem;
  formHeader!: string;
  formSubHeader!: string;
  stepMenu!: MenuItem[];
  activeIndex: number = 0;
  private selectedContainer$: BehaviorSubject<ContainerNumbers | null> =
  new BehaviorSubject<ContainerNumbers | null>(null);
  
  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private router: Router
  ){
    this.home = { icon: 'pi pi-home', routerLink: '/dashboard' };
  }

  ngOnInit(): void {
   this.populateBreadCrumb();
   this.setPageTitle();
   this.initSteps();
   this.clearStroageOnNavigatingOutside();
  }

  private populateBreadCrumb() {
    this.breadCrumbitems = [
      { label: 'Container', routerLink: '/timber' },
      { label: 'Measurements' },
    ];
  }

  private setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }

  private initSteps() {
    this.selectedContainer$.subscribe((container) => {
      // Update the items array whenever the container changes
      this.stepMenu = [
        {
          label: 'Container',
          routerLink: 'container'

          /* command: (event: any) => {
            this.activeIndex = 0;
          }, */
        },
        {
          label: 'Measurement',
          routerLink: 'pl',
          disabled: !(container && container.containerId),
           
        },
      ];
    });
  }

  clearStroageOnNavigatingOutside(){
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (!event.urlAfterRedirects.startsWith('/timber')) {
        // localStorage.removeItem(AppConstants.CONTAINER);
      }
    });
  }

}
