<router-outlet></router-outlet><!--  -->
<div class="custom-card">
    <!-- <p-toast></p-toast> -->
    <p-confirmDialog header="Product Deletion Restriction" icon="pi pi-exclamation-triangle" [style]="{width: '50rem'}" [rejectVisible]="false" [acceptLabel]="'OK'"></p-confirmDialog>
    <p-confirmDialog   icon="pi pi-exclamation-triangle" [style]="{width: '50rem'}" [rejectVisible]="true" key="deleteConfirmation"></p-confirmDialog>

    <div class="pb-3">
        <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
    </div>

    <p-card [header]="formHeader" [subheader]="formSubHeader" class="w-full">
        <p-speedDial [model]="productSpeedDialItems" direction="up-left" type="quarter-circle" [radius]="120"
            class="ml-5 my-speed-dial" [rotateAnimation]="true"></p-speedDial>
        <div>
            <!-- [tableStyle]="{'min-width': '50rem'}" -->
            <p-table #dt [lazy]="true" [value]="products" (onLazyLoad)="sortProducts($event)" [rowHover]="true"
                [customSort]="true" sortMode="multiple" dataKey="productId"
                [globalFilterFields]="['name','hsCoe','description']" [showCurrentPageReport]="true" [rows]="rows"
                [rowsPerPageOptions]="rowsPerPageOptions" [paginator]="true" [first]="first"
                currentPageReportTemplate="Showing {{first}} to {{last}} of {{totalRecords}} entries"
                [totalRecords]="totalRecords" [columns]="cols" [(selection)]="selectedProducts"
                (onRowSelect)="onRowSelect($event)" [styleClass]="tableStyle" [tableStyle]="tableStyleOptions"
                [loading]="loading" paginatorDropdownAppendTo="body">


                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-between">
                        <!-- <p-button [label]="isMobile ? '' : 'Clear'"
                    styleClass="p-button-outlined sm:p-button-sm md:p-button-md mr-1" icon="pi pi-filter-slash"
                    (click)="clear(dt)" class="clear-button">
                </p-button>

                <p-button [label]="isMobile ? '' : 'Delete'"
                    styleClass="p-button-outlined sm:p-button-sm md:p-button-md" icon="pi pi-trash"
                    (click)="deleteRows()" label="Delete" class="clear-button"></p-button> -->
                        <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" class="multiselectfields"
                            selectedItemsLabel="{0} Selected" placeholder="Choose Columns" defaultLabel="Choose Columns"
                            [maxSelectedLabels]="2"> </p-multiSelect>
                        <span class="p-input-icon-left">
                            <div class="p-inputgroup">
                                <input pInputText type="text" (input)="globalFilter($event,dt)" #globalFiterInput
                                    class="searchglobally" placeholder="Search keyword" />
                                <button type="button" pButton icon="pi pi-filter-slash" styleClass="p-button-warn"
                                    (click)="clearAllFilter(dt,globalFiterInput)"></button>
                            </div>
                        </span>
                    </div>
                </ng-template>

                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <!-- style="width: 4rem" -->
                        <th>
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="name" p-toggleableColumn>Name
                            <p-columnFilter type="text" field="name" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                            <p-sortIcon field="name"></p-sortIcon>
                        </th>
                        <th pSortableColumn="hsCode" *ngIf="_selectedColumns!.includes('HS Code')">HS Code
                            <p-columnFilter type="text" field="hsCode" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                            <p-sortIcon field="supplierId"></p-sortIcon>
                        </th>
                        <th *ngIf="_selectedColumns!.includes('Description')">Description
                            <p-columnFilter type="text" field="description" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                        </th>
                        <!-- <th *ngIf="_selectedColumns!.includes('Formula')">Formula
                    <p-columnFilter type="text" field="formula" display="menu" matchMode="contains"
                        [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                </th> -->
                        <th style="min-width:15rem" *ngIf="_selectedColumns!.includes('Formula')">
                            <div class="flex align-items-center">
                                Formula
                                <p-columnFilter field="formula" matchMode="in" display="menu" [showMatchModes]="false"
                                    [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="header">
                                        <div class="px-3 pt-3 pb-0">
                                            <span class="font-bold">Formula Picker</span>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="formulas" placeholder="Any"
                                            (onChange)="filter($event.value)" optionLabel="value">
                                            <ng-template let-option pTemplate="item">
                                                <div class="inline-block vertical-align-middle">
                                                    <span class="ml-1 mt-1">{{ option.value }}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>

                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-products let-columns="columns">
                    <!-- $event.target.tagName !== 'BUTTON' ? row : null) -->
                    <tr [pSelectableRow]="columns" (click)="onRowClick(products)" p-selectable-row>
                        <!-- <tr [pSelectableRow]="columns" (click)="onRowClick(seller)" p-selectable-row> -->
                        <td (click)="$event.stopPropagation()">
                            <p-tableCheckbox [value]="products" (click)="$event.stopPropagation()"></p-tableCheckbox>
                        </td>
                        <!--   <td *ngIf="showNameColumn"><a href="#" target="_blank">{{buyer.name}}</a></td> -->
                        <td> {{products.name}}</td>
                        <!-- <td *ngIf="_selectedColumns!.includes('Supplier')">1</td> -->
                        <td *ngIf="_selectedColumns!.includes('HS Code')">{{products.hsCode}}</td>
                        <td *ngIf="_selectedColumns!.includes('Description')">{{products.description}}</td>
                        <td *ngIf="_selectedColumns!.includes('Formula')">
                            <!-- {{products | json}} -->
                            {{ getFormulaName(products.formula) }}

                        </td>

                    </tr>
                </ng-template>

                <!--  <ng-template pTemplate="paginatorleft">
            <p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button>
        </ng-template>
        <ng-template pTemplate="paginatorright">
            <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button>
        </ng-template> -->
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <!-- <td colspan="(_selectedColumns?.length ?? 6) + 2">No records found.</td> -->
                        <td [attr.colspan]="(_selectedColumns ? _selectedColumns.length + 2 : 4)" class="text-center">No
                            records found.</td>

                    </tr>
                </ng-template>
                <ng-template pTemplate="loadingbody" let-columns="columns">
                    <tr style="height:46px">
                        <td>
                            <p-skeleton></p-skeleton>
                        </td>
                        <td> <p-skeleton></p-skeleton> </td>
                        <td *ngIf="_selectedColumns!.includes('HS Code')"> <p-skeleton></p-skeleton></td>
                        <td *ngIf="_selectedColumns!.includes('Description')"> <p-skeleton></p-skeleton></td>
                        <td *ngIf="_selectedColumns!.includes('Formula')"></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-card>
</div>
<p-sidebar [(visible)]="productSidebarVisible" position="right" [style]="{'width': '50vw'}" (onHide)="setPageTitle()">
    <app-product *ngIf="productSidebarVisible" [showBreadcrumb]="false" [isSubmittedFromSidebar]="true"
        (productSubmit)="onProductSubmit($event)" titleFromSidebar="Add New Product" headerFromSidebar="Create Product"
        subHeaderFromSidebar="Input new product details"></app-product>
</p-sidebar>

<!-- <button pButton icon="pi pi-eye" (click)="showNameColumn = !showNameColumn"
    [label]="showNameColumn ? 'Hide Name' : 'Show Name'"></button> -->

<!-- <button pButton icon="pi pi-eye" (click)="getSelectedProducts()" label="Get Selected Products"></button> -->