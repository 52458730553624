import { environment } from '../../../src/environments/environment';


export class AppConstants {
  public static WEBSITE_URL = environment.webUrl; 

  private static API_BASE_URL = environment.apiUrl; 
  private static OAUTH2_URL = AppConstants.API_BASE_URL + "oauth2/authorize/";
  private static REDIRECT_URL = "redirect_uri=" + environment.redirectUrl;
  private static FORCE_REDIRECT_URL = "redirect_uri=" + environment.forceRedirectUrl;

  public static API_URL = AppConstants.API_BASE_URL; 
  public static AUTH_API = AppConstants.API_URL + "auth/";
  // public static GOOGLE_AUTH_URL = AppConstants.OAUTH2_URL + "google?forceLogin=false&" + AppConstants.REDIRECT_URL + "&method=google";
  public static GOOGLE_AUTH_URL_FORCE = AppConstants.OAUTH2_URL + "google?forceLogin=true&" + AppConstants.FORCE_REDIRECT_URL + "&method=google";
  public static GOOGLE_AUTH_URL = AppConstants.GOOGLE_AUTH_URL_FORCE;// AppConstants.OAUTH2_URL + "google?forceLogin=false&" + AppConstants.REDIRECT_URL + "&method=google";

  public static MICROSOFT_AUTH_URL = AppConstants.OAUTH2_URL + "microsoft?forceLogin=false&" + AppConstants.REDIRECT_URL + "&method=microsoft";
  public static MICROSOFT_AUTH_URL_FORCE = AppConstants.OAUTH2_URL + "microsoft?forceLogin=false&" + AppConstants.FORCE_REDIRECT_URL + "&method=microsoft";


  public static LINKEDIN_AUTH_URL = AppConstants.OAUTH2_URL + "linkedin?" + AppConstants.REDIRECT_URL + "&method=linkedin";
  public static LINKEDIN_AUTH_URL_FORCE = AppConstants.OAUTH2_URL + "linkedin?forceLogin=true&" + AppConstants.FORCE_REDIRECT_URL + "&method=linkedin";
  
  public static SOCIAL_PROVIDER_GOOGLE = "google";
  public static SOCIAL_PROVIDER_MICROSOFT = "microsoft";
  public static SOCIAL_PROVIDER_LINKEDIN = "linkedin";

  // public static USER_KEY = 'user';

  // public static CURRENT_USER_KEY = 'current_user';

  public static PROMOTOER_CODE = 'promoter_code';
  public static USER = 'user';
  public static REDIRECT_SNAPSHOT_URL = 'redirect_url';

  public static DASHBOARD = '/';

  public static FONT_SIZE ='font-size';

  public static THEME ='theme';

  public static CONTAINER ='container';

  public static VIEW ='view';

  public static PUBLIC_URL_HOPPUS = '/public/view/measurement/';  
 
  public static PUBLIC_URL_VOLUMETRIC =  '/public/view/sawn-timber/';

  public static MEASUREMENT =  'measurement';

  public static PUBLIC_URL_BL =   '/public/view/bill-of-ladings/' ;

  // public static PUBLIC_URL_CONTAINER = '/public/view/measurement/'
  public static PUBLIC_URL_FORUMLA_1_CONTAINER = '/public/view/measurement/';
  public static PUBLIC_SHORT_URL_FORUMLA_1_CONTAINER = '/public/view/measurement/';

  public static PUBLIC_URL_FORUMLA_2_CONTAINER = '/public/view/sawn-timber/';

  public static HOPPUS_NAME ='Hoppus';

  public static RECTANGLE_PRISM_NAME ='Volumetric';

  public static SHOW_MENU_HOT_KEY = 'm'

  public static MAX_NUMBER_OF_IMAGES_UPLOAD = 5;

  public static MAX_IMAGE_SIZE_IN_MB = 5;

  public static BL_VIEW ='bl_view';

  public static SHIPMENT_COUNT = `${AppConstants.API_URL}openforall/api/v1/bills-of-lading/count`

  public static CONTAINERS_COUNTS =`${AppConstants.API_URL}openforall/api/v1/containers/count` 

  public static SHIPMENT_ARRIVING_COUNT = `${AppConstants.API_URL}openforall/api/v1/bills-of-lading/count-eta`

  public static SHIPMENT_DEPARTING_COUNT = `${AppConstants.API_URL}openforall/api/v1/bills-of-lading/count-etd`

  public static MONTHLY_SHIPMENT = `${AppConstants.API_URL}openforall/api/v1/bills-of-lading/monthly-counts`

  public static MONTHLY_CONTAINERS = `${AppConstants.API_URL}openforall/api/v1/containers/monthly-counts`

  public static COUNT_COUNTRY_OF_ORIGINS = `${AppConstants.API_URL}openforall/api/v1/containers/counts-by-country-of-origin`

  public static MONTHLY_PRODUCTS = `${AppConstants.API_URL}openforall/api/v1/containers/counts-by-product`

  public static LUMBER_LINQ_WEBSITE = `https://www.lumberlinq.com`;

  public static PUBLIC_SHARE_URL = environment.shareUrl;

  public static ENCRYPTION_KEY_COOKIE_NAME = 'x_client_id';

  public static USER_PROFILE_KEY = 'app-metadata-key';
}
