import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmEventType, ConfirmationService, MenuItem } from 'primeng/api';
import { CompanyService } from '../services/company.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PrimeIcons } from 'primeng/api';
import { ChipsModule } from 'primeng/chips';
import { CustomEmailValidator } from 'src/app/classes/custom-email.validator';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css'],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class ManageUsersComponent implements OnInit {
  displayConfirmDialog: boolean = false;
  emailConfirmation: string = '';
  user: any; // Set this to the email of the user to delete
  blockedDocument = false;

  isSubmitting = false;
  addUserToCompanyForm!: FormGroup;
  formHeader!: string;
  formSubHeader!: string;
  protected items!: MenuItem[];
  home!: MenuItem;
  companyUsers: any;
  emailAddresses!: string[];
  isVisible = true;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,

    private router: Router,
    private companyService: CompanyService,
    private toastMessageService: ToastMessagesService,
    // private cdr: ChangeDetectorRef,
    private confirmationService: ConfirmationService
  ) {}
  ngOnInit(): void {
    this.addUserToCompanyForm = new FormGroup({
      emailAddresses: new FormControl(''),
    });

    this.populateBreadCrumb();
    this.setPageTitle();

    this.companyService.getCompanyUsers().subscribe(
      (response: any) => {
        this.companyUsers = response;
        console.log(`getCompanyUsers response is ${JSON.stringify(response)}`);
      },
      (error: HttpErrorResponse) => {
        console.error(`getCompanyUsers error is ${JSON.stringify(error)}`);
      },
      () => {}
    );
  }

  onSubmit() {
    console.log('onSubmit');
    this.blockedDocument = true;
    this.isSubmitting = true;
    const controlKeys = Object.keys(this.addUserToCompanyForm.controls);

    const invalidControlKeys = controlKeys.filter((key) => {
      const control = this.addUserToCompanyForm.controls[key];
      return control.invalid;
    });
    console.log('Invalid control keys:', invalidControlKeys);
    //  this.myForm.markAllAsTouched();
    Object.keys(this.addUserToCompanyForm.controls).forEach((key) => {
      console.log(`Key is ${key}`);
      this.addUserToCompanyForm.get(key)?.updateValueAndValidity();
    });

    console.log(
      `before validattion ${JSON.stringify(this.addUserToCompanyForm.value)}`
    );
    if (this.addUserToCompanyForm.valid) {
      console.log(
        `after  validattion ${JSON.stringify(this.addUserToCompanyForm.value)}`
      );
      this.addUserToCompanyForm.get('emailAddresses');

      this.companyService
        .sendInviteRequest(this.addUserToCompanyForm.value)
        .subscribe(
          (response: any) => {
            console.log(`response is ${response}`);
            this.toastMessageService.showSuccessMessage(
              'Success',
              'User invitation sent'
            );
            //this.isVisible = false;
            this.ngOnInit();
            this.blockedDocument = false;
          },
          (error: HttpErrorResponse) => {
            console.error(`Error is ${JSON.stringify(error)}`);
            this.blockedDocument = false;
          },
          () => {
            this.blockedDocument = false;
          }
        );
    } else {
      console.error(`Form is not valid`);
    }
  }

  populateBreadCrumb() {
    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.items = [
      // { label: 'Buyers', routerLink: '/buyers' },
      { label: 'Manage Users' },
    ];
  }

  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }

  showDialog(user: any) {
    this.user = user;
    this.displayConfirmDialog = true;
  }

  removeUser() {
    console.log(`Remove User ${JSON.stringify(this.user)}`);

    // this.email = email;
    if (this.emailConfirmation === this.user.email) {
      this.blockedDocument = true;

      this.companyService.deleteUser(this.user.userId).subscribe(
        (response: any) => {
          console.log(
            `User successfully deleted. Response: ${JSON.stringify(response)}`
          );
          this.toastMessageService.showSuccessMessage(
            'User Deleted Successfully',
            `The user with Email ${this.user.email} was successfully deleted.`
          );
          this.blockedDocument = false;
        },
        (error: HttpErrorResponse) => {
          this.blockedDocument = false;
          console.error(`Error deleting user: ${JSON.stringify(error)}`);
          this.toastMessageService.showErrorMessage(
            'Failed to Delete User',
            `There was an error deleting the user with ID ${this.user.userId}. Please try again.`
          );
        },
        () => {
          this.ngOnInit();
          this.blockedDocument = false;
          this.displayConfirmDialog = false;
        }
      );
    }

    /* this.confirmationService.confirm({
      message: `Are you sure that you want to delete this user: <b>${user.email}</b>?`,
      header: 'Confirm User Deletion',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        console.log(`Attempting to remove User ${JSON.stringify(user)}`);
        this.companyService.deleteUser(user.userId).subscribe(
          (response: any) => {
            console.log(`User successfully deleted. Response: ${JSON.stringify(response)}`);
            this.toastMessageService.showSuccessMessage('User Deleted Successfully', `The user with Email ${user.email} was successfully deleted.`);
          },
          (error: HttpErrorResponse) => {
            console.error(`Error deleting user: ${JSON.stringify(error)}`);
            this.toastMessageService.showErrorMessage('Failed to Delete User', `There was an error deleting the user with ID ${user.userId}. Please try again.`);
          },
          () => {
            this.ngOnInit();
          },
        )
      },
      reject: (type: any) => {

      }
    }); */
  }

  onAddUser() {
    this.router.navigate(['/add/user']);
  }

  /* removeUser(user: any) {
    console.log(`Remove User ${JSON.stringify(user)}`);
    this.companyService.deleteUser(user.userId).subscribe(
      (response: any) => {
        console.log(`User Deleted response ${JSON.stringify(response)}`);
        this.toastMessageService.showSuccessMessage('User Deleted','User Deleted successfully');
      },
      (error: HttpErrorResponse) => {
        console.error(`User Deleted error ${JSON.stringify(error)}`);
        this.toastMessageService.showErrorMessage('Error User Deleted','Error User Deleted successfully');
      },
      () => { },
    )
    /// /users/{id}
  }

  confirm1() {
    this.confirmationService.confirm({
        message: 'Are you sure that you want to proceed?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
        },
        reject: (type:any) => {
            switch (type) {
                case ConfirmEventType.REJECT:
                    this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
                    break;
                case ConfirmEventType.CANCEL:
                    this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
                    break;
            }
        }
    });
} */
}
