import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AddUserToCompanyComponent } from './add-user-to-company/add-user-to-company.component';
import { ConfirmationService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class AddUserDeactivateGuard implements CanDeactivate<AddUserToCompanyComponent> {

  constructor(private confirmationService: ConfirmationService) { }
  
  canDeactivate(component: AddUserToCompanyComponent): Observable<boolean> | Promise<boolean> | boolean {
    if (component.isSubmitting && component.addUserToCompanyForm.dirty) { // check if component has unsaved changes
      return true;
    }
    if (component.addUserToCompanyForm.dirty) { // check if component has unsaved changes
      return new Promise((resolve) => {
        this.confirmationService.confirm({
          header: 'Warning',
          message: "Changes to the form have not been saved. Do you want to continue without saving?",
          accept: () => {
            resolve(true);
          },
          reject: () => { 
            resolve(false);
          }
        });
      });
    } else {
      return true; // allow deactivation if no unsaved changes
    }
  }

  
}
 