import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { RazorpayPayment } from '../model/razorpay-payment';
import { RazorpayPaymentResponse } from '../model/razorpay-payment-response';
import { SubscriptionService } from './subscription.service';
declare var Razorpay: any;  // Declaring Razorpay variable to call methods later



@Injectable({
  providedIn: 'root'
})
export class RazorpayService {

  constructor(
    private httpClient: HttpClient,
    private subscriptionService: SubscriptionService
  ) { }


  testRazorpayCreateOrder(): Observable<any> {
    return this.httpClient.get<any>(AppConstants.API_URL + 'openforall/createOrder');
  }




  /* pay(razorpayPayment: RazorpayPayment) {
    
    var options = {
      order_id: razorpayPayment.razorpayOrderId,//order_LvuVBnZqJjzNWL, //This is a sample Order
      key: razorpayPayment.key,  // Enter your key here
      amount: razorpayPayment.amount,  // 500 INR in paise
      currency: razorpayPayment.currency,
      name: razorpayPayment.name,
      description: razorpayPayment.description,
      image: razorpayPayment.image,
      customer_id: razorpayPayment.razorpayCustomerId,
      remember_customer: true,
      callback_url: razorpayPayment.callbackUrl,
      redirect: razorpayPayment.redirectUrl,
      recurring: razorpayPayment.recurring,
      subscription_id: razorpayPayment.subscriptionId,
      handler: function (response: any) {
        console.log(`handler response.razorpay_payment_id ${response.razorpay_payment_id}`);
        console.log(`handler response.razorpay_order_id ${response.razorpay_order_id}`);
        console.log(`handler response.razorpay_signature ${response.razorpay_signature}`);
      },
      one_click_checkout: true,
      show_coupons: true,

      prefill: {
        name: razorpayPayment.prefillName,
        email: razorpayPayment.prefillEmail
      },

      notes: {
        // "address": "Razorpay Corporate Office"
      },
      theme: {
        color: "#17A2B8"
      }
    };

    var rzp = new Razorpay(options);
    rzp.on('payment.failed', function (response: any) {
      console.log(response.error.code);
      console.log(response.error.description);
      console.log(response.error.source);
      console.log(response.error.step);
      console.log(response.error.reason);
      console.log(response.error.metadata.order_id);
      console.log(response.error.metadata.payment_id);
    }); 

    rzp.open();
  } */

  pay(razorpayPayment: RazorpayPayment): Promise<any> {
    return new Promise((resolve, reject) => {
      var options = {
        order_id: razorpayPayment.razorpayOrderId,
        key: razorpayPayment.key,
        amount: razorpayPayment.amount,
        currency: razorpayPayment.currency,
        name: razorpayPayment.name,
        description: razorpayPayment.description,
        image: razorpayPayment.image,
        customer_id: razorpayPayment.razorpayCustomerId,
        remember_customer: true,
        callback_url: razorpayPayment.callbackUrl,
        redirect: razorpayPayment.redirectUrl,
        recurring: razorpayPayment.recurring,
        subscription_id: razorpayPayment.subscriptionId,
        /*  handler: function (response: any) {
           console.log(`handler response.razorpay_payment_id ${response.razorpay_payment_id}`);
           console.log(`handler response.razorpay_order_id ${response.razorpay_order_id}`);
           console.log(`handler response.razorpay_signature ${response.razorpay_signature}`);
             this.test1223();
           resolve(response);
         }, */
        handler: (response: any) => { // Arrow function
          console.log(`handler response.razorpay_payment_id ${response.razorpay_payment_id}`);
          console.log(`handler response.razorpay_order_id ${response.razorpay_order_id}`);
          console.log(`handler response.razorpay_signature ${response.razorpay_signature}`);
          let paymentResponse = new RazorpayPaymentResponse();
          paymentResponse.subscriptionOrderId = razorpayPayment.subscriptionOrderId;
          paymentResponse.razorpayPaymentId = response.razorpay_payment_id;
          paymentResponse.razorpayOrderId = response.razorpay_order_id;
          paymentResponse.razorpaySignature = response.razorpay_signature;
          console.log(`paymentResponse ${JSON.stringify(paymentResponse)}`);
          this.subscriptionService.onPaymentResponse(paymentResponse).subscribe(
            (data) => {
              console.log(`On RazorPay Payment Response recorded on SubscriptionOrder ${JSON.stringify(data)}`);
            },
            (error: HttpErrorResponse) => {
              console.error(`On RazorPay Payment Error ${JSON.stringify(error)}`);
            },
            () => { },
          )
          resolve(response);
        },
        one_click_checkout: true,
        show_coupons: true,
        prefill: {
          name: razorpayPayment.prefillName,
          email: razorpayPayment.prefillEmail
        },
        notes: {},
        theme: {
          color: "#17A2B8"
        }
      };

      var rzp = new Razorpay(options);

      rzp.on('payment.failed', function (response: any) {
        console.log(response.error.code);
        console.log(response.error.description);
        console.log(response.error.source);
        console.log(response.error.step);
        console.log(response.error.reason);
        console.log(response.error.metadata.order_id);
        console.log(response.error.metadata.payment_id);
        reject(response.error);
      });

      rzp.open();
    });
  }

}
