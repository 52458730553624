import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AppConstants } from '../commons/app-constants';
import { Theme } from '../commons/theme-constants';

/* @Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  switchTheme(theme: string) {
    let themeLink = this.document.getElementById(
      'app-theme'
    ) as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = theme; // + '.css';
    }
  }
}
 */

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
constructor(@Inject(DOCUMENT) private document: Document) {
    // Apply the theme from localStorage when the app starts
    
    // const theme = localStorage.getItem(AppConstants.THEME) || Theme.BootstrapLight;
    const theme = localStorage.getItem(AppConstants.THEME) || Theme.LaraLight;
    
    this.switchTheme(theme);
    if (theme) {
      this.switchTheme(theme);
    } //bootstrap4-light-blue
  }

  switchTheme(theme: string) {
    let themeLink = this.document.getElementById(
      'app-theme'
    ) as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = theme;
      // Save the theme to localStorage whenever it's changed
      localStorage.setItem(AppConstants.THEME, theme);
    }
  }
}
