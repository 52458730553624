import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppConstants } from '../commons/app-constants';
import { ToastMessagesService } from '../commons/services/toast-messages.service';
import { Router } from '@angular/router';


@Injectable({ 
  providedIn: 'root',
})
export class LogoutService {

  constructor(
    private http: HttpClient,
    private messageService: ToastMessagesService,
    private router: Router

  ) { }

  /* logout() {
    const url = AppConstants.API_URL + "api/v1/logout";
    window.localStorage.clear();
    console.log(`Sign out url is ${url}`);
    return this.http.post(url, null)
      .subscribe(
        (success) => {
          window.localStorage.clear();
          this.messageService.showSuccessMessage('Logout Successful', 'You have been successfully logged out.');
          setTimeout(() => {
            
            this.router.navigate(['login']);;
          }, 1500);

        },
        (error: HttpErrorResponse) => {
          console.log(`Error is ${JSON.stringify(error)}`)
          this.messageService.showErrorMessage('Logout failed', 'Error while log out ')
        },
        () => {
          
         })
      ;

  }
 */

  logout(): Observable<boolean> {
    const url = AppConstants.API_URL + "api/v1/user-logout";
    return new Observable(observer => {
      this.http.post(url, null).subscribe(
        (success) => {
          localStorage.removeItem(AppConstants.REDIRECT_SNAPSHOT_URL);
          observer.next(true);
          observer.complete();
          // Redirect to login page
          this.router.navigate(['/login']);
        },
        (error: HttpErrorResponse) => {
          observer.next(false);
          observer.complete();
          // Redirect to login page even on error
         // this.router.navigate(['/login']);
        }
      );
    });
  }
  
  

}
