import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, of, tap } from 'rxjs';
import { AppConstants } from '../commons/app-constants';
import { ToastMessagesService } from '../commons/services/toast-messages.service';
import { CurrentUser } from '../interfaces/current-user';
import { SecretKeyService } from './secret-key.service';
import { SubscriptionStatus } from '../components/extras/subscription-status.enum';

const httpOptions = {
  //  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  redirectUrl: string | null = null;

  constructor(
    private http: HttpClient,
    private messageService: ToastMessagesService,
    private secretKeyService: SecretKeyService
  ) { }

  getCurrentUser1(): Observable<CurrentUser> {
    return this.http.get<CurrentUser>(
      AppConstants.API_URL + 'api/v1/user/me',
      httpOptions
    );
  }

  getByEmail(name: string): Observable<any | undefined> {
    const response = this.http.get(
      AppConstants.AUTH_API + `checkEmail?name=${name}`,
      httpOptions
    );
    console.log(`getByEmail Response is ${JSON.stringify(response)}`);
    return response;
  }

  // currentUser!: CurrentUser;
  private currentUserSubject = new BehaviorSubject<CurrentUser | null>(null);
  currentUser$ = this.currentUserSubject.asObservable();

  getUser(): Observable<CurrentUser | null> {
    const enc_user = localStorage.getItem(AppConstants.USER) || '';
    if (enc_user) {
      // console.log(`enc_user ${enc_user}`);
      const password = localStorage.getItem(AppConstants.PROMOTOER_CODE);
      // console.log(`password ${password}`);
      return this.secretKeyService.decrypt(enc_user, password).pipe(
        map((decryptedString: any) => {
          const currentUser = JSON.parse(decryptedString);
          this.currentUserSubject.next(currentUser); // update the currentUser BehaviorSubject
          return currentUser;
        }),
        catchError((error: any) => {
          console.log('Decryption error: ', error);
          return of(null);
        })
      );
    }else{
      return null;
    }
  }

  // @GetMapping("/subscriptions/latest/status")
  getSubscriptionStatus1(): Observable<any | null> {
    const url = `${AppConstants.API_URL}api/v1/subscriptions/latest/status`;
    console.log(`getSubscriptionStatus url ${url}`);
    return this.http.get<any>(url);
  }

  getSubscriptionStatus(): Observable<SubscriptionStatus | null> {
    const url = `${AppConstants.API_URL}api/v1/subscriptions/latest/status`;
    //console.log(`getSubscriptionStatus url ${url}`);
    return this.http.get<any>(url).pipe(
      map((response) => {
        const status = response.message;
        console.log(`Subscription Status is${JSON.stringify(status)}`)
        return (
          SubscriptionStatus[status as keyof typeof SubscriptionStatus] || null
        );
      })
    );
  }

  resetDatabase(): Observable<any | null> {
    const url = `${AppConstants.API_URL}api/v1/resetDatabase`;
    console.log(`resetDatabase url ${url}`);
    return this.http.post<any>(url, '');
  }

  /* getUser(): Observable<CurrentUser | null> {
    const enc_user = localStorage.getItem(AppConstants.USER) || '';
    const password = localStorage.getItem(AppConstants.PROMOTOER_CODE);
    return this.secretKeyService.decrypt(enc_user, password).pipe(
      map((decryptedString: any) => {
        console.log(`Decrypted String by UserService is ${decryptedString}`);
        this.currentUser = JSON.parse(decryptedString);
        console.log(`Decrypted currentUser by UserService is ${JSON.stringify(this.currentUser)}`);
        return this.currentUser;
      }),
      catchError((error: any) => {
        console.log('Decryption error: ', error);
        return of(null);
      })
    );
  } */
}
