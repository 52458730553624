<p-blockUI [blocked]="blockedDocument">
    <i class="pi pi-spin pi-spinner" style="font-size: 4rem !important"></i>
</p-blockUI>

<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" acceptLabel="Yes"
    rejectLabel="No"></p-confirmDialog>
<form [formGroup]="addUserToCompanyForm" (ngSubmit)="onSubmit()">

    <div class="pb-3">
        <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
    </div>

    <p-card [header]="formHeader" [subheader]="formSubHeader">
        <div class="formgrid grid" style="width: 99vw;">

            <div class="field col-12 md:col-6 w-full">

                <p-button label="Add user"  (click)="onAddUser()"></p-button>



                <p-table [value]="companyUsers" [tableStyle]="{ 'min-width': '50rem' }">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>SR</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th> <i class="pi pi-user-minus font-bold"></i>
                            </th>
                        </tr>  
                    </ng-template>
                    <ng-template pTemplate="body" let-user let-rowIndex="rowIndex">
                        <tr>  
                            <td>{{ rowIndex + 1 }}</td>
                            <td>{{ user.firstName }}</td>
                            <td>{{ user.lastName }}</td>
                            <td>{{ user.email }}</td>
                            <td><i class="pi pi-user-minus"
                                [ngClass]="{'enabled-icon': user.canBeDeleted && !user.hasAdminRole, 'disabled-icon': !user.canBeDeleted || user.hasAdminRole}"
                                    (click)="(user.canBeDeleted && !user.hasAdminRole )? showDialog(user) : null"></i>
                                <!-- (click)="removeUser(user)"></i> -->
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </p-card>
</form>

<!-- <p-dialog header="Confirm User Deletion" [(visible)]="display" [modal]="true" [style]="{width: '35vw'}">
    <p>Are you sure you want to delete this user? Please confirm by typing the user's email:</p>
    <input pInputText [(ngModel)]="emailConfirmation" placeholder="User Email">
    <p-footer>
        <button pButton type="button" label="No" class="p-button-text" (click)="display=false"></button>
        <button pButton type="button" label="Yes" [disabled]="emailConfirmation !== user?.email?? ''"
            (click)="removeUser()"></button>
    </p-footer>
</p-dialog> -->

<p-dialog header="Confirm User Deletion" [(visible)]="displayConfirmDialog" [modal]="true" [style]="{ 'width': '70vw','min-width': '280px','max-width': '600px' }" >
    <p><strong>Are you sure you want to delete this user?</strong></p>
    <p>Please confirm by typing the user's email below:</p>
    <input pInputText [(ngModel)]="emailConfirmation" placeholder="User Email" class="w-full">
    <p><strong style="color: red">This action cannot be undone.</strong></p>
    <p-footer>
        <button pButton type="button" label="No" class="p-button-text" (click)="displayConfirmDialog=false"></button>
        <button pButton type="button" label="Yes" [disabled]="emailConfirmation !== user?.email?? ''"
            (click)="removeUser()"></button>
    </p-footer>
</p-dialog>