import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgrammaticGuard implements CanActivate {
 
  constructor(private router: Router) {}

  canActivate(): boolean {
    // If it's a direct URL navigation, 'navigationId' will be 1.
    if (this.router.getCurrentNavigation()?.id === 1) {
      // If it's a direct navigation, navigate to some other route or show an error.
      this.router.navigate(['/timber/container']);
      return false;
    }
    return true;
  }

}
