import { Injectable } from '@angular/core';
import { Product } from '../model/product';

@Injectable({
  providedIn: 'root'
})
export class ProductSharedDataService {

  productSharedData!: any;

  constructor() { }

  saveDataToLocalStorage(data: Product) {
    localStorage.setItem(this.productSharedData, JSON.stringify(data));
  }

  getDataFromLocalStorage(): Product {
    const data = localStorage.getItem(this.productSharedData);
    return JSON.parse(data!);
  }
}
