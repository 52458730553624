<router-outlet></router-outlet>
<div class="custom-card">
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [style]="{ 'width': '70vw','min-width': '280px','max-width': '600px' }"></p-confirmDialog>
    <div class="pb-3">
        <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
    </div>
    <p-card [header]="formHeader" [subheader]="formSubHeader" class="w-full">
        <p-speedDial [model]="buyerSpeedDialItems" direction="up-left" type="quarter-circle" [radius]="120"
            class="ml-5 my-speed-dial" [rotateAnimation]="true"></p-speedDial>
        <!-- <div class="card"  style="width: 98vw"> -->
        <div>

            <p-table #dt [lazy]="true" [value]="buyers" (onLazyLoad)="sortBuyers($event)" [rowHover]="true"
                [customSort]="true" sortMode="multiple" dataKey="buyerId"
                [globalFilterFields]="['name','contactPerson','city','gst']" [showCurrentPageReport]="true"
                [rows]="rows" [rowsPerPageOptions]="rowsPerPageOptions" [paginator]="true" [first]="first"
                currentPageReportTemplate="Showing {{first}} to {{last}} of {{totalRecords}} entries"
                [totalRecords]="totalRecords" [columns]="cols" [(selection)]="selectedBuyers"
                (onRowSelect)="onRowSelect($event)" [styleClass]="tableStyle" [tableStyle]="tableStyleOptions"
                [loading]="loading" paginatorDropdownAppendTo="body">
                <!-- styleClass="p-datatable-customers p-datatable-gridlines" -->


                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-between">
                        <!--  <p-button [label]="isMobile ? '' : 'Clear'"
                        styleClass="p-button-outlined sm:p-button-sm md:p-button-md mr-1" icon="pi pi-filter-slash"
                        (click)="clear(dt)" class="clear-button">
                    </p-button>

                    <p-button [label]="isMobile ? '' : 'Delete'"
                        styleClass="p-button-outlined sm:p-button-sm md:p-button-md" icon="pi pi-trash"
                        (click)="deleteRows()" label="Delete" class="clear-button"></p-button> -->
                        <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" class="multiselectfields"
                            selectedItemsLabel="{0} Selected" placeholder="Choose Columns" defaultLabel="Choose Columns"
                            [maxSelectedLabels]="2"> </p-multiSelect>
                        <span class="p-input-icon-left">
                            <div class="p-inputgroup">
                                <input pInputText type="text" (input)="globalFilter($event,dt)" #globalFiterInput
                                    class="searchglobally" placeholder="Search keyword" />
                                <button type="button" pButton icon="pi pi-filter-slash" styleClass="p-button-warn"
                                    (click)="clearAllFilter(dt,globalFiterInput)"></button>
                            </div>
                        </span>


                    </div>
                </ng-template>

                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th class="checkbox-selection">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="name" p-toggleableColumn style="white-space: normal;">Name
                            <p-columnFilter type="text" field="name" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                            <p-sortIcon field="name" class="sort-icon"></p-sortIcon>
                        </th>
                        <th pSortableColumn="contactPerson" *ngIf="_selectedColumns!.includes('Contact Person')">Contact
                            Person
                            <p-columnFilter type="text" field="contactPerson" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                            <p-sortIcon field="contactPerson"></p-sortIcon>
                        </th>
                        <th *ngIf="_selectedColumns!.includes('City')">City <p-columnFilter type="text" field="city"
                                display="menu" matchMode="contains" [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                        </th>
                        <th *ngIf="_selectedColumns!.includes('State')">State <p-columnFilter type="text" field="state"
                                display="menu" matchMode="contains" [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                        </th>
                        <th *ngIf="_selectedColumns!.includes('Country')">Country
                            <p-columnFilter type="text" field="country" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                        </th>
                        <th *ngIf="_selectedColumns!.includes('GST')">GST
                            <p-columnFilter type="text" field="gst" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                        </th>
                        <th *ngIf="_selectedColumns!.includes('Phone')">Phone</th>
                        <th *ngIf="_selectedColumns!.includes('Email')">Email</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-buyer let-columns="columns">
                    <tr [pSelectableRow]="columns" (click)="onRowClick(buyer)" p-selectable-row>
                        <td (click)="$event.stopPropagation()">
                            <p-tableCheckbox [value]="buyer"></p-tableCheckbox>
                        </td>
                        <!--   <td *ngIf="showNameColumn"><a href="#" target="_blank">{{buyer.name}}</a></td> -->
                        <td> {{buyer.name}}</td>
                        <td *ngIf="_selectedColumns!.includes('Contact Person')">{{buyer.contactPerson}}</td>
                        <td *ngIf="_selectedColumns!.includes('City')">{{buyer.location.city.name}}</td>
                        <td *ngIf="_selectedColumns!.includes('State')">{{buyer.location.state.name}}</td>
                        <td *ngIf="_selectedColumns!.includes('Country')">{{buyer.location.country.countryName}}</td>
                        <td *ngIf="_selectedColumns!.includes('GST')">{{buyer.gst}}</td>
                        <td *ngIf="_selectedColumns!.includes('Phone')"> {{buyer.phone1}}</td>
                        <td *ngIf="_selectedColumns!.includes('Email')"> {{buyer.email}}</td>
                    </tr>

                </ng-template>

                <!--  <ng-template pTemplate="paginatorleft">
            <p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button>
        </ng-template>
        <ng-template pTemplate="paginatorright">
            <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button>
        </ng-template> -->
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <!-- <td colspan="(_selectedColumns?.length ?? 6) + 2">No records found.</td> -->
                        <td [attr.colspan]="(_selectedColumns ? _selectedColumns.length + 2 : 4)" class="text-center">No
                            records found.</td>

                    </tr>

                </ng-template>
                <ng-template pTemplate="loadingbody" let-columns="columns">
                    <tr style="height:46px">
                        <td>
                            <p-skeleton></p-skeleton>
                        </td>
                        <td>
                            <p-skeleton></p-skeleton>
                        </td>
                        <td *ngIf="_selectedColumns!.includes('Contact Person')">
                            <p-skeleton></p-skeleton>
                        </td>
                        <td *ngIf="_selectedColumns!.includes('City')">
                            <p-skeleton></p-skeleton>
                        </td>
                        <td *ngIf="_selectedColumns!.includes('State')">
                            <p-skeleton></p-skeleton>
                        </td>
                        <td *ngIf="_selectedColumns!.includes('Country')">
                            <p-skeleton></p-skeleton>
                        </td>
                        <td *ngIf="_selectedColumns!.includes('GST')">
                            <p-skeleton></p-skeleton>
                        </td>
                        <td *ngIf="_selectedColumns!.includes('Phone')">
                            <p-skeleton></p-skeleton>
                        </td>
                        <td *ngIf="_selectedColumns!.includes('Phone')">
                            <p-skeleton></p-skeleton>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-card>


    <p-sidebar [(visible)]="buyerSidebarVisible" position="right" [style]="{'width': '50vw'}" (onHide)="setPageTitle()">
        <app-buyer *ngIf="buyerSidebarVisible" [showBreadcrumb]="false" [isSubmittedFromSidebar]="true"
            (buyerSubmit)="onBuyerSubmit($event)" titleFromSidebar="Add New Buyer" headerFromSidebar="Create Buyer"
            subHeaderFromSidebar="Input new buyer details"></app-buyer>
    </p-sidebar>
</div>