<form [formGroup]="tableForm" (ngSubmit)="onSubmit()">

    <p-blockUI  [blocked]="blockedPanel" styleClass="p-blockui-document">
        <i class="pi pi-spin pi-spinner" style="font-size: 4rem !important"></i>
    </p-blockUI>
    <p-speedDial [model]="sawnTimberSpeedDialItems" direction="up-left" type="quarter-circle" [radius]="120"
        class="ml-5 my-speed-dial" [rotateAnimation]="true" (onClick)="onSpeedDialItemClick($event)"></p-speedDial>
    <!-- <p-button icon="pi pi-cog" styleClass="p-button-rounded" (onClick)="isConfigDialogVisible =true"></p-button> -->

    <!-- <p-dialog key="configDialog" header="Configuration" [(visible)]="isConfigDialogVisible" [modal]="true"
        [style]="{ width: '50vw' }"
        [breakpoints]="{ '320px': '100vw', '480px': '90vw', '768px': '80vw', '1024px': '60vw', '1200px': '50vw' }"
        [draggable]="false" [resizable]="false" escape="true" [closable]="true">

        <div formGroupName="config" class="field w-full">

            <p class="text-red-400" *ngIf="showValidationMessage">
                Please fill all the fields or disable validation.
            </p>

            <div class="flex justify-content-start flex-wrap mb-2">
                <div class="flex align-items-center justify-content-center border-round m-2">
                    <p>Length</p>
                    <p-dropdown [options]="unitOptions" formControlName="lengthUnit" class="ml-2"></p-dropdown>
                </div>
                <div class="flex align-items-center justify-content-center border-round m-2">
                    <label class="pr-2 pl-2">Width</label>
                    <p-dropdown [options]="unitOptions" formControlName="widthUnit" class="ml-2"></p-dropdown>
                </div>
                <div class="flex align-items-center justify-content-center border-round m-2">
                    <label class="pr-2 pl-2">Height</label>
                    <p-dropdown [options]="unitOptions" formControlName="heightUnit" class="ml-2"></p-dropdown>
                </div>
            </div>
            <div class="flex justify-content-start flex-wrap mb-2">
                <div class="flex align-items-center justify-content-center border-round m-2">
                    <p class="pr-2">Enable Validation</p>
                    <p-toggleButton formControlName="enableValidation" onLabel="Yes" offLabel="No" binary="true"
                        (onChange)="onToggleChange($event)"></p-toggleButton>
                </div>
            </div>



            <div *ngIf="tableForm.get('config.enableValidation')?.value"> 

                <div class="grid">
                    <div class="col-6">
                        <div class="p-inputgroup">
                            <label class="p-mr-2">Length Range</label>
                            <p-inputNumber placeholder="Min Length" formControlName="minLength" mode="decimal" [min]="0"
                                [max]="10000" (onKeyDown)="onRangeChange()"></p-inputNumber>
                            <p-inputNumber placeholder="Max Length" formControlName="maxLength" mode="decimal" [min]="0"
                                [max]="10000" (onKeyDown)="onRangeChange()"></p-inputNumber>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="p-inputgroup">
                            <label class="p-mr-2">Width Range</label>
                            <p-inputNumber placeholder="Min Width" formControlName="minWidth" mode="decimal" [min]="0"
                                [max]="10000" (onKeyDown)="onRangeChange()"></p-inputNumber>
                            <p-inputNumber placeholder="Max Width" formControlName="maxWidth" mode="decimal" [min]="0"
                                [max]="10000" (onKeyDown)="onRangeChange()"></p-inputNumber>
                        </div>
                    </div>
                </div>
                <div class="grid">
                    <div class="col-6">
                        <div class="p-inputgroup">
                            <label class="p-mr-2">Height Range</label>
                            <p-inputNumber placeholder="Min Height" formControlName="minHeight" mode="decimal" [min]="0"
                                [max]="10000" (onKeyDown)="onRangeChange()"></p-inputNumber>
                            <p-inputNumber placeholder="Max Height" formControlName="maxHeight" mode="decimal" [min]="0"
                                [max]="10000" (onKeyDown)="onRangeChange()"></p-inputNumber>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="p-inputgroup">
                            <label class="p-mr-2">Pieces Range</label>
                            <p-inputNumber placeholder="Min Pieces" formControlName="minPieces" mode="decimal" [min]="0"
                                [max]="10000" (onKeyDown)="onRangeChange()"></p-inputNumber>
                            <p-inputNumber placeholder="Max Pieces" formControlName="maxPieces" mode="decimal" [min]="0"
                                [max]="10000" (onKeyDown)="onRangeChange()"></p-inputNumber>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <ng-template pTemplate="footer">
            <p-button label="Close without Saving" styleClass="p-button-outlined p-button-danger" (click)="isConfigDialogVisible = false"></p-button>
            <p-button label="Save and Close" styleClass="p-button-outlined p-button-success" (click)="onDialogClose()"></p-button>
        </ng-template>

    </p-dialog> -->
    <p-dialog key="configDialog" header="Configuration" [(visible)]="isConfigDialogVisible" [modal]="true"
    [style]="{ 'width': '70vw','min-width': '280px','max-width': '600px' }" [draggable]="false" [resizable]="false" escape="true" [closable]="true">
        <p class="text-red-400" *ngIf="showValidationMessage">
            Please fill all the fields or disable validation.
        </p>

        

        <div class="formgrid grid col-12" formGroupName="config">
            <div class="col-12 pt-3">
                <p-card header="Auto Save">

                    <div class="field grid">
                        <div class="col-* p-2 pt-2">
                            <div class="ml-2"> <label>Enable Auto Saving?  </label></div>
                        </div>
                        <div class="col-3 xs:col-6 sm:col-6 md:col-6 lg:col-3 xl:col-3  pt-2">
                            <div><p-toggleButton formControlName="saveOptionBoolean" onLabel="Yes" offLabel="No"
                                    binary="true" (onChange)="changeSaveOption()"></p-toggleButton></div>
                        </div>
                        <div class="col-*  p-2  pt-2" *ngIf="showSaveOptionDiv$ | async">
                            <div class="ml-2"> <label>Duration </label></div>
                        </div>
                        <div class="col-* ml-2 pt-2" *ngIf="showSaveOptionDiv$ | async">
                            <p-inputNumber formControlName="autoSaveDuration" inputId="minmax" inputId="minmax"
                                mode="decimal" [min]="1" [max]="60" [showButtons]="true" [step]="1" mode="decimal"
                                suffix=" mins" (ngModelChange)="updateAutoSave()">
                            </p-inputNumber>
                        </div>
                    </div>

                </p-card>
            </div>
            <!-- ----  ---- ---- ----  ---->

            <div class="col-12 xs:col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12 pt-3">
                <p-card header="Unit of Measure">
                    <!-- Unit of Measure -->

                    <div class="field col-12 xs:col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
                        <label for="girthUnit" style="width: 5rem">Width <i class="text-red-400">*</i></label>
                        <p-dropdown [options]="unitOptions" formControlName="widthUnit" class="ml-2"></p-dropdown>
                    </div>

                    <div class="field col-12 xs:col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
                        <label for="girthUnit" style="width: 5rem">Thickness <i class="text-red-400">*</i></label>
                        <p-dropdown [options]="unitOptions" formControlName="heightUnit" class="ml-2"></p-dropdown>
                    </div>
                    <div class="field col-12 xs:col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
                        <label for="lengthUnit" style="width: 5rem">Length <i class="text-red-400">*</i></label>
                        <p-dropdown [options]="unitOptions" formControlName="lengthUnit" class="ml-2"></p-dropdown>
                    </div>


                </p-card>
            </div>

            <div class="col-12 xs:col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12 pt-3">
                <p-card header="Range Validation">
                    <!-- Enable Validation -->

                    <div class="field grid">
                        <!-- <label for="lengthAllowance" style="width: 10rem">Enable Validation </label>
                    <p-toggleButton formControlName="enableValidation" onLabel="Yes" offLabel="No" binary="true"
                        (onChange)="onToggleChange($event)"></p-toggleButton> -->
                        <div class="col-12 md:col-6 lg:col-3 p-2">
                            <div class="ml-2"> <label>Enable Validation? <i class="text-red-400">*</i></label></div>
                        </div>
                        <div class="col-12 md:col-6 lg:col-3 p-2">
                            <div><p-toggleButton formControlName="enableValidation" onLabel="Yes" offLabel="No"
                                    binary="true" (onChange)="onToggleChange($event)"></p-toggleButton></div>
                        </div>
                    </div>


                    <div class="field grid" *ngIf="tableForm.get('config.enableValidation')?.value">
                        <div class="col-12 md:col-6 lg:col-3 p-2">
                            <div class="ml-2"> <label>Min Length <i class="text-red-400">*</i></label></div>
                        </div>
                        <div class="col-12 md:col-6 lg:col-3 p-2">
                            <div><p-inputNumber placeholder="Min Length" formControlName="minLength" mode="decimal"
                                    [min]="0" [max]="10000" (onKeyDown)="onRangeChange()"></p-inputNumber></div>
                        </div>
                        <div class="col-12 md:col-6 lg:col-3 p-2">
                            <div class="ml-2"> <label>Max Length <i class="text-red-400">*</i></label></div>
                        </div>
                        <div class="col-12 md:col-6 lg:col-3 p-2">
                            <div><p-inputNumber placeholder="Max Length" formControlName="maxLength" mode="decimal"
                                    [min]="0" [max]="10000" (onKeyDown)="onRangeChange()"></p-inputNumber></div>
                        </div>
                    </div>

                    <div class="field grid" *ngIf="tableForm.get('config.enableValidation')?.value">
                        <div class="col-12 md:col-6 lg:col-3 p-2">
                            <div class="ml-2"> <label>Min Width <i class="text-red-400">*</i></label></div>
                        </div>
                        <div class="col-12 md:col-6 lg:col-3 p-2">
                            <div><p-inputNumber placeholder="Min Width" formControlName="minWidth" mode="decimal"
                                    [min]="0" [max]="10000" (onKeyDown)="onRangeChange()"></p-inputNumber></div>
                        </div>
                        <div class="col-12 md:col-6 lg:col-3 p-2">
                            <div class="ml-2"> <label>Max Width <i class="text-red-400">*</i></label></div>
                        </div>
                        <div class="col-12 md:col-6 lg:col-3 p-2">
                            <div><p-inputNumber placeholder="Max Width" formControlName="maxWidth" mode="decimal"
                                    [min]="0" [max]="10000" (onKeyDown)="onRangeChange()"></p-inputNumber></div>
                        </div>
                    </div>

                    <div class="field grid" *ngIf="tableForm.get('config.enableValidation')?.value">
                        <div class="col-12 md:col-6 lg:col-3 p-2">
                            <div class="ml-2"> <label>Min Height <i class="text-red-400">*</i></label></div>
                        </div>
                        <div class="col-12 md:col-6 lg:col-3 p-2">
                            <div><p-inputNumber placeholder="Min Height" formControlName="minHeight" mode="decimal"
                                    [min]="0" [max]="10000" (onKeyDown)="onRangeChange()"></p-inputNumber></div>
                        </div>
                        <div class="col-12 md:col-6 lg:col-3 p-2">
                            <div class="ml-2"> <label>Max Height <i class="text-red-400">*</i></label></div>
                        </div>
                        <div class="col-12 md:col-6 lg:col-3 p-2">
                            <div><p-inputNumber placeholder="Max Height" formControlName="maxHeight" mode="decimal"
                                    [min]="0" [max]="10000" (onKeyDown)="onRangeChange()"></p-inputNumber></div>
                        </div>
                    </div>

                    <div class="field grid" *ngIf="tableForm.get('config.enableValidation')?.value">
                        <div class="col-12 md:col-6 lg:col-3 p-2">
                            <div class="ml-2"> <label>Min Pieces <i class="text-red-400">*</i></label></div>
                        </div>
                        <div class="col-12 md:col-6 lg:col-3 p-2">
                            <div><p-inputNumber placeholder="Min Pieces" formControlName="minPieces" mode="decimal"
                                    [min]="0" [max]="10000" (onKeyDown)="onRangeChange()"></p-inputNumber></div>
                        </div>
                        <div class="col-12 md:col-6 lg:col-3 p-2">
                            <div class="ml-2"> <label>Max Pieces <i class="text-red-400">*</i></label></div>
                        </div>
                        <div class="col-12 md:col-6 lg:col-3 p-2">
                            <div><p-inputNumber placeholder="Max Pieces" formControlName="maxPieces" mode="decimal"
                                    [min]="0" [max]="10000" (onKeyDown)="onRangeChange()"></p-inputNumber></div>
                        </div>
                    </div>

                </p-card>
            </div>


        </div>


        <ng-template pTemplate="footer">
            <p-button label="Close without Saving" styleClass="p-button-outlined p-button-danger"
                (click)="isConfigDialogVisible = false"></p-button>
            <p-button label="Save and Close" styleClass="p-button-outlined p-button-success"
                (click)="onDialogClose()"></p-button>


            <!-- <button pButton type="button" label="Save and Close" class="ui-button-success" (click)="onDialogClose()"></button> -->
            <!-- <button pButton type="button" label="Close without Saving" class="ui-button-danger" (click)="isConfigDialogVisible = false"></button> -->
        </ng-template>

    </p-dialog>



    <div formArrayName="rows" class="pt-5">
        <p-table [value]="dummyArrayHorizontalGrid"
            styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped" selectionMode="single"
            [(selection)]="selectedRow" dataKey="uniqueKey">
            <!-- <thead> -->
            <ng-template pTemplate="header">
                <tr>
                    <!-- <th class="text-center" rowspan="2"  [ngStyle]="{'visibility': 'none'}">Review</th> -->
                    <th class="text-center" rowspan="2">SR</th>
                    <th class="text-center">Width</th>
                    <th class="text-center">Thickness</th>
                    <th class="text-center">Length</th>


                    <th class="text-center" rowspan="2">Pieces</th>
                    <th class="text-center" rowspan="2">CBM</th>
                    <th class="text-center" rowspan="2">CFT</th>
                    <th class="text-center" rowspan="2">Actions</th>
                </tr>
                <tr>
                    <th class="text-center uppercase">
                        {{this.tableForm
                        .get('config.widthUnit')?.value?? 'cm' }}
                    </th>
                    <th class="text-center uppercase">
                        {{this.tableForm
                        .get('config.heightUnit')?.value?? 'cm' }}
                    </th>
                    <th class="text-center uppercase">
                        {{this.tableForm
                        .get('config.lengthUnit')?.value?? 'cm' }}
                    </th>


                </tr>
            </ng-template>
            <!-- </thead> -->
            <!-- <tbody styleClass="p-datatable-gridlines  p-datatable-striped"> -->
            <ng-template pTemplate="body" let-product>
                <ng-container *ngFor="let row of rows.controls; let i = index">

                    <!--  <tr @fadeAnimation *ngFor="let row of rows.controls; let i = index" [formGroupName]="i"
                    [ngClass]="{'review': row.get('review')?.value, 'highlight': row.get('highlight')?.value}">
                     -->
                    <tr *ngIf="row.get('action')?.value !== 'REMOVED'" @fadeAnimation [formGroupName]="i"
                        [ngClass]="{'review': row.get('review')?.value, 'highlight': row.get('highlight')?.value}">
                        <!--     <input  type="hidden" formControlName="serialNumber"   [value]="i + 1" 
                    (input)="row.get('serialNumber')?.setValue(i + 1)"> -->
                        <input type="hidden" formControlName="serialNumber"> {{ row.get('serialNumber' | json)}}
                        <input type="hidden" formControlName="review" value="on">

                         
                        <td class="text-center"> {{ i +1 }}</td>
                        <td class="text-center"><input pInputText type="number" pKeyFilter="num" formControlName="width"
                                class="w-5rem text-right" #widthInput
                                [class.is-out-of-range]="isOutOfRange(row,'width')"
                                [class.required]="row.get('width')?.errors?.['required']"
                                (keydown.enter)="moveFocus(widthInput, heightInput, piecesInput, i, $event)"
                                (keydown.meta.arrowdown)="navigateUpDown($event, 1, 'width', i)"
                                (keydown.meta.arrowup)="navigateUpDown($event, -1, 'width', i)"
                                (keydown.meta.arrowright)="setFocusOnElement(i,heightInput)"
                                (keydown.meta.arrowleft)="setFocusOnElement(i,lengthInput)" (focus)="startEditRow(i)"
                                (input)="finishEditRow(i)" (blur)="finishEditRow(i)">
                        </td>

                        <td class="text-center"><input pInputText type="number" pKeyFilter="num"
                                formControlName="height" class="w-5rem text-right" #heightInput
                                [class.is-out-of-range]="isOutOfRange(row,'height')"
                                [class.required]="row.get('height')?.errors?.['required']"
                                (keydown.enter)="moveFocus(heightInput, lengthInput, piecesInput, i, $event)"
                                (keydown.meta.arrowdown)="navigateUpDown($event, 1, 'height', i)"
                                (keydown.meta.arrowup)="navigateUpDown($event, -1, 'height', i)"
                                (keydown.meta.arrowright)="setFocusOnElement(i,lengthInput)"
                                (keydown.meta.arrowleft)="setFocusOnElement(i,widthInput)" (focus)="startEditRow(i)"
                                (input)="finishEditRow(i)" (blur)="finishEditRow(i)"></td>

                        <td class="text-center"><input pInputText type="number" pKeyFilter="num"
                                formControlName="length" class="w-5rem text-right" #lengthInput pAutoFocus
                                [autofocus]="true" id="numberRegex" [class.is-out-of-range]="isOutOfRange(row,'length')"
                                [class.required]="row.get('length')?.errors?.['required']"
                                (keydown.enter)="moveFocus(lengthInput, piecesInput, piecesInput, i, $event)"
                                (keydown.meta.arrowdown)="navigateUpDown($event, 1, 'length', i)"
                                (keydown.meta.arrowup)="navigateUpDown($event, -1, 'length', i)"
                                (keydown.meta.arrowright)="setFocusOnElement(i,piecesInput)"
                                (keydown.meta.arrowleft)="setFocusOnElement(i,heightInput)" (focus)="startEditRow(i)"
                                (input)="finishEditRow(i)" (blur)="finishEditRow(i)">

                        </td>



                        <td class="text-center"><input pInputText type="number" pKeyFilter="num"
                                formControlName="pieces" class="w-5rem text-right" #piecesInput
                                [class.is-out-of-range]="isOutOfRange(row,'pieces')"
                                [class.required]="row.get('pieces')?.errors?.['required']"
                                (keydown.enter)="moveFocus(piecesInput, widthInput, piecesInput, i, $event, true)"
                                (keydown.meta.arrowdown)="navigateUpDown($event, 1, 'pieces', i)"
                                (keydown.meta.arrowup)="navigateUpDown($event, -1, 'pieces', i)"
                                (keydown.meta.arrowleft)="setFocusOnElement(i,lengthInput)"
                                (keydown.meta.arrowright)="setFocusOnElement(i,piecesInput)" (focus)="startEditRow(i)"
                                (input)="finishEditRow(i)" (blur)="finishEditRow(i)"></td>

                        <td>
                            <!-- <input pInputText formControlName="cbm" class="w-7rem" readonly> -->
                            <input type="hidden" formControlName="cbm">
                            <p class="text-right"> {{ row.get('cbm')?.value }}</p>
                        </td>
                        <td>
                            <!-- <input pInputText formControlName="cft" class="w-7rem" readonly> -->
                            <input type="hidden" formControlName="cft">
                            <p class="text-right"> {{ row.get('cft')?.value }}</p>
                        </td>
                        <td class="text-center">
                            <!-- <button pButton type="button" icon="pi pi-plus" (click)="createRow(null,i+1)" class="mr-2"> </button>-->
                            <!-- <p-button icon="pi pi-plus"
                                styleClass="p-button-rounded p-button-success p-button-text p-button-raised"
                                (click)="createRowFromTable(i,i+1)" class="mr-2"></p-button> -->
                            <p-button icon="pi pi-plus-circle" (click)="createRowFromTable(i,i+1)" class="mr-2"
                                styleClass="p-button-rounded p-button-success p-button-text"></p-button>
                            <!-- <p-button icon="pi pi-times"
                                styleClass="p-button-rounded p-button-danger p-button-text p-button-raised"
                                (click)="removeRow(i)" *ngIf="rows.length > 1"></p-button> -->
                            <p-button icon="pi pi-times-circle" (click)="removeRow(i)" *ngIf="rows.length > 1"
                                styleClass="p-button-rounded p-button-danger p-button-text"></p-button>


                            <!-- <button pButton type="button" icon="pi pi-minus" (click)="removeRow(i)"
                            *ngIf="rows.length > 1"></button> -->
                            <!-- <button pButton type="button" icon="pi pi-check" (click)="highlight1(i)">  {{ i }}</button> -->
                        </td>
                    </tr>
                </ng-container>
            </ng-template>
            <!-- </tbody> -->
            <!-- <tfoot> -->
            <ng-template pTemplate="footer">
                <tr>
                    <td class="text-right" colspan="5">Total</td>
                    <td class="text-right">{{ totalCBM.toFixed(3) }}</td>
                    <td class="text-right">{{ totalCFT.toFixed(3) }}</td>
                    <td class="text-right"></td>
                </tr>
            </ng-template>
            <!-- </tfoot> -->
            <ng-template pTemplate="summary">
                <div class="flex align-items-center justify-content-between">In total there are {{ totalPieces?? 0 }}
                    pieces.</div>
            </ng-template>
        </p-table>
    </div>

    <!--  <button pButton type="submit" label="Submit" (click)="printData()" class="mr-2"></button>
    <button pButton type="button" label="Difference" (click)="persistChanges()"></button> -->
</form>

<p-dialog header="Auto Save Interval" [(visible)]="showAutoSaveIntervalDialog" [style]="{width: '50vw'}">
    <div class="col-12 sm:12 md:col-12 lg:col-12 xl:col-12">
        <label for="saveInterval" class="mr-2">Auto-save interval in minutes</label>
        <p-inputNumber [(ngModel)]="saveInterval" inputId="minmax" inputId="minmax" mode="decimal" [min]="2" [max]="20"
            [showButtons]="true" [step]="1" mode="decimal" suffix=" mins">
        </p-inputNumber>
        <p class="text-xs font-italic">Here, you can adjust the auto-save interval. However, to activate or
            deactivate auto-save, please use the menu option.</p>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="showAutoSaveIntervalDialog = false" label="Ok"
            styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>

<!-- <button (click)="exportPdf()">Export to PDF</button>
<button (click)="exportExcel()">Export to Excel</button> -->