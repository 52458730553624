import { HttpParams } from '@angular/common/http';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Page } from 'src/app/commons/models/page';
import { BillOfLading } from '../model/bill-of-lading';
import { BillOfLadingSharedDataService } from '../service/bill-of-lading-shared-data.service';
import { BillOfLadingService } from '../service/bill-of-lading.service';
import { UtilService } from 'src/app/services/util.service';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { ContextMenu } from 'primeng/contextmenu';
import { ClipboardService } from 'ngx-clipboard';
import { AppConstants } from 'src/app/commons/app-constants';
import { DatePipe } from '@angular/common';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-bill-of-lading-list',
  templateUrl: './bill-of-lading-list.component.html',
  styleUrls: ['./bill-of-lading-list.component.css'],
  providers: [ToastMessagesService],
  standalone: true,
  imports: [SHARED_MODULES]
})
export class BillOfLadingListComponent implements OnInit, OnDestroy {
  currentRowIndex!: number;
  protected shareItems!: MenuItem[];
  @ViewChildren('cm') contextMenus!: QueryList<ContextMenu>;
  formHeader!: string;
  formSubHeader!: string;
  protected billOfLadingSpeedDialItems!: MenuItem[];
  showNameColumn = true;
  showContactPersonColumn = true;
  showGstColumn = true;
  showCityColumn = true;
  showPhoneColumn = false;
  showEmailColumn = false;
  protected items!: MenuItem[];
  home!: MenuItem;
  rows = 5;
  billOfLadings!: BillOfLading[];
  multiSortMeta!: any[];
  totalRecords: number = 0;
  first: number = 0;
  last: number = 0;
  rowsPerPageOptions = [5, 10, 20]; // set the options for the number of rows per page
  @ViewChild('globalFiterInput') globalFiterInput!: ElementRef;
  @ViewChild('dt') table!: Table;
  cols!: any[];
  copyUrl!: string;
  isMobile = false;
  loading: boolean = false;
  _selectedColumns!: any[];
  selectedBillOfLadings: BillOfLading[] = [];
  tableStyle: any;
  shareBLUUID!: string;
  shareBLShortUrl!: string;

  dateFilterOptions: { label: string; value: string }[];
  tableStyleOptions: any;

  constructor(
    private billOfLadingService: BillOfLadingService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private billOfLadingSharedDataService: BillOfLadingSharedDataService,
    private router: Router,
    private titleService: Title,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private toastMessagesService: ToastMessagesService,
    private clipboardService: ClipboardService,
    private datePipe: DatePipe,
    private encryptedStorageService: EncryptedStorageService
  ) {
    this.setPageTitle();
    this.tableStyle = this.utilService.getTableSize();
    this.dateFilterOptions = [
      { label: 'Equals', value: 'eq' },
      { label: 'Greater Than', value: 'gt' },
      { label: 'Less Than', value: 'lt' },
    ];
  }

  ngOnInit() {
    this.initTableColumns();
    this.populateBreadCrumb();
    this.isMobile = this.utilService.isMobile();
    this.initSpeedDial();
    this.shareItemsInit();
    this.initTableStyle();
  }

  sortBillOfLading(event: any) {
    this.loading = true;
    console.warn('sortBillOfLading on load');
    //const globalFilter = this.globalFiterInput.nativeElement.value;
    /* console.log(`value ${globalFilter}`);
    console.log(`%%% ${globalFilter}`) */
    const pageNumber = Math.floor(event.first / event.rows) + 1;
    console.log(`Page Number ${pageNumber}`);
    console.log(`Event List ${JSON.stringify(event)}`);
    let params = new HttpParams();
    let sortOrder = '';
    console.log(`customSort field ${[event!.field!]}`);
    // console.log(`customSort data ${[event!.data!]}`)
    console.log(`customSort mode ${[event!.mode!]}`);
    console.log(`customSort order ${[event!.order!]}`);
    console.log(
      `customSort multiSortMeta ${JSON.stringify([event!.multiSortMeta!])}`
    );
    console.log(`multiSortMeta ${event!.multiSortMeta?.length}`);
    const multiSortLength = event!.multiSortMeta?.length;
    params = params.set('size', event.rows);
    params = params.set('page', pageNumber - 1);
    for (var i = 0; i < multiSortLength!; i++) {
      if (event!.multiSortMeta![i].order === -1) {
        sortOrder = 'DESC';
      } else {
        sortOrder = 'ASC';
      }
      console.log(`field ${event!.multiSortMeta![i].field}`);
      console.log(`order ${event!.multiSortMeta![i].order}`);

      if (multiSortLength! > 0) {
        console.log(`in if`);
        params = params.set(
          'sort',
          event!.multiSortMeta![i].field + ',' + sortOrder
        );
      }
    }
    console.log(`Params ${params.toString()}`);
    this.billOfLadings = [];
    // filter implementation
    const filters = event.filters;
    if (event.filters.hasOwnProperty('blNumber')) {
      console.log(`filters ${JSON.stringify(filters.blNumber.length)}`);
      for (var i = 0; i < filters.blNumber.length; i++) {
        if (filters.blNumber[i].value) {
          params = params.set('blNumber', filters.blNumber[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('vesselName')) {
      for (var i = 0; i < filters.vesselName.length; i++) {
        if (filters.vesselName[i].value) {
          params = params.set('vesselName', filters.vesselName[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('vesselCode')) {
      for (var i = 0; i < filters.vesselCode.length; i++) {
        if (filters.vesselCode[i].value) {
          params = params.set('vesselCode', filters.vesselCode[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('etd')) {
      for (var i = 0; i < filters.etd.length; i++) {
        if (filters.etd[i].value) {
          let formattedDate = this.datePipe.transform(
            filters.etd[i].value,
            'dd/MM/yyyy'
          );
          let formattedDatMatchMode = filters.etd[i].matchMode;
          if (formattedDate) {
            let etdFilter = {
              value: formattedDate,
              matchMode: formattedDatMatchMode,
            };
            params = params.set('etd', JSON.stringify(etdFilter));
            // params = params.set('etdMatchMode', formattedDatMatchMode);
          }
        }
      }
      if (event.filters.hasOwnProperty('eta')) {
        for (var i = 0; i < filters.eta.length; i++) {
          if (filters.eta[i].value) {
            let formattedDate = this.datePipe.transform(
              filters.eta[i].value,
              'dd/MM/yyyy'
            );
            let formattedDatMatchMode = filters.eta[i].matchMode;
            console.log(`formattedDatMatchMode ${formattedDatMatchMode}`);
            if (formattedDate) {
              params = params.set('eta', formattedDate);
              params = params.set('etaMatchMode', formattedDatMatchMode);
            }
          }
        }
      }
      if (event.filters.hasOwnProperty('shippingLine')) {
        for (var i = 0; i < filters.shippingLine.length; i++) {
          if (filters.shippingLine[i].value) {
            params = params.set('shippingLine', filters.shippingLine[i].value);
          }
        }
      }
      if (event.filters.hasOwnProperty('buyer')) {
        for (var i = 0; i < filters.buyer.length; i++) {
          if (filters.buyer[i].value) {
            params = params.set('buyer', filters.buyer[i].value);
          }
        }
      }
    }

    this.billOfLadingService.getListBillOfLading(params).subscribe(
      (response: Page<BillOfLading>) => {
        this.billOfLadings = response.content;
        this.first =
          response.pageable.pageNumber * response.pageable.pageSize + 1;
        const max =
          response.pageable.pageNumber * response.pageable.pageSize +
          response.pageable.pageSize;
        this.last = max < response.totalElements ? max : response.totalElements;
        this.totalRecords = response.totalElements;
        console.log(`response is ${JSON.stringify(response)}`);
      },
      (error) => { },
      () => {
        this.loading = false;
      }
    );
  }

  globalFilter(value: any, table: Table) {
    console.log(`globalFilter  ${JSON.stringify(value)}`);
    const globalFilter = this.globalFiterInput.nativeElement.value;
    console.log(`value ${globalFilter}`);
    if (globalFilter) {
      let params = new HttpParams();
      params = params.append('q', globalFilter);
      this.billOfLadingService.getBillOfLadingGlobalFilter(params).subscribe(
        (response: Page<BillOfLading>) => {
          this.billOfLadings = response.content;
          this.first =
            response.pageable.pageNumber * response.pageable.pageSize + 1;
          const max =
            response.pageable.pageNumber * response.pageable.pageSize +
            response.pageable.pageSize;
          this.last =
            max < response.totalElements ? max : response.totalElements;
          this.totalRecords = response.totalElements;
        },
        (error) => { },
        () => { }
      );
    } else {
      table.clear();
    }
  }

  clear(table: Table) {
    table.clear();
  }

  initTableColumns() {
    this._selectedColumns = ['Vessel Name', 'ETD', 'ETA'];
    this.cols = [
      'Vessel Name',
      'Vessel Code',
      'ETD',
      'ETA',
      'Shipping Line',
      'Buyer',
    ];
  }

  deleteRows() {
    const ids: number[] = this.selectedBillOfLadings.map(
      (bol) => bol.billOfLadingId
    );

    if (ids.length === 0) {
      this.toastMessagesService.showWarningMessage(
        'No Selection',
        'No rows selected for deletion.'
      );
      return;
    }

    this.confirmationService.confirm({
      header: 'Delete Bill Of Lading',
      message: 'Are you sure you want to delete this bill of lading?',
      accept: () => {
        this.billOfLadingService.deleteBillOfLading(ids).subscribe(
          () => {
            this.toastMessagesService.showInfoMessage(
              'Success',
              'Bill Of Lading Deleted Successfully'
            );
            this.refreshTable();
          },
          () => {
            this.toastMessagesService.showErrorMessage(
              'Operation Unsuccessful',
              'An error occurred. Please try again.'
            );
          }
        );
      },
    });
  }

  refreshTable() {
    this.billOfLadingService
      .getListBillOfLading()
      .subscribe((response: Page<BillOfLading>) => {
        this.billOfLadings = response.content;
        this.first =
          response.pageable.pageNumber * response.pageable.pageSize + 1;
        const max =
          response.pageable.pageNumber * response.pageable.pageSize +
          response.pageable.pageSize;
        this.last = max < response.totalElements ? max : response.totalElements;
        this.totalRecords = response.totalElements;
        this.selectedBillOfLadings = [];
      });
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) => val.includes(col));
  }
  getSelectedBillOfLadings() {
    console.log(`Selected BLS length is ${this.selectedBillOfLadings.length} 
     and \nData is ${JSON.stringify(this.selectedBillOfLadings)}`);
  }

  onRowSelect(event: any) {
    //this.messageService.add({ severity: 'info', summary: 'Product Selected', detail: event.data.name });
  }

  onRowClick(data: BillOfLading) {
    console.log(`onRowClick Data is ${JSON.stringify(data)}`);
    this.billOfLadingSharedDataService.saveDataToLocalStorage(data);
    console.log(
      `Get data ${JSON.stringify(
        this.billOfLadingSharedDataService.getDataFromLocalStorage()
      )}`
    );
    this.router.navigate(['/edit/bill-of-lading']);
  }

  populateBreadCrumb() {
    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.items = [{ label: 'Bill Of Ladings' }];
  }
  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }

  onEditBL(bl: any) {
    console.log(`BL_VIEW DATA IS ${JSON.stringify(bl)}`);
    this.encryptedStorageService
      .setEncryptedDataToStorage(AppConstants.BL_VIEW, bl)
      .subscribe((response: any) => {
        console.log(`Data Stored for BL`);
      });
    // this.billOfLadingSharedDataService.saveBLIdLocalStorage(bl.billOfLadingId);
    this.router.navigate(['/view/bill-of-lading', bl.blUUID]);
  }
  ngOnDestroy(): void { }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = this.utilService.isMobile();
    console.log(`is mobile ${this.utilService.isMobile()}`);
    // this.tableStyle = this.utilService.getTableSize();
    this.initTableStyle();
  }

  initSpeedDial() {
    this.billOfLadingSpeedDialItems = [
      {
        icon: 'pi pi-filter-slash',
        command: () => {
          this.globalFiterInput.nativeElement.value = '';
          this.table.clear();
        },
      },
      {
        icon: 'pi pi-search',
        command: () => {
          this.focusOnGlobalFilter();
        },
      },
      {
        icon: 'pi pi-trash',
        command: () => {
          this.deleteRows();
          //this.messageService.add({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
        },
      },
      {
        icon: 'pi pi-plus',
        command: () => {
          this.router.navigate(['/add/bill-of-lading']);
        },
      },
    ];
  }

  focusOnGlobalFilter() {
    this.globalFiterInput.nativeElement.focus();
  }

  clearFilter(dt: any) {
    this.table.clear();
  }

  // Add this property to your class
  private hidingInProgress = false;

  showShareContextMenu(
    event: MouseEvent,
    rowIndex: number,
    bl: BillOfLading,
    contextMenu: ContextMenu
  ): void {
    this.shareBLUUID = bl.blUUID;
    this.shareBLShortUrl = bl.shortenUrl;
    // Prevent opening a new menu if there's a menu being hidden
    if (this.hidingInProgress) return;

    this.hidingInProgress = true;

    // Stop propagation to prevent the event reaching higher level components
    event.stopPropagation();

    // Show the context menu and set currentRowIndex
    contextMenu.show(event);
    this.currentRowIndex = rowIndex - 1;

    // Since the show method is asynchronous, we need to delay our positioning code slightly
    setTimeout(() => {
      const cmElement = contextMenu.containerViewChild?.nativeElement as HTMLElement | undefined;
      if (cmElement) {
        // Use the event's clientX and clientY properties to get the mouse click position
        const clickPosition = { x: event.clientX, y: event.clientY };
        // Calculate the offsets based on the dimensions of the context menu or the clicked row
        const offsetY = cmElement.offsetHeight / 2;
        const offsetX = cmElement.offsetWidth / 2;
        // Adjust the context menu position
        cmElement.style.top = `${clickPosition.y - offsetY}px`;
        cmElement.style.left = `${clickPosition.x - offsetX}px`;
        this.hidingInProgress = false;
      }

    }, 100); // Adjust this delay if needed
  }

  /*  this method has an issue it shows context menu at random position
  showShareContextMenu(
    event: MouseEvent,
    rowIndex: number,
    bl: BillOfLading
  ): void {
    console.log('contextMenus', this.contextMenus.length);
    console.log('rowIndex', rowIndex);
    // console.table(bl);
    this.contextMenus.forEach((cm, index) => {
      if (index !== rowIndex) {
        cm.hide();
      }
    });
    event.stopPropagation(); // stop propagation to prevent the event reaching higher level components
    const contextMenu = this.contextMenus.toArray()[rowIndex - 1];
    contextMenu.show(event);
    this.currentRowIndex = rowIndex - 1; // for copying public url

    // Since the show method is asynchronous, we need to delay our positioning code slightly
    setTimeout(() => {
      const cmElement = contextMenu.containerViewChild
        .nativeElement as HTMLElement;

      // Get the td element's position and dimension
      const rect = (event.target as HTMLElement)
        .closest('td')!
        .getBoundingClientRect();

      // Adjust the context menu position
      cmElement.style.top = `${rect.bottom}px`;
      cmElement.style.left = `${rect.left}px`;
    });
  } */

  private shareItemsInit() {
    this.shareItems = [
      {
        icon: 'pi pi-copy',
        label: 'Copy Link',
        command: () => this.copyPublicBlViewLink(this.shareBLShortUrl),
      },
      {
        icon: 'pi pi-external-link',
        label: 'Open Link',
        command: () => this.openInNewTabPublicBlViewLink(this.shareBLShortUrl),
      },
      {
        icon: 'pi pi-whatsapp',
        label: 'Share WhatsApp',

        command: () => {
          let message =
            'Please review the packing list on Lumberlinq via this secure link: \n';

          this.copyPublicBlViewLink(this.shareBLShortUrl),
            setTimeout(() => {
              message += this.copyUrl;
              const encodedMessage = encodeURIComponent(message);

              // Define the WhatsApp number here (e.g., '1234567890')
              const whatsappNumber = 'your_whatsapp_number_here';

              // Construct the WhatsApp URL
              const whatsappUrlnew = `https://api.whatsapp.com/send?text=${encodedMessage}. ${encodeURIComponent("\n\nThis link will open the digital packing list, complete with container photos and a comprehensive container summary.")}`;

              // Open the URL to send the message via WhatsApp
              window.open(whatsappUrlnew, '_blank');
            }, 200);

          // Encode the message for URL usage
        },
      },
    ];
  }


  copyPublicBLViewLink(shortUrl: string, isForWhatsapp: boolean = true) {
    const origin = window.location.origin;
    let path = origin; // + AppConstants.PUBLIC_URL_CONTAINER + bluuid;
    path = AppConstants.PUBLIC_SHARE_URL + shortUrl;

    this.clipboardService.copy(path);
    this.copyUrl = path;

    if (isForWhatsapp) {
      this.messageService.add({
        severity: 'success',
        summary: 'Copied',
        detail: 'Link Copied',
      });
    }
  }

  copyPublicBlViewLink(bluuid: string) {
    // const origin = window.location.origin;
    let path = '';
    path = AppConstants.PUBLIC_SHARE_URL + bluuid;
    this.copyUrl = path;
    this.clipboardService.copy(path);

    this.messageService.add({
      severity: 'success',
      summary: 'Copied',
      detail: 'Link Copied',
    });
  }

  openInNewTabPublicBlViewLink(bluuid: string) {
    // const origin = window.location.origin;
    let path = AppConstants.PUBLIC_SHARE_URL + bluuid;
    console.log('URL without path:', origin);
    window.open(path, '_blank');
  }
  debug(event: any) {
    console.log('Filter event:', event);
  }
  clearAllFilter(table: Table, field: HTMLInputElement) {
    field.value = ''; // cl
    table.clear();
  }

  initTableStyle() {
    this.isMobile = this.utilService.isMobile();
    this.tableStyle = this.utilService.getTableSize();
    this.tableStyleOptions = this.utilService.getTableMinWidth();
  }

  onViewBL(bl: any) {
    this.router.navigate(['/bl-view/', bl.shortenUrl]);
  }
}
