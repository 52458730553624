import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { Page } from 'src/app/commons/models/page';
import { Container } from '../model/container';
import { Photo } from '../model/photo';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';
import { PrivacyType } from 'src/app/commons/enums/privacy-type';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class ContainerService {
  totalRecords!: number;

  private containerNumberSource = new BehaviorSubject<any>('');
  currentContainerNumber = this.containerNumberSource.asObservable();
  groupedContainerType!: {
    label: string;
    value: string;
    items: { label: string; value: string }[];
  }[];

  constructor(
    private http: HttpClient,
    private encryptedStorageService: EncryptedStorageService
  ) { }

  changeContainerNumber(containerNumber: any) {
    this.containerNumberSource.next(containerNumber);
  }

  /* getData(): Observable<Container> {
    return this.http.get<Container>(AppConstants.API_URL + 'containers');
  } */

  postData(data: Container): Observable<Container> {
    const url = AppConstants.API_URL + 'api/v1/containers';
    console.log(`postData ${url}`);
    return this.http.post<Container>(url, data, { responseType: 'json' });
  }

  updateContainer(data: Container, containerMismatchContinueAnyway: boolean = false): Observable<Container> {
    const url = AppConstants.API_URL + `api/v1/containers/${data.containerId}?containerMismatchContinueAnyway=${containerMismatchContinueAnyway}`;
    console.log(`updateContainer ${url}`);
    return this.http.put<Container>(url, data, { responseType: 'json' });
  }

  checkByContainerNumber(containerNumber: string): Observable<any | undefined> {
    const response = this.http.get(
      AppConstants.API_URL +
      `api/v1/check-container-number?containerNumber=${containerNumber}`,
      httpOptions
    );

    return response;
  }

  isContainerNumberExists(containerNumber: string) {
    const response = this.http.get(
      AppConstants.API_URL +
      `api/v1/is-container-number-exists?containerNumber=${containerNumber}`
    );

    return response;
  } // is-container-id-exists

  isContainerIdExists(containerId: number) {
    const response = this.http.get(
      AppConstants.API_URL +
      `api/v1/is-container-id-exists?containerId=${containerId}`
    );

    return response;
  } // is-container-id-exists

  getByContainerSeal(seal: string): Observable<any | undefined> {
    const response = this.http.get(
      AppConstants.API_URL + `api/v1/check-container-seal?seal=${seal}`,
      httpOptions
    );
    console.log(`getByContainerSeal Response is ${JSON.stringify(response)}`);
    return response;
  }

  // <Page<Book>
  getContainersList(httpParams?: HttpParams): Observable<Page<Container>> {
    let url = `${AppConstants.API_URL}api/v1/containers/filter?`;
    if (httpParams) {
      url = url + httpParams;
    }
    console.log(`url is ${url}`);
    return this.http.get<Page<Container>>(url);
  }

  getContainersListGlobalFilter(
    httpParams?: HttpParams
  ): Observable<Page<Container>> {
    let url = `${AppConstants.API_URL}api/v1/containers/search?`;
    if (httpParams) {
      url = url + httpParams;
    }
    console.log(`url is ${url}`);
    return this.http.get<Page<Container>>(url);
  }

  deleteContainers(id: any): Observable<string> {
    let url = `${AppConstants.API_URL}api/v1/containers/${id}`;
    console.log(`url is ${url}`);
    return this.http.delete<string>(url);
  }

  getByContainerId(containerId: any): Observable<Container> {
    let url = `${AppConstants.API_URL}api/v1/containers/${containerId}`;

    // console.log(`url is ${url}`)

    return this.http.get<Container>(url);
  }

  fetchDataVirtualScroll(containerId: number, offset: number, limit: number) {
    // const apiUrl = `${AppConstants.API_URL}virtualScroll/${containerId}`;
    // @GetMapping("/measurements/lazy-load/{container-id}")
    const apiUrl = `${AppConstants.API_URL}api/v1/measurements/lazy-load/${containerId}`;
    const params = { offset: offset!.toString(), limit: limit!.toString() };

    return this.http
      .get<{ data: any[]; totalRecords: number }>(apiUrl, { params })
      .pipe(
        map((response) => {
          this.totalRecords = response.totalRecords;
          return response.data;
        })
      );
  }

  fetchData(
    containerId: number,
    offset: number,
    limit: number
  ): Observable<any> {
    // const API_URL = `${AppConstants.API_URL}virtualScroll/${containerId}`;
    const API_URL = `${AppConstants.API_URL}api/v1/measurements/lazy-load/${containerId}`;

    const params = { offset: offset.toString(), limit: limit.toString() };
    console.log(`fetch Data url is ${API_URL}`);
    console.log(`Parameter  ${JSON.stringify(params)}`);
    return this.http.get<any>(API_URL, { params });
  }

  uploadImage(file: File): Observable<Photo[]> {
    const API_URL = `${AppConstants.API_URL}api/images/uploads`;
    console.log(`API URL is ${API_URL}`);
    const formData: FormData = new FormData();
    formData.append('files', file, file.name);
    return this.http.post<Photo[]>(API_URL, formData);
  }

  getContainerImages(containerId: number): Observable<Photo[]> {
    // const API_URL = `${AppConstants.API_URL}containerImages/${containerId}`;
    ///containers/{container-id}/images
    let API_URL1 = `${AppConstants.API_URL}api/v1/containers/${containerId}/images`;
    console.log(`****API URL is ${API_URL1}`);
    //const formData: FormData = new FormData();
    return this.http.get<Photo[]>(API_URL1);
  }

  getContainerImagesByContainerUUID(containerId: any): Observable<Photo[]> {
    ///containers/{container-uuid}/images
    const API_URL = `${AppConstants.API_URL}public/api/v1/containers/${containerId}/images`;
    console.log(`API URL is ${API_URL}`);
    const formData: FormData = new FormData();
    return this.http.get<Photo[]>(API_URL);
  }

  uploadContainerImages(file: File, containerId: number): Observable<Photo[]> {
    const API_URL = `${AppConstants.API_URL}api/v1/upload-container-images`;
    console.log(`API URL is ${API_URL}`);
    const formData: FormData = new FormData();
    formData.append('files', file, file.name);
    formData.append('containerId', containerId.toString());
    return this.http.post<Photo[]>(API_URL, formData);
  }

  deleteByPhotoId(photoId: number): Observable<Photo> {
    const API_URL = `${AppConstants.API_URL}api/v1/delete-image/${photoId}`;
    console.log(`API URL is ${API_URL}`);
    return this.http.delete<Photo>(API_URL);
  }

  deleteByFileName(fileName: string): Observable<Photo> {
    const API_URL = `${AppConstants.API_URL}api/v1/delete-by-filename`;
    const params = { offset: fileName };
    console.log(`API URL is ${API_URL} and body parmas are ${params} `);
    return this.http.delete<Photo>(API_URL, { params });
  }

  getContainerIDFromUUID(containerUUID: string): Observable<any> {
    ///public/containerNumberFromUUID
    const API_URL = `${AppConstants.API_URL}public/api/v1/containers/${containerUUID}/container-number`; ///containers/{container-uuid}/container-number
    const params = { offset: containerUUID };
    console.log(`API URL is ${API_URL} and body parmas are ${params} `);
    return this.http.get<any>(API_URL);
  }

  getContainerNumberFromUUID(containerUUID: string): Observable<any> {
    ///public/containerNumberFromUUID
    const API_URL = `${AppConstants.API_URL}public/api/v1/containers/${containerUUID}/container-number`; ///containers/{container-uuid}/container-number
    const params = { offset: containerUUID };
    console.log(`API URL is ${API_URL} and body parmas are ${params} `);
    return this.http.get<any>(API_URL);
  }

  fetchDataPublicVirtualScroll(
    containerUUID: string,
    offset: number,
    limit: number
  ): Observable<any> {
    // "/containers/{container-uuid}/packing-list"
    const API_URL = `${AppConstants.API_URL}public/api/v1/containers/${containerUUID}/packing-list`;

    const params = { offset: offset.toString(), limit: limit.toString() };
    console.log(`fetch Data url is ${API_URL}`);
    console.log(`Parameter  ${JSON.stringify(params)}`);
    return this.http.get<any>(API_URL, { params });
  }

  getContainerNumberFromID(id: number): Observable<any> {
    const API_URL = `${AppConstants.API_URL}api/v1/container-number-from-id/${id}`;
    const params = { offset: id };
    console.log(`API URL is ${API_URL} and body parmas are ${params} `);
    return this.http.get<any>(API_URL);
  }

  setContainerInStorage(data: any) {
    // this.encryptedStorageService.setEncryptedDataToStorage(AppConstants.CONTAINER,data).subscribe();

    this.encryptedStorageService
      .setEncryptedDataToStorage(AppConstants.CONTAINER, data)
      .subscribe(
        () => {
          console.log('Data encrypted and stored successfully.');

          // Get encrypted data from local storage
          this.encryptedStorageService
            .getEncryptedDataFromStorage(AppConstants.CONTAINER)
            .subscribe(
              (data) => {
                console.log('Decrypted data:', data);
              },
              (error) => {
                console.error('Failed to decrypt data:', error);
              }
            );
        },
        (error) => {
          console.error('Failed to encrypt and store data:', error);
        }
      );
  }

  // /containers/fetch/{containerId}
  getContainerDTOFromId(id: number): Observable<any> {
    const API_URL = `${AppConstants.API_URL}api/v1/containers/fetch/${id}`;
    console.log(`API URL is ${API_URL}`);

    return this.http.get<any>(API_URL);
  } //sea-ports

  getSeaPorts(): Observable<any> {
    const API_URL = `${AppConstants.API_URL}api/v1/sea-ports`;
    console.log(`API URL is ${API_URL}`);
    return this.http.get<any>(API_URL);
  } //fumigation-type

  getFumigationType(): Observable<any> {
    const API_URL = `${AppConstants.API_URL}api/v1/fumigation-type`;
    console.log(`API URL is ${API_URL}`);
    return this.http.get<any>(API_URL);
  }

  // /containers/{containerId}/summary

  getContainerSummaryByContainerId(containerId: number): Observable<any> {
    const API_URL = `${AppConstants.API_URL}api/v1/containers/${containerId}/summary`;
    console.log(`API URL is ${API_URL}`);
    return this.http.get<any>(API_URL);
  }

  getContainerSummaryByContainerUUID(containerUUID: string): Observable<any> { ///containers/uuid//{containerUUID}/summary
    //const API_URL = `${AppConstants.API_URL}api/v1/containers/uuid/${containerUUID}/summary`;//public/api/v1
    const API_URL = `${AppConstants.API_URL}public/api/v1/containers/uuid/${containerUUID}/summary`;//public/api/v1
    console.log(`API URL is ${API_URL}`);
    return this.http.get<any>(API_URL);
  }

  getContainerNumbersApi(containerNumber: string): Observable<any> {
    const API_URL = `${AppConstants.API_URL}api/v1/containers/autocomplete?query=${containerNumber}`;
    console.log(`API URL is ${API_URL}`);
    return this.http.get<any>(API_URL);
  }

  createContainerType() {
    this.groupedContainerType = [
      {
        label: 'Regular Sized Cargo',
        value: 'regular',
        items: [
          { label: '20 Dry Standard', value: '20 Dry Standard' },
          { label: '40 Dry Standard', value: '40 Dry Standard' },
          { label: '40 Dry High', value: '40 Dry High' },
          { label: '45 Dry High', value: '45 Dry High' },
          { label: '20 Tank', value: '20 Tank' },
          { label: '40 Tank', value: '40 Tank' },
        ],
      },
      {
        label: 'Reefer Container',
        value: 'reefer',
        items: [
          { label: '20 Reefer Standard', value: '20 Reefer Standard' },
          { label: '40 Reefer High', value: '40 Reefer High' },
        ],
      },
      {
        label: 'Odd Sized Container',
        value: 'odd',
        items: [
          { label: '20 Open Top', value: '20 Open Top' },
          { label: '40 Open Top', value: '40 Open Top' },
          { label: '40 Open Top High', value: '40 Open Top High' },
          { label: '40 Flat Standard', value: '40 Flat Standard' },
          { label: '40 Flat High', value: '40 Flat High' },
          { label: '20 Flat', value: '20 Flat' },
        ],
      },
      {
        label: 'Truck',
        value: 'truck',
        items: [
          { label: 'Small Truck', value: 'Small Truck' },
          { label: 'Medium Truck', value: 'Medium Truck' },
          { label: 'Large Truck', value: 'Large Truck' },
          { label: 'Articulated Truck', value: 'Articulated Truck' },
        ],
      },
      {
        label: 'Trailer',
        value: 'trailer',
        items: [
          { label: 'Flatbed Trailer', value: 'Flatbed Trailer' },
          { label: 'Dry Van Trailer', value: 'Dry Van Trailer' },
          { label: 'Reefer Trailer', value: 'Reefer Trailer' },
          { label: 'Lowboy Trailer', value: 'Lowboy Trailer' },
          { label: 'Tanker Trailer', value: 'Tanker Trailer' },
        ],
      },
      {
        label: 'Train',
        value: 'train',
        items: [
          { label: 'Boxcar', value: 'Boxcar' },
          { label: 'Flatcar', value: 'Flatcar' },
          { label: 'Tank Car', value: 'Tank Car' },
          { label: 'Reefer Car', value: 'Reefer Car' },
          { label: 'Hopper Car', value: 'Hopper Car' },
        ],
      },
    ];
  }

  getContainerById(id: number): Observable<Container> {
    const API_URL = `${AppConstants.API_URL}api/v1/containers/autocomplete/${id}`;
    console.log(`API URL is ${API_URL}`);
    return this.http.get<Container>(API_URL);
  }


  private uploadUrl =  AppConstants.API_URL + `api/v1/documents/upload/multiple`; 
  uploadFiles(files: File[]): Observable<any> {
    const formData: FormData = new FormData();
    files.forEach(file => formData.append('files', file, file.name));

    const headers = new HttpHeaders({
      'Accept': 'application/json'
    });

    return this.http.post(this.uploadUrl, formData, { headers });
  }


  uploadFilesToContainer(files: File[], containerId: number, privacyType: PrivacyType): Observable<any> {
    const formData: FormData = new FormData();
    files.forEach(file => formData.append('files', file, file.name));
    formData.append('privacy', privacyType.toString());

    console.log(`Privacy Type: ${privacyType.toString()}`);
    const url =  AppConstants.API_URL + `api/v1/documents/containers/${containerId}/uploads`; 
    const headers = new HttpHeaders({
      'Accept': 'application/json'
    });
    console.log(`Url for upload Files to container is ${url}`);
    return this.http.post(url, formData, { headers });
  }

  // uploadFilesToContainer(files: File[], containerId: number, privacyType: PrivacyType): Observable<any> {
  //   const formData: FormData = new FormData();
  
  //   // Append files to the FormData
  //   files.forEach(file => formData.append('files', file, file.name));
  
  //   // Append privacyType as a string (FormData only accepts strings or Blobs)
  //   formData.append('privacy', privacyType.toString()); // Ensure this matches the enum string format
  
  //   const url = AppConstants.API_URL + `api/v1/documents/containers/${containerId}/uploads`;
  
  //   console.log(`Url for uploading files to container is ${url}`);
  
  //   // Let the browser handle 'Content-Type' with FormData
  //   return this.http.post(url, formData); // No 'Content-Type' headers needed
  // }
  
  
  

  getDocumentsSecuredUrl(containerId: number): Observable<any> { 
    const API_URL = `${AppConstants.API_URL}api/v1/containers/${containerId}/documents-secured-url`;
    console.log(`API URL is ${API_URL}`);
    return this.http.get<any>(API_URL);
  }

  updatePrivacy(ids: number[], privacyType: PrivacyType): Observable<string> {
    const url = AppConstants.API_URL + `api/v1/documents/update-privacy`;
    const requestBody = {
      ids: ids,
      privacyType: privacyType
    };

    return this.http.put<string>(`${url}`, requestBody);
  }

  getDocumentsByContainerId(containerId: number): Observable<any> {
    const API_URL = `${AppConstants.API_URL}api/v1/containers/${containerId}/documents`;
    console.log(`API URL is ${API_URL}`);
    return this.http.get<any>(API_URL);
  }
  

  deleteDocument(fileId: number): Observable<void> {
    const API_URL = `${AppConstants.API_URL}api/v1/documents/${fileId}`;
    console.log(`API URL is ${API_URL}`);
    return this.http.delete<void>(API_URL);
  } 

  deleteMultipleDocuments(fileIds: number[]): Observable<void> {
    const API_URL = `${AppConstants.API_URL}api/v1/documents/delete-multiple`;
    const params = new HttpParams().set('fileIds', fileIds.join(','));
    console.log(`API URL is ${API_URL} with fileIds ${fileIds}`);
    return this.http.delete<void>(API_URL, { params });
  }

  getTallySummaryByContainerId(containerId: number): Observable<any> {
    const API_URL = `${AppConstants.API_URL}api/v1/containers/${containerId}/tally-summary`;
    console.log(`API URL is ${API_URL}`);
    return this.http.get<any>(API_URL);
  }

  getContainerDetails(containerId: number): Observable<any> {
    const API_URL = `${AppConstants.API_URL}api/v1/container-details/${containerId}`;
    console.log(`API URL is ${API_URL}`);
    return this.http.get<any>(API_URL);
  }
}
