import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { SellerService } from '../../seller/services/seller.service';
import { Observable, of, from, switchMap } from 'rxjs';
import { EncryptionService } from 'src/app/services/encryption.service';

@Injectable({
  providedIn: 'root',
})
export class SellerDetailsResolver implements Resolve<any> {
  constructor(
    private sellerService: SellerService, 
    private router: Router,
    private encryptionService: EncryptionService) { }

  // resolve(route: ActivatedRouteSnapshot): Observable<any> {
  //   const encryptedId = route.paramMap.get('id');
  //   if (encryptedId) {
  //     const decodedId = atob(encryptedId); // Decrypt the ID
  //     return this.sellerService.getSellerById(decodedId).pipe(
  //       tap((response) => {
  //         console.log(`SellerDetailsResolver response is ${JSON.stringify(response)}`)
  //       }),
  //       catchError((error) => {
  //         console.error(`SellerDetailsResolver error is ${JSON.stringify(error)}`);
  //         this.router.navigate(['/error']); // Redirect on error
  //         return of(null);
  //       })
  //     );
  //   } else {
  //     this.router.navigate(['/sellers']);
  //     return of(null);
  //   }
  // }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const encryptedId = route.paramMap.get('id');
    if (encryptedId) {
      return from(this.encryptionService.decrypt(encryptedId)).pipe(
        switchMap((decodedId: any) =>
          this.sellerService.getSellerById(decodedId).pipe(
            tap((response) => {
              console.log(`SellerDetailsResolver response is ${JSON.stringify(response)}`);
            }),
            catchError((error) => {
              console.error(`SellerDetailsResolver error is ${JSON.stringify(error)}`);
              this.router.navigate(['/error']); // Redirect on error
              return of(null);
            })
          )
        ),
        catchError((error: any) => {
          console.error(`Decryption error: ${error}`);
          this.router.navigate(['/sellers']);
          return of(null);
        })
      );
    } else {
      this.router.navigate(['/sellers']);
      return of(null);
    }
  }
}
