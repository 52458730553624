<div style="width: 90vw">
    <p-card>
        <p-table [value]="measurements" [tableStyle]="{ 'min-width': '50rem' }"
            [loading]="(isLoading1 | async) ?? false">

            <ng-template pTemplate="header">
                <tr>
                    <th>SR </th>
                    <th *ngFor="let col of cols" pSortableColumn="{{col.field}}">{{col.header}} <p-sortIcon
                            field="{{col.field}}"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-measurement let-index="rowIndex">
                <tr>
                    <td>{{ index + 1}}</td>
                    <td *ngFor="let col of cols">{{measurement[col.field]}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="loadingbody">
                <tr *ngFor="let row of dummyRows">
                    <td>
                        <p-skeleton></p-skeleton>
                    </td>
                    <td *ngFor="let col of cols">
                        <p-skeleton></p-skeleton>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-card>
</div>