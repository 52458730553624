import { HttpParams } from '@angular/common/http';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Page } from 'src/app/commons/models/page';
import { Container } from '../model/container';
import { ContainerSharedDataService } from '../services/container-shared-data.service';
import { ContainerService } from '../services/container.service';
import { UtilService } from 'src/app/services/util.service';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ContainerComponent } from '../container/container.component';
import { SHARED_MODULES } from '../../shared-imports';
import { EncryptionService } from 'src/app/services/encryption.service';

@Component({
  selector: 'app-container-list',
  templateUrl: './container-list.component.html',
  styleUrls: ['./container-list.component.css'],
  providers: [ToastMessagesService],
  standalone: true,
  imports: [SHARED_MODULES, ContainerComponent]
})
export class ContainerListComponent implements OnInit {
  formHeader!: string;
  formSubHeader!: string;
  containerSidebarVisible: boolean = false;
  isAddLoadingSiteFromContainer: boolean = false;
  showNameColumn = true;
  showContactPersonColumn = true;
  showGstColumn = true;
  showCityColumn = true;
  showPhoneColumn = false;
  showEmailColumn = false;
  protected items!: MenuItem[];
  protected containersSpeedDialItems!: MenuItem[];
  home!: MenuItem;
  rows = 5;
  containers!: Container[];
  multiSortMeta!: any[];
  totalRecords: number = 0;
  first: number = 0;
  last: number = 0;
  rowsPerPageOptions = [5, 10, 20]; // set the options for the number of rows per page
  @ViewChild('globalFiterInput') globalFiterInput!: ElementRef;
  @ViewChild('dt') table!: Table;
  cols!: any[];
  isMobile = false;
  loading: boolean = false;
  _selectedColumns!: any[];
  selectedContainers: Container[] = [];
  tableStyle: any;
  tableStyleOptions: any;
  ref: DynamicDialogRef | undefined;

  constructor(
    private containerService: ContainerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private containerSharedDataService: ContainerSharedDataService,
    private router: Router,
    private titleService: Title,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private toastMessagesService: ToastMessagesService,
    public dialogService: DialogService,
    private encrptionService: EncryptionService
  ) {
    this.setPageTitle();
    this.isMobile = this.utilService.isMobile();
    this.tableStyle = this.utilService.getTableSize();
  }

  ngOnInit() {
    this.initTableColumns();
    this.populateBreadCrumb();
    this.initSpeedDial();
    this.initTableStyle();
  }

  sortContainers(event: any) {
    this.loading = true;
    const pageNumber = Math.floor(event.first / event.rows) + 1;
    console.log(`Page Number ${pageNumber}`);
    console.log(`Event List ${JSON.stringify(event)}`);
    let params = new HttpParams();
    let sortOrder = '';
    console.log(`customSort field ${[event!.field!]}`);
    // console.log(`customSort data ${[event!.data!]}`)
    console.log(`customSort mode ${[event!.mode!]}`);
    console.log(`customSort order ${[event!.order!]}`);
    console.log(
      `customSort multiSortMeta ${JSON.stringify([event!.multiSortMeta!])}`
    );
    console.log(`multiSortMeta ${event!.multiSortMeta?.length}`);
    const multiSortLength = event!.multiSortMeta?.length;
    params = params.set('size', event.rows);
    params = params.set('page', pageNumber - 1);
    for (var i = 0; i < multiSortLength!; i++) {
      if (event!.multiSortMeta![i].order === -1) {
        sortOrder = 'DESC';
      } else {
        sortOrder = 'ASC';
      }
      console.log(`field ${event!.multiSortMeta![i].field}`);
      console.log(`order ${event!.multiSortMeta![i].order}`);

      if (multiSortLength! > 0) {
        console.log(`in if`);
        params = params.set(
          'sort',
          event!.multiSortMeta![i].field + ',' + sortOrder
        );
      }
    }
    console.log(`Params ${params.toString()}`);
    this.containers = [];
    // filter implementation
    const filters = event.filters;
    if (event.filters.hasOwnProperty('containerNumber')) {
      console.log(`filters ${JSON.stringify(filters.containerNumber.length)}`);
      for (var i = 0; i < filters.containerNumber.length; i++) {
        if (filters.containerNumber[i].value) {
          params = params.set(
            'containerNumber',
            filters.containerNumber[i].value
          );
        }
      }
    }
    if (event.filters.hasOwnProperty('sealNumber')) {
      console.log(`filters ${JSON.stringify(filters.sealNumber.length)}`);
      for (var i = 0; i < filters.sealNumber.length; i++) {
        if (filters.sealNumber[i].value) {
          params = params.set('sealNumber', filters.sealNumber[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('supplier')) {
      console.log(`filters ${JSON.stringify(filters.supplier.length)}`);
      for (var i = 0; i < filters.supplier.length; i++) {
        if (filters.supplier[i].value) {
          params = params.set('supplier', filters.supplier[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('product')) {
      console.log(`filters ${JSON.stringify(filters.product.length)}`);
      for (var i = 0; i < filters.product.length; i++) {
        if (filters.product[i].value) {
          params = params.set('product', filters.product[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('loadingSite')) {
      console.log(`filters ${JSON.stringify(filters.loadingSite.length)}`);
      for (var i = 0; i < filters.loadingSite.length; i++) {
        if (filters.loadingSite[i].value) {
          params = params.set('loadingSite', filters.loadingSite[i].value);
        }
      }
    }

    this.containerService.getContainersList(params).subscribe(
      (response: Page<Container>) => {
//        console.log(`Containers List is ${JSON.stringify(response)}`);
        this.containers = response.content;
        this.first =
          response.pageable.pageNumber * response.pageable.pageSize + 1;
        const max =
          response.pageable.pageNumber * response.pageable.pageSize +
          response.pageable.pageSize;
        this.last = max < response.totalElements ? max : response.totalElements;
        this.totalRecords = response.totalElements;
      },
      (error) => {
        // this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  globalFilter(value: any, table: Table) {
    console.log(`globalFilter  ${JSON.stringify(value)}`);
    const globalFilter = this.globalFiterInput.nativeElement.value;
    console.log(`value ${globalFilter}`);
    if (globalFilter) {
      let params = new HttpParams();
      params = params.append('q', globalFilter);
      this.containerService.getContainersListGlobalFilter(params).subscribe(
        (response: Page<Container>) => {
          this.containers = response.content;
          this.first =
            response.pageable.pageNumber * response.pageable.pageSize + 1;
          const max =
            response.pageable.pageNumber * response.pageable.pageSize +
            response.pageable.pageSize;
          this.last =
            max < response.totalElements ? max : response.totalElements;
          this.totalRecords = response.totalElements;
        },
        (error) => { },
        () => { }
      );
    } else {
      table.clear();
    }
  }

  clear(table: Table) {
    table.clear();
  }

  clearAllFilter(table: Table, field: HTMLInputElement) {
    field.value = ''; // cl
    table.clear();
  }

  initTableColumns() {
    this._selectedColumns = ['Seal', 'Product'];
    this.cols = ['Seal', 'Product', 'Seller', 'Site'];
  }

  deleteRows() {
    const ids: number[] = this.selectedContainers.map(
      (container) => container.containerId
    );

    if (ids.length === 0) {
      this.toastMessagesService.showWarningMessage(
        'No Selection',
        'No rows selected for deletion.'
      );
      return;
    }

    this.confirmationService.confirm({
      header: 'Delete Container',
      message: 'Are you sure you want to delete this container?',
      accept: () => {
        this.containerService.deleteContainers(ids).subscribe(
          () => {
            this.toastMessagesService.showInfoMessage(
              'Success',
              'Container Deleted Successfully'
            );
            this.refreshTable();
          },
          () => {
            this.toastMessagesService.showErrorMessage(
              'Operation Unsuccessful',
              'An error occurred. Please try again.'
            );
          }
        );
      },
    });
  }

  refreshTable() {
    this.containerService
      .getContainersList()
      .subscribe((response: Page<Container>) => {
        this.containers = response.content;
        this.first =
          response.pageable.pageNumber * response.pageable.pageSize + 1;
        const max =
          response.pageable.pageNumber * response.pageable.pageSize +
          response.pageable.pageSize;
        this.last = max < response.totalElements ? max : response.totalElements;
        this.totalRecords = response.totalElements;
        this.selectedContainers = [];
      });
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) => val.includes(col));
  }
  getSelectedContainer() {
    console.log(`Selected Containers length is ${this.selectedContainers.length
      } 
     and \nData is ${JSON.stringify(this.selectedContainers)}`);
  }

  onRowSelect(event: any) {
    //this.messageService.add({ severity: 'info', summary: 'Container Selected', detail: event.data.name });
  }

  onRowClick(data: Container) {
    console.log(`onRowClick Data is ${JSON.stringify(data)}`);
    const id = "" + data.containerId;
    // const encryptedId = btoa(id); // Encrypt the ID
    this.encrptionService.encrypt(id).then((encryptedId) => { // Encrypt the ID
      this.router.navigate(['/edit/container', encryptedId]);
    });
  }

  populateBreadCrumb() {
    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.items = [{ label: 'Containers' }];
  }

  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = this.utilService.isMobile();
    console.log(`is mobile ${this.utilService.isMobile()}`);
    // this.tableStyle = this.utilService.getTableSize();
    this.initTableStyle();
  }

  onContainerSubmit(event: Event) {
    this.table.reset();
  }

  initSpeedDial() {
    this.containersSpeedDialItems = [
      {
        icon: 'pi pi-filter-slash',
        command: () => {
          this.globalFiterInput.nativeElement.value = '';
          this.table.clear();
        },
      },
      {
        icon: 'pi pi-search',
        command: () => {
          this.focusOnGlobalFilter();
        },
      },
      {
        icon: 'pi pi-trash',
        command: () => {
          this.deleteRows();
          //this.messageService.add({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
        },
      },
      {
        icon: 'pi pi-plus',
        command: () => {
          this.containerSidebarVisible = true;
          // this.deleteRows()
          //this.messageService.add({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
        },
      },
    ];
  }

  focusOnGlobalFilter() {
    this.globalFiterInput.nativeElement.focus();
  }

  clearFilter(dt: any) {
    this.table.clear();
  }

  initTableStyle() {
    this.isMobile = this.utilService.isMobile();
    this.tableStyle = this.utilService.getTableSize();
    this.tableStyleOptions = this.utilService.getTableMinWidth();
  }

  testDynamicDialog() {
    this.ref = this.dialogService.open(ContainerComponent, {
      header: 'Select a Product',
      width: '70%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true
    });

    // this.ref.onClose.subscribe((product: Product) => {
    //     if (product) {
    //         this.messageService.add({ severity: 'info', summary: 'Product Selected', detail: product.name });
    //     }
    // });

    // this.ref.onMaximize.subscribe((value) => {
    //     this.messageService.add({ severity: 'info', summary: 'Maximized', detail: `maximized: ${value.maximized}` });
    // });
  }

  viewContainer(containerShortCode: string){
    console.log(`view Container ${containerShortCode}`);
      window.open(`/container-view/${containerShortCode}`, '_blank');
  }
}
