export class Summary {
    dummyArrayHorizontalGrid = ['Helo'];
    logsCount!: number;
    totalGrossVol!: number;
    totalNetVol!: number;
    grossCftAvg!: number;
    netCftAvg!: number;
    lengthSummary: Array<any> = [];
    girthSummary: Array<any> = [];
}
