import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MenuItem, SelectItem } from 'primeng/api';
import { BehaviorSubject, Observable, finalize } from 'rxjs';
import { SAWN_ACTION } from '../../model/sawn-timber/sawn-action-enum';
import { SawnTimberService } from '../../services/sawn-timber.service';
import { each } from 'lodash';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { SawnTimberRow } from '../../model/sawn-timber/sawn-timber-row';
import { fadeAnimation } from 'src/app/commons/fadeAnimation';
import { ContainerService } from 'src/app/modules/container/services/container.service';
import { AppConstants } from 'src/app/commons/app-constants';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import { AddHoppusMeasurementv2Service } from 'src/app/modules/hoppus/services/add-hoppus-measurementv2.service';
import FileSaver from 'file-saver';
import { MeasurementCanDeactivateGuardServiceService } from 'src/app/modules/hoppus/services/measurement-can-deactivate-guard-service.service';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';

// const SAWN_TIMBER_ID = 1;

@Component({
  selector: 'app-sawn-timber-row',
  templateUrl: './sawn-timber-row.component.html',
  styleUrls: ['./sawn-timber-row.component.css'],
  providers: [ToastMessagesService],
  animations: [fadeAnimation],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class SawnTimberRowComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  configDialogWidth: string = '60vw';
  blockedPanel: boolean = false;
  private showSaveOptionDivSubject = new BehaviorSubject<boolean>(false);
  showSaveOptionDiv$: Observable<boolean> =
    this.showSaveOptionDivSubject.asObservable();
  dummyArrayHorizontalGrid = ['Helo'];
  selectedRow: any;
  @Input() container: any; // This is the input property that will receive the data from the parent
  // numberRegex = "^\d+\.\d{3}$";
  numberRegex: RegExp = /^\d+\.\d{3}$/;
  public tableForm!: FormGroup;
  intervalDuration: number | undefined;
  showValidationMessage = false; // add this property
  protected sawnTimberSpeedDialItems!: MenuItem[];
  saveOptionBoolean = false;
  showAutoSaveIntervalDialog = false;
  copyOptionBoolean = true;
  toolbarPopUpMenu!: MenuItem[];
  saveInterval = 5;
  intervalId!: any;

  sawnTimberId!: number;
  sawnTimberConfigId!: number;
  isConfigDialogVisible = false;
  unitOptions!: SelectItem[];
  totalPieces = 0;
  totalCBM = 0;
  totalCFT = 0;
  fetchedRows: any;
  summary: any;
  config: any;
  containerDetails: any;
  public removedRows: Array<FormGroup> = [];

  // Add these new properties to store the state of the rows, the unique_key and to hold the previous state
  private updatedRows = new BehaviorSubject<Array<SawnTimberRow>>([]);
  private uniqueKey = 0;
  private previousState: any = null;

  @ViewChildren('lengthInput') lengthInputList!: QueryList<ElementRef>;
  @ViewChildren('widthInput') widthInputList!: QueryList<ElementRef>;
  @ViewChildren('heightInput') heightInputList!: QueryList<ElementRef>;
  @ViewChildren('piecesInput') piecesInputList!: QueryList<ElementRef>;

  public lengthRange: { min: number; max: number } = { min: 1, max: 100 };
  public widthRange: { min: number; max: number } = { min: 1, max: 100 };
  public heightRange: { min: number; max: number } = { min: 1, max: 100 };
  public piecesRange: { min: number; max: number } = { min: 1, max: 100 };
  tempRowData!: SawnTimberRow;
  prepopulateConfig: any;
  totalRecords: number = 0;
  totalGrossVolumeCbm: number = 0;
  totalNetVolumeCbm: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private sawnTimberService: SawnTimberService,
    private toastMessageService: ToastMessagesService,
    private changeDetector: ChangeDetectorRef,
    private encryptedStorageService: EncryptedStorageService,
    private hoppusServiceV2: AddHoppusMeasurementv2Service,
    private measurementCanDeactivateService: MeasurementCanDeactivateGuardServiceService
  ) {
    this.initConfigDialogWidth();
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  ngAfterViewInit(): void {
    this.intervalDuration = this.saveInterval * 60000;
  }

  ngOnInit(): void {
    this.tableForm = new FormGroup({
      config: new FormGroup({
        enableValidation: new FormControl<boolean>(false),
        minLength: new FormControl('0'),
        maxLength: new FormControl('0'),
        minWidth: new FormControl('0'),
        maxWidth: new FormControl('0'),
        minHeight: new FormControl('0'),
        maxHeight: new FormControl('0'),
        minPieces: new FormControl('0'),
        maxPieces: new FormControl('0'),
        lengthUnit: new FormControl('ft'),
        widthUnit: new FormControl('in'),
        heightUnit: new FormControl('in'),
        autoSaveDuration: new FormControl('2'),
        enableAutoSave: new FormControl<boolean>(false),
      }),
      rows: new FormArray([]),
      // container:  new FormGroup(this.container)
      container: new FormGroup({}), // Initialize container as an empty FormGroup
      summary: new FormGroup({
        netVolume: new FormControl('0'),
        grossVolume: new FormControl('0'),
        netCFT: new FormControl('0'),
        grossCFT: new FormControl('0'),
        pieces: new FormControl('0'),
      }),
      // add more controls if needed
    });

    this.unitOptions = [
      { label: 'mm', value: 'mm' },
      { label: 'cm', value: 'cm' },
      { label: 'in', value: 'in' },
      { label: 'ft', value: 'ft' },
      { label: 'm', value: 'm' },
    ];

    // Add initial rows
    for (let i = 0; i < 1; i++) {}
    this.tableForm
      .get('lengthUnit')
      ?.valueChanges.subscribe(() => this.updateAllRows());
    this.tableForm
      .get('widthUnit')
      ?.valueChanges.subscribe(() => this.updateAllRows());
    this.tableForm
      .get('heightUnit')
      ?.valueChanges.subscribe(() => this.updateAllRows());

    this.getContainerNumberFromStorage();

    // this.dummyData(5);
    //this.createRow(1);

    this.updateAllRows();
    this.initRange();
    this.onRangeChange();
    this.rows.valueChanges.subscribe(() => this.captureUpdatedState());
    this.rows.valueChanges.subscribe(() => this.updateAllRows());
    this.tableForm.get('config')?.valueChanges.subscribe(() => {
      this.onRangeChange();
    });
    this.initSpeedDial();
    this.measurementCanDeactivateService.setForm(this.tableForm);
  }

  /* updateAllRows(): void {
    this.totalPieces = 0;
    this.totalCBM = 0;
    this.totalCFT = 0;
    for (const control of this.rows.controls) {
      const volume = this.calculateVolume(
        +(control.get('length')?.value ?? 0),
        +(control.get('width')?.value ?? 0),
        +(control.get('height')?.value ?? 0)
      );

      const pieces = +(control.get('pieces')?.value ?? 0);
      const cbm = volume * pieces;
      const cft = cbm * 35.315;

      this.totalPieces += pieces;
      this.totalCBM += cbm;
      this.totalCFT += cft;

      // Only round at the very end, after all calculations
      (control.get('cbm') as FormControl).patchValue(Number(cbm.toFixed(3)), {
        emitEvent: false,
      });
      (control.get('cft') as FormControl).patchValue(Number(cft.toFixed(3)), {
        emitEvent: false,
      });

      
    }
  } */
  updateAllRows(): void {
    console.log('updateAllRowsYEYS');
    this.totalPieces = 0;
    this.totalCBM = 0;
    this.totalCFT = 0;
    for (const control of this.rows.controls) {
      const volume = this.calculateVolume(
        +(control.get('length')?.value ?? 0),
        +(control.get('width')?.value ?? 0),
        +(control.get('height')?.value ?? 0)
      );

      const pieces = +(control.get('pieces')?.value ?? 0);
      let cbm = volume * pieces;
      cbm = Number(cbm.toFixed(3)); // Round CBM to 3 decimal places
      const cft = cbm * 35.315;

      this.totalPieces += pieces;
      this.totalCBM += cbm;
      this.totalCFT += cft;

      (control.get('cbm') as FormControl).patchValue(cbm, {
        emitEvent: false,
      });
      (control.get('cft') as FormControl).patchValue(Number(cft.toFixed(3)), {
        emitEvent: false,
      });
    }
  }

  prepopulateRow(data: SawnTimberRow) {
    const row = this.createRow(data);
    // this.rows.push(row);
    return row;
  }

  createRow(data: any | null = null, index?: number) {
    console.log();
    const row = this.formBuilder.group({
      id: [data?.id],
      // serialNumber: [data?.serialNumber],
      serialNumber: [
        data?.serialNumber || (index !== undefined ? index + 1 : null),
      ],

      length: [data?.length || null, [Validators.required]],
      width: [data?.width || null, [Validators.required]],
      height: [data?.height || null, [Validators.required]],
      pieces: [data?.pieces || null, [Validators.required]],
      cbm: [{ value: data?.cbm || 0, disabled: false }],
      cft: [{ value: data?.cft || 0, disabled: false }],
      review: [data?.review || false],
      unique_key: [this.uniqueKey++],
      action: [
        data ? data.action || SAWN_ACTION.UP_TO_DATE : SAWN_ACTION.ADDED,
      ],
      editMode: [false],
      highlight: [false],
    });

    // add subscriptions
    row.valueChanges.subscribe(() => {
      const volume = this.calculateVolume(
        +(row.get('length')?.value ?? 0),
        +(row.get('width')?.value ?? 0),
        +(row.get('height')?.value ?? 0)
      );

      let cbm = volume;
      cbm = Number(cbm.toFixed(3)); // Round CBM to 3 decimal places
      let cft = cbm * 35.315;

      (row.get('cbm') as AbstractControl).patchValue(cbm, {
        emitEvent: false,
      });
      (row.get('cft') as AbstractControl).patchValue(Number(cft.toFixed(3)), {
        emitEvent: false,
      });
    });

    row.valueChanges.subscribe(() => this.updateAllRows());

    // Insert the row into the rows FormArray at the specified index or at the end of the array
    if (index !== undefined && index >= 0 && index <= this.rows.length) {
      this.rows.insert(index, row);
    } else {
      this.rows.push(row);
    }
    if (index) {
      setTimeout(() => {
        this.widthInputList.toArray()[index].nativeElement.focus();
      }, 100);
    }
    // console.log(`Create a  Row `)
    //  console.table(row.value);
    return row;
  }

  createRowFromTable(currentIndex: number | null, nextIndex: number) {
    let newSerialNumber;

    if (nextIndex === this.rows.length) {
      newSerialNumber = nextIndex + 1;
    } else if (currentIndex && nextIndex) {
      newSerialNumber = this.getUniqueSerialNumber(currentIndex, nextIndex);
    }

    const row = this.formBuilder.group({
      id: null,
      serialNumber: [newSerialNumber],
      length: [null, [Validators.required]],
      width: [null, [Validators.required]],
      height: [null, [Validators.required]],
      pieces: [null, [Validators.required]],
      cbm: [{ value: 0, disabled: false }],
      cft: [{ value: 0, disabled: false }],
      review: [false],
      unique_key: [this.uniqueKey++],
      action: [SAWN_ACTION.ADDED],
      editMode: [false],
      highlight: [false],
    });

    // add subscriptions
    row.valueChanges.subscribe(() => {
      const volume = this.calculateVolume(
        +(row.get('length')?.value ?? 0),
        +(row.get('width')?.value ?? 0),
        +(row.get('height')?.value ?? 0)
      );

      const volumeStr = volume ?? 0;
      let cbm = row.get('cbm');
      (cbm as AbstractControl).patchValue(volumeStr.toFixed(3), {
        emitEvent: false,
      });

      let cftStr = volume * 35.315;
      let cft = row.get('cft');
      (cft as AbstractControl).patchValue(cftStr.toFixed(3), {
        emitEvent: false,
      });
    });

    row.valueChanges.subscribe(() => this.updateAllRows());

    // Insert the row into the rows FormArray at the specified index or at the end of the array
    if (
      nextIndex !== undefined &&
      nextIndex >= 0 &&
      nextIndex <= this.rows.length
    ) {
      this.rows.insert(nextIndex, row);
    } else {
      this.rows.push(row);
    }
    if (nextIndex) {
      setTimeout(() => {
        this.lengthInputList.toArray()[nextIndex].nativeElement.focus();
      }, 100);
    }
    // console.log(`Create a  Row `)
    //  console.table(row.value);
    return row;
  }

  calculateVolume(length: number, width: number, height: number): number {
    const lengthUnit = this.tableForm.get('config.lengthUnit')?.value;
    const widthUnit = this.tableForm.get('config.widthUnit')?.value;
    const heightUnit = this.tableForm.get('config.heightUnit')?.value;

    const lengthInMeters = this.convertUnitToMeter(length, lengthUnit);
    const widthInMeters = this.convertUnitToMeter(width, widthUnit);
    const heightInMeters = this.convertUnitToMeter(height, heightUnit);

    const total = lengthInMeters * widthInMeters * heightInMeters;
    return total; // Return the volume with full precision
  }

  convertUnitToMeter(value: number, unit: string): number {
    let conversionFactor = 1;
    switch (unit) {
      case 'mm':
        conversionFactor = 0.001;
        break;
      case 'cm':
        conversionFactor = 0.01;
        break;
      case 'in':
        conversionFactor = 0.0254;
        break;
      case 'ft':
        conversionFactor = 0.3048;
        break;
      case 'm':
        conversionFactor = 1;
        break;
    }
    return value * conversionFactor;
  }

  printData(): void {
    console.log(this.tableForm?.value);
  }

  rangeValidator(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (
        control.value !== undefined &&
        (isNaN(control.value) || control.value < min || control.value > max)
      ) {
        return { range: true };
      }
      return null;
    };
  }

  processData(): Observable<number> | void {
    return this.loadData();
    // if (this.sawnTimberId) {
    //   return this.loadData();
    // } else {
    //   //this.createRow(0);
    // }
  }

  loadData(): Observable<number> {
    return new Observable<number>((subscriber) => {
      //this.sawnTimberService.getTimbers(this.sawnTimberId).subscribe(
      this.sawnTimberService
        .getTimbersFromContainerId(this.container.containerId)
        .subscribe(
          (response: any) => {
            let rowsFetched: SawnTimberRow[] = response.rows;
            this.totalRecords = response.rows.length;
            console.log(
              `Init Before Sort Fetch Table Data ${JSON.stringify(
                response
              )} \n totalRecords ${this.totalRecords}`
            );
            this.fetchedRows = response.rows;
            this.summary = response.summary;
            this.config = response.config;
            console.table(rowsFetched);
            const sawnTimberId = response.id;
            //console.log(`SawnTimberId is***** ${sawnTimberId}`);
            this.prepopulateConfig = response.config;
            this.tableForm.get('config')?.patchValue(this.prepopulateConfig);

            rowsFetched = rowsFetched.sort((a, b) => {
              const serialA = a.serialNumber || 0;
              const serialB = b.serialNumber || 0;
              return serialA - serialB;
            });

            console.log('Init After Sort Fetch Table Data');

            console.table(rowsFetched);

            for (let i = 0; i < rowsFetched.length; i++) {
              const eachRow = rowsFetched[i];
              const row = this.prepopulateRow({
                id: eachRow.id,
                serialNumber: eachRow.serialNumber,
                length: eachRow.length,
                width: eachRow.width,
                height: eachRow.height,
                pieces: eachRow.pieces,
                review: eachRow.review,
                action: SAWN_ACTION.UP_TO_DATE,
                cbm: eachRow.cbm,
                cft: eachRow.cft,
                highlight: false,
              });
            }
            // console.log(`*************`);
            //  console.log(`****** totalRows ******* {}`, rowsFetched.length);
            if (rowsFetched.length < 1) {
              console.log(`****** totalRows ******* {}`, rowsFetched.length);
              // this.createRow(null, 1);
              this.createRow(1);
            }

            subscriber.next(rowsFetched.length);
            subscriber.complete();
            this.initValidations();
          },
          (error: any) => {
            // console.error(`Response is ${JSON.stringify(error)}`);
            console.error(`Response is ${JSON.stringify(error)}`);
            if (error.status === 404) {
              console.error('HTTP 500 Error: ', error);
              this.createRow(1);
              subscriber.next(0);
              subscriber.complete();
              this.initValidations();
              // Handle 500 error here. You might want to notify the user, log the error, etc.
            }
            subscriber.error(error);
          }
        );
    });
  }

  dummyData(rows: number) {
    //3
    for (let i = 0; i < rows; i++) {
      const row = this.prepopulateRow({
        serialNumber: i + 1,
        length: Math.floor(Math.random() * 100) + 1,
        width: Math.floor(Math.random() * 100) + 1,
        height: Math.floor(Math.random() * 100) + 1,
        pieces: Math.floor(Math.random() * 10) + 1,
        review: false,
        action: SAWN_ACTION.UP_TO_DATE,
        highlight: false,
      });
    }
  }

  removeRow(index: number): void {
    // Copy the row to removedRows array
    this.rows.at(index).patchValue({ action: SAWN_ACTION.REMOVED });
    this.removedRows.push(this.rows.at(index) as FormGroup);
    this.rows.at(index).patchValue({ highlight: true, emitEvent: false });
    setTimeout(() => {
      this.rows.at(index).patchValue({ highlight: false, emitEvent: false });
      // Remove the row from the form array
      this.rows.removeAt(index);

      this.updateAllRows();

      // Update serial numbers
      for (let i = index; i < this.rows.length; i++) {
        const row = this.rows.at(i);
        // row.get('serialNumber')?.setValue(i + 1);
      }
      this.tableForm.markAsDirty();
      this.getFilteredRows();
    }, 50);
  }

  onReviewChange(event: Event, row: any) {
    const checked = (event.target as HTMLInputElement)?.checked;
    row.get('review')?.setValue(checked);
  }

  onRangeChange() {
    this.lengthRange.min = this.tableForm?.get('config.minLength')?.value ?? 0;

    this.lengthRange.max = this.tableForm?.get('config.maxLength')?.value ?? 0;

    this.widthRange.min = this.tableForm?.get('config.minWidth')?.value ?? 0;
    this.widthRange.max = this.tableForm?.get('config.maxWidth')?.value ?? 0;

    this.heightRange.min = this.tableForm?.get('config.minHeight')?.value ?? 0;
    this.heightRange.max = this.tableForm?.get('config.maxHeight')?.value ?? 0;

    this.piecesRange.min = this.tableForm?.get('config.minPieces')?.value ?? 0;
    this.piecesRange.max = this.tableForm?.get('config.maxPieces')?.value ?? 0;
  }

  moveFocus(
    currentField: any,
    nextField: any,
    piecesInput: any,
    index: number,
    event: any,
    addNewRow = false
  ) {
    event.preventDefault();

    if (!currentField.value) {
      return;
    }

    if (addNewRow) {
      this.createRow(null, index + 1);
      setTimeout(() => {});
    } else {
      nextField.focus();
    }
  }

  navigateUpDown(
    event: Event,
    direction: number,
    fieldName: string,
    currentIndex: number
  ) {
    event.preventDefault(); // to stop default behaviour

    let inputList: QueryList<ElementRef>;
    switch (fieldName) {
      case 'length':
        inputList = this.lengthInputList;
        break;
      case 'width':
        inputList = this.widthInputList;
        break;
      case 'height':
        inputList = this.heightInputList;
        break;
      case 'pieces':
        inputList = this.piecesInputList;
        break;
      default:
        return;
    }

    this.moveVertical(inputList, currentIndex, direction); // direction is used for moving up or down
  }
  moveVertical(
    inputList: QueryList<ElementRef>,
    currentIndex: number,
    direction: number
  ) {
    const targetIndex = currentIndex + direction;

    if (targetIndex >= 0 && targetIndex < inputList.length) {
      inputList.toArray()[targetIndex].nativeElement.focus();
    }
  }

  initRange() {
    this.lengthRange.min = Number(this.tableForm?.get('minLength')?.value ?? 0);
    this.lengthRange.max = Number(this.tableForm?.get('maxLength')?.value ?? 0);

    this.widthRange.min = Number(this.tableForm?.get('minWidth')?.value ?? 0);
    this.widthRange.max = Number(this.tableForm?.get('maxWidth')?.value ?? 0);

    this.heightRange.min = Number(this.tableForm?.get('minHeight')?.value ?? 0);
    this.heightRange.max = Number(this.tableForm?.get('maxHeight')?.value ?? 0);

    this.piecesRange.min = Number(this.tableForm?.get('minPieces')?.value ?? 0);
    this.piecesRange.max = Number(this.tableForm?.get('maxPieces')?.value ?? 0);
  }

  startEditRow(index: number) {
    if (this.rows.at(index).get('action')?.value === SAWN_ACTION.UP_TO_DATE) {
      this.tempRowData = { ...this.rows.at(index).value };
    }
  }

  finishEditRow(index: number) {
    if (
      this.rows.at(index).get('action')?.value === SAWN_ACTION.UP_TO_DATE &&
      JSON.stringify(this.tempRowData) !==
        JSON.stringify(this.rows.at(index).value)
    ) {
      this.rows.at(index).get('action')?.setValue(SAWN_ACTION.EDITED);
    }
  }

  persistChanges() {
    console.log(`persistChanges`);
    // Here, you can handle the database operations as per the action keys
    // The database operations are not shown here as they would depend on your specific backend architecture
    this.getUpdatedRows().subscribe((rows: Array<SawnTimberRow>) => {
      rows.forEach((row) => {
        switch (row.action) {
          case SAWN_ACTION.ADDED:
            console.log(`This an added row ${JSON.stringify(row)}`);
            // Add the row to the database
            break;
          case SAWN_ACTION.EDITED:
            console.log(`This an edited row ${JSON.stringify(row)}`);
            // Update the row in the database
            break;
          case SAWN_ACTION.REMOVED:
            console.log(`This a removed row ${JSON.stringify(row)}`);
            // Remove the row from the database
            break;
          default:
            // Invalid action
            break;
        }
      });
    });
  }

  captureUpdatedState() {
    const currentState = this.rows.value;
    this.updatedRows.next(currentState);
  }

  getUpdatedRows() {
    return this.updatedRows.asObservable();
  }

  getFilteredRows() {
    return this.rows.controls.filter(
      (row: any) => row.get('action')?.value !== SAWN_ACTION.REMOVED
    );
  }
  setFocusOnElement0(index: number) {
    // Wait for QueryList to update
    setTimeout(() => {
      const elements = this.heightInputList.toArray();
      if (elements.length > index) {
        // Set focus
        elements[index].nativeElement.focus();
      }
    });
  }

  setFocusOnElement(index: number, element: HTMLInputElement) {
    // Wait for QueryList to update
    setTimeout(() => {
      element.focus();
    });
  }

  printRemovedRows(): void {
    this.removedRows.forEach((row, index) => {
      console.log(`Removed row ${index}:`, row.value);
    });
  }

  /* mergeRemovedRows(): void {
    const uniqueRowIds = new Set(
      this.rows.controls.map((control) => control?.get('id')?.value)
    );

    this.removedRows.forEach((row, index) => {
      console.log(`Removed row ${index}:`, row.value);

      if (row.value.id !== null && !uniqueRowIds.has(row.value.id)) {
        this.rows.push(row);
        uniqueRowIds.add(row.value.id);

        console.log(`Row Removed`);
        console.table(row.value);
      }
    });
  } */
  mergeRemovedRows(): void {
    const uniqueRowIds = this.rows.controls.map(
      (control) => control?.get('id')?.value
    );

    this.removedRows.forEach((row, index) => {
      console.log(`Removed row ${index}:`, row.value);

      if (row.value.id !== null && !uniqueRowIds.includes(row.value.id)) {
        this.rows.push(row);
        uniqueRowIds.push(row.value.id);

        console.log(`Row Removed`);
        console.table(row.value);
      }
    });
  }

  async onSubmit(isRequestFromAutoSave: boolean = false) {
    this.toastMessageService.showInfoMessage(
      'Do not refresh the page',
      'Saving in progress. Please wait.',
      true
    );
    console.table(this.rows.value);

    if (!isRequestFromAutoSave) {
      // console.log(`On Submit isValid ${this.tableForm.valid}`);
      if (this.isFormValid()) {
        this.blockedPanel = true;
        this.removeEmptyRows(this.rows);

        // this.getContainerNumberFromStorage();

        this.mergeRemovedRows(); // add removed rows from removedRows Array
        this.sawnTimberService
          .getSawnTimberId(this.container.containerId)
          .pipe(
            finalize(() => {
              this.createOrUpdateSawnTimber();
            })
          )
          .subscribe(
            (sawnTimberId: number) => {
              console.log(`reponse sawnTimberId is ${sawnTimberId}`);
              this.sawnTimberId = sawnTimberId;
            this.blockedPanel = false;

            },
            (error: HttpErrorResponse) => {
              console.error(`error  is ${JSON.stringify(error)}`);
            this.blockedPanel = false;

            }
          );
      } else {
        this.toastMessageService.showWarningMessage(
          'Incomplete Form',
          'Please enter all the required fields or delete any row that is not required.'
        );
      }
    } else {
      // console.log(`On Submit isValid ${this.tableForm.valid}`);
      // this.getContainerNumberFromStorage();
      this.blockedPanel = true;

      this.mergeRemovedRows(); // add removed rows from removedRows Array
      this.sawnTimberService
        .getSawnTimberId(this.container.containerId)
        .pipe(
          finalize(() => {
            this.createOrUpdateSawnTimber();
          })
        )
        .subscribe(
          (sawnTimberId: number) => {
            console.log(`reponse sawnTimberId is ${sawnTimberId}`);
            this.sawnTimberId = sawnTimberId;
            this.blockedPanel = false;
          },
          (error: HttpErrorResponse) => {
            console.error(`error  is ${JSON.stringify(error)}`);
            this.blockedPanel = false;
          }
        );
    }
  }

  syncAfterSaveOrUpdate() {
    console.log(`syncAfterSaveOrUpdate`);

    this.removeAllRemovedRows(this.rows);
    console.table(this.rows.value);
    /* this.rows.controls.forEach((control: AbstractControl) => {
      const group = control as FormGroup;
      group.get('action')?.setValue(SAWN_ACTION.UP_TO_DATE);
    }); */
  }

  /* highlight1(index: number) {
    let row = this.rows.at(index);
    console.log(`index is ${index} and row is${JSON.stringify(row.value)} `);
    let count =0;
    for (let i = 0; i < this.rows.controls.length; i++) {
      if (i === index && count===0) {
        this.rows.at(i).patchValue({ highlight: true, emitEvent: false });
        console.log(
          `row ${i} highlight: ${this.rows.at(i).get('highlight')?.value}`
        );
        count++;
        break;
      }
    }
    this.changeDetector.detectChanges();
  } */

  get rows(): FormArray {
    return this.tableForm?.get('rows') as FormArray;
  }
  initValidations() {
    console.log(
      `this.prepopulateConfig ${JSON.stringify(this.prepopulateConfig)}`
    );
    const configValues = this.tableForm.get('config')?.value;
    console.log('--> ' + JSON.stringify(this.tableForm.get('config')?.value));

    if (this.prepopulateConfig && this.prepopulateConfig.enableValidation) {
      //   this.applyRangeValidators(configValues);
      this.resetValidatorsToRequiredOnly();
    } else {
      // if validations are disabled or not defined remove range
      this.resetValidatorsToRequiredOnly();
    }
  }

  applyRangeValidators(configValues: any) {
    const rowsArray = this.rows.controls;

    rowsArray.forEach((rowControl: AbstractControl) => {
      const row = rowControl as FormGroup;
      for (let controlName in row.controls) {
        const controller = row.controls;
        console.log(`controlName ${controlName}`);
        if (['length', 'width', 'height', 'pieces'].includes(controlName)) {
          let control = row.controls[controlName];
          const currentValidators = control.validator
            ? [control.validator]
            : [];
          control.setValidators([Validators.required]);
          control.setValidators([
            ...currentValidators,
            this.rangeValidator(
              configValues[
                `min${
                  controlName.charAt(0).toUpperCase() + controlName.slice(1)
                }`
              ],
              configValues[
                `max${
                  controlName.charAt(0).toUpperCase() + controlName.slice(1)
                }`
              ]
            ),
          ]);
          control.updateValueAndValidity();
        }
      }
    });
  }

  onDialogClose() {
    // Access the 'config' form group

    const configGroup = this.tableForm.get('config') as FormGroup;
    // CHECK IF VALIDATION IS ENABLED
    if (configGroup?.get('enableValidation')?.value) {
      // DETERMINE IF THE FORM IS VALID
      const isValid = configGroup.valid;
      console.log(`onDialogClose isValid ${isValid} `);

      // IF FORM IS NOT VALID
      if (!isValid) {
        // Show the validation message
        this.showValidationMessage = true;

        // Iterate over each control in the form group
        Object.keys(configGroup.controls).forEach((key) => {
          console.log('Field: ', key);
          // Access the errors of the current control
          const controlErrors: ValidationErrors | null | undefined =
            configGroup.get(key)?.errors;

          // If there are errors
          if (controlErrors) {
            // Log each error
            Object.keys(controlErrors).forEach((keyError) => {
              console.log(
                'Field: ',
                key,
                ' Validation error: ',
                keyError,
                ', error value: ',
                controlErrors[keyError]
              );
            });
          }
        });

        // Return early since the form is not valid
        // PREVENTS THE DIALOG FROM CLOSING IF THERE ARE ERRORS IN THE FORM
        return;
      }
    }

    // AT THIS POINT, EITHER VALIDATION IS DISABLED OR THE FORM IS VALID

    console.log(`ConfigGroup save values`);

    // Reset form controls
    this.resetConfigFormControls();

    // Update range values
    this.onRangeChange();

    // Access the raw form values
    const config = configGroup?.getRawValue();

    // CHECK IF SAWN TIMBER ID IS NOT SET

    if (!this.sawnTimberId && this.tableForm.get('config')?.dirty) {
      this.blockedPanel = true;

      // IF SAWN TIMBER ID IS NOT SET, CREATE NEW SAWN TIMBER CONFIG
      this.sawnTimberService.createSawnTimberConfig(config).subscribe(
        (response: any) => {
          console.log(`Respone of Config saved  ${JSON.stringify(response)}`);
          this.sawnTimberId = response.id;
          this.sawnTimberConfigId = response.config.id;
          console.log(`this.sawnTimberId on response ${this.sawnTimberId}`);
          console.log(
            `this.sawnTimberConfigId on response ${this.sawnTimberConfigId}`
          );
          this.toastMessageService.showSuccessMessage(
            'Saved',
            'Configuration Saved Successfully'
          );
          // CLOSE DIALOG AFTER SUCCESSFUL SAVE
          this.isConfigDialogVisible = false;
          setTimeout(() => {
            this.blockedPanel = false;
          }, 1000);
        },
        (error: any) => {
          console.log(
            `Respone of Config saved error  ${JSON.stringify(error)}`
          );
          this.blockedPanel = false;
        }
      );
    } else {
      // IF SAWN TIMBER ID IS SET
      // IF SAWN TIMBER ID IS SET, UPDATE EXISTING SAWN TIMBER CONFIG
      if (this.tableForm.get('config')?.dirty) {
        this.blockedPanel = true;

        this.sawnTimberService
          .updateSawnTimberConfig(this.sawnTimberId, config)
          .subscribe(
            (response: any) => {
              console.log(
                `Respone of Config Update  ${JSON.stringify(response)}`
              );
              this.sawnTimberConfigId = response.id; //because returing config object
              console.log(`this.sawnTimberId on response ${this.sawnTimberId}`);
              console.log(
                `this.sawnTimberConfigId on response ${this.sawnTimberConfigId}`
              );
              // CLOSE DIALOG AFTER SUCCESSFUL UPDATE
              this.isConfigDialogVisible = false;
              this.toastMessageService.showInfoMessage(
                'Updated',
                'Configuration updated Successfully'
              );
              setTimeout(() => {
                this.blockedPanel = false;
              }, 1000);
            },
            (error: any) => {
              console.log(
                `Respone of Config Update error  ${JSON.stringify(error)}`
              );
              this.blockedPanel = false;
            }
          );
      }
    }

    // IF VALIDATION IS DISABLED AND FORM IS EMPTY (NO SAWN TIMBER ID SET), WE CLOSE THE DIALOG
    if (!configGroup?.get('enableValidation')?.value && !this.sawnTimberId) {
      this.isConfigDialogVisible = false;
    }
  }

  resetConfigFormControls() {
    const configGroup = this.tableForm.get('config');
    [
      'minLength',
      'maxLength',
      'minWidth',
      'maxWidth',
      'minHeight',
      'maxHeight',
      'minPieces',
      'maxPieces',
    ].forEach((field) => {
      configGroup?.get(field)?.setValue(0);
    });
    // this.resetConfigValidatorsToRequiredOnly();
  }

  resetConfigValidatorsToRequiredOnly() {
    // Access the 'config' form group
    const configGroup = this.tableForm.get('config') as FormGroup;

    // If the 'config' form group exists
    if (configGroup) {
      // List of fields to reset
      const fields = [
        'minLength',
        'maxLength',
        'minWidth',
        'maxWidth',
        'minHeight',
        'maxHeight',
        'minPieces',
        'maxPieces',
      ];

      // Iterate over each field
      fields.forEach((field) => {
        // Access the current field control
        const control = configGroup.get(field);

        // If the control exists
        if (control) {
          // Reset the control's validators to Validators.required only
          control.setValidators([Validators.required]);

          // Update the control to reflect the new validator
          control.updateValueAndValidity();
        }
      });
    }
  }

  resetValidatorsToRequiredOnly() {
    const rowsArray = this.rows.controls;
    rowsArray.forEach((rowControl: AbstractControl) => {
      const row = rowControl as FormGroup;
      ['length', 'width', 'height', 'pieces'].forEach((field) => {
        const control = row.get(field);
        if (control) {
          control.setValidators([Validators.required]);
          control.updateValueAndValidity();
        }
      });
    });
  }

  warnIfOutOfRange() {
    const rowsControl = this.tableForm?.get('rows') as FormArray;

    rowsControl.controls.forEach((group: AbstractControl, index: number) => {
      const lengthControl = group.get('length');
      const widthControl = group.get('width');
      const heightControl = group.get('height');
      const piecesControl = group.get('pieces');

      // Check if the form control values are out of the specified range
      // If they are, log a warning message
      if (
        lengthControl &&
        (lengthControl.value < this.lengthRange.min ||
          lengthControl.value > this.lengthRange.max)
      ) {
        console.warn(
          `Warning: Length value is out of range in row ${index + 1}.`
        );
      }

      if (
        widthControl &&
        (widthControl.value < this.widthRange.min ||
          widthControl.value > this.widthRange.max)
      ) {
        console.warn(
          `Warning: Width value is out of range in row ${index + 1}.`
        );
      }

      if (
        heightControl &&
        (heightControl.value < this.heightRange.min ||
          heightControl.value > this.heightRange.max)
      ) {
        console.warn(
          `Warning: Height value is out of range in row ${index + 1}.`
        );
      }

      if (
        piecesControl &&
        (piecesControl.value < this.piecesRange.min ||
          piecesControl.value > this.piecesRange.max)
      ) {
        console.warn(
          `Warning: Pieces value is out of range in row ${index + 1}.`
        );
      }
    });
  }

  isOutOfRange(row: AbstractControl, field: string): boolean {
    const control = row.get(field);

    const rangeMap: { [key: string]: { min: number; max: number } } = {
      length: this.lengthRange,
      width: this.widthRange,
      height: this.heightRange,
      pieces: this.piecesRange,
    };
    const configGroup = this.tableForm.get('config');
    const enableValidation = configGroup?.get('enableValidation')?.value;
    if (!enableValidation) {
      return false;
    }

    if (rangeMap[field].min === 0 && rangeMap[field].max === 0) {
      // console.log(`Both are zero for the ${field} `);
      return false;
    }

    return control
      ? control.value < rangeMap[field].min ||
          control.value > rangeMap[field].max
      : false;
  }

  isValid(row: AbstractControl, field: string): boolean {
    const control = row.get(field);
    const rangeMap: { [key: string]: { min: number; max: number } } = {
      length: this.lengthRange,
      width: this.widthRange,
      height: this.heightRange,
      pieces: this.piecesRange,
    };
    return control
      ? control.value >= rangeMap[field].min &&
          control.value <= rangeMap[field].max
      : false;
  }

  onToggleChange(value: any): void {
    if (value) {
      // toggle button is enabled, do something here
      this.resetConfigValidatorsToRequiredOnly();
      console.log('Toggle button has been enabled');
    } else {
      // toggle button is disabled, do something here
      console.log('Toggle button has been disabled');
      this.resetConfigFormControls();
      this.showValidationMessage = false;
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleShortcut3(event: KeyboardEvent) {
    if (
      (event.altKey && event.code === 'KeyS') ||
      (event.code === 'AltLeft' && event.key === 's') ||
      (event.code === 'AltRight' && event.key === 's')
    ) {
      this.onSubmit();
    }
  }

  /*  @HostListener('window:keydown.meta.shift.s', ['$event'])
  handleShortcut(event: KeyboardEvent) {
    //console.log('Meta + Shift + S pressed 1 !');
    this.onSubmit();
  } */

  initSpeedDial() {
    this.sawnTimberSpeedDialItems = [
      {
        icon: 'pi pi-cloud-upload', //pi-disk-o
        command: () => {
          this.onSubmit();
        },
        tooltipOptions: {
          tooltipLabel: 'Save',
          tooltipPosition: 'left',
        },
      },
      /* {
        icon: this.saveOptionBoolean ? 'pi pi-save' : 'pi pi-power-off', // auto save on off
        command: () => {
          if (this.tableForm.dirty) {
            this.toastMessageService.showErrorMessage(
              'Unsaved Changes Detected',
              'Please save unsaved records before proceeding.'
            );
            return;
          }
          this.saveOptionBoolean = !this.saveOptionBoolean;
          this.changeSaveOption();
          this.initSpeedDial(); // Update menu items when saveOptionBoolean changes
        },
        tooltipOptions: {
          tooltipLabel: this.getSaveOptionTooltip(),
          tooltipPosition: 'left',
        },
      }, */
      /* {
        icon: 'pi pi-clock',
        command: () => {
          if (this.tableForm.dirty) {
            this.toastMessageService.showErrorMessage(
              'Unsaved Changes Detected',
              'Please save unsaved records before proceeding.'
            );
            return;
          }
          this.showAutoSaveIntervalDialog = true;
        },
        tooltipOptions: {
          tooltipLabel: 'Configure Auto Save Interval',
          tooltipPosition: 'left',
        },
      }, */
      {
        icon: 'pi pi-cog',
        command: () => {
          if (this.tableForm.dirty) {
            this.toastMessageService.showErrorMessage(
              'Unsaved Changes Detected',
              'Please save unsaved records before proceeding.'
            );
            return;
          }
          this.isConfigDialogVisible = true;
        },
        tooltipOptions: {
          tooltipLabel: 'Open Configuration',
          tooltipPosition: 'left',
        },
      },
      {
        icon: 'pi pi-file-excel',
        command: () => {
          if (this.tableForm.dirty) {
            this.toastMessageService.showErrorMessage(
              'Unsaved Changes Detected',
              'Please save unsaved records before proceeding.'
            );
            return;
          }
          this.exportExcel();
        },
        tooltipOptions: {
          tooltipLabel: 'Export Excel',
          tooltipPosition: 'left',
        },
      },
      {
        icon: 'pi pi-file-pdf',
        command: () => {
          this.exportPdf();
          if (this.tableForm.dirty) {
            this.toastMessageService.showErrorMessage(
              'Unsaved Changes Detected',
              'Please save unsaved records before proceeding.'
            );
            return;
          }
        },
        tooltipOptions: {
          tooltipLabel: 'Export PDF',
          tooltipPosition: 'left',
        },
      },
    ];
  }

  getSaveOptionTooltip(): string {
    return this.saveOptionBoolean ? 'Auto Save is ON' : 'Auto Save is OFF';
  }
  changeSaveOption() {
    const config = this.tableForm.get('config');

    config
      ?.get('enableAutoSave')
      ?.setValue(!config?.get('enableAutoSave')?.value);

    if (config?.get('enableAutoSave')?.value) {
      this.stopAutoSave();
      this.showSaveOptionDivSubject.next(false); // or false
    } else {
      this.updateAutoSave();
      this.showSaveOptionDivSubject.next(true); // or false
    }
  }

  updateAutoSave() {
    this.toastMessageService.clearMessage();
    const config = this.tableForm.get('config');
    if (!config?.get('enableAutoSave')?.value) {
      const durationInMinutes = config?.get('autoSaveDuration')?.value ?? 1;
      const durationInMilliseconds =
        (config?.get('autoSaveDuration')?.value ?? 1) * 60000;

      // const intervalMinutes = this.saveInterval;
      this.toastMessageService.showInfoMessage(
        'Auto-Save Activation',
        `Auto-save has been activated and is set to save your progress every ${durationInMinutes} minutes.`
      );
      this.intervalId = setInterval(() => {
        if (config?.get('enableAutoSave')?.value) {
          this.onSubmit(true);
        }
      }, durationInMilliseconds);
    }
  }
  startAutoSave() {
    const interval = (this.intervalDuration ?? 0) / 60000;
    this.intervalDuration = this.saveInterval * 60000;
    // const intervalMinutes = this.saveInterval;
    // console.log(`Start Auto Save Interval at ${this.intervalDuration}`);
    this.toastMessageService.showInfoMessage(
      'Auto-Save Activation',
      `Auto-save has been activated and is set to save your progress every ${this.saveInterval} minutes.`
    );
    this.intervalId = setInterval(() => {
      if (this.saveOptionBoolean) {
        this.onSubmit();
      }
    }, this.intervalDuration);
  }
  stopAutoSave() {
    this.toastMessageService.showInfoMessage(
      'Auto-Save Deactivation',
      'Auto-save has been deactivated. Your progress will no longer be automatically saved.'
    );
    clearInterval(this.intervalId);
    console.log(`stopAutoSave`);
  }

  onSpeedDialItemClick(event: Event) {
    event.preventDefault();
  }

  getContainerNumberFromStorage() {
    this.encryptedStorageService
      .getEncryptedDataFromStorage(AppConstants.CONTAINER)
      .subscribe(
        (data) => {
          console.log(`Container Data ${JSON.stringify(data)}`);
          // Transform the data into a format that FormGroup understands
          this.container = data;
          const containerData = Object.keys(data).reduce(
            (acc: { [key: string]: AbstractControl }, key) => {
              acc[key] = new FormControl(data[key]);
              return acc;
            },
            {}
          );

          // Now set the transformed data to the container FormGroup
          this.tableForm.setControl('container', new FormGroup(containerData));
          if (this.container && this.container.containerId) {
            this.getContainerDetails();
            this.loadData().subscribe(
              (data) => {
                console.log(`Data Load From Server is ${JSON.stringify(data)}`);
              },
              (error) => {},
              () => {
                if (this.container && this.container.containerId) {
                  this.processData();
                } else {
                  this.createRow(1);
                }
              }
            );
          }
          /*  this.loadData().subscribe(
            (data)=>{}
          ) */
        },
        (error) => {
          console.error('Failed to decrypt data:', error);
        }
      );
  }

  private async createOrUpdateSawnTimber(): Promise<void> {
    console.log(`createOrUpdateSawnTimber`);
    let payload = this.tableForm.value;

    if (!this.isFormValid()) {
      this.toastMessageService.showWarningMessage(
        'Incomplete Form',
        'Please enter all the required fields or delete any row that is not required.'
      );
      return;
    }

    let sawnTimberObservable!: Observable<any>;
    // console.log(`Payload is ${JSON.stringify(payload.rows)}`);
    // this.createSawnTimberSummary(payload.rows);
    await this.createSawnTimberSummary(payload.rows);
    payload = this.tableForm.value; // get the updated form value
    if (this.sawnTimberId) {
      console.log(`Update this.sawnTimberId`);
      console.table(payload.rows);
      console.table(payload);
      sawnTimberObservable = this.sawnTimberService.updateSawnTimber(
        payload,
        this.sawnTimberId
      );
    } else {
      console.log(`Create this.sawnTimberId`);
      console.table(payload.rows);

      sawnTimberObservable = this.sawnTimberService.createSawnTimber(payload);
    }

    sawnTimberObservable.subscribe(
      (response: any) => {
        this.handleSuccessfulResponse(response);
      },
      (error: any) => {
        this.handleErrorResponse(error);
      }
    );
  }

  private handleSuccessfulResponse(response: any): void {
    this.sawnTimberId = response.id;
    this.sawnTimberConfigId = response.config.id;
    this.syncAfterSaveOrUpdate();
    this.tableForm.markAsPristine();
    const action = this.sawnTimberId ? 'Saved' : 'Added';
    this.toastMessageService.clearMessage();

    this.toastMessageService.showSuccessMessage(
      'Success',
      `${action} successfully`
    );
  }

  private handleErrorResponse(error: any): void {
    const action = this.sawnTimberId ? 'saving' : 'updating';
    this.toastMessageService.showErrorMessage('Error', `Error while ${action}`);
  }

  /* private isFormValid(): boolean {
    // Add validation logic here and return true or false
    return true; // Placeholder return
  } */

  private createSawnTimberSummary(rows: any): Promise<void> {
    console.log(`createSawnTimberSummary ${JSON.stringify(rows)}`);

    let netVolume = 0;
    let grossVolume = 0;
    let netCFT = 0;
    let grossCFT = 0;
    let totalCount = rows.length; // the total count is simply the length of the array
    let pieces = 0; // Initialize total pieces

    for (let i = 0; i < rows.length; i++) {
      if (rows[i].action !== 'REMOVED') {
        netVolume += parseFloat(rows[i].cbm); // or other calculation logic as per your requirement
        grossVolume += parseFloat(rows[i].cbm); // or other calculation logic as per your requirement
        netCFT += parseFloat(rows[i].cft); // or other calculation logic as per your requirement
        grossCFT += parseFloat(rows[i].cft); // or other calculation logic as per your requirement
        pieces += parseInt(rows[i].pieces); // or other calculation logic as per your requirement
      }
    }
    this.totalGrossVolumeCbm = grossVolume;
    this.totalNetVolumeCbm = netVolume;
    return new Promise<void>((resolve) => {
      Promise.all([
        this.tableForm.get('summary.netVolume')?.setValue(netVolume),
        this.tableForm.get('summary.grossVolume')?.setValue(grossVolume),
        this.tableForm.get('summary.netCFT')?.setValue(netCFT),
        this.tableForm.get('summary.grossCFT')?.setValue(grossCFT),
        this.tableForm.get('summary.pieces')?.setValue(pieces),
      ]).then(() => resolve());
    });
  }

  removeAllRemovedRows(rowsArray: FormArray) {
    const indicesToRemove: number[] = [];

    // Find the indices of all rows to be removed
    rowsArray.controls.forEach((control: AbstractControl, index: number) => {
      if (control.get('action')?.value === 'REMOVED') {
        indicesToRemove.push(index);
      }
    });

    // Reverse sort the indices to ensure we don't mess up the array while removing
    indicesToRemove
      .sort((a, b) => b - a)
      .forEach((index) => rowsArray.removeAt(index));

    this.rows.controls.forEach((control: AbstractControl) => {
      const group = control as FormGroup;
      group.get('action')?.setValue(SAWN_ACTION.UP_TO_DATE);
    });
  }

  getTableRowAt(index: number): FormGroup {
    return this.rows.at(index) as FormGroup;
  }

  /* getNewSerialNumber(currentIndex: number | null, nextIndex: number | null): number | null {
    let newSerialNumber = null;

    if (currentIndex !== null && nextIndex !== null) {
        const currentRow = this.getTableRowAt(currentIndex).value;
        const nextRow = this.getTableRowAt(nextIndex).value;
        
        if (currentRow && nextRow) {
            const difference = Math.abs(nextRow.serialNumber - currentRow.serialNumber);
            if (difference < 0.001) {  // Adjust this threshold as needed
                // Handle edge cases, maybe by returning null or assigning a default value.
                return null;
            }

            newSerialNumber = (currentRow.serialNumber + nextRow.serialNumber) / 2;
            
            // Check if newSerialNumber already exists in this.rows
            if (this.isSerialNumberExists(newSerialNumber)) {
                // If it does, get a new serial number that lies between the existing number and the newSerialNumber
                return this.getNewSerialNumber(currentIndex, this.getIndexOfSerialNumber(newSerialNumber));
            }
        }
    }
    console.log(`newSerialNumber ${newSerialNumber}`);
    newSerialNumber = this.roundToFiveDecimalPlaces(newSerialNumber);
    return newSerialNumber;
} */

  getUniqueSerialNumber(
    currentIndex: number | null,
    nextIndex: number | null
  ): number | null {
    if (currentIndex === null || nextIndex === null) {
      return null;
    }

    const currentRow = this.getTableRowAt(currentIndex).value;
    const nextRow = this.getTableRowAt(nextIndex).value;

    if (!currentRow || !nextRow) {
      return null;
    }

    let newSerialNumber = this.getUniqueSerialNumberBetween(
      currentRow.serialNumber,
      nextRow.serialNumber
    ); //(currentRow.serialNumber + nextRow.serialNumber) / 2;

    console.log(`New Serial Number is ${newSerialNumber}`);

    return newSerialNumber;
  }

  getUniqueSerialNumberBetween(current: number, next: number): number | null {
    // Calculate the midpoint to 5 decimal places
    let midPoint = +(current + (next - current) / 2).toFixed(5);

    // If the midpoint is the same as current or next after rounding, handle the edge case
    if (midPoint === current || midPoint === next) {
      // Here, we can return null to indicate we couldn't find a unique number.
      // Alternatively, we can return another value, log a message, throw an error, etc.
      console.error(
        'Could not find a unique midpoint. Consider adjusting the precision or using another method.'
      );
      return null;
    }

    return midPoint;
  }

  isEmptyRow(row: AbstractControl): boolean {
    if (!row) {
      console.error('Row is undefined or null!');
      return true; // or false depending on your logic
    }

    const fieldsToCheck = ['pieces', 'width', 'height', 'length'];
    return fieldsToCheck.every((field) => {
      const fieldValue = row.get(field)?.value;
      console.log(`Value of ${field} is: ${fieldValue}`);
      return !fieldValue;
    });
  }

  async removeEmptyRows(formArray: FormArray): Promise<void> {
    return new Promise<void>((resolve) => {
      const emptyRowsIndices: number[] = [];

      // Find the indices of all empty rows
      formArray.controls.forEach((row, index) => {
        if (this.isEmptyRow(row)) {
          emptyRowsIndices.push(index);
        }
      });

      // Remove the empty rows, starting from the end to avoid index issues
      for (let i = emptyRowsIndices.length - 1; i >= 0; i--) {
        formArray.removeAt(emptyRowsIndices[i]);
      }

      resolve();
    });
  }

  isFormValid(): boolean {
    // Loop backwards through the rows until a non-empty row is found or all rows have been checked
    for (let i = this.rows.length - 1; i >= 0; i--) {
      const row = this.rows.at(i);
      console.log(`this.isEmptyRow(row); ${this.isEmptyRow(row)}`);
      if (!this.isEmptyRow(row)) {
        return this.tableForm.valid;
      }
    }
    return true; // if all rows are empty, return true
  }

  initConfigDialogWidth() {
    if (window.innerWidth < 768) {
      return (this.configDialogWidth = '70vw');
    } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
      return (this.configDialogWidth = '60vw');
    } else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
      return (this.configDialogWidth = '50vw');
    } else if (window.innerWidth >= 1200) {
      return (this.configDialogWidth = '40vw');
    } else {
      return (this.configDialogWidth = '50vw');
    }
  }

  @HostListener('window:resize', ['$event'])
  resize() {
    this.initConfigDialogWidth();
  }

  getContainerDetails() {
    this.hoppusServiceV2
      .getHoppusTimberContainerDetails(this.container.containerId)
      .subscribe(
        (response: any) => {
          this.containerDetails = response;
        },
        (error: any) => {},
        () => {}
      );
  }

  reloadDataBeforeExport(): Observable<any> {
    return new Observable((observer) => {
      this.sawnTimberService
        .getTimbersFromContainerId(this.container.containerId)
        .subscribe(
          (response: any) => {
            // your existing code
            let rowsFetched: SawnTimberRow[] = response.rows;
            this.totalRecords = response.rows.length;
            console.log(
              `Init Before Sort Fetch Table Data ${JSON.stringify(
                response
              )} \n totalRecords ${this.totalRecords}`
            );
            this.fetchedRows = response.rows;
            this.summary = response.summary;
            this.config = response.config;
            observer.next(response);
            observer.complete();
          },
          (error) => observer.error(error)
        );
    });
  }

  async exportPdf() {
    await this.reloadDataBeforeExport().toPromise();
    if (!(this.totalRecords && this.totalRecords > 0)) {
      this.toastMessageService.showErrorMessage(
        'Export Unsuccessful',
        'No data available for export.'
      );
      return;
    }
    const doc = new jsPDF();
    let colWidths: number[] = [];

    let lastAutoTableY = 0; // Initialize Y value

    const containerNumber = this.container.containerNumber;
    const totalPieces = this.summary.pieces;
    const grossVolumeCbm = Number.parseFloat(
      this.summary.grossVolume.toFixed(3)
    );
    const netVolumeCbm = Number.parseFloat(this.summary.netVolume.toFixed(3));
    const grossCftAvg = (grossVolumeCbm * 35.315) / totalPieces;
    const netCftAvg = (netVolumeCbm * 35.315) / totalPieces;
    const thicknessUnit = this.config.heightUnit;
    const widthUnit = this.config.widthUnit;
    const LengthtUnit = this.config.lengthUnit;

    // Add additional data
    const additionalData = [
      ['Container Number', containerNumber],
      ['Product', this.containerDetails.product],
      ['Total Pieces', totalPieces],
      ['Gross Vol CBM', grossVolumeCbm.toString()],
      ['Net Vol CBM', netVolumeCbm.toString()],
      ['Gross Avg CFT', grossCftAvg.toFixed(3)],
      ['Net Avg CFT', netCftAvg.toFixed(3)],
      ['Country of Origin', this.containerDetails.countryOfOrigin],
      ['Port of Loading', this.containerDetails.portOfLoading],
      ['Port of Destination', this.containerDetails.portOfDestination],
      ['Fumigation', this.containerDetails.fumigation],
      // ... other key-value pairs
    ];
    // doc.text('Additional Data', 10, 10);
    autoTable(doc, {
      startY: 10,
      body: additionalData,
      styles: { fontSize: 8 },
      didDrawPage: function (data) {
        lastAutoTableY = data?.cursor?.y || lastAutoTableY;
      },
    });

    // Add Table Data
    const headers = [
      'SR',
      'Width (' + widthUnit.toUpperCase() + ')',
      'Thickness (' + thicknessUnit.toUpperCase() + ')',
      'Length (' + LengthtUnit.toUpperCase() + ')',
      'Pieces',
      'CBM',
      'CFT',
    ];
    const rows = this.tableForm.value.rows.map((row: any, index: number) => [
      index + 1,
      parseFloat(row.width),
      parseFloat(row.length),
      parseFloat(row.height),
      parseFloat(row.pieces),
      parseFloat(row.cbm),
      parseFloat(row.cft),
    ]);
    // doc.text('Table Data', 10, 50);
    // autoTable(doc, { startY: 100, head: [headers], body: rows });
    autoTable(doc, {
      startY: lastAutoTableY + 10,
      head: [headers],
      body: rows,
      didDrawPage: function (data) {
        lastAutoTableY = data.cursor?.y || lastAutoTableY;
      },
      didDrawCell: function (data) {
        if (data.section === 'head') {
          colWidths.push(data.cell.width);
        }
      },
    });

    const lastRow = [
      null,
      null,
      null,
      'TOTAL',
      totalPieces,
      parseFloat(grossVolumeCbm.toFixed(3)),
      parseFloat(netVolumeCbm.toFixed(3)),
    ];

    // let colStyles = {};
    let colStyles: { [key: number]: { cellWidth: number } } = {};

    colWidths.forEach((width, index) => {
      colStyles[index] = { cellWidth: width };
    });

    autoTable(doc, {
      startY: lastAutoTableY + 0,
      body: [lastRow],
      styles: { fontStyle: 'bold', fontSize: 10 },
      columnStyles: colStyles,
    });

    const filename = 'packinglist' + '_export_' + new Date().getTime() + '.pdf';
    doc.save(filename);
  }

  private addAdditionalRows(worksheet: any, data: any) {
    let rowIndex = 1; // Start at row 1
    Object.entries(data).forEach(([label, value]) => {
      let row;
      if (label.startsWith('BlankRow')) {
        row = worksheet.addRow(['', '', '', '', '', '']);
      } else {
        row = worksheet.addRow([label, '', '', value, '', '']);
        // Merge cells for label (A, B, C)
        worksheet.mergeCells(`A${rowIndex}:C${rowIndex}`);
        // Merge cells for value (D, E)
        worksheet.mergeCells(`D${rowIndex}:E${rowIndex}`);
        // Set label in bold
        row.getCell(1).font = { bold: true };
      }
      rowIndex++;
    });
  }

  async exportExcel() {
    await this.reloadDataBeforeExport().toPromise();
    if (!(this.totalRecords && this.totalRecords > 0)) {
      this.toastMessageService.showInfoMessage(
        'Export Unsuccessful',
        'No data available for export.'
      );
      return;
    }
   /* import('exceljs').then((ExcelJS) => {
      const containerNumber = this.container.containerNumber;
      const totalPieces = this.summary.pieces;
      const grossVolumeCbm = Number.parseFloat(
        this.summary.grossVolume.toFixed(3)
      );
      const netVolumeCbm = Number.parseFloat(this.summary.netVolume.toFixed(3));
      const thicknessUnit = this.config.heightUnit;
      const widthUnit = this.config.widthUnit;
      const LengthtUnit = this.config.lengthUnit;

      var workbook = new ExcelJS.Workbook();
      var worksheet = workbook.addWorksheet(containerNumber);

      // Add Container Number at the top
      // Data for additional rows
      const additionalData = {
        'Container Number': containerNumber,
        Product: this.containerDetails.product,
        BlankRow2: '',
        'Total Pieces': totalPieces.toString(),
        'Gross Vol CBM': grossVolumeCbm.toString(),
        'Net Vol CBM': netVolumeCbm.toString(),
        BlankRow1: '',
        'Country of Origin': this.containerDetails.countryOfOrigin,
        'Port of Loading': this.containerDetails.portOfLoading,
        'Port of Destination': this.containerDetails.portOfDestination,
        Fumigation: this.containerDetails.fumigation,

        // ... other key-value pairs
      };

      // Function call to add additional rows
      this.addAdditionalRows(worksheet, additionalData);
      worksheet.addRow([]);

      // Manually add headers
      const headerRow = worksheet.addRow([
        'SR',
        'Width',
        'Thickness',
        'Length',
        'Pieces',
        'CBM',
        'CFT',
      ]);
      headerRow.font = { bold: true };
      headerRow.alignment = { vertical: 'middle', horizontal: 'center' };

      const unitRow = worksheet.addRow([
        null,
        `(${widthUnit.toUpperCase()})`,
        `(${thicknessUnit.toUpperCase()})`,
        `(${LengthtUnit.toUpperCase()})`,
        null,
        null,
        null,
      ]);
      unitRow.alignment = { vertical: 'middle', horizontal: 'center' };

      let headerRowIdx = headerRow.number; // Replace with the actual row number if needed
      worksheet.mergeCells(`A${headerRowIdx}:A${headerRowIdx + 1}`);
      worksheet.mergeCells(`E${headerRowIdx}:E${headerRowIdx + 1}`);
      worksheet.mergeCells(`F${headerRowIdx}:F${headerRowIdx + 1}`);
      worksheet.mergeCells(`G${headerRowIdx}:G${headerRowIdx + 1}`);
      // worksheet.mergeCells('A1:A2');
      // worksheet.mergeCells('E1:E2');
      // worksheet.mergeCells('F1:F2');
      // worksheet.mergeCells('G1:G2');

      worksheet.columns.forEach((column) => {
        column.width =
          (column?.header?.length ?? 0) < 12 ? 12 : column?.header?.length ?? 0;
      });

      // Add data rows
      const rows = this.tableForm.value.rows.map((row: any, index: number) => {
        let newRow = [
          index + 1,
          parseFloat(row.width),
          parseFloat(row.length),
          parseFloat(row.height),
          parseFloat(row.pieces),
          parseFloat(row.cbm),
          parseFloat(row.cft),
        ];
        const addedRow = worksheet.addRow(newRow);

        // Set cell format for each column
        ['A', 'B', 'C', 'D', 'E'].forEach((col, idx) => {
          addedRow.getCell(idx + 1).numFmt = '0'; // Without decimal places
        });
        addedRow.getCell(6).numFmt = '0.000'; // With 3 decimal places
        addedRow.getCell(7).numFmt = '0.000'; // With 3 decimal places
      });

      // Add and bold the last row
      const lastRow = worksheet.addRow([
        null,
        null,
        null,
        'TOTAL',
        totalPieces,
        parseFloat(grossVolumeCbm.toFixed(3)),
        parseFloat(netVolumeCbm.toFixed(3)),
      ]);

      lastRow.font = { bold: true };

      // Write to buffer and save the file
      workbook.xlsx.writeBuffer().then((buffer) => {
        this.saveAsExcelFile(buffer, 'packinglist');
      });
    });*/
  }

  async exportExcel1() {
    await this.reloadDataBeforeExport().toPromise();
    if (!(this.totalRecords && this.totalRecords > 0)) {
      this.toastMessageService.showInfoMessage(
        'Export Unsuccessful',
        'No data available for export.'
      );
      return;
    }
    /*import('exceljs').then((ExcelJS) => {
      const containerNumber = this.container.containerNumber;
      const totalPieces = this.summary.pieces;
      const grossVolumeCbm = Number.parseFloat(
        this.summary.grossVolume.toFixed(3)
      );
      const netVolumeCbm = Number.parseFloat(this.summary.netVolume.toFixed(3));
      const thicknessUnit = this.config.heightUnit;
      const widthUnit = this.config.widthUnit;
      const LengthtUnit = this.config.lengthUnit;

      var workbook = new ExcelJS.Workbook();
      var worksheet = workbook.addWorksheet(containerNumber);

      // Add Container Number at the top
      // Data for additional rows
      const additionalData = {
        'Container Number': containerNumber,
        Product: this.containerDetails.product,
        BlankRow2: '',
        'Total Pieces': totalPieces.toString(),
        'Gross Vol CBM': grossVolumeCbm.toString(),
        'Net Vol CBM': netVolumeCbm.toString(),
        BlankRow1: '',
        'Country of Origin': this.containerDetails.countryOfOrigin,
        'Port of Loading': this.containerDetails.portOfLoading,
        'Port of Destination': this.containerDetails.portOfDestination,
        Fumigation: this.containerDetails.fumigation,

        // ... other key-value pairs
      };

      // Function call to add additional rows
      this.addAdditionalRows(worksheet, additionalData);
      worksheet.addRow([]);

      // Manually add headers
      const headerRow = worksheet.addRow([
        'SR',
        `Width (${widthUnit.toUpperCase()})`,
        `Thickness (${thicknessUnit.toUpperCase()})`,
        `Length (${LengthtUnit.toUpperCase()})`,
        'Pieces',
        'CBM',
        'CFT',
      ]);
      headerRow.font = { bold: true }; // Bold the headers
      headerRow.alignment = { horizontal: 'center' }; // Center-align header text
      worksheet.columns.forEach((column) => {
        column.width =
          (column?.header?.length ?? 0) < 12 ? 12 : column?.header?.length ?? 0;
      });

      // Add data rows
      const rows = this.tableForm.value.rows.map((row: any, index: number) => {
        let newRow = [
          index + 1,
          parseFloat(row.width),
          parseFloat(row.length),
          parseFloat(row.height),
          parseFloat(row.pieces),
          parseFloat(row.cbm),
          parseFloat(row.cft),
        ];
        const addedRow = worksheet.addRow(newRow);

        // Set cell format for each column
        ['A', 'B', 'C', 'D', 'E'].forEach((col, idx) => {
          addedRow.getCell(idx + 1).numFmt = '0'; // Without decimal places
        });
        addedRow.getCell(6).numFmt = '0.000'; // With 3 decimal places
        addedRow.getCell(7).numFmt = '0.000'; // With 3 decimal places
      });

      // Add and bold the last row
      const lastRow = worksheet.addRow([
        null,
        null,
        null,
        'TOTAL',
        totalPieces,
        parseFloat(grossVolumeCbm.toFixed(3)),
        parseFloat(netVolumeCbm.toFixed(3)),
      ]);

      lastRow.font = { bold: true };

      // Write to buffer and save the file
      workbook.xlsx.writeBuffer().then((buffer) => {
        this.saveAsExcelFile(buffer, 'packinglist');
      });
    });*/
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }
}
