import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { Accordion } from 'primeng/accordion';
import { MenuItem, MessageService, ConfirmationService } from 'primeng/api';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { Inplace } from 'primeng/inplace';
import { ToggleButtonChangeEvent } from 'primeng/togglebutton';
import { Subscription, BehaviorSubject, Observable, Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { CanComponentDeactivate } from 'src/app/guards/confirm-unsaved-changes.guard';
import { MessageType } from 'src/app/interfaces/tally-sheet/message-type';
import { TallySheet } from 'src/app/interfaces/tally-sheet/tally-sheet';
import { TallySheetSummaryResponse } from 'src/app/interfaces/tally-sheet/tally-sheet-summary-response';
import { TallySummary } from 'src/app/interfaces/tally-sheet/tally-summary';
import { WebSocketService } from 'src/app/services/tally-sheet/socket.service';
import { ContainerService } from '../services/container.service';
import { SHARED_MODULES } from '../../shared-imports';
import { SquareTallyRow } from 'src/app/interfaces/tally-sheet/squared-tally-row';
import { SquareTallySheet } from 'src/app/interfaces/square-tally-sheet';
import { SquaredTallysheetService } from '../services/squared-tallysheet.service';

@Component({
  selector: 'app-squared-tallysheet',
  standalone: true,
  imports: [SHARED_MODULES],
  templateUrl: './squared-tallysheet.component.html',
  styleUrl: './squared-tallysheet.component.css'
})
export class SquaredTallysheetComponent implements OnInit, OnDestroy, CanComponentDeactivate, AfterViewInit {
  @Input() containerId!: number;
  @Input() isCalledFromContainerComponent: boolean = false;
  @Input() resolvedDataTallysheetSummary: any;
  @Input() resolvedDataTallysheetRecord: any;
  @Input() resolvedDataSquareTallysheetRecord: any;



  isMobile: boolean = false;

  activeInplace: boolean[] = []; // Boolean array to manage the state of each Inplace
  summaryId: number | null = null; // Allow null values
  // summaryId: number | null = 90;
  filteredContainerNumbers: any[] | undefined;
  maxRowsPerSegment = 10;
  maxFractionDepth = 6;
  recordsForm!: FormGroup;
  summaryForm!: FormGroup;
  actionLog: any[] = [];
  tallysheetSettingsSidebar = false;
  @ViewChild('tallyCard') tallyCard!: ElementRef;  // Reference to p-card
  @ViewChild('summaryFormElement') summaryFormElement!: ElementRef;  // Reference to p-card
  private ackSubscription: Subscription = new Subscription;
  private recordsSubject: Subscription = new Subscription;
  // Initialize BehaviorSubject with an initial value of false
  private showProgressBarSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  editModeChecked: boolean = false;
  currentMode = 'View';
  changeToMode = 'Edit';
  changeToModeIcon = 'pi pi-pencil';
  shouldAddToLength: boolean = true; // Flag to enable or disable adding value to length
  //lengthIncreament: number = 200; // Default value to add to the length, can be set dynamically
  @ViewChild('accordionsss') accordion!: Accordion;
  tempLengthIncreament: number = 0;
  tempSummaryType: 'PUBLIC' | 'PRIVATE' | 'SOLD' = 'PUBLIC';


  focusedField: { index: number; fieldName: string } | null = null;




  // Observable for showProgressBar
  showProgressBar$: Observable<boolean> = this.showProgressBarSubject.asObservable();
  viewEditModeSeverity!: "success" | "info" | "warning" | "danger" | "help" | "primary" | "secondary" | "contrast" | null | undefined;

  // Example property to track loading state
  isLoadingMore = false;
  blockedForm: boolean = false;
  pageNumber = 0;
  pageSize = 25;
  loading = false;
  dataLoaded = false;
  // Store original values when the row is focused
  originalValues: { [key: number]: { lengths: number; thickness: number } } = {};
  ackMessage: string | undefined;
  commonFields: TallySummary = this.summaryForm?.value;
  serials: string[] = [];
  addRowLoading: boolean[] = []; // Array to track loading state for each row
  addRowAnimation: boolean[] = []; // Array to track loading state for each row
  deleteRowLoading: boolean[] = []; // Array to track loading state for each row
  recordOnFocus!: SquareTallyRow;
  recordOnBlur!: SquareTallyRow;
  speedDialMenuItems: MenuItem[] | undefined;
  //copyPreviousRowLength: boolean = true;
  focusedRowIndex: number | null = null; // Track focused row index
  tempExistingContainerNumber!: any;

  @ViewChild('inplacethickness') inplacethickness!: Inplace;
  @ViewChild('inplaceLength') inplaceLength!: Inplace;
  @ViewChild('inplaceUomLength') inplaceUomLength!: Inplace;
  @ViewChild('inplaceUomThickness') inplaceUomthickness!: Inplace; //
  @ViewChild('inplaceUomWidth') inplaceUomWidth!: Inplace; //
  
  @ViewChild('inplaceRoundingDirection') inplaceRoundingDirection!: Inplace;
  @ViewChild('inplaceDecimalPlaces') inplaceDecimalPlaces!: Inplace;
  // @ViewChild('inplaceContainerNumber') inplaceContainerNumber!: Inplace;
  // @ViewChild('inplaceTypeOfShipment') inplaceTypeOfShipment!: Inplace;

  @ViewChild('inplaceCopyPreviousRow') inplaceCopyPreviousRow!: Inplace;
  @ViewChild('inplaceLengthIncreament') inplaceLengthIncreament!: Inplace;
  @ViewChild('inplaceSummaryType') inplaceSummaryType!: Inplace; //

  revertBackInplaceValue!: any;

  activeIndex: number | undefined = 0;

  tableColumnNames = [
    { name: 'Net Dimensions', value: 'NetDimensions' },
    { name: 'Gross Volume', value: 'GrossVolume' },
    { name: 'Net Volume', value: 'NetVolume' },
  ];

  // selectedColumnNames: any[] = [{ name: 'Gross Volume', value: 'GrossVolume' }];  // Initial selected option

  rowStatus: string[] = []; // Array to track loading state for each row
  totalPages: any;
  hasAllRecordsLoaded: boolean = false;
  allCalculationsDone: boolean = false;
  private recalculateSubject = new Subject<void>();
  private tallySheetSubscription!: Subscription;
  visible: boolean = false;

  // Define the debounce time (300ms in this case)
  private readonly DEBOUNCE_TIME_MS = 300;

  constructor(
    // private webSocketService: WebSocketService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private _clipboardService: ClipboardService,
    private containerService: ContainerService,
    private route: ActivatedRoute,
    private squareTallysheetService: SquaredTallysheetService

  ) {
    //this.selectedColumnNames = [];
    this.recalculateSubject.pipe(
      debounceTime(this.DEBOUNCE_TIME_MS) // Debounce for 300ms
    ).subscribe(() => {
      //  this.recalculateAllVolumes();
    });
    this.activeInplace = Array(10).fill(false);
  }

  ngAfterViewInit(): void {
    if (this.editModeChecked) {
      this.viewEditModeSeverity = 'secondary';
    } else {
      this.viewEditModeSeverity = 'info';
    }
  }

  // Helper method to check if any inplace is active
  isAnyInplaceActive(): boolean {
    return this.activeInplace.some((state) => state);
  }

  // Method to handle activating an Inplace
  activateInplace(index: number): void {
    if (this.isAnyInplaceActive()) {
      console.log(`Cannot open Inplace at index ${index} because another is already open.`);
      this.messageService.add({
        severity: 'warn',
        summary: 'Action Denied',
        detail: 'You cannot open another field while one is already active.'
      });
      return;
    }

    console.log(`Activating Inplace at index ${index}.`);
    this.activeInplace = this.activeInplace.map((_, i) => i === index);
    // console.log(`Active states after activation: ${this.activeInplace}`);
  }

  showInPlaceAlreadyActive(value?: any) {
    //  alert('test ' + msg + "  this.is " + this.isAnyInplaceActive());
    if (this.isAnyInplaceActive()) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Action Denied',
        detail: 'You cannot open another field while one is already active.'
      });
    } else {
      this.revertBackInplaceValue = value;
    }
  }

  // Method to handle deactivating an Inplace
  deactivateInplace(index: number): void {
    // console.log(`Deactivating Inplace at index ${index}.`);
    this.activeInplace[index] = false;
    // console.log(`Active states after deactivation: ${this.activeInplace}`);
  }



  ngOnInit(): void {
    // const resolvedData = this.route.snapshot.data['data'];
    window.addEventListener('resize', this.checkScreenSize.bind(this));

    // Initialize forms with resolved data
    console.log(`**this.resolvedDataTallysheetSummary ${JSON.stringify(this.resolvedDataTallysheetSummary)} `);
    console.log(`**this.resolvedDataTallysheetRecord ${JSON.stringify(this.resolvedDataTallysheetRecord)} `);
    console.log(`**this.resolvedDataSquareTallysheetRecord ${JSON.stringify(this.resolvedDataSquareTallysheetRecord)} `);

    this.summaryId = this.resolvedDataTallysheetSummary.id;
    this.summary = this.resolvedDataTallysheetSummary;

    this.initializeForm();

    this.initializeTallySheetCommonFormNew();

    this.getExistingDataNew();

    // Call additional setup methods if needed

    this.setupDebounce('uomWidth');
    this.setupDebounce('uomThickness');
    this.setupDebounce('uomLength');
    this.setupDebounce('decimalPlaces');
    this.setupDebounce('roundingDirection');
    this.initSpeedDialMenuItems();
    this.loadSelectedColumns();
  }

  private getSummary(callback: (summaryId: number | null) => void): void {
    if (this.containerId) {
      this.containerService.getTallySummaryByContainerId(this.containerId).subscribe(
        (response: any) => {
          console.warn(`getSummaryOnInit ID ${JSON.stringify(response)}`);
          const tallySummaryId = response.tallySummaryId;
          this.summaryId = tallySummaryId;
          callback(tallySummaryId);  // Call getExistingData with the fetched ID
        },
        (error: any) => {
          console.error('Error fetching tally summary:', error);
          callback(null);  // Pass null if there's an error
        }
      );
    } else {
      callback(null);  // Pass null if containerId is not set
    }
  }

  tallySheetDataResponse!: TallySheetSummaryResponse;

  private createFormGroup(record: SquareTallyRow): FormGroup {

    return new FormGroup({
      id: new FormControl(record.id),
      serialNumber: new FormControl(record.serialNumber || 0),
      width: new FormControl(record.width || null),
      thickness: new FormControl(record.thickness || null),
      lengths: new FormControl(record.lengths || null),
      pieces: new FormControl(record.pieces || null),
      netVolumeCbm: new FormControl(record.netVolumeCbm || 0),
      netVolumeCft: new FormControl(record.netVolumeCft || 0),
      isNew: new FormControl(false),
      isUpdated: new FormControl(record.isUpdated || false),
      isDeleted: new FormControl(record?.isDeleted || false),
    });
  }


  ngOnDestroy(): void {
    if (this.ackSubscription) {
      this.ackSubscription.unsubscribe();
      this.recordsSubject.unsubscribe();
    }
    window.removeEventListener('resize', this.checkScreenSize.bind(this));

  }

  initializeForm(): void {
    this.recordsForm = new FormGroup({
      selectedColumnNames: new FormControl([]),
      tallySheetRecordsArray: new FormArray(this.initializeDummyData())
    });


  }

  initializeTallySheetCommonForm() {

    this.summaryForm = new FormGroup({
      id: new FormControl(null),
      uomWidth: new FormControl('in', [
        Validators.required,
        Validators.pattern(/^(mm|cm|in|ft|meter)$/), // only allows 'cm' or 'm'
      ]),
      uomthickness: new FormControl('in', [
        Validators.required,
        Validators.pattern(/^(mm|cm|in|ft|meter)$/), // only allows 'cm' or 'm'
      ]),
      uomLength: new FormControl('ft', [
        Validators.required,
        Validators.pattern(/^(mm|cm|in|ft|meter)$/), // only allows 'cm' or 'm'
      ]),
      container: new FormControl(null, [
        Validators.required,
        Validators.minLength(5),
      ]),
      typeOfShipment: new FormControl('Square', [
        Validators.required,
        Validators.pattern(/^(Round|Square)$/), // allows 'Round' or 'Square'
      ]),
      roundingDirection: new FormControl('Up', [
        Validators.required,
        Validators.pattern(/^(Up|Down)$/), // allows 'Up' or 'Down'
      ]),
      decimalPlaces: new FormControl(3, [
        Validators.required,
        Validators.min(0),
        Validators.max(6),
      ]),
      totalPieces: new FormControl(0),
      totalNetVolumeCBM: new FormControl(0),
      totalNetVolumeCFT: new FormControl(0),
      isRowModeEnabled: new FormControl<boolean>(true),
      summaryType: new FormControl('PRIVATE', [
        Validators.required,
        Validators.pattern(/^(PUBLIC|PRIVATE|SOLD)$/), // allows 'Up' or 'Down'
      ]),

      // selectedColumnNames:  new FormControl([])


    });
    if (this.summaryId) {

      this.squareTallysheetService.getTallysheetSummaryByContainerId(this.containerId).subscribe(
        (response: any) => {
          console.log(`getSummaryOnInit ID ${JSON.stringify(response)}`);


          this.summaryForm.patchValue({
            id: response.id,
            thicknessAllowance: response.thicknessAllowance,
            lengthAllowance: response.lengthAllowance,
            uomLength: response.uomLength,
            uomthickness: response.uomthickness,
            container: response.container,
            typeOfShipment: response.typeOfShipment,
            roundingDirection: response.roundingDirection,
            decimalPlaces: response.decimalPlaces,
            totalPieces: response.totalPieces,
            totalNetVolumeCBM: response.totalNetVolumeCBM,
            totalNetVolumeCFT: response.totalNetVolumeCFT,
            totalGrossVolumeCBM: response.totalGrossVolumeCBM,
            isRowModeEnabled: response.isRowModeEnabled,
            lengthIncreament: response.lengthIncreament ?? 0,
            copyPreviousRowLength: response.copyPreviousRowLength ?? false,
            summaryType: response.summaryType ?? 'PUBLIC',
            islengthIncreamentEnabled: new FormControl<boolean>(true),

          })

          this.summaryForm.get('typeOfShipment')?.disable();  // To disable
        },
        (error: any) => {
          this.messageService.clear();
          if (error.status === 401) { // UNAUTHORIZED ACCESS FOUND OR TOKEN EXPIRED
            this.messageService.add({
              severity: 'error',
              summary: error.error.error,
              detail: error.error.message,
              sticky: true
            });
            this.messageService.add({
              severity: 'warn',
              summary: 'Redirecting...',
              detail: 'Redirecting to login page',
              sticky: true
            });
            setTimeout(() => {
              this.router.navigate(['/register'], { replaceUrl: true });
            }, 3000);

          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Load Failed',
              detail: 'Unable to retrieve data. Please try again later.',
              sticky: true
            });
          }
        }
      )

    }
  }

  initializeDummyData(data?: SquareTallyRow[]): FormGroup[] {
    return Array.from({ length: 0 }, (_, i) => new FormGroup({
      id: new FormControl((i + 1)),
      serialNumber: new FormControl((i + 1) * 10),
      width: new FormControl(i + 1, Validators.required),
      thickness: new FormControl(i + 1, Validators.required),
      lengths: new FormControl(null, Validators.required),
      pieces: new FormControl(i + 1, Validators.required),
      netVolumeCbm: new FormControl(0),
      netVolumeCft: new FormControl(0),
      isNew: new FormControl(false),
      isUpdated: new FormControl(false),
      isDeleted: new FormControl(false),
    }));

  }


  initializeExistingData(data?: SquareTallyRow[]): FormGroup[] {
    const length = data?.length || 0;

    const dataset = Array.from({ length }, (_, i) => {
      const formGroup = new FormGroup({
        id: new FormControl(data?.[i]?.id || (i + 1)),
        serialNumber: new FormControl(data?.[i]?.serialNumber || (i + 1) * 10),
        lengths: new FormControl(data?.[i]?.lengths || null, Validators.required),
        thickness: new FormControl(data?.[i]?.thickness || null, Validators.required),
        width: new FormControl(data?.[i]?.width || null, Validators.required),
        pieces: new FormControl(data?.[i]?.pieces || null, Validators.required),
        netVolumeCbm: new FormControl(data?.[i]?.netVolumeCbm || 0),
        netVolumeCft: new FormControl(data?.[i]?.netVolumeCft || 0),
        isNew: new FormControl(false),
        isUpdated: new FormControl(data?.[i]?.isUpdated || false),
        isDeleted: new FormControl(data?.[i]?.isDeleted || false),

      });
      if (this.editModeChecked) {
        this.makeFieldEditableAtIndex(i);
      } else {
        this.makeFieldReadOnlyAtIndex(i);

      }

      // Track changes for each control that you want to monitor
      this.trackControlChanges(formGroup.get('width')!, i);
      this.trackControlChanges(formGroup.get('thickness')!, i);
      this.trackControlChanges(formGroup.get('lengths')!, i);
      this.trackControlChanges(formGroup.get('pieces')!, i);
      return formGroup;
    });

    this.summaryForm.patchValue({ totalPieces: length });

    return dataset;
  }

  get tallySheetRecordsArray(): FormArray {
    return this.recordsForm.get('tallySheetRecordsArray') as FormArray;
  }

  addRow(index: number, event: Event): void {
    console.log(`Add Row*****`);
    this.addRowAnimation[index + 1] = true;
    const isRowModeEnabled = this.summaryForm.get('isRowModeEnabled')?.value;
    this.addValueToCurrentRowLength(index);

    this.summaryForm.patchValue({
      totalPieces: this.countNotBlankRecords()

    });
    if (isRowModeEnabled) {
      console.log(`TWO Index is ${JSON.stringify(this.tallySheetRecordsArray.value)}`)
      if (this.tallySheetRecordsArray.at(index).value.width &&
        this.tallySheetRecordsArray.at(index).value.thickness &&
        this.tallySheetRecordsArray.at(index).value.lengths &&
        this.tallySheetRecordsArray.at(index).value.pieces) {


        const previousSerial = this.tallySheetRecordsArray.at(index).value.serialNumber;
        const nextSerial = this.tallySheetRecordsArray.at(index + 1)?.value.serialNumber || previousSerial + 10;
        var newSerialNumber = nextSerial;

        if (index !== this.tallySheetRecordsArray.length - 1) {
          newSerialNumber = (previousSerial + nextSerial) / 2;
        }

        // Check if serial numbers can still be divided
        if (this.countDecimalPlaces(newSerialNumber) >= this.maxFractionDepth) {
          alert("Cannot add more rows between these serial numbers.");
          return;
        }

        const newRow = new FormGroup({
          id: new FormControl(''),
          serialNumber: new FormControl(newSerialNumber),
          width: new FormControl(null, [Validators.required]),
          thickness: new FormControl(null, [Validators.required]),
          lengths: new FormControl(null, [Validators.required]),
          pieces: new FormControl(null, [Validators.required]),
          netVolumeCbm: new FormControl(0),
          netVolumeCft: new FormControl(0),
          isNew: new FormControl(true),
          isUpdated: new FormControl(false),
          isDeleted: new FormControl(false),
        });
        const record = newRow as FormGroup;
        this.tallySheetRecordsArray.insert(index + 1, newRow);
        this.actionLog.push({ id: newSerialNumber, type: 'add', index, records: record.value });
        // Track changes for the new control
        this.trackControlChanges(record, this.tallySheetRecordsArray.length - 1);

        this.setupDebounceOnDimension(record.get('width')!, record.get('serialNumber')?.value);
        this.setupDebounceOnDimension(record.get('thickness')!, record.get('serialNumber')?.value);
        this.setupDebounceOnDimension(record.get('lengths')!, record.get('serialNumber')?.value);
        this.setupDebounceOnDimension(record.get('pieces')!, record.get('serialNumber')?.value);
        this.isEditable[index + 1] = true;
        // this.calculateVolumes();
      }
    } else {
      console.log(`legnthwise`)
      if (this.tallySheetRecordsArray.at(index).value.length) {
        const previousSerial = this.tallySheetRecordsArray.at(index).value.serialNumber;
        const nextSerial = this.tallySheetRecordsArray.at(index + 1)?.value.serialNumber || previousSerial + 10;
        var newSerialNumber = nextSerial;

        if (index !== this.tallySheetRecordsArray.length - 1) {
          newSerialNumber = (previousSerial + nextSerial) / 2;
        }

        // Check if serial numbers can still be divided
        if (this.countDecimalPlaces(newSerialNumber) >= this.maxFractionDepth) {
          alert("Cannot add more rows between these serial numbers.");
          return;
        }

        const newRow = new FormGroup({
          id: new FormControl(''),
          serialNumber: new FormControl(newSerialNumber),
          width: new FormControl(null, [Validators.required]),
          thickness: new FormControl(null, [Validators.required]),
          lengths: new FormControl(null, [Validators.required]),
          pieces: new FormControl(null, [Validators.required]),
          netVolumeCbm: new FormControl(0),
          netVolumeCft: new FormControl(0),
          isNew: new FormControl(true),
          isUpdated: new FormControl(false),
          isDeleted: new FormControl(false),
        });
        const record = newRow as FormGroup;
        this.tallySheetRecordsArray.insert(index + 1, newRow);
        this.actionLog.push({ id: newSerialNumber, type: 'add', index, records: record.value });
        // Track changes for the new control
        this.trackControlChanges(record, this.tallySheetRecordsArray.length - 1);
        this.setupDebounceOnDimension(record.get('lengths')!, record.get('serialNumber')?.value);
        this.setupDebounceOnDimension(record.get('thickness')!, record.get('serialNumber')?.value);
        this.setupDebounceOnDimension(record.get('width')!, record.get('serialNumber')?.value);
        this.setupDebounceOnDimension(record.get('pieces')!, record.get('serialNumber')?.value);
        this.isEditable[index + 1] = true;
        // Call the function to copy the previous row's length if enabled

        // Call the function to add value to the current row's length if the flag is enabled

      }
    }
  }

  countDecimalPlaces(number: number): number {
    const strNumber = number.toString();
    const decimalIndex = strNumber.indexOf('.');
    if (decimalIndex === -1) {
      return 0; // No decimal places
    }
    return strNumber.length - decimalIndex - 1;
  }

  deleteRow(index: number, event: Event): void {
    if (!this.isEditable[index]) {
      event.preventDefault();
      this.messageService.add({
        severity: 'error',
        summary: 'Not',
        detail: 'You\'re in view mode and cant edit',
        sticky: false
      });
      // alert('Fields are readonly. Please enable editing to make changes.');
      return;  // Stop execution of the function
    }
    this.deleteRowLoading[index] = true;
    const response = confirm(`Are you sure do you want to delete?`)
    if (response) {

      setTimeout(() => {
        const row = this.tallySheetRecordsArray.at(index);
        const record = row as FormGroup;
        console.log(JSON.stringify(`### ${(JSON.stringify(record.value.id))}`))
        this.actionLog.push({ id: record.value.serialNumber, type: 'delete', index, records: record.value });
        if (record.value.id) {
          this.squareTallysheetService.deleteRecord(record.value.id).subscribe(
            (success: any) => {
              console.debug('Successfully Deleted row at index:', index);
              this.tallySheetRecordsArray.removeAt(index);
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Record Deleted Successfully'
              });
              this.deleteRowLoading[index] = false;
            },
            (error: any) => {
              console.debug('Error while Delete row at index:', index);
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Error while deleting record!'
              });
              this.deleteRowLoading[index] = false;
            },
            () => { },
          )
        } else {
          this.deleteRowLoading[index] = false;
          this.tallySheetRecordsArray.removeAt(index);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Record Deleted Successfully'
          });
        }
      }, 1000);
    }
  }

  onBlurValidate(index: number) {
    const control = this.tallySheetRecordsArray.at(index).get('length');
    if (control) {
      control.markAsTouched();
    }
  }


  tempMap = new Map<number, any>(); // Replace 'any' with the type of your value
  tempArray: any[] = [];

  onRowBlur(index: number, event: Event): void {
    if (!this.isEditable[index]) {
      event.preventDefault();  // Prevent default behavior
      // alert('Fields are readonly. Please enable editing to make changes.');
      this.messageService.add({
        severity: 'error',
        summary: 'Not',
        detail: 'You\'re in view mode and cant edit',
        sticky: false
      });
      return;  // Stop execution of the function
    }
    try {
      const record = this.tallySheetRecordsArray.at(index).value;
      this.recordOnBlur = record;

      const hasChange = this.deepEqual(this.recordOnBlur, this.recordOnFocus);
      if (hasChange) {
        if (this.isValidRow(this.recordOnBlur)) {
          setTimeout(() => {
            this.addRowLoading[index] = false;
          }, 250);
        }
      }
    } catch (error) {
      console.error('Error processing row blur:', error);
    }
  }

  handleRowInteraction(index: number): void {
    try {
      //console.log(`Handle Row Interaction`);
      this.calculateRowVolumeAtIndex(index);

      // Step 2: Mark control as touched
      const lengthControl = this.tallySheetRecordsArray.at(index).get('length');
      if (lengthControl) {
        lengthControl.markAsTouched();
      }


      // Step 3: Check if record has changed and send it if valid
      this.recordOnBlur = this.tallySheetRecordsArray.at(index).value;
      const hasChange = this.deepEqual(this.recordOnBlur, this.recordOnFocus);
      if (hasChange && this.isValidRow(this.recordOnBlur)) {
        this.tempMap.set(this.tallySheetRecordsArray.at(index).value.serialNumber, this.tallySheetRecordsArray.at(index).value);

        for (let [key, value] of this.tempMap) {
          console.log("Map is -----> " + key, JSON.stringify(value));
        }

        setTimeout(() => {
          this.addRowLoading[index] = false;
        }, 0);
      }
    }
    catch (error) {
      // console.error('Error processing row interaction:', error);
    }
  }

  serializeNewNatural(newNatural: any): any {
    return {
      ...newNatural,
      tallyRecords: Array.from(newNatural.tallyRecords.entries())
    };
  }

  isValidRow(record: SquareTallyRow): boolean {
    const isRowModeEnabled = this.summaryForm.get('isRowModeEnabled')?.value;
    if (isRowModeEnabled) {
      if (record.width && record.thickness && record.lengths && record.pieces) {
        return true;
      } else {
        return false;
      }
    } else {
      console.log(`isValidRow else`);
      if (record.lengths) {
        console.log(`isValidRow else->if ${record.lengths} return true`);
        return true;
      } else {
        console.log(`isValidRow else->else ${record.lengths} return false`);
        return false;
      }
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    console.log(`event.key ${event.key}  event.keyCode ${event.keyCode}`)

    const currentField = document.activeElement as HTMLInputElement;
    const currentRow = currentField.closest('tr') as HTMLElement;
    if (currentRow) {
      const rowIndex = Array.from(currentRow.parentNode!.children).indexOf(currentRow);
      const fieldIndex = Array.from(currentRow.querySelectorAll('input')).indexOf(currentField);
      if (event.key === 'Tab') {
        event.preventDefault();
      }
      if (event.metaKey) {
        //  console.log(`event.key ${event.key}`)

        event.preventDefault();
        // return;
      }
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
        if (event.key === 'ArrowUp' && rowIndex > 0) {
          this.focusField(rowIndex - 1, fieldIndex);
        } else if (event.key === 'ArrowDown' && rowIndex < this.tallySheetRecordsArray.length - 1) {
          this.focusField(rowIndex + 1, fieldIndex);
        }
        else if (event.key === 'ArrowRight' && fieldIndex === 0) {
          this.focusField(rowIndex, 1); // Move to thickness field
        } else if (event.key === 'ArrowLeft' && fieldIndex === 1) {
          this.focusField(rowIndex, 0); // Move to Length field
        }
        else if ((event.key === 'Enter' && event.ctrlKey) || (event.key === 'Enter' && event.metaKey)) {
          this.addRow(rowIndex, event); // Add new row and focus on its Length field
          setTimeout(() => {
            this.focusField(rowIndex + 1, 0);
          }, 5);
        }
      } else if (event.key === 'Enter' || event.key === 'Tab') {


        const isRowModeEnabled = this.summaryForm.get('isRowModeEnabled')?.value;

        // Log the current pattern
        console.log("Row pattern row-wise: ", isRowModeEnabled, "  fieldIndex  ", fieldIndex);

        if (fieldIndex === 0) { // Length field
          if (isRowModeEnabled === true) {
            this.focusField(rowIndex, 1); // Move to thickness field
          }
          /*else {
            console.log(`addrow ~~~~~~~~~~~ 1 `)
            // Check if a row already exists after the current row
            const nextRowExists = rowIndex < this.tallySheetRecordsArray.length - 1;
            if (!nextRowExists) {

              this.addRow(rowIndex, event); // Add new row and focus on Length of the new row
              setTimeout(() => {
                this.focusField(rowIndex + 1, 0); // Focus on the Length field of the new row
              }, 5);
            } else {
              this.focusField(rowIndex, 1); // Move to thickness field
            }
          }*/
        }
        else if (fieldIndex === 1) { // Length field
          if (isRowModeEnabled === true) {
            this.focusField(rowIndex, 2); // Move to thickness field
          }
        }
        else if (fieldIndex === 2) { // Length field
          if (isRowModeEnabled === true) {
            this.focusField(rowIndex, 3); // Move to thickness field
          }
        }
        // else if (fieldIndex === 3) { // Length field
        //   if (isRowModeEnabled === true) {
        //     this.focusField(rowIndex, 4); // Move to thickness field
        //   }
        // }
        else {
          console.log(`addrow ~~~~~~~~~~~ 1 `)
          // Check if a row already exists after the current row
          const nextRowExists = rowIndex < this.tallySheetRecordsArray.length - 1;
          if (!nextRowExists) {

            this.addRow(rowIndex, event); // Add new row and focus on Length of the new row
            setTimeout(() => {
              this.focusField(rowIndex + 1, 0); // Focus on the Length field of the new row
            }, 5);
          } else {
            this.focusField(rowIndex, 3); // Move to thickness field
          }
        }






      }
    }
  }

  moveToNextFieldOrAddRow(rowIndex: number): void {
    // Get the current row's field values
    const row = this.tallySheetRecordsArray.at(rowIndex);
    const widthValue = row.get('width')?.value;
    const thicknessValue = row.get('thickness')?.value;
    const lengthValue = row.get('length')?.value;
    const piecesValue = row.get('pieces')?.value;

    console.log(
      `widthValue: ${widthValue}, thicknessValue: ${thicknessValue}, lengthValue: ${lengthValue}, piecesValue: ${piecesValue}, 
      focusField: ${JSON.stringify(this.focusedField)}`
    );

    // Sequence of fields for the table
    const fieldSequence = ['width', 'thickness', 'length', 'pieces'];

    // Find the current field in the sequence
    const currentFieldIndex = fieldSequence.indexOf(this.focusedField?.fieldName);

    // Move to the next field if possible
    if (currentFieldIndex !== -1 && currentFieldIndex < fieldSequence.length - 1) {
      const nextFieldName = fieldSequence[currentFieldIndex + 1];
      const nextControl = row.get(nextFieldName);
      alert(`IN IF 1 nextContnextControl ${nextControl} nextControl.value ${nextControl.value}`)

      if (nextControl && nextControl.value == null) {
        // Focus the next field
        alert(`IN IF 2`)
        this.focusField(rowIndex, currentFieldIndex + 1);
        // document.querySelector<HTMLInputElement>(`[formControlName="${nextFieldName}"]`)?.focus();
        return;
      }
    }

    // If all fields are valid, add a new row
    if (widthValue && thicknessValue && lengthValue && piecesValue) {
      this.addRow(rowIndex, event);
    }
  }

  focusField(rowIndex: number, fieldIndex: number): void {
    const targetRow = document.querySelectorAll('tbody tr')[rowIndex];
    if (targetRow) {
      const targetField = targetRow.querySelectorAll('input')[fieldIndex] as HTMLInputElement;
      targetField?.focus();
    }
  }

  getFocusedRowIndex(): number {
    const focusedElement = document.activeElement as HTMLElement;
    const indexStr = focusedElement?.getAttribute('data-row-index');
    return indexStr ? parseInt(indexStr, 10) : 0;
  }

  focusRow(index: number): void {
    const row = document.querySelector(`[data-row-index="${index}"]`) as HTMLElement;
    if (row) {
      const input = row.querySelector('input') as HTMLElement; // Adjust selector to target the specific input if necessary
      input?.focus();
    }
  }

  calculateTotal(field: keyof SquareTallyRow): number {
    // console.log(`this.summaryForm?.value.decimalPlaces ${JSON.stringify(this.summaryForm?.value.decimalPlaces)}`)


    var total = this.tallySheetRecordsArray.controls.reduce((acc, record) => {
      const value = record.get(field)?.value;

      return acc + (typeof value === 'number' ? value : 0);
    }, 0);
    const totalFinal = this.roundValue(total ?? 0, this.summaryForm?.value.decimalPlaces ?? 3,
      this.summaryForm?.value.roundingDirection ?? 'Up');
    this.roundValue(total ?? 0, this.summaryForm?.value.decimalPlaces ?? 3, this.summaryForm?.value.roundingDirection ?? 'Up');
    return totalFinal;
  }

  recordHistoryFn(record: SquareTallyRow): void {
    this.recordOnFocus = record;
  }

  deepEqual(blur: SquareTallyRow, focus: SquareTallyRow) {

    const isRowModeEnabled = this.summaryForm.get('isRowModeEnabled')?.value;
    if (isRowModeEnabled) {
      try {
        if (blur.lengths !== focus.lengths) {
          return true;
        }
        if (blur.thickness !== focus.thickness) {
          return true;
        }
        if (blur.width !== focus.width) {
          return true;
        }
        if (blur.pieces !== focus.pieces) {
          return true;
        }
      } catch (error) {
        if (error instanceof TypeError) {
          return true;
        }
        throw error; // Re-throw if it's not a TypeError
      }
      return false;
    }
    else {
      //      console.log(`deepEqual 1 blur.length ${blur.length} !=== focus.length ${focus.length} `)
      try {
        if (blur.lengths !== focus.lengths) {
          return true;
        }
        if (blur.thickness !== focus.thickness) {
          return true;
        }
      } catch (error) {
        if (error instanceof TypeError) {
          return true;
        }
        throw error; // Re-throw if it's not a TypeError
      }
      return false;
    }
  }

  ackTallySheet(serialNumber: number, id: number) {
    console.log(`ackTallySheet Serail Number to find ${serialNumber}`)
    const serialNumberToFind = serialNumber; // The serial number to find
    const responseId = id; // The id received from the response

    const recordIndex = this.tallySheetRecordsArray.controls.findIndex(record => record.value.serialNumber === serialNumberToFind);

    if (recordIndex !== -1) {
      console.info(`Update the responseId ${responseId} id ${id}`)
      this.tallySheetRecordsArray.at(recordIndex).patchValue({ id: responseId });
      this.tallySheetRecordsArray.at(recordIndex).patchValue({ isNew: false });

      console.log('Record after patching:', this.tallySheetRecordsArray.at(recordIndex).value);
      // this.tallySheetRecordsArray.at(recordIndex).markAsPristine();
    } else {
      console.error('Record not found');
    }
  }

  ackTallySummary(id: number) {
    console.log(`Summary Id ${id}`)
    this.summaryForm.patchValue({ id: id });
    console.log(`Ack full summaryForm is ${JSON.stringify(this.summaryForm.value)} \nthis.summaryForm.value.id`)

    this.summaryId = this.summaryForm.value.id;

  }
  @ViewChildren('lengthInput') lengthInputs!: QueryList<ElementRef>;

  addZeroRow(): void {
    alert(`Add Zero Row`);
    const newRow = new FormGroup({
      id: new FormControl(''),
      serialNumber: new FormControl(10),
      width: new FormControl(null, [Validators.required]),
      thickness: new FormControl(null, [Validators.required]),
      lengths: new FormControl(null, [Validators.required]),
      pieces: new FormControl(null, [Validators.required]),
      netVolumeCbm: new FormControl(0),
      netVolumeCft: new FormControl(0),
      isNew: new FormControl(true),
      isUpdated: new FormControl(false),
      isDeleted: new FormControl(false),
      // set the default value

    });
    this.tallySheetRecordsArray.insert(0, newRow);
    console.log('New row added:', newRow.value);
    console.log('Current state of tallySheetRecordsArray:', this.tallySheetRecordsArray.value);

    this.editModeChecked = true;
    this.isEditable[0] = true;
    setTimeout(() => {
      this.toggleEditMode(true);
      const firstLengthInput = this.lengthInputs.first;

      if (firstLengthInput) {
        firstLengthInput.nativeElement.focus();
      }
    }, 100);
    const record = newRow as FormGroup;
    this.trackControlChanges(record, 0);
    this.setupDebounceOnDimension(record.get('width')!, record.get('width')?.value);
    this.setupDebounceOnDimension(record.get('thickness')!, record.get('thickness')?.value);
    this.setupDebounceOnDimension(record.get('lengths')!, record.get('lengths')?.value);
    this.setupDebounceOnDimension(record.get('pieces')!, record.get('pieces')?.value);
  }


  // saveSummary() {
  //   console.log(`Save Summary ${JSON.stringify(this.summaryForm.value)}`);
  //   this.webSocketService.sendSummary(this.summaryForm.value);
  // }

  // Call to save a new summary
  isSaveSummaryInProgress: boolean = false;
  saveSummary(): void {
    console.log(`Save Summary ${JSON.stringify(this.summaryForm.value)}`);
    const summary = this.summaryForm.value;

    this.isSaveSummaryInProgress = true;
    this.summaryForm.disable(); // Disable form during save operation
    console.log(`saveSummary is ${JSON.stringify(summary)}`)
    this.squareTallysheetService.saveSummary(summary).subscribe(
      response => {

        // Initialize form with a first blank row
        this.summaryId = response.id;
        // this.webSocketService.setSummaryIdSessionStorage(String(this.summaryId));

        // Success message on save completion
        this.messageService.add({
          severity: 'success',
          summary: 'Save Successful',
          detail: 'Your changes have been saved successfully.',
          sticky: false
        });

        this.summaryForm.markAsPristine();
        this.addZeroRow();

        this.scrollToTallyCard();
        this.activeIndex = 1;
        console.log('Summary saved:', response);
      },
      error => {
        // Error message on save failure
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to save summary'
        });

        console.error('Error saving summary:', error);
        this.isSaveSummaryInProgress = false;
        this.summaryForm.enable(); // Re-enable the form on failure
      },
      () => {
        this.isSaveSummaryInProgress = false;
        this.summaryForm.enable(); // Re-enable the form on success
      }
    );
  }

  // Call to delete a record by ID
  isDeleteRecordInProgress: boolean = false;
  deleteRecord(id: number): void {
    alert(`Delete Records ${id}`);
    this.isDeleteRecordInProgress = true;
    this.squareTallysheetService.deleteRecord(id)
      .subscribe(
        response => {
          console.log('Record deleted:', response);
        },
        error => {
          console.error('Error deleting record:', error);
        },
        () => {
          this.isDeleteRecordInProgress = false;
        }
      );
  }

  convertToCm(value: number, uom: string): number {
    //  console.log(`convertToCm value ${value} uom ${uom}`)
    switch (uom) {
      case 'mm':
        return value / 10;
      case 'cm':
        return value;
      case 'in':
        return value * 2.54;
      case 'ft':
        return value * 30.48;
      case 'meter':
        return value * 100;
      default:
        return value;
    }

  }

  calculateVolumes(): void {
    const roundingDirection: 'Up' | 'Down' = this.summaryForm?.value?.roundingDirection ?? 'Up';
    const decimalPlaces: number = this.summaryForm?.value?.decimalPlaces ?? 3;

    let totalCBM = 0;
    let totalCFT = 0;
    let totalPieces = 0;

    console.log('Calculation started with the following parameters:');
    console.log(`Rounding Direction: ${roundingDirection}, Decimal Places: ${decimalPlaces}`);

    // Iterate through each record in the tally sheet array
    this.tallySheetRecordsArray.value.forEach(record => {
      console.log(`Processing record with serial number: ${record.serialNumber}`);

      // Validate and convert Width to Centimeters
      let widthInCm = this.convertToCm(record.width, this.summary.uomWidth);
      console.log(`Width (${record.width} ${this.summary.uomWidth}) converted to cm: ${widthInCm}`);

      // Validate and convert Thickness to Centimeters
      let thicknessInCm = this.convertToCm(record.thickness, this.summary.uomThickness);
      console.log(`Thickness (${record.thickness} ${this.summary.uomThickness}) converted to cm: ${thicknessInCm}`);

      // Validate and convert Length to Feet if it's not already in feet
      let lengthInFeet = this.summary.uomLength === 'ft'
        ? record.lengths
        : this.convertToCm(record.lengths, this.summary.uomLength) / 30.48;
      console.log(`Length (${record.lengths} ${this.summary.uomLength}) converted to feet: ${lengthInFeet}`);

      // Calculate CBM: (Width (cm) * Thickness (cm) * Length (ft) * Pieces)
      const cbm = (widthInCm * thicknessInCm * lengthInFeet * record.pieces) / 1000000;
      console.log(`CBM Calculation: (${widthInCm} * ${thicknessInCm} * ${lengthInFeet} * ${record.pieces}) / 1000000 = ${cbm}`);

      // Round CBM to specified decimal places and direction
      const roundedCBM = this.roundValue(cbm, decimalPlaces, roundingDirection);
      console.log(`Rounded CBM: ${roundedCBM}`);

      // Calculate CFT: CFT = CBM * 35.315
      const cft = roundedCBM * 35.315;
      console.log(`CFT Calculation: ${roundedCBM} * 35.315 = ${cft}`);

      // Round CFT to specified decimal places and direction
      const roundedCFT = this.roundValue(cft, decimalPlaces, roundingDirection);
      console.log(`Rounded CFT: ${roundedCFT}`);

      // Update the record with calculated and rounded values
      record.netVolumeCbm = roundedCBM;
      record.netVolumeCft = roundedCFT;

      // Accumulate totals for CBM, CFT, and Pieces
      totalCBM += roundedCBM;
      totalCFT += roundedCFT;
      totalPieces += record.pieces;
    });

    // Apply rounding to total values
    this.summary.totalNetVolumeCBM = this.roundValue(totalCBM, decimalPlaces, roundingDirection);
    console.log(`Total CBM (after rounding): ${this.summary.totalNetVolumeCBM}`);

    this.summary.totalNetVolumeCFT = this.roundValue(totalCFT, decimalPlaces, roundingDirection);
    console.log(`Total CFT (after rounding): ${this.summary.totalNetVolumeCFT}`);

    this.summary.totalPieces = totalPieces;
    console.log(`Total Pieces: ${this.summary.totalPieces}`);

    console.log('Calculation completed.');
  }

  calculateGrandTotals(): void {
    let totalCBM = 0;
    let totalCFT = 0;
    let totalPieces = 0;

    const roundingDirection: 'Up' | 'Down' = this.summaryForm?.value?.roundingDirection ?? 'Up';
    const decimalPlaces: number = this.summaryForm?.value?.decimalPlaces ?? 3;

    this.tallySheetRecordsArray.controls.forEach((control) => {
      const record = control.value as SquareTallyRow;
      totalCBM += record.netVolumeCbm || 0;
      totalCFT += record.netVolumeCft || 0;
      totalPieces += record.pieces || 0;
    });

    const totalNetVolumeCBM = this.roundValue(totalCBM, decimalPlaces, roundingDirection);
    const totalNetVolumeCFT = this.roundValue(totalCFT, decimalPlaces, roundingDirection);
    // const  totalPieces = totalPieces;


    //this.tallySheet.summary = this.summary;
    this.summaryForm.patchValue({
      totalNetVolumeCBM: totalNetVolumeCBM,
      totalNetVolumeCFT: totalNetVolumeCFT,
      totalPieces: totalPieces
    });
    // console.log(`Total CBM (after rounding): ${totalNetVolumeCBM}`);

    // console.log(`Total CFT (after rounding): ${totalNetVolumeCFT}`);

    // console.log(`Total Pieces: ${this.summary.totalPieces}`);
  }


  summary: TallySummary = new TallySummary();



  /*calculateRowVolumeAtIndex(index: number): void {
    console.log(`tallySheetRecordsArray ${JSON.stringify(this.tallySheetRecordsArray.value)}`)
    console.log(`Summary Common ${JSON.stringify(this.summary)}`);
   
    const { container, ...summary } = this.summary;
    console.log(`***** Summary Common ${JSON.stringify(summary)}`);



    const record = this.tallySheetRecordsArray.controls[index].value as SquaredTallyRow;
    const control = this.tallySheetRecordsArray.at(index); // Update this to reference the correct form control

    if (record.width && record.thickness && record.length && record.pieces) {
      const lengthCm = this.convertToCm(record.length, this.summaryForm?.value?.uomLength ?? 'cm');
      const thicknessCm = this.convertToCm(record.thickness, this.summaryForm?.value?.uomthickness ?? 'cm');
      const roundingDirection = this.summaryForm.value?.roundingDirection ?? 'Up';
      const decimalPlaces = this.summaryForm?.value?.decimalPlaces ?? 3;

      const netThicknessCm = this.convertToCm((record.thickness - (this.summaryForm?.value?.thicknessAllowance ?? 0)), this.summaryForm?.value?.uomthickness ?? 'cm');
      const netLengthCm = this.convertToCm((record.length - (this.summaryForm?.value?.lengthAllowance ?? 0)), this.summaryForm?.value?.uomLength ?? 'cm');

      
      const netThickness = this.roundValue(record.thickness - (this.summaryForm?.value?.thicknessAllowance ?? 0), decimalPlaces, roundingDirection);
      console.log(`record.thickness ${record.thickness} netThickness ${netThicknessCm} thicknessCm ${thicknessCm} thicknessAllowance ${this.summaryForm?.value?.thicknessAllowance} decimalPlaces ${decimalPlaces} roundingDirection ${roundingDirection}`);
      const netLength = this.roundValue(record.length - (this.summaryForm?.value?.lengthAllowance ?? 0), decimalPlaces, roundingDirection);

      const grossCBM = this.roundValue((thicknessCm * thicknessCm * lengthCm) / 16000000, decimalPlaces, roundingDirection);
      const netCBM = this.roundValue((netThicknessCm * netThicknessCm * netLengthCm) / 16000000, decimalPlaces, roundingDirection);
      console.log("grossthickness", record.thickness, "netThickness", netThicknessCm, "grossLength", record.length, "netLength", netLengthCm, "grossCBM", grossCBM, "netCBM", netCBM);
      console.log(`Debugging Calculation for netCBM - Net thickness: ${netThicknessCm}, Net thickness Squared: ${netThicknessCm * netThicknessCm}, Net Length: ${netLengthCm}, Decimal Places: ${decimalPlaces}, Rounding Direction: ${roundingDirection}, Calculation: (${netThicknessCm} * ${netThicknessCm} * ${netLengthCm}) / 16000000 = ${(netThicknessCm * netThicknessCm * netLengthCm) / 16000000}, Rounded netCBM: ${this.roundValue((netThicknessCm * netThicknessCm * netLengthCm) / 16000000, decimalPlaces, roundingDirection)}`);

      console.log(`Debugging Calculation for grossCBM - thickness (cm): ${thicknessCm}, thickness Squared: ${thicknessCm * thicknessCm}, Length (cm): ${lengthCm}, Decimal Places: ${decimalPlaces}, Rounding Direction: ${roundingDirection}, Calculation: (${thicknessCm} * ${thicknessCm} * ${lengthCm}) / 16000000 = ${(thicknessCm * thicknessCm * lengthCm) / 16000000}, Rounded grossCBM: ${this.roundValue((thicknessCm * thicknessCm * lengthCm) / 16000000, decimalPlaces, roundingDirection)}`);

      const grossCFT = this.roundValue((grossCBM * 35.315), decimalPlaces, roundingDirection);
      const netCFT = this.roundValue((netCBM * 35.315), decimalPlaces, roundingDirection);

      record.netLength = netLength;

      record.grossVolumeCbm = grossCBM;
      record.netVolumeCbm = netCBM;
      record.grossVolumeCft = grossCFT
      record.netVolumeCft = netCFT;
      this.summary.totalGrossVolumeCBM = grossCBM;
      this.summary.totalNetVolumeCBM = netCBM;
      this.summary.totalGrossVolumeCFT = grossCFT;
      this.summary.totalNetVolumeCFT = netCFT;

      this.summary.uomWidth = this.summaryForm?.value?.uomWidth;
      this.summary.uomThickness = this.summaryForm?.value?.uomThickness;
      this.summary.uomLength = this.summaryForm?.value?.uomLength;

      this.summary.containerNumber = this.summaryForm?.value?.containerNumber;
      this.summary.roundingDirection = roundingDirection;
      this.summary.decimalPlaces = decimalPlaces;
      this.summary.totalPieces = this.countNotBlankRecords();
      //console.log(`thicknessCm ${thicknessCm} netThickness ${netThickness} GrossCBM ${grossCBM} netCBM ${netCBM} grossCFT ${grossCFT} netCFT ${netCFT}`)
      setTimeout(() => {
        this.tallySheetRecordsArray.at(index).patchValue({
          netVolumeCbm: netCBM,
          netVolumeCft: netCFT
        });
      }, 0);
    }
  }*/

  calculateRowVolumeAtIndex(index: number): void {
    //console.log(`^^^^tallySheetRecordsArray at index ${index}: ${JSON.stringify(this.tallySheetRecordsArray.value)}`);

    const { container, ...summary } = this.summary;
    //console.log(`***** Summary Common: ${JSON.stringify(summary)}`);

    const record = this.tallySheetRecordsArray.controls[index].value as SquareTallyRow;
   // console.log(`Processing record at index ${index}: ${JSON.stringify(record)}`);

    if (record.width && record.thickness && record.lengths && record.pieces) {
     // console.log(`Dimensions before conversion - Width: ${record.width}, Thickness: ${record.thickness}, Length: ${record.lengths}`);

      // Convert dimensions to proper units
      const lengthCm = this.convertToCm(record.lengths, this.summaryForm?.value?.uomLength ?? 'cm');
      const thicknessCm = this.convertToCm(record.thickness, this.summaryForm?.value?.uomThickness ?? 'cm');
      const widthCm = this.convertToCm(record.width, this.summaryForm?.value?.uomWidth ?? 'cm');

      //console.log(`Converted dimensions - Width (cm): ${widthCm}, Thickness (cm): ${thicknessCm}, Length (cm): ${lengthCm}`);

      // Get rounding settings
      const roundingDirection = this.summaryForm.value?.roundingDirection ?? 'Up';
      const decimalPlaces = this.summaryForm?.value?.decimalPlaces ?? 3;
     // console.log(`Rounding settings - Direction: ${roundingDirection}, Decimal Places: ${decimalPlaces}`);

      // Apply formula to calculate net CBM
      const netCBM = this.roundValue(
        (widthCm * thicknessCm * lengthCm * record.pieces) / 1000000,
        decimalPlaces,
        roundingDirection
      );
      //console.log(`Calculated net CBM using formula (Width x Thickness x Length x Pieces / 1000000): ${netCBM}`);

      // Convert CBM to CFT using formula (CFT = CBM * 35.315)
      const netCFT = this.roundValue(netCBM * 35.315, decimalPlaces, roundingDirection);
     // console.log(`Converted net CBM to net CFT (CBM * 35.315): ${netCFT}`);

      // Update the record values
      record.netVolumeCbm = netCBM;
      record.netVolumeCft = netCFT;

      // Update the summary totals
      this.summary.totalNetVolumeCBM += netCBM;
      this.summary.totalNetVolumeCFT += netCFT;
      this.summary.uomWidth = this.summaryForm?.value?.uomWidth;
      this.summary.uomThickness = this.summaryForm?.value?.uomThickness;
      this.summary.uomLength = this.summaryForm?.value?.uomLength;
      this.summary.containerNumber = this.summaryForm?.value?.containerNumber;
      this.summary.roundingDirection = roundingDirection;
      this.summary.decimalPlaces = decimalPlaces;
      this.summary.totalPieces = this.countNotBlankRecords();

     // console.log(`Summary after updating - Total CBM: ${this.summary.totalNetVolumeCBM}, Total CFT: ${this.summary.totalNetVolumeCFT}, Total Pieces: ${this.summary.totalPieces}`);

      // Patch the updated values back to the form control
      setTimeout(() => {
        this.tallySheetRecordsArray.at(index).patchValue({
          netVolumeCbm: netCBM,
          netVolumeCft: netCFT
        });
      //  console.log(`Patched values - netCBM: ${netCBM}, netCFT: ${netCFT}`);
      }, 0);
      this.calculateGrandTotals();
    } else {
     // console.log(`Record at index ${index} is missing required fields for calculation.`);
    }
  }


  roundValue(value: number, decimalPlaces: number, direction: 'Up' | 'Down' | 'Default'): number {
    const multiplier = Math.pow(10, decimalPlaces);
    if (direction === 'Up') {
      return Math.ceil(value * multiplier) / multiplier;
    } else if (direction === 'Down') {
      return Math.floor(value * multiplier) / multiplier;
    } else { // Natural rounding
      return Math.round(value * multiplier) / multiplier;
    }
  }


  countNotBlankRecords() {
    var totalNotBlankRecords = 0;
    for (var i = 0; i < this.tallySheetRecordsArray.length; i++) {
      const record = this.tallySheetRecordsArray.at(i).value;
      if (record.length || record.thickness) {
        totalNotBlankRecords = totalNotBlankRecords + 1;
      }
      /* if (record.length) {
        totalNotBlankRecords = totalNotBlankRecords + 1;
      } */
      /* if (record.length && record.thickness) {
        totalNotBlankRecords = totalNotBlankRecords + 1;
      } */
      // console.log(`Total Non Empty Records are ${totalNotBlankRecords}`)
    }
    return totalNotBlankRecords;
  }
  trackControlChanges(control: AbstractControl, index: number) {
    let lastValue = control?.value ?? '';
    if (control) {
      control.valueChanges.subscribe(newValue => {
        // Check if the new value is actually different from the last value
        if (this.isRealChange(lastValue, newValue)) {
          control.markAsDirty();

          this.tallySheetRecordsArray.at(index).get('isNew')?.setValue(true, { emitEvent: false });
        } else {
          // control.markAsPristine();
        }
        lastValue = newValue;
      });
    }
  }

  isRealChange(lastValue: any, newValue: any): boolean {
    if (lastValue && newValue && typeof lastValue === 'object' && typeof newValue === 'object') {
      const thicknessChanged = lastValue.thickness !== newValue.thickness;
      const lengthChanged = lastValue.length !== newValue.length;
      return thicknessChanged || lengthChanged;
    }
    return lastValue !== newValue;
  }

  private getExistingData(containerId?: number | null): void {
    console.log(`getExistingData 1st this.loading ${this.loading} this.isLoadingMore ${this.isLoadingMore}`)
    console.log(`getExistingData 2nd`);
    console.log(`getExistingData 3rd summaryId ${containerId}  this.summaryId ${this.containerId}`);

    this.loading = true;
    //this.webSocketService.getRecords(this.summaryId, this.pageNumber, this.pageSize);
    // this.getSummaryOnInit(this.summaryId)
    if (this.summaryId) {
      console.log(`getExistingData FOURTH `);
      this.squareTallysheetService.getTallysheetRecordsByContainerId(this.containerId, this.pageNumber, this.pageSize)
        .subscribe(response => {
          console.log('Records Line No 908');
          console.log('Records received:', JSON.stringify(response));

          this.totalPages = response.totalPages;
          this.isLoadingMore = false; // Reset loading state
          // this.appendData(response.content);
          this.loading = false;
          this.dataLoaded = true;
          let recordsArray = response.content as SquareTallyRow[];
          const formArray = this.recordsForm?.get('tallySheetRecordsArray') as FormArray;

          if (formArray) {
            // Append new records to the existing FormArray
            recordsArray.forEach((record, i) => {
              const formGroup = this.createFormGroup(record);
              formArray.push(formGroup);
              // Track changes for each control that you want to monitor
              this.trackControlChanges(formGroup.get('width')!, formArray.length - 1);
              this.trackControlChanges(formGroup.get('thickness')!, formArray.length - 1);
              this.trackControlChanges(formGroup.get('length')!, formArray.length - 1);
              this.trackControlChanges(formGroup.get('pieces')!, formArray.length - 1);
              // console.log(`initRecords :: setupDebounceOnDimension ${i}`)
              this.setupDebounceOnDimension(formGroup.get('width')!, formGroup?.get('serialNumber')?.value!);
              this.setupDebounceOnDimension(formGroup.get('thickness')!, formGroup?.get('serialNumber')?.value!);
              this.setupDebounceOnDimension(formGroup.get('lengths')!, formGroup?.get('serialNumber')?.value!);
              this.setupDebounceOnDimension(formGroup.get('pieces')!, formGroup?.get('serialNumber')?.value!);
            });
          } else {
            // Initialize the form with the first batch of records
            const newFormArray = new FormArray(this.initializeExistingData(recordsArray));
            this.recordsForm = new FormGroup({
              selectedColumnNames: new FormControl([]),
              tallySheetRecordsArray: newFormArray
            });

            // Track changes for each control in the initial set of records
            newFormArray.controls.forEach((formGroup, i) => {
              // this.trackControlChanges(formGroup.get('length')!, i);
              // this.trackControlChanges(formGroup.get('thickness')!, i);
              // Track changes for each control that you want to monitor
              this.trackControlChanges(formGroup.get('width')!, formArray.length - 1);
              this.trackControlChanges(formGroup.get('thickness')!, formArray.length - 1);
              this.trackControlChanges(formGroup.get('lengths')!, formArray.length - 1);
              this.trackControlChanges(formGroup.get('pieces')!, formArray.length - 1);

              this.setupDebounceOnDimension(formGroup.get('width')!, formGroup?.get('serialNumber')?.value!);
              this.setupDebounceOnDimension(formGroup.get('thickness')!, formGroup?.get('serialNumber')?.value!);
              this.setupDebounceOnDimension(formGroup.get('lengths')!, formGroup?.get('serialNumber')?.value!);
              this.setupDebounceOnDimension(formGroup.get('pieces')!, formGroup?.get('serialNumber')?.value!);
            });
          }

          this.makeFieldsReadonly();
          this.editModeChecked = false;

        }, error => {
          this.messageService.clear();
          console.error('Error fetching records:', error);
          if (error.status === 401) { // UNAUTHORIZED OR EXPIRED TOKEN
            this.messageService.add({
              severity: 'error',
              summary: error.error.error,
              detail: error.error.message,
              sticky: true
            });
            this.messageService.add({
              severity: 'warn',
              summary: 'Redirecting...',
              detail: 'Redirecting to login page',
              sticky: true
            });
            setTimeout(() => {
              this.router.navigate(['/register'], { replaceUrl: true });
            }, 3000);
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Load Failed',
              detail: 'Unable to retrieve data. Please try again later.',
              sticky: true
            });
          }

          this.loading = false;
          this.isLoadingMore = false; // Reset loading state
        });
    }
  }


  private appendData(data?: any[]): void {
    const formArray = this.summaryForm.get('tallySheetRecordsArray') as FormArray;
    const newRecords = this.initializeExistingData(data);
    console.log(`appendData  ${JSON.stringify(data)}`)
    console.log('formArray ** ' + formArray);

    console.log('formArray.value ** ' + formArray.value);
    console.log('FormArray length:', formArray.length);
    newRecords.forEach(record => {
      if (formArray)
        formArray.push(record)

    });


    if (formArray) {
      this.summaryForm.patchValue({
        totalPieces: formArray.length
      });
    }
  }

  private loadMoreRecords(): void {

    console.log(`loadMoreRecords ${this.pageNumber} < ${this.totalPages}  pageNumber ${this.pageNumber}`)
    if ((this.pageNumber < this.totalPages) && this.hasAllRecordsLoaded === false) {
      console.log(`€€€€`)
      this.pageNumber++;
      this.getExistingData();
    }
    else {
      // alert(`All Records are already loaded!`)
      this.hasAllRecordsLoaded = true;
    }
  }

  onScroll(event: any): void {
    const target = event.target as HTMLElement;
    const scrollHeight = target.scrollHeight;
    const offsetHeight = target.offsetHeight;
    const scrollTop = target.scrollTop;
    // Threshold value (in pixels)
    const threshold = 100;
    // console.log(`onScroll Event`)
    // Check if user is within threshold distance from the bottom
    if (scrollHeight - offsetHeight - scrollTop <= threshold && !this.isLoadingMore) {
      console.log('Approaching end of table, loading more records...');
      this.isLoadingMore = true;
      // console.log(`CurrentPage ${this.pageNumber} TotalPages ${this.totalPages}`)

      this.loadMoreRecords();

    }
  }

  /* recalculateAllVolumes(): void {
    alert(this.totalPages + "  " + this.pageNumber);
    while (this.totalPages > this.pageNumber) {
      this.loadMoreRecords();
    }
    // Iterate over all records in the FormArray
    this.tallySheetRecordsArray.controls.forEach((_, index) => {
      this.calculateVolumes(index);
    });
  } */

  // Call this function on keyup
  onKeyup(event: KeyboardEvent): void {
    const inputElement = event.target as HTMLInputElement;
    // Pass the new value to the form control
    this.summaryForm.get('thicknessAllowance')?.setValue(inputElement.value);
    // Trigger the debounced recalculation
    this.recalculateSubject.next();
  }


  recalculateAllVolumes(isMakeDirty: boolean = false): void {
    console.log(`recalculateAllVolumes`);


    // Load all pages first



    // this.loadAllPages().then(() => {

    // After all pages are loaded, iterate over all records and recalculate volumes
    this.tallySheetRecordsArray.controls.forEach((_, index) => {
      this.calculateRowVolumeAtIndex(index);
    });

    // Set a flag indicating all calculations are done
    this.reCalculateTotalOfAllRows();
    this.allCalculationsDone = true;
    this.calculateTotal('width');
    this.calculateTotal('thickness');
    this.calculateTotal('lengths');
    this.calculateTotal('pieces');

    this.calculateTotal('netVolumeCbm');
    this.calculateTotal('netVolumeCft');

    this.hideProgress();
    // });
  }

  // Load all pages sequentially
  /*loadAllPages(): Promise<void> {

    return new Promise((resolve) => {
      const loadNextPage = () => {
        if (this.pageNumber < this.totalPages) {
          this.loadMoreRecords(); // Load next page
          this.pageNumber++;
          setTimeout(loadNextPage, 10); // Adjust timeout based on API response time
        } else {
          resolve(); // Resolve when all pages are loaded
        }
      };
      loadNextPage();
    });
  }*/


  temp!: any;
  tempValue!: number;
  tempFieldName!: 'thicknessAllowance' | 'lengthAllowance' | 'uomLength' | 'uomthickness' | 'roundingDirection' | 'decimalPlaces' | 'typeOfShipment' | 'containerNumber';

  loadAll(currentValue?: number, fieldName?: 'thicknessAllowance' | 'lengthAllowance' | 'uomLength' | 'uomthickness' | 'roundingDirection' | 'decimalPlaces' | 'typeOfShipment' | 'containerNumber', event?: Event) {
    //alert(`1~~~~this.isCommonFieldsEditable ${!this.isCommonFieldsEditable}`)


    if (!this.isCommonFieldsEditable) {
      if (event) {
        event.preventDefault();  // Prevent default behavior
        event.stopImmediatePropagation();  // Stop other handlers from being called
      }
      this.messageService.clear();
      // alert('Fields are readonly. Please enable editing to make changes.');
      this.messageService.add({
        severity: 'error',
        summary: 'Field is open',
        detail: 'Either one of the fields is already open. Close it to open another one. Only one field can be open at a time. ',
        sticky: true,
        closable: true,
      });
      return;  // Stop execution of the function
    }
    this.isCommonFieldsEditable = false;
    //alert(`2~~~~this.isCommonFieldsEditable ${!this.isCommonFieldsEditable}`)

    if (this.checkIfAnyFieldIsDirty()) {
      // alert(`Pls save first!`);
      this.makeFieldsReadonly();
      // this.inplacethickness.onActivate.subscribe((success: any) => { alert(`on open ${success}`) })
      setTimeout(() => {
        if (this.inplacethickness.onActivate.subscribe((success: any) => { alert(`on open ${success}`) }))
          this.inplacethickness.deactivate(); // Programmatically close
        this.inplaceLength.deactivate(); // Programmatically close
        this.inplaceUomLength.deactivate(); // Programmatically close
        this.inplaceUomthickness.deactivate(); // Programmatically close
        this.inplaceRoundingDirection.deactivate(); // Programmatically close
        this.inplaceDecimalPlaces.deactivate(); // Programmatically close
        // this.inplaceContainerNumber.deactivate(); // Programmatically close
        // this.inplaceTypeOfShipment.deactivate(); // Programmatically close
      }, 50);

      return;
    } else {
      if (!this.hasAllRecordsLoaded) { // ${this.pageNumber} < ${this.totalPages}  pageNumber ${this.pageNumber}
        alert(`@@@`)
        this.makeFieldsReadonly();
        this.showProgress();
        this.fetchAllRecords(currentValue, fieldName);
      } else {
        // Create a deep copy of the FormArray's value
        const formArray = this.recordsForm?.get('tallySheetRecordsArray') as FormArray;
        this.temp = JSON.parse(JSON.stringify(formArray.value)); // Deep copy
        this.tempValue = currentValue ?? 0;
        this.tempFieldName = fieldName ?? 'thicknessAllowance';
        this.makeFieldsReadonly();

      }
    }
  }

  /*private fetchAllRecords(currentValue?: number | undefined, fieldName?: 'thicknessAllowance' | 'lengthAllowance' | 'uomLength' | 'uomthickness' | 'roundingDirection' | 'decimalPlaces' | 'typeOfShipment' | 'containerNumber') {

    if (!this.hasAllRecordsLoaded) {
      this.showProgress();
      if (this.summaryId) {
        this.squareTallysheetService.getAllRecordsRestful(this.summaryId).subscribe((response: any) => {

          const newFormArray = new FormArray(this.initializeExistingData(response));
          this.recordsForm = new FormGroup({
            selectedColumnNames: new FormControl([]),
            tallySheetRecordsArray: newFormArray
          });


          // Track changes for each control in the initial set of records
          newFormArray.controls.forEach((formGroup, i) => {
            // Track changes for each control that you want to monitor
            this.trackControlChanges(formGroup.get('width')!, formArray.length - 1);
            this.trackControlChanges(formGroup.get('thickness')!, formArray.length - 1);
            this.trackControlChanges(formGroup.get('lengths')!, formArray.length - 1);
            this.trackControlChanges(formGroup.get('pieces')!, formArray.length - 1);
            this.setupDebounceOnDimension(formGroup.get('width')!, formGroup?.get('serialNumber')?.value!);
            this.setupDebounceOnDimension(formGroup.get('thickness')!, formGroup?.get('serialNumber')?.value!);
            this.setupDebounceOnDimension(formGroup.get('lengths')!, formGroup?.get('serialNumber')?.value!);
            this.setupDebounceOnDimension(formGroup.get('pieces')!, formGroup?.get('serialNumber')?.value!);
          });

          this.hideProgress();

          this.recalculateAllVolumes();

          this.hasAllRecordsLoaded = true;

          // Create a deep copy of the FormArray's value
          const formArray = this.recordsForm?.get('tallySheetRecordsArray') as FormArray;
          this.temp = JSON.parse(JSON.stringify(formArray.value)); // Deep copy

          this.tempValue = currentValue ?? 0;
          this.tempFieldName = fieldName ?? 'thicknessAllowance';

          this.loadSelectedColumns();
        },
          (error) => {
            console.error('Error getting records:', error);
            this.loading = false;
            this.isLoadingMore = false;
          },
          () => {
            this.hideProgress();

          }
        );
      }
    }
  }*/
  private fetchAllRecords(currentValue?: number | undefined, fieldName?: 'thicknessAllowance' | 'lengthAllowance' | 'uomLength' | 'uomthickness' | 'roundingDirection' | 'decimalPlaces' | 'typeOfShipment' | 'containerNumber') {
    //  if(!this.summaryId){
    //   return;
    // }

    if (!this.hasAllRecordsLoaded) {
      this.showProgress();
      if (this.summaryId) {
        this.squareTallysheetService.getAllRecordsRestful(this.summaryId).subscribe((response: any) => {

          const newFormArray = new FormArray(this.initializeExistingData(response));
          this.recordsForm = new FormGroup({
            selectedColumnNames: new FormControl([]),
            tallySheetRecordsArray: newFormArray
          });

          // Track changes for each control in the initial set of records
          newFormArray.controls.forEach((formGroup, i) => {
            // Track changes for each control that you want to monitor
            this.trackControlChanges(formGroup.get('width')!, i);
            this.trackControlChanges(formGroup.get('thickness')!, i);
            this.trackControlChanges(formGroup.get('lengths')!, i);
            this.trackControlChanges(formGroup.get('pieces')!, i);

            this.setupDebounceOnDimension(formGroup.get('width')!, formGroup?.get('serialNumber')?.value!);
            this.setupDebounceOnDimension(formGroup.get('thickness')!, formGroup?.get('serialNumber')?.value!);
            this.setupDebounceOnDimension(formGroup.get('lengths')!, formGroup?.get('serialNumber')?.value!);
            this.setupDebounceOnDimension(formGroup.get('pieces')!, formGroup?.get('serialNumber')?.value!);
          });

          this.hideProgress();

          this.recalculateAllVolumes();

          this.hasAllRecordsLoaded = true;

          // Create a deep copy of the FormArray's value
          this.temp = JSON.parse(JSON.stringify(newFormArray.value)); // Deep copy

          this.tempValue = currentValue ?? 0;
          this.tempFieldName = fieldName ?? 'thicknessAllowance';

          this.loadSelectedColumns();
        },
          (error) => {
            console.error('Error getting records:', error);
            this.loading = false;
            this.isLoadingMore = false;
          },
          () => {
            this.hideProgress();
          });
      }
    }
  }


  /*loadAllRecords(records: any[]): Promise<void> {
    return new Promise((resolve) => {
      const newFormArray = new FormArray(this.initializeExistingData(records));
      this.recordsForm = new FormGroup({
        tallySheetRecordsArray: newFormArray
      });
      this.hasAllRecordsLoaded = true;

      // Track changes for each control in the initial set of records
      newFormArray.controls.forEach((formGroup, i) => {
        this.trackControlChanges(formGroup.get('length')!, i);
        this.trackControlChanges(formGroup.get('thickness')!, i);
        this.setupDebounceOnDimension(formGroup.get('length')!, formGroup.get('serialNumber')?.value);
        this.setupDebounceOnDimension(formGroup.get('thickness')!, formGroup.get('serialNumber')?.value);


      });

      this.showProgressBar = false;
      // alert(this.tallySheetRecordsArray.length)
      this.recalculateAllVolumes();
      // Resolve the promise once processing is complete
      resolve();
    });
  }*/

  loadAllRecords(records: any[]): void {
    const newFormArray = new FormArray(this.initializeExistingData(records));
    this.recordsForm = new FormGroup({
      selectedColumnNames: new FormControl([]),
      tallySheetRecordsArray: newFormArray
    });
    this.hasAllRecordsLoaded = true;

    // Track changes for each control in the initial set of records
    newFormArray.controls.forEach((formGroup, i) => {
      this.trackControlChanges(formGroup.get('lengths')!, i);
      this.trackControlChanges(formGroup.get('thickness')!, i);
      this.trackControlChanges(formGroup.get('width')!, i);
      this.trackControlChanges(formGroup.get('pieces')!, i);


      this.setupDebounceOnDimension(formGroup.get('lengths')!, formGroup.get('serialNumber')?.value);
      this.setupDebounceOnDimension(formGroup.get('thickness')!, formGroup.get('serialNumber')?.value);
      this.setupDebounceOnDimension(formGroup.get('width')!, formGroup.get('serialNumber')?.value);
      this.setupDebounceOnDimension(formGroup.get('pieces')!, formGroup.get('serialNumber')?.value);
    });

    this.hideProgress();
    this.recalculateAllVolumes();
  }


  closeBox() {
    // alert(`closeBox ${this.inplace}`)
    this.inplacethickness.deactivate(); // Programmatically close
  }

  reCalculateTotalOfAllRows() {
    let totalNetVolumeCbm = 0;
    let totalNetVolumeCft = 0;

    for (var i = 0; i < this.tallySheetRecordsArray.length; i++) {
      totalNetVolumeCbm = totalNetVolumeCbm + this.tallySheetRecordsArray.at(i).value.netVolumeCbm;
      totalNetVolumeCft = totalNetVolumeCft + this.tallySheetRecordsArray.at(i).value.netVolumeCft;
    }
    console.log(`totalNetVolumeCbm ${totalNetVolumeCbm} === ${this.calculateTotal('netVolumeCbm')}`)
    console.log(`totalNetVolumeCft ${totalNetVolumeCft} === ${this.calculateTotal('netVolumeCft')}`)
  }



  setupDebounce(fieldName: string) {
    this.summaryForm.get(fieldName)?.valueChanges.pipe(
      debounceTime(100), // adjust debounce time as needed
      distinctUntilChanged()
    ).subscribe((value) => {
      this.onFieldChange(fieldName, value);
    });
  }

  setupDebounceOnDimension(control: AbstractControl, serialNumber: number): void {
   // console.log(`setupDebounceOnDimension control ${control}`)
    control.valueChanges.pipe(
      debounceTime(50), // Adjust the debounce time as needed
      distinctUntilChanged()
    ).subscribe(() => {

      let index = this.tallySheetRecordsArray.controls.findIndex(record => record.value.serialNumber === serialNumber);
      if (index === -1) {
        index = 0;
      }
      //console.log(`## Index ## ${index}`)
      const record = this.tallySheetRecordsArray.at(index).value as SquareTallyRow;
      const commonFields = this.summaryForm.value as TallySummary;

      this.calculateRowVolumeAtIndex(index);
     // console.log(`From setupDebounceOnDimension()`);
      this.tallySheetRecordsArray.at(index).patchValue({
        netVolumeCft: record.netVolumeCft,
        netVolumeCbm: record.netVolumeCbm
      });
      this.handleRowInteraction(index)


    });
  }

  onFieldChange(fieldName: string, value: any) {
    this.recalculateAllVolumes();
  }

  sendUpdateRequest(fieldName: 'thicknessAllowance' | 'lengthAllowance' | 'uomLength' | 'uomthickness' | 'roundingDirection' | 'decimalPlaces') {
    console.log(`Update ${fieldName} 's Request ${this.tallySheetRecordsArray.length}`)
  }


  tallySheet: SquareTallySheet = new SquareTallySheet();


  saveTallySheetDimentions(fieldName?: string, callback?: () => void) {
    if (this.tempMap.size > 0) {
      this.show();
      this.showProgress();
      // Clear the array
      this.tempArray = [];

      // Rewrite the map into the array
      this.tempMap.forEach((value, key) => {
        const index = this.tallySheetRecordsArray.controls.findIndex(record => record.value.serialNumber === key);

        this.addRowLoading[index] = true;


        this.tempArray.push(value);
      });
      this.summary = this.summaryForm.value;
      // alert(`this.summary ${JSON.stringify(this.summary)}`)
      //return array;
      console.table(this.tempArray)
      this.tallySheet.rows = this.tempArray;
      this.tallySheet.summary = this.summary;
      this.tallySheet.summary.id = this.summaryId!;
      delete this.tallySheet.summary.container.documents;

      console.log(`Here is the full payload ${JSON.stringify(this.tallySheet)}`)

      // this.webSocketService.sendTallySheet(this.tallySheet);
      this.squareTallysheetService.saveSquareTallySheet(this.tallySheet).subscribe(
        (success: TallySheetSummaryResponse) => {
          console.log(`Success Response is\n${JSON.stringify(success)}`)
          this.tallySheetDataResponse = success;  // Automatically update component's property when data is received
          console.log('Received data:', this.tallySheetDataResponse);

          // Loop through each row in the response
          this.tallySheetDataResponse.rows.forEach(rowResponse => {
            const serialNumber = rowResponse.serialNumber;
            const index = this.tallySheetRecordsArray.controls.findIndex(record => record.value.serialNumber === serialNumber);
            // alert('Before @@@@ ' + JSON.stringify(this.tallySheetRecordsArray.at(index).value));
            if (index !== -1) {
              // Update the form control if the serial number matches
              this.tallySheetRecordsArray.at(index).patchValue({ isNew: false });
              this.tallySheetRecordsArray.at(index).patchValue({ id: rowResponse.rowId });
              this.tallySheetRecordsArray.at(index).markAsPristine();
            }
            if (fieldName) {
              this.summaryForm.get(fieldName?.toString())?.markAsPristine();
            }
            // alert(  ' After @@@ ' + JSON.stringify(this.tallySheetRecordsArray.at(index).value));
            // After updating, remove the serial number from the map

            this.clear();
            this.messageService.add({
              severity: 'success',
              summary: 'Save Successful',
              detail: 'Your changes have been saved successfully.',
              sticky: false
            });


            this.addRowLoading[index] = false;
            //  console.log(`Map Size before delete is ${this.tempMap.size}`);
            this.tempMap.delete(serialNumber);
            // console.log(`Map Size after delete is ${this.tempMap.size}`);
            this.hideProgress();
            // Run the callback function if provided
            if (callback) {
              callback();
            }

          });
        },
        (error: any) => {
          console.log(`error Response is\n${JSON.stringify(error)}`)
          this.messageService.add({ severity: 'error', summary: 'Error while saving', detail: 'Message Content', sticky: false });
          this.hideProgress();

        },

      )
    } else {
      //this.show();
      this.messageService.add({
        severity: 'info',
        summary: 'Data Up-to-Date',
        detail: 'All records are already up-to-date, no further action is needed.',
        sticky: false
      });


    }
  }

  show() {
    this.messageService.add({
      severity: 'info',
      summary: 'Saving Data',
      detail: 'Your changes are being saved. Please wait...',
      sticky: true
    });

  }

  clear() {
    this.messageService.clear();
  }

  tallySummaryAtLoadTime: any | undefined;

  updateCommonField(isUpdated: boolean, fieldName: 'thicknessAllowance' | 'lengthAllowance' | 'uomLength' | 'uomThickness' | 'roundingDirection' | 'decimalPlaces' | 'containerNumber' | 'uomWidth'
  ) {
    if (!isUpdated) {
      this.showProgress();
      this.messageService.add({
        severity: 'warn', // Indicating a warning
        summary: 'Reverted',
        detail: 'If you have made any changes, they will be reverted.'
      });


      //  alert(" this.temp .length " + JSON.stringify(this.temp));
      this.summaryForm!.get(fieldName)!.markAsPristine();
      //alert(this.revertBackInplaceValue);
      this.summaryForm.value.fieldName = this.revertBackInplaceValue;
      this.summaryForm!.get(fieldName).setValue(this.revertBackInplaceValue);
      this.summaryForm!.patchValue({ [fieldName]: this.revertBackInplaceValue });

      this.recordsForm.get('selectedColumnNames')?.markAsPristine();
      this.testPrisineAndDirty();
      this.showProgress();

      let formArray = this.recordsForm?.get('tallySheetRecordsArray') as FormArray;
      formArray.clear();
      // alert("After clear " + formArray.length);
      // alert(" this.temp .length " + JSON.stringify(this.temp));

      this.temp.forEach((record: SquareTallyRow, i: any) => {
        const formGroup = this.createFormGroup(record);
        formArray.push(formGroup);
        // Track changes for each control that you want to monitor
        this.trackControlChanges(formGroup.get('length')!, formArray.length - 1);
        this.trackControlChanges(formGroup.get('thickness')!, formArray.length - 1);
        // console.log(`initRecords :: setupDebounceOnDimension ${i}`)
        this.setupDebounceOnDimension(formGroup.get('lengths')!, formGroup?.get('serialNumber')?.value!);
        this.setupDebounceOnDimension(formGroup.get('thickness')!, formGroup?.get('serialNumber')?.value!);
        this.setupDebounceOnDimension(formGroup.get('width')!, formGroup?.get('serialNumber')?.value!);
        this.setupDebounceOnDimension(formGroup.get('pieces')!, formGroup?.get('serialNumber')?.value!);
        //  this.summaryForm.get(this.tempFieldName)?.setValue(this.tempValue);
        this.makeFieldsEditable();



      });
      this.hideProgress();
      this.closeAllInplaces(); // Programmatically close
      /*const currentValue = this.summaryForm.value[fieldName];
      const originalValue = this.tallySummaryAtLoadTime[fieldName];

      if (currentValue !== originalValue) {
        // this.sendUpdateRequest(fieldName);
        alert(`update ${fieldName}`);
      } else {
        alert('remove this alert and don\'t do anything');
      }*/
    }
    else {


      this.testPrisineAndDirty();
      // if (this.tallySummaryAtLoadTime) {
      //   this.summaryForm.value[fieldName] = this.tallySummaryAtLoadTime[fieldName];
      //   this.summaryForm.get(fieldName)?.setValue(this.tallySummaryAtLoadTime[fieldName]);
      //   this.recalculateAllVolumes();
      // }
      this.showProgress();
      this.recalculateAllVolumes(true);
      this.makeFieldsEditable();
      let formArray = this.recordsForm?.get('tallySheetRecordsArray') as FormArray;


      for (var i = 0; i < formArray.value.length; i++) {
        this.tempMap.set(this.tallySheetRecordsArray.at(i).value.serialNumber, this.tallySheetRecordsArray.at(i).value);
      }
      this.saveTallySheetDimentions(fieldName, () => {
        this.closeAllInplaces()
      });
    }

    // this.inplaceContainerNumber.deactivate(); // Programmatically close
    // this.inplaceTypeOfShipment.deactivate(); // Programmatically close
  }


  private closeAllInplaces() {
  //  this.inplacethickness.deactivate(); // Programmatically close
    //this.inplaceLength.deactivate(); // Programmatically close
    this.inplaceUomWidth.deactivate(); // Programmatically close
    this.inplaceUomLength.deactivate(); // Programmatically close
    this.inplaceUomthickness.deactivate(); // Programmatically close
    this.inplaceRoundingDirection.deactivate(); // Programmatically close
    this.inplaceDecimalPlaces.deactivate();
  }

  downloadTallySummaryInExcel(summaryId: number) {
    this.squareTallysheetService.exportTallySummary(summaryId).subscribe(
      (response) => {
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `tally-summary-${summaryId}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        console.error('Error downloading the file', error);
      }
    );
  }

  /*canDeactivate(): boolean {
    alert(`canDeactivate`)
    if (this.recordsForm.dirty && !this.recordsForm.pristine) {
      return confirm('You have unsaved changes. Do you really want to leave?');
    }
    return true;
  }*/

  /*canDeactivate(): boolean | Observable<boolean> {
    if ((this.recordsForm.dirty && !this.recordsForm.pristine) || (this.summaryForm.dirty && !this.summaryForm.pristine)) {
      return new Observable<boolean>((observer) => {
        this.confirmationService.confirm({
          message: 'You have unsaved changes. Are you sure you want to leave?',
          accept: () => {
            observer.next(true);
            observer.complete();
          },
          reject: () => {
            observer.next(false);
            observer.complete();
          }
        });
      });
    }
    return true;
  }*/

  canDeactivate(): boolean | Promise<boolean> {
    // Check both forms' dirty and pristine statuses
    if ((this.recordsForm.dirty && !this.recordsForm.pristine) || (this.summaryForm.dirty && !this.summaryForm.pristine)) {

      console.log("Checking form statuses...");

      // Check and log for recordsForm
      if (this.recordsForm.dirty) {
        console.log("recordsForm is dirty");
        this.logFormFieldStatus(this.recordsForm, 'recordsForm');
      } else {
        console.log("recordsForm is pristine");
      }

      // Check and log for summaryForm
      if (this.summaryForm.dirty) {
        console.log("summaryForm is dirty");
        this.logFormFieldStatus(this.summaryForm, 'summaryForm');
      } else {
        console.log("summaryForm is pristine");
      }

      return new Promise<boolean>((resolve) => {
        this.confirmationService.confirm({
          header: 'Unsaved Changes Confirmation',
          message: 'You have unsaved changes. Do you really want to leave?',
          acceptButtonStyleClass: 'p-button-danger',
          rejectButtonStyleClass: 'p-button-success',
          closeOnEscape: false,

          defaultFocus: "reject",
          accept: () => {
            resolve(true);  // Allow navigation
          },
          reject: () => {
            resolve(false); // Prevent navigation
          }
        });
      });
    }

    return true;
  }

  testPrisineAndDirty() {
    this.logFormFieldStatus(this.summaryForm, 'summaryForm');
    this.logFormFieldStatus(this.recordsForm, 'recordsForm');

  }
  // Utility function to log field statuses of the form
  logFormFieldStatus(formGroup: FormGroup, formName: string) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control!.dirty) {
        // console.log(`${formName} - Field "${key}" is dirty`);
      } else {
        // console.log(`${formName} - Field "${key}" is pristine`);
      }
    });
  }

  submitForm() {
    this.summaryForm.markAllAsTouched();
    if (this.summaryForm.valid) {
      this.saveSummary();
    }
  }
  selectValueIfExist(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    // alert(`inputElement ${}`)

    if (null != inputElement && inputElement.value) {
      inputElement.select();
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEventSave(event: KeyboardEvent) {
    //    console.log(event.altKey + "   " + event.key)

    if (event.altKey && event.key === 's') {
      event.preventDefault();  // Prevent the default browser action
      this.saveTallySheetDimentions();
    }
    else if (event.metaKey && event.key === 's') {
      event.preventDefault();  // Prevent the default browser action
      this.saveTallySheetDimentions();
    }

  }


  checkIfAnyFieldIsDirty() {

    const formArray = this.recordsForm?.get('tallySheetRecordsArray') as FormArray;

    for (let i = 0; i < formArray.length; i++) {
      const formGroup = formArray.at(i) as FormGroup;

      // Loop through each control in the form group
      for (const controlName in formGroup.controls) {
        const control = formGroup.get(controlName);

        if (control?.dirty) {
          // alert(`isDirty true`)
          return true; // If any field is dirty, return true
        }
      }
    }
    // alert(`isDirty false`)

    return false; // If no field is dirty, return false
  }

  showDialog(show: boolean = false) {
    this.visible = show;
  }

  // Array to track read-only state for each row
  isEditable: boolean[] = [];
  isCommonFieldsEditable: boolean = true;

  // Method to make all fields read-only
  makeFieldsReadonly() {
    this.isEditable = this.tallySheetRecordsArray.controls.map(() => false);
    this.isCommonFieldsEditable = false;
  }
  // Method to make all fields read-only
  makeFieldsEditable() {
    this.isEditable = this.tallySheetRecordsArray.controls.map(() => true);
    this.isCommonFieldsEditable = true;
    this.tallySheetRecordsArray.controls.forEach(control => {
      control.markAllAsTouched(); // Ensures validation messages show
    });

  }

  attachValueChanges(formGroup: FormGroup, index: number) {
    ['lengths', 'thickness', 'width', 'pieces'].forEach((field) => {
      const control = formGroup.get(field);
      if (control && !control['_valueChangesAttached']) {
        control['_valueChangesAttached'] = true; // Custom flag to prevent duplicates
        control.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
          console.log(`Record ${index}, Field ${field} changed to:`, value);
        });
      }
    });
  }


  makeFieldReadOnlyAtIndex(index: number) {
    this.isEditable[index] = false;
  }

  // Method to make specific row editable
  makeFieldEditableAtIndex(index: number) {
    this.isEditable[index] = true;
  }

  // Method to show an alert and prevent input
  showAlertIfReadonly(event: Event, index: number) {
    if (!this.isEditable[index]) {
      event.preventDefault();
      //  this.confirm(`showAlertIfReadonly`);
      //  alert('Fields are readonly. Please enable editing to make changes.');
    }
  }

  showAlertIfContainerNumberReadonly(event: Event) {

    if (!this.isCommonFieldsEditable) {
      event.preventDefault();
      //  this.confirm(`showAlertIfContainerNumberReadonly`);
    }
    // alert('Fields are readonly. Please enable editing to make changes.');
  }

  /* confirmUnsavedChanges(text?: string) {
    this.confirmationService.confirm({
      header: 'Unsaved Changes',
      // message: `You have unsaved changes. Are you sure you want to discard them? ${text ? text : ''}`,
      message: `You have unsaved changes. Are you sure you want to discard them?`,

      accept: () => {
        // User accepted; stay on the page, do not proceed with loadAll
        this.messageService.add({
          severity: 'info',
          summary: 'Changes Discarded',
          detail: 'You have chosen to discard unsaved changes.',

        });
        // Do nothing to stay on the page
      },
      reject: () => {
        // User rejected; proceed with loading or whatever you want
        this.messageService.add({
          severity: 'info',
          summary: 'Changes Retained',
          detail: 'You have chosen to keep your unsaved changes.',

        });
      }
    });
  } */

  confirmUnsavedChanges(onAccept: () => void, onReject?: () => void, text?: string) {
    this.confirmationService.confirm({
      header: 'Unsaved Changes',
      message: `You have unsaved changes. Are you sure you want to discard them?`,

      accept: () => {
        // Proceed with the accept callback
        this.messageService.add({
          severity: 'info',
          summary: 'Changes Discarded',
          detail: 'You have chosen to discard unsaved changes.',
        });
        onAccept();  // Execute the logic for accepting the unsaved changes
      },
      reject: () => {
        // Execute reject callback or do nothing
        if (onReject) onReject();
        this.messageService.add({
          severity: 'info',
          summary: 'Changes Retained',
          detail: 'You have chosen to keep your unsaved changes.',
        });
      }
    });
  }




  // Method to show the progress bar
  showProgress() {
    this.showProgressBarSubject.next(true);
  }

  // Method to hide the progress bar
  hideProgress() {
    this.showProgressBarSubject.next(false);
  }

  // onChangeEditMode(event: ToggleButtonChangeEvent) {
  //   console.log('onChangeEditMode ' + JSON.stringify(event));
  //   if (this.checkIfAnyFieldIsDirty()) {
  //     alert(`Pls save first!`);
  //     setTimeout(() => {
  //       this.editModeChecked = true;
  //       this.mode = 'Edit';
  //     }, 5);

  //     return;
  //   }
  //   if (event.checked) {
  //     this.fetchAllRecords();
  //     this.makeFieldsEditable();
  //     this.editModeChecked = true;
  //     this.mode = 'Edit';
  //   } else {
  //     this.makeFieldsReadonly();
  //     this.editModeChecked = false;
  //     this.mode = 'View';
  //   }
  // }


  confirmUnsavedChangesNew() {
    this.confirmationService.confirm({
      header: 'Are you sure?',
      message: 'You have unsaved changes. Would you like to save them?',
      key: 'modeButtonChangeConfirm',
      accept: () => {
        this.saveTallySheetDimentions();
      },
      reject: () => {
      }
    });
  }
  private toggleEditMode(checked: boolean) {

    if (this.checkIfAnyFieldIsDirty()) {
      this.confirmUnsavedChangesNew();
      setTimeout(() => {
        this.editModeChecked = true;
        this.currentMode = 'Edit';
      }, 5);
      return;
    }

    if (checked) {
      this.fetchAllRecords();
      this.makeFieldsEditable();
      this.editModeChecked = true;
      this.currentMode = 'Edit';
      this.changeToMode = 'View';
      this.changeToModeIcon = 'pi pi-eye';
      this.summaryForm.get('isRowModeEnabled')?.enable();
      this.viewEditModeSeverity = 'secondary';

    } else {
      this.makeFieldsReadonly();
      this.editModeChecked = false;
      this.currentMode = 'View';
      this.changeToMode = 'Edit';
      this.changeToModeIcon = 'pi pi-pencil';
      this.summaryForm.get('isRowModeEnabled')?.disable();

      this.viewEditModeSeverity = 'info';
    }
  }
  onEditModeButtonClick() {
    const newMode = !this.editModeChecked;

    this.toggleEditMode(newMode);

  }

  isUpdateContainerNumberInProgress: boolean = false;
  // Call to update container number


  fieldNameMap: { [key: string]: string } = {
    container: 'Container Number',
    lengthAllowance: 'Length Allowance',
    thicknessAllowance: 'thickness Allowance',
    uomLength: 'Unit of Measure (Length)',
    uomthickness: 'Unit of Measure (thickness)',
    totalPieces: 'Total Pieces',
    totalNetVolumeCBM: 'Total Net Volume (CBM)',
    totalNetVolumeCFT: 'Total Net Volume (CFT)',
    decimalPlaces: 'Decimal Places',
    roundingDirection: 'Rounding Direction',
    typeOfShipment: 'Type of Shipment',
    selectedColumnNames: 'Selected Column Names'
  };

  getErrorMessage(formControlName: string): string {
    const control = this.summaryForm.get(formControlName);
    const fieldName = this.fieldNameMap[formControlName] || formControlName;


    if (control!.hasError('required')) {
      return `${fieldName} is required`;
    }
    if (control!.hasError('min')) {
      return `${fieldName} must be greater than or equal to ${control?.errors!['min'].min}`;
    }
    if (control!.hasError('maxlength')) {
      return `${fieldName} must be shorter than ${control?.errors!['maxlength'].requiredLength} characters`;
    }
    if (control!.hasError('minlength')) {
      return `${fieldName} must be at least ${control?.errors!['minlength'].requiredLength} characters long`;
    }
    if (control!.hasError('pattern')) {
      if (fieldName === 'uomLength' || fieldName === 'uomthickness') {
        return `${fieldName} must be 'mm', 'cm', 'in', 'ft', or 'm'`;
      }
      if (fieldName === 'typeOfShipment') {
        return `${fieldName} must be 'Round' or 'Square'`;
      }
      if (fieldName === 'roundingDirection') {
        return `${fieldName} must be 'Up' or 'Down'`;
      }
    }
    if (control!.hasError('max')) {
      return `${fieldName} must be less than or equal to ${control?.errors!['max'].max}`;
    }

    return ''; // Return empty string if no errors
  }

  navigateToRoute() {
    this.router.navigate(['/tally-sheet2']);
  }

  // This will handle browser refresh or tab closing
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    // alert(`unload ${$event.returnValue}`)
    if ((this.recordsForm.dirty && !this.recordsForm.pristine) || (this.summaryForm.dirty && !this.summaryForm.pristine)) {
      $event.returnValue = true; // This triggers the browser's default confirmation dialog
    }
  }

  scrollToTallyCard() {
    // Use the nativeElement to scroll into view
    if (this.tallyCard) {
      this.tallyCard.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  scrollTosummaryFormCard() {
    // Use the nativeElement to scroll into view
    if (this.summaryFormElement) {
      this.summaryFormElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }




  onToggleRowModeEnabled(event: ToggleButtonChangeEvent, field?: Inplace) {
    if (this.summaryId) {
      this.summaryForm.get('isRowModeEnabled')?.disable();

      this.squareTallysheetService.updateRowModeEnabled(this.summaryId, event?.checked ?? false).subscribe({
        next: (response) => {
          console.log('Updated successfully: ', response);
          this.summaryForm.get('isRowModeEnabled')?.enable();
          this.messageService.clear();
          this.messageService.add({
            severity: 'success',
            summary: 'Mode Changed',
            detail: 'Record mode has been successfully changed.',

          });
          if (field) {
            field.deactivate();
            this.summaryForm.get('isRowModeEnabled')?.markAsPristine();
          }
        },
        error: (err) => {
          console.error('Update failed:', err);


          this.summaryForm.get('isRowModeEnabled')?.enable();
          this.messageService.add({
            severity: 'error',
            summary: 'Mode Change Failed',
            detail: 'Failed to change the record mode. Please try again.'
          });



        }

      });
    }
  }

  isInplaceActivated: boolean = false;
  onInplaceActivate(event: Event, inplaceFieldName: Inplace, fieldNameString?: string) {
    this.isInplaceActivated = true;
    this.isCommonFieldsEditable = false;
    if (this.recordsForm.dirty || this.summaryForm.dirty) {
      alert('onInplaceActivate')
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
      inplaceFieldName.deactivate();

      this.confirmUnsavedChanges(() => {
        // User accepted; continue execution here
        if (fieldNameString === 'inplaceContainerNumber') {
          this.tempExistingContainerNumber = this.summaryForm.value.containerNumber;
        }
      });
    }
  }

  onInplaceDeactivate(event: Event, inplaceFieldName: Inplace, fieldNameString?: string) {
    this.isInplaceActivated = false;
    this.isCommonFieldsEditable = true;

    if (status === 'activate') {
      this.isInplaceActivated = true;
    } else {
      this.isInplaceActivated = false;
    }
    if (this.recordsForm.dirty || this.summaryForm.dirty) {
      alert('onInplaceActivate')
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
      inplaceFieldName.deactivate();

      this.confirmUnsavedChanges(() => {
        // User accepted; continue execution here
        if (fieldNameString === 'inplaceContainerNumber') {
          this.tempExistingContainerNumber = this.summaryForm.value.containerNumber;
        }
      });
    }
  }


  // Function to check if a column is selected
  isColumnSelected(column: string): boolean {
    /* const isSelected = this.selectedColumnNames.some(col => {
      return col.value === column;
    });
    return isSelected; */
    const selectedColumns = this.recordsForm.get('selectedColumnNames')?.value || [];
    const isSelected = selectedColumns.some((col: { value: string; }) => {
      return col.value === column;
    });
    return isSelected;
    // return selectedColumns.includes(column);

  }



  onSelectionChange(event: any) {
    // Access the form control for selectedColumnNames
    const selectedColumnNamesFormControl = this.recordsForm.get('selectedColumnNames');
    let selectedColumnNames = selectedColumnNamesFormControl?.value || [];
    this.saveSelectedColumns(selectedColumnNames);
    this.recordsForm.get('selectedColumnNames')?.markAsPristine();
  }

  saveSelectedColumns(selected: any) {
    localStorage.setItem('selectedColumns', JSON.stringify(selected));
  }

  loadSelectedColumns() {
    const savedColumns = localStorage.getItem('selectedColumns');
    if (savedColumns && this.recordsForm) {
      this.recordsForm.get('selectedColumnNames')?.setValue(JSON.parse(savedColumns));
    }
  }

  calculateColspanForTotals(): number {
    let colspan = 3; // Default: No, SR, totalPieces
    // Gross Volume columns (these will always have totals)
    if (this.isColumnSelected('NetDimensions')) {
      colspan += 2; // Gross CBM and CFT
    }

    if (this.editModeChecked) {
      //colspan += 1; // Gross CBM and CFT

    }
    return colspan;
  }

  calculateTotalVisibleColumns() {
    let colspan = 4; // Start with the default 3 (No, SR, totalPieces)

    if (this.isColumnSelected('GrossVolume')) {
      colspan += 2; // Gross CBM and CFT columns
    }

    if (this.isColumnSelected('NetDimensions')) {
      colspan += 2; // Net Length and thickness columns
    }

    if (this.isColumnSelected('NetVolume')) {
      colspan += 2; // Net CBM and CFT columns
    }
    if (this.editModeChecked) {
      colspan += 1;
    }

    return colspan;
  }

  initSpeedDialMenuItems() {
    this.speedDialMenuItems = [
      {
        label: 'Save',
        icon: 'pi pi-save',
        command: () => {
          this.saveTallySheetDimentions();
        }
      },
      {
        label: this.getEditModeLabel(),
        icon: this.changeToModeIcon, // Use current icon based on mode
        command: () => {
          this.initSpeedDialMenuItems();
          this.onEditModeButtonClick(); // Toggle mode on click
        }
      }, {
        icon: 'pi pi-share-alt',
        label: 'Share',
        // visible: (this.summaryId!) && (this.summaryForm.get('summaryType')?.value ?? 'PUBLIC' === ('PUBLIC' || 'SOLD')),  // Check activeIndex dynamically
        visible: this.summaryId && (['PUBLIC', 'SOLD'].includes(this.summaryForm.get('summaryType')?.value ?? 'PUBLIC')),
        command: () => {
          this.getShareUrl();
        }
      },
      {
        icon: 'pi pi-refresh',
        label: 'Refresh',
        command: () => {
          window.location.reload();
          //this.messageService.add({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
        }
      },
      {
        icon: 'pi pi-angle-double-down',
        label: 'Scrool bottom',
        command: () => {
          this.scrollToEndOfTable()
          //this.messageService.add({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
        }
      },
      {
        icon: 'pi pi-sort-numeric-down',
        label: 'Got to Row',
        command: () => {
          this.openGoToRowNoDialog()
        }
      },
      {
        icon: 'pi pi-file-excel',
        label: 'Export to Excel',
        command: () => {
          this.downloadTallySummaryInExcel(this.summaryId!);
        }
      },

      {
        icon: 'pi pi-cog',
        label: 'Open Configuration',
        command: () => {
          this.tallysheetSettingsSidebar = true;
        }
      },
      {
        label: 'Goto Last Record',
        icon: 'pi pi-step-forward',
        // visible: this.activeIndex === 1 && this.editModeChecked,  // Check activeIndex dynamically
        command: () => {
          this.focusLastRecordLengthField();
        }
      }
    ];
  }

  activeIndexChange(index: any) {
    if ((this.recordsForm.dirty && !this.recordsForm.pristine) || (this.summaryForm.dirty && !this.summaryForm.pristine)) {
      this.confirmUnsavedChanges(() => {
        setTimeout(() => {
          this.activeIndex = index;
        }, 25);
      });
    } else {
      this.activeIndex = index;
    }
  }


  copyLengthFromPreviousRow(index: number): void {
    // Check if copying is enabled and index is valid
    const isCopyPreviousRowLength = this.summaryForm.get('copyPreviousRowLength')?.value ?? false;
    if (isCopyPreviousRowLength && index > 0) {
      const previousRowLength = this.tallySheetRecordsArray.at(index - 1)?.get('length')?.value;

      // If the previous row has a valid length, set it to the new row
      if (previousRowLength !== undefined) {
        this.tallySheetRecordsArray.at(index)?.patchValue({
          lengths: previousRowLength,
        });
      }
    }
  }

  addValueToCurrentRowLength(index: number): void {
    const currentLength = this.tallySheetRecordsArray.at(index).get('length')?.value;

    // Check if the currentLength exists and is a valid number
    if (currentLength && currentLength < 100) {
      // const updatedLength = currentLength + this.lengthIncreament;
      const updatedLength = currentLength + this.summaryForm.get('lengthIncreament')?.value;

      this.tallySheetRecordsArray.at(index).get('length')?.setValue(updatedLength);
    }
  }

  focusLastRecordLengthField(): void {
    let rowNumber = (this.tallySheetRecordsArray.length) | 0;
    // let rowNumber = rowNumberString;// parseInt(rowNumberString);
    if (rowNumber < 0 || rowNumber > this.tallySheetRecordsArray.length) {
      alert('Invalid row number:' + rowNumber);
      return;
    } else {
      rowNumber -= 1;
    }
    this.focusedRowIndex = rowNumber;
    const rowElement = document.getElementById('row-' + rowNumber);
    if (rowElement) {
      rowElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      if (this.editModeChecked) {
        const lengthInput = rowElement.querySelector('input[formControlName="length"]') as HTMLElement;
        if (lengthInput) {
          lengthInput.focus();
        }
      }
    } else {
      alert('Row not found:' + rowNumber);
    }
    this.displayGoToRowNoInputDialog = false;
    /* const lastIndex = this.tallySheetRecordsArray.length - 1;
    const lastLengthField = this.tallySheetRecordsArray.at(lastIndex)?.get('length');

    if (lastLengthField) {
      const lengthInput = document.querySelector(`input[formcontrolname="length"][formArrayName="tallySheetRecordsArray"]:nth-child(${lastIndex + 1})`);

      if (lengthInput) {
        (lengthInput as HTMLElement).focus(); // Set focus to the last length input field
      }
    } */
  }

  scrollToEndOfTable(): void {
    const tableContainer = document.querySelector('.table-container'); // Adjust this selector to target the correct table container

    if (tableContainer) {
      tableContainer.scrollTop = tableContainer.scrollHeight; // Scroll to the bottom of the table
    }
  }

  // Method to check if the field is in view and scroll if needed
  scrollToField(target: EventTarget | null) {
    if (target instanceof HTMLElement) {
      const field = target as HTMLElement;

      const bounding = field.getBoundingClientRect();

      // Viewport height and width
      let windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const windowWidth = window.innerWidth || document.documentElement.clientWidth;
      if (windowHeight) {
        windowHeight -= 200;
      }

      // Check if the field is out of view
      const isOutOfView = (
        bounding.top < 0 ||             // Above the visible area
        bounding.bottom > windowHeight  // Below the visible area
      );
      //481.5 > 667
      console.log(`windowHeight ${windowHeight} clientHeight ${document.documentElement.clientHeight} 
        bounding.top ${bounding.top} bounding.bottom  ${bounding.bottom} bounding.top {bounding.top < 0}  bounding.bottom > windowHeight${bounding.bottom > windowHeight}`);




      // Scroll only if the field is out of view
      if (isOutOfView) {
        console.log(`isOutOfView`);
        field.scrollIntoView({
          behavior: 'smooth',
          block: 'center',  // Scroll to the center of the viewport for better visibility
          inline: 'center'
        });
      }
    }
  }

  goToRow(rowNumberString: string | '') {
    let rowNumber = parseInt(rowNumberString);
    if (rowNumber < 0 || rowNumber >= this.tallySheetRecordsArray.length) {
      console.error('Invalid row number:', rowNumber);
      return;
    } else {
      rowNumber -= 1;
    }
    this.focusedRowIndex = rowNumber;
    const rowElement = document.getElementById('row-' + rowNumber);
    if (rowElement) {
      rowElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      if (this.editModeChecked) {
        const lengthInput = rowElement.querySelector('input[formControlName="length"]') as HTMLElement;
        if (lengthInput) {
          lengthInput.focus();
        }
      }
    } else {
      alert('Row not found:' + rowNumber);
    }
    this.displayGoToRowNoInputDialog = false;
  }

  displaySummaryIdInputDialog: boolean = false;
  displayGoToRowNoInputDialog: boolean = false;
  rowNo: number | null = null;

  onFieldFocus(index: number, fieldName: string): void {
    console.log(`onFieldFocus ${index}`);
    this.focusedField = { index, fieldName };
    this.focusedRowIndex = index; // Set the focused row
  }

  onFieldBlur(): void {
    this.focusedRowIndex = null; // Reset when blur
    this.focusedField = null; // Clear the focused field when blur occurs
  }

  openSummaryIdInputDialog() {
    this.displaySummaryIdInputDialog = true; // Show the dialog
    // this.summaryId = ''; // Optionally reset the value if needed
  }

  displayGoToRowNo: boolean = false;
  openGoToRowNoDialog() {
    console.log('Row No:', this.rowNo);

    this.displayGoToRowNoInputDialog = true;
    this.rowNo = null; // Reset the row number after the action
  }


  // Call this function before showing the speed dial menu
  onSpeedDialClick() {
    this.initSpeedDialMenuItems();
    // Now show the speed dial menu
  }


  logAccordionHeights() {
    // Query all open accordion tab contents

  }

  onTabOpened(event: any) {
    console.log('Tab opened:', event);
    this.logAccordionHeights();  // Log height when a tab is opened
  }

  onTabClosed(event: any) {
    console.log('Tab closed:', event);
    this.logAccordionHeights();  // Log height when a tab is closed
  }

  updateSummaryType(accepted: boolean, fieldName: string) {
    const summaryTypeSelected = this.summaryForm.get('summaryType')?.value ?? 'PUBLIC';
    if (accepted) {
      this.squareTallysheetService.updateSummaryType(this.summaryId!, summaryTypeSelected).subscribe(
        (response) => {
          this.inplaceSummaryType.deactivate();
          this.summaryForm.get('summaryType')?.markAsPristine();
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Summary Type updated successfully' });
        },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to update Summary Type' });
        }
      );
    } else {
      this.summaryForm.get('summaryType')?.setValue(this.tempSummaryType);
      this.inplaceSummaryType.deactivate();
      this.summaryForm.get('summaryType')?.markAsPristine();
    }
  }

  getBeforeEditSummaryType() {
    const temp = this.summaryForm?.get('summaryType')?.value ?? 0;
    this.tempSummaryType = temp;

  }

  getBeforeEditValueLengthIncreament() {
    const temp = this.summaryForm?.get('lengthIncreament')?.value ?? 0;
    this.tempLengthIncreament = temp;

  }

  checkActiveInplace(currentInplace: Inplace): boolean {
    /*  console.log(`inplacethickness ${this.inplacethickness?.active}`)
     console.log(`inplaceLength ${this.inplaceLength?.active}`)
 
     console.log(`inplaceTypeOfShipment ${this.inplaceTypeOfShipment?.active}`)
 
     const inplaces = [
       this.inplacethickness,
       this.inplaceLength,
       this.inplaceUomLength,
       this.inplaceUomthickness,
       this.inplaceRoundingDirection,
       this.inplaceDecimalPlaces,
       this.inplaceContainerNumber,
       this.inplaceTypeOfShipment,
       this.inplaceCopyPreviousRow,
       this.inplaceLengthIncreament,
       this.inplaceSummaryType
     ];
 
     // Find active Inplace, excluding the one being clicked
     const activeInplaces = inplaces.filter(inplace => {
       console.log(`inplace?.active ${inplace?.active} currentInplace ${currentInplace.active}`);
       return inplace?.active && inplace !== currentInplace
     });
 
     if (activeInplaces.length > 1) {
       // alert('Only one inplace can be open at a time.');
       return true; // Prevent click
     }
  */
    return false; // Allow click
  }

  private getShareUrl(): any {

    this.squareTallysheetService.getShareabelUrl(this.summaryId!).subscribe(
      (success: any) => {
        const url = success.url;
        this._clipboardService.copy(url);
        this.messageService.add({
          severity: 'success',
          summary: 'Link Copied',
          detail: 'The share link has been copied to your clipboard.',
          sticky: false
        });
        return url;
      },
      (error: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Copy Failed',
          detail: 'There was an issue copying the share link to your clipboard. Please try again.',
          sticky: false
        });
      },
      () => { }
    )

  }

  // Assuming you have an array to track the animation state for each row
  public rowAnimationPlayed: boolean[] = [];
  onAnimationEnd(index: number) {
    this.rowAnimationPlayed[index] = true;
  }

  containers: any[] | undefined;

  filterContainerNumbers(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;
    this.containerService.getContainerNumbersApi(query).subscribe(
      (response: any) => {
        console.log(`Container Numbers:`, response);  // Log the response
        filtered = response;
        this.filteredContainerNumbers = filtered;
      },
      (error: any) => {
        console.error('Error fetching container numbers:', error);
      }
    );

    this.filteredContainerNumbers = filtered;
  }

  // Initialize form with resolved summary data

  initializeTallySheetCommonFormNew() {
    this.summaryForm = new FormGroup({
      id: new FormControl(null),
      uomWidth: new FormControl('in', [
        Validators.required,
        Validators.pattern(/^(mm|cm|in|ft|meter)$/),
      ]),
      uomThickness: new FormControl('in', [
        Validators.required,
        Validators.pattern(/^(mm|cm|in|ft|meter)$/),
      ]),
      uomLength: new FormControl('ft', [
        Validators.required,
        Validators.pattern(/^(mm|cm|in|ft|meter)$/),
      ]),
      pieces: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(mm|cm|in|ft|meter)$/),
      ]),
      container: new FormControl(null, [
        Validators.required,
        Validators.minLength(5),
      ]),
      typeOfShipment: new FormControl('Round', [
        Validators.required,
        Validators.pattern(/^(Round|Square)$/),
      ]),
      roundingDirection: new FormControl('Up', [
        Validators.required,
        Validators.pattern(/^(Up|Down)$/),
      ]),
      decimalPlaces: new FormControl(3, [
        Validators.required,
        Validators.min(0),
        Validators.max(6),
      ]),
      totalPieces: new FormControl(0),
      totalNetVolumeCBM: new FormControl(0),
      totalNetVolumeCFT: new FormControl(0),
      isRowModeEnabled: new FormControl<boolean>(true),
      summaryType: new FormControl('PRIVATE', [
        Validators.required,
        Validators.pattern(/^(PUBLIC|PRIVATE|SOLD)$/),
      ]),
    });

    // Populate form fields with the resolved data instead of making a service call
    if (this.resolvedDataTallysheetSummary) {
      const summary = this.resolvedDataTallysheetSummary;
      this.summaryForm.patchValue({
        id: summary.id,
        uomWidth: summary.uomWidth,
        uomThickness: summary.uomThickness,
        uomLength: summary.uomLength,
        container: summary.container,
        typeOfShipment: summary.typeOfShipment,
        roundingDirection: summary.roundingDirection,
        decimalPlaces: summary.decimalPlaces,
        totalPieces: summary.totalPieces,
        totalNetVolumeCBM: summary.totalNetVolumeCBM,
        totalNetVolumeCFT: summary.totalNetVolumeCFT,

        summaryType: summary.summaryType ?? 'PRIVATE',
      });

      // Disable typeOfShipment field as needed
      this.summaryForm.get('typeOfShipment')?.disable();
    } else {
      console.warn("Resolved data for tally sheet summary is unavailable.");
    }
  }


  // Populate form array with resolved existing data

  private getExistingDataNew(): void {
    console.log(`getExistingData 1st this.loading ${this.loading} this.isLoadingMore ${this.isLoadingMore}`);
    console.log(`getExistingData 2nd`);


    this.loading = true;

    if (this.resolvedDataSquareTallysheetRecord) {
      console.log('Records received from resolved data:', JSON.stringify(this.resolvedDataSquareTallysheetRecord));

      // Using data from resolvedDataTallysheetRecord instead of response
      const response = this.resolvedDataSquareTallysheetRecord;

      this.totalPages = response.totalPages;
      this.isLoadingMore = false; // Reset loading state
      this.loading = false;
      this.dataLoaded = true;

      let recordsArray = response.content as SquareTallyRow[];
      console.log('getExistingData:', JSON.stringify(recordsArray));
      console.log(" this.recordsForm?.get('tallySheetRecordsArray')" + this.recordsForm?.get('tallySheetRecordsArray'))
      // this.recordsForm = new FormGroup({
      //   tallySheetRecordsArray: new FormArray([]), // Initialize as FormArray
      //   // ... other form controls if needed
      // });
      const formArray = this.recordsForm?.get('tallySheetRecordsArray') as FormArray;
      console.log("formArray", formArray);
      if (formArray) {
        // Append new records to the existing FormArray
        recordsArray.forEach((record, i) => {
          const formGroup = this.createFormGroup(record);

          // Track changes for each control before adding to the FormArray
          this.trackControlChanges(formGroup.get('width')!, formArray.length);
          this.trackControlChanges(formGroup.get('thickness')!, formArray.length);
          this.trackControlChanges(formGroup.get('lengths')!, formArray.length);
          this.trackControlChanges(formGroup.get('pieces')!, formArray.length);

          // Debounce setup on length and thickness fields before adding to the FormArray
          this.setupDebounceOnDimension(formGroup.get('width')!, formGroup?.get('serialNumber')?.value!);
          this.setupDebounceOnDimension(formGroup.get('thickness')!, formGroup?.get('serialNumber')?.value!);
          this.setupDebounceOnDimension(formGroup.get('lengths')!, formGroup?.get('serialNumber')?.value!);
          this.setupDebounceOnDimension(formGroup.get('pieces')!, formGroup?.get('serialNumber')?.value!);

          // Push the formGroup to the FormArray only after setting up listeners
          formArray.push(formGroup);
        });
      } else {
        // Initialize the form with the first batch of records
        const newFormArray = new FormArray(this.initializeExistingData(recordsArray));
        this.recordsForm = new FormGroup({
          selectedColumnNames: new FormControl([]),
          tallySheetRecordsArray: newFormArray
        });

        // Track changes for each control in the initial set of records
        newFormArray.controls.forEach((formGroup, i) => {
          this.setupDebounceOnDimension(formGroup.get('width')!, formGroup?.get('serialNumber')?.value!);
          this.setupDebounceOnDimension(formGroup.get('thickness')!, formGroup?.get('serialNumber')?.value!);
          this.setupDebounceOnDimension(formGroup.get('lengths')!, formGroup?.get('serialNumber')?.value!);
          this.setupDebounceOnDimension(formGroup.get('pieces')!, formGroup?.get('serialNumber')?.value!);
        });
      }

      this.makeFieldsReadonly();
      this.editModeChecked = false;
      this.hideProgress();
    } else {
      console.error('No resolved data for tally sheet records');
      this.loading = false;
      this.isLoadingMore = false; // Reset loading state
    }
  }

  checkScreenSize() {
    console.log(`window.innerWidth ${window.innerWidth}`)
    this.isMobile = window.innerWidth < 768; // Adjust the breakpoint as needed
  }

  getEditModeLabel(): string {
    return this.editModeChecked ? 'View Mode' : 'Edit Mode';
  }

  attachDebounceToRecords() {
    alert(`attachDebounceToRecords`)
    this.tallySheetRecordsArray.controls.forEach((control) => {
      if (control instanceof FormGroup) {
        const serialNumber = control.get('serialNumber')?.value;

        // Attach debounce logic to each field
        this.setupDebounceOnDimension(control.get('lengths')!, serialNumber);
        this.setupDebounceOnDimension(control.get('thickness')!, serialNumber);
        this.setupDebounceOnDimension(control.get('width')!, serialNumber);
        this.setupDebounceOnDimension(control.get('pieces')!, serialNumber);
      }
    });

  }
  



}
