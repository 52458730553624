import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  FormArray,
  Validators,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { MenuItem, SelectItem } from 'primeng/api';
import {
  BehaviorSubject,
  Observable,
  filter,
  finalize,
  mergeMap,
  of,
} from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';
import { SAWN_ACTION } from '../../sawn-timber/model/sawn-timber/sawn-action-enum';
import { SawnTimberRow } from '../../sawn-timber/model/sawn-timber/sawn-timber-row';
import { SawnTimberService } from '../../sawn-timber/services/sawn-timber.service';
import { fadeAnimation } from 'src/app/commons/fadeAnimation';
import { AddHoppusMeasurementv2Service } from '../services/add-hoppus-measurementv2.service';
import { HoppusTimber } from '../interfaces/hoppus-timber.model';
import { HoppusTimberRow } from '../interfaces/hoppus-timber-row.model';
import { UtilService } from 'src/app/services/util.service';
import { Table } from 'primeng/table';
import * as FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Router } from '@angular/router';
import { MeasurementCanDeactivateGuardServiceService } from '../services/measurement-can-deactivate-guard-service.service';
import { SHARED_MODULES } from '../../shared-imports';

/* 
const containerData1: Container = {
  containerId: 36,
  containerNumber: 'TISS656768-0',
  sealNumber: '',
  productId: new Product(),
  supplierId: new Seller(),
  loadingSiteId: new LoadingSite(),
  photos: [],
  measurements: [],
  containerSummary: new ContainerSummary(),
  fumigation: undefined,
  seaPort: undefined,
  countryOfOrigin: undefined,
  containerType: undefined,
  billOfLading: undefined,
};

const containerData2: any = {
  containerId: 36,
  containerNumber: 'TISS656768-0',
  productFormula: 2,
}; */

@Component({
  selector: 'app-add-hoppus-measurement',
  templateUrl: './add-hoppus-measurement.component.html',
  styleUrls: ['./add-hoppus-measurement.component.css'],
  providers: [ToastMessagesService],
  animations: [fadeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone:true,
  imports:[SHARED_MODULES]
  

})
export class AddHoppusMeasurementComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  sawnTimberResponseObservable!: Observable<any>;

  blockedPanel: boolean = false;
  limit: number = 100; // number of records to fetch per request
  offset: number = 0; // current offset in the dataset
  @ViewChild('scrollable') scrollable!: ElementRef;
  dummyArrayHorizontalGrid = ['Helo'];
  selectedRow!: any;
  isLoading$ = new BehaviorSubject<boolean>(false);
  loading: boolean = true;
  configDialogWidth: string = '60vw';
  @ViewChildren('grossLengthInput') grossLengthInputs!: QueryList<ElementRef>;
  // @ViewChild('dt') dt!: ElementRef;
  @ViewChild('dt') dt!: Table;

  @Input() container: any; // This is the input property that will receive the data from the parent
  // numberRegex = "^\d+\.\d{3}$";
  numberRegex: RegExp = /^\d+\.\d{3}$/;
  public tableForm!: FormGroup;
  isConsoleDialogVisible = false;
  beforeSubmitConsoleContent: any;
  afterSubmitConsoleContent: any;

  showValidationMessage = false; // add this property
  protected sawnTimberSpeedDialItems!: MenuItem[];
  showAutoSaveIntervalDialog = false;
  copyOptionBoolean = true;
  toolbarPopUpMenu!: MenuItem[];
  intervalId!: any;
  private showSaveOptionDivSubject = new BehaviorSubject<boolean>(false);
  showSaveOptionDiv$: Observable<boolean> =
    this.showSaveOptionDivSubject.asObservable();
  hoppusTimberId!: number;
  sawnTimberConfigId!: number;
  isConfigDialogVisible = false;
  unitOptions!: SelectItem[];
  totalPieces = 0;
  totalNetVolumeCbm = 0;
  totalGrossVolumeCbm = 0;
  totalNetVolumeCft = 0;
  totalGrossVolumeCft = 0;
  public removedRows: Array<FormGroup> = [];
  batchSize = 25; // You can adjust this number
  containerSummary: any;
  containerDetails: any;

  // Add these new properties to store the state of the rows, the unique_key and to hold the previous state
  private updatedRows = new BehaviorSubject<Array<SawnTimberRow>>([]);
  private uniqueKey = 0;
  private previousState: any = null;

  @ViewChildren('lengthInput') lengthInputList!: QueryList<ElementRef>;
  @ViewChildren('grossLengthInput')
  grossLengthInputList!: QueryList<ElementRef>; // grossGirthInput
  @ViewChildren('grossGirthInput') grossGirthInput!: QueryList<ElementRef>; // grossGirthInput
  @ViewChildren('widthInput') widthInputList!: QueryList<ElementRef>;
  @ViewChildren('heightInput') heightInputList!: QueryList<ElementRef>;
  @ViewChildren('piecesInput') piecesInputList!: QueryList<ElementRef>;

  public lengthRange: { min: number; max: number } = { min: 1, max: 100 };
  public girthRange: { min: number; max: number } = { min: 1, max: 100 };

  tempRowData!: SawnTimberRow;
  prepopulateConfig: any;
  totalRecords: any;
  private shouldCheckForChanges = true;
  dialogLoadAllData: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private hoppusServiceV2: AddHoppusMeasurementv2Service,
    private toastMessageService: ToastMessagesService,
    private encryptedStorageService: EncryptedStorageService,
    private renderer: Renderer2,
    private router: Router,
    private measurementCanDeactivateService: MeasurementCanDeactivateGuardServiceService,
    private cd: ChangeDetectorRef
  ) {
    this.initConfigDialogWidth();
  }
  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  initConfigDialogWidth() {
    if (window.innerWidth < 768) {
      return (this.configDialogWidth = '70vw');
    } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
      return (this.configDialogWidth = '60vw');
    } else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
      return (this.configDialogWidth = '50vw');
    } else if (window.innerWidth >= 1200) {
      return (this.configDialogWidth = '40vw');
    } else {
      return (this.configDialogWidth = '50vw');
    }
  }

  ngAfterViewInit(): void {
    console.log('this.dt.nativeElement --> ' + this.dt);
    this.implementTableScroll();

    const config = this.tableForm.get('config');
    const duration = (config?.get('autoSaveDuration')?.value ?? 1) * 60000;
    /* this.scrollable.nativeElement.addEventListener(
      'scroll',
      this.scrollCallback.bind(this)
    ); */
  }

  implementTableScroll() {
    const tableElement = this.dt.el.nativeElement;
    const scrollableWrapper = tableElement.querySelector(
      '.p-datatable-wrapper'
    );
    if (scrollableWrapper) {
      console.log('Scrollable wrapper found: ', scrollableWrapper);
      this.renderer.listen(
        scrollableWrapper,
        'scroll',
        this.onScroll.bind(this)
      );
    } else {
      console.error('Scrollable wrapper not found');
    }
  }

  /* onScroll(event: any): void {
    const tolerance = 40;
    const target = event.target;

    const isNearBottom =
      target.scrollTop + target.clientHeight + tolerance >= target.scrollHeight;
    if (isNearBottom) {
      alert(this.offset + "<" + this.totalRecords)
      if (this.offset < this.totalRecords) {
        this.offset += this.limit;
        if (this.rows.length < this.totalRecords) {
          this.loadData().subscribe(
            (data: any) => {},
            (error: any) => {},
            () => {
              // this.offset += this.limit;
            }
          );
        }
      }
    }
  } */

  onScroll(event: any): void {
    const tolerance = 60;
    const target = event.target;

    const isNearBottom =
      target.scrollTop + target.clientHeight + tolerance >= target.scrollHeight;
    if (isNearBottom && !this.loading) {
      // alert(this.offset + "<" + this.totalRecords);
      if (this.offset < this.totalRecords) {
        this.loading = true; // Set loading to true to start loading data
        this.offset += this.limit;
        if (this.rows.length < this.totalRecords) {
          this.loadData().subscribe(
            (data: any) => {
              // Process data
            },
            (error: any) => {
              // Handle error
              this.loading = false; // Reset loading flag in case of error
            },
            () => {
              this.loading = false; // Reset loading flag after data is loaded
            }
          );
        } else {
          this.loading = false; // Reset loading flag if no more data needs to be loaded
        }
      }
    }
  }

  

  ngOnInit(): void {
    this.tableForm = new FormGroup({
      config: new FormGroup({
        enableValidation: new FormControl<boolean>(false),
        id: new FormControl('0'),
        minLength: new FormControl('0'),
        maxLength: new FormControl('0'),
        minGirth: new FormControl('0'),
        maxGirth: new FormControl('0'),
        lengthUnit: new FormControl('cm'),
        girthUnit: new FormControl('cm'),

        lengthAllowance: new FormControl('0'),
        girthAllowance: new FormControl('0'),

        enableCopyLastRowGirth: new FormControl(true),
        validateMultipliesOfFive: new FormControl(true),
        autoSaveDuration: new FormControl('2'),
        enableAutoSave: new FormControl<boolean>(false),
      }),
      rows: new FormArray([]),
      // container:  new FormGroup(this.container)
      container: new FormGroup({}), // Initialize container as an empty FormGroup
      summary: new FormGroup({
        netVolume: new FormControl('0'),
        grossVolume: new FormControl('0'),
        netVolumeCft: new FormControl('0'),
        grossVolumeCft: new FormControl('0'),
        lengthAllowance: new FormControl('0'),
        girthAllowance: new FormControl('0'),
        lengthUnit: new FormControl('cm'),
        girthUnit: new FormControl('cm'),
        pieces: new FormControl('0'),
      }),
      // add more controls if needed
    });

    this.unitOptions = [
      { label: 'mm', value: 'mm' },
      { label: 'cm', value: 'cm' },
      { label: 'in', value: 'in' },
      { label: 'ft', value: 'ft' },
      { label: 'm', value: 'm' },
    ];

    // Add initial rows
    for (let i = 0; i < 1; i++) {}
    this.tableForm
      .get('lengthUnit')
      ?.valueChanges.subscribe(() => this.updateAllRows());
    this.tableForm
      .get('girthUnit')
      ?.valueChanges.subscribe(() => this.updateAllRows());

    // Detect changes in girthAllowance
    this.tableForm
      ?.get('config.girthAllowance')
      ?.valueChanges.subscribe((newValue) => {
        // Add additional logic here as needed
        this.updateAllRows();
      });

    // Detect changes in lengthAllowance
    this.tableForm
      ?.get('config.lengthAllowance')
      ?.valueChanges.subscribe((newValue) => {
        this.updateAllRows();
        // Add additional logic here as needed
      });

    this.tableForm
      ?.get('config.lengthUnit')
      ?.valueChanges.subscribe((newValue) => {
        this.updateAllRows();
        // Add additional logic here as needed
      });

    this.tableForm
      ?.get('config.girthUnit')
      ?.valueChanges.subscribe((newValue) => {
        this.updateAllRows();
        // Add additional logic here as needed
      });

    this.getContainerNumberFromStorage();

    // this.dummyData(5);
    //this.createRow(1);

    this.updateAllRows();
    this.initRange();
    this.onRangeChange();
    this.rows.valueChanges.subscribe(() => this.captureUpdatedState());
    this.rows.valueChanges.subscribe(() => this.updateAllRows());
    this.tableForm.get('config')?.valueChanges.subscribe(() => {
      this.onRangeChange();
    });
    this.initSpeedDial();
    this.measurementCanDeactivateService.setForm(this.tableForm);
  }

  updateAllRows(): void {
    this.totalGrossVolumeCbm = 0;
    this.totalNetVolumeCbm = 0;
    this.totalGrossVolumeCft = 0;
    this.totalNetVolumeCft = 0;

    for (const control of this.rows.controls) {
      const grossLength = control.get('grossLength')?.value ?? 0;
      const grossGirth = control.get('grossGirth')?.value ?? 0;

      const grossVolume = this.calculateVolume(+grossLength, +grossGirth);

      const girthAllowance =
        this.tableForm.get('config.girthAllowance')?.value ?? 0;
      const lengthAllowance =
        this.tableForm.get('config.lengthAllowance')?.value ?? 0;

      let netLength = grossLength - lengthAllowance;
      let netGirth = grossGirth - girthAllowance;

      const netVolume = this.calculateVolume(+netLength, +netGirth);

      const grossVolumeCbm = grossVolume;
      const grossVolumeCft = grossVolumeCbm * 35.315;

      const netVolumeCbm = netVolume;
      const netVolumeCft = netVolume * 35.315;

      this.totalGrossVolumeCbm += grossVolumeCbm;
      this.totalGrossVolumeCft += grossVolumeCft;
      this.totalNetVolumeCbm += netVolumeCbm;
      this.totalNetVolumeCft += netVolumeCft;
      this.totalPieces = this.rows.length;

      (control.get('netGirth') as FormControl).patchValue(netGirth, {
        emitEvent: false,
      });
      (control.get('netLength') as FormControl).patchValue(netLength, {
        emitEvent: false,
      });
      (control.get('grossVolumeCft') as FormControl).patchValue(
        grossVolumeCft.toFixed(3),
        {
          emitEvent: false,
        }
      );

      (control.get('grossVolumeCbm') as FormControl).patchValue(
        grossVolumeCbm.toFixed(3),
        {
          emitEvent: false,
        }
      );
      (control.get('grossVolumeCft') as FormControl).patchValue(
        grossVolumeCft.toFixed(3),
        {
          emitEvent: false,
        }
      );
      (control.get('netVolumeCbm') as FormControl).patchValue(
        netVolumeCbm.toFixed(3),
        {
          emitEvent: false,
        }
      );
      (control.get('netVolumeCft') as FormControl).patchValue(
        netVolumeCft.toFixed(3),
        {
          emitEvent: false,
        }
      );
      (control.get('girthAllowance') as FormControl).patchValue(
        girthAllowance,
        {
          emitEvent: false,
        }
      );
      (control.get('lengthAllowance') as FormControl).patchValue(
        lengthAllowance,
        {
          emitEvent: false,
        }
      );
    }
  }

  prepopulateRow(data: HoppusTimberRow) {
    const row = this.createRow(data);
    // this.rows.push(row);
    return row;
  }

  copyRowValues(index: number) {
    if (index > 0 && this.rows.length > 0) {
      return this.rows.at(index - 1).value;
    }
    return null;
  }

  createRow(data: any | null = null, index?: number) {
    const isCopyEnabled =
      this.tableForm?.get('config.enableCopyLastRowGirth')?.value ?? false;
    const nextIndex = index ?? 0 + 1;
    let serialNumberNew = -1;
    if (!data) {
      if (
        nextIndex >= this.rows.length ||
        nextIndex < 0
        //  &&
        // this.rows.at(index??0).get('serialNumber')?.value
      ) {
        if (index) {
          serialNumberNew =
            this.rows.at(index - 1).get('serialNumber')?.value + 1;
          serialNumberNew = Math.floor(serialNumberNew);
        } else {
          serialNumberNew = 1;
        }
      }
    } else {
      serialNumberNew = data?.serialNumber;
    }

    if (data === null && this.rows.length > 1 && isCopyEnabled) {
      const lastRowValues = this.copyRowValues(this.rows.length);

      if (lastRowValues) {
        data = {
          grossLength: lastRowValues.grossLength,
          action: SAWN_ACTION.ADDED,
        };
      }
    }

    const row = this.formBuilder.group({
      id: [data?.id],
      serialNumber: [
        serialNumberNew || (index !== undefined ? index + 1 : null),
      ],
      grossLength: [data?.grossLength || null, [Validators.required]],
      grossGirth: [data?.grossGirth || null, [Validators.required]],
      netLength: [data?.netLength || null, [Validators.required]],
      netGirth: [data?.netGirth || null, [Validators.required]],
      lengthAllowance: [data?.lengthAllowance || null, [Validators.required]],
      girthAllowance: [data?.girthAllowance || null, [Validators.required]],

      grossVolumeCbm: [{ value: data?.grossVolumeCbm || 0, disabled: false }], // if you still need this
      netVolumeCbm: [{ value: data?.grossVolumeCbm || 0, disabled: false }], // if you still need this

      grossVolumeCft: [{ value: data?.grossVolumeCft || 0, disabled: false }], // if you still need this
      netVolumeCft: [{ value: data?.netVolumeCbm || 0, disabled: false }], // if you still need this

      review: [data?.review || false],
      unique_key: [this.uniqueKey++],
      uuid: [
        {
          value: data?.uuid || this.hoppusServiceV2.generateUUID(),
          disabled: false,
        },
      ],
      action: [
        data ? data.action || SAWN_ACTION.UP_TO_DATE : SAWN_ACTION.ADDED,
      ],
      editMode: [false],
      highlight: [false],
    });

    row.valueChanges.subscribe(() => {
      const volume = this.calculateVolume(
        +(row.get('grossLength')?.value ?? 0),
        +(row.get('grossGirth')?.value ?? 0)
      );

      let cbm = row.get('grossVolumeCbm');
      (cbm as AbstractControl).patchValue(volume.toFixed(3), {
        // Round off here
        emitEvent: false,
      });

      let cftStr = volume * 35.315;
      let cft = row.get('grossVolumeCft');
      (cft as AbstractControl).patchValue(cftStr.toFixed(3), {
        // Round off here
        emitEvent: false,
      });
    });

    row.valueChanges.subscribe(() => this.updateAllRows());

    // Insert the row into the rows FormArray at the specified index or at the end of the array
    if (index !== undefined && index >= 0 && index <= this.rows.length) {
      this.rows.insert(index, row);
    } else {
      this.rows.push(row);
    }
    this.selectTextInLastInput();

    return row;
  }

  createRowFromTable(currentIndex: number | null, nextIndex: number) {
    let newSerialNumber = -1;
    if (nextIndex === this.rows.length) {
      /* newSerialNumber =
        this.getUniqueRandomNumberByIndex(currentIndex || 0, this.rows) ||
        -2222; */
      newSerialNumber =
        this.rows.at(currentIndex ?? 0).get('serialNumber')?.value + 1;
    } else if (currentIndex && nextIndex) {
      newSerialNumber =
        this.getUniqueRandomNumberByIndex(currentIndex, this.rows) || -1111;
    }

    const row = this.formBuilder.group({
      id: null,
      serialNumber: [newSerialNumber],
      grossLength: [null, [Validators.required]],
      grossGirth: [null, [Validators.required]],
      netLength: [null, [Validators.required]],
      netGirth: [null, [Validators.required]],
      lengthAllowance: [null, [Validators.required]],
      girthAllowance: [null, [Validators.required]],

      grossVolumeCbm: [{ value: 0, disabled: false }], // if you still need this
      netVolumeCbm: [{ value: 0, disabled: false }], // if you still need this

      grossVolumeCft: [{ value: 0, disabled: false }], // if you still need this
      netVolumeCft: [{ value: 0, disabled: false }], // if you still need this
      uuid:   [this.hoppusServiceV2.generateUUID()],
      review: [false],
      unique_key: [this.uniqueKey++],
      action: [SAWN_ACTION.ADDED],
      editMode: [false],
      highlight: [false],
    });

    // add subscriptions
    row.valueChanges.subscribe(() => {
      const volume = this.calculateVolume(
        +(row.get('grossLength')?.value ?? 0),
        +(row.get('grossGirth')?.value ?? 0)
      );

      const volumeStr = volume ?? 0;
      let cbm = row.get('grossVolumeCbm');
      (cbm as AbstractControl).patchValue(volumeStr.toFixed(3), {
        emitEvent: false,
      });

      let cftStr = volume * 35.315;
      let cft = row.get('cft');
      (cft as AbstractControl).patchValue(cftStr.toFixed(3), {
        emitEvent: false,
      });
    });

    row.valueChanges.subscribe(() => this.updateAllRows());

    // Insert the row into the rows FormArray at the specified index or at the end of the array
    if (
      nextIndex !== undefined &&
      nextIndex >= 0 &&
      nextIndex <= this.rows.length
    ) {
      this.rows.insert(nextIndex, row);
    } else {
      this.rows.push(row);
    }
    if (nextIndex) {
      setTimeout(() => {
        // this.lengthInputList.toArray()[nextIndex].nativeElement.focus();
        this.grossLengthInputList.toArray()[nextIndex].nativeElement.focus();
      }, 100);
    }

    if (nextIndex + 1 === this.rows.length) {
      setTimeout(() => {
        this.scrollToBottom();
        this.grossLengthInputList.toArray()[nextIndex].nativeElement.focus();
      }, 200);
    }
    // return row;
  }

  calculateVolume(length: number, girth: number): number {
    const lengthUnit = this.tableForm.get('config.lengthUnit')?.value;
    const girthUnit = this.tableForm.get('config.girthUnit')?.value;

    const lengthInMeters = this.convertUnitToMeter(length, lengthUnit);
    const girthInMeters = this.convertUnitToMeter(girth, girthUnit);

    let hoppusVolumeInMeters =
      (girthInMeters * girthInMeters * lengthInMeters) / 16;

    // Note that we're not rounding here, keeping as many decimal places as possible
    return hoppusVolumeInMeters;
  }

  convertUnitToMeter(value: number, unit: string): number {
    let conversionFactor = 1;
    switch (unit) {
      case 'mm':
        conversionFactor = 0.001;
        break;
      case 'cm':
        conversionFactor = 0.01;
        break;
      case 'in':
        conversionFactor = 0.0254;
        break;
      case 'ft':
        conversionFactor = 0.3048;
        break;
      case 'm':
        conversionFactor = 1;
        break;
    }
    return value * conversionFactor;
  }

  printData(): void {
    console.log(this.tableForm?.value);
  }

  rangeValidator(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (
        control.value !== undefined &&
        (isNaN(control.value) || control.value < min || control.value > max)
      ) {
        return { range: true };
      }
      return null;
    };
  }

  processData(): Observable<number> | void {
    return this.loadData();
  }

  loadData(): Observable<number> {
    return new Observable<number>((subscriber) => {
      if (this.offset >= (this.totalRecords ?? 1)) {
        subscriber.next(0);
        subscriber.complete();
        return;
      }

      this.loading = true;
      this.hoppusServiceV2
        .lazyLoadHoppusTimbers(
          this.container.containerId,
          this.offset,
          this.limit
        )
        .pipe(
          mergeMap((response: any) => {
            this.totalRecords = response.totalRecords;
            let rowsFetched: HoppusTimberRow[] = response.data;

            rowsFetched.forEach((eachRow) => {
              this.prepopulateRow({
                // Your existing object structure
                id: eachRow.id,
                serialNumber: eachRow.serialNumber,
                grossLength: eachRow.grossLength,
                netLength: eachRow.netLength,
                grossGirth: eachRow.grossGirth,
                netGirth: eachRow.netGirth,
                lengthAllowance: eachRow.lengthAllowance,
                girthAllowance: eachRow.girthAllowance,
                grossVolumeCbm: eachRow.grossVolumeCbm,
                netVolumeCbm: eachRow.netVolumeCbm,
                grossVolumeCft: eachRow.grossVolumeCft,
                netVolumeCft: eachRow.netVolumeCft,
                totalPieces: eachRow.totalPieces,
                review: eachRow.review,
                uniqueKey: eachRow.uniqueKey,
                action: SAWN_ACTION.UP_TO_DATE,
                editMode: false,
                hoppusTimber: eachRow.hoppusTimber,
                uuid:  eachRow.uuid ?? this.hoppusServiceV2.generateUUID(),
              });
            });

            if (rowsFetched.length < 1) {
              subscriber.next(0);
              subscriber.complete();
            }

            return of(response); // You can replace this with a relevant Observable if needed
          })
        )
        .subscribe(
          () => {
            this.loading = false;
          },
          (error: any) => {
            console.error(`Error: ${JSON.stringify(error)}`);
            this.loading = false;
            subscriber.error(error);
            this.createRow(1);
            this.cd.markForCheck(); // Manually trigger change detection
            subscriber.complete();
          },
          () => {
            this.cd.markForCheck(); // Manually trigger change detection

            subscriber.complete();
          }
        );
    });
  }

  loadDataOLD(): Observable<number> {
    if (this.offset < (this.totalRecords ?? 1)) {
      return new Observable<number>((subscriber) => {
        // this.isLoading$.next(true);
        this.loading = true;
        this.hoppusServiceV2
          .lazyLoadHoppusTimbers(
            this.container.containerId,
            this.offset,
            this.limit
          )
          .subscribe(
            (response: any) => {
              console.log(`response --> ${JSON.stringify(response.config)}`);
              let rowsFetched: HoppusTimberRow[] = response.data;

              this.totalRecords = response.totalRecords;

              rowsFetched.forEach((eachRow) => {
                this.prepopulateRow({
                  // Your existing object structure
                  id: eachRow.id,
                  serialNumber: eachRow.serialNumber,
                  grossLength: eachRow.grossLength,
                  netLength: eachRow.netLength,
                  grossGirth: eachRow.grossGirth,
                  netGirth: eachRow.netGirth,
                  lengthAllowance: eachRow.lengthAllowance,
                  girthAllowance: eachRow.girthAllowance,
                  grossVolumeCbm: eachRow.grossVolumeCbm,
                  netVolumeCbm: eachRow.netVolumeCbm,
                  grossVolumeCft: eachRow.grossVolumeCft,
                  netVolumeCft: eachRow.netVolumeCft,
                  totalPieces: eachRow.totalPieces,
                  review: eachRow.review,
                  uniqueKey: eachRow.uniqueKey,
                  action: SAWN_ACTION.UP_TO_DATE,
                  editMode: false,
                  hoppusTimber: eachRow.hoppusTimber,
                  uuid: eachRow.uuid ?? this.hoppusServiceV2.generateUUID(),
                });
              });

              if (rowsFetched.length < 1) {
                // this.createRow(1);
                subscriber.next(0);
                subscriber.complete();
              }
            },
            (error: any) => {
              // Existing error handling logic
              console.error(`Response is ${JSON.stringify(error)}`);
              if (error.status === 404) {
                console.error('HTTP 500 Error: ', error);
                this.createRow(1);
                subscriber.next(0);
                subscriber.complete();
                // this.isLoading$.next(false);
                this.loading = false;
              }
              subscriber.error(error);
            },
            () => {
              // this.isLoading$.next(false);
              this.loading = false;
            }
          );
      });
    } else {
      return of(0);
    }
  }

  loadConfig() {
    this.hoppusServiceV2
      .getHoppusTimbersConfigContainerId(this.container.containerId)
      .subscribe(
        (data: any) => {
          this.prepopulateConfig = data.config;
          this.tableForm.get('config')?.patchValue(this.prepopulateConfig);
        },
        (error: any) => {},
        () => {}
      );
  }

  // Function to process rows in batches

  /* dummyData(rows: number) {
    //3
    for (let i = 0; i < rows; i++) {
      const row = this.prepopulateRow({
        serialNumber: i + 1,
        length: Math.floor(Math.random() * 100) + 1,
        width: Math.floor(Math.random() * 100) + 1,
        height: Math.floor(Math.random() * 100) + 1,
        pieces: Math.floor(Math.random() * 10) + 1,
        review: false,
        action: SAWN_ACTION.UP_TO_DATE,
        highlight: false,
      });
    }
  } */

  removeRow(index: number): void {
    // Copy the row to removedRows array
    this.rows.at(index).patchValue({ action: SAWN_ACTION.REMOVED });
    this.removedRows.push(this.rows.at(index) as FormGroup);
    this.rows.at(index).patchValue({ highlight: true, emitEvent: false });
    setTimeout(() => {
      this.rows.at(index).patchValue({ highlight: false, emitEvent: false });
      // Remove the row from the form array
      this.rows.removeAt(index);

      this.updateAllRows();
      this.tableForm.markAsDirty();

      // Update serial numbers
      for (let i = index; i < this.rows.length; i++) {
        const row = this.rows.at(i);
        // row.get('serialNumber')?.setValue(i + 1);
      }

      this.getFilteredRows();
    }, 50);
  }
  loadingReviewRows: { [key: number]: boolean } = {};

  onReviewChange(event: any, row: any) {
    const originalReview = row.get('review').value;

    this.loadingReviewRows[row.get('id').value] = true; // Start loading

    setTimeout(() => {
      const checked = (event.target as HTMLInputElement)?.checked;
      // row.get('review')?.setValue(checked);
      if (SAWN_ACTION.UP_TO_DATE === row.get('action')?.value) {
        // write service here ....
        const rowId = row.get('id').value;
        const review = row.get('review').value ?? false;
        this.hoppusServiceV2.updateReview(rowId, review).subscribe(
          (response: any) => {},
          (error: any) => {
            row.get('review').setValue(originalReview); // Revert to original state
          },
          () => {
            this.loadingReviewRows[rowId] = false; // Stop loading
          }
        );
      } else {
        this.loadingReviewRows[row.get('id').value] = false; // Stop loading
      }
      this.printData();
    }, 0);
  }

  onRangeChange() {
    this.lengthRange.min = this.tableForm?.get('config.minLength')?.value ?? 0;
    this.lengthRange.max = this.tableForm?.get('config.maxLength')?.value ?? 0;

    this.girthRange.min = this.tableForm?.get('config.minGirth')?.value ?? 0;
    this.girthRange.max = this.tableForm?.get('config.maxGirth')?.value ?? 0;
  }

  moveFocus(
    currentField: any,
    nextField: any,
    index: number,
    event: any,
    addNewRow = false
  ) {
    event.preventDefault();

    if (!currentField.value) {
      return;
    }

    if (addNewRow) {
      if (!index) {
        index = 0;
      }
      let currentIndex = index + 1;
      this.createRow(null, index + 1);
      setTimeout(() => {});
      setTimeout(() => {
        if (currentIndex + 1 === this.rows.length) {
          this.scrollToBottom();
        }
      }, 200);
    } else {
      nextField.focus();
    }
  }

  navigateUpDown(
    event: Event,
    direction: number,
    fieldName: string,
    currentIndex: number
  ) {
    event.preventDefault(); // to stop default behaviour

    let inputList: QueryList<ElementRef>;
    switch (fieldName) {
      case 'length':
        inputList = this.grossLengthInputList;
        break;
      case 'girth':
        inputList = this.grossGirthInput;
        break;
      case 'height':
        inputList = this.heightInputList;
        break;
      case 'pieces':
        inputList = this.piecesInputList;
        break;
      default:
        return;
    }

    this.moveVertical(inputList, currentIndex, direction); // direction is used for moving up or down
  }
  moveVertical(
    inputList: QueryList<ElementRef>,
    currentIndex: number,
    direction: number
  ) {
    const targetIndex = currentIndex + direction;

    if (targetIndex >= 0 && targetIndex < inputList.length) {
      inputList.toArray()[targetIndex].nativeElement.focus();
    }
  }

  initRange() {
    this.lengthRange.min = Number(this.tableForm?.get('minLength')?.value ?? 0);
    this.lengthRange.max = Number(this.tableForm?.get('maxLength')?.value ?? 0);

    this.girthRange.min = Number(this.tableForm?.get('minGirth')?.value ?? 0);
    this.girthRange.max = Number(this.tableForm?.get('maxGirth')?.value ?? 0);
  }

  startEditRow(index: number) {
    if (this.rows.at(index).get('action')?.value === SAWN_ACTION.UP_TO_DATE) {
      this.tempRowData = { ...this.rows.at(index).value };
    }
  }

  finishEditRow(index: number) {
    if (
      this.rows.at(index).get('action')?.value === SAWN_ACTION.UP_TO_DATE &&
      JSON.stringify(this.tempRowData) !==
        JSON.stringify(this.rows.at(index).value)
    ) {
      this.rows.at(index).get('action')?.setValue(SAWN_ACTION.EDITED);
    }
  }

  persistChanges() {
    // Here, you can handle the database operations as per the action keys
    // The database operations are not shown here as they would depend on your specific backend architecture
    this.getUpdatedRows().subscribe((rows: Array<SawnTimberRow>) => {
      rows.forEach((row) => {
        switch (row.action) {
          case SAWN_ACTION.ADDED:
            // Add the row to the database
            break;
          case SAWN_ACTION.EDITED:
            // Update the row in the database
            break;
          case SAWN_ACTION.REMOVED:
            // Remove the row from the database
            break;
          default:
            // Invalid action
            break;
        }
      });
    });
  }

  captureUpdatedState() {
    const currentState = this.rows.value;
    this.updatedRows.next(currentState);
  }

  getUpdatedRows() {
    return this.updatedRows.asObservable();
  }

  getFilteredRows() {
    return this.rows.controls.filter(
      (row: any) => row.get('action')?.value !== SAWN_ACTION.REMOVED
    );
  }
  setFocusOnElement0(index: number) {
    // Wait for QueryList to update
    setTimeout(() => {
      const elements = this.heightInputList.toArray();
      if (elements.length > index) {
        // Set focus
        elements[index].nativeElement.focus();
      }
    });
  }

  setFocusOnElement(index: number, element: HTMLInputElement) {
    // Wait for QueryList to update
    setTimeout(() => {
      element.focus();
    });
  }

  printRemovedRows(): void {
    this.removedRows.forEach((row, index) => {
      console.log(`Removed row ${index}:`, row.value);
    });
  }
  mergeRemovedRows(): void {
    this.removedRows.forEach((row, index) => {
      if (row.value.id !== null) {
        this.rows.push(row);
        console.table(row.value);
      }
    });
  }
  async onSubmit1() {
    let rowsLength = this.rows.length;
    let offset = this.offset;

    this.printData();
  }

  async onSubmit(isRequestFromAutoSave: boolean = false) {
    await this.loadDataAtOnce().toPromise();
    if (!this.tableForm.dirty) {
      this.toastMessageService.showInfoMessage(
        'Nothing to Save',
        'Everything is already up-to-date.'
      );
      return;
    }
    // Load data first

    // this.toastMessageService.showInfoMessage('Do not refresh...', 'Save Process has Begun',true);
    this.toastMessageService.showInfoMessage(
      'Do not refresh the page',
      'Saving in progress. Please wait.',
      true
    );

    if (!isRequestFromAutoSave) {
      this.removeEmptyRows(this.rows);
      if (this.isFormValid()) {
        this.mergeRemovedRows(); // add removed rows from removedRows Array
        this.hoppusServiceV2
          .getHoppusTimberId(this.container.containerId)
          .pipe(
            finalize(() => {
              this.createOrUpdateTimberRow();
            })
          )
          .subscribe(
            (hoppusTimberId: number) => {
              this.hoppusTimberId = hoppusTimberId;
            },
            (error: HttpErrorResponse) => {
              console.error(`error  is ${JSON.stringify(error)}`);
            },
            () => {
              this.toastMessageService.clearMessage();
            }
          );
      } else {
        this.blockedPanel = false;

        this.toastMessageService.showWarningMessage(
          'Incomplete Form',
          'Please enter all the required fields or delete any row that is not required.'
        );
      }
    } else {
      this.mergeRemovedRows(); // add removed rows from removedRows Array
      this.hoppusServiceV2
        .getHoppusTimberId(this.container.containerId)
        .pipe(
          finalize(() => {
            this.createOrUpdateTimberRow(isRequestFromAutoSave);
          })
        )
        .subscribe(
          (hoppusTimberId: number) => {
            this.hoppusTimberId = hoppusTimberId;
          },
          (error: HttpErrorResponse) => {
            console.error(`error  is ${JSON.stringify(error)}`);
          },
          () => {
            this.toastMessageService.clearMessage();
          }
        );
    }
  }

  syncAfterSaveOrUpdate() {
    this.removeAllRemovedRows(this.rows);
    this.rows.controls.forEach((control: AbstractControl) => {
      const group = control as FormGroup;
      let action = group.get('action')?.value;
      console.log(`@@@@@@@@@@ ${group.get('action')?.value}`);
      if (action === 'ADDED') {
        group.get('action')?.setValue(SAWN_ACTION.UP_TO_DATE);
      }
    });
  }

  /* highlight1(index: number) {
  let row = this.rows.at(index);
  let count =0;
  for (let i = 0; i < this.rows.controls.length; i++) {
    if (i === index && count===0) {
      this.rows.at(i).patchValue({ highlight: true, emitEvent: false });
       
      count++;
      break;
    }
  }
  this.changeDetector.detectChanges();
} */

  get rows(): FormArray {
    return this.tableForm?.get('rows') as FormArray;
  }
  initValidations() {
    const configValues = this.tableForm.get('config')?.value;

    if (this.prepopulateConfig && this.prepopulateConfig.enableValidation) {
      //   this.applyRangeValidators(configValues);
      this.resetValidatorsToRequiredOnly();
    } else {
      // if validations are disabled or not defined remove range
      this.resetValidatorsToRequiredOnly();
    }
  }

  applyRangeValidators(configValues: any) {
    const rowsArray = this.rows.controls;

    rowsArray.forEach((rowControl: AbstractControl) => {
      const row = rowControl as FormGroup;
      for (let controlName in row.controls) {
        const controller = row.controls;
        if (['length', 'width', 'height', 'pieces'].includes(controlName)) {
          let control = row.controls[controlName];
          const currentValidators = control.validator
            ? [control.validator]
            : [];
          control.setValidators([Validators.required]);
          control.setValidators([
            ...currentValidators,
            this.rangeValidator(
              configValues[
                `min${
                  controlName.charAt(0).toUpperCase() + controlName.slice(1)
                }`
              ],
              configValues[
                `max${
                  controlName.charAt(0).toUpperCase() + controlName.slice(1)
                }`
              ]
            ),
          ]);
          control.updateValueAndValidity();
        }
      }
    });
  }

  async onDialogClose() {
    // Access the 'config' form group

    const configGroup = this.tableForm.get('config') as FormGroup;
    // CHECK IF VALIDATION IS ENABLED
    if (configGroup?.get('enableValidation')?.value) {
      // DETERMINE IF THE FORM IS VALID
      const isValid = configGroup.valid;

      // IF FORM IS NOT VALID
      if (!isValid) {
        // Show the validation message
        this.showValidationMessage = true;

        // Iterate over each control in the form group
        Object.keys(configGroup.controls).forEach((key) => {
          // Access the errors of the current control
          const controlErrors: ValidationErrors | null | undefined =
            configGroup.get(key)?.errors;

          // If there are errors
          if (controlErrors) {
            // Log each error
            Object.keys(controlErrors).forEach((keyError) => {});
          }
        });

        // Return early since the form is not valid
        // PREVENTS THE DIALOG FROM CLOSING IF THERE ARE ERRORS IN THE FORM
        return;
      }
    }

    // AT THIS POINT, EITHER VALIDATION IS DISABLED OR THE FORM IS VALID

    // Reset form controls
    // this.resetConfigFormControls();

    // Update range values
    this.onRangeChange();

    // Access the raw form values
    const config = configGroup?.getRawValue();

    // CHECK IF SAWN TIMBER ID IS NOT SET

    if (!this.hoppusTimberId && this.tableForm.get('config')?.dirty) {
      // IF SAWN TIMBER ID IS NOT SET, CREATE NEW SAWN TIMBER CONFIG
      this.blockedPanel = true;

      this.hoppusServiceV2.createHoppusTimberConfig(config).subscribe(
        async (response: any) => {
          console.log(`Respone of Config saved  ${JSON.stringify(response)}`);
          this.hoppusTimberId = response.id;
          this.sawnTimberConfigId = response.config.id;
          console.log(`this.sawnTimberId on response ${this.hoppusTimberId}`);
          console.log(
            `this.sawnTimberConfigId on response ${this.sawnTimberConfigId}`
          );
          this.toastMessageService.showSuccessMessage(
            'Saved',
            'Configuration Saved Successfully'
          );
          // CLOSE DIALOG AFTER SUCCESSFUL SAVE
          this.rows.controls.forEach((control: AbstractControl) => {
            (control as FormGroup).value['action'] = SAWN_ACTION.EDITED;
          });
          await this.onSubmit();
          this.isConfigDialogVisible = false;
          setTimeout(() => {
            // The code here will run after 5 seconds
            // You can perform actions here that should occur after the delay
            this.blockedPanel = false;
          }, 1000);
        },
        (error: any) => {
          console.log(
            `Respone of Config saved error  ${JSON.stringify(error)}`
          );
          this.blockedPanel = false;
        }
      );
    } else {
      // IF SAWN TIMBER ID IS SET
      // IF SAWN TIMBER ID IS SET, UPDATE EXISTING SAWN TIMBER CONFIG
      if (this.tableForm.get('config')?.dirty) {
        this.blockedPanel = true;
        this.hoppusServiceV2
          .updateHoppusTimberConfig(this.hoppusTimberId, config)
          .subscribe(
            (response: any) => {
              console.log(
                `Respone of Config Update  ${JSON.stringify(response)}`
              );
              this.sawnTimberConfigId = response.id; //because returing config object
              console.log(
                `this.sawnTimberId on response ${this.hoppusTimberId}`
              );
              console.log(
                `this.sawnTimberConfigId on response ${this.hoppusTimberId}`
              );
              // CLOSE DIALOG AFTER SUCCESSFUL UPDATE
              this.isConfigDialogVisible = false;
              this.toastMessageService.showInfoMessage(
                'Updated',
                'Configuration updated Successfully'
              );
              setTimeout(() => {
                // The code here will run after 5 seconds
                // You can perform actions here that should occur after the delay
                this.blockedPanel = false;
              }, 1000);
            },
            (error: any) => {
              this.blockedPanel = false;
              console.log(
                `Respone of Config Update error  ${JSON.stringify(error)}`
              );
            }
          );
      }
      this.isConfigDialogVisible = false;
    }

    // IF VALIDATION IS DISABLED AND FORM IS EMPTY (NO SAWN TIMBER ID SET), WE CLOSE THE DIALOG
    if (!configGroup?.get('enableValidation')?.value && !this.hoppusTimberId) {
      this.isConfigDialogVisible = false;
    }
  }

  resetConfigFormControls() {
    const configGroup = this.tableForm.get('config');
    [
      'id',
      'lengthUnit',
      'girthUnit',
      'minGirth',
      'maxGirth',
      'minLength',
      'maxLength',
    ].forEach((field) => {
      configGroup?.get(field)?.setValue(0);
    });
    // this.resetConfigValidatorsToRequiredOnly();
  }

  resetConfigValidatorsToRequiredOnly() {
    // Access the 'config' form group
    const configGroup = this.tableForm.get('config') as FormGroup;

    // If the 'config' form group exists
    if (configGroup) {
      // List of fields to reset
      const fields = [
        'lengthUnit',
        'girthUnit',
        'minGirth',
        'maxGirth',
        'minLength',
        'maxLength',
      ];

      // Iterate over each field
      fields.forEach((field) => {
        // Access the current field control
        const control = configGroup.get(field);

        // If the control exists
        if (control) {
          // Reset the control's validators to Validators.required only
          control.setValidators([Validators.required]);

          // Update the control to reflect the new validator
          control.updateValueAndValidity();
        }
      });
    }
  }

  resetValidatorsToRequiredOnly() {
    const rowsArray = this.rows.controls;
    rowsArray.forEach((rowControl: AbstractControl) => {
      const row = rowControl as FormGroup;
      ['length', 'width', 'height', 'pieces'].forEach((field) => {
        const control = row.get(field);
        if (control) {
          control.setValidators([Validators.required]);
          control.updateValueAndValidity();
        }
      });
    });
  }

  warnIfOutOfRange() {
    const rowsControl = this.tableForm?.get('rows') as FormArray;

    rowsControl.controls.forEach((group: AbstractControl, index: number) => {
      const lengthControl = group.get('length');
      const widthControl = group.get('width');
      const heightControl = group.get('height');
      const piecesControl = group.get('pieces');

      // Check if the form control values are out of the specified range
      // If they are, log a warning message
      if (
        lengthControl &&
        (lengthControl.value < this.lengthRange.min ||
          lengthControl.value > this.lengthRange.max)
      ) {
        console.warn(
          `Warning: Length value is out of range in row ${index + 1}.`
        );
      }

      if (
        widthControl &&
        (widthControl.value < this.girthRange.min ||
          widthControl.value > this.girthRange.max)
      ) {
        console.warn(
          `Warning: Width value is out of range in row ${index + 1}.`
        );
      }
    });
  }

  /* isOutOfRange(row: AbstractControl, field: string): boolean {
    const control = row.get(field);

    const rangeMap: { [key: string]: { min: number; max: number } } = {
      length: this.lengthRange,
      girth: this.girthRange,
    };
    const configGroup = this.tableForm.get('config');
    const enableValidation = configGroup?.get('enableValidation')?.value;
    if (!enableValidation) {
      return false;
    } 
    
    if (rangeMap[field].min === 0 && rangeMap[field].max === 0) {
      return false;
    }

    return control
      ? control.value < rangeMap[field].min ||
          control.value > rangeMap[field].max
      : false;
  } */

  /* isOutOfRange(row: AbstractControl, field: string): boolean {
    const configGroup = this.tableForm.get('config');
    const enableValidation = configGroup?.get('enableValidation')?.value;
    if (!enableValidation) {
      return false;
    } else {
      const control = row.get(field);
      const grossGirthEntered = control?.get('grossGirth')?.value;
      const grossLengthEntered = control?.get('grossLength')?.value;

      
      const minGirthValidation = configGroup?.get('minGirth')?.value;
      const maxGirthValidation = configGroup?.get('maxGirth')?.value;
      const minLengthValidation = configGroup?.get('minLength')?.value;
      const maxLengthValidation = configGroup?.get('maxLength')?.value;

      
    }

    return true;
  } */

  isOutOfRange(
    row: AbstractControl,
    field: string,
    type: 'girth' | 'length'
  ): boolean {
    const configGroup = this.tableForm.get('config');
    const enableValidation = configGroup?.get('enableValidation')?.value;

    if (!enableValidation) {
      return false;
    } else {
      const enteredValue = row?.get(field)?.value;
      let minValidation: number, maxValidation: number;

      if (type === 'girth') {
        minValidation = configGroup?.get('minGirth')?.value;
        maxValidation = configGroup?.get('maxGirth')?.value;
      } else if (type === 'length') {
        minValidation = configGroup?.get('minLength')?.value;
        maxValidation = configGroup?.get('maxLength')?.value;
      } else {
        console.error('Invalid type specified');
        return false;
      }

      if (enteredValue < minValidation || enteredValue > maxValidation) {
        return true;
      }
    }

    // If everything is in range
    return false;
  }

  isValid(row: AbstractControl, field: string): boolean {
    const control = row.get(field);
    const rangeMap: { [key: string]: { min: number; max: number } } = {
      length: this.lengthRange,
      width: this.girthRange,
    };
    return control
      ? control.value >= rangeMap[field].min &&
          control.value <= rangeMap[field].max
      : false;
  }

  onToggleChange(value: any): void {
    if (value) {
      // toggle button is enabled, do something here
      this.resetConfigValidatorsToRequiredOnly();
    } else {
      // toggle button is disabled, do something here
      this.resetConfigFormControls();
      this.showValidationMessage = false;
    }
  }

  /* @HostListener('window:keydown.meta.shift.s', ['$event'])
  handleShortcut(event: KeyboardEvent) {
    this.onSubmit();
  } */
  /* 
  @HostListener('window:keydown', ['$event'])
handleShortcut2(event: KeyboardEvent) {
  // this.onSubmit();
  
} */

  @HostListener('window:keydown', ['$event'])
  handleShortcut3(event: KeyboardEvent) {
    if (
      (event.altKey && event.code === 'KeyS') ||
      (event.code === 'AltLeft' && event.key === 's') ||
      (event.code === 'AltRight' && event.key === 's')
    ) {
      this.onSubmit();
    }
  }

  initSpeedDial() {
    this.sawnTimberSpeedDialItems = [
      {
        icon: 'pi pi-cloud-upload', //pi-disk-o
        command: () => {
          this.onSubmit();
        },
        tooltipOptions: {
          tooltipLabel: 'Save',
          tooltipPosition: 'left',
        },
      },
      {
        icon: 'pi pi-cog',
        command: () => {
          if (this.tableForm.dirty) {
            this.toastMessageService.showErrorMessage(
              'Unsaved Changes Detected',
              'Please save unsaved records before proceeding.'
            );
            return;
          }
          this.isConfigDialogVisible = true;
        },
        tooltipOptions: {
          tooltipLabel: 'Open Configuration',
          tooltipPosition: 'left',
        },
      },
      {
        icon: 'pi pi-file-excel',
        command: () => {
          if (this.tableForm.dirty) {
            this.toastMessageService.showErrorMessage(
              'Unsaved Changes Detected',
              'Please save unsaved records before proceeding.'
            );
            return;
          }
          this.exportExcel();
        },
        tooltipOptions: {
          tooltipLabel: 'Export to Excel',
          tooltipPosition: 'left',
        },
      },
      {
        icon: 'pi pi-file-pdf',
        command: () => {
          if (this.tableForm.dirty) {
            this.toastMessageService.showErrorMessage(
              'Unsaved Changes Detected',
              'Please save unsaved records before proceeding.'
            );
            return;
          }
          this.exportPdf();
        },
        tooltipOptions: {
          tooltipLabel: 'Export to PDF',
          tooltipPosition: 'left',
        },
      },
    ];
  }

  changeSaveOption() {
    const config = this.tableForm.get('config');
    config
      ?.get('enableAutoSave')
      ?.setValue(!config?.get('enableAutoSave')?.value);

    if (config?.get('enableAutoSave')?.value) {
      this.updateAutoSave();
      this.showSaveOptionDivSubject.next(true); // or false
    } else {
      this.stopAutoSave();
      this.showSaveOptionDivSubject.next(false); // or false
    }
  }
  updateAutoSave() {
    this.toastMessageService.clearMessage();
    const config = this.tableForm.get('config');
    if (config?.get('enableAutoSave')?.value) {
      const durationInMinutes = config?.get('autoSaveDuration')?.value ?? 1;
      const durationInMilliseconds =
        (config?.get('autoSaveDuration')?.value ?? 1) * 60000;

      // const intervalMinutes = this.saveInterval;
      this.toastMessageService.showInfoMessage(
        'Auto-Save Activation',
        `Auto-save has been activated and is set to save your progress every ${durationInMinutes} minutes.`
      );
      this.intervalId = setInterval(() => {
        if (config?.get('enableAutoSave')?.value) {
          this.onSubmit(true);
        }
      }, durationInMilliseconds);
    }
  }
  stopAutoSave() {
    this.toastMessageService.showInfoMessage(
      'Auto-Save Deactivation',
      'Auto-save has been deactivated. Your progress will no longer be automatically saved.'
    );
    clearInterval(this.intervalId);
  }

  onSpeedDialItemClick(event: Event) {
    event.preventDefault();
  }

  getContainerNumberFromStorage() {
    this.encryptedStorageService
      .getEncryptedDataFromStorage(AppConstants.CONTAINER)
      .subscribe(
        (data) => {
          // Transform the data into a format that FormGroup understands
          this.container = data; // containerData1;
          const containerData = Object.keys(data).reduce(
            (acc: { [key: string]: AbstractControl }, key) => {
              acc[key] = new FormControl(data[key]);
              return acc;
            },
            {}
          );

          // Now set the transformed data to the container FormGroup
          this.tableForm.setControl('container', new FormGroup(containerData));
          if (this.container && this.container.containerId) {
            this.loadConfig();
            // this.getSummary();
            this.getContainerDetails();
            this.loadData().subscribe(
              (data) => {},
              (error) => {},
              () => {
                if (this.container && this.container.containerId) {
                  //this.processData();
                } else {
                  this.createRow(1);
                  this.container = data; // containerData1;
                }
              }
            );
          }
        },
        (error) => {
          console.error('Failed to decrypt data:', error);
          // this.createRow(1);
          //this.staticContainerObjectForTest();
        }
      );
  }

  payload!: HoppusTimber;

  /*  private staticContainerObjectForTest() {
    this.container = containerData2;

    // Transform the data into a format that FormGroup understands
    this.container = containerData2;
    const containerData = Object.keys(containerData2).reduce(
      (acc: { [key: string]: AbstractControl; }, key) => {
        acc[key] = new FormControl(containerData2[key]);
        return acc;
      },
      {}
    );

    // Now set the transformed data to the container FormGroup
    this.tableForm.setControl('container', new FormGroup(containerData));
    if (this.container && this.container.containerId) {
      this.loadData().subscribe(
        (data) => { },
        (error) => { },
        () => {
          if (this.container && this.container.containerId) {
            this.processData();
          } else {
            this.createRow(1);
            this.container = containerData2;
          }
        }
      );
    }
  }
 */
  /* private async createOrUpdateTimberRow(isRequestFromAutoSave: boolean = false): Promise<void> {
    console.log(`createOrUpdateTimberRow`);
    // this.payload = this.tableForm.value;
    this.payload = JSON.parse(JSON.stringify(this.tableForm.value));
    // this.payload.container = containerData1;

    for (var i = 0; i < this.tableForm.value.rows.length; i++) {
     
      this.payload.rows[i].grossGirth =
        +this.tableForm.value.rows[i].grossGirth;
      this.payload.rows[i].grossLength =
        +this.tableForm.value.rows[i].grossLength;
      this.payload.rows[i].netLength = +this.tableForm.value.rows[i].netLength;
      this.payload.rows[i].netGirth = +this.tableForm.value.rows[i].netGirth;
      this.payload.rows[i].grossVolumeCbm =
        +this.tableForm.value.rows[i].grossVolumeCbm;
      this.payload.rows[i].netVolumeCbm =
        +this.tableForm.value.rows[i].netVolumeCbm;
      this.payload.rows[i].grossVolumeCft =
        +this.tableForm.value.rows[i].grossVolumeCft;
      this.payload.rows[i].netVolumeCft =
        +this.tableForm.value.rows[i].netVolumeCft;
      this.payload.rows[i].review = this.tableForm.value.rows[i].review;
      this.payload.rows[i].uniqueKey = +this.tableForm.value.rows[i].unique_key;
      this.payload.rows[i].action = this.tableForm.value.rows[i].action;
      this.payload.rows[i].editMode = this.tableForm.value.rows[i].editMode;
      // this.payload.rows[i].highlight = this.tableForm.value.rows[i].highlight;

      if (
        !this.payload.rows[i].grossLength ||
        !this.payload.rows[i].grossGirth
      ) {
        this.toastMessageService.showWarningMessage(
          'Incomplete Form',
          'Please enter all the required fields or delete any row that is not required.'
        );
        return;
      }
    }

    if (!this.isFormValid()) {
      this.toastMessageService.showWarningMessage(
        'Incomplete Form',
        'Please enter all the required fields or delete any row that is not required.'
      );
      return;
    }

    let sawnTimberObservable!: Observable<any>;

    //  this.createHoppusTimberSummary(this.payload.rows);
    await this.createHoppusTimberSummary(this.payload.rows);

    const values2 = JSON.stringify(this.tableForm.get('summary')?.value);
    this.payload = this.tableForm.value; // get the updated form value
    if (this.hoppusTimberId) {
      sawnTimberObservable = this.hoppusServiceV2.updateHoppusTimber(
        this.payload,
        this.hoppusTimberId
      );
    } else {
      sawnTimberObservable = this.hoppusServiceV2.createHoppusTimber(
        this.payload
      );
    }

    sawnTimberObservable.subscribe(
      (response: any) => {
        this.toastMessageService.clearMessage();
        this.handleSuccessfulResponse(response);
      },
      (error: any) => {
        this.toastMessageService.clearMessage();
        this.handleErrorResponse(error);
      }
    );
  } */

  private async createOrUpdateTimberRow(
    isRequestFromAutoSave: boolean = false
  ): Promise<void> {
    this.blockedPanel = true;
    this.payload = JSON.parse(JSON.stringify(this.tableForm.value));
    for (var i = 0; i < this.tableForm.value.rows.length; i++) {
      if (isRequestFromAutoSave) {
        if (
          this.tableForm.value.rows[i].grossGirth &&
          this.tableForm.value.rows[i].grossLength
        ) {
          console.log(
            `************* Girth ${this.tableForm.value.rows[i].grossGirth} && length ${this.tableForm.value.rows[i].grossLength} `
          );
          this.assignPayloadRows(i);
        } else {
        }
      } else {
        this.assignPayloadRows(i);

        if (
          !this.payload.rows[i].grossLength ||
          !this.payload.rows[i].grossGirth
        ) {
          this.toastMessageService.showWarningMessage(
            'Incomplete Form',
            'Please enter all the required fields or delete any row that is not required.'
          );
          this.blockedPanel = false;

          return;
        }
      }
    }

    // ... (rest of your code)
    if (!this.isFormValid()) {
      this.toastMessageService.showWarningMessage(
        'Incomplete Form',
        'Please enter all the required fields or delete any row that is not required.'
      );
      this.blockedPanel = false;

      return;
    }

    //  this.createHoppusTimberSummary(this.payload.rows);
    await this.createHoppusTimberSummary(this.payload.rows);

    const values2 = JSON.stringify(this.tableForm.get('summary')?.value);
    this.payload = this.tableForm.value; // get the updated form value
    if (this.hoppusTimberId) {
      this.beforeSubmitConsoleContent = this.payload.rows;
      //this.sawnTimberResponseObservable =
      this.blockedPanel = true;
      this.cd.markForCheck();
      this.hoppusServiceV2
        .updateHoppusTimber(this.payload, this.hoppusTimberId)
        .subscribe((response: any) => {
          this.toastMessageService.clearMessage();
          this.handleSuccessfulResponse(response);
          this.blockedPanel = false;
      this.cd.markForCheck();

        });
    } else {
//      this.cd.markForCheck();
      this.blockedPanel = true;
        this.hoppusServiceV2.createHoppusTimber(this.payload).subscribe(
          (success: any)=>{
             this.handleSuccessfulResponse(success);
          },
          (error: any)=>{
            this.blockedPanel = false;
            this.cd.markForCheck();
            this.handleErrorResponse(error);
          },
          ()=>{
            this.blockedPanel = false;
            this.cd.markForCheck();

          },
          )
     

    }

    /*  this.sawnTimberResponseObservable.subscribe(
      (response: any) => {
        this.toastMessageService.clearMessage();
        this.handleSuccessfulResponse(response);
        this.blockedPanel = false;
      },
      (error: any) => {
        this.toastMessageService.clearMessage();
        this.handleErrorResponse(error);
        this.blockedPanel = false;
      }
    ); */
  }

  private assignPayloadRows(i: number): void {
    this.payload.rows[i].grossGirth = +this.tableForm.value.rows[i].grossGirth;
    this.payload.rows[i].grossLength =
      +this.tableForm.value.rows[i].grossLength;
    this.payload.rows[i].netLength = +this.tableForm.value.rows[i].netLength;
    this.payload.rows[i].netGirth = +this.tableForm.value.rows[i].netGirth;
    this.payload.rows[i].grossVolumeCbm =
      +this.tableForm.value.rows[i].grossVolumeCbm;
    this.payload.rows[i].netVolumeCbm =
      +this.tableForm.value.rows[i].netVolumeCbm;
    this.payload.rows[i].grossVolumeCft =
      +this.tableForm.value.rows[i].grossVolumeCft;
    this.payload.rows[i].netVolumeCft =
      +this.tableForm.value.rows[i].netVolumeCft;
    this.payload.rows[i].review = this.tableForm.value.rows[i].review;
    this.payload.rows[i].uniqueKey = +this.tableForm.value.rows[i].unique_key;
    this.payload.rows[i].action = this.tableForm.value.rows[i].action;
    this.payload.rows[i].editMode = this.tableForm.value.rows[i].editMode;
    // this.payload.rows[i].highlight = this.tableForm.value.rows[i].highlight;
    // ... (other assignments)
  }

  private handleSuccessfulResponse(response: any): void {
    this.hoppusTimberId = response.id;
    this.sawnTimberConfigId = response.config.id;
    this.syncAfterSaveOrUpdate();

    const action = this.hoppusTimberId ? 'Saved' : 'Added';
    this.toastMessageService.showSuccessMessage(
      'Success',
      `${action} successfully`
    );
    this.tableForm.markAsPristine();
  }

  private handleErrorResponse(error: any): void {
    const action = this.hoppusTimberId ? 'saving' : 'updating';
    this.toastMessageService.showErrorMessage('Error', `Error while ${action}`);
  }

  /* private isFormValid(): boolean {
  // Add validation logic here and return true or false
  return true; // Placeholder return
} */

  private createHoppusTimberSummary(rows: any): Promise<void> {
    this.blockedPanel = true;
    let netVolumeCbm = 0.0;
    let grossVolumeCbm = 0.0;

    let totalNetVolumeCbm = 0.0;
    let totalGrossVolumeCbm = 0.0;
    let totalNetVolumeCft = 0.0;
    let totalGrossVolumeCft = 0.0;
    const totalPieces = rows.length;

    const lengthAllowance = this.tableForm?.get(
      'config.lengthAllowance'
    )?.value;
    const girthAllowance = this.tableForm?.get('config.girthAllowance')?.value;

    const lengthUnit = this.tableForm.get('config.lengthUnit')?.value;
    const girthUnit = this.tableForm.get('config.girthUnit')?.value;

    for (var i = 0; i < rows.length; i++) {
      let row = rows[i];
      if (row.action !== 'REMOVED') {
        netVolumeCbm = this.calculateVolume(row.netLength, row.netGirth);
        totalGrossVolumeCbm += this.calculateVolume(
          row.grossLength,
          row.grossGirth
        );
        totalNetVolumeCbm += netVolumeCbm;
        totalGrossVolumeCbm += grossVolumeCbm;
      }
    }

    totalNetVolumeCft = totalNetVolumeCbm * 35.315;
    totalGrossVolumeCft = totalGrossVolumeCbm * 35.315;
    return new Promise<void>((resolve, reject) => {
       try {
      Promise.all([
        this.tableForm.get('summary.netVolume')?.setValue(totalNetVolumeCbm),
        this.tableForm.get('summary.netVolumeCft')?.setValue(totalNetVolumeCft),
        this.tableForm
          .get('summary.grossVolumeCft')
          ?.setValue(totalGrossVolumeCft),
        this.tableForm
          .get('summary.grossVolume')
          ?.setValue(totalGrossVolumeCbm),
        this.tableForm
          .get('summary.lengthAllowance')
          ?.setValue(lengthAllowance),
        this.tableForm.get('summary.girthAllowance')?.setValue(girthAllowance),
        this.tableForm.get('summary.pieces')?.setValue(totalPieces),
        // netVolume: new FormControl('0'),
        // grossVolume: new FormControl('0'),
        // netVolumeCft: new FormControl('0'),
        // grossVolumeCft: new FormControl('0'),
        // lengthAllowance: new FormControl('0'),
        // girthAllowance: new FormControl('0'),
        // lengthUnit: new FormControl('cm'),
        // girthUnit: new FormControl('cm'),
        // pieces: new FormControl('0'),
      ]).then(() => {
        this.cd.markForCheck(); // Manually trigger change detection
        resolve();
      });
    }catch(error){
      console.error('Error in createHoppusTimberSummary:', error);
      this.cd.markForCheck();
      this.blockedPanel = false;
      reject(error);

    }
    });
  }

  safeStringify(obj: any) {
    let cache: any[] = [];
    return JSON.stringify(obj, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (cache.includes(value)) return;
        cache.push(value);
      }
      return value;
    });
  }

  removeAllRemovedRows(rowsArray: FormArray) {
    const indicesToRemove: number[] = [];

    // Find the indices of all rows to be removed
    rowsArray.controls.forEach((control: AbstractControl, index: number) => {
      if (control.get('action')?.value === 'REMOVED') {
        indicesToRemove.push(index);
      }
    });

    // Reverse sort the indices to ensure we don't mess up the array while removing
    indicesToRemove
      .sort((a, b) => b - a)
      .forEach((index) => rowsArray.removeAt(index));

    /*   this.rows.controls.forEach((control: AbstractControl) => {
      const group = control as FormGroup;
      group.get('action')?.setValue(SAWN_ACTION.UP_TO_DATE);
    });  */

    this.rows.controls.forEach((control: AbstractControl) => {
      (control as FormGroup).value['action'] = SAWN_ACTION.UP_TO_DATE;
    });
    this.rows.updateValueAndValidity();
    console.log(`Final After Sync results are -> as below`);
    console.table(this.payload.rows);
  }

  getTableRowAt(index: number): FormGroup {
    return this.rows.at(index) as FormGroup;
  }

  /* getNewSerialNumber(currentIndex: number | null, nextIndex: number | null): number | null {
  let newSerialNumber = null;

  if (currentIndex !== null && nextIndex !== null) {
      const currentRow = this.getTableRowAt(currentIndex).value;
      const nextRow = this.getTableRowAt(nextIndex).value;
      
      if (currentRow && nextRow) {
          const difference = Math.abs(nextRow.serialNumber - currentRow.serialNumber);
          if (difference < 0.001) {  // Adjust this threshold as needed
              // Handle edge cases, maybe by returning null or assigning a default value.
              return null;
          }

          newSerialNumber = (currentRow.serialNumber + nextRow.serialNumber) / 2;
          
          // Check if newSerialNumber already exists in this.rows
          if (this.isSerialNumberExists(newSerialNumber)) {
              // If it does, get a new serial number that lies between the existing number and the newSerialNumber
              return this.getNewSerialNumber(currentIndex, this.getIndexOfSerialNumber(newSerialNumber));
          }
      }
  }
  newSerialNumber = this.roundToFiveDecimalPlaces(newSerialNumber);
  return newSerialNumber;
} */

  isEmptyRow(row: AbstractControl): boolean {
    if (!row) {
      // console.error('Row is undefined or null!');
      return true; // or false depending on your logic
    }

    const fieldsToCheck = ['grossGirth', 'grossLength'];
    return fieldsToCheck.every((field) => {
      const fieldValue = row.get(field)?.value;
      return !fieldValue;
    });
  }

  async removeEmptyRows(formArray: FormArray): Promise<void> {
    return new Promise<void>((resolve) => {
      const emptyRowsIndices: number[] = [];

      // Find the indices of all empty rows
      formArray.controls.forEach((row, index) => {
        if (this.isEmptyRow(row)) {
          emptyRowsIndices.push(index);
        }
      });

      // Remove the empty rows, starting from the end to avoid index issues
      for (let i = emptyRowsIndices.length - 1; i >= 0; i--) {
        formArray.removeAt(emptyRowsIndices[i]);
      }

      resolve();
    });
  }

  isFormValid(): boolean {
    // Loop backwards through the rows until a non-empty row is found or all rows have been checked
    //for (let i = this.rows.length - 1; i >= 0; i--) {
    const row = this.rows.at(11);
    //if (!this.isEmptyRow(row)) {
    //return this.tableForm.valid;
    //}
    //}
    return true; // if all rows are empty, return true
  }

  @HostListener('window:resize', ['$event'])
  resize() {
    this.initConfigDialogWidth();
  }

  isMultipliedByFive(row: AbstractControl, field: string): boolean {
    const configGroup = this.tableForm.get('config');
    const isValidateMultipliesOfFive = configGroup?.get(
      'validateMultipliesOfFive'
    )?.value;

    if (!isValidateMultipliesOfFive) {
      return false;
    } else {
      const enteredValue = row?.get(field)?.value;

      if (enteredValue % 5 !== 0) {
        return true; // Return true if value is NOT a multiple of five.
      }
    }

    // If value is a multiple of five
    return false;
  }

  selectTextInLastInput() {
    // Use setTimeout to wait for the view to be updated
    setTimeout(() => {
      const lastInput = this.grossLengthInputs.last;
      if (lastInput) {
        lastInput.nativeElement.select();
      }
    });
  }

  createFirstRow() {
    let newSerialNumber = -1;

    let minSerialNumber = Number.MAX_VALUE;

    for (var i = 0; i < this.rows.length; i++) {
      const currentSerialNumber =
        this.rows.at(i)?.get('serialNumber')?.value ?? 1;
      if (currentSerialNumber < minSerialNumber) {
        minSerialNumber = currentSerialNumber;
      }
    }
    newSerialNumber = this.getUniqueRandomNumber(0, minSerialNumber, this.rows);
    console.log(`newSerialNumber ${newSerialNumber}`);
    const row = this.formBuilder.group({
      id: null,
      serialNumber: [newSerialNumber],
      grossLength: [null, [Validators.required]],
      grossGirth: [null, [Validators.required]],
      netLength: [null, [Validators.required]],
      netGirth: [null, [Validators.required]],
      lengthAllowance: [null, [Validators.required]],
      girthAllowance: [null, [Validators.required]],

      grossVolumeCbm: [{ value: 0, disabled: false }], // if you still need this
      netVolumeCbm: [{ value: 0, disabled: false }], // if you still need this

      grossVolumeCft: [{ value: 0, disabled: false }], // if you still need this
      netVolumeCft: [{ value: 0, disabled: false }], // if you still need this

      review: [false],
      unique_key: [this.uniqueKey++],
      action: [SAWN_ACTION.ADDED],
      editMode: [false],
      highlight: [false],
    });

    // add subscriptions
    row.valueChanges.subscribe(() => {
      const volume = this.calculateVolume(
        +(row.get('grossLength')?.value ?? 0),
        +(row.get('grossGirth')?.value ?? 0)
      );

      const volumeStr = volume ?? 0;
      let cbm = row.get('grossVolumeCbm');
      (cbm as AbstractControl).patchValue(volumeStr.toFixed(3), {
        emitEvent: false,
      });

      let cftStr = volume * 35.315;
      let cft = row.get('cft');
      (cft as AbstractControl).patchValue(cftStr.toFixed(3), {
        emitEvent: false,
      });
    });

    row.valueChanges.subscribe(() => this.updateAllRows());

    // Insert the row into the rows FormArray at the specified index or at the end of the array

    // this.rows.push(row);
    this.rows.insert(0, row);

    return row;
  }

  getRandomNumber(min: number, max: number): number {
    const random = Math.random() * (max - min) + min;
    return parseFloat(random.toFixed(5));
  }

  getUniqueRandomNumber(min: number, max: number, rows: FormArray): number {
    let unique = false;
    let randomNumber = -1;

    while (!unique) {
      unique = true;
      randomNumber = this.getRandomNumber(min, max);

      for (let i = 0; i < rows.length; i++) {
        const existingValue = rows.at(i).get('serialNumber')?.value;
        if (existingValue === randomNumber) {
          unique = false;
          break;
        }
      }
    }

    return randomNumber;
  }

  /*   getUniqueRandomNumberByIndex(currentIndex: number, rows: FormArray): number | null {
    if (currentIndex < 0 || currentIndex >= rows.length - 1) {
      return null; // Invalid index
    }
    
    const min = rows.at(currentIndex).get('serialNumber')?.value;
    const max = rows.at(currentIndex + 1).get('serialNumber')?.value;
    
    if (min === null || max === null) {
      return null; // Min or max value not found
    }
    
    let unique = false;
    let randomNumber = -1;
  
    while (!unique) {
      unique = true;
      randomNumber = this.getRandomNumber(min, max);
  
      for (let i = 0; i < rows.length; i++) {
        const existingValue = rows.at(i).get('serialNumber')?.value;
        if (existingValue === randomNumber) {
          unique = false;
          break;
        }
      }
    }
  
    return randomNumber;
  } */
  getUniqueRandomNumberByIndex(
    currentIndex: number,
    rows: FormArray
  ): number | null {
    if (currentIndex < 0 || currentIndex >= rows.length - 1) {
      return null; // Invalid index
    }

    const min = rows.at(currentIndex).get('serialNumber')?.value;
    let max = rows.at(currentIndex + 1).get('serialNumber')?.value;
    if (min === null || max === null) {
      return null; // Min or max value not found
    }
    if (min === max) {
      if (currentIndex + 2 < rows.length) {
        max = rows.at(currentIndex + 2).get('serialNumber')?.value;
      } else {
        max = min + 1;
      }
    }

    const potentialNumbers = new Set<number>();

    // Generate a set of potential random numbers
    for (let i = 0; i < 100; i++) {
      // Assuming 100 is sufficient. Increase if needed.
      const randNum = this.getRandomNumber(min, max);
      potentialNumbers.add(randNum);
    }

    // Check for uniqueness
    for (let i = 0; i < rows.length; i++) {
      const existingValue = rows.at(i).get('serialNumber')?.value;
      if (existingValue !== null) {
        potentialNumbers.delete(existingValue);
      }
    }

    // Pick any remaining number from the set
    if (potentialNumbers.size > 0) {
      return Array.from(potentialNumbers)[0];
    } else {
      return null; // No unique number found
    }
  }
  /*  scrollToTop() {
    //this.dt.nativeElement.scrollTop = 0;
  }

  scrollToBottom() {
    //   this.dt.nativeElement.scrollTop = this.dt.nativeElement.scrollHeight;
  } */

  scrollToTop(): void {
    const tableElement = this.dt.el.nativeElement;
    const scrollableWrapper = tableElement.querySelector(
      '.p-datatable-wrapper'
    );
    if (scrollableWrapper) {
      console.log('Scrollable wrapper found: ', scrollableWrapper);
      this.renderer.setProperty(scrollableWrapper, 'scrollTop', 0);
    } else {
      console.error('Scrollable wrapper not found');
    }
  }

  scrollToBottom(): void {
    const tableElement = this.dt.el.nativeElement;
    const scrollableWrapper = tableElement.querySelector(
      '.p-datatable-wrapper'
    );
    if (scrollableWrapper) {
      console.log('Scrollable wrapper found: ', scrollableWrapper);
      const scrollHeight = scrollableWrapper.scrollHeight;
      const offsetHeight = scrollableWrapper.offsetHeight;
      this.renderer.setProperty(
        scrollableWrapper,
        'scrollTop',
        scrollHeight - offsetHeight
      );
    } else {
      console.error('Scrollable wrapper not found');
    }
  }

  isOnTop(): boolean {
    if (this.dt) {
      const tableElement = this.dt.el.nativeElement;
      const scrollableWrapper = tableElement.querySelector(
        '.p-datatable-wrapper'
      );
      if (scrollableWrapper) {
        return scrollableWrapper.scrollTop === 0;
      } else {
        console.error('Scrollable wrapper not found');
        return false;
      }
    } else {
      return false;
    }
  }

  isOnBottom(): boolean {
    if (this.dt) {
      const tableElement = this.dt.el.nativeElement;
      const scrollableWrapper = tableElement.querySelector(
        '.p-datatable-wrapper'
      );
      if (scrollableWrapper) {
        const scrollHeight = scrollableWrapper.scrollHeight;
        const offsetHeight = scrollableWrapper.offsetHeight;
        return scrollableWrapper.scrollTop === scrollHeight - offsetHeight;
      } else {
        // console.error('Scrollable wrapper not found');
      }
    } else {
      // console.error('Table element not found');
      return false;
    }
    return false;
  }
  // const worksheet = xlsx.utils.json_to_sheet(this.tableForm.value.rows);
  /* const rowsWithSerialNumber = this.tableForm.value.rows.map(
        (row: any, index: number) => {
          return {
            SR: index + 1,
            grossLength: row.grossLength,
            netLength: row.netLength,
            grossGirth: row.grossGirth,
            netGirth: row.netGirth,
            grossVolumeCbm: row.grossVolumeCbm,
            netVolumeCbm: row.netVolumeCbm,
          };
        }
      );

      const headers = [
        {
          SR: 'SR',
          grossLength: 'G. Length',
          netLength: 'N. Length',
          grossGirth: 'G. Girth',
          netGirth: 'N. Girth',
          grossVolumeCbm: 'G. CBM',
          netVolumeCbm: 'N. CBM',
        },
      ];

      const dataToExport = [...headers, ...rowsWithSerialNumber]; */

  /* private addAdditionalRows(worksheet: any, data: any) {
    Object.entries(data).forEach(([label, value], index) => {
      const row = worksheet.addRow([label, '', '', value, '', '']);
      // Merge cells for label (A, B, C)
      worksheet.mergeCells(`A${index + 1}:C${index + 1}`);
      // Merge cells for value (D, E)
      worksheet.mergeCells(`D${index + 1}:E${index + 1}`);

      // Set label in bold
      row.getCell(1).font = { bold: true };
    });
  } */

  private addAdditionalRows(worksheet: any, data: any) {
    let rowIndex = 1; // Start at row 1
    Object.entries(data).forEach(([label, value]) => {
      let row;
      if (label.startsWith('BlankRow')) {
        row = worksheet.addRow(['', '', '', '', '', '']);
      } else {
        row = worksheet.addRow([label, '', '', value, '', '']);
        // Merge cells for label (A, B, C)
        worksheet.mergeCells(`A${rowIndex}:C${rowIndex}`);
        // Merge cells for value (D, E)
        worksheet.mergeCells(`D${rowIndex}:E${rowIndex}`);
        // Set label in bold
        row.getCell(1).font = { bold: true };
      }
      rowIndex++;
    });
  }

  async exportExcel() {
    await this.loadDataAtOnce().toPromise();
    if (!(this.totalRecords && this.totalRecords > 1)) {
      this.toastMessageService.showInfoMessage(
        'Export Unsuccessful',
        'No data available for export.'
      );
      return;
    }
   /* import('exceljs').then((ExcelJS) => {
      const config = this.tableForm.get('config')?.value ?? '';
      const girthAllowance =
        this.tableForm?.get('config.girthAllowance')?.value ?? 0;
      const lengthAllowance =
        this.tableForm?.get('config.lengthAllowance')?.value ?? 0;
      const containerNumber = this.container.containerNumber;
      const totalPieces = this.totalRecords;
      const grossVolumeCbm = Number.parseFloat(
        this.totalGrossVolumeCbm.toFixed(3)
      );
      const netVolumeCbm = Number.parseFloat(this.totalNetVolumeCbm.toFixed(3));
      const grossCftAvg = (grossVolumeCbm * 35.315) / totalPieces;
      const netCftAvg = (netVolumeCbm * 35.315) / totalPieces;

      var workbook = new ExcelJS.Workbook();
      var worksheet = workbook.addWorksheet(containerNumber);

      // Add Container Number at the top
      // Data for additional rows
      const additionalData = {
        'Container Number': this.container.containerNumber,
        Product: this.containerDetails.product,
        BlankRow2: '',
        'Total Pieces': this.totalRecords.toString(),
        'Girth Allowance': girthAllowance.toString(),
        'Length Allowance': lengthAllowance.toString(),
        'Gross Vol CBM': grossVolumeCbm.toString(),
        'Net Vol CBM': netVolumeCbm.toString(),
        'Gross Avg CFT': grossCftAvg.toFixed(3),
        'Net Avg CFT': netCftAvg.toFixed(3),
        BlankRow1: '',
        'Country of Origin': this.containerDetails.countryOfOrigin,
        'Port of Loading': this.containerDetails.portOfLoading,
        'Port of Destination': this.containerDetails.portOfDestination,
        Fumigation: this.containerDetails.fumigation,

        // ... other key-value pairs
      };

      // Function call to add additional rows
      this.addAdditionalRows(worksheet, additionalData);
      worksheet.addRow([]);

      // Manually add headers
      const headerRow = worksheet.addRow([
        'SR',
        'G. Length',
        'N. Length',
        'G. Girth',
        'N. Girth',
        'G. CBM',
        'N. CBM',
      ]);
      headerRow.font = { bold: true }; // Bold the headers
      headerRow.alignment = { horizontal: 'center' }; // Center-align header text

      // Add data rows
      const rows = this.tableForm.value.rows.map((row: any, index: number) => {
        let newRow = [
          index + 1,
          parseFloat(row.grossLength),
          parseFloat(row.netLength),
          parseFloat(row.grossGirth),
          parseFloat(row.netGirth),
          parseFloat(row.grossVolumeCbm),
          parseFloat(row.netVolumeCbm),
        ];
        const addedRow = worksheet.addRow(newRow);

        // Set cell format for each column
        ['A', 'B', 'C', 'D', 'E'].forEach((col, idx) => {
          addedRow.getCell(idx + 1).numFmt = '0'; // Without decimal places
        });
        addedRow.getCell(6).numFmt = '0.000'; // With 3 decimal places
        addedRow.getCell(7).numFmt = '0.000'; // With 3 decimal places
      });

      // Add and bold the last row
      const lastRow = worksheet.addRow([
        null,
        null,
        null,
        null,
        'TOTAL:',
        parseFloat(this.totalGrossVolumeCbm.toFixed(3)),
        parseFloat(this.totalNetVolumeCbm.toFixed(3)),
      ]);

      lastRow.font = { bold: true };

      // Write to buffer and save the file
      workbook.xlsx.writeBuffer().then((buffer) => {
        this.saveAsExcelFile(buffer, 'packinglist');
      });
    });*/
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  async exportPdf() {
    await this.loadDataAtOnce().toPromise();
    if (!(this.totalRecords && this.totalRecords > 1)) {
      this.toastMessageService.showErrorMessage(
        'Export Unsuccessful',
        'No data available for export.'
      );
      return;
    }
    const doc = new jsPDF();
    let colWidths: number[] = [];

    let lastAutoTableY = 0; // Initialize Y value

    const girthAllowance =
      this.tableForm?.get('config.girthAllowance')?.value ?? 0;
    const lengthAllowance =
      this.tableForm?.get('config.lengthAllowance')?.value ?? 0;
    const containerNumber = this.container.containerNumber;
    const totalPieces = this.totalRecords;
    const grossVolumeCbm = Number.parseFloat(
      this.totalGrossVolumeCbm.toFixed(3)
    );
    const netVolumeCbm = Number.parseFloat(this.totalNetVolumeCbm.toFixed(3));
    const grossCftAvg = (grossVolumeCbm * 35.315) / totalPieces;
    const netCftAvg = (netVolumeCbm * 35.315) / totalPieces;

    // Add additional data
    const additionalData = [
      ['Container Number', this.container.containerNumber],
      ['Product', this.containerDetails.product],
      ['Total Pieces', this.totalRecords.toString()],
      ['Girth Allowance', girthAllowance.toString()],
      ['Length Allowance', lengthAllowance.toString()],
      ['Gross Vol CBM', grossVolumeCbm.toString()],
      ['Net Vol CBM', netVolumeCbm.toString()],
      ['Gross Avg CFT', grossCftAvg.toFixed(3)],
      ['Net Avg CFT', netCftAvg.toFixed(3)],
      ['Country of Origin', this.containerDetails.countryOfOrigin],
      ['Port of Loading', this.containerDetails.portOfLoading],
      ['Port of Destination', this.containerDetails.portOfDestination],
      ['Fumigation', this.containerDetails.fumigation],

      // ... other key-value pairs
    ];
    // doc.text('Additional Data', 10, 10);
    autoTable(doc, {
      startY: 10,
      body: additionalData,
      styles: { fontSize: 8 },
      didDrawPage: function (data) {
        lastAutoTableY = data?.cursor?.y || lastAutoTableY;
      },
    });

    // Add Table Data
    const headers = [
      'SR',
      'G. Length',
      'N. Length',
      'G. Girth',
      'N. Girth',
      'G. CBM',
      'N. CBM',
    ];
    const rows = this.tableForm.value.rows.map((row: any, index: number) => [
      index + 1,
      parseFloat(row.grossLength),
      parseFloat(row.netLength),
      parseFloat(row.grossGirth),
      parseFloat(row.netGirth),
      parseFloat(row.grossVolumeCbm),
      parseFloat(row.netVolumeCbm),
    ]);
    // doc.text('Table Data', 10, 50);
    // autoTable(doc, { startY: 100, head: [headers], body: rows });
    autoTable(doc, {
      startY: lastAutoTableY + 10,
      head: [headers],
      body: rows,
      didDrawPage: function (data) {
        lastAutoTableY = data.cursor?.y || lastAutoTableY;
      },
      didDrawCell: function (data) {
        if (data.section === 'head') {
          colWidths.push(data.cell.width);
        }
      },
    });

    const lastRow = [
      null,
      null,
      null,
      null,
      'TOTAL:',
      parseFloat(this.totalGrossVolumeCbm.toFixed(3)),
      parseFloat(this.totalNetVolumeCbm.toFixed(3)),
    ];

    // let colStyles = {};
    let colStyles: { [key: number]: { cellWidth: number } } = {};

    colWidths.forEach((width, index) => {
      colStyles[index] = { cellWidth: width };
    });

    autoTable(doc, {
      startY: lastAutoTableY + 0,
      body: [lastRow],
      styles: { fontStyle: 'bold', fontSize: 10 },
      columnStyles: colStyles,
    });

    const filename = 'packinglist' + '_export_' + new Date().getTime() + '.pdf';
    doc.save(filename);
  }

  subscribedToLoadDataAtOnce() {
    this.loadDataAtOnce().subscribe(
      (response: any) => {},
      (error: any) => {},
      () => {
        this.scrollToBottom();
      }
    );
  }

  loadDataAtOnce(): Observable<number> {
    if (this.offset === 0) {
      this.offset = this.limit;
    }
    if ((this.rows?.length ?? 0) < (this.totalRecords ?? 1)) {
      // const loadFromIndex = this.rows?.length + 1 ?? 0;
      const loadFromIndex = this.rows && this.rows.length ? this.rows.length + 1 : 0;

      this.loading = true;
      this.dialogLoadAllData = true;
      return new Observable<number>((subscriber) => {
        this.hoppusServiceV2
          .getHoppusTimbersFromContainerIdFromIndex(
            this.container.containerId,
            loadFromIndex
          )
          .subscribe(
            (response: any) => {
              let rowsFetched: HoppusTimberRow[] = response.rows;
              this.prepopulateConfig = response.config;
              this.tableForm.get('config')?.patchValue(this.prepopulateConfig);

              rowsFetched = rowsFetched.sort((a, b) => {
                const serialA = a.serialNumber || 0;
                const serialB = b.serialNumber || 0;
                return serialA - serialB;
              });

              let index = 0;

              const asyncBatchUpdate = () => {
                const batch = rowsFetched.slice(index, index + this.batchSize);
                batch.forEach((eachRow) => {
                  this.prepopulateRow({
                    // Your existing object structure
                    id: eachRow.id,
                    serialNumber: eachRow.serialNumber,
                    grossLength: eachRow.grossLength,
                    netLength: eachRow.netLength,
                    grossGirth: eachRow.grossGirth,
                    netGirth: eachRow.netGirth,
                    lengthAllowance: eachRow.lengthAllowance,
                    girthAllowance: eachRow.girthAllowance,
                    grossVolumeCbm: eachRow.grossVolumeCbm,
                    netVolumeCbm: eachRow.netVolumeCbm,
                    grossVolumeCft: eachRow.grossVolumeCft,
                    netVolumeCft: eachRow.netVolumeCft,
                    totalPieces: eachRow.totalPieces,
                    review: eachRow.review,
                    uniqueKey: eachRow.uniqueKey,
                    action: SAWN_ACTION.UP_TO_DATE,
                    editMode: false,
                    hoppusTimber: eachRow.hoppusTimber,
                    uuid: eachRow.uuid ?? this.hoppusServiceV2.generateUUID(),
                  });
                });

                index += this.batchSize;

                if (index < rowsFetched.length) {
                  setTimeout(asyncBatchUpdate, 0); // 0 milliseconds delay to allow other code to execute
                } else {
                  subscriber.next(rowsFetched.length);
                  subscriber.complete();
                  this.initValidations();
                  this.dialogLoadAllData = false;
                }
              };

              asyncBatchUpdate();

              if (rowsFetched.length < 1) {
                subscriber.next(0);
                subscriber.complete();
              }
            },
            (error: any) => {
              // Existing error handling logic
              console.error(`Response is ${JSON.stringify(error)}`);
              if (error.status === 404) {
                console.error('HTTP 404 Error: ', error);
                // this.createRow(1);
                subscriber.next(0);
                subscriber.complete();
              }
              subscriber.error(error);
              this.loading = false;
              this.dialogLoadAllData = false;
            },
            () => {
              this.offset = this.totalRecords;
              this.loading = false;
            }
          );
      });
    } else {
      return of(0);
    }
  }
  testAutoSave() {
    this.onSubmit(true);
  }

  getSummary() {
    this.hoppusServiceV2
      .getHoppusTimberSummary(this.container.containerId)
      .subscribe(
        (response: any) => {
          this.containerSummary = response;
        },
        (error: any) => {},
        () => {}
      );
  }

  getContainerDetails() {
    this.hoppusServiceV2
      .getHoppusTimberContainerDetails(this.container.containerId)
      .subscribe(
        (response: any) => {
          this.containerDetails = response;
        },
        (error: any) => {},
        () => {}
      );
  }
}
