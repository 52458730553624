import { HttpParams } from '@angular/common/http';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Page } from 'src/app/commons/models/page';
import { Location } from '@angular/common';

import { ContextMenu } from 'primeng/contextmenu';
import { ClipboardService } from 'ngx-clipboard';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { AppConstants } from 'src/app/commons/app-constants';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';
import { MeasurementSummary } from 'src/app/modules/measurement/model/measurement-summary';
import { Measurement } from 'src/app/modules/measurement/model/measurement';
import { MeasurementSharedDataService } from 'src/app/modules/measurement/services/measurement-shared-data.service';
import { MeasurementService } from 'src/app/modules/measurement/services/measurement.service';
import { ContainerService } from 'src/app/modules/container/services/container.service';
import { UtilService } from 'src/app/services/util.service';
import { tap, catchError, throwError } from 'rxjs';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';

@Component({
  selector: 'app-sawn-timber-list',
  templateUrl: './sawn-timber-list.component.html',
  styleUrls: ['./sawn-timber-list.component.css'],
  providers: [ToastMessagesService],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class SawnTimberListComponent implements OnInit {
  currentRowIndex = 0;
  formHeader!: string;
  formSubHeader!: string;
  showNameColumn = true;
  showContactPersonColumn = true;
  showGstColumn = true;
  showCityColumn = true;
  showPhoneColumn = false;
  showEmailColumn = false;
  protected items!: MenuItem[];
  protected shareItems!: MenuItem[];
  measurementSpeedDialItems!: MenuItem[];
  home!: MenuItem;
  rows = 5;
  measurementSummarys!: MeasurementSummary[];
  multiSortMeta!: any[];
  totalRecords: number = 0;
  first: number = 0;
  last: number = 0;
  rowsPerPageOptions = [5, 10, 20]; // set the options for the number of rows per page
  @ViewChild('globalFiterInput') globalFiterInput!: ElementRef;
  @ViewChild('dt') table!: Table;
  cols!: any[];
  isMobile = false;
  @ViewChildren('cm') contextMenus!: QueryList<ContextMenu>;
  private hidingInProgress = false;

  _selectedColumns!: any[];
  selectedSummary: MeasurementSummary[] = [];
  tableStyle: any;

  constructor(
    private measurementService: MeasurementService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private measurementSharedDataService: MeasurementSharedDataService,
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private containerSerivce: ContainerService,
    private location: Location,
    private _clipboardService: ClipboardService,
    private toastMessagesService: ToastMessagesService,
    private encryptedStorageService: EncryptedStorageService
  ) {
    this.setPageTitle();
    this.isMobile = this.utilService.isMobile();
    this.tableStyle = this.utilService.getTableSize();
  }

  ngOnInit() {
    this.shareItemsInit();
    this.initTableColumns();
    this.populateBreadCrumb();
    this.initSpeedDial();

  }

  getSortOrder(order: number): string {
    return order === -1 ? 'DESC' : 'ASC';
  }

  setPaginationParams(params: HttpParams, event: any): HttpParams {
    const pageNumber = Math.floor(event.first / event.rows) + 1;
    params = params.set('size', event.rows);
    params = params.set('page', pageNumber - 1);
    return params;
  }

  setSortParams(params: HttpParams, event: any): HttpParams {
    const multiSortLength = event.multiSortMeta?.length;
    for (var i = 0; i < multiSortLength; i++) {
      const sortOrder = this.getSortOrder(event.multiSortMeta[i].order);
      params = params.set(
        'sort',
        event.multiSortMeta[i].field + ',' + sortOrder
      );
    }
    return params;
  }

  setFilterParams(params: HttpParams, event: any, fields: string[]): HttpParams {
    fields.forEach(field => {
      if (event.filters.hasOwnProperty(field)) {
        event.filters[field].forEach((filter: { value: string | number | boolean; }) => {
          if (filter.value) {
            params = params.set(field, filter.value);
          }
        });
      }
    });
    return params;
  }

  sortSummary(event: any) {
    let params = new HttpParams();
    params = this.setPaginationParams(params, event);
    params = this.setSortParams(params, event);

    const filterFields = [
      'pieces', 'netVolume', 'grossVolume', 'netAverage', 'grossAverage',
      'containerNumber', 'supplier', 'product', 'loadingSite'
    ];
    params = this.setFilterParams(params, event, filterFields);

    this.measurementSummarys = [];
    this.measurementService.getListContainerSummary1(event.filters, 0, 5).subscribe(
      (response: Page<MeasurementSummary>) => {
        this.measurementSummarys = response.content;
        this.first = response.pageable.pageNumber * response.pageable.pageSize + 1;
        const max = response.pageable.pageNumber * response.pageable.pageSize + response.pageable.pageSize;
        this.last = max < response.totalElements ? max : response.totalElements;
        this.totalRecords = response.totalElements;
      },
      (error) => { },
      () => { }
    );
  }


  handleResponse(response: Page<MeasurementSummary>) {
    this.measurementSummarys = response.content;
    this.first = response.pageable.pageNumber * response.pageable.pageSize + 1;
    const max = response.pageable.pageNumber * response.pageable.pageSize + response.pageable.pageSize;
    this.last = max < response.totalElements ? max : response.totalElements;
    this.totalRecords = response.totalElements;
  }

  globalFilter(value: any) {
    const globalFilter = this.globalFiterInput.nativeElement.value;
    if (globalFilter) {
      let params = new HttpParams().append('q', globalFilter);
      this.measurementService
        .getMeasurementSummaryGlobalFilter(params)
        .subscribe(
          response => this.handleResponse(response),
          error => { /* Handle error here, if necessary */ },
          () => { /* Add any logic required when observable completes, if necessary */ }
        );
    }
  }


  clear(table: Table) {
    table.clear();
  }

  initTableColumns() {
    this._selectedColumns = [
      'Container Number',
      'Pieces',
      'G Vol',
      'N Vol',
      'G Avg',
      'N Avg',
    ];
    this.cols = [
      'Container Number',
      'Pieces',
      'G Vol',
      'N Vol',
      'G Avg',
      'N Avg',
      'Product',
      'Supplier',
      'Loading Site',
    ];
  }

  displayToastMessage(type: string, title: string, message: string) {
    switch (type) {
      case 'info':
        this.toastMessagesService.showInfoMessage(title, message);
        break;
      case 'warning':
        this.toastMessagesService.showWarningMessage(title, message);
        break;
      case 'error':
        this.toastMessagesService.showErrorMessage(title, message);
        break;
    }
  }

  deleteRows() {
    const ids: number[] = this.selectedSummary.map(
      (summary) => summary.containerSummaryId
    );

    if (ids.length === 0) {
      this.displayToastMessage('warning', 'No Selection', 'No rows selected for deletion.');
      return;
    }

    this.confirmationService.confirm({
      header: 'Delete Measurement Summary',
      message: 'Are you sure you want to delete this measurement summary?',
      accept: () => {
        this.measurementService.deleteContainerSummary(ids).subscribe(
          () => {
            this.displayToastMessage('info', 'Success', 'Measurement Summary Deleted Successfully');
            this.refreshTable();
          },
          () => {
            this.displayToastMessage('error', 'Operation Unsuccessful', 'An error occurred. Please try again.');
          }
        );
      },
    });
  }

  refreshTable() {
    this.measurementService
      .getListContainerSummary()
      .subscribe((response: Page<MeasurementSummary>) => {
        this.measurementSummarys = response.content;
        this.first = response.pageable.pageNumber * response.pageable.pageSize + 1;
        const max = response.pageable.pageNumber * response.pageable.pageSize + response.pageable.pageSize;
        this.last = max < response.totalElements ? max : response.totalElements;
        this.totalRecords = response.totalElements;
        this.selectedSummary = [];
      });
  }


  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) => val.includes(col));
  }
  getSelectedContainerSummary() {
    console.log(`Selected Summary length is ${this.selectedSummary.length} 
     and \nData is ${JSON.stringify(this.selectedSummary)}`);
  }

  onRowSelect(event: any) {
    //this.messageService.add({ severity: 'info', summary: 'Product Selected', detail: event.data.name });
  }

  onRowClick(data: MeasurementSummary) {
    console.log(`onRowClick Data is ${JSON.stringify(data)}`);
    /* this.buyerSharedData.sharedData = data;
    console.log(`shared is ${JSON.stringify(this.buyerSharedData.sharedData)}`) */
    //this.measurementSharedDataService.saveDataToLocalStorage(data);
    console.log(
      `Get data ${JSON.stringify(
        this.measurementSharedDataService.getDataFromLocalStorage()
      )}`
    );
    this.storeInEncryptedStorage(data.container.containerId);
    // this.router.navigate(['/edit/measurement']);
  }

  populateBreadCrumb() {
    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.items = [{ label: 'Measurements' }];
  }
  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }

  onViewMeasurement(containerId: number) {
    console.log(`onViewMeasurement ${containerId}`);
    this.measurementSharedDataService.saveContainerIdStorage(containerId);
    this.router.navigate(['/view/measurement']);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = this.utilService.isMobile();
    console.log(`is mobile ${this.utilService.isMobile()}`);
    this.tableStyle = this.utilService.getTableSize();
  }

  private shareItemsInit() {
    this.shareItems = [
      {
        icon: 'pi pi-copy',
        label: 'Copy Link',
        command: (event) => {
          // console.log(`Event is ${JSON.stringify(event)}`)
          console.log(`this.currentRowIndex ${this.currentRowIndex}`);
          console.table(this.measurementSummarys[this.currentRowIndex]); // public/view/measurement/
          console.log(`location ${window.location.hostname}`);
          this.copyPublicContainerViewLink(
            this.measurementSummarys[this.currentRowIndex].container
              .containerId
          );
        },
      },
      {
        icon: 'pi pi-external-link',
        label: 'Open Link',
        command: () => {
          this.openInNewTabPublicContainerViewLink(
            this.measurementSummarys[this.currentRowIndex].container
              .containerId
          );
          // this.messageService.add({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
        },
      },
    ];
  }





  showShareContextMenu(
    event: MouseEvent,
    rowIndex: number,
    measurements: MeasurementSummary
  ): void {
    // Prevent opening a new menu if there's a menu being hidden
    if (this.hidingInProgress) return;

    this.hidingInProgress = true;
    this.contextMenus.forEach((cm, index) => {
      if (index !== rowIndex) {
        cm.hide();
      }
    });

    // Allow enough time for the hide operations to complete
    setTimeout(() => {
      this.hidingInProgress = false;
    }, 100); // Adjust this delay if needed

    // Stop propagation to prevent the event reaching higher level components
    event.stopPropagation();

    const contextMenu = this.contextMenus.toArray()[rowIndex - 1];
    contextMenu.show(event);
    this.currentRowIndex = rowIndex - 1; // For copying public url

    // Since the show method is asynchronous, we need to delay our positioning code slightly
    setTimeout(() => {
      const cmElement = contextMenu.containerViewChild?.nativeElement as HTMLElement | undefined;
      if (cmElement) {
        // Proceed with your logic here
        // Use the event's clientX and clientY properties to get the mouse click position
        const clickPosition = { x: event.clientX, y: event.clientY };

        // Calculate the offsets based on the dimensions of the context menu or the clicked row
        const offsetY = cmElement.offsetHeight / 2;
        const offsetX = cmElement.offsetWidth / 2;

        // Adjust the context menu position
        cmElement.style.top = `${clickPosition.y - offsetY}px`; // Subtract additional 40
        cmElement.style.left = `${clickPosition.x - offsetX}px`; // Subtract additional 30
      }

    });
  }

  copyPublicContainerViewLink(containerId: number) {
    this.containerSerivce.getContainerNumberFromID(containerId).subscribe(
      (data: any) => {
        console.log(`Container UUID is ${JSON.stringify(data)}`);
        const origin = window.location.origin;
        const path = origin + '/public/view/measurement/' + data.containerUUID;
        console.log('URL without path:', origin);
        this._clipboardService.copy(path);
        this.messageService.add({
          severity: 'success',
          summary: 'Copied',
          detail: 'Link Copied',
        });
      },
      (error: any) => {
        console.error('Error while loading pulbic url');
      },
      () => { }
    );
  }

  openInNewTabPublicContainerViewLink(containerId: number) {
    this.fetchContainerUUID(containerId).subscribe(
      (data: any) => {
        if (typeof data.containerUUID === 'string') {
          this.openContainerInNewTab(data.containerUUID);
        } else {
          console.error('containerUUID is not a string:', data.containerUUID);
        }
      },
      error => console.error('Error while loading public url')
    );
  }

  fetchContainerUUID(containerId: number) {
    return this.containerSerivce.getContainerNumberFromID(containerId).pipe(
      tap(data => console.log(`Container UUID is ${JSON.stringify(data.containerUUID)}`)),
      catchError(error => {
        console.error(`Error fetching container UUID: ${JSON.stringify(error)}`);
        return throwError(error);
      })
    );
  }

  openContainerInNewTab(containerUUID: string) {
    const origin = window.location.origin;
    const path = `${origin}/public/view/measurement/${containerUUID}`;
    console.log('URL:', path);
    window.open(path, '_blank');
    // this.messageService.add({ severity: 'success', summary: 'Copied', detail: 'Link Copied' });
  }



  initSpeedDial() {
    this.measurementSpeedDialItems = [
      { icon: 'pi pi-filter-slash', command: () => this.clearTable() },
      { icon: 'pi pi-search', command: () => this.focusOnGlobalFilter() },
      { icon: 'pi pi-trash', command: () => this.deleteRows() },
      { icon: 'pi pi-plus', command: () => this.navigateToAddMeasurement() },
    ];
  }

  clearTable() {
    this.globalFiterInput.nativeElement.value = '';
    this.table.clear();
  }

  navigateToAddMeasurement() {
    //this.router.navigate(['/add/measurement']);
  }

  focusOnGlobalFilter() {
    this.globalFiterInput.nativeElement.focus();
  }

  clearFilter(dt: any) {
    this.table.clear();
  }

  storeInEncryptedStorage(containerId: number) {
    // /containers/fetch/{containerId}
    this.containerSerivce.getContainerDTOFromId(containerId).subscribe(
      (data: any) => {
        console.log(`getContainerDTOFromId ${JSON.stringify(data)}`);
        this.encryptedStorageService
          .setEncryptedDataToStorage(AppConstants.CONTAINER, data)
          .subscribe(
            (data: any) => {
              console.log(`Data Stored Successfully`);
              this.router.navigate(['/timber/pl']);
            },
            (error: any) => { },
            () => { }
          );
      },
      (error: any) => {
        console.error(`getContainerDTOFromId error  ${JSON.stringify(error)}`);
      },
      () => { }
    );
  }
}
