import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ClientJS } from 'clientjs';
import { AppConstants } from 'src/app/commons/app-constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = AppConstants.API_URL;
  fingerprint: any;
  constructor(private http: HttpClient) {
    const client = new ClientJS();
    this.fingerprint = client.getFingerprint();
  }

  // Store the token in localStorage after login

  // Example of making a request with JWT
  

  fetchTokenFromBackend(): Observable<any> {
    return this.http.get(`${this.apiUrl}/auth/token`, { withCredentials: true });
  }

  logoutAllOtherDevices(): Observable<any> {
    const headers = new HttpHeaders().set('deviceId', this.fingerprint.toString());

    return this.http.post(
      `${this.apiUrl}login/confirm-logout-other-devices`,
      {}, // You can pass an empty object or the actual body if needed
      { headers: headers, withCredentials: true } // Correctly include withCredentials here
    );
  }

  getProgress() {
    return this.http.get(`${AppConstants.API_URL}api/v1/progress`, { withCredentials: true })

  }
}
