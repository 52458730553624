import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FileUploadTestComponent } from './components/file-upload-test/file-upload-test.component';
import { TestComponent } from './components/test/test.component';
// import { AppGuardGuard } from './helper/app-guard.guard';
import { DeactivatesGuard } from './helper/deactivates.guard';
import { BillOfLadingListComponent } from './modules/bill-of-lading/bill-of-lading-list/bill-of-lading-list.component';
import { BillOfLadingViewComponent } from './modules/bill-of-lading/bill-of-lading-view/bill-of-lading-view.component';
import { BillOfLadingComponent } from './modules/bill-of-lading/bill-of-lading/bill-of-lading.component';
import { DeactivateBilOfLadingGuard } from './modules/bill-of-lading/helper/deactivate-bil-of-lading.guard';
import { BuyerComponent } from './modules/buyer/buyer/buyer.component';
import { BuyersListComponent } from './modules/buyer/buyers-list/buyers-list.component';
import { PageNotFoundComponent } from './modules/commons/page-not-found/page-not-found.component';
import { ContainerListComponent } from './modules/container/container-list/container-list.component';
import { ContainerComponent } from './modules/container/container/container.component';
import { DeactivatesContainerGuard } from './modules/container/helper/deactivates-container.guard';
import { ForbiddenComponent } from './modules/extras/forbidden/forbidden.component';
import { DeactivatesLoadingSiteGuard } from './modules/loading-site/helper/deactivates-loading-site.guard';
import { LoadingSiteListComponent } from './modules/loading-site/loading-site-list/loading-site-list.component';
import { LoadingSiteComponent } from './modules/loading-site/loading-site/loading-site.component';
import { LoginComponent } from './modules/login-logout/login/login.component';
import { MeasurementListComponent } from './modules/measurement/measurement-list/measurement-list.component';
import { BlPackingListComponent } from './modules/measurement/measurement-view/bl-packing-list/bl-packing-list.component';
import { MeasurementViewComponent } from './modules/measurement/measurement-view/measurement-view.component';
import { MeasurementComponent } from './modules/measurement/measurement/measurement.component';
import { BlPackingList } from './modules/measurement/model/bl-packing-list';
import { DeactivatesProductGuard } from './modules/product/helper/deactivates-product.guard';
import { ProductListComponent } from './modules/product/product-list/product-list.component';
import { ProductComponent } from './modules/product/product/product.component';
import { SellerGuard } from './modules/seller/helper/seller.guard';
import { SellerComponent } from './modules/seller/seller/seller.component';
import { SellersListComponent } from './modules/seller/sellers-list/sellers-list.component';
import { UserRegistrationComponent } from './modules/extras/user-registration/user-registration.component';
import { UserNotApprovedComponent } from './modules/extras/user-not-approved/user-not-approved.component';
import { UserAlreadyLoginComponent } from './modules/extras/user-already-login/user-already-login.component';
import { LoginSuccessComponent } from './modules/extras/login-success/login-success.component';
import { SocialLoginComponent } from './components/test/social-login/social-login.component';
import { TictactoeComponent } from './components/test/tictactoe/tictactoe.component';
import { LudoComponent } from './components/test/ludo/ludo.component';
import { GallaryComponent } from './components/test/gallary/gallary.component';
import { UnidentifiedErrorComponent } from './modules/extras/unidentified-error/unidentified-error.component';
import { TableComponent } from './components/test/table/table.component';
import { CompanyComponent } from './modules/extras/company/company.component';
import { SubscriptionComponent } from './modules/extras/subscription/subscription.component';
import { RegistrationComponent } from './modules/extras/registration/registration.component';
import { ConfirmationComponent } from './modules/extras/confirmation/confirmation.component';
import { LoginAddUserToCompanyComponent } from './modules/login-logout/login-add-user-to-company/login-add-user-to-company.component';
import { SubscriptionPackagesView } from './modules/extras/model/subscription-packages-view';
import { SubscriptionManagementComponent } from './modules/subscription-view/subscription-management/subscription-management.component';
import { SubscriptionOptedComponent } from './modules/subscription-view/subscription-opted/subscription-opted.component';
import { SubscriptionOptedFeaturesComponent } from './modules/subscription-view/subscription-opted-features/subscription-opted-features.component';
import { SubscriptionViewMainComponent } from './modules/subscription-view/subscription-view-main/subscription-view-main.component';
import { TransactionHistory } from './modules/subscription-view/models/transaction-history';
import { TransactionHistoryComponent } from './modules/subscription-view/transaction-history/transaction-history.component';
import { AddUserToCompanyComponent } from './modules/extras/add-user-to-company/add-user-to-company.component';
import { AddUserDeactivateGuard } from './modules/extras/add-user-deactivate.guard';
import { ManageUsersComponent } from './modules/extras/manage-users/manage-users.component';
import { ROLES } from './roles';
import { RestrictedPageComponent } from './modules/extras/restricted-page/restricted-page.component';
import { LocationSelectionComponent } from './modules/commons/location-selection/location-selection.component';
import { SawnTimberRowComponent } from './modules/sawn-timber/components/sawn-timber-row/sawn-timber-row.component';
import { SawnTimberContainerSelectionComponent } from './modules/sawn-timber/components/sawn-timber-container-selection/sawn-timber-container-selection.component';
import { SawnTimberComponent } from './modules/sawn-timber/components/sawn-timber/sawn-timber.component';
import { SawnTimberMeasurementComponent } from './modules/sawn-timber/components/sawn-timber-measurement/sawn-timber-measurement.component';
import { UnitConversionComponent } from './modules/utility/components/unit-conversion/unit-conversion.component';
import { VolumeEstimateComponent } from './modules/utility/components/volume-estimate/volume-estimate.component';
import { SlabGeneratorComponent } from './modules/utility/components/slab-generator/slab-generator.component';
import { HoppusMeasurementComponent } from './modules/hoppus-measurement/hoppus-measurement/hoppus-measurement.component';
import { SawnTimberListComponent } from './modules/sawn-timber/components/list/sawn-timber-list/sawn-timber-list.component';
import { SawnTimberViewMainComponent } from './modules/sawn-timber/components/sawn-timber-view/sawn-timber-view-main/sawn-timber-view-main.component';
import { DashboardMainComponent } from './modules/dashboard/dashboard-main/dashboard-main.component';
import { AddHoppusMeasurementComponent } from './modules/hoppus/add-hoppus-measurement/add-hoppus-measurement.component';
import { ProgrammaticGuard } from './helper/programmatic.guard';
import { MeasurementCanDeactivateGuard } from './helper/measurement-can-deactivate.guard';
import { PaymentSucessComponent } from './modules/extras/payment-redirection/payment-sucess/payment-sucess.component';
import { PaymentFailureComponent } from './modules/extras/payment-redirection/payment-failure/payment-failure.component';
import { MasterSubscriptionComponent } from './modules/root/master-subscription/master-subscription.component';
import { ViewMasterSubscriptionComponent } from './modules/root/view-master-subscription/view-master-subscription.component';
import { AddUserToExistingCompanyComponent } from './modules/extras/add-user-to-existing-company/add-user-to-existing-company';
import { UserAddedToExistingCompanySuccessfullyMessageComponent } from './modules/extras/user-added-to-existing-company-successfully-message/user-added-to-existing-company-successfully-message.component';
import { TallySheetFourComponent } from './modules/tally-sheet/tally-sheet-four/tally-sheet-four.component';
import { AccountDetailsComponent } from './modules/tally-sheet/account-setup/account-details/account-details.component';
import { AccountSetupComponent } from './modules/tally-sheet/account-setup/account-setup/account-setup.component';
import { ChartComponent } from './modules/tally-sheet/chart/chart.component';
import { SubscribeComponent } from './modules/tally-sheet/subscribe/subscribe.component';
import { DashboardComponent } from './modules/tally-sheet/dashboard/dashboard.component';
import { TallySheetUserRegistrationComponent } from './modules/tally-sheet/user-registration/user-registration.component';
import { ConfirmLogoutDevicesComponent } from './modules/tally-sheet/confirm-logout-devices/confirm-logout-devices.component';
import { NewGuard } from './helper/new-guard.guard';
import { UnauthorizedComponent } from './components/extras/unauthorized/unauthorized.component';
import { RoleGuard } from './helper/role.guard';
import { ViewTallySheetComponent } from './modules/tally-sheet/view-tally-sheet/view-tally-sheet.component';
import { BuyerDetailsResolver } from './modules/extras/resolvers/buyer-details.resolver';
import { SellerDetailsResolver } from './modules/extras/resolvers/seller-details.resolver';
import { LoadingSiteDetailsResolver } from './modules/extras/resolvers/loading-site-details.resolver';
import { AccountDetailsResolver } from './modules/extras/resolvers/account-details.resolver';
import { CompanyDetailsResolver } from './modules/extras/resolvers/company-details.resolver';
import { ProductDetailResolver } from './modules/extras/resolvers/product-detail.resolver';
import { ContainerDetailsResolver } from './modules/extras/resolvers/container-detail.resolver';
import { SubscriptionPackageDetailResolver } from './modules/extras/resolvers/subscription-package-detail.resolver';
import { PaymentDetailResolver } from './modules/extras/resolvers/payment-detail.resolver';
import { TallysheetResolver } from './modules/extras/resolvers/tally-sheet.resolver';
import { ContainerPrivacyComponent } from './modules/container/privacy/container-privacy/container-privacy.component';
import { ContainerViewResolver } from './modules/extras/resolvers/container-view.resolver';
import { BLViewResolver } from './modules/extras/resolvers/bl-view.resolver';
import { PostLoginComponent } from './modules/extras/post-login/post-login.component';

const routes: Routes = [
  {
    path: 'tally-sheet/tally-sheet',
    component: TallySheetFourComponent,

    /* data: {
      roles: [ROLES.ROLE_ROOT],
    }, */
  },
  {
    path: 'dashboard',
    //canActivate: [AppGuardGuard],
    //  component: DashboardMainComponent,
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },

  { path: 'hoppus', component: AddHoppusMeasurementComponent },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login - Lumberlinq',
      header: 'Welcome Back!',
      subHeader: 'View and manage existing buyer profiles',
      animation: 'FirstPage',
    },
  },
  { path: 'login/addUser', component: LoginAddUserToCompanyComponent },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: 'pagenotfound',
    pathMatch: 'full',
    component: PageNotFoundComponent,
  },
  //hello
  {
    path: 'buyers',
    // canActivate: [NewGuard],
    component: BuyersListComponent,
    data: {
      title: 'Buyer Directory',
      header: 'All Buyers',
      subHeader: 'View and manage existing buyer profiles',
      animation: 'FirstPage',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']
    },
    canActivate: [RoleGuard],

    //canActivate: [AppGuardGuard],
  }, //, canDeactivate: [AppGuardGuard] },
  {
    path: 'add/buyer',
    component: BuyerComponent,
    canDeactivate: [DeactivatesGuard],
    data: {
      title: 'Add New Buyer',
      header: 'Create Buyer',
      subHeader: 'Input new buyer details',
      animation: 'SecondPage',
      roles: [ROLES.ROLE_ADMIN]
    },
    canActivate: [RoleGuard],

    //canActivate: [AppGuardGuard],
  },
  {
    path: 'edit/buyer/:id',
    component: BuyerComponent,
    // canDeactivate: [DeactivatesGuard],
    resolve: { buyer: BuyerDetailsResolver },
    data: {
      title: 'Modify Buyer Information',
      header: 'Update Buyer',
      subHeader: 'Edit details of existing buyer profiles',
      roles: [ROLES.ROLE_ADMIN],
    },
    canActivate: [RoleGuard],
    //canActivate: [AppGuardGuard],
  },

  //sellers
  // { path: 'list/sellers', component: SellersListComponent, data: { title: 'List of Sellers', header: 'List Sellers', subHeader: 'Subheader Seller' }, canActivate: [AppGuardGuard] },//, canDeactivate: [AppGuardGuard] },
  {
    path: 'sellers',
    component: SellersListComponent,
    data: {
      title: 'Seller Directory',
      header: 'All Sellers',
      subHeader: 'View and manage existing seller profiles',
      roles: [ROLES.ROLE_ADMIN],
    },
    canActivate: [RoleGuard],

    //canActivate: [AppGuardGuard],
  }, //, canDeactivate: [AppGuardGuard] },
  {
    path: 'add/seller',
    component: SellerComponent,
    canDeactivate: [SellerGuard],
    data: {
      title: 'Add New Seller',
      header: 'Create Seller',
      subHeader: 'Input new seller details',
      roles: [ROLES.ROLE_ADMIN],
    },
    canActivate: [RoleGuard],
    //canActivate: [AppGuardGuard],
  },
  {
    path: 'edit/seller/:id',
    component: SellerComponent,
    resolve: { seller: SellerDetailsResolver },
    canDeactivate: [SellerGuard],
    data: {
      title: 'Modify Seller Information',
      header: 'Update Seller',
      subHeader: 'Edit details of existing seller profiles',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']

    },
    canActivate: [RoleGuard],
    //canActivate: [AppGuardGuard],
  }, //, canDeactivate: [AppGuardGuard] },

  //loading-site
  {
    path: 'loading-sites',
    component: LoadingSiteListComponent,
    data: {
      title: 'Loading Site Directory',
      header: 'All Loading Sites',
      subHeader: 'View and manage existing loading site profiles',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']

    },
    canActivate: [RoleGuard],

    //canActivate: [AppGuardGuard],
  }, //, canDeactivate: [AppGuardGuard] },
  {
    path: 'add/loading-sites',
    component: LoadingSiteComponent,
    canDeactivate: [DeactivatesLoadingSiteGuard],
    data: {
      title: 'Add New Loading Site',
      header: 'Create Loading Site',
      subHeader: 'Input new loading site details',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']

    },
    canActivate: [RoleGuard],

    //canActivate: [AppGuardGuard],
  }, //, canDeactivate: [AppGuardGuard] },
  {
    path: 'edit/loading-sites/:id',
    component: LoadingSiteComponent,
    canDeactivate: [DeactivatesLoadingSiteGuard],
    resolve: { loadingSite: LoadingSiteDetailsResolver },
    data: {
      title: 'Modify Loading Site Information',
      header: 'Update Loading Site',
      subHeader: 'Edit details of existing loading sites',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']

    },
    canActivate: [RoleGuard],

    //canActivate: [AppGuardGuard],
  }, //, canDeactivate: [AppGuardGuard] },

  //product
  {
    path: 'products',
    component: ProductListComponent,
    data: {
      title: 'Product Catalog',
      header: 'All Products',
      subHeader: 'View and manage existing product details',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']

    },
    canActivate: [RoleGuard],

  }, //, canDeactivate: [AppGuardGuard] },
  {
    path: 'add/product',
    component: ProductComponent,
    canDeactivate: [DeactivatesProductGuard],
    data: {
      title: 'Add New Product',
      header: 'Create Product',
      subHeader: 'Input new product details',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']
    },
    canActivate: [RoleGuard],

    //canActivate: [AppGuardGuard],
  }, //, canDeactivate: [AppGuardGuard] },
  {
    path: 'edit/product/:id',
    component: ProductComponent,
    canDeactivate: [DeactivatesProductGuard],
    resolve: { product: ProductDetailResolver },
    data: {
      title: 'Modify Product Information',
      header: 'Update Product',
      subHeader: 'Edit details of existing products',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']
    },
    canActivate: [RoleGuard],

    //canActivate: [AppGuardGuard],
  }, //, canDeactivate: [AppGuardGuard] },

  //product
  {
    path: 'containers',
    component: ContainerListComponent,
    data: {
      title: 'Container Directory',
      header: 'All Containers',
      subHeader: 'View and manage existing container profiles',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']
    },
    canActivate: [RoleGuard],

  }, //, canDeactivate: [AppGuardGuard] },
  {
    path: 'add/container',
    component: ContainerComponent,
    canDeactivate: [DeactivatesContainerGuard],
    data: {
      title: 'Add New Container',
      header: 'Create Container',
      subHeader: 'Input new container details',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']
    },
    canActivate: [RoleGuard],

    //canActivate: [AppGuardGuard],
  }, //, canDeactivate: [AppGuardGuard] },
  {
    path: 'edit/container/:id',
    resolve: { container: ContainerDetailsResolver },
    component: ContainerComponent,
    canDeactivate: [DeactivatesContainerGuard],
    data: {
      title: 'Modify Container Information',
      header: 'Update Container',
      subHeader: 'Edit details of existing containers',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']
    },
    canActivate: [RoleGuard],

    //canActivate: [AppGuardGuard],
  }, //, canDeactivate: [AppGuardGuard] },

  //product
  {
    path: 'measurements',
    component: MeasurementListComponent,
    data: {
      title: 'Measurement Records',
      header: 'All Measurements',
      subHeader: 'View and manage existing measurement details',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']
    },
    canActivate: [RoleGuard],

  }, //, canDeactivate: [AppGuardGuard] },
  {
    path: 'add/measurement',
    component: MeasurementComponent,
    data: {
      title: 'Add New Measurement',
      header: 'Create Measurement',
      subHeader: 'Input new timber log measurement details',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']
    },
    canActivate: [RoleGuard],

  }, //, canDeactivate: [AppGuardGuard] },
  {
    path: 'edit/measurement',
    component: MeasurementComponent,
    data: {
      title: 'Modify Measurement Information',
      header: 'Update Measurement',
      subHeader: 'Edit details of existing measurements',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']
    },
    canActivate: [RoleGuard],

  }, //, canDeactivate: [AppGuardGuard] },
  {
    path: 'view/measurement/:containerUUID',
    component: MeasurementViewComponent,
    data: {
      title: 'Measurement Records',
      header: 'All Measurements',
      subHeader: 'View and manage existing measurement details',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']
    },
    canActivate: [RoleGuard],
    canDeactivate: [DeactivateBilOfLadingGuard],
    //canActivate: [AppGuardGuard],
  },

  //bill-of-lading-list
  {
    path: 'bill-of-ladings',
    component: BillOfLadingListComponent,
    data: {
      title: 'List of Bill of Lading',
      header: 'List Bill of Lading',
      subHeader: 'Subheader Bill of Lading',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']
    },
    canActivate: [RoleGuard],
    canDeactivate: [DeactivateBilOfLadingGuard],
    //canActivate: [AppGuardGuard],
  },
  {
    path: 'add/bill-of-lading',
    component: BillOfLadingComponent,
    data: {
      title: 'Create New Bill of Lading',
      header: 'Create B/L',
      subHeader: 'Prepare new bill of lading',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']
    },
    canActivate: [RoleGuard],
    canDeactivate: [DeactivateBilOfLadingGuard],
    //canActivate: [AppGuardGuard],
  },
  {
    path: 'edit/bill-of-lading',
    component: BillOfLadingComponent,
    data: {
      title: 'Modify Bill of Lading Information',
      header: 'Update B/L',
      subHeader: 'Edit details of existing bills of lading',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']
    },
    canActivate: [RoleGuard],
    canDeactivate: [DeactivateBilOfLadingGuard],
    //canActivate: [AppGuardGuard],
  },
  {
    path: 'view/bill-of-lading/:blUUID',
    component: BillOfLadingViewComponent,
    data: {
      title: 'Bill of Lading Directory',
      header: 'All Bills of Lading',
      subHeader: 'View and manage existing bills of lading',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']
    },
    canActivate: [RoleGuard],
    canDeactivate: [DeactivateBilOfLadingGuard],
    //canActivate: [AppGuardGuard],
  },

  {
    path: 'add/user',
    component: AddUserToCompanyComponent,
    data: {
      title: 'Add New User',
      header: 'Create User',
      subHeader: 'Input new user details to the system',
      roles: [ROLES.ROLE_ADMIN]
    },
    canActivate: [RoleGuard],
    canDeactivate: [AddUserDeactivateGuard],
    //canActivate: [AppGuardGuard],
  },
  {
    path: 'manage/user',
    component: ManageUsersComponent,
    data: {
      title: 'User Management',
      header: 'Manage User',
      subHeader: 'View and administer user profiles',
      roles: [ROLES.ROLE_ADMIN],
    },
    canActivate: [RoleGuard],
    //canActivate: [AppGuardGuard],
  },

  {
    path: 'public/view/measurement/:containerUUID',
    component: MeasurementViewComponent,
    data: {
      title: 'Measurement Records',
      header: 'View Measurements',
      subHeader: 'Review measurement records for the selected container',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']
    },
    canActivate: [RoleGuard],
  },
  {
    path: 'public/view/bill-of-ladings/:blUUID',
    component: BillOfLadingViewComponent,
    data: {
      title: 'Bill of Lading Details',
      header: 'View Bill of Lading',
      subHeader: 'View details of the selected Bill of Lading',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']
    },
    canActivate: [RoleGuard],
  },
  { path: 'error/forbidden', component: ForbiddenComponent },
  { path: 'restrictedPage', component: RestrictedPageComponent },
  {
    path: 'register',
    component: UserRegistrationComponent,
    data: {
      title: 'Update Your Profile Information',
      header: 'Edit Profile',
      subHeader: 'Modify your personal user details',
      roles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_REGISTRATION']
    },
    canActivate: [RoleGuard],
  },
  {
    path: 'company',
    component: CompanyComponent,
    data: {
      roles: [ROLES.ROLE_ADMIN],
      title: 'Company Profile Management',
      header: 'Your Company Profile',
      subHeader: "View or edit your company's profile details",
    },
    canActivate: [RoleGuard],
    //canActivate: [AppGuardGuard],
  },
  { path: 'error/unusual', component: UnidentifiedErrorComponent },
  {
    path: 'registration',
    component: RegistrationComponent,
    children: [
      { path: '', redirectTo: 'register-user', pathMatch: 'full' },
      {
        path: 'register-user',
        component: UserRegistrationComponent,
        data: {
          roles: [ROLES.ROLE_ADMIN],
          title: 'Create New User Account',
          header: 'Register User',
          subHeader: 'Add new user details to the system',
        },
      },
      {
        path: 'add-user-to-exisiting-company',
        component: AddUserToExistingCompanyComponent,
        data: {
          roles: [ROLES.ROLE_ADMIN],
          title: 'Add New User to Existing Company',
          header: 'Register User',
          subHeader: 'Add new user details to exisiting company',
        },
      },

      { path: 'subscription', component: SubscriptionComponent },
      {
        path: 'company',
        component: CompanyComponent,
        data: {
          roles: [ROLES.ROLE_ADMIN],
          title: 'Company Profile Management',
          header: 'Your Company Profile',
          subHeader: "View or edit your company's profile details",
        },
      },
      {
        path: 'register-company',
        component: CompanyComponent,
        data: {
          roles: [ROLES.ROLE_ADMIN],
          title: 'Company Profile Management',
          header: 'Your Company Profile',
          subHeader: "View or edit your company's profile details",
        },
      },
      { path: 'confirmation', component: ConfirmationComponent },
    ],
  },
  {
    path: 'subscriptions',
    //canActivate: [AppGuardGuard],
    data: {
      roles: [ROLES.ROLE_ADMIN],
      title: 'Manage Your Subscription',
      header: 'Subscription',
      subHeader: 'Select or update your subscription plan',
    },
    component: SubscriptionViewMainComponent,
    children: [
      {
        path: 'subscription-management',
        component: SubscriptionManagementComponent,
        data: {
          title: 'View Subscriptions',
          header: 'View Subscription',
          subHeader: 'Subheader Subscription',
        },
      },
      {
        path: 'subscription-opted',
        component: SubscriptionOptedComponent,
        data: {
          title: 'View Subscriptions',
          header: 'View Subscription',
          subHeader: 'Subheader Subscription',
        },
      },
      {
        path: 'subscription-features',
        component: SubscriptionOptedFeaturesComponent,
        data: {
          title: 'View Features',
          header: 'View Features',
          subHeader: 'Subheader Features',
        },
      },
      {
        path: 'subscription-history',
        component: TransactionHistoryComponent,
        data: {
          title: 'View Transactions',
          header: 'View Transactions',
          subHeader: 'Subheader Transactions',
        },
      },
    ],
  },
  { path: 'error/notapproved', component: UserNotApprovedComponent },
  { path: 'error/alreadyLogedIn', component: UserAlreadyLoginComponent },

  /* { path: 'sawn', component: SawnTimberComponent },
  { path: 'sawn-row', component: SawnTimberRowComponent },
  { path: 'sawn-container', component: SawnTimberContainerSelectionComponent }, */
  {
    path: 'sawnlist',
    //canActivate: [AppGuardGuard],
    component: SawnTimberListComponent,
  },
  {
    path: 'timber',
    // path: 'sawn',
    //canActivate: [AppGuardGuard],
    component: SawnTimberComponent,
    children: [
      { path: '', redirectTo: 'container', pathMatch: 'full' }, // This line added
      {
        path: 'container',
        component: SawnTimberContainerSelectionComponent,
        data: {
          title: 'Select Container',
        },
      },
      {
        path: 'pl',
        component: SawnTimberMeasurementComponent,
        data: {
          title: 'Add New Measurement',
        },
        canActivate: [ProgrammaticGuard],
        canDeactivate: [MeasurementCanDeactivateGuard],
      },
      {
        path: 'hoppus',
        component: HoppusMeasurementComponent,
        data: {
          title: 'Add New Measurement',
          header: 'Create Measurement',
          subHeader: 'Input new timber log measurement details',
        },
      },
    ],
  },
  {
    path: 'view-sawn-timber',
    //canActivate: [AppGuardGuard],
    component: SawnTimberViewMainComponent,
  },
  {
    path: 'public/view/sawn-timber/:containerUUID',
    component: SawnTimberViewMainComponent,
  },
  {
    path: 'utility/unit-conversion',
    //canActivate: [AppGuardGuard],
    component: UnitConversionComponent,
    data: {
      title: 'Unit Conversion Tool',
      header: 'Convert Units',
      subHeader: 'Enter your measurements and select units for conversion',
    },
  },
  {
    path: 'utility/volume-estimates',
    //canActivate: [AppGuardGuard],
    component: VolumeEstimateComponent,
    data: {
      title: 'Cost Estimation Tool',
      header: 'Estimate Your Costs',
      subHeader: 'Input your parameters and calculate the projected cost',
    },
  },
  {
    path: 'utility/slab-generator',
    //canActivate: [AppGuardGuard],
    component: SlabGeneratorComponent,
    data: {
      title: 'Slab Pricing Generator',
      header: 'Generate Slab Prices',
      subHeader:
        'Input slab dimensions to generate price estimations per size difference',
    },
  },
  {
    path: 'payment/success',
    component: PaymentSucessComponent,
  },
  {
    path: 'user-added-to-existing-company/success',
    component: UserAddedToExistingCompanySuccessfullyMessageComponent,
  },
  {
    path: 'payment/failure',
    component: PaymentFailureComponent,
  },
  {
    path: 'root/master-subscription',
    component: MasterSubscriptionComponent,
    data: {
      roles: [ROLES.ROLE_ROOT],
    },
  },
  {
    path: 'root/master-subscription/:id',
    component: MasterSubscriptionComponent,
    data: {
      roles: [ROLES.ROLE_ROOT],
    },
  },
  {
    path: 'container-privacy',
    component: ContainerPrivacyComponent,
    data: {
      title: 'Container Privacy Settings',
      header: 'Manage Container Privacy Configurations',
      subHeader: 'View and customize privacy levels for container fields'
    },
  },
  {
    path: 'root/master-subscriptions',
    component: ViewMasterSubscriptionComponent,
    data: {
      roles: [ROLES.ROLE_ROOT],
    },
  },
  { path: 'locations', component: LocationSelectionComponent },

  {
    path: 'account-setup',
    component: AccountSetupComponent,
    children: [
      { path: '', redirectTo: 'account-details', pathMatch: 'full' },  // Redirect to account-details when account-setup is entered
      {
        path: 'account-details', loadComponent: () => import('./modules/tally-sheet/account-setup/account-details/account-details.component').then(m => m.AccountDetailsComponent),
        resolve: { accountDetails: AccountDetailsResolver }
      },
      {
        path: 'company-details', loadComponent: () => import('./modules/tally-sheet/account-setup/company-details/company-details.component').then(m => m.CompanyDetailsComponent),
        resolve: { companyDetails: CompanyDetailsResolver }
      },
      {
        path: 'subscription', loadComponent: () => import('./modules/tally-sheet/account-setup/subscription-package/subscription-package.component').then(m => m.SubscriptionPackageComponent),
        resolve: { subscriptionPackage: SubscriptionPackageDetailResolver }
      },
      {
        path: 'payment', loadComponent: () => import('./modules/tally-sheet/account-setup/payment/payment.component').then(m => m.PaymentComponent),
        resolve: { payment: PaymentDetailResolver }
      },
      { path: 'confirmation', loadComponent: () => import('./modules/tally-sheet/account-setup/confirm-account-setup/confirm-account-setup.component').then(m => m.ConfirmAccountSetupComponent) }
    ]
  },
  { path: 'tally-sheet/account-details', component: AccountDetailsComponent },
  { path: 'tally-sheet/chart', component: ChartComponent },
  { path: 'tally-sheet/register', component: TallySheetUserRegistrationComponent },
  { path: 'tally-sheet/subscribe', component: SubscribeComponent },
  { path: 'tally-sheet/dashboard', component: DashboardComponent },
  { path: 'tally-sheet/auth/confirm-login', component: ConfirmLogoutDevicesComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  {
    path: 'tally-sheets',
    component: ViewTallySheetComponent,
    data: {
      title: 'Tallysheet Records',
      header: 'All Tallysheet',
      subHeader: 'View and manage existing tallysheet details',
    },

    //canActivate: [AppGuardGuard],
  },
  {
    path: 'container-view/:id', loadChildren: () => import('./container-view/container-view.module').then(m => m.ContainerViewModule),
    resolve: { containerData: ContainerViewResolver }, // Add the resolver
  },
  {
    path: 'bl-view/:id', loadChildren: () => import('./container-view/container-view.module').then(m => m.ContainerViewModule),
    resolve: { containerData: BLViewResolver }, // Add the resolver
  },
  { path: 'post-login', component: PostLoginComponent },






  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
export { routes }; // Export the routes array