import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  BehaviorSubject,
  Subscription,
  fromEvent,
  debounceTime,
  filter,
  Observable,
} from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';
import { UtilService } from 'src/app/services/util.service';
import { Container } from '../../container/model/container';
import { ContainerService } from '../../container/services/container.service';
import { Measurement } from '../../measurement/model/measurement';
import { SawnTimberService } from '../../sawn-timber/services/sawn-timber.service';
import { AddHoppusMeasurementv2Service } from '../services/add-hoppus-measurementv2.service';
import { VIEW_MODE } from '../../bill-of-lading/bill-of-lading-main/bill-of-lading-main.component';
import { Formula } from '../../product/product/product.component';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-view-hoppus-measurement',
  templateUrl: './view-hoppus-measurement.component.html',
  styleUrls: ['./view-hoppus-measurement.component.css'],
  standalone: true,
  imports: [SHARED_MODULES],
})
export class ViewHoppusMeasurementComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() data: any;
  isLoading = false; // <-- declare your isLoading variable here
  isLoading1 = new BehaviorSubject<boolean>(false);
  tableStyleOptions: any;
  // define loadData property which can hold a function reference
  loadData!: () => void;
  @Input() viewMode!: VIEW_MODE;
  @Input() containerIdInput!: any; // = CONTAINER_ID; //////
  @Input() formula!: Formula;
  VIEW_MODE = VIEW_MODE;

  dummyArrayHorizontalGrid = ['Helo'];
  container!: Container;
  measurements: Measurement[] = [];
  //data: any[] = [];

  totalRecords: number = 0; // total number of records in the dataset
  limit: number = 50; // number of records to fetch per request
  offset: number = 0; // current offset in the dataset
  scrollSubscription: Subscription | undefined; // subscription to scroll event
  @Input() containerId!: any;
  @Input() containerUUID!: any;

  tableStyle!: any;
  isMobile!: boolean;
  cols!: { field: string; header: string }[];
  isPublic = false;
  showGrossLength: boolean = true;
  showGrossGirth: boolean = true;
  lengthAllowance!: number;
  girthAllowance!: number;
  @ViewChild('scrollable') scrollable!: ElementRef;
  showTopButton: boolean = true;
  showBottomButton: boolean = true;

  constructor(
    private httpClient: HttpClient,
    private containerService: ContainerService,
    private router: Router,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private encryptedStorageService: EncryptedStorageService,
    private sawnTimberService: SawnTimberService,
    private hoppuseService: AddHoppusMeasurementv2Service,
    private renderer: Renderer2
  ) {
    console.log(`SawnTimberViewListComponent `);
    // this.isMobile = this.utilService.isMobile();
  }

  /* scrollCallback(event: Event) {
    const element = event.target as HTMLElement;
    // if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    if (element.scrollHeight - element.scrollTop <= element.clientHeight + 60) {
      this.offset += this.limit;
      this.loadData = this.loadPublicData.bind(this);
      this.loadData();
      
    }
  } */
  scrollCallback(event: Event) {
    const element = event.target as HTMLElement;

    // Check if all records have been fetched
    if (this.offset >= this.totalRecords) {
        // If all records are loaded, do not proceed further
        return;
    }

    // Check if the scroll position is near the bottom of the element
    if (element.scrollHeight - element.scrollTop <= element.clientHeight + 60) {
        this.offset += this.limit;
        this.loadData = this.loadPublicData.bind(this);
        this.loadData();
    }
}

  ngAfterViewInit(): void {
    this.scrollable.nativeElement.addEventListener(
      'scroll',
      this.scrollCallback.bind(this)
    );
    // this.checkScrollPosition();

    this.initColumns();

    this.isPublic = this.router.url.includes('/public/view');
    this.loadData = this.loadPublicData.bind(this);
    this.loadData();
    this.subscribeToScrollEvent(this.loadData);
   /*  if (this.isPublic) {
      this.loadData = this.loadPublicData.bind(this);
      this.loadData();
      this.subscribeToScrollEvent(this.loadData);
    } else {
      this.encryptedStorageService
        .getEncryptedDataFromStorage(AppConstants.CONTAINER)
        .subscribe(
          (response: any) => {
            this.containerId = response.containerId;
            this.loadData = this.loadPrivateData.bind(this, this.containerId);
            this.loadData();
            this.subscribeToScrollEvent(this.loadData);
          },
          (error: any) => {
            this.isLoading = false; // <-- set loading to false if there's an error
          },
          () => {
            this.isLoading = false; // <-- set loading to false if there's an error
          }
        );
    } */
    this.tableStyle = this.utilService.getTableSize();
    this.isLoading = false; // <-- set loading to false once the entire init process is completed
  }

  initColumns() {
    this.cols = [
      { field: 'grossLength', header: 'Gross Length' },
      // { field: 'lengthAllowance', header: 'Length Allowance' },
      { field: 'netLength', header: 'Net Length' },
      // { field: 'girthAllowance', header: 'Girth Allowance' },
      { field: 'grossGirth', header: 'Gross Girth' },
      { field: 'netGirth', header: 'Net Girth' },
      { field: 'netVolumeCbm', header: 'Net Volume Cbm' },
      { field: 'grossVolumeCbm', header: 'Gross Volume Cbm' },
      { field: 'netVolumeCft', header: 'Net Volume Cft' },
      { field: 'grossVolumeCft', header: 'Gross Volume Cft' },
    ];
  }

  checkConditions(measurements: any[]) {
    let count = 0;
    let listSize = measurements.length;

    this.measurements.forEach((item) => {
      console.log(`\n\n ${JSON.stringify(item)}`);
      if (item.lengthAllowance === 0) {
        this.lengthAllowance = 0;
        count++;
      }

      if (item.girthAllowance === 0) {
        this.girthAllowance = 0;
      }
    });

    this.measurements.forEach((item) => {
      if (item.lengthAllowance === 0 && item.girthAllowance === 0) {
        count++;
      }
    });

    // Check if the condition is met for any 5 from list, or if the list size is <=5 and the condition is met for all.
    this.showGrossLength = !measurements.some(
      (item) => item.lengthAllowance === 0
    );
    this.showGrossGirth = !measurements.some(
      (item) => item.girthAllowance === 0
    );
  }

  ngOnInit(): void {
    this.isLoading = true; // <-- set loading to true before making requests
  }

  subscribeToScrollEvent(callback: () => void) {
    this.scrollSubscription = fromEvent(window, 'scroll')
      .pipe(
        debounceTime(300),
        filter(
          () =>
            !this.isLoading &&
            this.offset < this.totalRecords &&
            this.isScrolledToBottom()
        )
      )
      .subscribe(() => {
        this.offset += this.limit;
        callback();
      });
  }

  loadInitialData() {
    this.isLoading = true; // <-- set loading to true before making requests
    if (this.loadData) {
      this.loadData();
    }
    this.isLoading = false; // <-- set loading to false once data is loaded
  }

  createScrollSubscription() {
    this.scrollSubscription = fromEvent(window, 'scroll')
      .pipe(
        debounceTime(300),
        filter(
          () =>
            !this.isLoading &&
            this.offset < this.totalRecords &&
            this.isScrolledToBottom()
        )
      )
      .subscribe(() => {
        this.offset += this.limit;
        if (this.loadData) {
          this.loadData();
        }
      });
  }

  loadPrivateData(containerId: number) {
    this.loadDataFromService(
      this.hoppuseService.getLazyLoadingVirtualPackinglist(
        containerId,
        this.offset,
        this.limit
      )
    );
  }

  loadPublicData() {
    this.loadDataFromService(
      this.hoppuseService.getPublicLazyLoadingHoppusTimberVirtualPackinglist(
        this.containerUUID,
        this.offset,
        this.limit
      )
    );
  }

  loadDataFromService(serviceCall$: Observable<any>) {
    this.isLoading = true; // <-- set loading to true before making requests
    this.isLoading1.next(true); // use next to change the value

    console.log(`isLoading to True; value is ${this.isLoading}`);
    setTimeout(() => {
      serviceCall$.subscribe(
        (response) => {
          this.measurements = this.measurements.concat(response.data);
          this.totalRecords = response.totalRecords;
          this.isLoading = false;
          // console.log(`Set isFashion to False`);
          this.checkConditions(this.measurements);
          this.isLoading1.next(false); // use next to change the value

          console.log(`isLoading to False; value is ${this.isLoading}`);
        },
        (error) => {
         /*  console.log(error);

          // when public view bl, condition
          this.loadDataFromService(
            this.hoppuseService.getLazyLoadingVirtualPackinglist(
              this.containerUUID,
              this.offset,
              this.limit
            )
          ); */
          this.isLoading = false;
          this.isLoading1.next(false); // use next to change the value
        }
      );
    }, 200);
  }
  dummyRows = Array(10).fill(0);

  isScrolledToBottom(): boolean {
    const windowHeight =
      'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const documentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    return windowBottom >= documentHeight;
  }

  ngOnDestroy(): void {}
  
  /* @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = this.utilService.isMobile();
    console.log(`is mobile ${this.utilService.isMobile()}`);
    this.tableStyle = this.utilService.getTableSize();
  } */

  scrollToTop() {
    // this.renderer.setProperty(this.scrollable.nativeElement, 'scrollTop', 0);
    this.smoothScroll(this.scrollable.nativeElement, 0, 600);
  }

  scrollToBottom() {
    const target =
      this.scrollable.nativeElement.scrollHeight -
      this.scrollable.nativeElement.offsetHeight;
    this.smoothScroll(this.scrollable.nativeElement, target, 600);

    /*  const scrollHeight = this.scrollable.nativeElement.scrollHeight;
    const offsetHeight = this.scrollable.nativeElement.offsetHeight;
    this.renderer.setProperty(
      this.scrollable.nativeElement,
      'scrollTop',
      scrollHeight - offsetHeight
    ); */
  }

  smoothScroll(element: any, target: number, duration: number) {
    const start = element.scrollTop;
    const change = target - start;
    let startTime: number | null = null;

    const animateScroll = (currentTime: number) => {
      if (!startTime) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1);

      const easeInOutQuad = (t: number) =>
        t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      const val = easeInOutQuad(progress);

      element.scrollTop = start + change * val;

      if (timeElapsed < duration) {
        window.requestAnimationFrame(animateScroll);
      }
    };

    window.requestAnimationFrame(animateScroll);
  }
}
