<div class="card">

    <div class="field grid">
        <label for="packageName" class="col-12 mb-2 md:col-2 md:mb-0">Package</label>
        <div class="col-12 md:col-10">
            <p>
                {{ selectedPackage?.name?? '' }}
            </p>
        </div>

        <label for="paymentCycle" class="col-12 mb-2 md:col-2 md:mb-0">Payment Cycle</label>
        <div class="col-12 md:col-10">
            <!-- <p-dropdown [options]="paymentCycle" optionLabel="name" placeholder="Select a Payment Cycle"
                (onChange)="onPaymentCycle($event)"></p-dropdown> -->



            <!-- 
            {{selectedPackagePricingPlans | json}} 
            <br/><br/><br/><br/>
        -----    {{selectedPackagePricingPlans | json}}  ---- -->  
            <p-dropdown [options]="selectedPackagePricingPlans" [(ngModel)]="selectedPricingPackageView"
                optionLabel="offerPrice" placeholder="Select a Payment Cycle" [showClear]="true"
                placeholder="Select a Payment Cycle" (onChange)="onDurationSelected($event)">
                --- {{ selectedPricingPackageView | json }} ---
                <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" >
                        <!-- {{ selectedPricingPackageView | json }} -->
                        {{ selectedPricingPackageView.offerPrice | currency: selectedPricingPackageView.isoCurrency:'symbol':'1.2-2' }}/ 
                        {{ selectedPricingPackageView.duration }}
                    </div>
                </ng-template>

                 
                 
                <ng-template let-plans pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <p> {{ plans.duration }}</p>
                        <p-chip>
                            {{ plans.offerPrice | currency: plans.isoCurrency:'symbol':'1.2-2' }}
                        </p-chip>
                        <div>{{ plans.price | currency: plans.isoCurrency:'symbol':'1.2-2' }}</div>
                    </div>
                    <div>
                        Save {{ plans.amountSaved }}
                    </div>
                </ng-template>
            </p-dropdown>




        </div>
        <label for="paymentCycle" class="pt-2 col-12 mb-2 md:col-2 md:mb-0">Auto Debit
            <i [pTooltip]="autoDebitTooltip" class="pl-2" tooltipPosition="top">(?)</i>
        </label>
        <div class="pt-2 col-12 md:col-10">
            <p-checkbox [(ngModel)]="autoDebit" [binary]="true" inputId="binary"
                (onChange)="onAutoDebit($event)" (onClear)="clearMessage()" ></p-checkbox>
        </div>
    </div>
    <div *ngIf="obligationPaymentMessage">
        <div
            class="shadow-2 pr-5 surface-card text-center p-0 border-round-sm h-4rem w-full flex align-items-center justify-content-end font-semibold">
            <p>
                {{ this.obligationPaymentMessage }}
                <!-- {{selectedPackage?.subscriptionPrices[0]?.offerPrice| currency:
                    selectedCurrency  }} -->
            </p>
        </div>
    </div>
</div>