export class CreateSubscriptionOrder {

    //subscriptionTypeId!: number;



    //currency!: string;

    //  durationUnit!: any;
    billingId!: number;
    autoDebit!: boolean;
    subscriptionId!: number;
    currencyId!: number;
    subscriptionPricingsId!: number;
    hasPromoApplied = false;
    promoCodeId!: number;
    promoCode!: string;
    billingCompanyName!: string;
    billingPhone!: string;
    billingAddress!:string;
    billingCountry!: string;
    billingCity!: string;
    billingPincode!: string;
    billingState!: string;
    billingGst!: string;
    paymentGateway!:string;
}

export enum PaymentGateway{
    STRIPE,
    PHONE_PE
}