<router-outlet></router-outlet>
<div class="custom-card">
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [style]="{ 'width': '70vw','min-width': '280px','max-width': '600px' }"></p-confirmDialog>
    <div class="pb-3">
        <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
    </div>
    <p-card [header]="formHeader" [subheader]="formSubHeader" class="w-full">
        <p-speedDial [model]="sellerSpeedDialItems" direction="up-left" type="quarter-circle" [radius]="120"
            class="ml-5 my-speed-dial" [rotateAnimation]="true"></p-speedDial>
        <div>
            <p-table #dt [lazy]="true" [value]="sellers" (onLazyLoad)="sortSellers($event)" [rowHover]="true"
                [customSort]="true" sortMode="multiple" dataKey="supplierId"
                [globalFilterFields]="['name','contactPerson','ghanaId','reference']" [showCurrentPageReport]="true"
                [rows]="rows" [rowsPerPageOptions]="rowsPerPageOptions" [paginator]="true" [first]="first"
                currentPageReportTemplate="Showing {{first}} to {{last}} of {{totalRecords}} entries"
                [totalRecords]="totalRecords" [columns]="cols" [(selection)]="selectedSellers"
                [tableStyle]="tableStyleOptions" (onRowSelect)="onRowSelect($event)" [loading]="loading"
                paginatorDropdownAppendTo="body">


                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-between">

                        <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" class="multiselectfields"
                            selectedItemsLabel="{0} Selected" placeholder="Choose Columns" defaultLabel="Choose Columns"
                            [maxSelectedLabels]="2"> </p-multiSelect>
                        <span class="p-input-icon-left">

                            <div class="p-inputgroup">
                                <input pInputText type="text" (input)="globalFilter($event,dt)" #globalFiterInput
                                    class="searchglobally" placeholder="Search keyword" />
                                <button type="button" pButton icon="pi pi-filter-slash" styleClass="p-button-warn"
                                    (click)="clearAllFilter(dt,globalFiterInput)"></button>
                            </div>
                        </span>
                    </div>

                </ng-template>

                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th>
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="name" p-toggleableColumn>Name
                            <p-columnFilter type="text" field="name" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                            <p-sortIcon field="name"></p-sortIcon>
                        </th>
                        <th pSortableColumn="contactPerson" *ngIf="_selectedColumns!.includes('Contact Person')">Contact
                            Person
                            <p-columnFilter type="text" field="contactPerson" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                            <p-sortIcon field="contactPerson"></p-sortIcon>
                        </th>
                        <th *ngIf="_selectedColumns!.includes('Tax ID')">Tax Id <p-columnFilter type="text"
                                field="ghanaId" display="menu" matchMode="contains" [showMatchModes]="false"
                                [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                        </th>
                        <th *ngIf="_selectedColumns!.includes('Reference')">Reference
                            <p-columnFilter type="text" field="Reference" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                        </th>
                        <th *ngIf="_selectedColumns!.includes('Phone')">Phone</th>
                        <th *ngIf="_selectedColumns!.includes('Notes')">Notes</th>
                        <th *ngIf="_selectedColumns!.includes('City')">City <p-columnFilter type="text" field="city"
                                display="menu" matchMode="contains" [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                        </th>
                        <th *ngIf="_selectedColumns!.includes('State')">State <p-columnFilter type="text" field="state"
                                display="menu" matchMode="contains" [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                        </th>
                        <th *ngIf="_selectedColumns!.includes('Country')">Country
                            <p-columnFilter type="text" field="country" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-seller let-columns="columns">
                    <tr [pSelectableRow]="columns" (click)="onRowClick(seller)" p-selectable-row>
                        <td (click)="$event.stopPropagation()">
                            <p-tableCheckbox [value]="seller" (click)="$event.stopPropagation()"></p-tableCheckbox>
                        </td>
                        <td> {{seller.name}}</td>
                        <td *ngIf="_selectedColumns!.includes('Contact Person')">{{seller.contactPerson}}</td>
                        <td *ngIf="_selectedColumns!.includes('Tax ID')">{{seller.ghanaId}}</td>
                        <td *ngIf="_selectedColumns!.includes('Reference')">{{seller.reference}}</td>
                        <td *ngIf="_selectedColumns!.includes('Phone')"> {{seller.phone1}}</td>
                        <td *ngIf="_selectedColumns!.includes('Notes')"> {{seller.notes}}</td>
                        <td *ngIf="_selectedColumns!.includes('City')">{{seller?.location?.city?.name?? ''}}</td>
                        <td *ngIf="_selectedColumns!.includes('State')">{{seller?.location?.state?.name?? ''}}</td>
                        <td *ngIf="_selectedColumns!.includes('Country')">{{seller?.location?.country?.countryName??
                            ''}}
                        </td>
                    </tr>
                </ng-template>


                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="(_selectedColumns ? _selectedColumns.length + 2 : 6)" class="text-center">No
                            records found.</td>

                    </tr>
                </ng-template>

                <ng-template pTemplate="loadingbody" let-columns="columns">
                    <tr style="height:46px">
                        <td>
                            <p-skeleton></p-skeleton>
                        </td>
                        <td>
                            <p-skeleton></p-skeleton>
                        </td>

                        <td *ngIf="_selectedColumns!.includes('Contact Person')">
                            <p-skeleton></p-skeleton>
                        </td>
                        <td *ngIf="_selectedColumns!.includes('Tax ID')">
                            <p-skeleton></p-skeleton>
                        </td>
                        <td *ngIf="_selectedColumns!.includes('Reference')">
                            <p-skeleton></p-skeleton>
                        </td>
                        <td *ngIf="_selectedColumns!.includes('Phone')">
                            <p-skeleton></p-skeleton>
                        </td>
                        <td *ngIf="_selectedColumns!.includes('Notes')">
                            <p-skeleton></p-skeleton>
                        </td>
                        <td *ngIf="_selectedColumns!.includes('City')">
                            <p-skeleton></p-skeleton>
                        </td>
                        <td *ngIf="_selectedColumns!.includes('State')">
                            <p-skeleton></p-skeleton>
                        </td>
                        <td *ngIf="_selectedColumns!.includes('Country')">
                            <p-skeleton></p-skeleton>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-card>

    <p-sidebar [(visible)]="sellerSidebarVisible" position="right" [style]="{'width': '50vw'}"
        (onHide)="setPageTitle()">
        <app-seller *ngIf="sellerSidebarVisible" [showBreadcrumb]="false" [isSubmittedFromSidebar]="true"
            (supplierSubmit)="onSellerSubmit($event)" titleFromSidebar="Add New Seller"
            headerFromSidebar="Create Seller" subHeaderFromSidebar="Input new seller details"></app-seller>
    </p-sidebar>
</div>