import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from '../../model/subscription';
import { SubscriptionPackagesView } from '../../model/subscription-packages-view';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';

@Component({
  selector: 'app-subscription-package-selection',
  templateUrl: './subscription-package-selection.component.html',
  styleUrls: ['./subscription-package-selection.component.css'],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class SubscriptionPackageSelectionComponent
  implements AfterViewInit, OnInit
{
  selectedPackage!: SubscriptionPackagesView | null;
  @Input() packagesList!: SubscriptionPackagesView[]; // assuming the package type is 'any' for this example
  @Output() packageSelected = new EventEmitter<any>();
  @Output() currencySelected = new EventEmitter<any>();
  @Input() selectedCurrency!: string; // assuming the package type is 'any' for this example
  selectedCurrencyDropDown!: any; // = { name: this.selectedCurrency };  // assuming the package type is 'any' for this example

  currencies = [{ name: 'INR' }, { name: 'USD' }];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngAfterViewInit(): void {}
  sortPackagesListDesc(): void {
    this.packagesList.sort((a, b) => b.sortOrder - a.sortOrder);
}

  ngOnInit(): void {
    this.sortPackagesListDesc();
    const currency = this.activatedRoute.snapshot.queryParamMap.get('currency');
    console.log(`ngOnInit queryParamMap  ${currency}`);
    this.selectedCurrencyDropDown = { name: currency };
  }

  selectPackage(package1: any) {
    console.log(`Selected Package is ${JSON.stringify(package1)}`);
    this.selectedPackage = package1;
    this.packageSelected.emit(this.selectedPackage); // Emitting the event
  }

  onCurrencyChange(event: any) {
    const currency = event.value.name;
    console.log(`onCurrencyChange ${JSON.stringify(event.value.name)}`);
    this.selectedPackage = null;
    this.currencySelected.emit(event.value.name);
    this.updateUrlParams(event.value.name);
    //this.ngOnInit();
    /* this.router.navigateByUrl('/dummy', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/registration/subscription'], { queryParams: { currency: event.value.name } });
    });  */
    let refreshWithUrl = '';
    let fragment = '';
    const currentUrl = this.router.url;

    if (currentUrl.startsWith('/subscriptions')) {
      refreshWithUrl = '/subscriptions';
      fragment = 'tab4';
    } else if (currentUrl.startsWith('/registration/subscription')) {
      refreshWithUrl = '/registration/subscription';
    }

    this.router.navigate([refreshWithUrl], {
      fragment: fragment,
      queryParams: { currency: event.value.name },
    });
    //location.reload();  // Reloads the page when a new route is loaded
  }

  updateUrlParams(currency: string) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        currency: currency,
      },
      queryParamsHandling: 'merge', // merge with the current queryParams
      replaceUrl: true, // this will replace the current URL in browser history instead of pushing a new one
    });
  }
}
