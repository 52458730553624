import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { CustomEmailValidator } from 'src/app/classes/custom-email.validator';
import { NameCheckValidator } from 'src/app/validators/name-check-validator';
import { City } from 'src/app/commons/models/city';
import { PopulateDropdownService } from 'src/app/services/populate-dropdown.service';
import { SellerService } from '../services/seller.service';
import { ConfirmationService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SellerSharedDataService } from '../services/seller-shared-data.service';
import { Title } from '@angular/platform-browser';
import { Seller } from '../model/seller';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { Location } from '../../commons/location-selection/model/location';
import { LocationSelectionService } from '../../commons/location-selection/service/location-selection.service';
import { ConfirmDeleteCommonService } from 'src/app/commons/services/confirm-delete-common.service';
import { ResetCommonService } from 'src/app/commons/services/reset-common.service';
import { LocationSelectionComponent } from '../../commons/location-selection/location-selection.component';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-seller',
  templateUrl: './seller.component.html',
  styleUrls: ['./seller.component.css'],
  providers: [ToastMessagesService],
  standalone: true,
  imports: [SHARED_MODULES, LocationSelectionComponent]
})
export class SellerComponent implements OnInit, AfterViewInit {
  @ViewChild(LocationSelectionComponent, { static: false })
  locationSelectionComponent!: LocationSelectionComponent;
  @Input() isFromContainerSidebar!: boolean;
  blockedDocument = false;

  @Input() showBreadcrumb: boolean = true;
  @Input() isSubmittedFromSidebar: boolean = false;
  @Output() supplierSubmit: EventEmitter<any> = new EventEmitter();

  @Input() titleFromSidebar!: string;
  @Input() headerFromSidebar!: string;
  @Input() subHeaderFromSidebar!: string;

  editMode = false;
  isSubmitting = false;
  addEditButton = 'Save';
  sellerEditData!: Seller;
  existingLocationData!: Location;
  saveForm() {
    throw new Error('Method not implemented.');
  }

  formHeader!: string;
  formSubHeader!: string;
  protected items!: MenuItem[];
  home!: MenuItem;
  sellerForm!: FormGroup;
  form: any;
  cities!: City[];
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private nameValidator: NameCheckValidator,
    private populateDropdownService: PopulateDropdownService,
    private sellerService: SellerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private sellerSharedService: SellerSharedDataService,
    private titleService: Title,
    private route: ActivatedRoute,
    private toastService: ToastMessagesService,
    private locationSelectionService: LocationSelectionService,
    private confirmDeleteServiceCommon: ConfirmDeleteCommonService,
    private resetServiceCommon: ResetCommonService
  ) {
    this.setPageTitle();

    this.cities = [];
    this.populateCities();
  }

  ngAfterViewInit(): void {
    this.sellerForm.markAsPristine();
  }

  data: Seller = new Seller();
  ngOnInit(): void {
    this.populateBreadCrumb();

    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.createForm();
    const url = this.router.url;
    if (url.includes('edit/seller')) {
      this.editMode = true;
      this.route.data.subscribe((data) => {
        this.sellerEditData = data['seller'];
        this.existingLocationData = this.locationSelectionService.getLocationData(
          this.sellerEditData.location
        );
        this.sellerForm!.get('name')!.setAsyncValidators([]);
        this.sellerForm!.get('ghanaId')!.setAsyncValidators([]);
        this.addEditButton = 'Update';
      });
      /*  this.sellerEditData = this.sellerSharedService.getDataFromLocalStorage();
       console.log(
         `Shared Data SellerComponent is ${JSON.stringify(this.sellerEditData)}`
       );
       this.existingLocationData = this.locationSelectionService.getLocationData(
         this.sellerEditData.location
       ); */
      this.sellerForm.patchValue(this.sellerEditData);


    }
    this.initSidebar();
  }

  private createForm() {
    this.sellerForm = this.fb.group({
      supplierId: [],
      //     field1: new FormControl('', Validators.required, this.asyncValidator1),
      // name: new FormControl('', null, this.nameValidator.checkSellerNameValidator()),
      /*  name: ['', Validators.required, Validators.minLength(3), Validators.maxLength(32),
         this.nameValidator.checkSellerNameValidator(),
       ], */
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(64),
        ],
        [this.nameValidator.checkSellerNameValidator()],
      ],
      contactPerson: ['', [Validators.minLength(3), Validators.maxLength(32)]],
      //  address: ['', [Validators.minLength(3), Validators.maxLength(512)]],
      reference: ['', [Validators.minLength(3), Validators.maxLength(128)]],
      notes: ['', [Validators.maxLength(1024)]],
      phone1: ['', [Validators.pattern('^[0-9]{8,15}$')]],
      phone2: ['', [Validators.pattern('^[0-9]{8,15}$')]],
      ghanaId: [
        '',
        [Validators.minLength(5), Validators.maxLength(20)],
        [this.nameValidator.checkGhanaIDValidator()],
      ],
      location: [],
      /*   name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(32),],
          [this.nameValidator.checkSellerNameValidator()],
        ],
        contactPerson: ['', [Validators.minLength(3), Validators.maxLength(32)]],
        address: ['', [Validators.minLength(3), Validators.maxLength(512)]],
        reference: ['', [Validators.minLength(3), Validators.maxLength(128)]],
        notes: ['', [Validators.maxLength(1024)]],
        phone1: ['', [Validators.pattern("^[0-9]{8,15}$")]],
        phone2: ['', [Validators.pattern("^[0-9]{8,15}$")]],
        ghanaId: ['', [Validators.minLength(5), Validators.maxLength(20),],
          [this.nameValidator.checkGhanaIDValidator()]] */
    });
  }

  populateBreadCrumb() {
    this.items = [
      { label: 'Sellers', routerLink: '/sellers' },
      { label: 'Add Seller' },
    ];
  }

  private populateCities() {
    this.populateDropdownService
      .getCities()
      .subscribe((cities) => (this.cities = cities));
  }

  onCityChange(event: Event) {
    const cityId = this.sellerForm.controls['cityId'].value;
    console.log(
      `onCity Change ${event.target} value is ${JSON.stringify(cityId)}`
    );
    if (cityId == null) {
      this.sellerForm.controls['state'].patchValue('');
    } else this.sellerForm.controls['state'].patchValue(cityId.stateId.name);
  }

  onSubmit() {
    this.locationSelectionComponent.onSubmit();
    this.isSubmitting = true;
    //  this.getFormValidationErrors();
    Object.keys(this.sellerForm.controls).forEach((key) => {
      //console.log(`Key is ${key}`)
      //  this.sellerForm.get(key)?.updateValueAndValidity();
    });
    this.sellerForm.markAllAsTouched();
    const controlKeys = Object.keys(this.sellerForm.controls);

    const invalidControlKeys = controlKeys.filter((key) => {
      const control = this.sellerForm.controls[key];
      return control.invalid;
    });
    console.log('Invalid control keys:', invalidControlKeys);
    /*  this.sellerForm.controls['name'].markAsTouched();
     this.sellerForm.controls['name'].updateValueAndValidity(); */
    /*  this.sellerForm.controls['name'].markAsTouched();
     this.sellerForm.controls['cityId'].markAsTouched();
     this.sellerForm.controls['pincode'].markAsTouched(); */
    console.log(this.sellerForm.value);
    if (this.sellerForm.valid) {
      this.blockedDocument = true;
      console.log(this.sellerForm.value);
      this.sellerService.postData(this.sellerForm.value).subscribe(
        (resp) => {
          console.log(`On Submit Response is ${JSON.stringify(resp)}`);
          if (this.editMode) {
            this.toastService.showSuccessMessage(
              'Success',
              'Seller Edited Successfully'
            );
            this.router.navigate(['/sellers']);
          } else {
            this.toastService.showSuccessMessage(
              'Success',
              'Seller Created Successfully'
            );
            this.onReset();
            if (!this.isSubmittedFromSidebar) {
              this.confirmationService.confirm({
                message: 'Do you want to add more seller?',
                accept: () => {
                  this.sellerForm.reset();
                },
                reject: () => {
                  this.onReset();
                  this.router.navigate(['/sellers']);
                },
              });
            } else {
              console.log(`Seller/Supplier Submit from sidebar`);
              this.sellerForm.reset();
              this.supplierSubmit.emit();
            }
          }
          this.blockedDocument = false;
        },
        (error) => {
          console.log(`On Submit error is ${JSON.stringify(error)}`);
          this.blockedDocument = false;
          this.toastService.showErrorMessage('Error', error.error.message);
          // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
        },
        () => {
          this.blockedDocument = false;
          console.log(`Finish onSubmit`);
        }
      );
    } else {
      this.blockedDocument = false;
      console.error(`Form is not valid`);
    }
  }

  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }

  // nameValidation  is used while editMode=true, if name is changed check if already exist, if name is same as it was entered then ignore async validation
  // add even function (input) in html
  nameValidation() {
    console.log(`nameValidation `);
    if (this.editMode) {
      const isValueChanged =
        this.sellerEditData.name == this.sellerForm.get('name')?.value;
      if (!isValueChanged) {
        console.log(`is value changed isValueChanged}`);
        this.sellerForm!.get('name')!.setAsyncValidators([
          this.nameValidator.checkSellerNameValidator(),
        ]);
      } else {
        this.sellerForm!.get('name')!.setAsyncValidators([]);
        this.sellerForm.markAllAsTouched();
        this.sellerForm.get('name')?.updateValueAndValidity();
        console.log(`is not value changed isValueChanged}`);
      }
    }
  }

  ghanaIDValidation() {
    console.log(`ghanaIDValidation `);
    if (this.editMode) {
      const isValueChanged =
        this.sellerEditData.ghanaId == this.sellerForm.get('ghanaId')?.value;
      if (!isValueChanged) {
        console.log(`is value changed isValueChanged}`);
        this.sellerForm!.get('ghanaId')!.setAsyncValidators([
          this.nameValidator.checkGhanaIDValidator(),
        ]);
      } else {
        this.sellerForm!.get('ghanaId')!.setAsyncValidators([]);
        this.sellerForm.markAllAsTouched();
        this.sellerForm.get('ghanaId')?.updateValueAndValidity();
        console.log(`is not value changed isValueChanged}`);
      }
    }
  }

  getErrorMessage() {
    let myFieldControl = this.sellerForm.get('name');
    if (myFieldControl!.hasError('required')) {
      return 'This field is required.';
    }
    if (myFieldControl!.hasError('invalid')) {
      return 'This field is invalid.';
    }
    //console.log(`Error ${JSON.stringify(myFieldControl?.hasError)} `)
    return 'Unknown error.';
  }

  getFormValidationErrors() {
    Object.keys(this.sellerForm.controls).forEach((key) => {
      console.log(
        `Each key is ${key} value is ${this.sellerForm!.get(key)?.value
        } and hasError ${this.sellerForm!.get(key)!.errors!}`
      );
      const controlErrors: ValidationErrors =
        this.sellerForm!.get(key)!.errors!;

      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
            controlErrors[keyError]
          );
        });
      }
    });
  }
  initSidebar() {
    console.log(`initSidebar  `);
    console.log(`initSidebar ${this.isSubmittedFromSidebar} `);
    if (this.isSubmittedFromSidebar) {
      console.log(`initSidebar  ${this.headerFromSidebar}`);
      this.formHeader = this.headerFromSidebar;
      this.formSubHeader = this.subHeaderFromSidebar;
      this.titleService.setTitle(this.titleFromSidebar);
    }
  }

  locationFormValidators = {
    country: [Validators.required],
    state: [Validators.required],
    city: [Validators.required],
    zipcode: [Validators.required],
  };

  onLocationFormStatus(isLocationFormValid: boolean): void {
    console.log(`onLocationFormStatus ${isLocationFormValid}`);
    if (isLocationFormValid) {
      this.sellerForm.controls['location'].setErrors(null);
    } else {
      this.sellerForm.controls['location'].setErrors({
        invalidLocationForm: true,
      });
    }
  }

  private executeDeleteService(id: any): Promise<void> {
    return new Promise((resolve, reject) => {
      // Call your actual delete service here
      console.log(`id is ${id}`);
      this.blockedDocument = true;

      this.sellerService.deleteSellers(id).subscribe(
        () => {
          resolve();
          this.blockedDocument = false;
        },
        (error) => {
          reject(error);
          this.blockedDocument = false;

        }
      );
    });
  }

  onDelete(id: any): void {
    this.sellerForm.markAsPristine();
    this.confirmDeleteServiceCommon.confirm(
      id,
      () => this.executeDeleteService(id),
      '/sellers'
    );
  }

  onReset() {
    this.locationSelectionComponent.resetLocationForm();
    this.resetServiceCommon.resetForm(this.sellerForm);
  }

  @HostListener('window:keydown.meta.shift.s', ['$event'])
  handleSaveShortcut(event: KeyboardEvent) {
    this.onSubmit();
  }

  @HostListener('window:keydown.meta.shift.d', ['$event'])
  handleDeleteShortcut(event: KeyboardEvent) {
    if (this.editMode) {
      this.onDelete(this.sellerEditData.supplierId);
    }
  }
}
