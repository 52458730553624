import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CardModule } from 'primeng/card';
import { LocationSelectionComponent } from './location-selection/location-selection.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import {  AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { SidebarModule } from 'primeng/sidebar';
import { SpeedDialModule } from 'primeng/speeddial';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';



@NgModule({
  declarations: [
    // PageNotFoundComponent,
    // LocationSelectionComponent
  ],
  imports: [
    CommonModule,
    CardModule, 
    ButtonModule,
    BreadcrumbModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextareaModule,
    InputMaskModule,
    InputTextModule,
    ToastModule,
    ConfirmDialogModule,
    TableModule,
    PaginatorModule,
    MultiSelectModule,
    SpeedDialModule,
    SidebarModule,
    AutoCompleteModule,
    ProgressSpinnerModule,

    PageNotFoundComponent,
    LocationSelectionComponent
    

  ],
  exports: [PageNotFoundComponent,LocationSelectionComponent],
  providers: [ConfirmationService,MessageService,ToastMessagesService]
})
export class CommonsModule { }
