import { Injectable } from '@angular/core';
import { AppConstants } from '../commons/app-constants';
import { Buyer } from '../modules/buyer/model/buyer';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { City } from '../commons/models/city';
import { Seller } from '../modules/seller/model/seller';
import { Product } from '../modules/product/model/product';
import { LoadingSite } from '../modules/loading-site/model/loading-site';
import { Container } from '../modules/container/model/container';
import { ContainerNumbers } from '../modules/container/model/container-numbers';
import { ShippingLine } from '../modules/bill-of-lading/model/shipping-line';

@Injectable({
  providedIn: 'root'
})
export class PopulateDropdownService {

  constructor(private http: HttpClient) { }

  getCities(): Observable<City[]> {

    const url = AppConstants.API_URL + 'api/v1/cities';
    return this.http.get<City[]>(url);
  }

  getFilteredCities(city : string): Observable<City[]> {

    const url = `${AppConstants.API_URL}api/v1/filterCities?city=${city}`;
    return this.http.get<City[]>(url);
  }

  getBuyers(): Observable<Buyer[]> {
    return this.http.get<Buyer[]>(AppConstants.API_URL + 'api/v1/buyers-list');
  }

  getShippingLines(): Observable<ShippingLine[]> {
    return this.http.get<ShippingLine[]>(AppConstants.API_URL + 'api/v1/shipping-lines');
  }
  getSuppliers(): Observable<Seller[]> {
    return this.http.get<Seller[]>(AppConstants.API_URL + 'api/v1/suppliers');
  }

  getProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(AppConstants.API_URL + 'api/v1/products');
  }

  getLoadingSites(): Observable<LoadingSite[]> {
    return this.http.get<LoadingSite[]>(AppConstants.API_URL + 'api/v1/loading-sites');
  }


  getContainers(): Observable<Container[]> {
    return this.http.get<Container[]>(AppConstants.API_URL + 'api/v1/containers');
  }

  getContainerNumbers(search?: string): Observable<ContainerNumbers[]> {
    const url = AppConstants.API_URL + 'api/v1/container-numbers?q=' + search;
    return this.http.get<ContainerNumbers[]>(url);
  }

  getContainerNumbersNotInSummary(containerNumber: string): Observable<ContainerNumbers[]> {
    const url =`${AppConstants.API_URL}api/v1/container-numbers-not-in-summary?containerNumber=${containerNumber}`;
    return this.http.get<ContainerNumbers[]>(url);
  }
  //
}
