import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SecretKeyService } from './secret-key.service';
import { AppConstants } from '../commons/app-constants';

@Injectable({
  providedIn: 'root'
})
export class EncryptedStorageService {

  constructor(private secretKeyService : SecretKeyService) { }

  setEncryptedDataToStorage(key: string, value: any): Observable<void> {
    return new Observable((observer) => {
      const password = localStorage.getItem(AppConstants.PROMOTOER_CODE);
      const data = typeof value === 'object' ? JSON.stringify(value) : value;
      this.secretKeyService.encrypt(data, password).subscribe(
        (encryptedData) => {
          localStorage.setItem(
            key,
            btoa(String.fromCharCode.apply(null, Array.from(encryptedData)))
          );
          observer.next();
          observer.complete();
        },
        (error) => observer.error(error)
      );
    });
  }

  getEncryptedDataFromStorage(key: string): Observable<any> {
    // console.log(`getEncryptedDataFromStorage **** 1 key is ${key}`);
    return new Observable((observer) => {
      // console.log(`getEncryptedDataFromStorage **** 2`);
      const password = localStorage.getItem(AppConstants.PROMOTOER_CODE);
      // console.log(`getEncryptedDataFromStorage **** 3`);
      const encryptedData = (localStorage.getItem(key) || '');
      // console.log(`getEncryptedDataFromStorage **** 4 encrypted data is ${encryptedData} pasword is ${password}`);
      this.secretKeyService.decrypt(encryptedData, password).subscribe(
        (decryptedData) => {
          // console.log(`getEncryptedDataFromStorage **** 5 ${decryptedData}`);
          try {
            // console.log(`getEncryptedDataFromStorage ${decryptedData}`);
            const data = JSON.parse(decryptedData);
            observer.next(data);
          } catch (error) {
            observer.next(decryptedData);
          }
          observer.complete();
        },
        (error) => observer.error(error)
      );
    });
  }

  deleteKey(keyName: string){
    localStorage.removeItem(keyName);

  }

}
