<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" acceptLabel="Yes"
    rejectLabel="No"></p-confirmDialog>


<form [formGroup]="userRegistrationForm" (ngSubmit)="onSubmit()">
    <div class="text-red-400"
        *ngIf="userRegistrationForm.get('mobile')?.errors?.['required'] && userRegistrationForm.get('mobile')?.touched">
        <div *ngIf="!userRegistrationForm.get('verifiedMobile')?.value">Mobile is required. Verify mobile number first.
        </div>
    </div>

    <p-card #card header="Registration" subheader="User Details">
        <div class="formgrid grid">

            <!-- <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                <label for="companyName">Company Name <i class="text-red-400">*</i></label>
                <input id="companyName" type="text" formControlName="companyName" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400" name
                    *ngIf="userRegistrationForm.invalid && (userRegistrationForm.controls?.['companyName']?.invalid && (userRegistrationForm.controls?.['companyName']?.dirty || userRegistrationForm.controls?.['companyName']?.touched))">
                    <div *ngIf="userRegistrationForm.controls?.['companyName']?.errors?.['required']">Company Name is
                        required.
                    </div>
                </div>
            </div>

            <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                <label for="industry">Industry <i class="text-red-400">*</i></label>
                <input id="industry" type="text" formControlName="industry" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400" name
                    *ngIf="userRegistrationForm.invalid && (userRegistrationForm.controls?.['industry']?.invalid && (userRegistrationForm.controls?.['industry']?.dirty || userRegistrationForm.controls?.['industry']?.touched))">
                    <div *ngIf="userRegistrationForm.controls?.['industry']?.errors?.['required']">Industry is
                        required.
                    </div>
                </div>
            </div> -->


            <div class="field col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4">
                <label for="firstName">First Name <i class="text-red-400">*</i></label>
                <input id="firstName" type="text" formControlName="firstName" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400" name
                    *ngIf="userRegistrationForm.invalid && (userRegistrationForm.controls?.['firstName']?.invalid && (userRegistrationForm.controls?.['firstName']?.dirty || userRegistrationForm.controls?.['firstName']?.touched))">
                    <div *ngIf="userRegistrationForm.controls?.['firstName']?.errors?.['required']">First Name is
                        required.
                    </div>
                </div>
            </div>

            <div class="field col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4">
                <label for="middleName">Middle Name </label>
                <input id="middleName" type="text" formControlName="middleName" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400" name
                    *ngIf="userRegistrationForm.invalid && (userRegistrationForm.controls?.['middleName']?.invalid && (userRegistrationForm.controls?.['middleName']?.dirty || userRegistrationForm.controls?.['middleName']?.touched))">
                    <div *ngIf="userRegistrationForm.controls?.['middleName']?.errors?.['required']">Middle Name is
                        required.
                    </div>
                </div>
            </div>

            <div class="field col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4">
                <label for="lastName">Last Name <i class="text-red-400">*</i></label>
                <input id="lastName" type="text" formControlName="lastName" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400" name
                    *ngIf="userRegistrationForm.invalid && (userRegistrationForm.controls?.['lastName']?.invalid && (userRegistrationForm.controls?.['lastName']?.dirty || userRegistrationForm.controls?.['lastName']?.touched))">
                    <div *ngIf="userRegistrationForm.controls?.['lastName']?.errors?.['required']">Last Name is
                        required.
                    </div>
                </div>
            </div>

            <div class="field col-12 sm:6 md:col-6 lg:col-4 xl:col-4">
                <label for="etd">Date of Brith <i class="text-red-400">*</i><i
                        class="ml-2 text-xs">dd/mm/yyyy</i></label>
                <p-calendar [showIcon]="true" inputId="icon" formControlName="dateOfBirth" styleClass="w-full"
                    dateFormat="dd/mm/yy" [showButtonBar]="true" [maxDate]="maximumDate">
                </p-calendar>
            </div>
            <!-- <div class="field col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4">
                <label for="dateOfBirth">Date of Birth <i class="text-red-400">*</i></label>
                
                <p-calendar id="dateOfBirth" type="text" formControlName="dateOfBirth"
                    dateFormat="dd/mm/yy" ></p-calendar>
                <div class="text-red-400" name
                    *ngIf="userRegistrationForm.invalid && (userRegistrationForm.controls?.['dateOfBirth']?.invalid && (userRegistrationForm.controls?.['dateOfBirth']?.dirty || userRegistrationForm.controls?.['dateOfBirth']?.touched))">
                    <div *ngIf="userRegistrationForm.controls?.['dateOfBirth']?.errors?.['required']">Date of Birth is
                        required.
                    </div>
                </div>
            </div> -->


            <div class="field col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4">
                <label for="gender">Gender <i class="text-red-400">*</i></label>
                <p-dropdown [options]="[{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }, 
                { label: 'Other', value: 'other' }]" formControlName="gender" styleClass="field col-12 w-full"
                    placeholder="Select Gender"></p-dropdown>
                <div class="text-red-400"
                    *ngIf="userRegistrationForm.controls?.['gender']?.invalid && (userRegistrationForm.controls?.['gender']?.dirty || userRegistrationForm.controls?.['gender']?.touched)">
                    <div
                        *ngIf="userRegistrationForm.invalid &&  (userRegistrationForm.controls?.['gender']?.errors?.['required'])">
                        Gender is
                        required.</div>
                </div>
            </div>

            <div class="field col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4">
                <label for="employeeId">Employee Id</label>
                <input id="employeeId" type="text" formControlName="employeeId" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">

            </div>

            <div class="field col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4">
                <label for="email">Registered Email <i class="text-red-400">*</i></label>
                <input id="email" type="text" formControlName="email" pInputText readonly
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>


            <div class="field col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4">
                <label for="alternateEmail">Alternate Email <i class="text-red-400">*</i></label>
                <input id="alternateEmail" type="text" formControlName="alternateEmail" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400" name
                    *ngIf="userRegistrationForm.invalid && (userRegistrationForm.controls?.['alternateEmail']?.invalid && (userRegistrationForm.controls?.['alternateEmail']?.dirty || userRegistrationForm.controls?.['alternateEmail']?.touched))">
                    <div *ngIf="userRegistrationForm.controls?.['alternateEmail']?.errors?.['required']">Alternate Email
                        is
                        required.
                    </div>
                </div>
            </div>

            <div class="field col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4">
                <label for="mobile">Mobile <i class="text-red-400">*</i></label>
                <div class="text-red-400"
                    *ngIf="userRegistrationForm.get('mobile')?.touched && userRegistrationForm.get('mobile')?.hasError('invalidPhoneNumber')">
                    Invalid phone number
                </div>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon w-3">
                        <!-- <p-dropdown [options]="isdCodes" formControlName="isdCode" optionLabel="isdCode" 
                            placeholder="Country" styleClass="w-full  border-none" [filter]="true" filterBy="isdCode,countryName"
                            [virtualScroll]="true" [virtualScrollItemSize]="38" tooltipPosition="bottom"
                            (onChange)="getSelectedCountryIsoCode($event)" appendTo="body"
                            [readonly]="data?.user?.mobileVerified ?? false" >
                            <ng-template pTemplate="selectedItem">

                                <div class="flex align-items-center">
                                    <div>{{ selectedIsdCode?.isdCode}}</div>
                                </div>
                            </ng-template>
                            <ng-template let-country pTemplate="item">
                                <div class="flex align-items-center w-full">
                                   
                                    <div style="width: 4rem;" >
                                         +{{ country.isdCode}} 
                                        </div>
                                    <div> 
                                        {{ country.countryName}}
                                    </div>
                                </div>
                            </ng-template>
                        </p-dropdown> -->
                      
                        <p-dropdown [options]="isdCodes" [ngModel]="selectedIsdCode"   optionLabel="isdCode" (onChange)="getSelectedCountryIsoCode($event)"
                            formControlName="isdCode" styleClass="w-full  border-none" [filter]="true"
                            [virtualScroll]="true" [virtualScrollItemSize]="38" 
                            class="w-full" appendTo="body"  tooltipPosition="bottom" filterBy="name"
                             placeholder="Country" [readonly]="data?.user?.mobileVerified ?? false">
                            <ng-template pTemplate="selectedItem">
                                <div class="flex align-items-center gap-2" *ngIf="selectedIsdCode">
                                    <div>{{ selectedIsdCode?.emoji?? '' }}</div>
                                    <!-- <div>{{ selectedIsdCode?.name?? '' }}</div> -->
                                </div>
                            </ng-template>
                            <ng-template let-country pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <div>{{ country?.emoji?? '' }}</div>
                                    <div>{{ country?.name?? '' }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>

                    </span>

                    <input id="mobile" formControlName="mobile" pInputText maxLength="14" type="tel" pKeyFilter="num"
                        [readOnly]="data?.user?.mobileVerified ?? false"
                        class="text-base text-color surface-overlay p-2 border-1 focus:border-primary w-2">
                    <!-- <input id="mobile" formControlName="mobile" pInputText maxLength="14" type="tel" pKeyFilter="num"
                        class="text-base text-color surface-overlay p-2 border-1 focus:border-primary w-2"> -->

                    <span class="p-inputgroup-addon">
                        <!--   <button type="button" pButton [icon]="mobileButtonStyle.icon" [class]="mobileButtonStyle.style"
                            [pTooltip]="mobileButtonStyle.tooltip" tooltipPosition="top"
                            [disabled]="mobileButtonStyle.isVerified"></button> -->
                        <p-button [icon]="mobileButtonStyle.icon" [styleClass]="mobileButtonStyle.style"
                            [pTooltip]="mobileButtonStyle.tooltip" tooltipPosition="left"
                            [disabled]="mobileButtonStyle.isVerified"></p-button>

                    </span>

                </div>
                <!--   <a href="#" *ngIf="!mobileButtonStyle.isVerified" (click)="getOTP()">
                    <small id="username-help">
                    Send OTP
                </small></a>  -->
                <div *ngIf="!data?.user?.mobileVerified ?? true">
                    <p-button *ngIf="!showTimer; else showTimerDiv" label="Generate OTP" styleClass="p-button-link"
                        (click)="getOTP();validatePhoneNumber()" [disabled]="!isPhoneNumberValid()?? false"
                        [attr.disabled]="!isPhoneNumberValid() ? true : null"></p-button>
                    <ng-template #showTimerDiv>
                        <p> Resend OTP after {{ timeLeft }} </p>
                        <p> OTP is valid for 3 minutes </p>
                    </ng-template>
                </div>
                <div class="text-red-400" name
                    *ngIf="userRegistrationForm.invalid && (userRegistrationForm.controls?.['mobile']?.invalid && (userRegistrationForm.controls?.['mobile']?.dirty || userRegistrationForm.controls?.['mobile']?.touched))">
                    <div *ngIf="userRegistrationForm.controls?.['mobile']?.errors?.['required']">Mobile is
                        required.
                    </div>
                </div>
                <div class="text-red-400" *ngIf="userRegistrationForm?.errors?.['mobileNotVerified'] ?? null">
                    Verify
                    mobile number first.
                </div>
                <div class="text-red-400" name
                    *ngIf="userRegistrationForm.invalid && (userRegistrationForm.controls?.['isdCode']?.invalid && (userRegistrationForm.controls?.['isdCode']?.dirty || userRegistrationForm.controls?.['isdCode']?.touched))">
                    <div *ngIf="userRegistrationForm.controls?.['isdCode']?.errors?.['required']">Country Code
                        is
                        required.
                    </div>

                    <div
                        *ngIf="userRegistrationForm.get('mobile')?.touched && userRegistrationForm.get('mobile')?.errors">
                        Invalid phone number
                    </div>
                </div>


            </div>
            <div class="w-full m-2">
                <app-location-selection formControlName="location" (locationFormStatus)="onLocationFormStatus($event)"
                    [locationFormValidators]="locationFormValidators" [existingLocationData]="existingLocationData"
                    [isAddress2Optional]="true"></app-location-selection>

            </div>
            <div *ngIf="!isEditMode">
                <p-checkbox formControlName="termsAccepted" value="I have read and accept the terms and conditions."
                    inputId="terms"></p-checkbox>
                <label for="terms">I have read and accept the terms and conditions <i class="text-red-400">* </i>
                </label>
                <p><a href="http://www.rikexim.com" target="_blank" class="text-xs">View Terms & Conditions</a> </p>
                <div class="text-red-400 p-1" name
                    *ngIf="userRegistrationForm.invalid && (userRegistrationForm.controls?.['termsAccepted']?.invalid && (userRegistrationForm.controls?.['termsAccepted']?.dirty || userRegistrationForm.controls?.['termsAccepted']?.touched))">
                    <div *ngIf="userRegistrationForm.controls?.['termsAccepted']?.errors?.['required']">Accept terms and
                        conditions to proceed.
                    </div>
                </div>
            </div>

        </div>
        <ng-template pTemplate="footer">
            <p-button [label]="submitButtonLabel" [disabled]="!userRegistrationForm.dirty" icon="pi pi-check"
                type="submit"></p-button>
            <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary"
                [style]="{'margin-left': '.5em'}"></p-button>
            <!-- <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary"
                [style]="{'margin-left': '.5em'}" (click)="nameValidation()"></p-button> -->
        </ng-template>
    </p-card>

    <!-- <div class="p-field">
        <button pButton type="submit" label="Register"></button>
    </div> -->
</form>

<p-dialog header="Enter OTP" [(visible)]="otpDialogVisible" [resizable]="false" [modal]="true"
    [showHeader]="showOtpDialogHeader" [style]="{width: getViewportWidth()}">
    <!-- <p-dialog header="Enter OTP" [(visible)]="otpDialogVisible" [style]="{width: '22vw'}"
   [breakpoints]="{ 
    '(max-width: 575px)': '100vw', // For extra small devices (phones)
    '(min-width: 576px) and (max-width: 767px)': '70vw', // For small devices (tablets)
    '(min-width: 768px) and (max-width: 991px)': '50vw', // For medium devices (laptops)
    '(min-width: 992px) and (max-width: 1199px)': '40vw', // For large devices (desktops)
    '(min-width: 1200px)': '35vw' // For extra large devices (large desktops)
}"[resizable]="false" [modal]="true" [showHeader]="showOtpDialogHeader"> -->

    <p>OTP sent to <b>{{ userRegistrationForm.controls?.['mobile']?.value }} </b></p>
    <!-- <p-inputNumber [(ngModel)]="otp" mode="decimal" inputId="withoutgrouping" [useGrouping]="false">
    </p-inputNumber> -->
    <!-- <ng-otp-input [(ngModel)]="otp" [config]="{length:4,allowNumbersOnly:false}"></ng-otp-input> -->
    <p-inputOtp [(ngModel)]="otp" [mask]="true"  [integerOnly]="true" />

    <p-button *ngIf="!showTimer; else showTimerDiv" label="Send OTP" styleClass="p-button-link"
        (click)="getOTP()"></p-button>
    <ng-template #showTimerDiv>
        <p> Resend OTP after {{ timeLeft }}</p>
        <p> OTP is valid for 3 minutes </p>
    </ng-template>


    <ng-template pTemplate="footer">
        <p-button type="button" icon="pi pi-check" (click)="validateOTP();" label="Ok"
            styleClass="p-button-success p-button-outlined"></p-button>
        <p-button type="button" icon="pi pi-times" (click)="otpDialogVisible =false" label="Cancel"
            styleClass="p-button-danger p-button-outlined"></p-button>
    </ng-template>

</p-dialog>

<div
    *ngIf="userRegistrationForm.get('mobile')?.touched && userRegistrationForm.get('mobile')?.hasError('invalidPhoneNumber')">
    Invalid phone number
</div>


<p-blockUI [blocked]="blockedPanel">
    <!-- <i class="pi pi-lock" style="font-size: 3rem"></i> -->
    <i class="pi pi-spin pi-spinner" style="font-size: 4rem !important"></i>

</p-blockUI>