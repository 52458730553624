import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

@Injectable({
  providedIn: 'root',
})
export class PhoneValidatorService {
  constructor() {}

  /* phoneNumberValidator(): ValidatorFn {
    const phoneUtil = PhoneNumberUtil.getInstance();

    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const isdCode = control.get('isdCode')?.value;
      const phoneNumber = control.get('mobile')?.value;
      console.log(
        `Phone Number ${phoneNumber} isdcode ${JSON.stringify(isdCode.isdCode)}`
      );
      if (!isdCode || !phoneNumber) {
        return null; // return null if controls haven't initialised yet
      }

      const fullNumber = '+' + isdCode.isdCode + phoneNumber;

      try {
        const parsedNumber = phoneUtil.parseAndKeepRawInput(
          fullNumber,
          isdCode.countryCode
        );
        console.log(phoneUtil.isValidNumber(parsedNumber));
        if (phoneUtil.isValidNumber(parsedNumber)) {
          return null; // return null if phone number is valid
        } else {
          console.log(`INValid phone number and return now`);
          return { invalidPhoneNumber: true };
        }
      } catch (e) {
        // handle the error (e.g., logging), if needed
      }

      return { invalidPhoneNumber: true };
    };
  } */

  phoneNumberValidator(): ValidatorFn {
    const phoneUtil = PhoneNumberUtil.getInstance();
  
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const formGroup = control.parent as FormGroup;
      const isdCode = formGroup?.get('isdCode')?.value;
      const phoneNumber = control.value; // Note: control is the `mobile` FormControl itself

      if (!isdCode || !phoneNumber || !isdCode.isdCode || !isdCode.countryCode) {
        return null; // return null if controls haven't initialised yet or isdCode is undefined
      }
      
  
      console.log(`Phone Number ${phoneNumber} isdcode ${JSON.stringify(isdCode.isdCode)}`);
  
      if (!isdCode || !phoneNumber) {
        return null; // return null if controls haven't initialised yet
      }
  
      const fullNumber = '+' + isdCode.isdCode + phoneNumber;
  
      try {
        const parsedNumber = phoneUtil.parseAndKeepRawInput(fullNumber, isdCode.countryCode);
        console.log(phoneUtil.isValidNumber(parsedNumber));
        if (phoneUtil.isValidNumber(parsedNumber)) {
          return null; // return null if phone number is valid
        } else {
          console.log(`INValid phone number and return now`);
          return { invalidPhoneNumber: true };
        }
      } catch (e) {
        // handle the error (e.g., logging), if needed
      }
  
      return { invalidPhoneNumber: true };
    };
  }
  
}
