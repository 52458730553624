import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { UtilService } from 'src/app/services/util.service';
import { BlSummary } from '../../bill-of-lading/model/bl-summary';
import { MeasurementSharedDataService } from '../../measurement/services/measurement-shared-data.service';
import { MeasurementService } from '../../measurement/services/measurement.service';
import { ContainerService } from '../services/container.service';
import { BehaviorSubject, Observable, finalize } from 'rxjs';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-container-summary',
  templateUrl: './container-summary.component.html',
  styleUrls: ['./container-summary.component.css'],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class ContainerSummaryComponent implements OnInit {
  @Input() blSummary!: BlSummary;
  @Input() containerId!: any; // = CONTAINER_ID; //////
  // data!: MeasurementSummary;
  data!: any;
  dummyArrayHorizontalGrid = ['Helo'];
  isPublic!: boolean;
  containerUUID!: any;
  isMobile!: boolean;
  tableStyle: any;
  isLoading = false;

  totalGrossVol: number = 0;
  totalNetVol: number = 0;
  logsCount: number = 0;
  grossCftAvg: number = 0;
  netCftAvg: number = 0;
  minLength: number = 0;
  maxLength: number = 0;
  minGirth: number = 0;
  maxGirth: number = 0;
  girthAllowance: number = 2;
  lengthAllowance: number = 0;

  productName: any;
  formula: any;
  sealNumber: any;
  containerNumber!: string;
  public lengthSummary: Array<any> = [];
  public girthSummary: Array<any> = [];

  private isLoadingSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();

  constructor(
    private measurementService: MeasurementService,
    private toastMessagesService: ToastMessagesService,
    private measurementSharedDataService: MeasurementSharedDataService,
    private router: Router,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private containerService: ContainerService
  ) {}

  ngOnInit(): void {
    // console.log(`BlSummaryComponent blSummary is${JSON.stringify(this.blSummary)}`);

    this.isPublic = this.router.url.includes('public');
    console.log(`this.isPublic ${this.isPublic}`);
    if (this.isPublic) {
      this.route.params.subscribe((params) => {
        this.containerUUID = params['containerUUID'];
        console.log(`UUID is ${this.containerId}`);
        if (this.containerUUID) {
          this.containerId = this.containerUUID;
        }
        this.loadPublicData(this.containerUUID);
      });
    } else {
      this.containerId =
        this.measurementSharedDataService.getContainerIdStorage();
      console.log(` this.containerId  ${this.containerId}`);
      this.loadPrivateData(this.containerId);
    }
  }

   

  loadPrivateData(containerId: any) {
    // Set the loading state to true
    this.isLoadingSubject.next(true);
  
    // Make the container service call
    this.containerService.getContainerSummaryByContainerId(containerId)
      .pipe(
        // Ensure the loading state is set to false once the request completes or errors out
        finalize(() => this.isLoadingSubject.next(false))
      )
      .subscribe(
        (response) => {
          console.log(`%%%%%% *****  ${JSON.stringify(response)}`);
  
          this.totalGrossVol = response.grossVolume;
          this.totalNetVol = response.netVolume;
          this.grossCftAvg = response.grossAverage;
          this.netCftAvg = response.netAverage;
          this.logsCount = response.pieces;
          this.lengthSummary = this.transformToSummary(response.lengthHistogram);
          this.girthSummary = this.transformToSummary(response.girthHistogram);
          this.containerNumber = response.containerNumber;
          this.productName = response.product;
          this.formula = response.formula;
          this.sealNumber = response.sealNumber;
          // this.setStatus();
        },
        (error) => {
          console.error('Error while fetching container summary:', error);
        }
      );
  }
  

  loadPublicData(containerId: any) {
    // Set the loading state to true
    this.isLoadingSubject.next(true);
  
    // Make the container service call
    this.containerService.getContainerSummaryByContainerUUID(containerId)
      .pipe(
        // Ensure the loading state is set to false once the request completes or errors out
        finalize(() => this.isLoadingSubject.next(false))
      )
      .subscribe(
        (response) => {
          console.log(`%%%%%% *****  ${JSON.stringify(response)}`);
  
          this.totalGrossVol = response.grossVolume;
          this.totalNetVol = response.netVolume;
          this.grossCftAvg = response.grossAverage;
          this.netCftAvg = response.netAverage;
          this.logsCount = response.pieces;
          this.lengthSummary = this.transformToSummary(response.lengthHistogram);
          this.girthSummary = this.transformToSummary(response.girthHistogram);
          this.containerNumber = response.containerNumber;
          this.productName = response.product;
          this.formula = response.formula;
          this.sealNumber = response.sealNumber;
          // this.setStatus();
        },
        (error) => {
          console.error('Error while fetching public container summary:', error);
        },
        () => {
          // this.deleteFieldValue(0);
          // this.onEnterGirth(0);
        }
      );
  }
  

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = this.utilService.isMobile();
    this.tableStyle = this.utilService.getTableSize();
    console.log(`is mobile ${this.utilService.isMobile()}`);
  }

  transformToSummary(histogram: any): Array<any> {
    const summary = [];
    for (const range in histogram) {
      if (histogram.hasOwnProperty(range)) {
        summary.push({ range: range, count: histogram[range] });
      }
    }
    return summary;
  }
}
