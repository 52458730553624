import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { ToastMessagesService } from './toast-messages.service';

@Injectable({
  providedIn: 'root',
})
export class ConfirmDeleteCommonService {
  constructor(
    private confirmationService: ConfirmationService,
    private router: Router,
    private toastMessageService: ToastMessagesService
  ) {}

  confirm(
    id: any,
    deleteFunction: (id: any) => Promise<void>,
    redirectUrl: string
  ): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this record?',
      header: 'Confirm Deletion',
      icon: 'pi pi-exclamation-triangle',
      key: 'deleteConfirmDialogCommon',
      accept: async () => {
        try {
          await deleteFunction(id);
          //this.messageService.add({severity: 'success', summary: 'Success', detail: 'Record has been deleted'});
          this.toastMessageService.showSuccessMessage(
            'Success',
            'Record has been deleted'
          );
          this.router.navigate([redirectUrl]);
        } catch (error) {
          // this.messageService.add({severity: 'error', summary: 'Error', detail: 'Failed to delete the record'});
          this.toastMessageService.showErrorMessage(
            'Error',
            'Failed to delete the record'
          );
        }
      },
    });
  }
}
