<!-- <p-toast></p-toast> -->
<p-blockUI [blocked]="blockedDocument">
    <i class="pi pi-spin pi-spinner" style="font-size: 4rem !important"></i>
</p-blockUI>
<div class="custom-card">
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" acceptLabel="Yes"
        rejectLabel="No"></p-confirmDialog>
    <form [formGroup]="productForm" (ngSubmit)="onSubmit($event)">

        <div class="pb-3" *ngIf="showBreadcrumb">
            <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
        </div>

        <p-card class="align-content-center " [header]="formHeader" [subheader]="formSubHeader">
            <div class="formgrid grid">

                <!-- <div class="field col-12 md:col-6"> -->
                <div class="field col-12 sm:12 md:col-12 lg:col-4 xl:col-4">
                    <label for="name">Name <i class="text-red-400">*</i></label>
                    <input id="name" type="text" formControlName="name" pInputText (input)="nameValidation()"
                        maxlength="64"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    <div class="text-red-400"
                        *ngIf="productForm.invalid && (productForm.controls?.['name']?.invalid && (productForm.controls?.['name']?.dirty || productForm.controls?.['name']?.touched))">
                        <div *ngIf="productForm.controls?.['name']?.errors?.['required']">Name is required.</div>
                        <div *ngIf="productForm.controls?.['name']?.errors?.['isProductExists'];">Name already exists.
                        </div>
                        <div *ngIf="productForm.controls?.['name']?.errors?.['minlength']">Min Length 5 characters
                        </div>
                    </div>
                </div>
                <div class="field col-12 sm:12 md:col-12 lg:col-4 xl:col-4">
                    <label for="hsCode">HS Code <i class="text-red-400">*</i></label>
                    <input id="hsCode" type="number" pKeyFilter="int" formControlName="hsCode" pInputText maxlength="8"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    <div class="text-red-400"
                        *ngIf="productForm.invalid && (productForm.controls?.['hsCode']?.invalid && (productForm.controls?.['hsCode']?.dirty || productForm.controls?.['hsCode']?.touched))">
                        <div *ngIf="productForm.controls?.['hsCode']?.errors?.['required']">HS Code is required.</div>
                        <div *ngIf="productForm.controls?.['hsCode']?.errors?.['minlength']">Min Length 4 characters
                        </div>
                        <div *ngIf="productForm.controls?.['hsCode']?.errors?.['maxlength']">Max Length 8 characters
                        </div>
                        <div *ngIf="productForm.controls?.['hsCode']?.errors?.['pattern']">Numbers only between 4-8
                            digits
                        </div>
                    </div>
                </div>
                <div class="field col-12 sm:12 md:col-12 lg:col-4 xl:col-4">
                    <label for="formula">Formula
                        <i class="text-red-400">*</i>
                        <!-- <p-button icon="pi pi-question-circle" (onClick)="onFormulaHelp()"
                        styleClass="p-button-rounded p-button-danger p-button-text"></p-button> -->
                    </label>
                    <div class="p-inputgroup">

                        <p-dropdown [options]="formulas" formControlName="formula" optionLabel="name"
                            styleClass="field col-12 w-full" [lazy]="true" placeholder="Select Formula"
                            [showClear]="true"></p-dropdown>
                        <p-button icon="pi pi-question-circle" (onClick)="onFormulaHelp()"
                            styleClass="p-button-rounded p-button-danger p-button-text"></p-button>

                    </div>
                    <div class="text-red-400"
                        *ngIf="productForm.invalid && (productForm.controls?.['formula']?.invalid && (productForm.controls?.['formula']?.dirty || productForm.controls?.['formula']?.touched))">
                        <div *ngIf="productForm.controls?.['formula']?.errors?.['required']">Forumla is required.</div>
                    </div>
                </div>
                <div class="field col-12 sm:12 md:col-12 lg:col-12 xl:col-12">
                    <label for="description">Description</label>
                    <textarea id="description" name="description" type="text" formControlName="description"
                        pInputTextarea maxlength="1024"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                        style="height:80%">
                </textarea>
                    <div class="text-red-400"
                        *ngIf="productForm.controls?.['description']?.invalid && (productForm.controls?.['description']?.dirty || productForm.controls?.['description']?.touched)">
                        <div *ngIf="productForm.controls?.['description']?.errors?.['minlength']">Min Length 3
                            characters
                        </div>
                        <div *ngIf="productForm.controls?.['description']?.errors?.['maxlength']">Max Length 512
                            characters
                        </div>
                    </div>
                </div>

            </div>
            <ng-template pTemplate="footer">
                <!--  <p-button [label]="addEditButton" [disabled]="!productForm.dirty" icon="pi pi-check"
                type="submit"></p-button>
            <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary"
                [style]="{'margin-left': '.5em'}"></p-button> -->
                <!-- <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary"
                [style]="{'margin-left': '.5em'}" (click)="nameValidation()"></p-button> -->
                <!-- <p-button [label]="addEditButton" [disabled]="!productForm.dirty" icon="pi pi-save"
                styleClass="p-button-success w-9rem" type="submit"> </p-button>

            <p-button label="Reset" icon="pi pi-sync" styleClass="p-button-secondary  w-9rem" *ngIf="!editMode"
                [style]="{'margin-left': '.5em'}" (onClick)="onReset()"></p-button>

            <p-button label="Delete" icon="pi pi-trash" styleClass="p-button-danger  w-9rem" *ngIf="editMode"
                [style]="{'margin-left': '.5em'}" (onClick)="onDelete(this.productEditData.productId)"></p-button> -->

                <div class="grid">
                    <div class="col-fixed" class="sm:w-12rem md:w-12rem lg:w-12rem xl:w-12rem">
                        <div class="text-center p-3 font-bold">
                            <!-- <p-button [label]="addEditButton" [disabled]="!productForm.dirty" icon="pi pi-save"
                                styleClass="p-button-success w-9rem" type="submit"> </p-button> -->
                                <p-button [label]="addEditButton"   icon="pi pi-save"
                                styleClass="p-button-success w-9rem" type="submit"> </p-button>
                        </div>
                    </div>
                    <div class="col-fixed" class="sm:w-12rem md:w-12rem lg:w-12rem xl:w-12rem">
                        <div class="text-center p-3 font-bold">
                            <p-button label="Reset" icon="pi pi-sync" styleClass="p-button-secondary  w-9rem"
                                *ngIf="!editMode" [style]="{'margin-left': '.5em'}" (onClick)="onReset()"></p-button>

                            <p-button label="Delete" icon="pi pi-trash" styleClass="p-button-danger  w-9rem"
                                *ngIf="editMode" [style]="{'margin-left': '.5em'}"
                                (onClick)="onDelete(this.productEditData.productId)"></p-button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-card>

    </form>

    <p-dialog header="Formulas Guide" [(visible)]="showFormulaHelpDialog" [style]="{width: '50vw',height: '65vh'}"
        [breakpoints]="{'480px': '100vw',
    '768px': '75vw',  
    '992px': '70vw',  
    '1200px': '65vw', 
    '1400px': '60vw'}" [closable]="true" [closeOnEscape]="true" [draggable]="false" [resizable]="false"
        [responsive]="true">

        <p class="text-md font-bold">Hoppus Formula</p>

        <p class="text-md font-italic">Application:</p>
        <p class="text-sm">
            The Hoppus formula is primarily used for measuring Rough Squares, Semi Clean, Round Logs, etc. It's a widely
            recognized method in the timber industry, especially when dealing with roundwood or logs.
        </p>

        <p class="text-md font-italic">How it works:</p>
        <p class="text-sm">
            The Hoppus formula estimates the volume of a log based on its girth and length.
        </p>

        <p class="text-md font-bold">Formula:</p>
        <p class="text-sm font-bold">
            Volume = (Girth² * Length) / 144
        </p>

        <div class="surface-overlay">
            <ul class="list-disc">
                <li class="text-sm">Girth is the circumference of the log (measured in the middle or at the smallest
                    end)
                </li>
                <li class="text-sm">Length is the length of the log</li>
            </ul>
        </div>

        <p>
            <span class="text-xs font-italic">Note:</span>
            <span class="text-xs">This formula simplifies the complex geometry of a log into a square calculation, which
                is
                quicker but less accurate for non-square shapes.</span>
        </p>

        <hr />
        <p class="text-md font-bold">Volumetric Formula</p>

        <p class="text-md font-italic">Application:</p>
        <p class="text-sm">
            The Volumetric formula, also known as the Rectangle Prism formula, is suitable for measuring sawn timbers
            like
            size, lumber, square size, rectangle sizes, etc.
        </p>

        <p class="text-md font-italic">How it works:</p>
        <p class="text-sm">
            This formula calculates the volume of a cuboid or a rectangular prism, making it ideal for sawn or
            rectangular
            timber.
        </p>

        <p class="text-md font-bold">Formula:</p>
        <p class="text-sm font-bold">
            Volume = Length * Width * Height
        </p>

        <div class="surface-overlay">
            <ul class="list-disc">
                <li class="text-sm">Length is the length of the timber</li>
                <li class="text-sm">Width is the width of the timber</li>
                <li class="text-sm">Height is the height/thickness of the timber</li>
            </ul>
        </div>

        <p>
            <span class="text-xs font-italic">Note:</span>
            <span class="text-xs">The volumetric formula provides accurate measurements for rectangular pieces, but can
                underestimate the volume of round or irregularly shaped pieces.</span>
        </p>

        <hr />
        <p class="text-sm text-justify">
            In conclusion, understanding which formula to use and when is key to obtaining accurate measurements in
            timber
            trade or management. Always use the Hoppus formula for round logs and the Volumetric formula for sawn or
            rectangular timber.
        </p>


    </p-dialog>
</div>