import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app/commons/app-constants';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { CurrentUser } from 'src/app/interfaces/current-user';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import {
  ConfirmationService,
  MessageService,
  ConfirmEventType,
} from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import platform from 'platform';
import { Observable } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { DeviceService } from 'src/app/helper/device-service';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-login-add-user-to-company',
  templateUrl: './login-add-user-to-company.component.html',
  styleUrls: ['./login-add-user-to-company.component.css'],
  providers: [ToastMessagesService, MessageService],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class LoginAddUserToCompanyComponent implements OnInit {
  flipped = false;
  returnUrl!: string;
  formHeader!: string;
  formSubHeader!: string;

  form: any = {};
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  currentUser!: CurrentUser | null;
  googleURL = AppConstants.GOOGLE_AUTH_URL;
  linkedinURL = AppConstants.LINKEDIN_AUTH_URL;
  microsoftURL = AppConstants.MICROSOFT_AUTH_URL;
  tokenParam!: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private deviceService: DeviceService,
    private toastMessageService: ToastMessagesService,
    private titleService: Title,
    private appComponent: AppComponent,
    private confirmationService: ConfirmationService
  ) {
    this.setPageTitle();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      let token = params['token'];
      let error = params['error'];
      let redirectUri =
        'http://localhost:4200/login?forceLogin=true&method=google';
      let encodedRedirectUri = encodeURIComponent(redirectUri);
      let finalUrl = `${AppConstants.API_URL}oauth2/authorize/google?forceLogin=true&redirect_uri=${encodedRedirectUri}&invitationToken=a8cb7d30-7089-4cc3-8f23-223bb79812cf`;
      this.tokenParam = token;
      this.googleURL = finalUrl; //`${this.googleURL}&invitationToken=${token}`;

      console.log(`Google Url ${this.googleURL}`);
      if (!token) {
        this.confirm();
      }
      if (error) {
        this.confirm();
      }
      console.log(token);
    });
  }

  onSubmit(): void {
    console.log(`OnSUbmit Login.tml`);
    this.authService.login(this.form).subscribe(
      (data) => {
        //   this.tokenStorage.saveToken(data.accessToken);
        //  this.login(data.user);
      },
      (err) => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    );
  }

  /*  startGoogleLogin(info: any): Observable<any> {
    console.log(`startGoogleLogin`);
    const url = `${AppConstants.API_URL}openforall/google-login`;
    return this.httpClient.post(url, info);
  }

  async onGoogleLogin() {
    this.deviceService.getDeviceIdObservable().subscribe(
      (deviceInfo: any) => {
        console.log(`deviceInfo ${JSON.stringify(deviceInfo)}`);
        const platformInfo = platform.parse(navigator.userAgent);
        let info = {
          browser: platformInfo.name + ' ' + platformInfo.version,
          os: platformInfo?.os?.family + ' ' + platformInfo?.os?.version,
          deviceId: deviceInfo,
        };
        console.log(`INFO IS ${JSON.stringify(info)}`);
        this.startGoogleLogin(info).subscribe((response) => {
          console.log(`Response is ${JSON.stringify(response)}`);
          if (response.message) {
            console.log(`Response Url is ${response.message}`);
            window.location.href = response.message;
          }
        });
      },
      (error: any) => {
        console.error(`deviceInfo error ${JSON.stringify(error)}`);
      },
      () => {}
    );
  }

  onMicrosoftLogin() {}

  onLinkedInLogin() {} */

  startSocialLogin(url: string, info: any): Observable<any> {
    console.log(`startSocialLogin`);
    return this.httpClient.post(url, info);
  }

  async onSocialLogin(apiEndpoint: string) {
    this.deviceService.getDeviceIdObservable().subscribe(
      (deviceInfo: any) => {
        console.log(`deviceInfo ${JSON.stringify(deviceInfo)}`);
        const platformInfo = platform.parse(navigator.userAgent);
        let info = {
          browser: platformInfo.name + ' ' + platformInfo.version,
          os: platformInfo?.os?.family + ' ' + platformInfo?.os?.version,
          deviceId: deviceInfo,
        };
        console.log(`INFO IS ${JSON.stringify(info)}`);
        this.startSocialLogin(apiEndpoint, info).subscribe(
          (response) => {
            console.log(`Response is ${JSON.stringify(response)}`);
            if (response.message) {
              console.log(`Response Url is ${response.message}`);
              window.location.href = response.message;
            }
          },
          (error: any) => {
            console.error(`deviceInfo error ${JSON.stringify(error)}`);
            this.toastMessageService.showErrorMessage(
              'Login Error',
              'An error occurred while attempting to log in.'
            );
            // this.toastr.error("An error occurred while attempting to log in."); // Display error toast
          },
          () => {}
        );
      },
      (error: any) => {
        console.error(`deviceInfo error ${JSON.stringify(error)}`);
        this.toastMessageService.showErrorMessage(
          'Device Information Error',
          'An error occurred while getting device information.'
        );
        // this.toastr.error("An error occurred while getting device information."); // Display error toast
      },
      () => {}
    );
  }

  async onGoogleLogin() {
    let googleLoginUrl = `${AppConstants.API_URL}public/social-login/google`;

    if (this.tokenParam) {
      googleLoginUrl = `${googleLoginUrl}?invitationToken=${this.tokenParam}`;
    }

    this.onSocialLogin(googleLoginUrl);
  }

  async onMicrosoftLogin() {
    let microsoftLoginUrl = `${AppConstants.API_URL}public/social-login/microsoft`;
    if (this.tokenParam) {
      microsoftLoginUrl = `${microsoftLoginUrl}?invitationToken=${this.tokenParam}`;
    }
    this.onSocialLogin(microsoftLoginUrl);
  }

  async onLinkedinLogin() {
    let linkedinLoginUrl = `${AppConstants.API_URL}public/social-login/linkedin`;

    if (this.tokenParam) {
      linkedinLoginUrl = `${linkedinLoginUrl}?invitationToken=${this.tokenParam}`;
    }
    this.onSocialLogin(linkedinLoginUrl);
  }

  flip() {
    this.flipped = !this.flipped;
  }
  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }

  confirm() {
    this.confirmationService.confirm({
      message:
        'The token provided in the URL is invalid or missing. Please verify the URL or request a new token.',
      header: 'Invalid Token',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.router.navigate(['/login']);
      },
      reject: (type: any) => {
        this.router.navigate(['/login']);
      },
    });
  }
}

/*  implements OnInit {

  returnUrl!: string;

  form: any = {};
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  currentUser!: CurrentUser | null;
  googleURL = AppConstants.GOOGLE_AUTH_URL;
  linkedinURL = AppConstants.LINKEDIN_AUTH_URL;
  microsoftURL = AppConstants.MICROSOFT_AUTH_URL;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private showToastMessage: ToastMessagesService,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      let token = params['token'];
      this.googleURL = `${this.googleURL}&invitationToken=${token}`;
      if (!token) {
        this.confirm();
      }
      console.log(token);
    });
  }


  confirm() {
    this.confirmationService.confirm({
      message: 'The token provided in the URL is invalid or missing. Please verify the URL or request a new token.',
      header: 'Invalid Token',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.router.navigate(['/login']);
      },
      reject: (type: any) => {
        this.router.navigate(['/login']);
      }

    });
  }
  

} */
