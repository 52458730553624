<!-- <p-toast></p-toast> -->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" acceptLabel="Yes"
    rejectLabel="No"></p-confirmDialog>


<form [formGroup]="companyRegistrationForm" (ngSubmit)="onSubmit()">

    <p-card header="Company Details" subheader="Company Registration">
        <div class="formgrid grid">

            <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                <label for="name">Company Name <i class="text-red-400">*</i></label>
                <input id="name" type="text" formControlName="name" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400" name
                    *ngIf="companyRegistrationForm.invalid && (companyRegistrationForm.controls?.['name']?.invalid && (companyRegistrationForm.controls?.['name']?.dirty || companyRegistrationForm.controls?.['name']?.touched))">
                    <!--  <div *ngIf="companyRegistrationForm.controls?.['name']?.errors?.['required']">Company Name is
                        required.
                    </div> -->
                </div>
            </div>

            <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                <label for="industry">Industry <i class="text-red-400">*</i></label>
                <input id="industry" type="text" formControlName="industry" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400" name
                    *ngIf="companyRegistrationForm.invalid && (companyRegistrationForm.controls?.['industry']?.invalid && (companyRegistrationForm.controls?.['industry']?.dirty || companyRegistrationForm.controls?.['industry']?.touched))">
                    <div *ngIf="companyRegistrationForm.controls?.['industry']?.errors?.['required']">Industry is
                        required.
                    </div>
                </div>
            </div>

            <app-location-selection formControlName="location" (locationFormStatus)="onLocationFormStatus($event)"
            [locationFormValidators]="locationFormValidators"  class="w-full m-2" [existingLocationData]="existingLocationData"></app-location-selection>

            <!-- <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                <label for="address1">Address 1 <i class="text-red-400">*</i></label>
                <input id="address1" type="text" formControlName="address1" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400" name
                    *ngIf="companyRegistrationForm.invalid && (companyRegistrationForm.controls?.['address1']?.invalid && (companyRegistrationForm.controls?.['address1']?.dirty || companyRegistrationForm.controls?.['address1']?.touched))">
                    <div *ngIf="companyRegistrationForm.controls?.['address1']?.errors?.['required']">Address 1 is
                        required.
                    </div>
                </div>
            </div>

            <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                <label for="address2">Address 2 </label>
                <input id="address2" type="text" formControlName="address2" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>

            <div class="field col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4">
                <label for="country">Country <i class="text-red-400">*</i></label>

                <p-dropdown [options]="countries" formControlName="country" styleClass="field col-12 w-full"
                    optionLabel="name" placeholder="Select Country" [filter]="true"></p-dropdown>
                <div class="text-red-400"
                    *ngIf="companyRegistrationForm.controls?.['country']?.invalid && (companyRegistrationForm.controls?.['country']?.dirty || companyRegistrationForm.controls?.['country']?.touched)">
                    <div
                        *ngIf="companyRegistrationForm.invalid &&  (companyRegistrationForm.controls?.['country']?.errors?.['country'])">
                        Country is
                        required.</div>
                </div>
            </div>


            <div class="field col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4">
                <label for="state">State </label>
                <input id="state" type="text" formControlName="state" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>

            <div class="field col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4">
                <label for="city">City </label>
                <input id="city" type="text" formControlName="city" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>

            <div class="field col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4">
                <label for="pincode">Pincode</label>
                <input id="pincode" type="text" formControlName="pincode" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div> -->

            <div class="field col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4">
                <label for="companyId">Company ID </label>
                <input id="companyId" type="text" formControlName="companyId" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>

            <div class="field col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4">
                <label for="taxId">Tax ID </label>
                <input id="taxId" type="text" formControlName="taxId" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>

            <div class="field col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4">
                <label for="phone1">Phone 1 </label>
                <input id="phone1" type="text" formControlName="phone1" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>

            <div class="field col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4">
                <label for="phone2">Phone 2</label>
                <input id="phone2" type="text" formControlName="phone2" pInputText
                    class="text-base text-color surfacd-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>

            <div class="field col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4">
                <label for="email">Email</label>
                <input id="email" type="email" formControlName="email" pInputText
                    class="text-base text-color surfacd-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>

            <div class="field col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4">
                <label for="web">Web</label>
                <input id="web" type="text" formControlName="web" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>

           

        </div>
        <ng-template pTemplate="footer">
            <p-button [label]="submitButtonLabel" [disabled]="!companyRegistrationForm.dirty" icon="pi pi-check"
                type="submit"></p-button>
            <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary"
                [style]="{'margin-left': '.5em'}"></p-button>
            <!-- <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary"
                [style]="{'margin-left': '.5em'}" (click)="nameValidation()"></p-button> -->
        </ng-template>
    </p-card>

    <!-- <div class="p-field">
        <button pButton type="submit" label="Register"></button>
    </div> -->
</form>


<p-blockUI  [blocked]="blockedPanel">
    <!-- <i class="pi pi-lock" style="font-size: 3rem"></i> -->
    <i class="pi pi-spin pi-spinner" style="font-size: 4rem !important"></i>

</p-blockUI>