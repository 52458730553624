import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { CustomEmailValidator } from 'src/app/classes/custom-email.validator';
import { ConfirmDeleteCommonService } from 'src/app/commons/services/confirm-delete-common.service';
import { ResetCommonService } from 'src/app/commons/services/reset-common.service';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { PopulateDropdownService } from 'src/app/services/populate-dropdown.service';
import { NameCheckValidator } from 'src/app/validators/name-check-validator';
import { LocationSelectionComponent } from '../../commons/location-selection/location-selection.component';
import { Location } from '../../commons/location-selection/model/location';
import { LocationSelectionService } from '../../commons/location-selection/service/location-selection.service';
import { Seller } from '../../seller/model/seller';
import { LoadingSite } from '../model/loading-site';
import { LoadingSiteSharedDataService } from '../services/loading-site-shared-data.service';
import { LoadingSiteService } from '../services/loading-site.service';
import { SHARED_MODULES } from '../../shared-imports';
import { SellerComponent } from '../../seller/seller/seller.component';

@Component({
  selector: 'app-loading-site',
  templateUrl: './loading-site.component.html',
  styleUrls: ['./loading-site.component.css'],
  providers: [ToastMessagesService],
  standalone:true,
  imports:[SHARED_MODULES, LocationSelectionComponent, SellerComponent]
})
export class LoadingSiteComponent implements OnInit {
  blockedDocument = false;

  @Input() isFromContainerSidebar!: boolean;
  existingLocationData!: Location;
  @Input() showBreadcrumb: boolean = true;
  @Input() isSubmittedFromSidebar: boolean = false;
  @Output() loadingSiteSubmit: EventEmitter<any> = new EventEmitter();
  @Input() isAddFromContainer!: boolean;
  loadingSiteSidebarVisible: boolean = false;
  @ViewChild(LocationSelectionComponent, { static: false })
  locationSelectionComponent!: LocationSelectionComponent;
  @Input() titleFromSidebar!: string;
  @Input() headerFromSidebar!: string;
  @Input() subHeaderFromSidebar!: string;

  supplierSidebarVisible: boolean = false;

  editMode = false;
  isSubmitting = false;
  addEditButton = 'Save';
  loadingSiteData!: LoadingSite;

  saveForm() {
    throw new Error('Method not implemented.');
  }

  initSidebar() {
    console.log(`initSidebar  `);
    console.log(`initSidebar ${this.isSubmittedFromSidebar} `);
    if (this.isSubmittedFromSidebar) {
      console.log(`initSidebar  ${this.headerFromSidebar}`);
      this.formHeader = this.headerFromSidebar;
      this.formSubHeader = this.subHeaderFromSidebar;
      this.titleService.setTitle(this.titleFromSidebar);
    }
  }

  formHeader!: string;
  formSubHeader!: string;
  protected breadCrumbItems!: MenuItem[];
  breadCrumbHome!: MenuItem;
  loadingSiteForm!: FormGroup;
  form: any;
  supplierIdList!: Seller[];
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private customEmailValidator: CustomEmailValidator,
    private nameValidator: NameCheckValidator,
    private populateDropdownService: PopulateDropdownService,
    private loadingSiteService: LoadingSiteService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private loadingSiteSharedDataService: LoadingSiteSharedDataService,
    private titleService: Title,
    private route: ActivatedRoute,
    private toastService: ToastMessagesService,
    private locationSelectionService: LocationSelectionService,
    private confirmDeleteServiceCommon: ConfirmDeleteCommonService,
    private resetServiceCommon: ResetCommonService
  ) {
    this.setPageTitle();
    this.populateSuppliers();
  }

  ngOnInit(): void {
    this.populateBreadCrumb();

    this.breadCrumbHome = { icon: 'pi pi-home', routerLink: '/' };
    this.createForm();
    const url = this.router.url;
    if (url.includes('edit/loading-sites')) {
      this.editMode = true;


      this.route.data.subscribe((data) => {
        this.loadingSiteData = data['loadingSite'];
      });

      this.loadingSiteForm.patchValue(this.loadingSiteData);

      this.loadingSiteForm!.get('name')!.setAsyncValidators([]);
      console.log(`Edit Data is ${JSON.stringify(this.loadingSiteData)}`);
      this.existingLocationData = this.locationSelectionService.getLocationData(
        this.loadingSiteData.location
      );
      this.addEditButton = 'Update';
    }
    this.initSidebar();
  }

  private createForm() {
    this.loadingSiteForm = this.fb.group({
      loadingSiteId: [],
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(64),
        ],
        [this.nameValidator.checkLoadingSiteNameValidator()],
      ],
      contactPerson: [''],
      phone: [''],
      location: ['', [Validators.required]],
      operatingHours: [''],
      capacity: [''],
      currentVolume: [''],
      machinesInstalled: [''],
      notes: ['', [Validators.maxLength(2048)]],
    });
  }

  populateBreadCrumb() {
    this.breadCrumbItems = [
      { label: 'Loading Sites', routerLink: '/loading-sites' },
      { label: 'Add Loading Site' },
    ];
  }

  private populateSuppliers() {
    this.populateDropdownService
      .getSuppliers()
      .subscribe((suppliers) => (this.supplierIdList = suppliers));
  }

  onCityChange(event: Event) {
    const cityId = this.loadingSiteForm.controls['cityId'].value;
    console.log(
      `onCity Change ${event.target} value is ${JSON.stringify(cityId)}`
    );
    if (cityId == null) {
      this.loadingSiteForm.controls['state'].patchValue('');
    } else
      this.loadingSiteForm.controls['state'].patchValue(cityId.stateId.name);
  }

  onSubmit() {
    this.isSubmitting = true;

    this.locationSelectionComponent.onSubmit();
    const controlKeys = Object.keys(this.loadingSiteForm.controls);

    const invalidControlKeys = controlKeys.filter((key) => {
      const control = this.loadingSiteForm.controls[key];

      return control.invalid;
    });
    console.log('Invalid control keys:', invalidControlKeys);
    this.loadingSiteForm.markAllAsTouched();
    Object.keys(this.loadingSiteForm.controls).forEach((key) => {
      console.log(`Key is ${key}`);
      //  this.loadingSiteForm.get(key)?.updateValueAndValidity();
    });
    /*  this.myForm.controls['name'].markAsTouched();
     this.myForm.controls['cityId'].markAsTouched();
     this.myForm.controls['pincode'].markAsTouched(); */
    console.log(JSON.stringify(this.loadingSiteForm.value));
    if (this.loadingSiteForm.valid) {
      this.blockedDocument = true;
      this.loadingSiteService.postData(this.loadingSiteForm.value).subscribe(
        (resp) => {
          console.log(`On Submit Response is ${JSON.stringify(resp)}`);
          if (this.editMode) {
            this.toastService.showSuccessMessage(
              'Success',
              'Loading Site Edited Successfully'
            );
            this.router.navigate(['/loading-sites']);
          } else {
            this.onReset();
            this.toastService.showSuccessMessage(
              'Success',
              'Loading Site Created Successfully'
            );
            if (!this.isSubmittedFromSidebar) {
              this.confirmationService.confirm({
                message: 'Do you want to add more loading site?',
                accept: () => {
                  //Actual logic to perform a confirmation
                  this.loadingSiteForm.reset();
                },
                reject: () => {
                  this.router.navigate(['/loading-sites']);
                },
              });
            }
          }
          this.loadingSiteSubmit.emit();
          this.loadingSiteForm.reset();
          this.blockedDocument = false;
        },
        (error) => {
          console.log(`On Submit error is ${JSON.stringify(error)}`);
          this.toastService.showErrorMessage('Error', error.error.message);
          this.blockedDocument = false;
          // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
        },
        () => {
          console.log(`Finish onSubmit`);
          this.blockedDocument = false;
        }
      );
    } else {
      console.error(`Form is not valid`);
    }
  }

  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }

  // nameValidation  is used while editMode=true, if name is changed check if already exist, if name is same as it was entered then ignore async validation
  // add even function (input) in html
  nameValidation() {
    console.log(`nameValidation `);
    if (this.editMode) {
      const isValueChanged =
        this.loadingSiteData.name == this.loadingSiteForm.get('name')?.value;
      if (!isValueChanged) {
        console.log(`is value changed isValueChanged}`);
        this.loadingSiteForm!.get('name')!.setAsyncValidators([
          this.nameValidator.checkLoadingSiteNameValidator(),
        ]);
      } else {
        this.loadingSiteForm!.get('name')!.setAsyncValidators([]);
        this.loadingSiteForm.markAllAsTouched();
        this.loadingSiteForm.get('name')?.updateValueAndValidity();
        console.log(`is not value changed isValueChanged}`);
      }
    }
  }
  onSupplierSubmit(event: any) {
    this.populateSuppliers();
  }

  onLocationFormStatus(isLocationFormValid: boolean): void {
    console.log(`onLocationFormStatus ${isLocationFormValid}`);
    if (isLocationFormValid) {
      this.loadingSiteForm.controls['location'].setErrors(null);
    } else {
      this.loadingSiteForm.controls['location'].setErrors({
        invalidLocationForm: true,
      });
    }
  }

  locationFormValidators = {
    country: [Validators.required],
    state: [],
    city: [],
    zipcode: [],
  };

  private executeDeleteService(id: any): Promise<void> {
    return new Promise((resolve, reject) => {
      // Call your actual delete service here
      console.log(`id is ${id}`);
      this.blockedDocument = true;

      this.loadingSiteService.deleteListLoadingSites(id).subscribe(
        () => {
          resolve();
          this.blockedDocument = false;
        },
        (error) => {
          reject(error);
          this.blockedDocument = false;
        }
      );
    });
  }

  onDelete(id: any): void {
    this.loadingSiteForm.markAsPristine();
    this.confirmDeleteServiceCommon.confirm(
      id,
      () => this.executeDeleteService(id),
      '/loading-sites'
    );
  }

  onReset() {
    console.log('onReset called in buyer');
    this.locationSelectionComponent.resetLocationForm();
    this.resetServiceCommon.resetForm(this.loadingSiteForm);
  }

  @HostListener('window:keydown.meta.shift.s', ['$event'])
  handleSaveShortcut(event: KeyboardEvent) {
    this.onSubmit();
  }

  @HostListener('window:keydown.meta.shift.d', ['$event'])
  handleDeleteShortcut(event: KeyboardEvent) {
    if (this.editMode) {
      this.onDelete(this.loadingSiteData.loadingSiteId);
    }
  }
}
