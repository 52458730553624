import { Buyer } from "../../buyer/model/buyer";
import { Container } from "../../container/model/container";
import { ShippingLine } from "./shipping-line";

export class BillOfLading {
    billOfLadingId!: number;
    blNumber!: string;
    vesselName!: string;
    vesselCode!: string;
    eta!: Date;
    etd!: Date;
    containers!: Container[];
    shippingLineId!: ShippingLine;
    buyerId!: Buyer
    blUUID!: string;
    portOfLoading!:string;
    portOfDestination!:string;
    shortenUrl!: string;

}
