import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'; 
import { FileUpload } from 'primeng/fileupload';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { ContainerService } from 'src/app/modules/container/services/container.service';
import { Photos } from '../../model/photos';
import { MeasurementSharedDataService } from '../../services/measurement-shared-data.service';
import { MeasurementService } from '../../services/measurement.service';
import { VIEW_MODE } from 'src/app/modules/bill-of-lading/bill-of-lading-main/bill-of-lading-main.component';
import { Formula } from 'src/app/modules/product/product/product.component';
import { delay, finalize } from 'rxjs';
import { PhotoGalleryGroupDirective } from '@twogate/ngx-photo-gallery';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';

@Component({
  selector: 'app-bl-photos',
  templateUrl: './bl-photos.component.html',
  styleUrls: ['./bl-photos.component.css'],
  providers:[PhotoGalleryGroupDirective],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class BlPhotosComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() containerId!: any; // = CONTAINER_ID; //////
  @Input() containerUUID!: any; // = CONTAINER_ID; //////

  @Input() viewMode!: VIEW_MODE;
  VIEW_MODE = VIEW_MODE;
  isLoading!: boolean;

  @Input() formula!: Formula;
  images!: any[];

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5,
    },
    {
      breakpoint: '960px',
      numVisible: 4,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
    },
  ];

  constructor(
    private measurementService: MeasurementService,
    private router: Router,
    private route: ActivatedRoute,
    private containerService: ContainerService,
    private toastMessageService: ToastMessagesService,
    private measurementSharedDataService: MeasurementSharedDataService
  ) {
    //this.dummyImages();
  }
  ngOnDestroy(): void {
    // throw new Error('Method not implemented.');
  }

  ngAfterViewInit(): void {
    //  throw new Error('Method not implemented.');
    console.log(`VIEW_MODE ${JSON.stringify(this.viewMode)}`);

    console.log(`url is ${this.router.url}`);
    console.log(`this.containerId ${this.containerId}`);
    this.loadPublicData();

    /* if (this.viewMode == VIEW_MODE.public) {
      console.log(`IN PUBLIC MODE`);
      this.route.params.subscribe((params) => {
        this.containerUUID = params['containerUUID']; //`fe5329eb-b27b-4949-85b5-b21ff4de0897`;// params['containerUUID'];
        console.log(`UUID is ${this.containerUUID}`);
        if (this.containerUUID) {
          this.containerId = this.containerUUID;
        }
        this.loadPublicData();
      });
    } else {
      // this.containerId = this.measurementSharedDataService.getContainerIdStorage();
      this.loadPrivateData();
    } */
  }
  ngOnInit(): void {}

  loadPublicData() {
    console.log(`LoadPublic Data`);
    this.isLoading = true;
    this.containerService
      .getContainerImagesByContainerUUID(this.containerUUID)
      .pipe(
        delay(0), // delay of 2000ms
        finalize(() => (this.isLoading = false)) // this will run on complete, error, or unsubscribe
      )
      .subscribe(
        (data) => {
          this.images = data;
        },
        (error) => {
          this.toastMessageService.showErrorMessage(
            'Error',
            'Error occurred while fetching photos'
          );
        }
      );
  }

  loadPrivateData() {
    console.log(`Load Private Data`);
    this.isLoading = true;
    this.containerService
      .getContainerImagesByContainerUUID(this.containerUUID)
      .pipe(
        finalize(() => (this.isLoading = false)) // this will run on complete, error, or unsubscribe
      )
      .subscribe(
        (data) => {
          this.images = data;
        },
        (error) => {
          this.toastMessageService.showErrorMessage(
            'Error',
            'Error occurred while fetching photos'
          );
        }
      );
  }
}
