import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, from, map, of } from 'rxjs';
import { AppConstants } from '../commons/app-constants';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class SecretKeyService {
  constructor(private httpClient: HttpClient) {}

  encrypt(message: any, password: any): Observable<Uint8Array> {
    return from(
      (async () => {
        const msgBuffer = new TextEncoder().encode(message);
        const passwordBuffer = new TextEncoder().encode(password);

        const importedKey = await window.crypto.subtle.importKey(
          'raw',
          passwordBuffer,
          { name: 'PBKDF2' },
          false,
          ['deriveKey']
        );

        const salt = window.crypto.getRandomValues(new Uint8Array(16));

        const key = await window.crypto.subtle.deriveKey(
          {
            name: 'PBKDF2',
            salt: salt,
            iterations: 250000,
            hash: 'SHA-256',
          },
          importedKey,
          { name: 'AES-GCM', length: 256 },
          false,
          ['encrypt', 'decrypt']
        );

        const iv = window.crypto.getRandomValues(new Uint8Array(12));
        const encryptedContent = await window.crypto.subtle.encrypt(
          {
            name: 'AES-GCM',
            iv: iv,
          },
          key,
          msgBuffer
        );

        const encryptedContentArr = new Uint8Array(encryptedContent);
        let buffer = new Uint8Array(
          salt.byteLength + iv.byteLength + encryptedContentArr.byteLength
        );
        buffer.set(salt, 0);
        buffer.set(iv, salt.byteLength);
        buffer.set(encryptedContentArr, salt.byteLength + iv.byteLength);
        return buffer;
      })()
    );
  }

  decrypt(encryptedMessage: any, password: any): Observable<string> {
    return from(
      (async () => {
        // console.log(`encryptedMessage is ${encryptedMessage}`);
        const dataString = atob(encryptedMessage);
        // console.log(`dataString is ${dataString}`);
        const dataArray = new Uint8Array(
          dataString.split('').map((char) => char.charCodeAt(0))
        );
        // console.log(`dataArray is ${dataArray}`);
        const passwordBuffer = new TextEncoder().encode(password);

        const importedKey = await window.crypto.subtle.importKey(
          'raw',
          passwordBuffer,
          { name: 'PBKDF2' },
          false,
          ['deriveKey']
        );

        const salt = dataArray.slice(0, 16);
        const iv = dataArray.slice(16, 16 + 12);
        const data = dataArray.slice(16 + 12);

        const key = await window.crypto.subtle.deriveKey(
          {
            name: 'PBKDF2',
            salt: salt,
            iterations: 250000,
            hash: 'SHA-256',
          },
          importedKey,
          { name: 'AES-GCM', length: 256 },
          false,
          ['encrypt', 'decrypt']
        );

        const decryptedContent = await window.crypto.subtle.decrypt(
          {
            name: 'AES-GCM',
            iv: iv,
          },
          key,
          data
        );

        return new TextDecoder().decode(decryptedContent);
      })()
    );
  }
}
