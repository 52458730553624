import { Component } from '@angular/core';

@Component({
  selector: 'app-unidentified-error',
  templateUrl: './unidentified-error.component.html',
  styleUrls: ['./unidentified-error.component.css']
})
export class UnidentifiedErrorComponent {

}
