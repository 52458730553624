import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuyerComponent } from './buyer/buyer.component';
import {CardModule} from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {MenuItem} from 'primeng/api';
import { ReactiveFormsModule } from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputMaskModule} from 'primeng/inputmask';
import {InputTextModule} from 'primeng/inputtext';
import {ToastModule} from 'primeng/toast';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService, ConfirmEventType, MessageService} from 'primeng/api';
import { BuyersListComponent } from './buyers-list/buyers-list.component';
import {TableModule} from 'primeng/table';
import {PaginatorModule} from 'primeng/paginator';
import {MultiSelectModule} from 'primeng/multiselect';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { SpeedDialModule } from 'primeng/speeddial';
import { SidebarModule } from 'primeng/sidebar';
import { CommonsModule } from '../commons/commons.module';
import { SkeletonModule } from 'primeng/skeleton';
import { BlockUIModule } from 'primeng/blockui';
import { PhotoGalleryModule } from '@twogate/ngx-photo-gallery';


@NgModule({ 
  declarations: [
    // BuyerComponent,
    // BuyersListComponent,
  ],
  imports: [
    CommonModule,
    CardModule, 
    ButtonModule,
    BreadcrumbModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextareaModule,
    InputMaskModule,
    InputTextModule,
    ToastModule,
    ConfirmDialogModule,
    TableModule,
    PaginatorModule,
    MultiSelectModule,
    SpeedDialModule,
    SidebarModule,
    // CommonsModule,
    SkeletonModule,
    BlockUIModule,
    PhotoGalleryModule,


    BuyerComponent,
    BuyersListComponent,
  ],
  exports: [BuyerComponent],
  providers: [ConfirmationService,MessageService,ToastMessagesService]
})
export class BuyerModule { }
