import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators, FormsModule } from '@angular/forms';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ClientJS } from 'clientjs';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/tally-sheet/user.service';
import { AppConstants } from 'src/app/commons/app-constants';



@Component({
  selector: 'app-user-registration',
  standalone: true,
  imports: [FormsModule, CommonModule, ReactiveFormsModule],
  templateUrl: './user-registration.component.html',
  styleUrl: './user-registration.component.scss'
})
export class TallySheetUserRegistrationComponent {
  registrationForm!: FormGroup;
  loginForm!: FormGroup;
  facebookUrl = `${AppConstants.API_URL}oauth2/authorization/facebook?deviceId=12312312`;

  constructor(private fb: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private http: HttpClient) {
    localStorage.clear();
    const client = new ClientJS();
    const fingerprint = client.getFingerprint();

    // Print the 32bit hash id to the console
    console.log(fingerprint);
    this.facebookUrl = `${AppConstants.API_URL}oauth2/authorization/facebook?deviceId=${fingerprint}`;
    //  alert(authService.getToken());
  }

  onClickUrl() {
    this.http.get(this.facebookUrl).subscribe(
      (success: any) => {
        console.log(`success ${JSON.stringify(success)}`)
      },
      (error: any) => {
        console.error(`error ${JSON.stringify(error)}`)

      },
      () => { }
    )

  }

  ngOnInit() {
    this.initRegistrationForm();
    this.initLoginForm();
  }

  private initLoginForm() {
    this.loginForm = this.fb.group({
      email: ['punj@rikexim.com', Validators.required],
      password: ['123456', [Validators.required, Validators.minLength(6)]],
    });
  }

  private initRegistrationForm() {
    this.registrationForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      mobile: ['', Validators.required],
    });
  }

  registerUser() {
    if (this.registrationForm.valid) {
      this.userService.registerUser(this.registrationForm.value).subscribe(
        (response) => {
          console.log('User registered successfully', response);
        },
        (error) => {
          console.error('Registration failed', error);
        }
      );
    } else {
      console.error('Form is invalid');
    }
  }

  loginUser() {
    console.log(`Login ${JSON.stringify(this.loginForm.value)}`);

    this.userService.loginUser(this.loginForm.value).subscribe(
      (success: any) => {
        // Log the entire success object to inspect its structure
        console.log('Full response:', success);

        // Try to access the redirectUrl
        const redirectUrl = success.redirectUrl;  // Access the property directly

        // Log what you are trying to access
        console.log(`Redirect URL: ${redirectUrl}`);

        // Check if redirectUrl exists before navigating
        if (redirectUrl) {
          // this.router.navigateByUrl(redirectUrl, { replaceUrl: true });
          window.location.href = redirectUrl;
        } else {
          console.error('Redirect URL is missing from the response.');
        }
      },
      (error: any) => {
        console.error(`Error: ${JSON.stringify(error)}`);
      },
      () => { },
    );
  }



}
