import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Company } from '../model/company';
import { CompanyService } from '../services/company.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { UtilService } from 'src/app/services/util.service';
import { UserRegistrationService } from '../services/user-registration.service';
import { Title } from '@angular/platform-browser';
import { Location } from '../../commons/location-selection/model/location';
import { LocationSelectionService } from '../../commons/location-selection/service/location-selection.service';
import { SHARED_MODULES } from '../../shared-imports';
import { LocationSelectionComponent } from '../../commons/location-selection/location-selection.component';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css'],
  providers:[ToastMessagesService],
  standalone: true,
  imports: [SHARED_MODULES, LocationSelectionComponent]
})
export class CompanyComponent implements OnInit, AfterViewInit, OnDestroy {
  blockedPanel = false;
  isMobile: any;
  countries: any;
  companyRegistrationForm!: FormGroup;
  userRegistrations: Company[] = [];
  submitButtonLabel!: string;
  navigate!: string;
  existingLocationData!: Location;
  isEditMode = false;
  isFirstLogin = false;

  constructor(
    private fb: FormBuilder,
    private companyService: CompanyService,
    private messageService: ToastMessagesService,
    private router: Router,
    private utilityService: UtilService,
    private userRegistranService: UserRegistrationService,
    private route: ActivatedRoute,
    private titleService: Title,
    private locationSelectionService : LocationSelectionService

  ) {
    this.route.url.subscribe((url: any) => {
      console.log(`URL IS && ${url}`);
    /*   if (url.includes('register-company')) { 
        this.isFirstLogin = true;
      } */
      if (window.location.pathname.includes('register-company')) { 
        this.isFirstLogin = true;
       
    }
    
    });
    this.createForm();
    this.renameSubmitButton();
    this.getAllCountries();
    this.setPageTitle();
    if (this.isMobile) {
    }
  }

  ngOnInit(): void {

  }


  ngAfterViewInit(): void {
    this.populateCompanyFromUserId();
  }

  private async createForm() {
    this.companyRegistrationForm = this.fb.group({
      name: ['', Validators.required],
      industry: ['', Validators.required],
      taxId: [''],
      companyId: [''],
      phone1: [''],
      phone2: [''],
      web: [''],
      location: ['']
    });

    //const isFirstTime = await this.isFirstTimeUser();


  }


  private addCompany(company: Company) {
    this.blockedPanel = true;
    this.companyService.save(company).subscribe(
      (data: Company) => {
        this.userRegistrations.push(data)
        if(!this.isEditMode){
        this.messageService.showSuccessMessage('Saved', 'Successfully Registered');
        }else{
          this.messageService.showInfoMessage('Update', 'Successfully Updated');
        }
        if(this.isFirstLogin){
          this.router.navigate(['registration/subscription']);
        }
        
        this.blockedPanel = false;
      },
      (error) => {
        this.messageService.showErrorMessage('Error', 'Error while registering');
        this.blockedPanel = true;
      },
      () => { 
        this.blockedPanel = true;
      });
  }

  populateCompanyFromUserId(): void {
    this.companyService.getCompanyByUserId().subscribe((data: Company) => {
      this.isEditMode = true;
        this.existingLocationData = this.locationSelectionService.getLocationData(data.location);
      this.companyRegistrationForm.patchValue(data);
      this.checkInvalidFields();
      if (data) {
        this.markAllControlsAsDirtyAndTouched(this.companyRegistrationForm);
      }

    });
  }

  private renameSubmitButton() {
    const url = this.router.url; // Get current URL

    if (url.includes('registration/company')) { ///list/subscription
      this.submitButtonLabel = 'Next'
      this.navigate = '/registration/subscription';
    } else {
      this.submitButtonLabel = 'Update';
      this.navigate = '/buyers';
    }
  }

  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
     /*  this.companyRegistrationForm = data['header'];
      this.companyRegistrationForm = data['subHeader']; */
    });
  }

  onSubmit() {
    this.checkInvalidFields();
    /* if(this.isFirstLogin){
      this.router.navigate(['/registration-subscription']);
    } */
    //    this.companyRegistrationForm.setErrors(null);
    
    console.log()
    if (this.companyRegistrationForm.valid) {
      console.table(this.companyRegistrationForm.value);
      this.addCompany(this.companyRegistrationForm.value);
      // Submit the form data to your server

    }
  }

  /* private isFirstTimeUser(): Promise<boolean> {
    return new Promise((resolve) => {
      this.route.url.subscribe(segments => {
        const urlContainsRegistration = segments.some(segment => segment.path === 'registration');
        resolve(urlContainsRegistration);
      });
    });
  } */

  checkInvalidFields() {
    for (const controlName in this.companyRegistrationForm.controls) {
      const control = this.companyRegistrationForm.controls[controlName];
      if (control.invalid) {
        console.log(`Invalid field: ${controlName}`);
      }
    }
  }



  private getAllCountries() {
    this.userRegistranService.getAllCountries().subscribe
      ((data) => {
        this.countries = data;
        // console.log(`Data Countries ${data}`)

      },
        (error) => {

        },
        (() => { }));
  }

  private markAllControlsAsDirtyAndTouched(control: AbstractControl): void {
    if (control instanceof FormGroup) {
      for (const key in control.controls) {
        this.markAllControlsAsDirtyAndTouched(control.controls[key]);
      }
    } else {
      control.markAsDirty();
      control.markAsTouched();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = this.utilityService.isMobile();
    if (this.isMobile) {
    }
    console.log(`is mobile ${this.utilityService.isMobile()}`);
  }

  ngOnDestroy(): void {

  }

  locationFormValidators = {
    country: [Validators.required],
    state: [Validators.required],
    city: [Validators.required],
    zipcode: [Validators.required],
  };

  onLocationFormStatus(isLocationFormValid: boolean): void {
    console.log(`onLocationFormStatus ${isLocationFormValid}`);
    if (isLocationFormValid) {
      this.companyRegistrationForm.controls['location'].setErrors(null);
    } else {
      this.companyRegistrationForm.controls['location'].setErrors({
        invalidLocationForm: true,
      });
    }
  }



}
