<form *ngIf="!isLoggedIn" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
	<div class="center-screen">

		<div class="flip-container flip-container-right" [class.flipped]="flipped">
			<div class="flipper">
				<!-- <p-card class="back">


					<div class="w-full lg:w-*">
						<div class="text-center mb-5">
							<img src="https://rikexim.com/assets/images/resources/rikexim_200x50_dark.webp" alt="Lumberlinq" class="mb-3"
							style="height: 4rem;">
							<div class="text-900 text-3xl font-medium mb-3">Already having account?</div>
							<a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer" (click)="flip()">Login
								now!</a>
							<hr />
						</div>

						<div class="w-full">
							<button pButton type="button" label="Sign up with Google" class="google-btn w-full mb-3"
								icon="pi pi-google"></button>

							<button pButton type="button" label="Sign up with Microsoft"
								class="microsoft-btn w-full mb-3" icon="pi pi-microsoft"></button>

							<button pButton type="button" label="Sign up with LinkedIn" class="linkedin-btn w-full mb-3"
								icon="pi pi-linkedin"></button>

						</div>
					</div>


				</p-card> -->
				<p-card  class="front">
					<div class="w-full lg:w-*">
						<div class="text-center mb-5">
							<img src="https://rikexim.com/assets/images/resources/rikexim_200x50_dark.webp" 
							alt="Lumberlinq" class="mb-3" style="height: 4rem;">
							<div class="text-900 text-3xl font-medium mb-3" >You're invite to Join us!</div>
							<!-- <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
								(click)="flip()">Create today!</a>
							<hr /> -->
						</div>

						<div class="w-full">
							<button pButton type="button" label="Sign in with Google" class="google-btn w-full mb-3"
								icon="pi pi-google" (click)="onGoogleLogin();"></button>

							<button pButton type="button" label="Sign in with Microsoft"
								class="microsoft-btn w-full mb-3" icon="pi pi-microsoft"
								(click)="onMicrosoftLogin();"></button>

							<button pButton type="button" label="Sign in with LinkedIn" class="linkedin-btn w-full mb-3"
								icon="pi pi-linkedin" (click)="onLinkedinLogin();"></button>

						</div>
					</div>
				</p-card>
			</div>
		</div>
	</div>
</form>
<div class="alert alert-success" *ngIf="isLoggedIn">
	You're already loggedin as
	{{currentUser?.firstName ?? ''}} <br>Logged in as {{ currentUser?.email ?? '' }}.
</div>