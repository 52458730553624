import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { NameCheckValidator } from 'src/app/validators/name-check-validator';
import { PopulateDropdownService } from 'src/app/services/populate-dropdown.service';
import { ConfirmationService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { Product } from '../model/product';
import { ProductService } from '../services/product.service';
import { ProductSharedDataService } from '../services/product-shared-data.service';
import { ConfirmDeleteCommonService } from 'src/app/commons/services/confirm-delete-common.service';
import { ResetCommonService } from 'src/app/commons/services/reset-common.service';
import { LocationSelectionComponent } from '../../commons/location-selection/location-selection.component';
import { SHARED_MODULES } from '../../shared-imports';

export enum Formula {
  Hoppus = 'HOPPUS',
  RectanglePrism = 'RECTANGLE_PRISM',
  SQUARED = 'SQUARED',
  ROUGH = 'ROUGH'
}

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
  providers: [ToastMessagesService],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class ProductComponent implements OnInit {
  @Input() isFromContainerSidebar!: boolean;
  blockedDocument = false;

  @Input() showBreadcrumb: boolean = true;

  @Input() titleFromSidebar!: string;
  @Input() headerFromSidebar!: string;
  @Input() subHeaderFromSidebar!: string;
  @Input() isSubmittedFromSidebar: boolean = false;
  @Output() productSubmit: EventEmitter<any> = new EventEmitter();
  showFormulaHelpDialog = false;

  editMode = false;
  isSubmitting = false;
  addEditButton = 'Save';
  productEditData!: Product;
  formulas!: { name: string; key: string | null }[];

  saveForm() {
    throw new Error('Method not implemented.');
  }

  formHeader!: string;
  formSubHeader!: string;
  protected items!: MenuItem[];
  home!: MenuItem;
  productForm!: FormGroup;
  form: any;
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private nameValidator: NameCheckValidator,
    private populateDropdownService: PopulateDropdownService,
    private productService: ProductService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private productSharedDataService: ProductSharedDataService,
    private titleService: Title,
    private route: ActivatedRoute,
    private toastService: ToastMessagesService,
    private confirmDeleteServiceCommon: ConfirmDeleteCommonService,
    private resetServiceCommon: ResetCommonService
  ) {
    this.setPageTitle();
  }
  ngOnInit(): void {
    this.populateBreadCrumb();
    this.initForumla();

    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.createForm();
    const url = this.router.url;
    if (url.includes('edit/product')) {
      this.editMode = true;
      this.route.data.subscribe((data) => {
        this.productEditData = data['product'];
      });

      this.productForm.patchValue(this.productEditData);
      console.log(`From storge ${this.productEditData}`);
      console.log(`From storge ${JSON.stringify(this.formulas)}`);
      const formulaObj = this.formulas.find((f) => {
        console.log(
          `formulaObj  f is ${JSON.stringify(f)} === ${this.productEditData.formula
          } this.productEditData.formula`
        );
        return f.key === this.productEditData.formula;
      });

      this.productForm.patchValue({
        formula: formulaObj,
      });

      this.productForm!.get('name')!.setAsyncValidators([]);
      this.addEditButton = 'Update';
    }
    this.initSidebar();
  }

  private createForm() {
    this.productForm = this.fb.group({
      productId: [],
      formula: [null, Validators.required],
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(64),
        ],
        [this.nameValidator.checkProductNameValidator()],
      ],
      description: ['', [Validators.minLength(3), Validators.maxLength(1024)]],
      hsCode: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(8),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
    });
  }

  populateBreadCrumb() {
    this.items = [
      { label: 'Products', routerLink: '/products' },
      { label: 'Add Product' },
    ];
  }

  initSidebar() {
    console.log(`initSidebar  `);
    console.log(`initSidebar ${this.isSubmittedFromSidebar} `);
    if (this.isSubmittedFromSidebar) {
      console.log(`initSidebar  ${this.headerFromSidebar}`);
      this.formHeader = this.headerFromSidebar;
      this.formSubHeader = this.subHeaderFromSidebar;
      this.titleService.setTitle(this.titleFromSidebar);
    }
  }

  // product.component.ts

  onSubmit(event?: Event) {
    this.productForm.markAllAsTouched();
    if (event) {
      event.stopPropagation();
    }

    this.isSubmitting = true;

    this.validateFormFields();

    if (this.productForm.valid) {
      // this.saveProductData();
      let productData = { ...this.productForm.value };

      // If the formula field exists in the form, and is an object with a 'key' field, replace it with the key field.
      if (
        productData.formula &&
        typeof productData.formula === 'object' &&
        'key' in productData.formula
      ) {
        productData.formula = productData.formula.key;
      }
      this.saveProductData(productData);
    } else {
      console.error('Form is not valid');
    }
  }

  validateFormFields() {
    Object.keys(this.productForm.controls).forEach((key) => {
      const control = this.productForm.controls[key];
      if (control.invalid) {
        console.log('Invalid control key:', key);
      }
    });
    this.productForm.markAllAsTouched();
  }

  saveProductData(productData: any) {
    this.blockedDocument = true;
    this.productService.postData(productData).subscribe(
      (resp) => {
        console.log(`On Submit Response is ${JSON.stringify(resp)}`);
        this.toastService.showSuccessMessage(
          'Success',
          this.editMode
            ? 'Product Edited Successfully'
            : 'Product Created Successfully'
        );

        if (this.editMode) {
          this.router.navigate(['/products']);
        } else {
          this.postProductCreationActions();
          this.onReset();
        }
        this.blockedDocument = false;
      },
      (error) => {
        this.blockedDocument = false;
        console.log(`On Submit error is ${JSON.stringify(error)}`);
        this.toastService.showErrorMessage('Error', error.error.message);
      },
      () => {
        console.log('Finish onSubmit');
        this.blockedDocument = false;
      }
    );
  }

  postProductCreationActions() {
    if (!this.isSubmittedFromSidebar) {
      this.confirmationService.confirm({
        message: 'Do you want to add more product?',
        accept: () => {
          this.productForm.reset();
        },
        reject: () => {
          this.router.navigate(['/products']);
        },
      });
    } else {
      this.productSubmit.emit(); // emit event to parent to close sidebar
      this.productForm.reset();
    }
  }

  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }

  // nameValidation  is used while editMode=true, if name is changed check if already exist, if name is same as it was entered then ignore async validation
  // add even function (input) in html
  nameValidation() {
    console.log(`nameValidation `);
    if (this.editMode) {
      const isValueChanged =
        this.productEditData.name == this.productForm.get('name')?.value;
      if (!isValueChanged) {
        console.log(`is value changed isValueChanged}`);
        this.productForm!.get('name')!.setAsyncValidators([
          this.nameValidator.checkProductNameValidator(),
        ]);
      } else {
        this.productForm!.get('name')!.setAsyncValidators([]);
        this.productForm.markAllAsTouched();
        this.productForm.get('name')?.updateValueAndValidity();
        console.log(`is not value changed isValueChanged}`);
      }
    }
  }

  getErrorMessage() {
    let myFieldControl = this.productForm.get('name');
    if (myFieldControl!.hasError('required')) {
      return 'This field is required.';
    }
    if (myFieldControl!.hasError('invalid')) {
      return 'This field is invalid.';
    }
    //console.log(`Error ${JSON.stringify(myFieldControl?.hasError)} `)
    return 'Unknown error.';
  }

  getFormValidationErrors() {
    Object.keys(this.productForm.controls).forEach((key) => {
      console.log(
        `Each key is ${key} value is ${this.productForm!.get(key)?.value
        } and hasError ${this.productForm!.get(key)!.errors!}`
      );
      const controlErrors: ValidationErrors =
        this.productForm!.get(key)!.errors!;

      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
            controlErrors[keyError]
          );
        });
      }
    });
  }

  initForumla() {
    this.formulas = [
      { name: 'Hoppus', key: Formula.Hoppus },
      { name: 'Volumetric', key: Formula.RectanglePrism },
    ];
  }

  onFormulaHelp() {
    this.showFormulaHelpDialog = this.showFormulaHelpDialog ? false : true;
  }

  private executeDeleteService(id: any): Promise<void> {
    return new Promise((resolve, reject) => {
      // Call your actual delete service here
      console.log(`id is ${id}`);
      this.blockedDocument = true;

      this.productService.deleteProducts(id).subscribe(
        () => {
          resolve();
          this.blockedDocument = false;
        },
        (error) => {
          reject(error);
          this.blockedDocument = false;
        }
      );
    });
  }

  onDelete(id: any): void {
    this.productForm.markAsPristine();
    this.confirmDeleteServiceCommon.confirm(
      id,
      () => this.executeDeleteService(id),
      '/products'
    );
  }

  onReset() {
    console.log('onReset called in buyer');
    this.resetServiceCommon.resetForm(this.productForm);
  }

  @HostListener('window:keydown.meta.shift.s', ['$event'])
  handleSaveShortcut(event: KeyboardEvent) {
    this.onSubmit();
  }

  @HostListener('window:keydown.meta.shift.d', ['$event'])
  handleDeleteShortcut(event: KeyboardEvent) {
    if (this.editMode) {
      this.onDelete(this.productEditData.productId);
    }
  }
}
