import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { Buyer } from '../model/buyer';
import { HttpParams } from '@angular/common/http';
import { Page } from 'src/app/commons/models/page';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class BuyerService {

  constructor(private http: HttpClient) { }

  getData(): Observable<Buyer> {
    return this.http.get<Buyer>(AppConstants.API_URL + 'api/v1/buyer');
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  postData(data: Buyer): Observable<Buyer> {
    return this.http.post<Buyer>(AppConstants.API_URL + 'api/v1/buyer', data, { responseType: 'json' });
  }

  getByBuyerName(name: string): Observable<any | undefined> {
    const response = this.http.get(AppConstants.API_URL + `api/v1/check-buyer-name?name=${name}`, httpOptions);
    console.log(`getByCompanyName Response is ${JSON.stringify(response)}`)
    return response;
  }

  getByGST(gst: string): Observable<any | undefined> {
    const response = this.http.get(AppConstants.API_URL + `api/v1/check-gst?gst=${gst}`, httpOptions);
    console.log(`getByGST Response is ${JSON.stringify(response)}`)
    return response;
  }
  // <Page<Book>
  getListBuyers(httpParams?: HttpParams): Observable<Page<Buyer>> {
    let url = `${AppConstants.API_URL}api/v1/buyers?`;
    if (httpParams) {
      url = url + httpParams;
    }
    console.log(`url is ${url}`)
    return this.http.get<Page<Buyer>>(url);
  }

  getBuyersGlobalFilter(httpParams?: HttpParams): Observable<Page<Buyer>> {
    let url = `${AppConstants.API_URL}api/v1/buyers/search?`;
    if (httpParams) {
      url = url + httpParams;
    }
    console.log(`url is ${url}`)
    return this.http.get<Page<Buyer>>(url);
  }  ///buyers/{id}

  deleteBuyers(id: any, forceDelete: boolean = false): Observable<string> {
    let url = `${AppConstants.API_URL}api/v1/buyers/${id}?forceDelete=${forceDelete}`;
    console.log(`url is ${url}`)
    return this.http.delete<string>(url);
  }


  del() {
  }
  deleteBuyers1(id: any): Observable<string> {
    let url = `${AppConstants.API_URL}api/v1/buyers/${id}`;
    console.log(`Calling deleteBuyers with URL: ${url}`); // Log the URL
    return this.http.delete<string>(url).pipe(
      catchError(error => {
        console.error('Error in deleteBuyers:', error); // Log any errors
        return throwError(error);
      })
    );
  }

  getBuyerById(id: any): Observable<Buyer> {
    const url = AppConstants.API_URL + 'api/v1/buyer/' + id;
    console.log(`getBuyerById Url is ${url}`);
    return this.http.get<Buyer>(url);

    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

}
