<!-- <app-subscription></app-subscription> -->

<p-confirmDialog #cd [style]="{ 'width': '70vw','min-width': '280px','max-width': '600px' }" >
    <ng-template pTemplate="header">
        <h3>Purchase Successful!</h3>
    </ng-template>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-sign-out" label="Proceed to Log out"
            (click)="logoutAndRedirectToLogin();cd.accept()"></button>
    </ng-template>
</p-confirmDialog>
<div class="pt-5">

    <!-- {{ activeIndexAccordion}} {{ mainSelectedPackage | json}} -->
    <p-accordion #accordion    [(activeIndex)]="activeIndexAccordion" (onOpen)="handleTabOpen($event)"
        (onClose)="handleTabClose($event)">

        <p-accordionTab header="Select Package" >

            <div *ngIf="(isPackageLoading | async)">
                <div class="text-center">
                    <span class="text-center font-bold"> 🌟 Just a moment, we're curating an exclusive offer tailored
                        just
                        for you. Your special deal is
                        on its way! 🌟
                    </span>
                </div>
                <div>
                    <p-skeleton width="100%" height="150px" styleClass="mt-2 mb-2"></p-skeleton>
                </div>


            </div>

            <app-subscription-package-selection *ngIf="!(isPackageLoading | async)" [packagesList]="packages"
                [selectedCurrency]="selectedCurrency" (packageSelected)="handlePackageSelected($event)"
                (currencySelected)="handleCurrencySelected($event)"></app-subscription-package-selection>

            <div class="formgrid grid">
                <p-button type="button" label="Next" icon="pi pi-angle-right" iconPos="right"
                    [disabled]="!mainSelectedPackage" (onClick)="confirmPackageSelection($event)"></p-button>
            </div>
        </p-accordionTab>


        <p-accordionTab header="Package Details" [disabled]="disablePackageDetailsAccordion">
            <app-subscription-package-details [selectedPackage]="mainSelectedPackage"
                [selectedPackagePricingPlans]="listPackagePricingPlans"
                (paymentCycleSelectedOutput)="handlePaymentCycleSelected($event)" [selectedCurrency]="selectedCurrency"
                (autoDebitOutput)="handleAutoDebit($event)"></app-subscription-package-details>
            <div class="formgrid grid pt-3">
                <p-button label="Next" icon="pi pi-angle-right" iconPos="right" [disabled]="!selectedDuration"
                    (onClick)="gotoAccordionIndex(2)"></p-button>
            </div>
        </p-accordionTab>


        <p-accordionTab header="Billing Details" [disabled]="!selectedDuration">
            <app-subscription-billing-details [selectedCurrency]="selectedCurrency"
                (billingId)="handleBillingId($event)" (onBillDetailsSubmit)="handleBillingDetailsSubmission($event)"
                (billDetailsSubmitObject)="handleBillingDetailsSubmitObject($event)"
                [selectedCurrency]="selectedCurrencyChange"></app-subscription-billing-details>
        </p-accordionTab>

        <p-accordionTab header="Evaluate Order" [disabled]="!isBillingDetailsSubmitted">
            <!-- <p-accordionTab header="Evaluate Order"> -->
            <!-- <p-accordionTab header="Evaluate Order"> -->
            <app-subscription-evaluate-order [selectedPackage]="mainSelectedPackage"
                [selectedCurrency]="selectedCurrency" [selectedDuration]="selectedDuration"
                [totalBillAmount]="totalBillAmount" [cgst]="cgst" [sgst]="sgst" [grandTotal]="grandTotal"
                [duration]="duration" [promoApplied]="promoObject"></app-subscription-evaluate-order>

            <div class="flex justify-content-end flex-wrap p-2">
                <div class="flex align-items-center justify-content-center">
                    <input pInputText type="text" class="p-inputtext-lg" placeholder="Promo Code"
                        [(ngModel)]="promoCode" />
                </div>
                <div class="flex align-items-center justify-content-center pl-2">
                    <p-button label="Apply" (onClick)="onApplyPromoCode()" [disabled]="!promoCode"></p-button>
                </div>
            </div>
            <div class="flex justify-content-end flex-wrap p-2" *ngIf="promoCode">
                <div class="flex align-items-center justify-content-center">
                    <p class="text-green-500" *ngIf="promoAppliedMessage"> {{ promoAppliedMessage }}</p>
                    <p class="text-red-500" *ngIf="promoErrorMessage"> {{ promoErrorMessage }}</p>
                    <!-- <small id="username-help">Enter your username to reset your password.</small> -->
                </div>

            </div>


            <div class="formgrid grid pt-3">
                <p-button label="Pay Now" icon="pi pi-money-bill" iconPos="right" (click)="onPayNow()"></p-button>
            </div>
        </p-accordionTab>

    </p-accordion>

</div>
<!-- <p-button label="Pay Now"  (click)="gotoAccordionIndex(3);onPayNow()"></p-button> -->
<p-toast></p-toast> 