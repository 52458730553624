import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of, from, switchMap,forkJoin } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { BuyerService } from '../../buyer/services/buyer.service';
import { ContainerService } from '../../container/services/container.service';
import { EncryptionService } from 'src/app/services/encryption.service';
import { WebSocketService } from 'src/app/services/tally-sheet/socket.service';


@Injectable({
  providedIn: 'root'
})
export class ContainerDetailsResolver implements Resolve<any> {

  constructor(
    private encryptionService: EncryptionService,
    private containerService: ContainerService,
    private webSocketService: WebSocketService,
    private router: Router
  ) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const encryptedId = route.paramMap.get('id');
  
    if (encryptedId) {
      // Decrypt the ID and fetch container details based on containerId
      return from(this.encryptionService.decrypt(encryptedId)).pipe(
        switchMap((decodedId: string) => {
          const containerId = Number(decodedId);  // Convert decodedId to number
  
          if (isNaN(containerId)) {
            console.error('Invalid containerId after decryption');
            this.router.navigate(['/containers']); // Redirect if conversion fails
            return of(null);
          }
  
          // Log containerId
          console.log('Container ID:', containerId);
  
          // Call getContainerDetails and map response to required structure
          return this.containerService.getContainerDetails(containerId).pipe(
            tap(response => {
              console.log('Container Resolver ::: Response:', response); // Log the full response
              console.log('Container Resolver ::: Response Summary:', response.summary); // Log the summary
              console.log('Container Resolver ::: Response Records:', response.records); // Log the records
              console.log('Container Resolver ::: Response Square Records:', response.squareRecords); // Log the records

            }),
            map(response => (
              {
              container: { containerId }, // Directly use containerId
              tallysheetSummary: response.summary, // Map summary from response
              tallysheetRecord: response.records, // Map records from response
              tallysheetSquareRecord:  response.squareRecords  // Map squareRecords from response
            })),
            catchError(error => {
              console.error('Error fetching container details:', error);
              this.router.navigate(['/error']); // Redirect on error
              return of(null);
            })
          );
        }),
        catchError(error => {
          console.error('Decryption error:', error);
          this.router.navigate(['/containers']); // Redirect if decryption fails
          return of(null);
        })
      );
    } else {
      // If no ID in route, navigate back to containers list
      this.router.navigate(['/containers']);
      return of(null);
    }
  }
  
  // resolve(route: ActivatedRouteSnapshot): Observable<any> {
  //   const encryptedId = route.paramMap.get('id');
  
  //   if (encryptedId) {
  //     // Decrypt the ID, and fetch summary and records based on containerId
  //     return from(this.encryptionService.decrypt(encryptedId)).pipe(
  //       switchMap((decodedId: string) => {
  //         const containerId = Number(decodedId);  // Convert decodedId to number
  
  //         if (isNaN(containerId)) {
  //           console.error('Invalid containerId after decryption');
  //           this.router.navigate(['/containers']); // Redirect if conversion fails
  //           return of(null);
  //         }
  
  //         // Log containerId
  //         console.log('Container ID:', containerId);
  
  //         return forkJoin({
  //           container: of({ containerId }),  // Directly use containerId
  //           tallysheetSummary: this.webSocketService.getTallysheetSummaryByContainerId(containerId).pipe(
  //             tap(summary => console.log('Tallysheet Summary:', JSON.stringify(summary))),
  //             catchError(error => {
  //               console.error('Error fetching tallysheet summary:', error);
  //               return of(null); // Return null so forkJoin can continue
  //             })
  //           ),
  //           tallysheetRecord: this.webSocketService.getTallysheetRecordsByContainerId(containerId, 0, 20).pipe(
  //             tap(record => console.log('Tallysheet Record:', JSON.stringify(record))),
  //             catchError(error => {
  //               console.error('Error fetching tallysheet records:', error);
  //               return of(null); // Return null so forkJoin can continue
  //             })
  //           )
  //         });
  //       }),
  //       catchError(error => {
  //         console.error('Decryption error:', error);
  //         this.router.navigate(['/containers']); // Redirect if decryption fails
  //         return of(null); // Return null to avoid breaking the chain
  //       })
  //     );
  //   } else {
  //     // If no ID in route, navigate back to containers list
  //     this.router.navigate(['/containers']);
  //     return of(null);
  //   }
  // }
  

  // resolve(route: ActivatedRouteSnapshot): Observable<any> {
  //   const encryptedId = route.paramMap.get('id');

  //   if (encryptedId) {
  //     // Decrypt the ID, fetch container details, then fetch summary and records based on containerId
  //     return from(this.encryptionService.decrypt(encryptedId)).pipe(
  //       switchMap((decodedId: string) =>
  //         this.containerService.getByContainerId(decodedId).pipe(
  //           switchMap(containerResponse => {
  //             const containerId = containerResponse.containerId;

  //             // Log containerResponse and containerId
  //             console.log('Container Details:'+ JSON.stringify(containerResponse));
  //             console.log('Container ID:', containerId);

  //             return forkJoin({
  //               container: of(containerResponse),  // Include container details directly
  //               tallysheetSummary: this.webSocketService.getTallysheetSummaryByContainerId(containerId).pipe(
  //                 tap(summary => console.log('Tallysheet Summary:'+ JSON.stringify(summary) )),
  //                 catchError(error => {
  //                   console.error('Error fetching tallysheet summary:', error);
  //                   return of(null); // Return null so forkJoin can continue
  //                 })
  //               ),
  //               tallysheetRecord: this.webSocketService.getTallysheetRecordsByContainerId(containerId, 0, 20).pipe(
  //                 tap(record => console.log('Tallysheet Record:'+ JSON.stringify(record) )),
  //                 catchError(error => {
  //                   console.error('Error fetching tallysheet records:', error);
  //                   return of(null); // Return null so forkJoin can continue
  //                 })
  //               )
  //             });
  //           }),
  //           catchError(error => {
  //             console.error('Error fetching container details:', error);
  //             this.router.navigate(['/error']); // Redirect on error
  //             return of(null); // Return null to avoid breaking the chain
  //           })
  //         )
  //       ),
  //       catchError(error => {
  //         console.error('Decryption error:', error);
  //         this.router.navigate(['/containers']); // Redirect if decryption fails
  //         return of(null); // Return null to avoid breaking the chain
  //       })
  //     );
  //   } else {
  //     // If no ID in route, navigate back to containers list
  //     this.router.navigate(['/containers']);
  //     return of(null);
  //   }
  //  }
  }
 