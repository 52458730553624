import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, debounceTime, take, switchMap, tap } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { Validators } from '@angular/forms';
import { BuyerService } from '../modules/buyer/services/buyer.service';
import { SellerService } from '../modules/seller/services/seller.service';
import { LoadingSiteService } from '../modules/loading-site/services/loading-site.service';
import { ProductService } from '../modules/product/services/product.service';
import { ContainerService } from '../modules/container/services/container.service';
import { BillOfLadingService } from '../modules/bill-of-lading/service/bill-of-lading.service';

function isEmptyInputValue(value: any): boolean {
  // we don't check for string here so it also works with arrays
  return value === null || value.length === 0;
}

@Injectable({
  providedIn: 'root',
})
export class NameCheckValidator {
  constructor(
    private userService: UserService,
    private buyerService: BuyerService,
    private sellerService: SellerService,
    private loadingSiteService: LoadingSiteService,
    private productService: ProductService,
    private containerService: ContainerService,
    private billOfLadingService: BillOfLadingService
  ) {}

  checkBuyerNameValidator(initialEmail: string = ''): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<{ [key: string]: any } | null>
      | Observable<{ [key: string]: any } | null> => {
      if (isEmptyInputValue(control.value)) {
        return of(null);
      } else if (control.value === initialEmail) {
        return of(null);
      } else {
        return control.valueChanges.pipe(
          debounceTime(500),
          take(1),
          switchMap((_) =>
            this.buyerService.getByBuyerName(control.value).pipe(
              /* map(user =>
                                    user ? { existingEmail: { value: control.value } } : null
                                ) */
              map((resp) => {
                console.log('response is ' + JSON.stringify(resp));
                if (resp!['success'] == true) {
                  console.log(`in if and return null`);
                  return null; // no error
                } else {
                  console.log(`in else and return remoteError true`);
                  return { isNameExists: true };
                }
              })
            )
          )
        );
      }
    };
  }

  checkGSTValidator(initialEmail: string = ''): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<{ [key: string]: any } | null>
      | Observable<{ [key: string]: any } | null> => {
      if (isEmptyInputValue(control.value)) {
        return of(null);
      } else if (control.value === initialEmail) {
        return of(null);
      } else {
        return control.valueChanges.pipe(
          debounceTime(500),
          take(1),
          switchMap((_) =>
            this.buyerService.getByGST(control.value).pipe(
              /* map(user =>
                                    user ? { existingEmail: { value: control.value } } : null
                                ) */
              map((resp) => {
                console.log('response is ' + JSON.stringify(resp));
                if (resp!['success'] == true) {
                  console.log(`in if and return null`);
                  return null; // no error
                } else {
                  console.log(`in else and return remoteError true`);
                  return { isGstExists: true };
                }
              })
            )
          )
        );
      }
    };
  }

  checkSellerNameValidator(initialSellerName: string = ''): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<{ [key: string]: any } | null>
      | Observable<{ [key: string]: any } | null> => {
      if (isEmptyInputValue(control.value)) {
        return of(null);
      } else if (control.value === initialSellerName) {
        return of(null);
      } else {
        return control.valueChanges.pipe(
          debounceTime(500),
          take(1),
          switchMap((_) =>
            this.sellerService.getBySellerName(control.value).pipe(
              map((resp) => {
                console.log('response is ' + JSON.stringify(resp));
                if (resp!['success'] == true) {
                  console.log(`in if and return null`);

                  return null;
                  // return null; // no error working well untill now
                } else {
                  console.log(`in else and return remoteError true`);
                  return { isSellerNameExists: true };
                }
              })
            )
          )
        );
      }
    };
  }

  checkGhanaIDValidator(initialEmail: string = ''): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<{ [key: string]: any } | null>
      | Observable<{ [key: string]: any } | null> => {
      if (isEmptyInputValue(control.value)) {
        return of(null);
      } else if (control.value === initialEmail) {
        return of(null);
      } else {
        return control.valueChanges.pipe(
          debounceTime(500),
          take(1),
          switchMap((_) =>
            this.sellerService.getByGhanaID(control.value).pipe(
              /* map(user =>
                                    user ? { existingEmail: { value: control.value } } : null
                                ) */
              map((resp) => {
                console.log('response is ' + JSON.stringify(resp));
                if (resp!['success'] == true) {
                  console.log(`in if and return null`);
                  return null; // no error
                } else {
                  console.log(`in else and return remoteError true`);
                  return { isGhanaIdExists: true };
                }
              })
            )
          )
        );
      }
    };
  }

  checkLoadingSiteNameValidator(
    loadingSiteInitialName: string = ''
  ): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<{ [key: string]: any } | null>
      | Observable<{ [key: string]: any } | null> => {
      if (isEmptyInputValue(control.value)) {
        return of(null);
      } else if (control.value === loadingSiteInitialName) {
        return of(null);
      } else {
        return control.valueChanges.pipe(
          debounceTime(500),
          take(1),
          switchMap((_) =>
            this.loadingSiteService.getByLoadingSiteName(control.value).pipe(
              /* map(user =>
                                    user ? { existingEmail: { value: control.value } } : null
                                ) */
              map((resp) => {
                console.log(
                  'checkLoadingSiteNameValidator response is ' +
                    JSON.stringify(resp)
                );
                if (resp!['success'] == true) {
                  console.log(
                    `checkLoadingSiteNameValidator in if and return null`
                  );
                  return null; // no error
                } else {
                  console.log(
                    `checkLoadingSiteNameValidator in else and return remoteError true`
                  );
                  return { isLoadingSiteNameExists: true };
                }
              })
            )
          )
        );
      }
    };
  }

  checkProductNameValidator(initialProductName: string = ''): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<{ [key: string]: any } | null>
      | Observable<{ [key: string]: any } | null> => {
      if (isEmptyInputValue(control.value)) {
        return of(null);
      } else if (control.value === initialProductName) {
        return of(null);
      } else {
        return control.valueChanges.pipe(
          debounceTime(500),
          take(1),
          switchMap((_) =>
            this.productService.getByProductName(control.value).pipe(
              map((resp) => {
                console.log(
                  'checkProductNameValidator response is ' +
                    JSON.stringify(resp)
                );
                if (resp!['success'] == true) {
                  console.log(
                    `checkProductNameValidator in if and return null`
                  );
                  return null; // no error
                } else {
                  console.log(
                    `checkProductNameValidator in else and return remoteError true`
                  );
                  return { isProductExists: true };
                }
              })
            )
          )
        );
      }
    };
  }
  isContainerNumberValidationInProgress = false;
  /* checkContainerNumberValidator(
    initialContainerNumber?: string
  ): AsyncValidatorFn {
    console.log(`checkContainerNumberValidator-1`);
    return (
      control: AbstractControl
    ):
      | Promise<{ [key: string]: any } | null>
      | Observable<{ [key: string]: any } | null> => {
        console.log(`checkContainerNumberValidator-2`);
      if (isEmptyInputValue(control.value)) {
        return of(null);
      } else if (control.value === initialContainerNumber) {
        return of(null);
      } else {
        this.isContainerNumberValidationInProgress = true;
        return control.valueChanges.pipe(
          debounceTime(500),
          take(1),
          switchMap((_) =>
            this.containerService.checkByContainerNumber(control.value).pipe(
              map((resp) => {
                this.isContainerNumberValidationInProgress = false;
                console.log(
                  'checkContainerNumberValidator response is ' +
                    JSON.stringify(resp)
                );
                if (resp!['success'] == true) {
                  console.log(
                    `checkContainerNumberValidator in if and return null`
                  );
                  return null; // no error
                } else {
                  console.log(
                    `checkContainerNumberValidator in else and return remoteError true`
                  );
                  return { isContainerNumberExists: true };
                }
              })
            )
          )
        );
      }
    };
  } */
  checkContainerNumberValidator(
    initialContainerNumber?: string
  ): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<{ [key: string]: any } | null>
      | Observable<{ [key: string]: any } | null> => {
      if (isEmptyInputValue(control.value)) {
        return of(null);
      } else if (control.value === initialContainerNumber) {
        return of(null);
      } else {
        this.isContainerNumberValidationInProgress = true;
        return this.containerService.checkByContainerNumber(control.value).pipe(
          map((resp) => {
            this.isContainerNumberValidationInProgress = false;
            if (resp!['success'] == true) {
              return null; // no error
            } else {
              return { isContainerNumberExists: true };
            }
          })
        );
      }
    };
  }
  

  isContainerSealValidationInProgress = false;
  checkContainerSealValidator(
    initialContainerSeal: string = ''
  ): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<{ [key: string]: any } | null>
      | Observable<{ [key: string]: any } | null> => {
      if (isEmptyInputValue(control.value)) {
        return of(null);
      } else if (control.value === initialContainerSeal) {
        return of(null);
      } else {
        this.isContainerSealValidationInProgress = true;
        return control.valueChanges.pipe(
          debounceTime(500),
          take(1),
          switchMap((_) =>
            this.containerService.getByContainerSeal(control.value).pipe(
              map((resp) => {
                this.isContainerSealValidationInProgress = false;
                console.log(
                  'checkContainerSealValidator response is ' +
                    JSON.stringify(resp)
                );
                // this.isContainerNumberValidationInProgress = false; // Set back to false when validation completes

                if (resp!['success'] == true) {
                  console.log(
                    `checkContainerSealValidator in if and return null`
                  );
                  return null; // no error
                } else {
                  console.log(
                    `checkContainerSealValidator in else and return remoteError true`
                  );
                  return { isContainerSealExists: true };
                }
              })
            )
          )
        );
      }
    };
  }

  checkBLNumberValidator(initialSellerName: string = ''): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<{ [key: string]: any } | null>
      | Observable<{ [key: string]: any } | null> => {
      if (isEmptyInputValue(control.value)) {
        return of(null);
      } else if (control.value === initialSellerName) {
        return of(null);
      } else {
        return control.valueChanges.pipe(
          debounceTime(500),
          take(1),
          switchMap((_) =>
            this.billOfLadingService.existsBLNumber(control.value).pipe(
              map((resp) => {
                console.log('response is ' + JSON.stringify(resp));
                if (resp!['success'] == true) {
                  console.log(`in if and return null`);

                  return null;
                  // return null; // no error working well untill now
                } else {
                  console.log(`in else and return remoteError true`);
                  return { isBLNumberExists: true };
                }
              })
            )
          )
        );
      }
    };
  }
}
