<div style="padding: 0;margin: 0;" class="full-card">
    <div class="pb-3">
        <p-breadcrumb [model]="breadCrumbitems" [home]="home"></p-breadcrumb>
    </div>

    <div>
        <p-card [header]="formHeader" [subheader]="formSubHeader"  >  

            <p-steps [model]="stepMenu" [readonly]="false"  >
            </p-steps>
            <router-outlet></router-outlet>

        </p-card>
    </div>
</div>