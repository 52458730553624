import { environment } from "src/environments/environment";

export function disableConsoleInProduction() {
    console.log(`environment.production ${environment.production}`);
  if (environment.production) {
    window.console.log = () => {};
    window.console.error = () => {};
    window.console.info = () => {};
    window.console.table = () => {};
  }
}