import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class RegistrationComponent implements OnInit, OnDestroy {


  activeIndex: number = 0;
  items!: MenuItem[];
  subscription!: Subscription;


  constructor() {
    this.initStep();
  }

  ngOnInit(): void {

  }


  initStep() {
    this.items = [
      {
        label: 'Personal Detail',
        routerLink: 'register-user'
      },
      {
        label: 'Company Detail',
        routerLink: 'register-company'
      },
      {
        label: 'Subscription',
        routerLink: 'subscription'
      },
      {
        label: 'Confirmation',
        routerLink: 'confirmation'
      }
    ];
    /*   this.subscription = this.ticketService.paymentComplete$.subscribe((personalInformation) => {
        this.messageService.add({ severity: 'success', summary: 'Order submitted', detail: 'Dear, ' + personalInformation.firstname + ' ' + personalInformation.lastname + ' your order completed.' });
      }); */
  }

  onActiveIndexChange(event: any) {
    this.activeIndex = event;
  }


  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
