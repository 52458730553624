import { HttpParams } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  HostListener,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Page } from 'src/app/commons/models/page';
import { Seller } from '../model/seller';
import { SellerSharedDataService } from '../services/seller-shared-data.service';
import { SellerService } from '../services/seller.service';
import { UtilService } from 'src/app/services/util.service';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { SHARED_MODULES } from '../../shared-imports';
import { SellerComponent } from '../seller/seller.component';
import { EncryptionService } from 'src/app/services/encryption.service';

@Component({
  selector: 'app-sellers-list',
  templateUrl: './sellers-list.component.html',
  styleUrls: ['./sellers-list.component.css'],
  providers: [ToastMessagesService],
  standalone: true,
  imports: [SHARED_MODULES, SellerComponent]
})
export class SellersListComponent implements OnInit {
  sellerSidebarVisible: boolean = false;
  isAddLoadingSiteFromContainer: boolean = false;
  loading: boolean = false;
  formHeader!: string;
  formSubHeader!: string;
  showNameColumn = true;
  showContactPersonColumn = true;
  showGstColumn = true;
  showCityColumn = true;
  showPhoneColumn = false;
  showEmailColumn = false;
  protected items!: MenuItem[];
  home!: MenuItem;
  rows = 5;
  sellers!: Seller[];
  multiSortMeta!: any[];
  totalRecords: number = 0;
  first: number = 0;
  last: number = 0;
  rowsPerPageOptions = [5, 10, 20]; // set the options for the number of rows per page
  @ViewChild('globalFiterInput') globalFiterInput!: ElementRef;
  @ViewChild('dt') table!: Table;
  cols!: any[];
  isMobile = false;
  tableStyle: any;
  sellerSpeedDialItems!: MenuItem[];

  _selectedColumns!: any[];
  selectedSellers: Seller[] = [];
  tableStyleOptions!: any;

  constructor(
    private sellerService: SellerService,
    private messageService: MessageService,
    private toastMessageService: ToastMessagesService,
    private confirmationService: ConfirmationService,
    private sellerSharedDataService: SellerSharedDataService,
    private router: Router,
    private titleService: Title,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private encryptionService: EncryptionService
  ) {
    this.setPageTitle();
    this.tableStyle = this.utilService.getTableSize();
  }

  ngOnInit() {
    this.initTableColumns();
    this.populateBreadCrumb();
    this.initSpeedDial();
    this.initTableStyle();
  }

  initTableStyle() {
    this.tableStyle = this.utilService.getTableSize();
    this.tableStyleOptions = this.utilService.getTableMinWidth();
  }

  sortSellers(event: any) {
    //const globalFilter = this.globalFiterInput.nativeElement.value;
    /* console.log(`value ${globalFilter}`);
    console.log(`%%% ${globalFilter}`) */
    this.loading = true;
    const pageNumber = Math.floor(event.first / event.rows) + 1;
    console.log(`Page Number ${pageNumber}`);
    console.log(`Event List ${JSON.stringify(event)}`);
    let params = new HttpParams();
    let sortOrder = '';
    console.log(`customSort field ${[event!.field!]}`);
    // console.log(`customSort data ${[event!.data!]}`)
    console.log(`customSort mode ${[event!.mode!]}`);
    console.log(`customSort order ${[event!.order!]}`);
    console.log(
      `customSort multiSortMeta ${JSON.stringify([event!.multiSortMeta!])}`
    );
    console.log(`multiSortMeta ${event!.multiSortMeta?.length}`);
    const multiSortLength = event!.multiSortMeta?.length;
    params = params.set('size', event.rows);
    params = params.set('page', pageNumber - 1);
    for (var i = 0; i < multiSortLength!; i++) {
      if (event!.multiSortMeta![i].order === -1) {
        sortOrder = 'DESC';
      } else {
        sortOrder = 'ASC';
      }
      console.log(`field ${event!.multiSortMeta![i].field}`);
      console.log(`order ${event!.multiSortMeta![i].order}`);

      if (multiSortLength! > 0) {
        console.log(`in if`);
        params = params.set(
          'sort',
          event!.multiSortMeta![i].field + ',' + sortOrder
        );
      }
    }
    console.log(`Params ${params.toString()}`);
    this.sellers = [];
    // filter implementation
    const filters = event.filters;
    if (event.filters.hasOwnProperty('name')) {
      console.log(`filters ${JSON.stringify(filters.name.length)}`);
      for (var i = 0; i < filters.name.length; i++) {
        if (filters.name[i].value) {
          params = params.set('name', filters.name[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('contactPerson')) {
      console.log(`filters ${JSON.stringify(filters.contactPerson.length)}`);
      for (var i = 0; i < filters.contactPerson.length; i++) {
        if (filters.contactPerson[i].value) {
          params = params.set('contactPerson', filters.contactPerson[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('ghanaId')) {
      console.log(`filters ${JSON.stringify(filters.ghanaId.length)}`);
      for (var i = 0; i < filters.ghanaId.length; i++) {
        if (filters.ghanaId[i].value) {
          params = params.set('ghanaId', filters.ghanaId[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('notes')) {
      console.log(`filters ${JSON.stringify(filters.notes.length)}`);
      for (var i = 0; i < filters.notes.length; i++) {
        if (filters.notes[i].value) {
          params = params.set('notes', filters.notes[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('city')) {
      console.log(`filters ${JSON.stringify(filters.city.length)}`);
      for (var i = 0; i < filters.city.length; i++) {
        if (filters.city[i].value) {
          params = params.set('city', filters.city[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('state')) {
      console.log(`filters ${JSON.stringify(filters.state.length)}`);
      for (var i = 0; i < filters.state.length; i++) {
        if (filters.state[i].value) {
          params = params.set('state', filters.state[i].value);
        }
      }
    }
    if (event.filters.hasOwnProperty('country')) {
      console.log(`filters ${JSON.stringify(filters.country.length)}`);
      for (var i = 0; i < filters.country.length; i++) {
        if (filters.country[i].value) {
          params = params.set('country', filters.country[i].value);
        }
      }
    }

    this.sellerService.getListSeller(params).subscribe(
      (response: Page<Seller>) => {
        this.sellers = response.content;
        this.first =
          response.pageable.pageNumber * response.pageable.pageSize + 1;
        const max =
          response.pageable.pageNumber * response.pageable.pageSize +
          response.pageable.pageSize;
        this.last = max < response.totalElements ? max : response.totalElements;
        this.totalRecords = response.totalElements;
      },
      (error) => { },
      () => {
        this.loading = false;
      }
    );
  }

  globalFilter(value: any, table: Table) {
    console.log(`globalFilter  ${JSON.stringify(value)}`);
    const globalFilter = this.globalFiterInput.nativeElement.value;
    console.log(`value ${globalFilter}`);
    if (globalFilter) {
      let params = new HttpParams();
      params = params.append('q', globalFilter);
      this.sellerService.getSellersGlobalFilter(params).subscribe(
        (response: Page<Seller>) => {
          this.sellers = response.content;
          this.first =
            response.pageable.pageNumber * response.pageable.pageSize + 1;
          const max =
            response.pageable.pageNumber * response.pageable.pageSize +
            response.pageable.pageSize;
          this.last =
            max < response.totalElements ? max : response.totalElements;
          this.totalRecords = response.totalElements;
        },
        (error) => { },
        () => { }
      );
    } else {
      table.clear();
    }
  }
  clearAllFilter(table: Table, field: HTMLInputElement) {
    field.value = ''; // cl
    table.clear();
  }

  clear(table: Table) {
    table.clear();
  }

  initTableColumns() {
    this._selectedColumns = ['Contact Person', 'Tax ID'];
    this.cols = [
      'Contact Person',
      'Tax ID',
      'Reference',
      'Phone',
      'Notes',
      'City',
      'State',
      'Country',
    ];
  }

  deleteRows() {
    const ids: number[] = this.selectedSellers.map(
      (seller) => seller.supplierId
    );

    if (ids.length === 0) {
      this.toastMessageService.showWarningMessage(
        'No Selection',
        'No rows selected for deletion.'
      );
      // this.toastMessageService.showWarnMessage('No Selection', 'No rows selected for deletion.');
      return;
    }

    this.confirmationService.confirm({
      header: 'Delete Seller',
      message: 'Are you sure you want to delete this seller?',
      accept: () => {
        this.sellerService.deleteSellers(ids).subscribe(
          () => {
            this.toastMessageService.showInfoMessage(
              'Success',
              'Seller Deleted Successfully'
            );
            this.refreshTable();
          },
          (error) => {
            if (error.status === 409) {
              console.error(`Error while deleting is ${JSON.stringify(error)}`);
              // Assuming 409 is the HTTP status code for 'Conflict'
              // Show PrimeNG Confirm Dialog
              this.confirmationService.confirm({
                message: 'Warning: Deleting this supplier will also result in the permanent removal of all linked records, such as containers, measurements, and BL. This action is irreversible. Would you like to proceed?',
                accept: () => {
                  // User confirmed, proceed with force delete
                  this.sellerService.deleteSellers(ids, true).subscribe(
                    () => {
                      this.toastMessageService.showInfoMessage(
                        'Success',
                        'Seller Force-Deleted Successfully'
                      );
                      this.refreshTable();
                    },
                    () => {
                      this.toastMessageService.showErrorMessage(
                        'Operation Unsuccessful',
                        'An error occurred. Please try again.'
                      );
                    }
                  );
                },
              });
            } else {
              this.toastMessageService.showErrorMessage(
                'Operation Unsuccessful',
                'An error occurred. Please try again.'
              );
            }
          }
        );
      },
      /*   this.sellerService.deleteSellers(ids).subscribe(
          () => {
            this.toastMessageService.showInfoMessage(
              'Success',
              'Seller Deleted Successfully'
            );
            this.refreshTable();
          },
          () => {
            this.toastMessageService.showErrorMessage(
              'Operation Unsuccessful',
              'An error occurred. Please try again.'
            );
          }
        );
      }, */
    });
  }

  refreshTable() {
    this.sellerService.getListSeller().subscribe((response: Page<Seller>) => {
      this.sellers = response.content;
      this.first =
        response.pageable.pageNumber * response.pageable.pageSize + 1;
      const max =
        response.pageable.pageNumber * response.pageable.pageSize +
        response.pageable.pageSize;
      this.last = max < response.totalElements ? max : response.totalElements;
      this.totalRecords = response.totalElements;
      this.selectedSellers = [];
    });
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) => val.includes(col));
  }
  getSelectedSellers() {
    console.log(`Selected Sellers length is ${this.selectedSellers.length} 
     and \nData is ${JSON.stringify(this.selectedSellers)}`);
  }

  onRowSelect(event: any) {
    //this.messageService.add({ severity: 'info', summary: 'Product Selected', detail: event.data.name });
  }

  onRowClick(data: Seller) {
    console.log(`onRowClick Data is ${JSON.stringify(data)}`);
    const id = "" + data.supplierId;
    // const encryptedId = btoa(id); // Encrypt the ID
    // this.router.navigate(['/edit/seller', encryptedId]);
    // this.router.navigate(['/edit/seller']);
    this.encryptionService.encrypt(id).then((encryptedId) => {
      this.router.navigate(['/edit/seller', encryptedId]);
    });
  }

  populateBreadCrumb() {
    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.items = [{ label: 'Sellers' }];
  }
  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = this.utilService.isMobile();
    console.log(`is mobile ${this.utilService.isMobile()}`);
    //this.tableStyle = this.utilService.getTableSize();
    this.initTableStyle();
  }

  onSellerSubmit(event: Event) {
    console.log(`On Seller Submit`);
    this.table.reset();
  }

  focusOnGlobalFilter() {
    this.globalFiterInput.nativeElement.focus();
  }
  initSpeedDial() {
    this.sellerSpeedDialItems = [
      {
        icon: 'pi pi-filter-slash',
        command: () => {
          this.globalFiterInput.nativeElement.value = '';
          this.table.clear();
        },
      },
      {
        icon: 'pi pi-search',
        command: () => {
          this.focusOnGlobalFilter();
        },
      },
      {
        icon: 'pi pi-trash',
        command: () => {
          this.deleteRows();
          //this.messageService.add({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
        },
      },
      {
        icon: 'pi pi-plus',
        command: () => {
          this.sellerSidebarVisible = true;
          // this.deleteRows()
          //this.messageService.add({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
        },
      },
    ];
  }
}
