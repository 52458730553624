import { Injectable } from '@angular/core';
declare var Stripe: any; // Declare Stripe to be recognized

@Injectable({
  providedIn: 'root'
})
export class StripePgService {

  private stripe;
  private elements;
  private card;

  constructor() {
    this.stripe = Stripe('pk_test_51Nxu7BSFQ7v3SQHKbTSXyACiP1cY1bKKC64tpyLAFboe7JfWUQdrfgtK1P12jpoEgAy6o1GGE93XnvsqhBvgjagP00idqythMZ'); // Initialize Stripe
    this.elements = this.stripe.elements();
    this.card = this.elements.create('card');
  }

  mountCard(elementId: string) {
    this.card.mount(`#${elementId}`);
  }

  async handlePayment() {
    const { token, error } = await this.stripe.createToken(this.card);

    if (error) {
      // Handle error
      return { error };
    } else {
      // Send token to server
      return { token };
    }
  }
}
