<div>
    <p-scrollTop></p-scrollTop>
    <ng-container *ngIf="!isMobile; else elseBlock">
        <p-card>

            <!-- {{measurements | json}} -->
            <!-- <p-table [value]="measurements" [styleClass]="tableStyle" [tableStyle]="tableStyleOptions"
                [loading]="(isLoading1 | async) ?? false">

                <ng-template pTemplate="header">
                    <tr>
                        <th>SR </th>
                        <th *ngFor="let col of cols" pSortableColumn="{{col.field}}">{{col.header}} <p-sortIcon
                                field="{{col.field}}"></p-sortIcon></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-measurement let-index="rowIndex">
                    <tr>
                        <td>{{ index + 1}}</td>
                        <td *ngFor="let col of cols">{{measurement[col.field]}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="loadingbody">
                    <tr *ngFor="let row of dummyRows">
                        <td>
                            <p-skeleton></p-skeleton>
                        </td>
                        <td *ngFor="let col of cols">
                            <p-skeleton></p-skeleton>
                        </td>
                    </tr>
                </ng-template>
            </p-table> -->
            <!-- <div #scrollable style="height: 50vh; overflow-y: auto;" (scroll)="checkScrollPosition()"> -->
                <!-- {{ measurements | json}} -->
                <div #scrollable style="height: 50vh; overflow-y: auto;">
                <p-table #myTable [value]="measurements" [styleClass]="tableStyle" [tableStyle]="tableStyleOptions"
                    [loading]="(isLoading1 | async) ?? false" [style]="{ overflow: 'hidden' }">
                    

                    <ng-template pTemplate="header">
                        <tr>
                            <th>SR length</th>

                            <!-- Headers -->
                            <ng-container *ngIf="lengthAllowance !== 0 && girthAllowance !== 0">
                                <!-- Both allowances are not zero -->
                                <th pSortableColumn="grossLength">Gross Length <p-sortIcon
                                        field="grossLength"></p-sortIcon></th>
                                <th pSortableColumn="netLength">Net Length <p-sortIcon field="netLength"></p-sortIcon>
                                </th>
                                <th pSortableColumn="grossGirth">Gross Girth <p-sortIcon
                                        field="grossGirth"></p-sortIcon></th>
                                <th pSortableColumn="netGirth">Net Girth <p-sortIcon field="netGirth"></p-sortIcon></th>
                                <th pSortableColumn="grossVolumeCbm">Gross Volume CBM <p-sortIcon
                                        field="grossVolumeCbm"></p-sortIcon></th>
                                <th pSortableColumn="netVolumeCbm">Net Volume CBM<p-sortIcon
                                        field="netVolumeCbm"></p-sortIcon></th>
                                <th pSortableColumn="grossVolumeCft">Gross Volume CFT<p-sortIcon
                                        field="grossVolumeCft"></p-sortIcon></th>
                                <th pSortableColumn="netVolumeCft">Net Volume CFT<p-sortIcon
                                        field="netVolumeCft"></p-sortIcon></th>
                            </ng-container>


                             <!-- Only lengthAllowance is zero -->
                            <ng-container *ngIf="lengthAllowance === 0 && girthAllowance !== 0">
                               
                                <th pSortableColumn="netLength">Length <p-sortIcon field="netLength"></p-sortIcon></th>
                                <th pSortableColumn="grossGirth">Gross Girth <p-sortIcon
                                        field="grossGirth"></p-sortIcon></th>
                                <th pSortableColumn="netGirth">Net Girth <p-sortIcon field="netGirth"></p-sortIcon></th>
                                <th pSortableColumn="grossVolumeCbm">Gross Volume CBM <p-sortIcon
                                        field="grossVolumeCbm"></p-sortIcon></th>
                                <th pSortableColumn="netVolumeCbm">Net Volume CBM <p-sortIcon
                                        field="netVolumeCbm"></p-sortIcon></th>
                                <th pSortableColumn="grossVolumeCft">Gross Volume CFT <p-sortIcon
                                        field="grossVolumeCft"></p-sortIcon></th>
                                <th pSortableColumn="netVolumeCft">Net Volume CFT <p-sortIcon
                                        field="netVolumeCft"></p-sortIcon></th>
                            </ng-container>

                            <!-- Only girthAllowance is zero -->
                            <ng-container *ngIf="girthAllowance === 0 && lengthAllowance !== 0">
                                <th pSortableColumn="grossLength">Gross Length <p-sortIcon
                                        field="grossLength"></p-sortIcon></th>
                                <th pSortableColumn="netLength">Net Length <p-sortIcon field="netLength"></p-sortIcon>
                                </th>
                                <th pSortableColumn="netGirth">Girth<p-sortIcon field="netGirth"></p-sortIcon></th>
                                <th pSortableColumn="grossVolumeCbm">Gross Volume CBM<p-sortIcon
                                        field="grossVolumeCbm"></p-sortIcon></th>
                                <th pSortableColumn="netVolumeCbm">Net Volume CBM<p-sortIcon
                                        field="netVolumeCbm"></p-sortIcon></th>
                                <th pSortableColumn="grossVolumeCft">Gross Volume CFT<p-sortIcon
                                        field="grossVolumeCft"></p-sortIcon></th>
                                <th pSortableColumn="netVolumeCft">Net Volume CFT<p-sortIcon
                                        field="netVolumeCft"></p-sortIcon></th>
                            </ng-container>

                            <!-- Both allowances are zero -->
                            <ng-container *ngIf="girthAllowance === 0 && lengthAllowance === 0">
                                <th pSortableColumn="netLength">Length <p-sortIcon field="netLength"></p-sortIcon></th>
                                <th pSortableColumn="netGirth">Girth <p-sortIcon field="netGirth"></p-sortIcon></th>
                                <th pSortableColumn="netVolumeCbm">Volume CBM <p-sortIcon
                                        field="netVolumeCbm"></p-sortIcon></th>
                                <th pSortableColumn="netVolumeCft">Volume CFT <p-sortIcon
                                        field="netVolumeCft"></p-sortIcon></th>
                            </ng-container>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-measurement let-index="rowIndex">
                        <tr>
                            <td>{{ index + 1 }}</td>

                            <!-- Body Rows -->
                            <ng-container *ngIf="measurement.lengthAllowance !== 0 && measurement.girthAllowance !== 0">
                                <!-- Both allowances are not zero -->
                                <td>{{ measurement.grossLength }}</td>
                                <td>{{ measurement.netLength }}</td>
                                <td>{{ measurement.grossGirth }}</td>
                                <td>{{ measurement.netGirth }}</td>
                                <td>{{ measurement.grossVolumeCbm }}</td>
                                <td>{{ measurement.netVolumeCbm }}</td>
                                <td>{{ measurement.grossVolumeCft }}</td>
                                <td>{{ measurement.netVolumeCft }}</td>
                            </ng-container>

                            <ng-container *ngIf="measurement.lengthAllowance === 0 && measurement.girthAllowance !== 0">
                                <!-- Only lengthAllowance is zero -->
                                <td>{{ measurement.netLength }}</td>
                                <td>{{ measurement.grossGirth }}</td>
                                <td>{{ measurement.netGirth }}</td>
                                <td>{{ measurement.grossVolumeCbm }}</td>
                                <td>{{ measurement.netVolumeCbm }}</td>
                                <td>{{ measurement.grossVolumeCft }}</td>
                                <td>{{ measurement.netVolumeCft }}</td>
                            </ng-container>

                            <ng-container *ngIf="measurement.girthAllowance === 0 && measurement.lengthAllowance !== 0">
                                <!-- Only girthAllowance is zero -->
                                <td>{{ measurement.grossLength }}</td>
                                <td>{{ measurement.netLength }}</td>
                                <td>{{ measurement.netGirth }}</td>
                                <td>{{ measurement.grossVolumeCbm }}</td>
                                <td>{{ measurement.netVolumeCbm }}</td>
                                <td>{{ measurement.grossVolumeCft }}</td>
                                <td>{{ measurement.netVolumeCft }}</td>
                            </ng-container>

                            <ng-container *ngIf="measurement.girthAllowance === 0 && measurement.lengthAllowance === 0">
                                <!-- Both allowances are zero -->
                                <td>{{ measurement.netLength }}</td>
                                <td>{{ measurement.netGirth }}</td>
                                <td>{{ measurement.netVolumeCbm }}</td>
                                <td>{{ measurement.netVolumeCft }}</td>
                            </ng-container>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="loadingbody">
                        <tr *ngFor="let row of dummyRows">
                            <td>
                                <p-skeleton></p-skeleton>
                            </td>
                            <td *ngFor="let col of cols">
                                <p-skeleton></p-skeleton>
                            </td>
                        </tr>
                    </ng-template>

                </p-table>
                <!-- <button (click)="scrollToTop()"  id="scroll-to-top" class="scroll-btn">Scroll to Top</button> -->
                <!-- <button (click)="scrollToBottom()" id="scroll-to-bottom" class="scroll-btn" >Scroll to Bottom</button> -->
                <p-button *ngIf="showBottomButton && (measurements?.length ?? 0) > 20" (click)="scrollToBottom()" id="scroll-to-bottom" class="scroll-btn" styleClass="p-button-rounded p-button-help p-button-text p-button-raised" icon="pi pi-arrow-circle-down" ></p-button>
                <p-button *ngIf="showTopButton && (measurements?.length ?? 0) > 20"  (click)="scrollToTop()" id="scroll-to-top" class="scroll-btn" styleClass="p-button-rounded p-button-help p-button-text p-button-raised" icon="pi pi-arrow-circle-up" ></p-button>
                
            </div>
            

        </p-card>
    </ng-container>
    <ng-template #elseBlock>

        <p-table [value]="measurements" [styleClass]="tableStyle" [tableStyle]="tableStyleOptions"
            [loading]="(isLoading1 | async) ?? false">

            <ng-template pTemplate="header">
                <tr>
                    <th>SR </th>
                    <th *ngFor="let col of cols" pSortableColumn="{{col.field}}">{{col.header}} <p-sortIcon
                            field="{{col.field}}"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-measurement let-index="rowIndex">
                <tr>
                    <td>{{ index + 1}}</td>
                    <td *ngFor="let col of cols">{{measurement[col.field]}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="loadingbody">
                <tr *ngFor="let row of dummyRows">
                    <td>
                        <p-skeleton></p-skeleton>
                    </td>
                    <td *ngFor="let col of cols">
                        <p-skeleton></p-skeleton>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        
    </ng-template>
</div>