import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountSetupStepperService {

  private activeStepSource = new BehaviorSubject<number>(0);  // Default to step 0
  activeStep$ = this.activeStepSource.asObservable();

  constructor() { }

  setActiveStep(step: number) {
    this.activeStepSource.next(step);  // Update the active step
  }
}
