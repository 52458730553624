<!-- <footer class="footer">
  <div class="left">
    &copy; 2023 My Company Name. All Rights Reserved.
  </div>
  <div class="right">
    <span class="contact">Contact Us</span>
    <button pButton icon="pi pi-twitter" class="p-mr-1"></button>
    <button pButton icon="pi pi-facebook" class="p-mr-1"></button>
    <button pButton icon="pi pi-linkedin"></button>
  </div>
</footer>
 -->

<footer class="footer"  >
  <div class="left text-xs"> 
    &copy; 2023 Lumber Linq. All Rights Reserved.
  </div>

  <div class="center text-xs">
    Powered By Lumber Linq
  </div>

  <div class="publicity text-xs sm:ml-2 md:ml-2">
    Love our app? <a [href]="lumberlinq_url" target="_blank" rel="noopener noreferrer">Use it for your own
      business!</a>
  </div>

  <div class="right text-xs">
    <p-button icon="pi pi-facebook" styleClass="p-button-sm p-button-rounded p-button-text facebook mr-2"
      (onClick)="openSocialUrl('facebook')"></p-button>
    <p-button icon="pi pi-twitter" styleClass="p-button-sm p-button-rounded p-button-text twitter mr-2"
    (onClick)="openSocialUrl('twitter')"></p-button>
    <p-button icon="pi pi-linkedin" styleClass="p-button-sm p-button-rounded p-button-text linkedin mr-2"
    (onClick)="openSocialUrl('linkedin')"></p-button>
    <p-button icon="pi pi-instagram" styleClass="p-button-sm p-button-rounded p-button-text instagram mr-2"
    (onClick)="openSocialUrl('instagram')"></p-button>
    <p-button icon="pi pi-youtube" styleClass="p-button-sm p-button-rounded p-button-text youtube"
  (onClick)="openSocialUrl('youtube')"></p-button>
   
  </div>

</footer>