import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { Page } from 'src/app/commons/models/page';
import { BillOfLading } from '../model/bill-of-lading';
import { ContainerNumber } from '../model/container-number';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class BillOfLadingService {

  constructor(private http: HttpClient) { }

  getContainerNumbers(): Observable<ContainerNumber[]> {
    return this.http.get<ContainerNumber[]>(AppConstants.API_URL + 'api/v1/container-numbers-not-in-bl');
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  } //containerNumbers

  getData(): Observable<BillOfLading> {
    return this.http.get<BillOfLading>(AppConstants.API_URL + 'api/v1/suppliers');
    //return this.http.get<any>(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  postData(data: BillOfLading, containerMismatchContinueAnyway: boolean = false, ignorePermissionConflict = false): Observable<BillOfLading> {
    const url = AppConstants.API_URL + `api/v1/bill-of-lading?containerMismatchContinueAnyway=${containerMismatchContinueAnyway}&ignorePermissionConflict=${ignorePermissionConflict}`;
    console.log(`Save Bill Of Lading url is ${url} and data is ${JSON.stringify(data)}`)

    return this.http.post<BillOfLading>(url, data, { responseType: 'json' });
  }

  putData(data: BillOfLading): Observable<BillOfLading> {
    return this.http.put<BillOfLading>(AppConstants.API_URL + 'api/v1/bill-of-lading', data, { responseType: 'json' });
  }

  existsBLNumber(blNumber: string): Observable<any | undefined> {
    const response = this.http.get(AppConstants.API_URL + `api/v1/check-bill-of-lading-number?blNumber=${blNumber}`, httpOptions);
    console.log(`existsBLNumber Response is ${JSON.stringify(response)}`)
    return response;
  }


  // <Page<Book>
  getListBillOfLading(httpParams?: HttpParams): Observable<Page<BillOfLading>> {
    let url = `${AppConstants.API_URL}api/v1/bill-of-ladings?`;
    if (httpParams) {
      url = url + httpParams;
    }
    console.log(`url is ${url}`)
    return this.http.get<Page<BillOfLading>>(url);
  }

  getBillOfLadingGlobalFilter(httpParams?: HttpParams): Observable<Page<BillOfLading>> {
    let url = `${AppConstants.API_URL}api/v1/bill-of-ladings-list-search?`;
    if (httpParams) {
      url = url + httpParams;
    }
    console.log(`url is ${url}`)
    return this.http.get<Page<BillOfLading>>(url);
  }

  deleteBillOfLading(id: any): Observable<string> {
    let url = `${AppConstants.API_URL}api/v1/bill-of-lading/${id}`;
    console.log(`url is ${url}`)
    return this.http.delete<string>(url);
  }

  getBillOfLadingIdById(id: number): Observable<BillOfLading> {
    const url = AppConstants.API_URL + 'api/v1/bill-of-lading/' + id;
    console.log(`Url is ${url}`);
    return this.http.get<BillOfLading>(url);
  }  //

  getBillOfLadingProjectionByBLId(id: number): Observable<any> {
    const url = AppConstants.API_URL + 'api/v1/bill-of-lading-projection/' + id;
    console.log(`Url is ${url}`);
    return this.http.get<any>(url);
  }  //getBillOfLadingProjection
  // getBillOfLadingProjectionByBlUUID

  getBillOfLadingProjectionByBlUUID(blUUID: string): Observable<any> {
    ///bills-of-lading/{bl-uuid}
    const url = AppConstants.API_URL + 'public/api/v1/bills-of-lading/' + blUUID;
    console.log(`Url is ${url}`);
    return this.http.get<any>(url);
  }

  // getBillOfLadingIdByBlUUID

  getBillOfLadingNumberByBlUUID(blUUID: string): Observable<any> {
    // /bl-number-by-uuid/{blUUID}
    const url = AppConstants.API_URL + 'public/api/v1/bl-number-by-uuid/' + blUUID;
    console.log(`Url is ${url}`);
    return this.http.get<any>(url);
  }
}
