import { Formula } from "../../product/product/product.component";

export class BlSummary { 
    containerSummaryId?: number;
    pieces?: number;
    netVolume?: number;
    grossVolume?: number;
    netAverage?: number;
    grossAverage?: number;
    containerNumber?: string;
    containerId?: number;
    containerUUID?: string;
    formula?:Formula;
}
