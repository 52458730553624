<div class="w-full">

    <p-fileUpload name="files[]" [customUpload]="true" [multiple]="true" (uploadHandler)="onUpload($event)"
        [auto]="false" [showUploadButton]="true">
        <ng-template pTemplate="content">
            <div>
                <span class="p-text-center">Drag and Drop files here</span>
            </div>
        </ng-template>
    </p-fileUpload>
    <div *ngIf="files.length > 0">
        <ng-container *ngFor="let file of files">
            <p-progressBar [value]="file.progress" [showValue]="true" *ngIf="file.isNew"></p-progressBar>
        </ng-container>
    </div>


    <div class="flex flex-wrap md:flex-wrap card-container purple-container photodiv">
        <div class="images-item" *ngFor="let image of existingImages">
            <div class=" m-2 imagediv">
                <div (click)="deleteImage($event, image)">
                    <button pButton pRipple class="p-button-rounded p-button-danger p-button-text closebuttondiv">
                        <div class="bg-black opacity-70 rounded-circle">
                            <i class="pi pi-times text-white font-weight-bold p-1"></i>
                        </div>
                    </button>
                </div>
                <img [src]="image.thumbnailUrl" class="thumbnaildiv">

                <p-confirmPopup key="confirm1"></p-confirmPopup>

            </div>
        </div>
    </div>

</div>

<!-- <div class="products" *ngIf="existingImages?.length ?? 0 > 0; else noImages"
        [photoGalleryGroup]="{ arrowEl: true, indexIndicatorSep: ' / ', mouseUsed:true,shareEl:false,tapToToggleControls:true,showAnimationDuration: 200, hideAnimationDuration:200,arrowKeys:true,closeEl: true   }"
        p>
        <div class="p-d-flex p-ai-center p-jc-center p-dir-col">
            <p-scroller [items]="existingImages" [itemSize]="50" [scrollHeight]="scrollHeight" orientation="horizontal"
                styleClass="border-1 surface-border" [style]="{'width': pScrollerWidth, 'height': pScrollerHeight}">
                <ng-template pTemplate="item" let-item let-options="options">
                    <div class="products-item-image" [photoGallery]="item.imageUrl">
                        <img [style]="{ 'height': pScrollerHeight, 'width': pScrollerImgWidth}"
                            [src]="item.thumbnailUrl" />
                    </div>
                </ng-template>
            </p-scroller>
        </div>
    </div> -->


<ng-template #noImages>
    <!-- Content to display when existingImages is undefined, null, or has a length of 0 -->
    <p>No have been added.</p>
</ng-template>