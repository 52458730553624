<p-blockUI [blocked]="blockedDocument">
    <i class="pi pi-spin pi-spinner" style="font-size: 4rem !important"></i>
</p-blockUI>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" acceptLabel="Yes"
    rejectLabel="No"></p-confirmDialog>
<form [formGroup]="containerForm">
    <input type="hidden" [formControlName]="'documents'" />

    <div class="pb-3">
        <p-breadcrumb [model]="items" [home]="home" *ngIf="showBreadcrumb"></p-breadcrumb>
    </div>
    <p-card styleClass="m-0 p-0" [header]="formHeader" [subheader]="formSubHeader">

        <p-tabView [(activeIndex)]="activeTabIndex" (activeIndexChange)="onTabChange($event)">
            <p-tabPanel header="Container Details">


                <div class="formgrid grid">
                    <input type="hidden" formControlName="billOfLading">


                    <div class="field col-12 sm:12 md:col-6 lg:col-4 xl:col-4">
                        <label for="containerNumber">Container Number <i class="text-red-400">*</i></label>
                        <p-inputMask id="containerNumber" styleClass="w-full" formControlName="containerNumber"
                            type="text" mask="aaaa999999-9" placeholder="e.g. ABCD123456-7" id="containerNumber"
                            (onInput)="containerNumberValidation();test()" (onComplete)="convertToUpperCase();test()"
                            class="text-base text-color w-full"></p-inputMask>
                        <div class="text-red-400"
                            *ngIf="containerForm.controls?.['containerNumber']?.invalid && (containerForm.controls?.['containerNumber']?.dirty || containerForm.controls?.['containerNumber']?.touched)">
                            <div *ngIf="containerForm.controls?.['containerNumber']?.errors?.['required']">Container
                                Number
                                is required.
                            </div>
                            <div
                                *ngIf="containerForm.controls?.['containerNumber']?.errors?.['isContainerNumberExists']">
                                Container Number
                                already exists.
                            </div>
                            <div *ngIf="containerForm.controls?.['containerNumber']?.errors?.['minlength']">Min
                                Length
                                is 5
                            </div>
                            <div *ngIf="containerForm.controls?.['containerNumber']?.errors?.['maxlength']">Max
                                Length
                                is 16
                            </div>
                        </div>
                    </div>


                    <div class="field col-12 sm:12 md:col-6 lg:col-4 xl:col-4">
                        <label for="sealNumber">Seal Number</label>
                        <input id="sealNumber" type="text" formControlName="sealNumber" pInputText maxlength="16"
                            (input)=" containerSealValidation();test()"
                            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">

                        <div class="text-red-400"
                            *ngIf="containerForm.controls?.['sealNumber']?.invalid && (containerForm.controls?.['sealNumber']?.dirty || containerForm.controls?.['sealNumber']?.touched)">
                            <div *ngIf="containerForm.controls?.['sealNumber']?.errors?.['minlength']">Min Length is
                                5
                            </div>
                            <div *ngIf="containerForm.controls?.['sealNumber']?.errors?.['maxlength']">Max Length is
                                16
                            </div>
                            <div *ngIf="containerForm.controls?.['sealNumber']?.errors?.['isContainerSealExists'];">
                                Container
                                Seal Number already exists.</div>
                        </div>
                    </div>

                    <div class="field col-12 sm:12 md:col-6 lg:col-4 xl:col-4">
                        <label for="containerType">Container Type</label>


                        <!-- <label for="containerType">Container Type</label> -->
                        <p-dropdown [options]="groupedContainerType" placeholder="Select a Type" [group]="true"
                            styleClass="field col-12 w-full">
                            <ng-template let-group pTemplate="group">
                                <div class="flex align-items-center">
                                    <span>{{ group.label }}</span>
                                </div>
                            </ng-template>
                            <ng-template let-item pTemplate="item">
                                <div class="flex align-items-center">
                                    <span>{{ item.label }}</span>
                                </div>
                            </ng-template>
                        </p-dropdown>




                    </div>

                    <div class="field col-12 sm:12 md:col-6 lg:col-4 xl:col-4">
                        <label for="productId">Product <i class="text-red-400">*</i></label>
                        <div class="p-inputgroup">
                            <p-dropdown [options]="products" formControlName="productId" optionLabel="name"
                                [filter]="true" [lazy]="true" styleClass="field col-12 w-full" [virtualScroll]="true"
                                [virtualScrollItemSize]="38" [lazy]="true" placeholder="Select Product"
                                [showClear]="true" [ngClass]="{'disabled-product': editMode}"
                                [readonly]="editMode"></p-dropdown>

                            <!--    <p-button icon="pi pi-plus" styleClass="p-button-sm p-button-default ml-1"
                        *ngIf="!isAddLoadingSiteFromMeasurement" (click)="productSidebarVisible = true"
                        pTooltip="Add new product" tooltipPosition="top" [showDelay]="300">
                    </p-button> -->

                            <p-button icon="pi pi-plus-circle" (click)="productSidebarVisible = true"
                                pTooltip="Add new product" tooltipPosition="left"
                                styleClass="p-button-rounded p-button-secondary p-button-text"
                                pTooltip="Add new product" tooltipPosition="left" [showDelay]="200"
                                *ngIf="!isAddLoadingSiteFromMeasurement"></p-button>

                        </div>
                        <div class="text-red-400"
                            *ngIf="containerForm.controls?.['productId']?.invalid && (containerForm.controls?.['productId']?.dirty || containerForm.controls?.['productId']?.touched)">
                            <div
                                *ngIf="containerForm.invalid &&  (containerForm.controls?.['productId']?.errors?.['required'])">
                                Product is required.</div>
                        </div>

                    </div>
                    <div class="field col-12 sm:12 md:col-6 lg:col-4 xl:col-4">
                        <label for="supplierId">Seller</label>
                        <div class="p-inputgroup">
                            <p-dropdown [options]="sellers" formControlName="supplierId" optionLabel="name"
                                [filter]="true" [lazy]="true" styleClass="field col-12 w-full" [virtualScroll]="true"
                                [virtualScrollItemSize]="38" [lazy]="true" placeholder="Select Seller"
                                [showClear]="true"></p-dropdown>
                            <div class="text-red-400"
                                *ngIf="containerForm.controls?.['supplierId']?.invalid && (containerForm.controls?.['supplierId']?.dirty || containerForm.controls?.['supplierId']?.touched)">
                                <div
                                    *ngIf="containerForm.invalid &&  (containerForm.controls?.['supplierId']?.errors?.['required'])">
                                    Seller is required.</div>
                            </div>
                            <!--  <p-button icon="pi pi-plus" styleClass="p-button-sm p-button-default ml-1"
                        *ngIf="!isAddLoadingSiteFromMeasurement" (click)="supplierSidebarVisible = true"
                        pTooltip="Add new supplier" tooltipPosition="top" [showDelay]="300">
                    </p-button> -->
                            <p-button icon="pi pi-plus-circle" (click)="supplierSidebarVisible = true"
                                pTooltip="Add new product" tooltipPosition="left"
                                styleClass="p-button-rounded p-button-secondary p-button-text"
                                pTooltip="Add new supplier" tooltipPosition="left" [showDelay]="200"
                                *ngIf="!isAddLoadingSiteFromMeasurement"></p-button>
                        </div>
                    </div>
                    <div class="field col-12 sm:12 md:col-6 lg:col-4 xl:col-4">
                        <label for="loadingSiteId">Loading Site
                        </label>
                        <div class="p-inputgroup">
                            <p-dropdown [options]="loadingSites" formControlName="loadingSiteId" optionLabel="name"
                                [filter]="true" [lazy]="true" styleClass="field col-12 w-full" [virtualScroll]="true"
                                [virtualScrollItemSize]="38" [lazy]="true" placeholder="Select Loading Site"
                                [showClear]="true"></p-dropdown>
                            <div class="text-red-400"
                                *ngIf="containerForm.controls?.['loadingSiteId']?.invalid && (containerForm.controls?.['loadingSiteId']?.dirty || containerForm.controls?.['loadingSiteId']?.touched)">
                                <div
                                    *ngIf="containerForm.invalid &&  (containerForm.controls?.['loadingSiteId']?.errors?.['required'])">
                                    Loading Site is required.</div>
                            </div>
                            <!-- <p-button icon="pi pi-plus" styleClass="p-button-sm p-button-default ml-1"
                        *ngIf="!isAddLoadingSiteFromMeasurement"
                        (click)="loadingSiteSidebarVisible = true; isAddLoadingSiteFromContainer = true;"
                        pTooltip="Add new loading site" tooltipPosition="top" [showDelay]="300">
                    </p-button> -->

                            <p-button icon="pi pi-plus-circle"
                                (click)="loadingSiteSidebarVisible = true; isAddLoadingSiteFromContainer = true;"
                                pTooltip="Add new loading site" tooltipPosition="left"
                                styleClass="p-button-rounded p-button-secondary p-button-text"
                                pTooltip="Add new supplier" tooltipPosition="left" [showDelay]="200"
                                *ngIf="!isAddLoadingSiteFromMeasurement"></p-button>
                        </div>
                    </div>


                    <!-- Country of Origin-->
                    <div class="field col-12 sm:12 md:col-6 lg:col-4 xl:col-4">
                        <label for="productId">Country of Origin </label>
                        <p-dropdown [options]="countrySuggestions" optionLabel="countryName"
                            formControlName="countryOfOrigin" styleClass="field col-12 w-full"
                            placeholder="Select a Country" [filter]="true" filterBy="countryName" [virtualScroll]="true"
                            [virtualScrollItemSize]="38" [showClear]="true" class="w-full" tooltipPosition="bottom">
                        </p-dropdown>
                    </div>

                    <!-- Certificate of Origin-->
                    <div class="field col-12 sm:12 md:col-6 lg:col-4 xl:col-4">
                        <label for="productId">Port of Loading </label>

                        <!-- <p-dropdown [options]="seaPortsSuggestion" [(ngModel)]="selectedSeaPort" optionLabel="mainPortName"
                    formControlName="seaPort" styleClass="field col-12 w-full" placeholder="Select a Sea  Port"
                    [filter]="true" filterBy="mainPortName" [virtualScroll]="true" [virtualScrollItemSize]="38"
                    [showClear]="true" class="w-full" tooltipPosition="bottom">
                </p-dropdown> -->

                        <p-dropdown [options]="seaPortsSuggestion" optionLabel="mainPortName" [showClear]="true"
                            placeholder="Select a Country" formControlName="seaPort" styleClass="field col-12 w-full"
                            placeholder="Select a Sea  Port" [filter]="true"
                            filterBy="mainPortName,portCode,countryCode" [virtualScroll]="true"
                            [virtualScrollItemSize]="38" [showClear]="true" class="w-full" tooltipPosition="bottom">
                            <ng-template pTemplate="selectedItem">
                                <div class="flex align-items-center gap-2" *ngIf="selectedSeaPort">

                                    <div>{{ selectedSeaPort.mainPortName }}</div>
                                </div>
                            </ng-template>
                            <ng-template let-port pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <div>{{ port.mainPortName }} ({{ port.portCode }}, {{port.countryCode}})
                                    </div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>

                    <!-- Fumigation Types -->
                    <div class="field col-12 sm:12 md:col-6 lg:col-4 xl:col-4">
                        <label for="productId">Fumigation Type </label>

                        <p-dropdown [options]="fumigationTypeSuggestion" optionLabel="type" formControlName="fumigation"
                            styleClass="field col-12 w-full" placeholder="Select a Fumigation" [filter]="true"
                            filterBy="fumigation" [virtualScroll]="true" [virtualScrollItemSize]="38" [showClear]="true"
                            class="w-full" tooltipPosition="bottom">
                        </p-dropdown>
                    </div>

                    <!-- Privacy Types -->
                    <div class="field col-12 sm:12 md:col-6 lg:col-4 xl:col-4">
                        <label for="productId">Privacy Type </label>

                        <select formControlName="privacy" id="privacy"
                            class="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                            style="appearance: auto">
                            <option value="PRIVATE">Private</option>
                            <option value="PROTECTED">Protected</option>
                            <option value="PUBLIC">Public</option>
                            <option value="SOLD">Sold</option>
                            <option value="CUSTOM">Custom</option>

                        </select>
                    </div>

                </div>

                <ng-template pTemplate="footer">

                    <!-- <p-button [label]="addEditButton" [disabled]="!containerForm.dirty" icon="pi pi-save"
                    styleClass="p-button-success w-9rem" type="submit"> </p-button>
                
                <p-button label="Reset" icon="pi pi-sync" styleClass="p-button-secondary  w-9rem" *ngIf="!editMode"
                    [style]="{'margin-left': '.5em'}"  (onClick)="onReset()"></p-button>
                
                <p-button label="Delete" icon="pi pi-trash" styleClass="p-button-danger  w-9rem" *ngIf="editMode"
                    [style]="{'margin-left': '.5em'}" (onClick)="onDelete(this.containerEditData.containerId)" ></p-button> -->

                </ng-template>

                <div class="grid">
                    <div class="col-fixed" class="sm:w-12rem md:w-12rem lg:w-12rem xl:w-12rem">
                        <div class="text-center p-3 font-bold">

                            <p-button [label]="addEditButton" icon="pi pi-save" styleClass="p-button-success w-9rem"
                                (onClick)="onSubmit()"> </p-button>
                        </div>
                    </div>
                    <div class="col-fixed" class="sm:w-12rem md:w-12rem lg:w-12rem xl:w-12rem">
                        <div class="text-center p-3 font-bold">
                            <p-button label="Reset" icon="pi pi-sync" styleClass="p-button-secondary  w-9rem"
                                *ngIf="!editMode" [style]="{'margin-left': '.5em'}" (onClick)="onReset()"
                                [outlined]="true"></p-button>

                            <p-button label="Delete" icon="pi pi-trash" styleClass="p-button-danger  w-9rem"
                                *ngIf="editMode" [style]="{'margin-left': '.5em'}"
                                (onClick)="onDelete(this.containerEditData.containerId)" [outlined]="true"></p-button>
                        </div>
                    </div>
                </div>
            </p-tabPanel>

            <p-tabPanel header="Attachments" [disabled]="!containerEditData">
                <ng-template pTemplate="content">
                    <app-attachment [attachmentData]="containerEditData?.documents?? null"
                        [containerId]="containerEditData.containerId" [containerEditData]="containerEditData?? null"
                        [containerForm]="containerForm?? null">
                    </app-attachment>
                </ng-template>
            </p-tabPanel>

            <p-tabPanel header="Tallysheet" [disabled]="!containerEditData">

                <ng-template pTemplate="content">
                    <app-tally-sheet-four [isCalledFromContainerComponent]="true"
                        *ngIf="containerEditData.productId.formula === 'ROUGH'"
                        [resolvedDataTallysheetSummary]="tallysheetSummary"
                        [resolvedDataTallysheetRecord]="tallysheetRecord" [containerId]="containerEditData.containerId">
                    </app-tally-sheet-four>

                    <app-squared-tallysheet [isCalledFromContainerComponent]="true"
                        *ngIf="containerEditData.productId.formula === 'SQUARED'"
                        [resolvedDataTallysheetSummary]="tallysheetSummary"
                        [resolvedDataTallysheetRecord]="tallysheetRecord"
                        [resolvedDataSquareTallysheetRecord]="tallysheetSquareRecord"
                        [containerId]="containerEditData.containerId">
                    </app-squared-tallysheet>
                </ng-template>
            </p-tabPanel>
        </p-tabView>
        <ng-template pTemplate="footer">
            <!-- <div class="grid">
                <div class="col-fixed" class="sm:w-12rem md:w-12rem lg:w-12rem xl:w-12rem">
                    <div class="text-center p-3 font-bold">
                      
                        <p-button [label]="addEditButton" icon="pi pi-save" styleClass="p-button-success w-9rem"
                            (onClick)="onSubmit()"> </p-button>
                    </div>
                </div>
                <div class="col-fixed" class="sm:w-12rem md:w-12rem lg:w-12rem xl:w-12rem">
                    <div class="text-center p-3 font-bold">
                        <p-button label="Reset" icon="pi pi-sync" styleClass="p-button-secondary  w-9rem"
                            *ngIf="!editMode" [style]="{'margin-left': '.5em'}" (onClick)="onReset()"></p-button>

                        <p-button label="Delete" icon="pi pi-trash" styleClass="p-button-danger  w-9rem"
                            *ngIf="editMode" [style]="{'margin-left': '.5em'}"
                            (onClick)="onDelete(this.containerEditData.containerId)"></p-button>
                    </div>
                </div>
            </div> -->
        </ng-template>
    </p-card>
</form>



<p-sidebar [(visible)]="productSidebarVisible" position="right" [style]="{'width': '50vw'}" (onHide)="setPageTitle()">
    <app-product [isFromContainerSidebar]="true" *ngIf="productSidebarVisible" [showBreadcrumb]="false"
        [isSubmittedFromSidebar]="true" (productSubmit)="onProductSubmit($event)" titleFromSidebar="Add New Product"
        headerFromSidebar="Create Product" subHeaderFromSidebar="Input new product details"></app-product>
</p-sidebar>

<p-sidebar [(visible)]="supplierSidebarVisible" position="right" [style]="{'width': '50vw'}" (onHide)="setPageTitle()">
    <app-seller [isFromContainerSidebar]="true" *ngIf="supplierSidebarVisible" [showBreadcrumb]="false"
        [isSubmittedFromSidebar]="true" (supplierSubmit)="onSupplierSubmit($event)" titleFromSidebar="Add New Supplier"
        headerFromSidebar="Create Seller" subHeaderFromSidebar="Input new seller details"></app-seller>
</p-sidebar>


<p-sidebar [(visible)]="loadingSiteSidebarVisible" position="right" [style]="{'width': '50vw'}"
    (onHide)="setPageTitle()">
    <app-loading-site [isFromContainerSidebar]="true" *ngIf="loadingSiteSidebarVisible" [showBreadcrumb]="false"
        [isSubmittedFromSidebar]="true" [isAddFromContainer]="isAddLoadingSiteFromContainer"
        (loadingSiteSubmit)="onSupplierSubmit($event)" titleFromSidebar="Add New Loading Site"
        headerFromSidebar="Create Loading Site"
        subHeaderFromSidebar="Input new loading site details"></app-loading-site>
</p-sidebar>


<p-dialog header="Port of Loading Mismatch" [(visible)]="portOfLoadingMismatchVisible" [style]="{width: '50vw'}">
    <p class="line-height-2 text-xs text-justify">
        The Port of Loading mentioned in the Bill of Lading does not match with the Port of Loading specified for
        some of the Containers.
    </p>
    <p-table [value]="mismatchContainers" [tableStyle]="{ 'min-width': '20rem' }" styleClass="p-datatable-sm">

        <ng-template pTemplate="header">
            <th>Container Number</th>
            <th>PoL in Container</th>
            <th>PoL in BL</th>
        </ng-template>
        <ng-template pTemplate="body" let-containers>
            <tr>
                <td class="text-center">{{ containers.containerNumber }}</td>
                <td class="text-center">{{ containers.containerPortOfLoading }}</td>
                <td class="text-center text-red-600">{{ containers.billOfLadingPortOfLoading }}</td>
            </tr>
        </ng-template>
    </p-table>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="continueAnywayContainerMismatch()" label="Continue anyway"
            styleClass="p-button-text p-button-danger"></p-button>
        <p-button icon="pi pi-times" (click)="portOfLoadingMismatchVisible = false" label="Stop and Review"
            styleClass="p-button-text"></p-button>
    </ng-template>

</p-dialog>