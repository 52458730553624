<p-blockUI [blocked]="blockedDocument">
    <i class="pi pi-spin pi-spinner" style="font-size: 4rem !important"></i>
</p-blockUI>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" acceptLabel="Yes"
    rejectLabel="No"></p-confirmDialog>
<form [formGroup]="addUserToCompanyForm" (ngSubmit)="onSubmit()">

    <div class="pb-3">
        <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
    </div>
    <!-- <p-chips formControlName="emailAddresses" separator="," placeholder="Hint: a, b, c"></p-chips> -->

    <p-card [header]="formHeader" [subheader]="formSubHeader"> 
        <div class="formgrid grid custom-width"  >

            <div class="field col-12 md:col-6">

                <div class="card p-fluid">
                    <!-- <p-chips [(ngModel)]="emailAddresses" separator="," placeholder="Hint: a, b, c"></p-chips> -->
                    <p>Add Email Address</p>
                    <p-chips formControlName="emailAddresses" separator=","
                        placeholder="e.g. abc@example.com, xyz@example.com" class="w-full"></p-chips>
                    <div class="text-red-400 mt-1"
                        *ngIf="addUserToCompanyForm.controls?.['emailAddresses']?.invalid && (addUserToCompanyForm.controls?.['emailAddresses']?.dirty || addUserToCompanyForm.controls?.['emailAddresses']?.touched)">
                        <div *ngIf="addUserToCompanyForm.controls?.['emailAddresses']?.errors?.['validateEmail']">
                            Invalid Email Address</div>
                        <div *ngIf="addUserToCompanyForm.controls?.['emailAddresses']?.errors?.['required']">Enter Email
                            Address</div>
                    </div>
                </div>
                <div class="mt-2">
                    <p-button label="Add" [disabled]="!addUserToCompanyForm.dirty" icon="pi pi-check"
                        type="submit"></p-button>
                    <!-- <p-button label="Add" type="submit" class="w-10"></p-button> -->
                </div>
            </div>
            <div class="card  w-full">
                <p-table [value]="companyUsers"  *ngIf="isVisible">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="text-center">SR</th>
                            <th class="text-center">Email</th>
                            <th class="text-center">Invitation accepted</th>
                            <th class="text-center">Resend</th>
                        </tr> 
                    </ng-template>
                    <ng-template pTemplate="body" let-user let-rowIndex="rowIndex">
                        <tr>
                            <td class="text-center">{{ rowIndex + 1 }}</td>
                            <td class="text-center">{{ user.email }}</td>
                            <td class="text-center">
                                <span *ngIf="user.accepted"
                                    class="pi pi-check-circle text-green-500 text-center"></span>
                                <span *ngIf="!user.accepted"
                                    class="pi pi-times-circle text-red-500 text-center"></span>
                            </td>
                            <td class="text-center">
                                <p-button *ngIf="!user.accepted" label="Resend" (onClick)="resendInvitation(user)"
                                    styleClass="p-button-link"></p-button>

                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <!-- <td colspan="(_selectedColumns?.length ?? 6) + 2">No records found.</td> -->
                            <td [attr.colspan]="4" class="text-center">No records found.</td>
            
                        </tr>
                    </ng-template>
                </p-table>

            </div>
        </div>

        <div 
            class="text-xs border-round border-1 border-gray-500 font-normal m-2 flex align-items-center justify-content-left">
            <!-- <h2 class="text-lg">Terms and Conditions</h2> -->
            <ul class="custom-width"   >
                <li >
                    Please note that the number of users allowed is subject to the limits of your current subscription
                    plan.</li>
                <li>We kindly request you to be mindful of the maximum user limit specified in your plan.</li>
                <li>In the event that the number of requests exceeds your plan's user limit, additional users will not
                    be able to join.</li>
                <li>Users who accept the invitation will be able to join the app. However, if the maximum user limit is
                    reached, any remaining users will be unable to join. In this case, we recommend considering an
                    upgrade to your subscription plan.</li>
            </ul>


        </div>
    </p-card>
</form>