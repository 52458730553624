import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BuyerService } from '../../buyer/services/buyer.service';
import { SubscriptionService } from '../services/subscription.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentDetailResolver implements Resolve<any> {
  constructor(
    private subscriptionService: SubscriptionService,
    private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    
    return this.subscriptionService.getCartSubscriptionPeriod().pipe(
      tap((response) => {
        console.log(`PaymentDetailResolver response is ${JSON.stringify(response)}`)
      }),
      catchError((error) => {
        console.error(`PaymentDetailResolver error is ${JSON.stringify(error)}`);
        this.router.navigate(['/error']); // Redirect on error
        return of(null);
      })
    );
  }
}
