<ng-container *ngIf="router.url !== '/login'">
    <div *ngIf="isMenuExpand;else smallMenu">
        <p-sidebar [(visible)]="isMenuExpand" [baseZIndex]="10000" [closeOnEscape]="true" [modal]="true"
            class="w-full custom-sidebar" [style]="{width: '18rem'}" class="custom-sidebar" (onHide)="onHide()">
              <ng-template pTemplate="header">
                <div class="p-grid">
                    <div class="p-col">
                      
                        <p class="no-padding mr-2 font-bold">Hello
                            {{userProfile?.firstName ?? ''}}
                            {{userProfile?.lastName ?? ''}}!</p>
                    </div>
                    <div class="p-col-12">
                        <p class="text-xs">Company ID: {{userProfile?.companyId ?? ''}}</p>
                    </div>
                </div>
            </ng-template>
            <!-- Header Content -->
              <ng-template pTemplate="content">
                <div class="field card flex flex-wrap justify-content-left w-full mb-2">
                    <span class="p-input-icon-right  w-full">
                        <i class="pi pi-search" *ngIf="!searchString"></i>
                        <!-- <input #searchInput class="p-inputtext-sm w-full" [autofocus]="true" type="search"
                            [(ngModel)]="searchString" (input)="getMenuItems()" placeholder="Search menu..." /> -->
                    <input #searchInput class="p-inputtext-sm w-full" [autofocus]="true" type="search"
                        [(ngModel)]="searchString" (input)="filterMenuItems()" placeholder="Search menu..." />
                    </span>
                </div>
                <p-panelMenu [model]="accessibleMenuItems" class="w-full" styleClass="custom"
                    *ngIf="accessibleMenuItems && accessibleMenuItems.length >0; else noMenu"></p-panelMenu>
                <ng-template #noMenu>
                    <p class="pt-3 m-2 text-center">No menu items found.</p>
                </ng-template>
            </ng-template>

            <ng-template pTemplate="footer" let-footer class="mt-2">
                <p-divider type="solid"></p-divider>
                <table>
                    <tr>
                        <td rowspan="2">
                            <ng-container *ngIf="userProfile?.profilePicture; else noImageUrl">
                                <p-avatar #avatar [image]="userProfile?.profilePicture?? ''"
                                    styleClass="useravatar mr-2" class="useravatar" shape="circle"></p-avatar>
                            </ng-container>
                            <ng-template #noImageUrl>
                                <p-avatar [label]="userProfile?.firstName?.charAt(0) || 'U'" styleClass="mr-2"
                                    size="normal" [style]="{ 'background-color': '#2196F3', color: '#ffffff' }"
                                    shape="circle"></p-avatar>
                            </ng-template>
                        </td>
                        <td>
                            <p class="no-padding no-margin font-bold">
                                {{userProfile?.firstName ?? ''}} {{ userProfile?.lastName?? ''}}
                            </p>
                    </tr>
                    <tr>
                        <p-button label="Logout" styleClass="no-padding no-margin p-button-link"
                            (onClick)="onLogout()"></p-button>


                    </tr>
                </table>
                <span class="text-center">SB {{user?.sbAppVersion?? ''}} NG {{ngAppVersion}}</span>
            </ng-template>
        </p-sidebar>
    </div>
    <ng-template #smallMenu>

    </ng-template>
</ng-container>