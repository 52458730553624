import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AppConstants } from '../commons/app-constants';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  private key: CryptoKey | null = null;
  private keyReady: Promise<void>;

  constructor(
    private cookieService: CookieService
  ) {
    this.keyReady = this.initKey();
  }

  public async initKey() {
    const keyFromCookie = this.getKeyFromCookie();
    if (keyFromCookie) {
      const rawKey = this.base64ToArrayBuffer(keyFromCookie);
      this.key = await crypto.subtle.importKey(
        'raw',
        rawKey,
        { name: 'AES-GCM' },
        false,
        ['encrypt', 'decrypt']
      );
    } else {
      console.error('Encryption key not found in cookies.');
    }
  }

  private getKeyFromCookie(): string | null {
    const key = this.cookieService.get(AppConstants.ENCRYPTION_KEY_COOKIE_NAME);
   console.log(`Key using service is ${key}`);
    return key ? key : null;
  }

  private base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  async encrypt(text: string): Promise<string> {
    await this.keyReady; // Wait for the key initialization
    if (!this.key) {
      throw new Error('Encryption key is not available.');
    }

    const iv = crypto.getRandomValues(new Uint8Array(12));
    const encodedText = new TextEncoder().encode(text);
    const encryptedData = await crypto.subtle.encrypt(
      { name: 'AES-GCM', iv },
      this.key,
      encodedText
    );
    return `${this.arrayBufferToHex(iv)}:${this.arrayBufferToHex(new Uint8Array(encryptedData))}`;
  }

    async decrypt(ciphertext: string): Promise<string> {
  //  console.log(`Decrypt called with ${ciphertext}`);

    await this.keyReady; // Ensure key is initialized
    if (!this.key) {
      throw new Error('Encryption key is not available.');
    }

    // Step 1: Verify that ciphertext is in the correct format
    const parts = ciphertext.split(':');
    if (parts.length !== 2) {
      throw new Error('Invalid ciphertext format; expected ivHex:encryptedHex');
    }

    const [ivHex, encryptedHex] = parts;

  //  console.log(`ivHex: ${ivHex}, encryptedHex: ${encryptedHex}`);

    // Step 2: Validate ivHex and encryptedHex
    if (!ivHex || !encryptedHex || encryptedHex === 'undefined') {
      throw new Error('ivHex or encryptedHex is missing or undefined.');
    }

    // Step 3: Convert ivHex and encryptedHex from hex to ArrayBuffer
    const iv = this.hexToArrayBuffer(ivHex);
    const encryptedData = this.hexToArrayBuffer(encryptedHex);

    // Step 4: Decrypt using AES-GCM with iv and key
    const decryptedData = await crypto.subtle.decrypt(
      { name: 'AES-GCM', iv },
      this.key,
      encryptedData
    );

  //  console.log(`decryptedData: ${decryptedData}`);

    // Step 5: Decode the decrypted data to a UTF-8 string
    return new TextDecoder().decode(decryptedData);
  }

  // Helper function to convert hex string to ArrayBuffer
  private hexToArrayBuffer(hex: string): ArrayBuffer {
    const typedArray = new Uint8Array(
      hex.match(/.{1,2}/g).map(byte => parseInt(byte, 16))
    );
    return typedArray.buffer;
  }

  private arrayBufferToHex(buffer: ArrayBuffer): string {
    return Array.from(new Uint8Array(buffer))
      .map(b => b.toString(16).padStart(2, '0'))
      .join('');
  }


}
