import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SideBarMenuSharedService {
  isMenuExpanded = new EventEmitter<boolean>();;

  constructor() { }
}
