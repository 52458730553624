import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { disableConsoleInProduction } from './app/modules/utility/console-utility';
import { bootstrapApplication, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ToastMessagesService } from './app/commons/services/toast-messages.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CurrencyPipe, DatePipe } from '@angular/common'; // Import DatePipe
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app/app-routing.module';
import { TallysheetHttpInterceptorService } from './app/interceptors/tallysheet-http.interceptor';
import { DialogService } from 'primeng/dynamicdialog';
import { FontSizeServiceService } from './app/services/font-size-service.service';
// main.ts
import 'hammerjs';
import { MyHammerConfig } from './app/helper/hammer.config';
import { environment } from './environments/environment';
declare const module: any;



disableConsoleInProduction();

// bootstrapApplication(AppComponent).catch(err => console.error(err));

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptorsFromDi()), // Enable interceptors from DI

    MessageService,
    DatePipe,
    ConfirmationService,
    DialogService,
    FontSizeServiceService,
    CurrencyPipe,
    Location,
    importProvidersFrom(BrowserAnimationsModule),
    provideRouter(routes), // Define your routes here
    { provide: HTTP_INTERCEPTORS, useClass: TallysheetHttpInterceptorService, multi: true },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },

  ]
}).catch(err => console.error(err));

// Handle HMR (Optional - can be skipped if --hmr works without it)
if (module['hot']) {
  module['hot'].accept();
  module['hot'].dispose(() => {
    const appRef = window['ngRef'];
    if (appRef) {
      appRef.destroy();
    }
  });
}


//if (environment.production) {
 // console.log = () => { };
  //console.warn = () => { };
  //console.error = () => { }; // Optional, if you want to disable error logs too
  //console.info = () => { };
  //console.debug = () => { };
//}
