<router-outlet></router-outlet><!--  -->
<div class="custom-card">
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [style]="{ 'width': '70vw','min-width': '280px','max-width': '600px' }"></p-confirmDialog>
    <div class="pb-3">
        <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
    </div>

    <p-card [header]="formHeader" [subheader]="formSubHeader" class="w-full">
        <p-speedDial [model]="loadingSiteSpeedDialItems" direction="up-left" type="quarter-circle" [radius]="120"
            class="ml-5 my-speed-dial" [rotateAnimation]="true"></p-speedDial>
        <div>
            <p-table #dt [lazy]="true" [value]="loadingSites" (onLazyLoad)="sortLoadingSites($event)" [rowHover]="'true'"
                [customSort]="true" sortMode="multiple" dataKey="loadingSiteId"
                [globalFilterFields]="['name','region','supplierId','notes']" [showCurrentPageReport]="true"
                [rows]="rows" [rowsPerPageOptions]="rowsPerPageOptions" [paginator]="true" [first]="first"
                currentPageReportTemplate="Showing {{first}} to {{last}} of {{totalRecords}} entries"
                [totalRecords]="totalRecords" [columns]="cols" [(selection)]="selectedLoadingSites"
                (onRowSelect)="onRowSelect($event)" [styleClass]="tableStyle" [tableStyle]="tableStyleOptions"
                [loading]="loading" paginatorDropdownAppendTo="body">


                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-between">
                        <!-- p-button [label]="isMobile ? '' : 'Clear'"
                        styleClass="p-button-outlined sm:p-button-sm md:p-button-md mr-1" icon="pi pi-filter-slash"
                        (click)="clear(dt)" class="clear-button">
                    </p-button>

                    <p-button [label]="isMobile ? '' : 'Delete'"
                        styleClass="p-button-outlined sm:p-button-sm md:p-button-md" icon="pi pi-trash"
                        (click)="deleteRows()" label="Delete" class="clear-button"></p-button> -->
                        <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" class="multiselectfields"
                            selectedItemsLabel="{0} Selected" placeholder="Choose Columns" defaultLabel="Choose Columns"
                            [maxSelectedLabels]="3"> </p-multiSelect>
                        <span class="p-input-icon-left">
                            <div class="p-inputgroup">
                                <input pInputText type="text" (input)="globalFilter($event,dt)" #globalFiterInput
                                    class="searchglobally" placeholder="Search keyword" />
                                <button type="button" pButton icon="pi pi-filter-slash" styleClass="p-button-warn"
                                    (click)="clearAllFilter(dt,globalFiterInput)"></button>
                            </div>
                        </span>
                    </div>
                </ng-template>

                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <!-- style="width: 4rem" -->
                        <th>
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="name" p-toggleableColumn>Loading Site Name
                            <p-columnFilter type="text" field="name" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                            <p-sortIcon field="name"></p-sortIcon>
                        </th>
                        <th pSortableColumn="contactPerson" *ngIf="_selectedColumns!.includes('Contact Person')">Contact
                            Person
                            <p-columnFilter type="text" field="contactPerson" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                            <p-sortIcon field="supplierId"></p-sortIcon>
                        </th>
                        <th *ngIf="_selectedColumns!.includes('Country')">Country
                            <p-columnFilter type="text" field="country" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                        </th>
                        <th *ngIf="_selectedColumns!.includes('State')">State
                            <p-columnFilter type="text" field="state" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                        </th>
                        <th *ngIf="_selectedColumns!.includes('City')">City
                            <p-columnFilter type="text" field="city" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                        </th>
                        <th *ngIf="_selectedColumns!.includes('Capacity')">Capacity
                            <p-columnFilter type="text" field="capacity" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                        </th>
                        <th *ngIf="_selectedColumns!.includes('Machines Installed')">Machines Installed
                            <p-columnFilter type="text" field="machinesInstalled" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                        </th>

                        <th *ngIf="_selectedColumns!.includes('Notes')">Notes
                            <p-columnFilter type="text" field="notes" display="menu" matchMode="contains"
                                [showMatchModes]="false" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-loadingSite let-columns="columns">
                    <!-- $event.target.tagName !== 'BUTTON' ? row : null) -->
                    <tr [pSelectableRow]="columns" (click)="onRowClick(loadingSite)" p-selectable-row>
                        <!-- <tr [pSelectableRow]="columns" (click)="onRowClick(seller)" p-selectable-row> -->
                        <td (click)="$event.stopPropagation()">
                            <p-tableCheckbox [value]="loadingSite" (click)="$event.stopPropagation()"></p-tableCheckbox>
                        </td>
                        <td> {{loadingSite.name}}</td>
                        <td *ngIf="_selectedColumns!.includes('Contact Person')"> {{loadingSite?.contactPerson?? ''}}
                        </td>
                        <td *ngIf="_selectedColumns!.includes('Country')">{{loadingSite?.location?.country.countryName??
                            ''
                            }}</td>
                        <td *ngIf="_selectedColumns!.includes('State')">{{ loadingSite?.location?.state?.name?? '' }}
                        </td>
                        <td *ngIf="_selectedColumns!.includes('City')">{{ loadingSite?.location?.city?.name?? '' }}</td>
                        <td *ngIf="_selectedColumns!.includes('Capacity')">{{ loadingSite?.capacity?? '' }}</td>
                        <td *ngIf="_selectedColumns!.includes('Machines Installed')">{{ loadingSite?.machinesInstalled??
                            ''
                            }}</td>
                        <td *ngIf="_selectedColumns!.includes('Notes')">{{ loadingSite?.notes?? '' }}</td>

                    </tr>
                </ng-template>

                <!--  <ng-template pTemplate="paginatorleft">
            <p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button>
        </ng-template>
        <ng-template pTemplate="paginatorright">
            <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button>
        </ng-template> -->
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="(_selectedColumns ? _selectedColumns.length + 2 : 5)" class="text-center">No
                            records found.</td>

                    </tr>
                </ng-template>
                <ng-template pTemplate="loadingbody" let-columns="columns">
                    <tr style="height:46px">
                        <td>

                        </td>
                        <td> </td>
                        <td *ngIf="_selectedColumns!.includes('Contact Person')"> <p-skeleton></p-skeleton> </td>
                        <td *ngIf="_selectedColumns!.includes('Country')"> <p-skeleton></p-skeleton> </td>
                        <td *ngIf="_selectedColumns!.includes('State')"> <p-skeleton></p-skeleton> </td>
                        <td *ngIf="_selectedColumns!.includes('City')"> <p-skeleton></p-skeleton> </td>
                        <td *ngIf="_selectedColumns!.includes('Capacity')"> <p-skeleton></p-skeleton> </td>
                        <td *ngIf="_selectedColumns!.includes('Machines Installed')"> <p-skeleton></p-skeleton></td>
                        <td *ngIf="_selectedColumns!.includes('Notes')"> <p-skeleton></p-skeleton> </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-card>
</div>
<!-- 
<button pButton icon="pi pi-eye" (click)="showNameColumn = !showNameColumn"
    [label]="showNameColumn ? 'Hide Name' : 'Show Name'"></button>

<button pButton icon="pi pi-eye" (click)="getSelectedLoadingSites()" label="Get Selected Buyers"></button> -->

<p-sidebar [(visible)]="loadingSiteSidebarVisible" position="right" [style]="{'width': '50vw'}"
    (onHide)="setPageTitle()">
    <app-loading-site *ngIf="loadingSiteSidebarVisible" [showBreadcrumb]="false" [isSubmittedFromSidebar]="true"
        (loadingSiteSubmit)="onLoadingSiteSubmit($event)" titleFromSidebar="Add New Loading Site"
        headerFromSidebar="Create Loading Site"
        subHeaderFromSidebar="Input new loading site details"></app-loading-site>
</p-sidebar>