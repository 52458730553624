import { Component, Input, OnInit } from '@angular/core';
import { RootService } from '../services/root-service.service';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-view-master-subscription',
  templateUrl: './view-master-subscription.component.html',
  styleUrls: ['./view-master-subscription.component.css'],
  standalone: true,
  imports: [SHARED_MODULES],
})
export class ViewMasterSubscriptionComponent implements OnInit {
  protected items!: MenuItem[];
  home!: MenuItem;
  formHeader = 'View Master Subscription ';
  formSubHeader!: string;
  subscriptions: any;
  _selectedColumns!: any[];
  cols!: any[];

  constructor(private rootService: RootService, private router: Router) {}

  ngOnInit(): void {
    this.populateBreadCrumb();
    this.home = { icon: 'pi pi-home', routerLink: '/dashboard' };
    this.initTableColumns();
    this.getAllSubscriptions();
  }

  initTableColumns() {
    this._selectedColumns = ['Contact Person', 'GST'];
    this.cols = [
      'Contact Person',
      'GST',
      'City',
      'State',
      'Country',
      'Phone',
      'Email',
    ];
  }

  getAllSubscriptions() {
    this.rootService.getAllSubscriptions().subscribe(
      (response: any) => {
        console.log(`Response is ${JSON.stringify(response)}`);
        this.subscriptions = response;
      },
      (error: HttpErrorResponse) => {
        console.error(
          `Error in getAllSubscriptions is ${JSON.stringify(error)}`
        );
        if (error.status === 403) {
          // Handle 403 Forbidden error
          console.log('Access Denied. You do not have permission to access this resource.');
          this.router.navigate(['error/forbidden']);
          // You can also redirect the user, show a message, etc.
        } else {
          // Handle other types of errors
          console.log(`An error occurred: ${error.message}`);
        }
      },
      () => {}
    );
  }

  onRowClick(subscription: any) {
    console.log(`${JSON.stringify(subscription.id)}`);
    this.router.navigate(['/root/master-subscription', subscription.id]);
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) => val.includes(col));
  }

 /*  sortPricing(pricings: any[]): any[] {
    return pricings.sort((a, b) => {
      if (a.currency.name === 'USD') return -1;
      if (b.currency.name === 'USD') return 1;
      return 0;
    });
  } */

  sortPricing(pricings: any[]): any[] {
    return pricings.sort((a, b) => {
      if (a.currency.name === 'USD' && b.currency.name !== 'USD') {
        return -1;
      } else if (a.currency.name !== 'USD' && b.currency.name === 'USD') {
        return 1;
      } else {
        return a.price - b.price; // Sorting by price within the same currency
      }
    });
  }
  populateBreadCrumb() {
    this.items = [
     
      { label: 'View Master Subscriptions' },
    ];
  }

  addNewSubscription(){
    this.router.navigate(['/root/master-subscription']);
  }
}
