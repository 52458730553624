import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../commons/app-constants';
import { EncryptionService } from './encryption.service';

@Injectable({
  providedIn: 'root'
})
export class SideBarMenuService {

  // private baseUrl = `${environment.httpProtocol}${environment.ipaddress}${environment.port}`;

  private baseUrl = `${AppConstants.API_URL}`;


  constructor(private http: HttpClient,
    private encryptionService: EncryptionService
  ) { }

  getSideBarMenu() {
    const url = `${AppConstants.API_URL}api/v1/menu-items`;
    console.log(`Url for getting menu ${url}`)
    return this.http.get(url);
  }

  getUserProfile() {
    const url = `${AppConstants.API_URL}api/v1/user-profile`;
    console.log(`Url for getting user profile ${url}`)
    return this.http.get(url);
  }


  

  async setEncryptedUserProfile(userProfile: any) {
    try {
      const userProfileString = JSON.stringify(userProfile);
//      console.log(`Plain User Profile {}`,userProfile);
      const encryptedData = await this.encryptionService.encrypt(userProfileString);
      localStorage.setItem(AppConstants.USER_PROFILE_KEY, encryptedData);
    // console.log("Encrypted Profile:", encryptedData);
    } catch (error) {
      console.error('Error encrypting user profile:', error);
    }
  }

  async getDecryptedUserProfile(): Promise<any> {
    let attempts = 0;
    const maxAttempts = 1;
    const retryDelay = 3000; // 3 seconds

    while (attempts < maxAttempts) {
      try {
        const encryptedProfile = localStorage.getItem(AppConstants.USER_PROFILE_KEY);
        if (encryptedProfile) {
          const decryptedData = await this.encryptionService.decrypt(encryptedProfile);
          const userProfile = JSON.parse(decryptedData);
         console.log("Decrypted Profile:", userProfile);
          return userProfile;
        }
        return null;
      } catch (error) {
        attempts++;
        console.error(`Error decrypting user profile (attempt ${attempts}):`, error);
        if (attempts < maxAttempts) {
          await new Promise(resolve => setTimeout(resolve, retryDelay));
        } else {
          return null;
        }
      }
    }
  }
}
