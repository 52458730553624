import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app/commons/app-constants';

@Injectable({
  providedIn: 'root'
})
export class ContainerViewService {

  constructor(private http: HttpClient) { }

  getViewContainer(containerCode: string) {
    const url = `${AppConstants.API_URL}api/v1/container-view/view-container/${containerCode}`;
    console.log(`Url getViewContainer is ${url}`);
    return this.http.get(url);
  }

  getViewBL(blCode: string) {
    const url = `${AppConstants.API_URL}api/v1/bl-view/view-bl/${blCode}`;
    console.log(`Url getViewBL is ${url}`);
    return this.http.get(url);
  }

  getSquareTallyRecords(containerCode: string) {
    return this.http.get(`${AppConstants.API_URL}api/v1/container-view/${containerCode}/square-tally-records`);
  }

  // getPaginatedRoughTallyRecords(containerCode: string, page: number = 1, size: number = 20) {
  //   const url = `${AppConstants.API_URL}api/v1/container-view/${containerCode}/rough-tally-records`;
  //   console.log(`Url is ${url}`);
  //   return this.http.get(url, {
  //     params: {
  //       page: page.toString(),
  //       size: size.toString()
  //     }
  //   });
  // }

  getPaginatedRoughTallyRecords(containerCode: string, page: number = 1, size: number = 20, sortMode?: string, multiSortMeta?: any) {
    const url = `${AppConstants.API_URL}api/v1/container-view/${containerCode}/rough-tally-records`;
    
    let params: any = {
      page: page.toString(),
      size: size.toString()
    };
    if (sortMode) {
      params.sortMode = sortMode;
    }

    if (multiSortMeta) {
      params.multiSortMeta = JSON.stringify(multiSortMeta);
    }
    console.log(`Url is ${url} with params:`, params);

    return this.http.get(url, { params });
  }

  getPaginatedSquaredTallyRecords(containerCode: string, page: number = 1, size: number = 20, sortMode?: string, multiSortMeta?: any) {
    const url = `${AppConstants.API_URL}api/v1/container-view/${containerCode}/square-tally-records`;
    
    let params: any = {
      page: page.toString(),
      size: size.toString()
    };
    if (sortMode) {
      params.sortMode = sortMode;
    }

    if (multiSortMeta) {
      params.multiSortMeta = JSON.stringify(multiSortMeta);
    }
    console.log(`Url is ${url} with params:`, params);

    return this.http.get(url, { params });
  }
}
