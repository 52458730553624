import { LoadingSite } from "../../loading-site/model/loading-site";
import { Measurement } from "../../measurement/model/measurement";
import { Product } from "../../product/model/product";
import { Seller } from "../../seller/model/seller";
import { ContainerSummary } from "./container-summary";
import { Photo } from "./photo";

export class Container {
    containerId!: number;
    containerNumber!: string;
    sealNumber!: string; 
    productId!: Product;
    supplierId!: Seller;
    loadingSiteId!: LoadingSite;
    photos!: Photo[];
    measurements!: Measurement[];
    containerSummary!: ContainerSummary;
    fumigation: any;
    seaPort: any;
    countryOfOrigin: any;
    containerType: any;
    billOfLading:any;
 documents:any;
 shortenUrl: string;
}
