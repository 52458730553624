import { Injectable } from '@angular/core';
import { Container } from '../model/container';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContainerSharedDataService {

  containerSharedData!: any;

  constructor(http: HttpClient) { }

  saveDataToLocalStorage(data: Container) {
    localStorage.setItem(this.containerSharedData, JSON.stringify(data));
  }

  getDataFromLocalStorage(): Container {
    const data = localStorage.getItem(this.containerSharedData);
    return JSON.parse(data!);
  }

   
}
