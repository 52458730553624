import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Confirmation, ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { City } from 'src/app/commons/models/city';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { PopulateDropdownService } from 'src/app/services/populate-dropdown.service';
import { NameCheckValidator } from 'src/app/validators/name-check-validator';
import { BillOfLading } from '../model/bill-of-lading';
import { ContainerNumber } from '../model/container-number';
import { BillOfLadingSharedDataService } from '../service/bill-of-lading-shared-data.service';
import { BillOfLadingService } from '../service/bill-of-lading.service';
import moment from 'moment';
import { Buyer } from '../../buyer/model/buyer';
import { ShippingLine } from '../model/shipping-line';
import { Container } from '../../container/model/container';
import { ContainerService } from '../../container/services/container.service';
import { ConfirmDeleteCommonService } from 'src/app/commons/services/confirm-delete-common.service';
import { ResetCommonService } from 'src/app/commons/services/reset-common.service';
import { SHARED_MODULES } from '../../shared-imports';
import { BuyerComponent } from '../../buyer/buyer/buyer.component';

@Component({
  selector: 'app-bill-of-lading',
  templateUrl: './bill-of-lading.component.html',
  styleUrls: ['./bill-of-lading.component.css'],
  providers: [ToastMessagesService],
  standalone:true,
  imports:[SHARED_MODULES,  BuyerComponent]
})
export class BillOfLadingComponent implements OnInit, OnDestroy {
  blockedDocument = false;
  buyerSidebarVisible: boolean = false;
  notes: string = '';
  portOfLoadingMismatchVisible = false;
  editMode = false;
  seaPortsSuggestion: any;
  selectedPortOfLoading!: any | undefined;
  selectedPortOfDestination!: any | undefined;
  isSubmitting = false;
  addEditButton = 'Save';
  billOfLadingEditData!: BillOfLading;
  containerNumbers: ContainerNumber[] = [];
  selectectedContainerNumbers: ContainerNumber[] = [];
  formHeader!: string;
  formSubHeader!: string;
  protected items!: MenuItem[];
  home!: MenuItem;
  billOfLadingForm!: FormGroup;
  form: any;
  buyers!: Buyer[];
  shippingLines!: ShippingLine[];
  loading: boolean = false;
  data: BillOfLading = new BillOfLading();
  minETADate!: Date;
  existingContainerNumbersTemp!: ContainerNumber;
  mismatchContainers!: any[];
  errorMessage: string = '';
  showPermissionConflictsDialog: boolean = false;
  @ViewChild('showPermissionCD') showPermissionCD: Confirmation; // Access dialog instance

  constructor(
    private fb: FormBuilder,
    private nameValidator: NameCheckValidator,
    private populateDropdownService: PopulateDropdownService,
    private billOfLadingService: BillOfLadingService,
    private containerService: ContainerService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private billOfLadingSharedDataService: BillOfLadingSharedDataService,
    private titleService: Title,
    private route: ActivatedRoute,
    private toastService: ToastMessagesService,
    private confirmDeleteServiceCommon: ConfirmDeleteCommonService,
    private resetServiceCommon: ResetCommonService
  ) {
    this.setPageTitle();
    this.buyers = [];
    this.populateBreadCrumb();
    this.populateBuyers();
    this.populateShippingLines();
    this.populatContainerNumbers();
  }

  ngOnInit(): void {
    //  this. containerNumbers = [
    //     { containerNumber: 'Option 1', containerId: 1 },
    //     { containerNumber: 'Option 2', containerId: 2 },
    //     { containerNumber: 'Option 3', containerId: 3 },
    //     { containerNumber: 'Option 4', containerId: 4 },
    //   ];
    this.populatContainerNumbers();
    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.createForm();
    const url = this.router.url;
    if (url.includes('edit/bill-of-lading')) {
      this.editMode = true;

      this.billOfLadingEditData =
        this.billOfLadingSharedDataService.getDataFromLocalStorage();

      //  this.containerNumbers = this.billOfLadingEditData.containers.;
      console.log(
        `Shared Data BillOfLadingComponent is  ${JSON.stringify(
          this.billOfLadingEditData
        )}`
      );
      this.containerArrayChips = this.billOfLadingEditData.containers;

      //this.billOfLadingEditData.eta = moment(this.billOfLadingEditData.eta, 'YYYY-MM-DD').format('DD-MM-YYYY');
      //this.billOfLadingEditData.etd = moment(this.billOfLadingEditData.etd, 'YYYY-MM-DD').format('DD-MM-YYYY');

      console.log(
        `Shared Data BillOfLadingComponent is  etd ${JSON.stringify(
          this.billOfLadingEditData.etd
        )}`
      );
      console.log(
        `Shared Data BillOfLadingComponent is  eta ${JSON.stringify(
          this.billOfLadingEditData.eta
        )}`
      );

      this.billOfLadingForm.patchValue(this.billOfLadingEditData);
      console.log(`this.billOfLadingEditData.portOfLoading= ${JSON.stringify(
        this.billOfLadingEditData.portOfLoading
      )},
      this.billOfLadingEditData.portOfDestination ${JSON.stringify(
        this.billOfLadingEditData.portOfDestination
      )})`);
      this.fetchSeaPorts(
        this.billOfLadingEditData.portOfLoading,
        this.billOfLadingEditData.portOfDestination
      );

      const etaDate1 = new Date();
      this.billOfLadingForm!.get('blNumber')!.setAsyncValidators([]);
      this.addEditButton = 'Update';
    } else {
      this.fetchSeaPorts();
    }
  }

  private createForm() {
    this.billOfLadingForm = this.fb.group({
      billOfLadingId: [],
      blNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(64),
        ],
        [this.nameValidator.checkBLNumberValidator()],
      ],
      buyerId: [],
      shippingLineId: [],
      vesselName: ['', [Validators.minLength(3), Validators.maxLength(64)]],
      vesselCode: ['', [Validators.minLength(3), Validators.maxLength(32)]],
      eta: [, [Validators.minLength(3), Validators.maxLength(15)]],
      etd: [, [Validators.minLength(3), Validators.maxLength(15)]],
      // containers: [[], [Validators.required]],
      containers: [[]],
      portOfLoading: [],
      portOfDestination: [],
      fumigation: [],
      notes: [],
      privacy: ['PRIVATE'],
    });
  }

  populateBreadCrumb() {
    this.items = [
      { label: 'Bill Of Lading', routerLink: '/bill-of-ladings' },
      { label: 'Add Bill Of Lading' },
    ];
  }

  private populateShippingLines() {
    this.populateDropdownService
      .getShippingLines()
      .subscribe((shippingLines) => (this.shippingLines = shippingLines));
  }
  private populateBuyers() {
    this.populateDropdownService
      .getBuyers()
      .subscribe((buyers) => (this.buyers = buyers));
  }
  private populatContainerNumbers(data?: Container[]) {
    console.warn(`%%%% ${this.billOfLadingService.getContainerNumbers()}`);
    this.billOfLadingService
      .getContainerNumbers()
      ?.subscribe((containerNumbers) => {
        this.containerNumbers = containerNumbers;
        if (data) {
          data.forEach((eachContainer) => {
            this.containerNumbers.push({
              containerId: eachContainer.containerId,
              containerNumber: eachContainer.containerNumber,
            });
            // this.billOfLadingForm!.get('containers')!.setValue([{containerNumbers:'HELLO',containerId:1},{containerNumbers:'Shree Ganeshay Namah Two',containerId:2}]);
          });
        }
        this.fetchDataAndPreselectValues();
      });
  }

  onETDSelect(value: Date) {
    console.log(`ETD entered is ${value}`);
    this.minETADate = value;
  }

  onSubmit() {
    console.log(this.billOfLadingForm.get('containers')!.value);
    this.billOfLadingForm.markAllAsTouched();
    if (this.editMode) {
      console.info(`in EDIT MODE`);
      this.edit();
    } else {
      console.info(`in SAVE MODE`);
      this.save();
    }
  }

  private isValidControl(control: any) {
    return control.invalid;
  }

  private getFormattedDate(dateString: any, controlName: any) {
    if (dateString) {
      // Parse the date with the correct format
      const date = moment.utc(dateString, 'DD/MM/YYYY').local();

      // Check if the date is valid
      if (!date.isValid()) {
        console.error(`Invalid date: ${dateString}`);
        return;
      }

      console.log(
        ` this.billOfLadingForm.controls['${controlName}'].value  ${this.billOfLadingForm.controls[controlName].value}`
      );
      const formattedDate = date.format('DD/MM/YYYY');
      console.log(` Formatted Date is ${formattedDate}`);
      this.billOfLadingForm.patchValue({ [controlName]: formattedDate });
    }
  }

  private processDataOnSubmit(resp: any) {
    console.log(`On Submit Response is ${JSON.stringify(resp)}`);
    if (resp.containers) {
      resp.containers.forEach(
        (container: { containerId: any; containerNumber: any }) => {
          this.containerNumbers.push({
            containerId: container.containerId,
            containerNumber: container.containerNumber,
          });
        }
      );
    }

    if (this.editMode) {
      this.toastService.showSuccessMessage(
        'Success',
        'Bill Of Lading Edited Successfully'
      );
      this.router.navigate(['/bill-of-ladings']);
    } else {
      this.onReset();
      this.toastService.showSuccessMessage(
        'Success',
        'Bill Of Lading Created Successfully'
      );

      this.confirmationService.confirm({
        message: 'Do you want to add more bill of lading?',
        accept: () => {
          this.billOfLadingForm.reset();
        },
        reject: () => {
          this.router.navigate(['/bill-of-ladings']);
        },
      });
    }
  }

  private handleFormError(error: any) {
    console.log(`On Submit error is ${JSON.stringify(error)}`);
    if(error.error.message){
      this.showPermissionConflictsDialog = true;
      this.errorMessage = error.error.message;
    }
    if (error.error.mismatchedContainerDetails) {
      this.portOfLoadingMismatchVisible = true;
      console.log(
        `ERRORS __>> ${JSON.stringify(error.error.mismatchedContainerDetails)}`
      );
      this.mismatchContainers = error.error.mismatchedContainerDetails;
      console.log(
        `ERRORS __>> ${JSON.stringify(this.mismatchContainers.length)}`
      );
    } else {
      this.toastService.showErrorMessage('Error', error.error.message);
    }
  }

  private processForm(containerMismatchContinueAnyway: boolean = false, ignorePermissionConflict:boolean = false) {
    this.isSubmitting = true;
    this.billOfLadingForm.markAllAsTouched();

    const controlKeys = Object.keys(this.billOfLadingForm.controls);
    const invalidControlKeys = controlKeys.filter((key) =>
      this.isValidControl(this.billOfLadingForm.controls[key])
    );
    console.log('Invalid control keys:', invalidControlKeys);

    const ETADate = this.billOfLadingForm.controls['eta'].value;
    const ETDDate = this.billOfLadingForm.controls['etd'].value;

    this.getFormattedDate(ETADate, 'eta');
    this.getFormattedDate(ETDDate, 'etd');

    console.log(this.billOfLadingForm.value);
    if (this.billOfLadingForm.valid) {
      console.log(this.billOfLadingForm.value);
      this.billOfLadingForm.patchValue({
        containers: this.containerArrayChips,
      });
      this.blockedDocument = true;
      this.billOfLadingService
        .postData(this.billOfLadingForm.value, containerMismatchContinueAnyway,ignorePermissionConflict)
        .subscribe(
          (resp) => {
            this.processDataOnSubmit(resp);
            this.blockedDocument = false;
          },
          (error) => {
            this.handleFormError(error);
            this.blockedDocument = false;
          },
          () => {
            console.log(`Finish onSubmit`);
            this.blockedDocument = false;
          }
        );
    } else {
      console.error(`Form is not valid`);
      this.blockedDocument = false;
    }
  }

  private save() {
    console.log(`**********`);
    this.processForm();
  }

  private edit() {
    console.log(`**** EDIT MODE SUBMIT ******`);
    this.processForm();
  }

  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }

  // nameValidation  is used while editMode=true, if name is changed check if already exist, if name is same as it was entered then ignore async validation
  // add even function (input) in html
  nameValidation() {
    console.log(`nameValidation `);
    if (this.editMode) {
      const isValueChanged =
        this.billOfLadingEditData.blNumber ==
        this.billOfLadingForm.get('blNumber')?.value;
      if (!isValueChanged) {
        console.log(`is value changed isValueChanged}`);
        this.billOfLadingForm!.get('name')!.setAsyncValidators([
          this.nameValidator.checkBLNumberValidator(),
        ]);
      } else {
        this.billOfLadingForm!.get('name')!.setAsyncValidators([]);
        this.billOfLadingForm.markAllAsTouched();
        this.billOfLadingForm.get('name')?.updateValueAndValidity();
        console.log(`is not value changed isValueChanged}`);
      }
    }
  }

  getErrorMessage() {
    let myFieldControl = this.billOfLadingForm.get('name');
    if (myFieldControl!.hasError('required')) {
      return 'This field is required.';
    }
    if (myFieldControl!.hasError('invalid')) {
      return 'This field is invalid.';
    }
    //console.log(`Error ${JSON.stringify(myFieldControl?.hasError)} `)
    return 'Unknown error.';
  }

  getFormValidationErrors() {
    Object.keys(this.billOfLadingForm.controls).forEach((key) => {
      console.log(
        `Each key is ${key} value is ${
          this.billOfLadingForm!.get(key)?.value
        } and hasError ${this.billOfLadingForm!.get(key)!.errors!}`
      );
      const controlErrors: ValidationErrors =
        this.billOfLadingForm!.get(key)!.errors!;

      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
            controlErrors[keyError]
          );
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.billOfLadingSharedDataService.removeDataFromLocalStorage();
  }

  private fetchDataAndPreselectValues(): void {
    this.existingValues();
    // Simulate fetching data from the server

    let fetchedData2: number[] = [];

    this.billOfLadingEditData?.containers?.forEach((eachContainer) => {
      fetchedData2.push(eachContainer.containerId);
    });
    /* const fetchedData = {
      selectedOptions: [1],
    }; */
    // Set the value of the selectedValues FormControl to the fetched values
    this.billOfLadingForm!.get('containers')!.setValue(fetchedData2);
  }

  private existingValues(): void {
    for (
      var i = 0;
      i < this.billOfLadingEditData?.containers?.length || 0;
      i++
    ) {
      this.containerNumbers.push({
        containerId: this.billOfLadingEditData.containers[i].containerId,
        containerNumber:
          this.billOfLadingEditData.containers[i].containerNumber,
      });
    }
  }

  containerArrayChips: ContainerNumber[] = [];
  protected onChangeMultiselect(event: any) {
    console.log(`onChangeMultiselect ${JSON.stringify(event.value)}`);
    this.containerArrayChips = this.containerNumbers.filter((item) =>
      event.value.includes(item.containerId)
    );
    console.log(`New Array is ${JSON.stringify(this.containerArrayChips)}`);
    //this.billOfLadingForm.patchValue({ 'containers': newArray });
  }

  isValidDateFormat(dateString: string): boolean {
    const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/;
    return regex.test(dateString);
  }

  onBuyerSubmit(event: Event) {
    console.log(`populate buyer from bl`);
    this.populateBuyers();
  }

  private fetchSeaPorts(
    selectPortOfLoading?: any,
    selectPortOfDestination?: any
  ) {
    this.containerService.getSeaPorts().subscribe(
      (data: any) => {
        // console.log(`searports response is ${JSON.stringify(data)}`);
        this.seaPortsSuggestion = data;
        if (selectPortOfLoading) {
          console.log(
            `Selected selectPortOfLoading ${JSON.stringify(
              selectPortOfLoading
            )}`
          );
          this.billOfLadingForm.patchValue({
            portOfLoading: selectPortOfLoading,
          });
        }
        if (selectPortOfDestination) {
          console.log(
            `Selected selectPortOfDestination ${JSON.stringify(
              selectPortOfDestination
            )}`
          );
          this.billOfLadingForm.patchValue({
            portOfDestination: selectPortOfDestination,
          });
        }
      },
      (error: any) => {}
    );
  }
  continueAnywayContainerMismatch() {
    this.processForm(true,false);
  }
  continueAnywayPermissionConflict() {
   this.showPermissionConflictsDialog = false;
    this.processForm(false,true);
  }

  private executeDeleteService(id: any): Promise<void> {
    return new Promise((resolve, reject) => {
      // Call your actual delete service here
      console.log(`id is ${id}`);
      this.blockedDocument = true;
      this.billOfLadingService.deleteBillOfLading(id).subscribe(
        () => {
          resolve();
          this.blockedDocument = false;
        },
        (error) => {
          reject(error);
          this.blockedDocument = true;
        }
      );
    });
  }

  onDelete(id: any): void {
    this.billOfLadingForm.markAsPristine();
    this.confirmDeleteServiceCommon.confirm(
      id,
      () => this.executeDeleteService(id),
      '/bill-of-ladings'
    );
  }

  onReset() {
    console.log('onReset called in buyer');
    this.resetServiceCommon.resetForm(this.billOfLadingForm);
  }

  @HostListener('window:keydown.meta.shift.s', ['$event'])
  handleSaveShortcut(event: KeyboardEvent) {
    this.onSubmit();
  }

  @HostListener('window:keydown.meta.shift.d', ['$event'])
  handleDeleteShortcut(event: KeyboardEvent) {
    if (this.editMode) {
      this.onDelete(this.billOfLadingEditData.billOfLadingId);
    }
  }
}
