import { Component, Input } from '@angular/core';
import { Summary } from '../model/summary';
import { Router } from '@angular/router';
import { Subscription, finalize, switchMap } from 'rxjs';
import { ContainerService } from '../../container/services/container.service';
import { ContainerNumbers } from '../../container/model/container-numbers';
import { Measurement } from '../model/measurement';
import { ContainerSummary } from '../../container/model/container-summary';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';
import { AppConstants } from 'src/app/commons/app-constants';
import { ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SHARED_MODULES } from '../../shared-imports';

const BATCH_DELAY = 100;

const BATCH_SIZE = 50;
@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css'],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class SummaryComponent {
  @Input() inputSummary!: Summary;
  subscription!: Subscription;
  containerNumbers: ContainerNumbers[] = [];
  containerNumber!: string;
  fieldArray: Measurement[] = [];
  public lengthSummary: Array<any> = [];
  public girthSummary: Array<any> = [];
  public overallSummary: ContainerSummary = new ContainerSummary();
  summary = new Summary();
  productName: any;
  formula: any;
  sealNumber: any;
  private isLoadingSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();

  constructor(
    private router: Router,
    private containerService: ContainerService,
    private encryptedStorageService: EncryptedStorageService,
    private cdr: ChangeDetectorRef
  ) {
    this.loadSummary();
  }

  dummyArrayHorizontalGrid = ['Helo'];

  loadSummary() {
    // Start by setting the loading state to true
    this.isLoadingSubject.next(true);

    this.encryptedStorageService
      .getEncryptedDataFromStorage(AppConstants.CONTAINER)
      .pipe(
        // Once we have the containerId, use switchMap to chain to the next Observable
        switchMap((data: any) => {
          console.log(`******* &&&&& ${data.containerId}`);
          return this.containerService.getContainerSummaryByContainerId(
            data.containerId
          );
        }),
        // Regardless of success or error, finalize will be called, ensuring the loading state is set to false
        finalize(() => this.isLoadingSubject.next(false))
      )
      .subscribe(
        (response) => {
          console.log(`%%%%%% *****  ${JSON.stringify(response)}`);
          this.totalGrossVol = response.grossVolume;
          this.totalNetVol = response.netVolume;
          this.grossCftAvg = response.grossAverage;
          this.netCftAvg = response.netAverage;
          this.logsCount = response.pieces;
          this.lengthSummary = this.transformToSummary(
            response.lengthHistogram
          );
          this.girthSummary = this.transformToSummary(response.girthHistogram);
          this.containerNumber = response.containerNumber;
          this.productName = response.product;
          this.formula = response.formula;
          this.sealNumber = response.sealNumber;
        },
        (error) => {
          console.error('Error while fetching container summary:', error);
        }
      );
  }

  loadSummary1() {
    this.isLoadingSubject.next(true);

    this.encryptedStorageService
      .getEncryptedDataFromStorage(AppConstants.CONTAINER)
      .subscribe(
        (data: any) => {
          console.log(`******* &&&&& ${data.containerId}`);

          this.containerService
            .getContainerSummaryByContainerId(data.containerId)
            .subscribe(
              (response) => {
                console.log(`%%%%%% *****  ${JSON.stringify(response)}`);

                this.totalGrossVol = response.grossVolume;
                this.totalNetVol = response.netVolume;
                this.grossCftAvg = response.grossAverage;
                this.netCftAvg = response.netAverage;
                this.logsCount = response.pieces;
                this.lengthSummary = this.transformToSummary(
                  response.lengthHistogram
                );
                this.girthSummary = this.transformToSummary(
                  response.girthHistogram
                );
                this.containerNumber = response.containerNumber;
                this.productName = response.product;
                this.formula = response.formula;
                this.sealNumber = response.sealNumber;
                //  this.setStatus();
              },
              (error) => {},
              () => {
                // this.deleteFieldValue(0);
                // this.onEnterGirth(0);
              }
            );
        },
        (error: any) => {},
        () => {
          this.cdr.detectChanges();
        }
      );
  }

  transformToSummary(histogram: any): Array<any> {
    const summary = [];
    for (const range in histogram) {
      if (histogram.hasOwnProperty(range)) {
        summary.push({ range: range, count: histogram[range] });
      }
    }
    return summary;
  }

  processMeasurementsInBatches(
    measurements: any,
    batchSize: number,
    delay: number
  ) {
    console.log(`$$$$$ {}`, JSON.stringify(measurements));
    let i = 0;

    const processBatch = () => {
      const end = Math.min(i + batchSize, measurements.length);
      for (; i < end; i++) {
        var girth = measurements[i]!.grossGirth! | 0;
        var length = measurements[i]!.grossLength! | 0;
        var netLength = measurements[i]!.netLength! | 0;
        var netGirth = measurements[i]!.netGirth! | 0;
        var netCbm = measurements[i]!.netCbm! | 0;
        var grossCbm = measurements[i]!.grossCbm! | 0;
        var netCft = measurements[i]!.netCft! | 0;
        var grossCft = measurements[i]!.grossCft! | 0;

        this.fieldArray.push({
          sr: i,
          grossLength: length,
          grossGirth: girth,
          netLength: netLength,
          netGirth: netGirth,
          netCbm: netCbm,
          grossCbm: grossCbm,
          netCft: netCft,
          grossCft: grossCft,
        });
      }

      if (i < measurements.length) {
        setTimeout(processBatch, delay);
      } else {
        //  this.deleteFieldValue(0);
      }
    };

    processBatch();
  }
  totalGrossVol: number = 0;
  totalNetVol: number = 0;
  logsCount: number = 0;
  grossCftAvg: number = 0;
  netCftAvg: number = 0;
  minLength: number = 0;
  maxLength: number = 0;
  minGirth: number = 0;
  maxGirth: number = 0;
  girthAllowance: number = 2;
  lengthAllowance: number = 0;
  cbmDecimalPoints = 3;

  setStatus() {
    (this.logsCount = 0),
      (this.totalNetVol = 0),
      (this.totalGrossVol = 0),
      (this.netCftAvg = 0),
      (this.grossCftAvg = 0);
    this.totalGrossVol = 0;
    for (var i = 0; i < this.fieldArray.length; i++) {
      console.log(`Set Status ${i}`);
      const length = this.fieldArray[i].grossLength;
      const grossGirth = this.fieldArray[i].grossGirth;

      if (grossGirth !== undefined && length !== undefined) {
        const netGirth = grossGirth - this.girthAllowance;

        this.fieldArray[i].netCbm = this.calculateCBM(length, netGirth);
        this.fieldArray[i].netGirth = netGirth;
        this.fieldArray[i].grossCbm = this.calculateCBM(length, grossGirth);
        this.fieldArray[i].girthAllowance = this.girthAllowance;
        this.fieldArray[i].lengthAllowance = this.lengthAllowance;
        this.fieldArray[i].netLength = length - this.lengthAllowance;
        this.fieldArray[i].netCft = this.fieldArray[i]?.netCbm ?? 0 * 35.315;
        this.fieldArray[i].grossCft =
          this.fieldArray[i]?.grossCbm ?? 0 * 35.315;

        this.totalGrossVol += Number(this.fieldArray[i].grossCbm);
        this.totalNetVol += Number(this.fieldArray[i].netCbm);
        this.logsCount++;
      }

      console.warn(`grossGirth && length ${grossGirth}  -- ${length}`);
      this.totalGrossVol = Number(this.totalGrossVol.toFixed(3));
    }

    this.grossCftAvg = (this.totalGrossVol * 35.315) / this.logsCount;
    this.netCftAvg = (this.totalNetVol * 35.315) / this.logsCount;
    this.getLengthSummary(this.fieldArray);
    this.getGirthSummary(this.fieldArray);
    this.setOverAllSummary(
      this.logsCount,
      this.totalNetVol,
      this.totalGrossVol,
      this.netCftAvg,
      this.grossCftAvg
    );
    console.log(`logs Count ${this.logsCount}`);
    this.summary.totalGrossVol = this.totalGrossVol;
    this.summary.totalNetVol = this.totalNetVol;
    this.summary.netCftAvg = this.netCftAvg;
    this.summary.grossCftAvg = this.grossCftAvg;
    this.summary.lengthSummary = this.lengthSummary;
    this.summary.girthSummary = this.girthSummary;
    this.summary.logsCount = this.logsCount;
    console.log(`overall summary ${JSON.stringify(this.overallSummary)}`);
  }

  calculateCBM(length: number, girth: number): any {
    const cbm = (length * girth * girth) / 16000000;
    return cbm.toFixed(this.cbmDecimalPoints);
  }

  getLengthSummary(arr: any) {
    // console.log(`getLengthSummary  ${JSON.stringify(arr)}`);
    console.log(`getLengthSummary  ${JSON.stringify(this.fieldArray.length)}`);
    this.lengthSummary = [];
    this.girthSummary = [];
    var count = 0;
    this.minLength = Math.min.apply(
      Math,
      this.fieldArray
        .filter((o) => o.grossLength !== undefined)
        .map((o) => o.grossLength!)
    );
    this.maxLength = Math.max.apply(
      Math,
      this.fieldArray
        .filter((o) => o.grossLength !== undefined)
        .map((o) => o.grossLength!)
    );

    console.log(`Minimum length is ${this.minLength}`);
    console.log(`Maximum length is ${this.maxLength}`);
    // console.log(`&&`)
    for (var i = this.minLength; i <= this.maxLength; i += 5) {
      console.log(`******&&&&&&*******`);
      count = 0;
      for (var j = 0; j < this.fieldArray.length; j++) {
        if (i == this.fieldArray[j].grossLength) {
          count = count + 1;
          // console.log(`Length steps are ${i} ${this.fieldArray[j].girth} * ${this.fieldArray[j].length} ${count}`);
        }
        // console.log(`Length steps are ${i} ${this.fieldArray[j].girth} * ${this.fieldArray[j].length} ${count}`);
      }

      this.lengthSummary.push({ length: i, count: count });
      // console.log(`Length steps are ${i} ${count}`);
      // console.log(`Length steps are ${i}`);
    }
    console.log(JSON.stringify(this.lengthSummary));
  }

  getGirthSummary(arr: any) {
    var count = 0;
    this.minGirth = Math.min.apply(
      Math,
      this.fieldArray
        .filter((o) => o.grossGirth !== undefined)
        .map((o) => o.grossGirth!)
    );
    this.maxGirth = this.roundUpGirth(
      Math.max.apply(
        Math,
        this.fieldArray
          .filter((o) => o.grossGirth !== undefined)
          .map((o) => o.grossGirth!)
      )
    );

    let minGirthRange = Math.floor(this.minGirth / 5) * 5;

    for (var i = minGirthRange; i < this.maxGirth; i += 10) {
      count = 0;
      for (var j = 0; j < this.fieldArray.length; j++) {
        if (
          this.fieldArray[j].grossGirth! >= i &&
          this.fieldArray[j].grossGirth! < i + 10
        ) {
          count = count + 1;
        }
      }
      this.girthSummary.push({ girth: `${i}-${i + 9}`, count: count });
    }

    console.log(JSON.stringify(this.girthSummary));
  }

  private setOverAllSummary(
    totalPcs: number,
    netVol: number,
    grossVol: number,
    netCftAvg: number,
    grossCftAvg: number
  ) {
    this.overallSummary.pieces = totalPcs;
    this.overallSummary.netVolume = netVol;
    this.overallSummary.grossVolume = grossVol;
    this.overallSummary.netAverage = netCftAvg;
    this.overallSummary.grossAverage = grossCftAvg;
    this.overallSummary.pieces = totalPcs;
  }

  private roundUpGirth(girth: number): number {
    const num = 102;
    const mod = num % 100;
    const remainder = 10 - mod;
    let finalNumber = num + remainder;
    // console.log(`%%%%%%%% ${finalNumber}`);
    return finalNumber;
  }
}
