import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { SawnTimberService } from '../../../services/sawn-timber.service';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';
import { AppConstants } from 'src/app/commons/app-constants';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  debounceTime,
  filter,
  fromEvent,
  switchMap,
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Container } from 'src/app/modules/container/model/container';
import { ContainerService } from 'src/app/modules/container/services/container.service';
import { Measurement } from 'src/app/modules/measurement/model/measurement';
import { UtilService } from 'src/app/services/util.service';
import { SHARED_MODULES } from 'src/app/modules/shared-imports';

@Component({
  selector: 'app-sawn-timber-view-list',
  templateUrl: './sawn-timber-view-list.component.html',
  styleUrls: ['./sawn-timber-view-list.component.css'],
  standalone:true, 
  imports:[SHARED_MODULES]
})
export class SawnTimberViewListComponent implements OnInit, OnDestroy {
  @Input() data: any;
  isLoading = false; // <-- declare your isLoading variable here
  isLoading1 = new BehaviorSubject<boolean>(false);
  tableStyleOptions: any;
  // define loadData property which can hold a function reference
  loadData!: () => void;
  showTopButton: boolean = true;
  showBottomButton: boolean = true;
  @ViewChild('scrollable') scrollable!: ElementRef;

  dummyArrayHorizontalGrid = ['Helo'];
  container!: Container;
  measurements: Measurement[] = [];
  //data: any[] = [];

  totalRecords: number = 0; // total number of records in the dataset
  limit: number = 50; // number of records to fetch per request
  offset: number = 0; // current offset in the dataset
  scrollSubscription: Subscription | undefined; // subscription to scroll event
  @Input() containerId!: any;
  containerUUID: any;
  tableStyle!: any;
  isMobile!: boolean;
  cols!: { field: string; header: string; unit?: string }[];
  isPublic = false;
  lengthUnit: any;
  widthUnit: any;
  thicknessUnit: any;

  constructor(
    private httpClient: HttpClient,
    private containerService: ContainerService,
    private router: Router,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private encryptedStorageService: EncryptedStorageService,
    private sawnTimberService: SawnTimberService
  ) {
    console.log(`SawnTimberViewListComponent `);
    this.isMobile = this.utilService.isMobile();
  }

  initColumns() {
    this.cols = [
      { field: 'width', header: 'Width', unit: this.widthUnit || '' },
      { field: 'height', header: 'Thickness', unit: this.thicknessUnit || ''  },
      { field: 'length', header: 'Length', unit: this.lengthUnit || '' },
      { field: 'pieces', header: 'Pieces' },
      { field: 'cbm', header: 'Cbm' },
      { field: 'cft', header: 'Cft' },
    ];
  }

  ngOnInit(): void {
    this.isLoading = true; // <-- set loading to true before making requests

    this.initColumns();
    this.isPublic = this.router.url.includes('/public/view');
    if (this.isPublic) {
      this.containerUUID = this.route.snapshot.paramMap.get('containerUUID');
      this.loadData = this.loadPublicData.bind(this);
      this.loadData();
      this.subscribeToScrollEvent(this.loadData);
    } else {
      this.encryptedStorageService
        .getEncryptedDataFromStorage(AppConstants.VIEW)
        .subscribe(
          (response: any) => {
            this.containerId = response.containerId;
            this.loadData = this.loadPrivateData.bind(this, this.containerId);
            this.loadData();
            this.subscribeToScrollEvent(this.loadData);
          },
          (error: any) => {
            this.isLoading = false; // <-- set loading to false if there's an error
          },
          () => {
            this.isLoading = false; // <-- set loading to false if there's an error
          }
        );
    }
    this.tableStyle = this.utilService.getTableSize();
    this.isLoading = false; // <-- set loading to false once the entire init process is completed
  }

  subscribeToScrollEvent(callback: () => void) {
    this.scrollSubscription = fromEvent(window, 'scroll')
      .pipe(
        debounceTime(300),
        filter(
          () =>
            !this.isLoading &&
            this.offset < this.totalRecords &&
            this.isScrolledToBottom()
        )
      )
      .subscribe(() => {
        this.offset += this.limit;
        callback();
      });
  }

  loadInitialData() {
    this.isLoading = true; // <-- set loading to true before making requests
    if (this.loadData) {
      this.loadData();
    }
    this.isLoading = false; // <-- set loading to false once data is loaded
  }

  createScrollSubscription() {
    this.scrollSubscription = fromEvent(window, 'scroll')
      .pipe(
        debounceTime(300),
        filter(
          () =>
            !this.isLoading &&
            this.offset < this.totalRecords &&
            this.isScrolledToBottom()
        )
      )
      .subscribe(() => {
        this.offset += this.limit;
        if (this.loadData) {
          this.loadData();
        }
      });
  }

  loadPrivateData(containerId: number) {
    this.loadDataFromService(
      this.sawnTimberService.getLazyLoadingVirtualPackinglist(
        containerId,
        this.offset,
        this.limit
      )
    );
    this.loadContainerSummary('private',this.containerId);
  }

  loadPublicData() {
    this.loadDataFromService(
      this.sawnTimberService.getPublicLazyLoadingVirtualPackinglist(
        this.containerUUID,
        this.offset,
        this.limit
      )
    );
    this.loadContainerSummary('public',this.containerUUID);
  }

  loadDataFromService(serviceCall$: Observable<any>) {
    this.isLoading = true; // <-- set loading to true before making requests
    this.isLoading1.next(true); // use next to change the value

    console.log(`isLoading to True; value is ${this.isLoading}`);
    setTimeout(() => {
      serviceCall$.subscribe(
        (response) => {
          this.measurements = this.measurements.concat(response.data);
          this.totalRecords = response.totalRecords;
          this.isLoading = false;
          this.isLoading1.next(false); // use next to change the value

          console.log(`isLoading to False; value is ${this.isLoading}`);
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.isLoading1.next(false); // use next to change the value
        }
      );
    }, 200);
  }

  loadContainerSummary(mode:string, continerIdOrUUID: string) {
    this.sawnTimberService.getSummarySawnTimber(continerIdOrUUID,mode).subscribe(
      (resp: any) => {
        console.log(`loadContainerSummary resp ${JSON.stringify(resp)}`);
        if (resp) {
          this.lengthUnit = resp.lengthUnit;
          this.widthUnit = resp.widthUnit;
          this.thicknessUnit = resp.heightUnit;
          this.initColumns();
        }
      },
      (error: any) => {
        console.error(`loadContainerSummary error ${JSON.stringify(error)}`);
      },
      () => {}
    );
  } ///sawn-timber-rows/units/{container-id}

  dummyRows = Array(10).fill(0);

  isScrolledToBottom(): boolean {
    const windowHeight =
      'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const documentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    return windowBottom >= documentHeight;
  }

  ngOnDestroy(): void {}
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = this.utilService.isMobile();
    console.log(`is mobile ${this.utilService.isMobile()}`);
    this.tableStyle = this.utilService.getTableSize();
  }

  scrollToTop() {
    // this.renderer.setProperty(this.scrollable.nativeElement, 'scrollTop', 0);
    this.smoothScroll(this.scrollable.nativeElement, 0, 600);
  }

  scrollToBottom() {
    const target =
      this.scrollable.nativeElement.scrollHeight -
      this.scrollable.nativeElement.offsetHeight;
    this.smoothScroll(this.scrollable.nativeElement, target, 600);

    /*  const scrollHeight = this.scrollable.nativeElement.scrollHeight;
    const offsetHeight = this.scrollable.nativeElement.offsetHeight;
    this.renderer.setProperty(
      this.scrollable.nativeElement,
      'scrollTop',
      scrollHeight - offsetHeight
    ); */
  }

  smoothScroll(element: any, target: number, duration: number) {
    const start = element.scrollTop;
    const change = target - start;
    let startTime: number | null = null;

    const animateScroll = (currentTime: number) => {
      if (!startTime) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1);

      const easeInOutQuad = (t: number) =>
        t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      const val = easeInOutQuad(progress);

      element.scrollTop = start + change * val;

      if (timeElapsed < duration) {
        window.requestAnimationFrame(animateScroll);
      }
    };

    window.requestAnimationFrame(animateScroll);
  }
}

/* loadPrivateData(containerId: number) {
    this.isLoading = true;  // <-- set loading to true before making requests

    this.loadDataFromService(
      this.sawnTimberService.getLazyLoadingVirtualPackinglist(
        containerId,
        this.offset,
        this.limit
      )
    );
    this.isLoading = false;  // <-- set loading to false once data is loaded

  } */
/*  **** OLD WORKING CODE WITH SAME FUNCTIONALITIES AS ABOVE.
    **** DIFFERENCE IS ABOVE IS REFACTORED AND BELOW IS NOT
    ngOnInit(): void {
    this.initColumns();

    this.isPublic = this.router.url.includes('/public/view');
    if (this.isPublic) {
      this.containerUUID = this.route.snapshot.paramMap.get('containerUUID');
      console.log(`this.containerUUID is ${this.containerUUID}`);

      this.loadPublicData();
      this.scrollSubscription = fromEvent(window, 'scroll')
        .pipe(
          debounceTime(300),
          filter(
            () =>
              !this.loading &&
              this.offset < this.totalRecords &&
              this.isScrolledToBottom()
          )
        )
        .subscribe(() => {
          console.log(`@@@@@@@@@@`);
          this.offset += this.limit;
          this.loadPublicData();
        });
      this.tableStyle = this.utilService.getTableSize();
    } else {
      this.encryptedStorageService
        .getEncryptedDataFromStorage(AppConstants.VIEW)
        .subscribe(
          (response: any) => {
            console.log(
              `response from encrypted storage is ${JSON.stringify(response)}`
            );
            this.containerId = response.containerId;
            console.log(
              `Container ID from storage ${JSON.stringify(
                response.containerId
              )}`
            );
            this.loadPrivateData(response.containerId);
            this.scrollSubscription = fromEvent(window, 'scroll')
              .pipe(
                debounceTime(300),
                filter(
                  () =>
                    !this.loading &&
                    this.offset < this.totalRecords &&
                    this.isScrolledToBottom()
                )
              )
              .subscribe(() => {
                console.log(`@@@@@@@@@@`);
                this.offset += this.limit;
                this.loadPrivateData(response.containerId);
              });
            this.tableStyle = this.utilService.getTableSize();
          },
          (error: any) => {},
          () => {}
        );
    }
  }

  loadPrivateData(containerId: number) {
    console.log(`loadPrivateData  1`);
    this.loading = true;
    console.log(`loadPrivateData  2`);
    // fetch data from API
    this// .fetchData(containerId, this.offset, this.limit)
    .sawnTimberService
      .getLazyLoadingVirtualPackinglist(containerId, this.offset, this.limit)
      .subscribe(
        (response) => {
          console.log(
            `Response is of measurements  ${JSON.stringify(response)}`
          );
          this.measurements = this.measurements.concat(response.data);
          this.totalRecords = response.totalRecords;
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
  }

  loadPublicData() {
    this.loading = true;
    // Simulate loading delay
    setTimeout(() => {
      // fetch data from API
      this.sawnTimberService
        .getPublicLazyLoadingVirtualPackinglist(
          this.containerUUID,
          this.offset,
          this.limit
        )
        .subscribe(
          (response) => {
            this.measurements = this.measurements.concat(response.data);
            console.log(`*** *** *** *** ${JSON.stringify(response)}`);
            this.totalRecords = response.totalRecords;
            this.loading = false;
          },
          (error) => {
            console.log(error);
            this.loading = false;
          }
        );
    }, 2000);
  }

  isScrolledToBottom(): boolean {
    const windowHeight =
      'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const documentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    return windowBottom >= documentHeight;
  }

  onScroll(event: any) {
    if (this.loading) {
      return;
    }
    if (this.offset >= this.totalRecords) {
      return;
    }
    this.offset += this.limit;
    if (!this.isPublic) {
      this.loadPrivateData(this.containerId);
    } else {
      this.loadPublicData();
    }
  }

  ngOnDestroy(): void {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = this.utilService.isMobile();
    console.log(`is mobile ${this.utilService.isMobile()}`);
    this.tableStyle = this.utilService.getTableSize();
  }


*/
