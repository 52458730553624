import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SlabGeneratorService {
  constructor() {}

  /* generateSlabs(rate: number, slabCft: number, slabPriceSize: number, slabSize: number, upperSlabs: number, lowerSlabs: number) {
    let slabs = [];
    let currentRate = rate - (lowerSlabs * slabPriceSize);

    for(let i = slabCft - (lowerSlabs * slabSize); i <= slabCft + (upperSlabs * slabSize); i += slabSize) {
      slabs.push({ min: i, max: i + slabSize, rate: currentRate });
      currentRate += slabPriceSize;
    }

    return slabs;
} */

  generateSlabs(
    rate: number,
    slabCft: number,
    slabPriceSize: number,
    slabSize: number,
    upperSlabs: number,
    lowerSlabs: number
  ) {
    let slabs = [];
    let currentRate = rate - lowerSlabs * slabPriceSize;

    for (
      let i = slabCft - lowerSlabs * slabSize;
      i <= slabCft + upperSlabs * slabSize;
      i += slabSize
    ) {
      slabs.push({ min: i, max: i + slabSize - 0.01, rate: currentRate });
      currentRate += slabPriceSize;
    }

    return slabs;
  }

  getBaseRateRange(slabCft: number, slabSize: number) {
    // Find the quotient
    let quotient = Math.floor(slabCft / slabSize);
  
    // Calculate lower and upper limit of the range
    let lowerLimit = quotient * slabSize;
    let upperLimit = (quotient + 1) * slabSize - 0.01;
  
    return { min: lowerLimit, max: upperLimit };
  }
  
}
