<!-- <router-outlet></router-outlet>
<ng-container *ngIf="router.url !== '/login';else loginPage">
    <div class="container">
      <div class="header">
        <app-header></app-header>
      </div>
      <app-side-bar></app-side-bar>
      <div class="content" #sidebarspace>
        <router-outlet></router-outlet>
      </div>
      <div class="footer">
        <app-footer></app-footer>
      </div>
    </div>
  </ng-container>
  <ng-template #loginPage>
    <router-outlet></router-outlet>
  </ng-template>  -->

<!-- <router-outlet></router-outlet> -->
<!-- <div class="container">
  <div class="header">
    <app-header *ngIf="showHeader"></app-header>
  </div>
  <app-side-bar *ngIf="showMenu"></app-side-bar>
  <div class="content" #sidebarspace>
    <div [ngStyle]="{'font-size.px': currentFontSize}">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="footer">
    <app-footer *ngIf="showFooter"></app-footer>
  </div>
</div> -->
<!-- 
<div class="news-div">
  <p-tag value="New"></p-tag>
</div>
 -->

<!-- <div class="news-div flex align-items-center justify-content-center">
  <p style="color: white;margin: 0;" class="p-0;m-0">BETA</p>
</div> -->

<!-- *ngIf="isOffline" -->
<!-- <p-toast [breakpoints]="{'920px': {width: '50%'},'768px': {width: '50%'},'480px': {width: '50%'}}" -->
<p-toast [preventOpenDuplicates]="true" [showTransformOptions]="'translateY(100%)'" [showTransitionOptions]="'700ms'"
  [hideTransitionOptions]="'1000ms'" [showTransformOptions]="'translateX(100%)'" position="top-center"></p-toast>
<div *ngIf="isOffline" class="fixed pr-0 pl-0 pt-0 mb-2 m-0 text-center bg-cyan-600 text-white w-full">
  <p class="m-2">You are offline. Some features may not be available.</p>
</div>
<div class="container" [ngClass]="{'pt-4': isOffline}">

  <header class="header">
    <p-toast key="billingDueToast"
      [breakpoints]="{'920px': {width: '50%'},'768px': {width: '50%'},'480px': {width: '50%'}}"
      [preventOpenDuplicates]="true" [showTransformOptions]="'translateY(100%)'" [showTransitionOptions]="'700ms'"
      [hideTransitionOptions]="'1000ms'" [showTransformOptions]="'translateX(100%)'" position="top-center"></p-toast>

    <p-toast key="expiredToast"
      [breakpoints]="{'920px': {width: '50%'},'768px': {width: '50%'},'480px': {width: '50%'}}"
      [preventOpenDuplicates]="true" [showTransformOptions]="'translateY(100%)'" [showTransitionOptions]="'700ms'"
      [hideTransitionOptions]="'1000ms'" [showTransformOptions]="'translateX(100%)'" position="top-center"></p-toast>

    <p-toast key="overDueToast"
      [breakpoints]="{'920px': {width: '50%'},'768px': {width: '50%'},'480px': {width: '50%'}}"
      [preventOpenDuplicates]="true" [showTransformOptions]="'translateY(100%)'" [showTransitionOptions]="'700ms'"
      [hideTransitionOptions]="'1000ms'" [showTransformOptions]="'translateX(100%)'" position="top-center"></p-toast>

    <app-header [showHeader]="(showHeader$ | async) ?? false" [visibilityCondition]="showMenu"
      [showLogo]="(showLogo$ | async) ?? false" [showFontSize]="showFontSize" [showMenuButton]="showMenuButton"
      [showDayNightScheme]="showDayNightScheme" [showUserProfileButton]="showUserProfileButton"></app-header>
  </header>

  <!-- <div class="main">
    <aside class="sidebar">
      <app-side-bar *ngIf="showMenu"></app-side-bar>
    </aside>

    <main class="main-content">
      <div>
        <div [@routeAnimations]="prepareRoute(outlet)">
          <p-button *ngIf="(showHeader$ | async) ?? false" icon="pi pi-cog" styleClass="p-button-sm"
            class="layout-config-button p-link" (click)="op.toggle($event)"></p-button>
          <router-outlet #outlet="outlet"></router-outlet>
        </div>

        <div class="spinner-container" *ngIf="loading">
          <p-progressSpinner></p-progressSpinner>
        </div>
      </div>
    </main>
  </div> -->

  <div class="flex flex-row flex-1">
    <aside class="flex-none">
      <app-side-bar *ngIf="showMenu"></app-side-bar>
    </aside>

    <main class="flex-grow-1 flex flex-column p-0 pb-7">
      <div>
        <div [@routeAnimations]="prepareRoute(outlet)">
          <p-button *ngIf="(showHeader$ | async) ?? false" icon="pi pi-cog" styleClass="p-button-sm"
            class="layout-config-button p-link" (click)="op.toggle($event)"></p-button>
          <router-outlet #outlet="outlet"></router-outlet>
        </div>

        <div class="flex justify-center align-items-center" *ngIf="loading">
          <p-progressSpinner></p-progressSpinner>
        </div>
      </div>
    </main>
  </div>


  <!-- <footer class="border-3 border-primary-500"> -->
  <app-footer *ngIf="showFooter"></app-footer>
  <!-- </footer> -->
</div>

<p-confirmDialog key="deleteConfirmDialogCommon"></p-confirmDialog>


<p-dialog key="errorDialog" header="Something went wrong" [(visible)]="showErrorDialog" [modal]="true"
  [style]="{ 'width': '70vw','min-width': '280px','max-width': '600px' }" [draggable]="false" [resizable]="false">
  <p class="m-0">
    {{ errorMessage }}
  </p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="showErrorDialog  = false" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<p-overlayPanel #op>

  <div class="field" [style]="{'width': '40vw'}">
    <label for="sliderValue">Font Size</label>
    <!-- <input id="firstname1" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"> -->
    <div class="p-inputgroup">
      <span class="text-xs p-2">A</span>
      <p-slider [(ngModel)]="sliderValue" [step]="1" [min]="0" [max]="fontSizes.length - 1"
        (onChange)="onSliderChange($event)" class="text-base text-color surface-overlay p-2 w-full"></p-slider>
      <span class="text-2xl p-2">A</span>
    </div>
  </div>
  <div class="field">
    <label for="isDarkThemeEnabled">Day/Night Mode</label>
    <!-- <input id="lastname1" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"> -->
    <p-toggleButton [(ngModel)]="isDarkThemeEnabled" offIcon="pi pi-moon" onIcon="pi pi-sun" (onChange)="updateTheme()"
      *ngIf="showDayNightScheme" class="themebutton text-base text-color surface-overlay p-2 w-full">
    </p-toggleButton>
  </div>
  <!-- <p-slider [style]="{'width': '40vw'}" [(ngModel)]="sliderValue" [step]="1" [min]="0" [max]="fontSizes.length - 1"
    (onChange)="onSliderChange($event)"></p-slider>
  <p-toggleButton [(ngModel)]="isDarkThemeEnabled" offIcon="pi pi-moon" onIcon="pi pi-sun" (onChange)="updateTheme()" 
    class="themebutton" *ngIf="showDayNightScheme">
  </p-toggleButton> -->
</p-overlayPanel>

<!-- <p-sidebar [(visible)]="visible" position="right" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
  styleClass="layout-config-sidebar w-20rem">
  <h5>Scale</h5>

  <p-slider [(ngModel)]="sliderValue" (onChange)="onSliderChange($event)"></p-slider>


  <p-toggleButton [(ngModel)]="isDarkThemeEnabled" offIcon="pi pi-moon" onIcon="pi pi-sun" (onChange)="updateTheme()"
    class="themebutton" *ngIf="showDayNightScheme">
  </p-toggleButton>
</p-sidebar> -->