<form [formGroup]="billingDetailsForm" (ngSubmit)="onSubmit()">
    <div class="card">
        <div class="formgrid grid">

            <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                <label for="name">Company Name <i class="text-red-400">*</i></label>
                <input id="name" type="text" pInputText formControlName="companyName"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="billingDetailsForm.invalid && (billingDetailsForm.controls?.['companyName']?.invalid && (billingDetailsForm.controls?.['companyName']?.dirty || billingDetailsForm.controls?.['companyName']?.touched))">
                    <div *ngIf="billingDetailsForm.controls?.['companyName']?.errors?.['required']">
                        Company Name is required.
                    </div>
                    <div *ngIf="billingDetailsForm.controls?.['companyName']?.errors?.['minlength']">
                        Minimum length should be 3.
                    </div>
                    <div *ngIf="billingDetailsForm.controls?.['companyName']?.errors?.['maxlength']">
                        Maximum length should be 64.
                    </div>
                    <div *ngIf="billingDetailsForm.controls?.['companyName']?.errors?.['pattern']">
                        Company Name must start with a character.
                    </div>
                </div>

            </div>
            <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                <label for="phone">Phone<i class="text-red-400">*</i> </label>
                <input id="phone" type="tel" pKeyFilter="num" pInputText formControlName="phone"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400" name
                    *ngIf="billingDetailsForm.invalid && (billingDetailsForm.controls?.['phone']?.invalid && (billingDetailsForm.controls?.['phone']?.dirty || billingDetailsForm.controls?.['phone']?.touched))">
                    <div *ngIf="billingDetailsForm.controls['phone'].errors?.['required']">
                        Phone number is required.
                    </div>
                    <div *ngIf="billingDetailsForm.controls['phone'].errors?.['minlength']">
                        Phone number must be at least 8 digits.
                    </div>
                    <div *ngIf="billingDetailsForm.controls['phone'].errors?.['pattern']">
                        Phone number can only contain digits.
                    </div>
                </div>

            </div>

            <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                <label for="address">Address <i class="text-red-400">*</i></label>
                <input id="address" type="text" formControlName="address" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400" name
                    *ngIf="billingDetailsForm.invalid && (billingDetailsForm.controls?.['address']?.invalid && (billingDetailsForm.controls?.['address']?.dirty || billingDetailsForm.controls?.['address']?.touched))">
                    <div *ngIf="billingDetailsForm.controls?.['address']?.errors?.['required']"> Address is
                        required.</div>
                </div>
            </div>
            <div class="field col-12 md:col-6" *ngIf="selectedCurrency!=='INR'; else countryInput2">
                <label for="country">Country <i class="text-red-400">*</i></label>
                <p-dropdown [options]="countries" formControlName="country" optionLabel="name" [filter]="true"
                    [lazy]="true" styleClass="field col-12 w-full" [virtualScroll]="true" [virtualScrollItemSize]="38"
                    [lazy]="true" placeholder="Select Country" [showClear]="true"></p-dropdown>
                <div class="text-red-400"
                    *ngIf="billingDetailsForm.controls?.['country']?.invalid && (billingDetailsForm.controls?.['country']?.dirty || billingDetailsForm.controls?.['country']?.touched)">
                    <div
                        *ngIf="billingDetailsForm.invalid &&  (billingDetailsForm.controls?.['country']?.errors?.['required'])">
                        Country is
                        required.</div>
                </div>
            </div>
            <ng-template #countryInput2>
                <div class="field col-12 md:col-6">
                    <label for="country">Country <i class="text-red-400">*</i></label>
                    <p-dropdown [options]="[{ id: 77, name: 'India', code: 'IN' }]" formControlName="country"
                        optionLabel="name" [filter]="true" [lazy]="true" styleClass="field col-12 w-full"
                        [virtualScroll]="true" [virtualScrollItemSize]="38" [lazy]="true" placeholder="Select Country"
                        [showClear]="true"></p-dropdown>
                    <div class="text-red-400"
                        *ngIf="billingDetailsForm.controls?.['country']?.invalid && (billingDetailsForm.controls?.['country']?.dirty || billingDetailsForm.controls?.['country']?.touched)">
                        <div
                            *ngIf="billingDetailsForm.invalid &&  (billingDetailsForm.controls?.['country']?.errors?.['required'])">
                            Country is
                            required.</div>
                    </div>
                </div>
            </ng-template>
            <div class="field col-12 md:col-6" *ngIf="selectedCurrency==='INR'; else cityInput">
                <label for="city">City <i class="text-red-400">*</i></label>
                <p-dropdown [options]="cities" formControlName="city" optionLabel="name" [filter]="true" [lazy]="true"
                    styleClass="field col-12 w-full" [virtualScroll]="true" [virtualScrollItemSize]="38" [lazy]="true"
                    (onChange)="onCityChange($event)" placeholder="Select City" [showClear]="true"></p-dropdown>
                <div class="text-red-400"
                    *ngIf="billingDetailsForm.controls?.['city']?.invalid && (billingDetailsForm.controls?.['city']?.dirty || billingDetailsForm.controls?.['city']?.touched)">
                    <div
                        *ngIf="billingDetailsForm.invalid &&  (billingDetailsForm.controls?.['city']?.errors?.['required'])">
                        City is required.
                    </div>
                </div>
            </div>

            <ng-template #cityInput>
                <div class="field col-12 md:col-6">
                    <label for="city">City <i class="text-red-400">*</i></label>
                    <input pInputText type="text" formControlName="city" placeholder="Enter City"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    <div class="text-red-400"
                        *ngIf="billingDetailsForm.controls?.['city']?.invalid && (billingDetailsForm.controls?.['city']?.dirty || billingDetailsForm.controls?.['city']?.touched)">
                        <div
                            *ngIf="billingDetailsForm.invalid &&  (billingDetailsForm.controls?.['city']?.errors?.['required'])">
                            City is required.
                        </div>
                    </div>
                </div>
            </ng-template>

            <div class="field col-12 md:col-3">
                <label for="state" *ngIf="selectedCurrency==='INR'; else otherCurrency3">State</label>
                <ng-template #otherCurrency3>
                    <label for="state">State
                        <i class="text-red-400">*</i>
                    </label>
                </ng-template>
                <input id="state" type="text" pInputText formControlName="state" [disabled]="selectedCurrency==='INR'"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400" name
                    *ngIf="billingDetailsForm.invalid && (billingDetailsForm.controls?.['state']?.invalid && (billingDetailsForm.controls?.['state']?.dirty || billingDetailsForm.controls?.['state']?.touched))">
                    <div> Enter valid value</div>
                    <div *ngIf="billingDetailsForm.controls?.['state']?.errors?.['required']"> State
                        is required. </div>
                </div>
            </div>
            <div class="field col-12 md:col-3">
                <label for="pincode" *ngIf="selectedCurrency==='INR' ; else otherCurrency">Pincode <i
                        class="text-red-400">*</i></label>
                <ng-template #otherCurrency>
                    <label for="pincode">Zipcode <i class="text-red-400">*</i></label>
                </ng-template>
                <input id="pincode" type="tel" pKeyFilter="num" formControlName="pincode" pInputText maxlength="9"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400"
                    *ngIf="billingDetailsForm.invalid && (billingDetailsForm.controls?.['pincode']?.invalid && (billingDetailsForm.controls?.['pincode']?.dirty || billingDetailsForm.controls?.['pincode']?.touched))">
                    <div *ngIf="selectedCurrency==='INR' ; else otherCurrency1">
                        <div *ngIf="billingDetailsForm.controls?.['pincode']?.errors?.['required']">Pincode is
                            required.</div>
                        <div *ngIf="billingDetailsForm.controls?.['pincode']?.errors?.['minlength']">
                            Minimum length should be 3.
                        </div>
                        <div *ngIf="billingDetailsForm.controls?.['companyName']?.errors?.['maxlength']">
                            Maximum length should be 64.
                        </div>

                        <div *ngIf="billingDetailsForm.controls?.['pincode']?.errors?.['pattern']">Invalid
                            pincode</div>
                    </div>
                    <ng-template #otherCurrency1>
                        <div *ngIf="billingDetailsForm.controls?.['pincode']?.errors?.['required']">Zipcode is
                            required.</div>

                        <div *ngIf="billingDetailsForm.controls?.['pincode']?.errors?.['pattern']">Invalid
                            zipcode</div>
                        <div *ngIf="billingDetailsForm.controls?.['pincode']?.errors?.['minlength']">
                            Minimum length should be 3.
                        </div>
                        <div *ngIf="billingDetailsForm.controls?.['pincode']?.errors?.['maxlength']">
                            Maximum length should be 10.
                        </div>
                    </ng-template>
                </div>
            </div>

            <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6" *ngIf="selectedCurrency==='INR'">
                <label for="name">Do you possess a valid GSTIN? <i class="text-red-400">*</i></label>
                <div class="flex flex-wrap gap-3">
                    <div class="flex align-items-center" *ngFor="let options of hasGSTINOptions">
                        <p-radioButton [inputId]="options.key" [value]="options" formControlName="hasGSTIN"
                            (onClick)="onGstinClick($event)"></p-radioButton>
                        <label [for]="options.key" class="ml-2">{{ options.name }}</label>
                    </div>
                    <div class="text-red-400" name
                        *ngIf="billingDetailsForm.invalid && (billingDetailsForm.controls?.['hasGSTIN']?.invalid && (billingDetailsForm.controls?.['hasGSTIN']?.dirty || billingDetailsForm.controls?.['hasGSTIN']?.touched))">
                        <div *ngIf="billingDetailsForm.controls?.['hasGSTIN']?.errors?.['required']"> GSTIN is required.
                        </div>
                    </div>
                </div>
            </div>
            <!-- {{billingDetailsForm.get('hasGSTIN')?.value?.key?? false}} -->
            <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6"
                *ngIf="selectedCurrency==='INR' && billingDetailsForm.get('hasGSTIN')?.value?.key?? false">
                <label for="gstin">GSTIN <i class="text-red-400">*</i></label>
                <input id="gstin" type="text" pInputText formControlName="gstin"
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <div class="text-red-400" name
                    *ngIf="billingDetailsForm.invalid && (billingDetailsForm.controls?.['gstin']?.invalid && (billingDetailsForm.controls?.['gstin']?.dirty || billingDetailsForm.controls?.['gstin']?.touched))">
                    <div *ngIf="billingDetailsForm.controls?.['gstin']?.errors?.['required']"> GSTIN is
                        required. </div>
                </div>
            </div>

            <div class="field col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">

                <div class="flex flex-wrap gap-3">
                    <div class="flex align-items-center">
                        <p-checkbox formControlName="hasAgreeToTerms" [binary]="true"
                            inputId="hasAgreeToTerms"></p-checkbox>
                        <label for="hasAgreeToTerms">I hereby agree to all
                            <a href="https://www.rikexim.com?terms" target="_blank">Terms and Conditions</a> and
                            <a href="https://www.rikexim.com?privacy" target="_blank">Privacy Policy</a>
                        </label>

                    </div>
                    <div class="text-red-400" name
                        *ngIf="billingDetailsForm.invalid && (billingDetailsForm.controls?.['hasAgreeToTerms']?.invalid && (billingDetailsForm.controls?.['hasAgreeToTerms']?.dirty || billingDetailsForm.controls?.['hasAgreeToTerms']?.touched))">
                        <div *ngIf="billingDetailsForm.controls?.['hasAgreeToTerms']?.errors?.['required']">
                            You must agree to the terms and conditions.
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
    <div class="formgrid grid pt-3">
        <p-button type="submit" label="Next" icon="pi pi-angle-right" iconPos="right"></p-button>
        <!-- <p-button type="submit" label="Next" icon="pi pi-angle-right" iconPos="right"  [disabled]="!billingDetailsForm.valid" ></p-button> -->
    </div>
</form>
<!-- 
<div *ngIf="!billingDetailsForm.valid">
    Invalid controls:
    <ul>
        <li *ngFor="let control of billingDetailsForm.controls | keyvalue">
            <span *ngIf="control.value.invalid">{{ control.key }}</span>
        </li>
    </ul>
</div> -->