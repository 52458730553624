<form *ngIf="!isLoggedIn" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>

	<!-- 	<div class="center-screen">

		<div class="flip-container flip-container-right" [class.flipped]="flipped">
			<div class="flipper">
				<p-card class="back">


					<div class="w-full lg:w-*">
						<div class="text-center mb-5">
							<img src="https://rikexim.com/assets/images/resources/rikexim_200x50_dark.webp"
								alt="Lumberlinq" class="mb-3" style="height: 4rem;">
							<div class="text-900 text-3xl font-medium mb-3">Already having account?</div>
							<a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer" (click)="flip()">Login
								now!</a>
							<hr />
						</div>

						<div class="w-full">
							<button pButton type="button" label="Sign up with Google" class="google-btn w-full mb-3"
								icon="pi pi-google"></button>

							<button pButton type="button" label="Sign up with Microsoft"
								class="microsoft-btn w-full mb-3" icon="pi pi-microsoft"></button>

							<button pButton type="button" label="Sign up with LinkedIn" class="linkedin-btn w-full mb-3"
								icon="pi pi-linkedin"></button>

						</div>
					</div>


				</p-card>
				<p-card class="front">
					<div class="w-full lg:w-*">
						<div class="text-center mb-5">
							<img src="https://rikexim.com/assets/images/resources/rikexim_200x50_dark.webp"
								alt="Lumberlinq" class="mb-3" style="height: 4rem;">
							<div class="text-900 text-3xl font-medium mb-3">Don't have an account?</div>
							<a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
								(click)="flip()">Create today!</a>
							<hr />
						</div>

						<div class="w-full">
							<button pButton type="button" label="Sign in with Google" class="google-btn w-full mb-3"
								icon="pi pi-google" (click)="onGoogleLogin();"></button>

							<button pButton type="button" label="Sign in with Microsoft"
								class="microsoft-btn w-full mb-3" icon="pi pi-microsoft"
								(click)="onMicrosoftLogin();"></button>

							<button pButton type="button" label="Sign in with LinkedIn" class="linkedin-btn w-full mb-3"
								icon="pi pi-linkedin" (click)="onLinkedinLogin();"></button>

						</div>
					</div>
				</p-card>
			</div>
		</div>
	</div>
</form>
<div class="alert alert-success" *ngIf="isLoggedIn">
	You're already loggedin as
	{{currentUser?.firstName ?? ''}} <br>Logged in as {{ currentUser?.email ?? '' }}.
</div>

<p-dialog header="Error Notification" [draggable]="false" [resizable]="false" [(visible)]="visible" [style]="{ 'width': '70vw','min-width': '280px','max-width': '600px' }">
	<p>
		{{ error }}
	</p>
	<ng-template pTemplate="footer">
		<p-button  (click)="visible = false" label="Ok" styleClass="p-button-text"></p-button>
	</ng-template>
</p-dialog> -->
	<div
		class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
		<div class="flex flex-column align-items-center justify-content-center">
			<div
				style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%);">
				<div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius:53px"
					*ngIf="!register; else signup">
					<div class="text-center mb-5">
						<img src="https://rikexim.com/assets/images/resources/rikexim_200x50_dark.webp" alt="Lumberlinq"
							class="mb-3" style="height: 4rem;">
						<div class="text-900 text-3xl font-medium mb-3">Welcome</div>
						<span class="text-600 font-medium">Sign in to continue</span>
					</div>

					<div>
						<a [href]="facebookUrl" rel="noopener noreferrer">
							<button pButton type="button" label="Sign in with Facebook" class="facebook-btn w-full mb-3"
								icon="pi pi-facebook"></button>
						</a>

						<a [href]="googleUrl" rel="noopener noreferrer">
							<button pButton type="button" label="Sign in with Google" class="google-btn w-full mb-3"
								icon="pi pi-google"></button>
						</a>

						<a [href]="microsoftUrl" rel="noopener noreferrer">
							<button pButton type="button" label="Sign in with Microsoft"
								class="microsoft-btn w-full mb-3" icon="pi pi-microsoft"></button>
						</a>

						<a [href]="facebookUrl" rel="noopener noreferrer">
							<button pButton type="button" label="Sign in with LinkedIn" class="linkedin-btn w-full mb-3"
								icon="pi pi-linkedin"></button>
						</a>

					</div>

				</div>

				<ng-template #signup>
					<div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius:53px">
						<div class="text-center mb-5">
							<img src="https://rikexim.com/assets/images/resources/rikexim_200x50_dark.webp"
								alt="Lumberlinq" class="mb-3" style="height: 4rem;">
							<div class="text-900 text-3xl font-medium mb-3">Welcome</div>
							<span class="text-600 font-medium">Sign up to continue</span>
						</div>

						<div>
							<button pButton type="button" label="Sign up with Google" class="google-btn w-full mb-3"
								icon="pi pi-google" (click)="onGoogleLogin();"></button>

							<button pButton type="button" label="Sign up with Google" class="google-btn w-full mb-3"
								icon="pi pi-facebook" (click)="onGoogleLogin();"></button>
							<a [href]="facebookUrl">Login with
								Facebook</a>
							<p-button label="Go to Page" styleClass="p-button-link" [routerLink]="facebookUrl">
							</p-button>


							<button pButton type="button" label="Sign in with Microsoft"
								class="microsoft-btn w-full mb-3" icon="pi pi-microsoft"
								(click)="onMicrosoftLogin();"></button>

							<button pButton type="button" label="Sign up with LinkedIn" class="linkedin-btn w-full mb-3"
								icon="pi pi-linkedin" (click)="onLinkedinLogin();"></button>

						</div>

					</div>
				</ng-template>


			</div>
		</div>
	</div>
</form>

<p-dialog header="Error Notification" [draggable]="false" [resizable]="false" [(visible)]="visible"
	[style]="{ 'width': '70vw','min-width': '280px','max-width': '600px' }">
	<p>
		{{ error }}
	</p>
	<ng-template pTemplate="footer">
		<p-button (click)="visible = false" label="Ok" styleClass="p-button-text"></p-button>
	</ng-template>
</p-dialog>