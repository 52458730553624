import { Component, OnInit } from '@angular/core';
import { SubscriptionViewService } from '../services/subscription-view.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MenuItem } from 'primeng/api';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-subscription-opted-features',
  templateUrl: './subscription-opted-features.component.html',
  styleUrls: ['./subscription-opted-features.component.css'],
  standalone: true,
  imports: [SHARED_MODULES]
})
export class SubscriptionOptedFeaturesComponent implements OnInit {

  features!: any;
  protected breadCumbItems!: MenuItem[];
  home!: MenuItem;

  constructor(private subscriptionViewService: SubscriptionViewService) {
  }



  ngOnInit(): void {
    console.log(`SubscriptionOptedFeaturesComponent Loading now... `);
    this.subscriptionViewService.getFeatures().subscribe(
      (response: any) => {
        console.log(`Response for SubscriptionOptedFeaturesComponent is ${JSON.stringify(response)}`);
        this.features = response;
      },
      (error: HttpErrorResponse) => {
        console.error(`Error for SubscriptionOptedFeaturesComponent is ${JSON.stringify(error)}`);
      },
      () => { }
    )
    this.populateBreadCrumb();
  }

  populateBreadCrumb() {
    this.home = { icon: 'pi pi-home', routerLink: '/' };
    this.breadCumbItems = [

      { label: 'Subscription' },
      { label: 'Subcription Features' },
    ];
  }
}
