import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstants } from '../commons/app-constants';
import { UtilService } from './util.service';

/* @Injectable({
  providedIn: 'root'
})
export class FontSizeServiceService {
  

  private fontSizeSource = new BehaviorSubject<number>(14);
  currentFontSize = this.fontSizeSource.asObservable();

  constructor() {}

  changeFontSize(size: number) {
    console.log(`changeFontSize ${size}`)
    this.fontSizeSource.next(size);
  }
}
  */

@Injectable({
  providedIn: 'root',
})
export class FontSizeServiceService {
  private fontSizeSource: BehaviorSubject<number>;
  currentFontSize: Observable<number>;

  constructor(private utilService: UtilService) {
    const storedFontSize = localStorage.getItem(AppConstants.FONT_SIZE);
    // const storedFontSize = localStorage.getItem(AppConstants.FONT_SIZE) || 0.75;

    // console.log(`Get FontSize from localstorage is ${storedFontSize}`);
    let initialFontSize = 0;
    if (this.utilService.isMobile()) {
      initialFontSize = storedFontSize ? +storedFontSize : 0.75;
    } else if (this.utilService.isTab()) {
      initialFontSize = storedFontSize ? +storedFontSize : 0.875;
    } else {
      initialFontSize = storedFontSize ? +storedFontSize : 1;
    }

    this.fontSizeSource = new BehaviorSubject<number>(initialFontSize);
    this.currentFontSize = this.fontSizeSource.asObservable();
  }

  /* changeFontSize(size: number) {
    console.trace(`changeFontSize1  from service is ${size}`);

    console.log(`changeFontSize1  from service is ${size}`);
    localStorage.setItem(AppConstants.FONT_SIZE, size.toString());
    this.fontSizeSource.next(size);
  } */

  changeFontSize(size: number) {
    if (size === undefined) {
      console.error('FontSize is undefined');
      return;
    }
  
    console.trace(`changeFontSize from service is ${size}`);
    localStorage.setItem(AppConstants.FONT_SIZE, size.toString());
    this.fontSizeSource.next(size);
  }
}
