export class RazorpayPaymentResponse {
    
      subscriptionOrderId!: number

      razorpayOrderId!: string;

      razorpayPaymentId!: string;

      razorpaySignature!: string
}
