import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { CustomEmailValidator } from 'src/app/classes/custom-email.validator';
import { NameCheckValidator } from 'src/app/validators/name-check-validator';
import { City } from 'src/app/commons/models/city';
import { BuyerService } from '../services/buyer.service';
import { ConfirmationService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { BuyerSharedDataService } from '../services/buyer-shared-data.service';
import { Title } from '@angular/platform-browser';
import { Buyer } from '../model/buyer';
import { ToastMessagesService } from 'src/app/commons/services/toast-messages.service';
import { ToastModule } from 'primeng/toast';
import { LocationSelectionService } from '../../commons/location-selection/service/location-selection.service';
import { Location } from '../../commons/location-selection/model/location';
import { LocationSelectionComponent } from '../../commons/location-selection/location-selection.component';
import { ConfirmDeleteCommonService } from 'src/app/commons/services/confirm-delete-common.service';
import { ResetCommonService } from 'src/app/commons/services/reset-common.service';
import { ShortcutCommonService } from 'src/app/commons/services/shortcut-common.service';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-buyer',
  templateUrl: './buyer.component.html',
  styleUrls: ['./buyer.component.css'],
  providers: [MessageService, ToastMessagesService, BuyerService],
  standalone:true,
  imports:[SHARED_MODULES, LocationSelectionComponent]
})
export class BuyerComponent implements OnInit, AfterViewInit {
  @Input() showBreadcrumb: boolean = true;
  @Input() titleFromSidebar!: string;
  @Input() headerFromSidebar!: string;
  @Input() subHeaderFromSidebar!: string;
  @Input() isSubmittedFromSidebar: boolean = false;
  @Output() buyerSubmit: EventEmitter<any> = new EventEmitter();
  @ViewChild(LocationSelectionComponent, { static: false })
  locationSelectionComponent!: LocationSelectionComponent;
  blockedDocument = false;

  editMode = false;
  isSubmitting = false;
  addEditButton = 'Save';
  buyerEditData!: Buyer;
  existingLocationData!: Location;
  saveForm() {
    throw new Error('Method not implemented.');
  }

  formHeader!: string;
  formSubHeader!: string;
  protected items!: MenuItem[];
  home!: MenuItem;
  myForm!: FormGroup;
  form: any;
  cities!: City[];
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private customEmailValidator: CustomEmailValidator,
    private nameValidator: NameCheckValidator,
    private buyerService: BuyerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private buyerSharedService: BuyerSharedDataService,
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastMessagesService,
    private locationSelectionService: LocationSelectionService,
    private confirmDeleteServiceCommon: ConfirmDeleteCommonService,
    private resetServiceCommon: ResetCommonService,
    private shortcutService: ShortcutCommonService
  ) {
    this.setPageTitle();

    this.cities = [];
  }
  ngAfterViewInit(): void {
    this.myForm.markAsPristine();
  }

  ngOnInit(): void {
   
    this.populateBreadCrumb();
    this.home = { icon: 'pi pi-home', routerLink: '/dashboard' };
    this.createForm();
    const url = this.router.url;
    if (url.includes('edit/buyer')) {
      this.editMode = true;
      this.route.data.subscribe((data) => {
        this.buyerEditData = data['buyer'];
      });
     // this.buyerEditData = this.buyerSharedService.getDataFromLocalStorage();
      if (!this.buyerEditData) {
      }
      
      console.log(
        `this.myForm?.get('location')?.value ${JSON.stringify(
          this.buyerEditData.location
        )}`
      );
      this.existingLocationData = this.locationSelectionService.getLocationData(
        this.buyerEditData.location
      );

      this.myForm.patchValue(this.buyerEditData);
      this.myForm
        .get('state')
        ?.patchValue(this.buyerEditData.cityId.stateId.name);

      this.myForm!.get('name')!.setAsyncValidators([]);
      this.myForm!.get('gst')!.setAsyncValidators([]);
      // this.formHeader = 'Edit Buyer';
      // this.formSubHeader = 'Edit Buyer Sub Header';
      this.addEditButton = 'Update';

      this.myForm.markAsPristine();
    }

    this.initSidebar();
  }

  private createForm() {
    this.myForm = this.fb.group({
      buyerId: [],
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(32),
        ],
        [this.nameValidator.checkBuyerNameValidator()],
      ],
      contactPerson: ['', [Validators.minLength(3), Validators.maxLength(32)]],
      // address1: ['', [Validators.minLength(3), Validators.maxLength(64)]],
      //address2: ['', [Validators.minLength(3), Validators.maxLength(64)]],
      // cityId: ['', [Validators.required]],
      //state: [''],
      /* pincode: [
        '',
        [
          Validators.required,
          Validators.pattern('^[1-9]{1}[0-9]{2}s{0,1}[0-9]{3}$'),
        ],
      ], */
      phone1: ['', [Validators.pattern('^[0-9]{8,15}$')]],
      phone2: ['', [Validators.pattern('^[0-9]{8,15}$')]],
      email: ['', [Validators.email]],
      gst: [
        '',
        [Validators.minLength(5), Validators.maxLength(20)],
        [this.nameValidator.checkGSTValidator()],
      ],
      location: ['', [Validators.required]],
    });

    this.myForm.markAsPristine();
  }

  populateBreadCrumb() {
    this.items = [
      { label: 'Buyers', routerLink: '/buyers' },
      { label: 'Add Buyer' },
    ];
  }

  onSubmit() {
    this.isSubmitting = true;

    this.locationSelectionComponent.onSubmit();
    const controlKeys = Object.keys(this.myForm.controls);

    const invalidControlKeys = controlKeys.filter((key) => {
      const control = this.myForm.controls[key];
      return control.invalid;
    });
    console.log('Invalid control keys:', invalidControlKeys);
    this.myForm.markAllAsTouched();
    Object.keys(this.myForm.controls).forEach((key) => {
      console.log(`Key is ${key}`);
      //this.myForm.get(key)?.updateValueAndValidity();
    });
    /*  this.myForm.controls['name'].markAsTouched();
     this.myForm.controls['cityId'].markAsTouched();
     this.myForm.controls['pincode'].markAsTouched(); */
    console.log(this.myForm.value);
    if (this.myForm.valid) {
      // if (true) {
      console.log(this.myForm.value);
      this.blockedDocument = true;
      this.buyerService.postData(this.myForm.value).subscribe(
        (resp) => {
          console.log(`On Submit Response is ${JSON.stringify(resp)}`);
          //  this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Buyer Created Successfully' });

          if (this.editMode) {
            this.router.navigate(['/buyers']);
            this.toastService.showInfoMessage(
              'Success',
              "Buyer's details have been successfully updated."
            );
          } else {
            this.toastService.showSuccessMessage(
              'Success',
              'New buyer has been successfully registered.'
            );
            // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Message Content' });

            if (!this.isSubmittedFromSidebar) {
              this.confirmationService.confirm({
                header: 'Add More Buyers',
                message: 'Would you like to continue adding more buyers?',
                accept: () => {
                  //Actual logic to perform a confirmation
                  this.myForm.reset();
                },
                reject: () => {
                  this.router.navigate(['/buyers']);
                },
              });
            } else {
              this.myForm.reset();
              this.buyerSubmit.emit();
            }
          }
          this.blockedDocument = false;
        },
        (error) => {
          this.blockedDocument = false;

          console.log(`On Submit error is ${JSON.stringify(error)}`);
          this.toastService.showErrorMessage(
            'Operation Unsuccessful',
            'An error occurred. Please try again.'
          );
          // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
        },
        () => {
          console.log(`Finish onSubmit`);
        }
      );
    } else {
      console.error(`Form is not valid`);
    }
  }

  setPageTitle() {
    this.route.data.subscribe((data) => {
      this.titleService.setTitle(data['title']);
      this.formHeader = data['header'];
      this.formSubHeader = data['subHeader'];
    });
  }

  // nameValidation  is used while editMode=true, if name is changed check if already exist, if name is same as it was entered then ignore async validation
  // add even function (input) in html
  nameValidation() {
    console.log(`nameValidation `);
    if (this.editMode) {
      const isValueChanged =
        this.buyerEditData.name == this.myForm.get('name')?.value;
      if (!isValueChanged) {
        console.log(`is value changed isValueChanged}`);
        this.myForm!.get('name')!.setAsyncValidators([
          this.nameValidator.checkBuyerNameValidator(),
        ]);
      } else {
        this.myForm!.get('name')!.setAsyncValidators([]);
        this.myForm.markAllAsTouched();
        this.myForm.get('name')?.updateValueAndValidity();
        console.log(`is not value changed isValueChanged}`);
      }
      //if(this.buyerEditData.name == this.myForm.get('name')?.value)
    }
  }

  // gstValidation  is used while editMode=true, if gst is changed check if already exist, if gst is same as it was entered then ignore async validation
  // add even function (input) in html
  gstValidation() {
    console.log(`gstValidation `);
    if (this.editMode) {
      const isValueChanged =
        this.buyerEditData.gst == this.myForm.get('gst')?.value;
      if (!isValueChanged) {
        console.log(`is value changed isValueChanged}`);
        this.myForm!.get('gst')!.setAsyncValidators([
          this.nameValidator.checkGSTValidator(),
        ]);
      } else {
        this.myForm!.get('gst')!.setAsyncValidators([]);
        this.myForm.markAllAsTouched();
        this.myForm.get('gst')?.updateValueAndValidity();
        console.log(`is not value changed isValueChanged}`);
      }
      //if(this.buyerEditData.name == this.myForm.get('name')?.value)
    }
  }

  initSidebar() {
    console.log(`initSidebar  `);
    console.log(`initSidebar ${this.isSubmittedFromSidebar} `);
    if (this.isSubmittedFromSidebar) {
      console.log(`initSidebar  ${this.headerFromSidebar}`);
      this.formHeader = this.headerFromSidebar;
      this.formSubHeader = this.subHeaderFromSidebar;
      this.titleService.setTitle(this.titleFromSidebar);
    }
  }

  locationFormValidators = {
    country: [Validators.required],
    state: [Validators.required],
    city: [Validators.required],
    zipcode: [Validators.required],
  };

  onLocationFormStatus(isLocationFormValid: boolean): void {
    console.log(`onLocationFormStatus ${isLocationFormValid}`);
    if (isLocationFormValid) {
      this.myForm.controls['location'].setErrors(null);
    } else {
      this.myForm.controls['location'].setErrors({ invalidLocationForm: true });
    }
  }

  private executeDeleteService(id: any): Promise<void> {
    return new Promise((resolve, reject) => {
      // Call your actual delete service here
      console.log(`id is ${id}`);
      this.blockedDocument = true;

      this.buyerService.del();
      this.buyerService.deleteBuyers1(id).subscribe(
        () => {
          resolve();
          this.blockedDocument = false;
        },
        (error) => {
          reject(error);
          this.blockedDocument = false;
        }
      );
    });
  }

  onDelete(id: any): void {
    this.myForm.markAsPristine();
    this.confirmDeleteServiceCommon.confirm(
      id,
      () => this.executeDeleteService(id),
      '/buyers'
    );
  }

  onReset() {
    console.log('onReset called in buyer');
    this.locationSelectionComponent.resetLocationForm();
    this.resetServiceCommon.resetForm(this.myForm);
  }

  // @HostListener('window:keydown.meta.shift.s', ['$event'])
  // handleSaveShortcut(event: KeyboardEvent) {
  //   this.onSubmit();
  // }

  // @HostListener('window:keydown.meta.shift.d', ['$event'])
  // handleDeleteShortcut(event: KeyboardEvent) {
  //   if (this.editMode) {
  //     this.onDelete(this.buyerEditData.buyerId);
  //   }
  // }

  onLocationLoadingFinished() {}
}
