<!-- <p-toast></p-toast> -->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" acceptLabel="Yes"
    rejectLabel="No"></p-confirmDialog>

<!-- <div class="pb-3">
    <p-breadcrumb [model]="breadCumbItems" [home]="home"></p-breadcrumb>
</div> -->




<p-card [style.overflow-x]="'auto'">
    <p-table [columns]="selectedColumns" [value]="history" [tableStyle]="{ 'min-width': '50rem' }"
        styleClass="p-datatable">
        <ng-template pTemplate="caption">
            <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{'min-width': '200px'}"
                placeholder="Choose Columns"></p-multiSelect>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <!-- <th *ngFor="let col of columns" class="text-center">
                    {{col.header}}
                </th> -->
                <th class="text-center">SR</th>
                <th class="text-center" *ngIf="isColumnSelected('date')">Date</th>
                <th class="text-center" *ngIf="isColumnSelected('start_date')">Start Date</th>
                <th class="text-center" *ngIf="isColumnSelected('end_date')">End Date</th>
                <th class="text-center"  *ngIf="isColumnSelected('package')">Package</th>
                <th class="text-center" *ngIf="isColumnSelected('duration')">Duration</th>
                <th class="text-center" *ngIf="isColumnSelected('amount')">Amount</th>
                <th class="text-center" *ngIf="isColumnSelected('payment_id')">Payment Id</th>
                <th class="text-center" *ngIf="isColumnSelected('payment_status')">Payment Status</th>
                <th class="text-center" *ngIf="isColumnSelected('auto_debit')">Auto Debit</th>
                <th class="text-center" *ngIf="isColumnSelected('download')">
                    <i class="pi pi-download" style="font-size: 1rem"></i>
                </th>
            </tr>
        </ng-template>
         
          

         <ng-template pTemplate="body" let-eachHistory let-rowIndex="rowIndex">
            <tr>
                <td class="text-center">{{ rowIndex + 1 }}</td>
                <td class="text-center"  *ngIf="isColumnSelected('date')"> {{ eachHistory.createdDate | date:'dd/MM/yyyy HH:mm:ss' }}</td>
                <td class="text-center" *ngIf="isColumnSelected('start_date')"> {{ eachHistory.startDate | date:'dd/MM/yyyy' }}</td>
                <td class="text-center" *ngIf="isColumnSelected('end_date')"> {{ eachHistory.endDate | date:'dd/MM/yyyy' }}</td>
                <td class="text-center" *ngIf="isColumnSelected('package')">{{ eachHistory.name }}</td>
                <td class="text-center" *ngIf="isColumnSelected('duration')">{{ eachHistory.period }}</td>
                <td class="text-center" *ngIf="isColumnSelected('amount')">{{ eachHistory.grandTotal | currency:
                    eachHistory.isoCurrencyName:'symbol':'1.2-2' }}</td>
                <td class="text-center" *ngIf="isColumnSelected('payment_id')"> {{ eachHistory.razorpayPaymentId }}</td>
                <td class="text-center" *ngIf="isColumnSelected('payment_status')">{{ eachHistory.paymentStatus }}</td>
                <td class="text-center" *ngIf="isColumnSelected('auto_debit')">
                    <p>{{ eachHistory.isAutoDebit ? 'YES' : 'NO' }}</p>
                </td>
                <td   class="text-center" *ngIf="isColumnSelected('download')" >
                    <a [class.disabled]="eachHistory.paymentStatus === 'TRIAL' || eachHistory.paymentStatus === 'PENDING'"  href="#" (click)="$event.preventDefault();downloadInvoice(eachHistory.id)">
                        <i  class="pi pi-download" style="font-size: 1rem"></i>

                    </a>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-card>