<div class="card pt-3"> 
    <!-- This page is called by /timber/pl -->
    <div class="formgrid grid">
        <div class="field col">
            <label for="firstname2"> <span class="text-bold"> Container Number: </span> </label>
            <span class="text-bold"> {{ containerNumber?.containerNumber?? '' }}</span>
        </div>

    </div>
    <p-tabView>
        <p-tabPanel header="Measurements">
            <!-- <ng-template pTemplate="content" class="w-100">
                <app-sawn-timber-row *ngIf="containerNumber.productFormula === 1" [container]="containerNumber"></app-sawn-timber-row>
                <app-hoppus-measurement *ngIf="containerNumber.productFormula === 2"  [container]="containerNumber"></app-hoppus-measurement>
            </ng-template> -->

            <ng-container *ngIf="containerNumber; else loading">
                <ng-container *ngIf="containerNumber.productFormula === 2; else hoppusMeasurementBlock">
                    <app-sawn-timber-row></app-sawn-timber-row>
                </ng-container>
            </ng-container>

            <ng-template #loading>
                <!-- You can put some loading spinner or placeholder content here -->
                <p>Loading...</p>
            </ng-template>

            <ng-template #hoppusMeasurementBlock>
                <!-- <app-hoppus-measurement > </app-hoppus-measurement> -->
                <app-add-hoppus-measurement></app-add-hoppus-measurement>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Photos">
            <ng-template pTemplate="content" class="w-100 mt-3">
                <app-sawn-timber-photos></app-sawn-timber-photos>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Summary">
            <ng-template pTemplate="content" class="w-100 mt-3">
                <!-- {{containerNumber.productFormula}} {{containerNumber.productFormula === 2}} -->
                <!-- <app-sawn-timber-summary *ngIf="containerNumber.productFormula === 2; else hoppusSummaryBlock"></app-sawn-timber-summary> -->
                <app-sawn-timber-view-summary
                    *ngIf="containerNumber.productFormula === 2; else hoppusSummaryBlock"></app-sawn-timber-view-summary>
                <!-- <app-view-hoppus-summary [formula]="containerNumber.productFormula"></app-view-hoppus-summary> -->
            </ng-template>
            <ng-template #hoppusSummaryBlock>
                <!-- <app-summary [inputSummary]="hoppusSummary"></app-summary> -->
                <app-view-hoppus-summary [containerIdInput]="containerNumber?.containerId?? ''" [formula]="containerNumber.productFormula"></app-view-hoppus-summary>
            </ng-template>
        </p-tabPanel>
    </p-tabView>
</div>

<p-confirmDialog [style]="{width: '50vw'}" key="confirmNavigation"></p-confirmDialog>

