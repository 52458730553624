import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';
import { Container } from '../model/container';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/commons/app-constants';
import { SquareTallySheet } from 'src/app/interfaces/square-tally-sheet';
import { TallySheetSummaryResponse } from 'src/app/interfaces/tally-sheet/tally-sheet-summary-response';

@Injectable({
  providedIn: 'root'
})
export class SquaredTallysheetService {

  baseUrl = '';


  constructor(
    private http: HttpClient,
    private encryptedStorageService: EncryptedStorageService
  ) {

  }

  saveSquareTallySheet(tallySheet: SquareTallySheet): Observable<TallySheetSummaryResponse> {
    const API_URL = `${AppConstants.API_URL}api/v1/square-tally-record/saveSquareTallySheet`;
    return this.http.post<TallySheetSummaryResponse>(API_URL, tallySheet);
  }

  deleteRecord(id: number): Observable<any> {
    const API_URL = `${AppConstants.API_URL}api/v1/square-tally-record/deleteRecord`;
    let params = new HttpParams().set('id', id.toString());
    return this.http.delete(`${API_URL}`, { params });
  }

  saveSummary(summary: any): Observable<any> {
    const API_URL = `${AppConstants.API_URL}api/v1/square-tally-record/saveSummary`;
    return this.http.post(`${API_URL}saveSummary`, summary);
  }

  getTallysheetSummaryByContainerId(id: number): Observable<any> {
    const API_URL = `${AppConstants.API_URL}api/v1/square-tally-record/summaryByContainerId`;

    const params = new HttpParams()
      .set('id', id.toString());
    const fullUrl = `${API_URL}?${params.toString()}`;
    console.log(`Full URL for getTallysheetSummaryByContainerId is ${fullUrl}`);
    return this.http.get(API_URL, { params });//, headers, withCredentials: true  });
  }

  
  updateSummaryType(id: number, summaryType: 'PUBLIC' | 'PRIVATE' | 'SOLD'): Observable<any> {
    // const url = `${this.baseUrl}${id}/summary-type`;
    const API_URL = `${AppConstants.API_URL}api/v1/square-tally-record/summary-type`;
    console.log(`url ${API_URL}`)
    return this.http.put(API_URL, null, {
      params: { summaryType: summaryType },
    });
  }

  getTallysheetRecordsByContainerId(id: number, page: number, size: number): Observable<any> {
    // const url = `${this.baseUrl}getRecordsByContainerId`;
    const API_URL = `${AppConstants.API_URL}api/v1/square-tally-record/getRecordsByContainerId`;
    
    const params = new HttpParams()
      .set('id', id.toString())
      .set('page', page.toString())
      .set('size', size.toString());
      const fullUrl = `${API_URL}?${params.toString()}`;
      console.log(`Full URL for getTallysheetRecordsByContainerId is ${fullUrl}`);
    return this.http.get(API_URL, { params });
  }

  getAllRecordsRestful(id: number): Observable<any> {
    // const url = `${this.baseUrl}getAllRecords`;
    const API_URL = `${AppConstants.API_URL}api/v1/square-tally-record/getAllRecords`;
    const params = new HttpParams()
      .set('id', id.toString())
    return this.http.get(API_URL, { params });
  }

  exportTallySummary(summaryId: number) {
    // const url = `${this.baseUrl}tally-summary/${summaryId}/export`;
    const API_URL = `${AppConstants.API_URL}api/v1/square-tally-record/getAl`;

    return this.http.get(API_URL, { responseType: 'blob' });
  }

   // Method to update isRowModeEnabled
   updateRowModeEnabled(id: number, isRowModeEnabled: boolean): Observable<any> {
    return this.http.put(`${this.baseUrl}${id}/row-mode-enabled`, null, {
      params: {
        isRowModeEnabled: isRowModeEnabled.toString()
      }
    });
  }

  //
  getShareabelUrl(id: number): Observable<any> {
    const url = `${this.baseUrl}share-url/${id}`;
    console.log(`url ${url}`)
    return this.http.get(url);
  }


}
