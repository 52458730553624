import { Injectable } from '@angular/core';
import { BillOfLading } from '../model/bill-of-lading';

@Injectable({
  providedIn: 'root'
})
export class BillOfLadingSharedDataService {

  sellerSharedData!: any;

  constructor() { }


  saveDataToLocalStorage(data: BillOfLading) {
    localStorage.setItem('BILLOFLADING', JSON.stringify(data));
  }

  getDataFromLocalStorage(): BillOfLading {
    const data = localStorage.getItem('BILLOFLADING');
    return JSON.parse(data!);
  }

  removeDataFromLocalStorage(): void {
    const data = localStorage.removeItem('BILLOFLADING');
  }

  saveBLIdLocalStorage(data: number) {
    localStorage.setItem('BL_ID', JSON.stringify(data));
  }

  getBLIdLocalStorage(): number {
    const data = localStorage.getItem('BL_ID');
    return JSON.parse(data!);
  }

  removeBLIdFromLocalStorage(): void {
    const data = localStorage.removeItem('BL_ID');
  }
}
