import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MasterSubscriptionComponent } from './master-subscription/master-subscription.component';
import { ViewMasterSubscriptionComponent } from './view-master-subscription/view-master-subscription.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PhotoGalleryModule } from '@twogate/ngx-photo-gallery';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SpeedDialModule } from 'primeng/speeddial';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { PanelModule } from 'primeng/panel';
import { BlockUIModule } from 'primeng/blockui';
import { TallySheetModule } from '../tally-sheet/tally-sheet.module';




@NgModule({
  declarations: [
    // MasterSubscriptionComponent,
    // ViewMasterSubscriptionComponent
  ],
  imports: [
    CommonModule,
    CommonModule,
    CardModule,
    ButtonModule,
    BreadcrumbModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextareaModule,
    InputMaskModule,
    InputTextModule,
    ToastModule,
    ConfirmDialogModule,
    TableModule,
    PaginatorModule,
    MultiSelectModule,
    MessagesModule,
    SidebarModule,
    SpeedDialModule,
    SidebarModule,
    AutoCompleteModule,
    PhotoGalleryModule.forRoot({ defaultOptions: { showHideOpacity: true, arrowEl: true,  indexIndicatorSep: '/'} }),
    DividerModule,
    SkeletonModule,
    ProgressSpinnerModule,
    DialogModule,
    PanelModule,
    BlockUIModule,
    TallySheetModule,
    
    MasterSubscriptionComponent,
    ViewMasterSubscriptionComponent
  ],exports: [
    MasterSubscriptionComponent,
    ViewMasterSubscriptionComponent
  ]
   
})
export class ROOTModule { }
