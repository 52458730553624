import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConstants } from 'src/app/commons/app-constants';
import { EncryptedStorageService } from 'src/app/services/encrypted-storage.service';
import { HistogramDataService } from 'src/app/services/histogram-data.service';
import { SawnTimberService } from '../../sawn-timber/services/sawn-timber.service';
import { VIEW_MODE } from '../bill-of-lading-main/bill-of-lading-main.component';
import { Formula } from '../../product/product/product.component';
import { SHARED_MODULES } from '../../shared-imports';

@Component({
  selector: 'app-bl-volumetric-summary',
  templateUrl: './bl-volumetric-summary.component.html',
  styleUrls: ['./bl-volumetric-summary.component.css'],
  standalone:true,
  imports:[SHARED_MODULES]
})
export class BlVolumetricSummaryComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  // @Input() data: any;
  @Input() containerId: any;
  @Input() viewMode!: VIEW_MODE;
  @Input() formula!: Formula;
  histogramData!: any[];
  lengthSummary!: any[];
  widthSummary!: any[];
  heightSummary!: any[];
  containerDetails: any;
  VIEW_MODE = VIEW_MODE;
  // containerId: any;
  containerDetailsArray!: { property: string; value: unknown }[];
  // isPublic = false;
  // containerUUID!: string | null;
  @Input() containerUUID!: any | null;

  isLoading = false; // loading property

  constructor(
    private encryptedStorageService: EncryptedStorageService,
    private sawnTimberService: SawnTimberService,
    private histogramDataService: HistogramDataService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    try {
      const timberData = await this.sawnTimberService
          .getPublicTimbersFromContainerUUID(this.containerUUID)
          .toPromise();

        this.calculate(timberData.rows);

        await this.getPublicContainerDetails(this.containerUUID);
     /*  if (this.viewMode = VIEW_MODE.private) {
        const timberData = await this.sawnTimberService
          .getTimbersFromContainerId(this.containerId)
          .toPromise();

        this.calculate(timberData.rows);

        await this.getPrivateContainerDetails(this.containerId);
      } else {

        // this.containerUUID = this.route.snapshot.paramMap.get('containerUUID');

        const timberData = await this.sawnTimberService
          .getPublicTimbersFromContainerUUID(this.containerUUID)
          .toPromise();

        this.calculate(timberData.rows);

        await this.getPublicContainerDetails(this.containerUUID);
      } */
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  ngOnDestroy(): void {}
  ngAfterViewInit(): void {}

 /*  calculate(data: any) {
    let totalPieces = data.reduce((acc: any, item: any) => {
      let lengthKey = `length:${item.length}`;
      let widthKey = `width:${item.width}`;
      let heightKey = `height:${item.height}`;

      if (!acc[lengthKey]) {
        acc[lengthKey] = {
          dimension: item.length,
          totalPieces: 0,
          type: 'length',
        };
      } else {
        acc[lengthKey].totalPieces += item.pieces;
      }

      if (!acc[widthKey]) {
        acc[widthKey] = {
          dimension: item.width,
          totalPieces: 0,
          type: 'width',
        };
      } else {
        acc[widthKey].totalPieces += item.pieces;
      }

      if (!acc[heightKey]) {
        acc[heightKey] = {
          dimension: item.height,
          totalPieces: 0,
          type: 'height',
        };
      } else {
        acc[heightKey].totalPieces += item.pieces;
      }

      return acc;
    }, {});

    this.lengthSummary = [];
    this.widthSummary = [];
    this.heightSummary = [];

    for (let key in totalPieces) {
      if (totalPieces[key].type === 'length') {
        this.lengthSummary.push(totalPieces[key]);
      } else if (totalPieces[key].type === 'width') {
        this.widthSummary.push(totalPieces[key]);
      } else if (totalPieces[key].type === 'height') {
        this.heightSummary.push(totalPieces[key]);
      }
    }
    // Assuming lengthSummary, widthSummary, and heightSummary are class properties
    this.lengthSummary.sort((a, b) => a.dimension - b.dimension);
    this.widthSummary.sort((a, b) => a.dimension - b.dimension);
    this.heightSummary.sort((a, b) => a.dimension - b.dimension);
  } */
  calculate(data: any) {
    console.log(`Data of Rows is ${JSON.stringify(data)}`);
    let totalPieces = data.reduce((acc: any, item: any) => {
      let lengthKey = `length:${item.length}`;
      let widthKey = `width:${item.width}`;
      let heightKey = `height:${item.height}`;

      if (!acc[lengthKey]) {
        acc[lengthKey] = {
          dimension: item.length,
          totalPieces: item.pieces, // change here
          type: 'length',
        };
      } else {
        acc[lengthKey].totalPieces += item.pieces;
      }

      if (!acc[widthKey]) {
        acc[widthKey] = {
          dimension: item.width,
          totalPieces: item.pieces, // change here
          type: 'width',
        };
      } else {
        acc[widthKey].totalPieces += item.pieces;
      }

      if (!acc[heightKey]) {
        acc[heightKey] = {
          dimension: item.height,
          totalPieces: item.pieces, // change here
          type: 'height',
        };
      } else {
        acc[heightKey].totalPieces += item.pieces;
      }

      return acc;
    }, {});

    this.lengthSummary = [];
    this.widthSummary = [];
    this.heightSummary = [];

    for (let key in totalPieces) {
      if (totalPieces[key].type === 'length') {
        this.lengthSummary.push(totalPieces[key]);
      } else if (totalPieces[key].type === 'width') {
        this.widthSummary.push(totalPieces[key]);
      } else if (totalPieces[key].type === 'height') {
        this.heightSummary.push(totalPieces[key]);
      }
    }
    // Assuming lengthSummary, widthSummary, and heightSummary are class properties
    this.lengthSummary.sort((a, b) => a.dimension - b.dimension);
    this.widthSummary.sort((a, b) => a.dimension - b.dimension);
    this.heightSummary.sort((a, b) => a.dimension - b.dimension);
  }

  async getPrivateContainerDetails(containerId: number) {
    try {
      const data = await this.sawnTimberService
        .getContainerDetail(containerId)
        .toPromise();
      this.containerDetails = data;
      this.containerDetailsArray = [
        { property: 'Container Number', value: data.containerNumber },
        { property: 'Net Volume', value: data.totalNetVolume },
        { property: 'Pieces', value: data.pieces },
        { property: 'Product', value: data.productName },
        { property: 'Formula', value: data.formula },
        { property: 'Seal Number', value: data.containerSeal },
      ];
    } catch (error) {
      console.error(error);
    }
  }

  async getPublicContainerDetails(containerUuid: string | null) {
    try {
      const data = await this.sawnTimberService
        .getPublicContainerDetail(containerUuid)
        .toPromise();
      this.containerDetails = data;
      this.containerDetailsArray = [
        { property: 'Container Number', value: data.containerNumber },
        { property: 'Net Volume', value: data.totalNetVolume },
        { property: 'Pieces', value: data.pieces },
        { property: 'Product', value: data.productName },
        { property: 'Formula', value: data.formula },
        { property: 'Seal Number', value: data.containerSeal },
      ];
    } catch (error) {
      console.error(error);
    }
  }
}
/* 
  histogram(data: any, totalBins: number) {
    let bins = this.histogramDataService.createHistogramBins(data, totalBins);
    this.histogramData = this.histogramDataService.countRecordsInBins(
      data,
      bins
    );

    console.log(JSON.stringify(data));
    console.log(this.histogramData);
  } */

/***** OLD CODE same functionalities as above only the difference is above code is refactored 
 ***** and below code is not refactored 
 export class SawnTimberViewSummaryComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() data: any;
  histogramData!: any[];
  lengthSummary!: any[];
  widthSummary!: any[];
  heightSummary!: any[];
  containerDetails: any;
  containerId: any;
  containerDetailsArray!: { property: string; value: unknown }[];
  isPublic = false;
  containerUUID!: string | null;

  constructor(
    private encryptedStorageService: EncryptedStorageService,
    private sawnTimberService: SawnTimberService,
    private histogramDataService: HistogramDataService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {

    this.isPublic = this.router.url.includes('/public/view');

    if (!this.isPublic) {
      this.encryptedStorageService
        .getEncryptedDataFromStorage(AppConstants.VIEW)
        .subscribe(
          (data: any) => {
            console.log(`data is ${JSON.stringify(data)}`);
            this.containerId = data.containerId;
            this.sawnTimberService
              .getSawnTimberSummary(data.containerId)
              .subscribe(
                (data: any) => {
                  console.log(`Response of Server is ${JSON.stringify(data)}`);
                },
                (error: any) => {},
                () => {}
              );

            this.sawnTimberService
              .getTimbersFromContainerId(data.containerId)
              .subscribe(
                (data: any) => {
                  console.log(`Data of rows is ${JSON.stringify(data.rows)}`);
                  // let widthArray = data.map((item: { width: any; }) => item.width);
                  const rows = data.rows;
                  let widthArray = rows.map(
                    (item: { width: number }) => item.width
                  );

                 // this.histogram(widthArray, 5);
                 // this.calculate(rows);
                  this.getPrivateContainerDetails(this.containerId);
                },
                (error: any) => {},
                () => {}
              );
          },
          (error: any) => {},
          () => {}
        );
    } else {
      this.containerUUID = this.route.snapshot.paramMap.get('containerUUID');
      console.log(`Container UUID is ${this.containerUUID}`);
      this.sawnTimberService
        .getPublicTimbersFromContainerUUID(this.containerUUID)
        .subscribe(
          (data: any) => {
            console.log(`Data of rows is ${JSON.stringify(data.rows)}`);
            const rows = data.rows;
            let widthArray = rows.map((item: { width: number }) => item.width);

            this.calculate(rows);
            this.getPublicContainerDetails(this.containerUUID);
          },
          (error: any) => {},
          () => {}
        );
    }
  }
  ngOnDestroy(): void {
  }
  ngAfterViewInit(): void {
  }


  calculate(data: any) {
    let totalPieces = data.reduce((acc: any, item: any) => {
      let lengthKey = `length:${item.length}`;
      let widthKey = `width:${item.width}`;
      let heightKey = `height:${item.height}`;

      if (!acc[lengthKey]) {
        acc[lengthKey] = {
          dimension: item.length,
          totalPieces: 0,
          type: 'length',
        };
      } else {
        acc[lengthKey].totalPieces += item.pieces;
      }

      if (!acc[widthKey]) {
        acc[widthKey] = {
          dimension: item.width,
          totalPieces: 0,
          type: 'width',
        };
      } else {
        acc[widthKey].totalPieces += item.pieces;
      }

      if (!acc[heightKey]) {
        acc[heightKey] = {
          dimension: item.height,
          totalPieces: 0,
          type: 'height',
        };
      } else {
        acc[heightKey].totalPieces += item.pieces;
      }

      return acc;
    }, {});

    this.lengthSummary = [];
    this.widthSummary = [];
    this.heightSummary = [];

    for (let key in totalPieces) {
      if (totalPieces[key].type === 'length') {
        this.lengthSummary.push(totalPieces[key]);
      } else if (totalPieces[key].type === 'width') {
        this.widthSummary.push(totalPieces[key]);
      } else if (totalPieces[key].type === 'height') {
        this.heightSummary.push(totalPieces[key]);
      }
    }
    // Assuming lengthSummary, widthSummary, and heightSummary are class properties
    this.lengthSummary.sort((a, b) => a.dimension - b.dimension);
    this.widthSummary.sort((a, b) => a.dimension - b.dimension);
    this.heightSummary.sort((a, b) => a.dimension - b.dimension);
  }

  getPrivateContainerDetails(containerId: number) {
    this.sawnTimberService.getContainerDetail(containerId).subscribe(
      (data: any) => {
        this.containerDetailsArray = [
          { property: 'Container Number', value: data.containerNumber },
          { property: 'Net Volume', value: data.totalNetVolume },
          { property: 'Pieces', value: data.pieces },
          { property: 'Product', value: data.productName },
          { property: 'Formula', value: data.formula },
          { property: 'Seal Number', value: data.containerSeal },
        ];
        this.containerDetails = data;
      },
      (error: any) => {},
      () => {}
    );
  }

  getPublicContainerDetails(containerUuid: string | null) {
    this.sawnTimberService.getPublicContainerDetail(containerUuid).subscribe(
      (data: any) => {
        this.containerDetailsArray = [
          { property: 'Container Number', value: data.containerNumber },
          { property: 'Net Volume', value: data.totalNetVolume },
          { property: 'Pieces', value: data.pieces },
          { property: 'Product', value: data.productName },
          { property: 'Formula', value: data.formula },
          { property: 'Seal Number', value: data.containerSeal },
        ];
        this.containerDetails = data;
      },
      (error: any) => {},
      () => {}
    );
  }

  
}

*/
