import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of, from, switchMap } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BuyerService } from '../../buyer/services/buyer.service';
import { EncryptionService } from 'src/app/services/encryption.service';

@Injectable({
  providedIn: 'root',
})
export class BuyerDetailsResolver implements Resolve<any> {

constructor(
    private buyerService: BuyerService,
    private router: Router,
    private encryptionService: EncryptionService) { }
  
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const encryptedId = route.paramMap.get('id');
    if (encryptedId) {
      return from(this.encryptionService.decrypt(encryptedId)).pipe(
        switchMap((decodedId: any) =>
          this.buyerService.getBuyerById(decodedId).pipe(
            tap((response) => {
              console.log(`BuyerDetailsResolver response is ${JSON.stringify(response)}`);
            }),
            catchError((error) => {
              console.error(`BuyerDetailsResolver error is ${JSON.stringify(error)}`);
              this.router.navigate(['/error']); // Redirect on error
              return of(null);
            })
          )
        ),
        catchError((error: any) => {
          console.error(`Decryption error: ${error}`);
          this.router.navigate(['/buyers']);
          return of(null);
        })
      );
    } else {
      this.router.navigate(['/buyers']);
      return of(null);
    }
  }

}
